export default {
  home: {
    pageTitle: 'أشكالي',
    locationChecklists_label: 'ملء نموذج لكائن',
    generalChecklists_label: 'املأ النموذج العام',
    processingChecklists_label: 'النموذج قيد التقدم',
    checklistDatas_label: 'النماذج المكتملة',
    selectLocation: 'حدد الكائن',
    scanQrCode: 'مسح رمز الكائن',
    showAll: '...عرض الكل',
    showLess: '...اظهر أقل'
  },
  fill: {
    checklists: 'نماذج',
    fillChecklist: 'تعبئة الاستمارة',
    checklistNotFound: 'للأسف، لا يمكن العثور على النموذج الذي طلبته.',
    checklistOfflineLoginUserRequired: 'يتطلب هذا النموذج اختيار الموظف. الرجاء إنشاء اتصال بالإنترنت للوصول إلى قالب النموذج.',
    checklistNotOfflineAvailable: 'لم يتم حفظ قالب النموذج دون اتصال، يرجى الاتصال بالإنترنت للوصول إلى قالب النموذج.',
    resetConfirm: 'هل تريد حقا إعادة تعيين النموذج؟',
    categoryAppeared: 'تمت إضافة الفئة "{{categoryName}}".',
    groupAppeared: 'تمت إضافة الصفحة "{{groupName}}".',
    itemAppearedInGroup: 'تمت إضافة أسئلة جديدة إلى صفحة "{{groupName}}".',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'إلى السؤال التالي',
    containerUriExpired: 'للأسف انتهت فترة التحميل.',
    finishMessage: 'لقد قمت بإدخال جميع البيانات اللازمة ويمكنك الآن إكمال النموذج.',
    offlineMessage: 'يرجى الاتصال بالإنترنت لإكمال النموذج.',
    offlinePreviewMessage: 'الرجاء الاتصال بالإنترنت لإنشاء معاينة.',
    checklistUpdatedHeader: 'تم تغيير قالب النموذج',
    checklistUpdatedMessage: 'تم تغيير قالب النموذج. يرجى التحقق من إدخالاتك مرة أخرى قبل إرسال النموذج.',
    checklistLocationRequiredHeader: 'حدد الكائن',
    checklistLocationRequiredMessage: 'يجب عليك تحديد كائن لإكمال هذا النموذج.',
    finishSuccess: 'تم إكمال النموذج بنجاح وتمت معالجة جميع البيانات.',
    finishError: 'لا يمكن إكمال النموذج بسبب وجود خطأ. يرجى الاتصال بالدعم.',
    createdWith: 'تم إنشاء هذا النموذج باستخدام',
    preview: 'معاينة',
    fileUploadFileMissingHeader: 'لم يتم العثور على الملفات',
    fileUploadFileMissingMessage: 'تم تحديد الملفات التالية ولكن لا يمكن العثور عليها على الجهاز: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'نعم', no: 'لا' },
    scan: 'مسح',
    email: { invalidEmail: 'يرجى التحقق من الإدخال الخاص بك.' },
    takePicture: { picture: 'صورة', buttonText: 'إضافة صورة' },
    createTask: {
      taskTitle: 'عنوان التذكرة',
      taskDescription: 'وصف التذكرة',
      taskDueDate: 'موعد استحقاق التذكرة'
    }
  },
  noChecklistsForLocationFound: 'لا يوجد شكل لهذا الكائن. الرجاء اختيار عقار آخر أو ملء النموذج العام.',
  selectChecklistByLocation: 'حدد النموذج للكائن'
};
