// Last update: 10.06.2022
// This array contains all v6 Icons available for light style.

export const fontAwesomeIcons = [
  {
    id: '0',
    name: '0'
  },
  {
    id: '1',
    name: '1'
  },
  {
    id: '2',
    name: '2'
  },
  {
    id: '3',
    name: '3'
  },
  {
    id: '360-degrees',
    name: '360 Degrees'
  },
  {
    id: '4',
    name: '4'
  },
  {
    id: '5',
    name: '5'
  },
  {
    id: '6',
    name: '6'
  },
  {
    id: '7',
    name: '7'
  },
  {
    id: '8',
    name: '8'
  },
  {
    id: '9',
    name: '9'
  },
  {
    id: 'a',
    name: 'A'
  },
  {
    id: 'abacus',
    name: 'Abacus'
  },
  {
    id: 'accent-grave',
    name: 'Accent Grave'
  },
  {
    id: 'acorn',
    name: 'Acorn'
  },
  {
    id: 'address-book',
    name: 'Address Book'
  },
  {
    id: 'address-card',
    name: 'Address Card'
  },
  {
    id: 'air-conditioner',
    name: 'Air Conditioner'
  },
  {
    id: 'airplay',
    name: 'Airplay'
  },
  {
    id: 'alarm-clock',
    name: 'Alarm Clock'
  },
  {
    id: 'alarm-exclamation',
    name: 'Alarm Exclamation'
  },
  {
    id: 'alarm-plus',
    name: 'Alarm Plus'
  },
  {
    id: 'alarm-snooze',
    name: 'Alarm Snooze'
  },
  {
    id: 'album',
    name: 'Album'
  },
  {
    id: 'album-collection',
    name: 'Album Collection'
  },
  {
    id: 'alicorn',
    name: 'Alicorn'
  },
  {
    id: 'alien',
    name: 'Alien'
  },
  {
    id: 'alien-8bit',
    name: 'Alien 8bit'
  },
  {
    id: 'align-center',
    name: 'align-center'
  },
  {
    id: 'align-justify',
    name: 'align-justify'
  },
  {
    id: 'align-left',
    name: 'align-left'
  },
  {
    id: 'align-right',
    name: 'align-right'
  },
  {
    id: 'align-slash',
    name: 'Align Slash'
  },
  {
    id: 'alt',
    name: 'Alt'
  },
  {
    id: 'amp-guitar',
    name: 'Guitar Amplifier'
  },
  {
    id: 'ampersand',
    name: 'Ampersand'
  },
  {
    id: 'anchor',
    name: 'Anchor'
  },
  {
    id: 'angel',
    name: 'Angel'
  },
  {
    id: 'angle',
    name: 'Angle'
  },
  {
    id: 'angle-90',
    name: 'Angle 90'
  },
  {
    id: 'angle-down',
    name: 'angle-down'
  },
  {
    id: 'angle-left',
    name: 'angle-left'
  },
  {
    id: 'angle-right',
    name: 'angle-right'
  },
  {
    id: 'angle-up',
    name: 'angle-up'
  },
  {
    id: 'angles-down',
    name: 'Angles down'
  },
  {
    id: 'angles-left',
    name: 'Angles left'
  },
  {
    id: 'angles-right',
    name: 'Angles right'
  },
  {
    id: 'angles-up',
    name: 'Angles up'
  },
  {
    id: 'ankh',
    name: 'Ankh'
  },
  {
    id: 'aperture',
    name: 'Aperture'
  },
  {
    id: 'apostrophe',
    name: 'Apostrophe'
  },
  {
    id: 'apple-core',
    name: 'Apple Core'
  },
  {
    id: 'apple-whole',
    name: 'Apple whole'
  },
  {
    id: 'archway',
    name: 'Archway'
  },
  {
    id: 'arrow-down',
    name: 'Arrow down'
  },
  {
    id: 'arrow-down-1-9',
    name: 'Arrow down 1 9'
  },
  {
    id: 'arrow-down-9-1',
    name: 'Arrow down 9 1'
  },
  {
    id: 'arrow-down-a-z',
    name: 'Arrow down a z'
  },
  {
    id: 'arrow-down-arrow-up',
    name: 'Arrow down arrow up'
  },
  {
    id: 'arrow-down-big-small',
    name: 'Arrow down big small'
  },
  {
    id: 'arrow-down-from-dotted-line',
    name: 'Arrow down from dotted line'
  },
  {
    id: 'arrow-down-from-line',
    name: 'Arrow down from line'
  },
  {
    id: 'arrow-down-left',
    name: 'Arrow down left'
  },
  {
    id: 'arrow-down-left-and-arrow-up-right-to-center',
    name: 'Arrow down left and arrow up right to center'
  },
  {
    id: 'arrow-down-long',
    name: 'Arrow down long'
  },
  {
    id: 'arrow-down-right',
    name: 'Arrow down right'
  },
  {
    id: 'arrow-down-short-wide',
    name: 'Arrow down short wide'
  },
  {
    id: 'arrow-down-small-big',
    name: 'Arrow down small big'
  },
  {
    id: 'arrow-down-square-triangle',
    name: 'Arrow down square triangle'
  },
  {
    id: 'arrow-down-to-bracket',
    name: 'Arrow down to bracket'
  },
  {
    id: 'arrow-down-to-dotted-line',
    name: 'Arrow down to dotted line'
  },
  {
    id: 'arrow-down-to-line',
    name: 'Arrow down to line'
  },
  {
    id: 'arrow-down-to-square',
    name: 'Arrow down to square'
  },
  {
    id: 'arrow-down-triangle-square',
    name: 'Arrow down triangle square'
  },
  {
    id: 'arrow-down-wide-short',
    name: 'Arrow down wide short'
  },
  {
    id: 'arrow-down-z-a',
    name: 'Arrow down z a'
  },
  {
    id: 'arrow-left',
    name: 'arrow-left'
  },
  {
    id: 'arrow-left-from-line',
    name: 'Arrow left from line'
  },
  {
    id: 'arrow-left-long',
    name: 'Arrow left long'
  },
  {
    id: 'arrow-left-to-line',
    name: 'Arrow left to line'
  },
  {
    id: 'arrow-pointer',
    name: 'Arrow pointer'
  },
  {
    id: 'arrow-right',
    name: 'arrow right'
  },
  {
    id: 'arrow-right-arrow-left',
    name: 'Arrow right arrow left'
  },
  {
    id: 'arrow-right-from-bracket',
    name: 'Arrow right from bracket'
  },
  {
    id: 'arrow-right-from-line',
    name: 'Arrow right from line'
  },
  {
    id: 'arrow-right-long',
    name: 'Arrow right long'
  },
  {
    id: 'arrow-right-to-bracket',
    name: 'Arrow right to bracket'
  },
  {
    id: 'arrow-right-to-line',
    name: 'Arrow right to line'
  },
  {
    id: 'arrow-rotate-left',
    name: 'Arrow Rotate Left'
  },
  {
    id: 'arrow-rotate-right',
    name: 'Arrow Rotate Right'
  },
  {
    id: 'arrow-trend-down',
    name: 'Arrow trend down'
  },
  {
    id: 'arrow-trend-up',
    name: 'Arrow trend up'
  },
  {
    id: 'arrow-turn-down',
    name: 'Arrow turn down'
  },
  {
    id: 'arrow-turn-down-left',
    name: 'Arrow Turn Down Left'
  },
  {
    id: 'arrow-turn-up',
    name: 'Arrow turn up'
  },
  {
    id: 'arrow-up',
    name: 'Arrow up'
  },
  {
    id: 'arrow-up-1-9',
    name: 'Arrow up 1 9'
  },
  {
    id: 'arrow-up-9-1',
    name: 'Arrow up 9 1'
  },
  {
    id: 'arrow-up-a-z',
    name: 'Arrow up a z'
  },
  {
    id: 'arrow-up-arrow-down',
    name: 'Arrow up arrow down'
  },
  {
    id: 'arrow-up-big-small',
    name: 'Arrow up big small'
  },
  {
    id: 'arrow-up-from-bracket',
    name: 'Arrow up from bracket'
  },
  {
    id: 'arrow-up-from-dotted-line',
    name: 'Arrow up from dotted line'
  },
  {
    id: 'arrow-up-from-line',
    name: 'Arrow up from line'
  },
  {
    id: 'arrow-up-from-square',
    name: 'Arrow up from square'
  },
  {
    id: 'arrow-up-left',
    name: 'Arrow up left'
  },
  {
    id: 'arrow-up-left-from-circle',
    name: 'Arrow Up left from circle'
  },
  {
    id: 'arrow-up-long',
    name: 'Arrow up long'
  },
  {
    id: 'arrow-up-right',
    name: 'Arrow up right'
  },
  {
    id: 'arrow-up-right-and-arrow-down-left-from-center',
    name: 'Arrow up right and arrow down left from center'
  },
  {
    id: 'arrow-up-right-from-square',
    name: 'Arrow up right from square'
  },
  {
    id: 'arrow-up-short-wide',
    name: 'Arrow up short wide'
  },
  {
    id: 'arrow-up-small-big',
    name: 'Arrow up small big'
  },
  {
    id: 'arrow-up-square-triangle',
    name: 'Arrow up square triangle'
  },
  {
    id: 'arrow-up-to-dotted-line',
    name: 'Arrow up to dotted line'
  },
  {
    id: 'arrow-up-to-line',
    name: 'Arrow up to line'
  },
  {
    id: 'arrow-up-triangle-square',
    name: 'Arrow up triangle square'
  },
  {
    id: 'arrow-up-wide-short',
    name: 'Arrow up wide short'
  },
  {
    id: 'arrow-up-z-a',
    name: 'Arrow up z a'
  },
  {
    id: 'arrows-cross',
    name: 'Arrows Cross'
  },
  {
    id: 'arrows-from-dotted-line',
    name: 'Arrows from dotted line'
  },
  {
    id: 'arrows-from-line',
    name: 'Arrows From-line'
  },
  {
    id: 'arrows-left-right',
    name: 'Arrows left right'
  },
  {
    id: 'arrows-maximize',
    name: 'Arrows maximize'
  },
  {
    id: 'arrows-minimize',
    name: 'Arrows minimize'
  },
  {
    id: 'arrows-repeat',
    name: 'Arrows repeat'
  },
  {
    id: 'arrows-repeat-1',
    name: 'Arrows repeat 1'
  },
  {
    id: 'arrows-retweet',
    name: 'Arrows retweet'
  },
  {
    id: 'arrows-rotate',
    name: 'Arrows rotate'
  },
  {
    id: 'arrows-to-dotted-line',
    name: 'Arrows to dotted line'
  },
  {
    id: 'arrows-to-line',
    name: 'Arrows To-line'
  },
  {
    id: 'arrows-up-down',
    name: 'Arrows up down'
  },
  {
    id: 'arrows-up-down-left-right',
    name: 'Arrows up down left right'
  },
  {
    id: 'asterisk',
    name: 'asterisk'
  },
  {
    id: 'at',
    name: 'At'
  },
  {
    id: 'atom',
    name: 'Atom'
  },
  {
    id: 'atom-simple',
    name: 'Atom simple'
  },
  {
    id: 'audio-description',
    name: 'Rectangle audio description'
  },
  {
    id: 'audio-description-slash',
    name: 'Audio Description Slash'
  },
  {
    id: 'austral-sign',
    name: 'Austral Sign'
  },
  {
    id: 'avocado',
    name: 'Avocado'
  },
  {
    id: 'award',
    name: 'Award'
  },
  {
    id: 'award-simple',
    name: 'Award Simple'
  },
  {
    id: 'axe',
    name: 'Axe'
  },
  {
    id: 'axe-battle',
    name: 'Axe Battle'
  },
  {
    id: 'b',
    name: 'B'
  },
  {
    id: 'baby',
    name: 'Baby'
  },
  {
    id: 'baby-carriage',
    name: 'Baby Carriage'
  },
  {
    id: 'backpack',
    name: 'Backpack'
  },
  {
    id: 'backward',
    name: 'backward'
  },
  {
    id: 'backward-fast',
    name: 'Backward fast'
  },
  {
    id: 'backward-step',
    name: 'Backward step'
  },
  {
    id: 'bacon',
    name: 'Bacon'
  },
  {
    id: 'bacteria',
    name: 'Bacteria'
  },
  {
    id: 'bacterium',
    name: 'Bacterium'
  },
  {
    id: 'badge',
    name: 'Badge'
  },
  {
    id: 'badge-check',
    name: 'Check Badge'
  },
  {
    id: 'badge-dollar',
    name: 'Badge Dollar'
  },
  {
    id: 'badge-percent',
    name: 'Badge Percent'
  },
  {
    id: 'badge-sheriff',
    name: 'Sheriff Badge'
  },
  {
    id: 'badger-honey',
    name: 'Honey Badger'
  },
  {
    id: 'badminton',
    name: 'Badminton'
  },
  {
    id: 'bag-shopping',
    name: 'Bag shopping'
  },
  {
    id: 'bags-shopping',
    name: 'Shopping Bags'
  },
  {
    id: 'bahai',
    name: 'Bahá\'í'
  },
  {
    id: 'baht-sign',
    name: 'Baht Sign'
  },
  {
    id: 'ball-pile',
    name: 'Ball Pile'
  },
  {
    id: 'balloon',
    name: 'Balloon'
  },
  {
    id: 'balloons',
    name: 'Balloons'
  },
  {
    id: 'ballot',
    name: 'Ballot'
  },
  {
    id: 'ballot-check',
    name: 'Ballot Check'
  },
  {
    id: 'ban',
    name: 'ban'
  },
  {
    id: 'ban-bug',
    name: 'Ban bug'
  },
  {
    id: 'ban-parking',
    name: 'Ban parking'
  },
  {
    id: 'ban-smoking',
    name: 'Ban smoking'
  },
  {
    id: 'banana',
    name: 'Banana'
  },
  {
    id: 'bandage',
    name: 'Bandage'
  },
  {
    id: 'bangladeshi-taka-sign',
    name: 'Bangladeshi Taka Sign'
  },
  {
    id: 'banjo',
    name: 'Banjo'
  },
  {
    id: 'bank',
    name: 'Bank'
  },
  {
    id: 'barcode',
    name: 'barcode'
  },
  {
    id: 'barcode-read',
    name: 'Barcode Read'
  },
  {
    id: 'barcode-scan',
    name: 'Barcode Scan'
  },
  {
    id: 'bars',
    name: 'Bars'
  },
  {
    id: 'bars-filter',
    name: 'Bars Filter'
  },
  {
    id: 'bars-progress',
    name: 'Bars progress'
  },
  {
    id: 'bars-sort',
    name: 'Bars Sort'
  },
  {
    id: 'bars-staggered',
    name: 'Bars staggered'
  },
  {
    id: 'baseball',
    name: 'Baseball Ball'
  },
  {
    id: 'baseball-bat-ball',
    name: 'Baseball bat ball'
  },
  {
    id: 'basket-shopping',
    name: 'Basket shopping'
  },
  {
    id: 'basket-shopping-simple',
    name: 'Basket shopping simple'
  },
  {
    id: 'basketball',
    name: 'Basketball Ball'
  },
  {
    id: 'basketball-hoop',
    name: 'Basketball Hoop'
  },
  {
    id: 'bat',
    name: 'Bat'
  },
  {
    id: 'bath',
    name: 'Bath'
  },
  {
    id: 'battery-bolt',
    name: 'Battery Bolt'
  },
  {
    id: 'battery-empty',
    name: 'Battery Empty'
  },
  {
    id: 'battery-exclamation',
    name: 'Battery Exclamation'
  },
  {
    id: 'battery-full',
    name: 'Battery Full'
  },
  {
    id: 'battery-half',
    name: 'Battery 1/2 Full'
  },
  {
    id: 'battery-low',
    name: 'Battery Low'
  },
  {
    id: 'battery-quarter',
    name: 'Battery 1/4 Full'
  },
  {
    id: 'battery-slash',
    name: 'Battery Slash'
  },
  {
    id: 'battery-three-quarters',
    name: 'Battery 3/4 Full'
  },
  {
    id: 'bed',
    name: 'Bed'
  },
  {
    id: 'bed-bunk',
    name: 'Bunkbed'
  },
  {
    id: 'bed-empty',
    name: 'Bed Empty'
  },
  {
    id: 'bed-front',
    name: 'Bed front'
  },
  {
    id: 'bed-pulse',
    name: 'Bed pulse'
  },
  {
    id: 'bee',
    name: 'Bee'
  },
  {
    id: 'beer-mug',
    name: 'Beer mug'
  },
  {
    id: 'beer-mug-empty',
    name: 'Beer mug empty'
  },
  {
    id: 'bell',
    name: 'bell'
  },
  {
    id: 'bell-concierge',
    name: 'Bell concierge'
  },
  {
    id: 'bell-exclamation',
    name: 'Bell Exclamation'
  },
  {
    id: 'bell-on',
    name: 'Bell On'
  },
  {
    id: 'bell-plus',
    name: 'Bell Plus'
  },
  {
    id: 'bell-school',
    name: 'Bell School'
  },
  {
    id: 'bell-school-slash',
    name: 'Bell School Slash'
  },
  {
    id: 'bell-slash',
    name: 'Bell Slash'
  },
  {
    id: 'bells',
    name: 'Bells'
  },
  {
    id: 'bench-tree',
    name: 'Bench Tree'
  },
  {
    id: 'bezier-curve',
    name: 'Bezier Curve'
  },
  {
    id: 'bicycle',
    name: 'Bicycle'
  },
  {
    id: 'binary',
    name: 'Binary'
  },
  {
    id: 'binary-circle-check',
    name: 'Binary Circle Check'
  },
  {
    id: 'binary-lock',
    name: 'Binary Lock'
  },
  {
    id: 'binary-slash',
    name: 'Binary Slash'
  },
  {
    id: 'binoculars',
    name: 'Binoculars'
  },
  {
    id: 'biohazard',
    name: 'Biohazard'
  },
  {
    id: 'bitcoin-sign',
    name: 'Bitcoin Sign'
  },
  {
    id: 'blanket',
    name: 'Blanket'
  },
  {
    id: 'blender',
    name: 'Blender'
  },
  {
    id: 'blender-phone',
    name: 'Blender Phone'
  },
  {
    id: 'blinds',
    name: 'Blinds'
  },
  {
    id: 'blinds-open',
    name: 'Blinds Open'
  },
  {
    id: 'blinds-raised',
    name: 'Blinds Raised'
  },
  {
    id: 'block-quote',
    name: 'Block Quote'
  },
  {
    id: 'blog',
    name: 'Blog'
  },
  {
    id: 'blueberries',
    name: 'Blueberries'
  },
  {
    id: 'bluetooth',
    name: 'Bluetooth'
  },
  {
    id: 'bold',
    name: 'bold'
  },
  {
    id: 'bolt',
    name: 'Bolt'
  },
  {
    id: 'bolt-auto',
    name: 'Bolt Auto'
  },
  {
    id: 'bolt-lightning',
    name: 'Lightning Bolt'
  },
  {
    id: 'bolt-slash',
    name: 'Bolt Slash'
  },
  {
    id: 'bomb',
    name: 'Bomb'
  },
  {
    id: 'bone',
    name: 'Bone'
  },
  {
    id: 'bone-break',
    name: 'Bone Break'
  },
  {
    id: 'bong',
    name: 'Bong'
  },
  {
    id: 'book',
    name: 'book'
  },
  {
    id: 'book-arrow-right',
    name: 'Book Arrow Right'
  },
  {
    id: 'book-arrow-up',
    name: 'Book Arrow Up'
  },
  {
    id: 'book-atlas',
    name: 'Book atlas'
  },
  {
    id: 'book-bible',
    name: 'Book bible'
  },
  {
    id: 'book-blank',
    name: 'Book blank'
  },
  {
    id: 'book-bookmark',
    name: 'Book Bookmark'
  },
  {
    id: 'book-circle-arrow-right',
    name: 'Book Circle Arrow Right'
  },
  {
    id: 'book-circle-arrow-up',
    name: 'Book Circle Arrow Up'
  },
  {
    id: 'book-copy',
    name: 'Book Copy'
  },
  {
    id: 'book-font',
    name: 'Book Font'
  },
  {
    id: 'book-heart',
    name: 'Book with Heart'
  },
  {
    id: 'book-journal-whills',
    name: 'Book journal whills'
  },
  {
    id: 'book-medical',
    name: 'Medical Book'
  },
  {
    id: 'book-open',
    name: 'Book Open'
  },
  {
    id: 'book-open-cover',
    name: 'Book open cover'
  },
  {
    id: 'book-open-reader',
    name: 'Book open reader'
  },
  {
    id: 'book-quran',
    name: 'Book quran'
  },
  {
    id: 'book-section',
    name: 'Law Book'
  },
  {
    id: 'book-skull',
    name: 'Book skull'
  },
  {
    id: 'book-sparkles',
    name: 'Book sparkles'
  },
  {
    id: 'book-tanakh',
    name: 'Book tanakh'
  },
  {
    id: 'book-user',
    name: 'Book with User'
  },
  {
    id: 'bookmark',
    name: 'bookmark'
  },
  {
    id: 'bookmark-slash',
    name: 'Bookmark Slash'
  },
  {
    id: 'books',
    name: 'Books'
  },
  {
    id: 'books-medical',
    name: 'Medical Books'
  },
  {
    id: 'boombox',
    name: 'Boombox'
  },
  {
    id: 'boot',
    name: 'Boot'
  },
  {
    id: 'boot-heeled',
    name: 'Boot Heeled'
  },
  {
    id: 'booth-curtain',
    name: 'Booth with Curtain'
  },
  {
    id: 'border-all',
    name: 'Border All'
  },
  {
    id: 'border-bottom',
    name: 'Border Bottom'
  },
  {
    id: 'border-bottom-right',
    name: 'Border bottom right'
  },
  {
    id: 'border-center-h',
    name: 'Border Center - Horizontal'
  },
  {
    id: 'border-center-v',
    name: 'Border Center - Vertical'
  },
  {
    id: 'border-inner',
    name: 'Border Inner'
  },
  {
    id: 'border-left',
    name: 'Border Left'
  },
  {
    id: 'border-none',
    name: 'Border None'
  },
  {
    id: 'border-outer',
    name: 'Border Outer'
  },
  {
    id: 'border-right',
    name: 'Border Right'
  },
  {
    id: 'border-top',
    name: 'Border Top'
  },
  {
    id: 'border-top-left',
    name: 'Border top left'
  },
  {
    id: 'bow-arrow',
    name: 'Bow Arrow'
  },
  {
    id: 'bowl-chopsticks',
    name: 'Bowl Chopsticks'
  },
  {
    id: 'bowl-chopsticks-noodles',
    name: 'Bowl Chopsticks Noodles'
  },
  {
    id: 'bowl-hot',
    name: 'Bowl hot'
  },
  {
    id: 'bowl-rice',
    name: 'Bowl Rice'
  },
  {
    id: 'bowling-ball',
    name: 'Bowling Ball'
  },
  {
    id: 'bowling-ball-pin',
    name: 'Bowling Ball Pin'
  },
  {
    id: 'bowling-pins',
    name: 'Bowling Pins'
  },
  {
    id: 'box',
    name: 'Box'
  },
  {
    id: 'box-archive',
    name: 'Box archive'
  },
  {
    id: 'box-ballot',
    name: 'Box Ballot'
  },
  {
    id: 'box-check',
    name: 'Box Check'
  },
  {
    id: 'box-circle-check',
    name: 'Box Circle Check'
  },
  {
    id: 'box-dollar',
    name: 'Box dollar'
  },
  {
    id: 'box-heart',
    name: 'Box with Heart'
  },
  {
    id: 'box-open',
    name: 'Box Open'
  },
  {
    id: 'box-open-full',
    name: 'Box open full'
  },
  {
    id: 'box-taped',
    name: 'Box taped'
  },
  {
    id: 'box-tissue',
    name: 'Tissue Box'
  },
  {
    id: 'boxes-stacked',
    name: 'Boxes stacked'
  },
  {
    id: 'boxing-glove',
    name: 'Boxing Glove'
  },
  {
    id: 'bracket-curly',
    name: 'Bracket curly'
  },
  {
    id: 'bracket-curly-right',
    name: 'Bracket Curly-right'
  },
  {
    id: 'bracket-round',
    name: 'Bracket Round'
  },
  {
    id: 'bracket-round-right',
    name: 'Bracket Round-right'
  },
  {
    id: 'bracket-square',
    name: 'Bracket square'
  },
  {
    id: 'bracket-square-right',
    name: 'Bracket Square-right'
  },
  {
    id: 'brackets-curly',
    name: 'Curly Brackets'
  },
  {
    id: 'brackets-round',
    name: 'Brackets Round'
  },
  {
    id: 'brackets-square',
    name: 'Brackets square'
  },
  {
    id: 'braille',
    name: 'Braille'
  },
  {
    id: 'brain',
    name: 'Brain'
  },
  {
    id: 'brain-arrow-curved-right',
    name: 'Brain arrow curved right'
  },
  {
    id: 'brain-circuit',
    name: 'Brain Circuit'
  },
  {
    id: 'brake-warning',
    name: 'Brake Warning'
  },
  {
    id: 'bread-loaf',
    name: 'Loaf of Bread'
  },
  {
    id: 'bread-slice',
    name: 'Bread Slice'
  },
  {
    id: 'briefcase',
    name: 'Briefcase'
  },
  {
    id: 'briefcase-arrow-right',
    name: 'Briefcase Arrow-right'
  },
  {
    id: 'briefcase-blank',
    name: 'Briefcase Blank'
  },
  {
    id: 'briefcase-clock',
    name: 'Briefcase clock'
  },
  {
    id: 'briefcase-medical',
    name: 'Medical Briefcase'
  },
  {
    id: 'brightness',
    name: 'Brightness'
  },
  {
    id: 'brightness-low',
    name: 'Brightness Low'
  },
  {
    id: 'bring-forward',
    name: 'Bring Forward'
  },
  {
    id: 'bring-front',
    name: 'Bring Front'
  },
  {
    id: 'broom',
    name: 'Broom'
  },
  {
    id: 'browser',
    name: 'Browser'
  },
  {
    id: 'browsers',
    name: 'Browsers'
  },
  {
    id: 'brush',
    name: 'Brush'
  },
  {
    id: 'bug',
    name: 'Bug'
  },
  {
    id: 'building',
    name: 'Building'
  },
  {
    id: 'buildings',
    name: 'Buildings'
  },
  {
    id: 'bullhorn',
    name: 'bullhorn'
  },
  {
    id: 'bullseye',
    name: 'Bullseye'
  },
  {
    id: 'bullseye-arrow',
    name: 'Bullseye Arrow'
  },
  {
    id: 'bullseye-pointer',
    name: 'Bullseye Pointer'
  },
  {
    id: 'burger',
    name: 'Burger'
  },
  {
    id: 'burger-cheese',
    name: 'Burger cheese'
  },
  {
    id: 'burger-fries',
    name: 'Burger Fries'
  },
  {
    id: 'burger-glass',
    name: 'Burger Glass'
  },
  {
    id: 'burger-soda',
    name: 'Burger and Soda'
  },
  {
    id: 'burrito',
    name: 'Burrito'
  },
  {
    id: 'bus',
    name: 'Bus'
  },
  {
    id: 'bus-school',
    name: 'Bus School'
  },
  {
    id: 'bus-simple',
    name: 'Bus simple'
  },
  {
    id: 'c',
    name: 'C'
  },
  {
    id: 'cabinet-filing',
    name: 'Filing Cabinet'
  },
  {
    id: 'cable-car',
    name: 'Cable Car'
  },
  {
    id: 'cactus',
    name: 'Cactus'
  },
  {
    id: 'cake-candles',
    name: 'Cake candles'
  },
  {
    id: 'calculator',
    name: 'Calculator'
  },
  {
    id: 'calculator-simple',
    name: 'Calculator simple'
  },
  {
    id: 'calendar',
    name: 'Calendar'
  },
  {
    id: 'calendar-arrow-down',
    name: 'Calendar arrow down'
  },
  {
    id: 'calendar-arrow-up',
    name: 'Calendar arrow up'
  },
  {
    id: 'calendar-check',
    name: 'Calendar Check'
  },
  {
    id: 'calendar-clock',
    name: 'Calendar clock'
  },
  {
    id: 'calendar-day',
    name: 'Calendar with Day Focus'
  },
  {
    id: 'calendar-days',
    name: 'Calendar days'
  },
  {
    id: 'calendar-exclamation',
    name: 'Calendar Exclamation'
  },
  {
    id: 'calendar-heart',
    name: 'Calendar Heart'
  },
  {
    id: 'calendar-image',
    name: 'Calendar Image'
  },
  {
    id: 'calendar-lines',
    name: 'Calendar lines'
  },
  {
    id: 'calendar-minus',
    name: 'Calendar Minus'
  },
  {
    id: 'calendar-pen',
    name: 'Calendar pen'
  },
  {
    id: 'calendar-plus',
    name: 'Calendar Plus'
  },
  {
    id: 'calendar-range',
    name: 'Calendar Range'
  },
  {
    id: 'calendar-star',
    name: 'Calendar Star'
  },
  {
    id: 'calendar-week',
    name: 'Calendar with Week Focus'
  },
  {
    id: 'calendar-xmark',
    name: 'Calendar X Mark'
  },
  {
    id: 'calendars',
    name: 'Calendars'
  },
  {
    id: 'camcorder',
    name: 'Camcorder'
  },
  {
    id: 'camera',
    name: 'camera'
  },
  {
    id: 'camera-cctv',
    name: 'Camera cctv'
  },
  {
    id: 'camera-movie',
    name: 'Movie Camera'
  },
  {
    id: 'camera-polaroid',
    name: 'Polaroid Camera'
  },
  {
    id: 'camera-retro',
    name: 'Retro Camera'
  },
  {
    id: 'camera-rotate',
    name: 'Camera Rotate'
  },
  {
    id: 'camera-security',
    name: 'Camera security'
  },
  {
    id: 'camera-slash',
    name: 'Camera Slash'
  },
  {
    id: 'camera-viewfinder',
    name: 'Camera Viewfinder'
  },
  {
    id: 'camera-web',
    name: 'Camera web'
  },
  {
    id: 'camera-web-slash',
    name: 'Camera web slash'
  },
  {
    id: 'campfire',
    name: 'Campfire'
  },
  {
    id: 'campground',
    name: 'Campground'
  },
  {
    id: 'candle-holder',
    name: 'Candle Holder'
  },
  {
    id: 'candy-cane',
    name: 'Candy Cane'
  },
  {
    id: 'candy-corn',
    name: 'Candy Corn'
  },
  {
    id: 'cannabis',
    name: 'Cannabis'
  },
  {
    id: 'capsules',
    name: 'Capsules'
  },
  {
    id: 'car',
    name: 'Car'
  },
  {
    id: 'car-battery',
    name: 'Car Battery'
  },
  {
    id: 'car-bolt',
    name: 'Car Bolt'
  },
  {
    id: 'car-building',
    name: 'Car and Building'
  },
  {
    id: 'car-bump',
    name: 'Car Bump'
  },
  {
    id: 'car-bus',
    name: 'Car and Bus'
  },
  {
    id: 'car-circle-bolt',
    name: 'Car Circle Bolt'
  },
  {
    id: 'car-crash',
    name: 'Car Crash'
  },
  {
    id: 'car-garage',
    name: 'Car Garage'
  },
  {
    id: 'car-mirrors',
    name: 'Car Mirrors'
  },
  {
    id: 'car-rear',
    name: 'Car rear'
  },
  {
    id: 'car-side',
    name: 'Car Side'
  },
  {
    id: 'car-side-bolt',
    name: 'Car Side Bolt'
  },
  {
    id: 'car-tilt',
    name: 'Car Tilt'
  },
  {
    id: 'car-wash',
    name: 'Car Wash'
  },
  {
    id: 'car-wrench',
    name: 'Car wrench'
  },
  {
    id: 'caravan',
    name: 'Caravan'
  },
  {
    id: 'caravan-simple',
    name: 'Caravan simple'
  },
  {
    id: 'caret-down',
    name: 'Caret Down'
  },
  {
    id: 'caret-left',
    name: 'Caret Left'
  },
  {
    id: 'caret-right',
    name: 'Caret Right'
  },
  {
    id: 'caret-up',
    name: 'Caret Up'
  },
  {
    id: 'carrot',
    name: 'Carrot'
  },
  {
    id: 'cars',
    name: 'Cars'
  },
  {
    id: 'cart-arrow-down',
    name: 'Shopping Cart Arrow Down'
  },
  {
    id: 'cart-flatbed',
    name: 'Cart flatbed'
  },
  {
    id: 'cart-flatbed-boxes',
    name: 'Cart flatbed boxes'
  },
  {
    id: 'cart-flatbed-empty',
    name: 'Cart flatbed empty'
  },
  {
    id: 'cart-flatbed-suitcase',
    name: 'Cart flatbed suitcase'
  },
  {
    id: 'cart-minus',
    name: 'Cart Minus'
  },
  {
    id: 'cart-plus',
    name: 'Add to Shopping Cart'
  },
  {
    id: 'cart-shopping',
    name: 'Cart shopping'
  },
  {
    id: 'cart-shopping-fast',
    name: 'Cart Shopping-fast'
  },
  {
    id: 'cart-xmark',
    name: 'Cart Xmark'
  },
  {
    id: 'cash-register',
    name: 'Cash Register'
  },
  {
    id: 'cassette-betamax',
    name: 'Betamax Cassette'
  },
  {
    id: 'cassette-tape',
    name: 'Cassette Tape'
  },
  {
    id: 'cassette-vhs',
    name: 'VHS Cassette'
  },
  {
    id: 'castle',
    name: 'Castle'
  },
  {
    id: 'cat',
    name: 'Cat'
  },
  {
    id: 'cat-space',
    name: 'Space Cat'
  },
  {
    id: 'cauldron',
    name: 'Cauldron'
  },
  {
    id: 'cedi-sign',
    name: 'Cedi Sign'
  },
  {
    id: 'cent-sign',
    name: 'Cent Sign'
  },
  {
    id: 'certificate',
    name: 'certificate'
  },
  {
    id: 'chair',
    name: 'Chair'
  },
  {
    id: 'chair-office',
    name: 'Office Chair'
  },
  {
    id: 'chalkboard',
    name: 'Chalkboard'
  },
  {
    id: 'chalkboard-user',
    name: 'Chalkboard user'
  },
  {
    id: 'champagne-glass',
    name: 'Champagne glass'
  },
  {
    id: 'champagne-glasses',
    name: 'Champagne glasses'
  },
  {
    id: 'charging-station',
    name: 'Charging Station'
  },
  {
    id: 'chart-area',
    name: 'Area Chart'
  },
  {
    id: 'chart-bar',
    name: 'Bar Chart'
  },
  {
    id: 'chart-bullet',
    name: 'Chart Bullet'
  },
  {
    id: 'chart-candlestick',
    name: 'Chart Candlestick'
  },
  {
    id: 'chart-column',
    name: 'Chart Column'
  },
  {
    id: 'chart-gantt',
    name: 'Chart Gantt'
  },
  {
    id: 'chart-line',
    name: 'Line Chart'
  },
  {
    id: 'chart-line-down',
    name: 'Line Chart in Down Direction'
  },
  {
    id: 'chart-line-up',
    name: 'Chart Line-up'
  },
  {
    id: 'chart-mixed',
    name: 'Chart mixed'
  },
  {
    id: 'chart-network',
    name: 'Network Chart'
  },
  {
    id: 'chart-pie',
    name: 'Pie Chart'
  },
  {
    id: 'chart-pie-simple',
    name: 'Chart pie simple'
  },
  {
    id: 'chart-pyramid',
    name: 'Chart Pyramid'
  },
  {
    id: 'chart-radar',
    name: 'Chart Radar'
  },
  {
    id: 'chart-scatter',
    name: 'Scatter Chart'
  },
  {
    id: 'chart-scatter-3d',
    name: 'Chart Scatter-3d'
  },
  {
    id: 'chart-scatter-bubble',
    name: 'Chart Scatter-bubble'
  },
  {
    id: 'chart-tree-map',
    name: 'Chart Tree-map'
  },
  {
    id: 'chart-user',
    name: 'Chart user'
  },
  {
    id: 'chart-waterfall',
    name: 'Chart Waterfall'
  },
  {
    id: 'check',
    name: 'Check'
  },
  {
    id: 'check-double',
    name: 'Double Check'
  },
  {
    id: 'check-to-slot',
    name: 'Check to Slot'
  },
  {
    id: 'cheese',
    name: 'Cheese'
  },
  {
    id: 'cheese-swiss',
    name: 'Swiss Cheese'
  },
  {
    id: 'cherries',
    name: 'Cherries'
  },
  {
    id: 'chess',
    name: 'Chess'
  },
  {
    id: 'chess-bishop',
    name: 'Chess Bishop'
  },
  {
    id: 'chess-bishop-piece',
    name: 'Chess bishop piece'
  },
  {
    id: 'chess-board',
    name: 'Chess Board'
  },
  {
    id: 'chess-clock',
    name: 'Chess Clock'
  },
  {
    id: 'chess-clock-flip',
    name: 'Chess clock flip'
  },
  {
    id: 'chess-king',
    name: 'Chess King'
  },
  {
    id: 'chess-king-piece',
    name: 'Chess king piece'
  },
  {
    id: 'chess-knight',
    name: 'Chess Knight'
  },
  {
    id: 'chess-knight-piece',
    name: 'Chess knight piece'
  },
  {
    id: 'chess-pawn',
    name: 'Chess Pawn'
  },
  {
    id: 'chess-pawn-piece',
    name: 'Chess pawn piece'
  },
  {
    id: 'chess-queen',
    name: 'Chess Queen'
  },
  {
    id: 'chess-queen-piece',
    name: 'Chess queen piece'
  },
  {
    id: 'chess-rook',
    name: 'Chess Rook'
  },
  {
    id: 'chess-rook-piece',
    name: 'Chess rook piece'
  },
  {
    id: 'chevron-down',
    name: 'chevron-down'
  },
  {
    id: 'chevron-left',
    name: 'chevron-left'
  },
  {
    id: 'chevron-right',
    name: 'chevron-right'
  },
  {
    id: 'chevron-up',
    name: 'chevron-up'
  },
  {
    id: 'chevrons-down',
    name: 'Chevrons down'
  },
  {
    id: 'chevrons-left',
    name: 'Chevrons left'
  },
  {
    id: 'chevrons-right',
    name: 'Chevrons right'
  },
  {
    id: 'chevrons-up',
    name: 'Chevrons up'
  },
  {
    id: 'child',
    name: 'Child'
  },
  {
    id: 'chimney',
    name: 'Chimney'
  },
  {
    id: 'church',
    name: 'Church'
  },
  {
    id: 'circle',
    name: 'Circle'
  },
  {
    id: 'circle-0',
    name: 'Circle 0'
  },
  {
    id: 'circle-1',
    name: 'Circle 1'
  },
  {
    id: 'circle-2',
    name: 'Circle 2'
  },
  {
    id: 'circle-3',
    name: 'Circle 3'
  },
  {
    id: 'circle-4',
    name: 'Circle 4'
  },
  {
    id: 'circle-5',
    name: 'Circle 5'
  },
  {
    id: 'circle-6',
    name: 'Circle 6'
  },
  {
    id: 'circle-7',
    name: 'Circle 7'
  },
  {
    id: 'circle-8',
    name: 'Circle 8'
  },
  {
    id: 'circle-9',
    name: 'Circle 9'
  },
  {
    id: 'circle-a',
    name: 'Circle A'
  },
  {
    id: 'circle-ampersand',
    name: 'Circle Ampersand'
  },
  {
    id: 'circle-arrow-down',
    name: 'Circle arrow down'
  },
  {
    id: 'circle-arrow-down-left',
    name: 'Circle Arrow-down-left'
  },
  {
    id: 'circle-arrow-down-right',
    name: 'Circle Arrow-down-right'
  },
  {
    id: 'circle-arrow-left',
    name: 'Circle arrow left'
  },
  {
    id: 'circle-arrow-right',
    name: 'Circle arrow right'
  },
  {
    id: 'circle-arrow-up',
    name: 'Circle arrow up'
  },
  {
    id: 'circle-arrow-up-left',
    name: 'Circle Arrow-up-left'
  },
  {
    id: 'circle-arrow-up-right',
    name: 'Circle Arrow-up-right'
  },
  {
    id: 'circle-b',
    name: 'Circle B'
  },
  {
    id: 'circle-bolt',
    name: 'Circle Bolt'
  },
  {
    id: 'circle-book-open',
    name: 'Circle book open'
  },
  {
    id: 'circle-bookmark',
    name: 'Circle bookmark'
  },
  {
    id: 'circle-c',
    name: 'Circle C'
  },
  {
    id: 'circle-calendar',
    name: 'Circle calendar'
  },
  {
    id: 'circle-camera',
    name: 'Circle camera'
  },
  {
    id: 'circle-caret-down',
    name: 'Circle caret down'
  },
  {
    id: 'circle-caret-left',
    name: 'Circle caret left'
  },
  {
    id: 'circle-caret-right',
    name: 'Circle caret right'
  },
  {
    id: 'circle-caret-up',
    name: 'Circle caret up'
  },
  {
    id: 'circle-check',
    name: 'Circle check'
  },
  {
    id: 'circle-chevron-down',
    name: 'Circle chevron down'
  },
  {
    id: 'circle-chevron-left',
    name: 'Circle chevron left'
  },
  {
    id: 'circle-chevron-right',
    name: 'Circle chevron right'
  },
  {
    id: 'circle-chevron-up',
    name: 'Circle chevron up'
  },
  {
    id: 'circle-d',
    name: 'Circle D'
  },
  {
    id: 'circle-dashed',
    name: 'Circle Dashed'
  },
  {
    id: 'circle-divide',
    name: 'Circle Divide'
  },
  {
    id: 'circle-dollar',
    name: 'Circle dollar'
  },
  {
    id: 'circle-dollar-to-slot',
    name: 'Circle dollar to slot'
  },
  {
    id: 'circle-dot',
    name: 'Circle dot'
  },
  {
    id: 'circle-down',
    name: 'Circle down'
  },
  {
    id: 'circle-down-left',
    name: 'Circle Down-left'
  },
  {
    id: 'circle-down-right',
    name: 'Circle Down-right'
  },
  {
    id: 'circle-e',
    name: 'Circle E'
  },
  {
    id: 'circle-ellipsis',
    name: 'Circle Ellipsis'
  },
  {
    id: 'circle-ellipsis-vertical',
    name: 'Circle Ellipsis-vertical'
  },
  {
    id: 'circle-envelope',
    name: 'Circle envelope'
  },
  {
    id: 'circle-exclamation',
    name: 'Circle exclamation'
  },
  {
    id: 'circle-exclamation-check',
    name: 'Circle Exclamation-check'
  },
  {
    id: 'circle-f',
    name: 'Circle F'
  },
  {
    id: 'circle-g',
    name: 'Circle G'
  },
  {
    id: 'circle-h',
    name: 'Circle h'
  },
  {
    id: 'circle-half',
    name: 'Circle Half'
  },
  {
    id: 'circle-half-stroke',
    name: 'Circle half stroke'
  },
  {
    id: 'circle-heart',
    name: 'Circle heart'
  },
  {
    id: 'circle-i',
    name: 'Circle I'
  },
  {
    id: 'circle-info',
    name: 'Circle info'
  },
  {
    id: 'circle-j',
    name: 'Circle J'
  },
  {
    id: 'circle-k',
    name: 'Circle K'
  },
  {
    id: 'circle-l',
    name: 'Circle L'
  },
  {
    id: 'circle-left',
    name: 'Circle left'
  },
  {
    id: 'circle-location-arrow',
    name: 'Circle location arrow'
  },
  {
    id: 'circle-m',
    name: 'Circle M'
  },
  {
    id: 'circle-microphone',
    name: 'Circle microphone'
  },
  {
    id: 'circle-microphone-lines',
    name: 'Circle microphone lines'
  },
  {
    id: 'circle-minus',
    name: 'Circle minus'
  },
  {
    id: 'circle-n',
    name: 'Circle N'
  },
  {
    id: 'circle-notch',
    name: 'Circle Notched'
  },
  {
    id: 'circle-o',
    name: 'Circle O'
  },
  {
    id: 'circle-p',
    name: 'Circle P'
  },
  {
    id: 'circle-parking',
    name: 'Circle parking'
  },
  {
    id: 'circle-pause',
    name: 'Circle pause'
  },
  {
    id: 'circle-phone',
    name: 'Circle phone'
  },
  {
    id: 'circle-phone-flip',
    name: 'Circle phone flip'
  },
  {
    id: 'circle-phone-hangup',
    name: 'Circle phone hangup'
  },
  {
    id: 'circle-play',
    name: 'Circle play'
  },
  {
    id: 'circle-plus',
    name: 'Circle plus'
  },
  {
    id: 'circle-q',
    name: 'Circle Q'
  },
  {
    id: 'circle-quarter',
    name: 'Circle Quarter'
  },
  {
    id: 'circle-question',
    name: 'Circle question'
  },
  {
    id: 'circle-r',
    name: 'Circle R'
  },
  {
    id: 'circle-radiation',
    name: 'Circle radiation'
  },
  {
    id: 'circle-right',
    name: 'Circle right'
  },
  {
    id: 'circle-s',
    name: 'Circle S'
  },
  {
    id: 'circle-small',
    name: 'Circle Small'
  },
  {
    id: 'circle-sort',
    name: 'Circle sort'
  },
  {
    id: 'circle-sort-down',
    name: 'Circle sort down'
  },
  {
    id: 'circle-sort-up',
    name: 'Circle sort up'
  },
  {
    id: 'circle-star',
    name: 'Circle star'
  },
  {
    id: 'circle-stop',
    name: 'Circle stop'
  },
  {
    id: 'circle-t',
    name: 'Circle T'
  },
  {
    id: 'circle-three-quarters',
    name: 'Circle Three-quarters'
  },
  {
    id: 'circle-trash',
    name: 'Circle trash'
  },
  {
    id: 'circle-u',
    name: 'Circle U'
  },
  {
    id: 'circle-up',
    name: 'Circle up'
  },
  {
    id: 'circle-up-left',
    name: 'Circle Up-left'
  },
  {
    id: 'circle-up-right',
    name: 'Circle Up-right'
  },
  {
    id: 'circle-user',
    name: 'Circle user'
  },
  {
    id: 'circle-v',
    name: 'Circle V'
  },
  {
    id: 'circle-video',
    name: 'Circle video'
  },
  {
    id: 'circle-w',
    name: 'Circle W'
  },
  {
    id: 'circle-waveform-lines',
    name: 'Circle waveform lines'
  },
  {
    id: 'circle-x',
    name: 'Circle X'
  },
  {
    id: 'circle-xmark',
    name: 'Circle X Mark'
  },
  {
    id: 'circle-y',
    name: 'Circle Y'
  },
  {
    id: 'circle-z',
    name: 'Circle Z'
  },
  {
    id: 'citrus',
    name: 'Citrus'
  },
  {
    id: 'citrus-slice',
    name: 'Citrus Slice'
  },
  {
    id: 'city',
    name: 'City'
  },
  {
    id: 'clapperboard',
    name: 'Clapperboard'
  },
  {
    id: 'clapperboard-play',
    name: 'Clapperboard Play'
  },
  {
    id: 'clarinet',
    name: 'Clarinet'
  },
  {
    id: 'claw-marks',
    name: 'Claw Marks'
  },
  {
    id: 'clipboard',
    name: 'Clipboard'
  },
  {
    id: 'clipboard-check',
    name: 'Clipboard with Check'
  },
  {
    id: 'clipboard-list',
    name: 'Clipboard List'
  },
  {
    id: 'clipboard-list-check',
    name: 'Clipboard List with Check'
  },
  {
    id: 'clipboard-medical',
    name: 'Clipboard Medical'
  },
  {
    id: 'clipboard-prescription',
    name: 'Clipboard Prescription'
  },
  {
    id: 'clipboard-user',
    name: 'Clipboard with User'
  },
  {
    id: 'clock',
    name: 'Clock'
  },
  {
    id: 'clock-desk',
    name: 'Clock Desk'
  },
  {
    id: 'clock-eight',
    name: 'Clock Eight'
  },
  {
    id: 'clock-eight-thirty',
    name: 'Clock Eight-Thirty'
  },
  {
    id: 'clock-eleven',
    name: 'Clock Eleven'
  },
  {
    id: 'clock-eleven-thirty',
    name: 'Clock Eleven-Thirty'
  },
  {
    id: 'clock-five',
    name: 'Clock Five'
  },
  {
    id: 'clock-five-thirty',
    name: 'Clock Five-Thirty'
  },
  {
    id: 'clock-four-thirty',
    name: 'Clock Four-Thirty'
  },
  {
    id: 'clock-nine',
    name: 'Clock Nine'
  },
  {
    id: 'clock-nine-thirty',
    name: 'Clock Nine-Thirty'
  },
  {
    id: 'clock-one',
    name: 'Clock One'
  },
  {
    id: 'clock-one-thirty',
    name: 'Clock One-Thirty'
  },
  {
    id: 'clock-rotate-left',
    name: 'Clock Rotate Left'
  },
  {
    id: 'clock-seven',
    name: 'Clock Seven'
  },
  {
    id: 'clock-seven-thirty',
    name: 'Clock Seven-Thirty'
  },
  {
    id: 'clock-six',
    name: 'Clock Six'
  },
  {
    id: 'clock-six-thirty',
    name: 'Clock Six-Thirty'
  },
  {
    id: 'clock-ten',
    name: 'Clock Ten'
  },
  {
    id: 'clock-ten-thirty',
    name: 'Clock Ten-Thirty'
  },
  {
    id: 'clock-three',
    name: 'Clock Three'
  },
  {
    id: 'clock-three-thirty',
    name: 'Clock Three-Thirty'
  },
  {
    id: 'clock-twelve',
    name: 'Clock Twelve'
  },
  {
    id: 'clock-twelve-thirty',
    name: 'Clock Twelve-Thirty'
  },
  {
    id: 'clock-two',
    name: 'Clock Two'
  },
  {
    id: 'clock-two-thirty',
    name: 'Clock Two-Thirty'
  },
  {
    id: 'clone',
    name: 'Clone'
  },
  {
    id: 'closed-captioning',
    name: 'Closed Captioning'
  },
  {
    id: 'closed-captioning-slash',
    name: 'Closed Captioning-slash'
  },
  {
    id: 'clothes-hanger',
    name: 'Clothes Hanger'
  },
  {
    id: 'cloud',
    name: 'Cloud'
  },
  {
    id: 'cloud-arrow-down',
    name: 'Cloud arrow down'
  },
  {
    id: 'cloud-arrow-up',
    name: 'Cloud arrow up'
  },
  {
    id: 'cloud-bolt',
    name: 'Cloud bolt'
  },
  {
    id: 'cloud-bolt-moon',
    name: 'Cloud bolt moon'
  },
  {
    id: 'cloud-bolt-sun',
    name: 'Cloud bolt sun'
  },
  {
    id: 'cloud-check',
    name: 'Cloud Check'
  },
  {
    id: 'cloud-drizzle',
    name: 'Cloud with Drizzle'
  },
  {
    id: 'cloud-fog',
    name: 'Cloud fog'
  },
  {
    id: 'cloud-hail',
    name: 'Cloud with Hail'
  },
  {
    id: 'cloud-hail-mixed',
    name: 'Cloud with Mixed Hail'
  },
  {
    id: 'cloud-meatball',
    name: 'Cloud with (a chance of) Meatball'
  },
  {
    id: 'cloud-minus',
    name: 'Cloud Minus'
  },
  {
    id: 'cloud-moon',
    name: 'Cloud with Moon'
  },
  {
    id: 'cloud-moon-rain',
    name: 'Cloud with Moon and Rain'
  },
  {
    id: 'cloud-music',
    name: 'Cloud Music'
  },
  {
    id: 'cloud-plus',
    name: 'Cloud Plus'
  },
  {
    id: 'cloud-rain',
    name: 'Cloud with Rain'
  },
  {
    id: 'cloud-rainbow',
    name: 'Cloud with Rainbow'
  },
  {
    id: 'cloud-showers',
    name: 'Cloud with Showers'
  },
  {
    id: 'cloud-showers-heavy',
    name: 'Cloud with Heavy Showers'
  },
  {
    id: 'cloud-slash',
    name: 'Cloud Slash'
  },
  {
    id: 'cloud-sleet',
    name: 'Cloud with Sleet'
  },
  {
    id: 'cloud-snow',
    name: 'Cloud with Snow'
  },
  {
    id: 'cloud-sun',
    name: 'Cloud with Sun'
  },
  {
    id: 'cloud-sun-rain',
    name: 'Cloud with Sun and Rain'
  },
  {
    id: 'cloud-word',
    name: 'Cloud Word'
  },
  {
    id: 'cloud-xmark',
    name: 'Cloud Xmark'
  },
  {
    id: 'clouds',
    name: 'Clouds'
  },
  {
    id: 'clouds-moon',
    name: 'Clouds with Moon'
  },
  {
    id: 'clouds-sun',
    name: 'Clouds with Sun'
  },
  {
    id: 'clover',
    name: 'Clover'
  },
  {
    id: 'club',
    name: 'Club'
  },
  {
    id: 'coconut',
    name: 'Coconut'
  },
  {
    id: 'code',
    name: 'Code'
  },
  {
    id: 'code-branch',
    name: 'Code Branch'
  },
  {
    id: 'code-commit',
    name: 'Code Commit'
  },
  {
    id: 'code-compare',
    name: 'Code Compare'
  },
  {
    id: 'code-fork',
    name: 'Code Fork'
  },
  {
    id: 'code-merge',
    name: 'Code Merge'
  },
  {
    id: 'code-pull-request',
    name: 'Code Pull-request'
  },
  {
    id: 'code-simple',
    name: 'Code Simple'
  },
  {
    id: 'coffee-bean',
    name: 'Coffee Bean'
  },
  {
    id: 'coffee-beans',
    name: 'Coffee Beans'
  },
  {
    id: 'coffee-pot',
    name: 'Coffee Pot'
  },
  {
    id: 'coffin',
    name: 'Coffin'
  },
  {
    id: 'coffin-cross',
    name: 'Coffin with Cross'
  },
  {
    id: 'coin',
    name: 'Coin'
  },
  {
    id: 'coins',
    name: 'Coins'
  },
  {
    id: 'colon',
    name: 'Colon'
  },
  {
    id: 'colon-sign',
    name: 'Colon Sign'
  },
  {
    id: 'columns-3',
    name: 'Columns 3'
  },
  {
    id: 'comet',
    name: 'Comet'
  },
  {
    id: 'comma',
    name: 'Comma'
  },
  {
    id: 'command',
    name: 'Command'
  },
  {
    id: 'comment',
    name: 'comment'
  },
  {
    id: 'comment-arrow-down',
    name: 'Comment Arrow-down'
  },
  {
    id: 'comment-arrow-up',
    name: 'Comment Arrow-up'
  },
  {
    id: 'comment-arrow-up-right',
    name: 'Comment Arrow-up-right'
  },
  {
    id: 'comment-captions',
    name: 'Comment Captions'
  },
  {
    id: 'comment-check',
    name: 'Comment Check'
  },
  {
    id: 'comment-code',
    name: 'Comment Code'
  },
  {
    id: 'comment-dollar',
    name: 'Comment Dollar'
  },
  {
    id: 'comment-dots',
    name: 'Comment Dots'
  },
  {
    id: 'comment-exclamation',
    name: 'Comment Exclamation'
  },
  {
    id: 'comment-image',
    name: 'Comment Image'
  },
  {
    id: 'comment-lines',
    name: 'Comment Lines'
  },
  {
    id: 'comment-medical',
    name: 'Alternate Medical Chat'
  },
  {
    id: 'comment-middle',
    name: 'Comment Middle'
  },
  {
    id: 'comment-middle-top',
    name: 'Comment Middle-top'
  },
  {
    id: 'comment-minus',
    name: 'Comment Minus'
  },
  {
    id: 'comment-music',
    name: 'Comment Music'
  },
  {
    id: 'comment-pen',
    name: 'Comment pen'
  },
  {
    id: 'comment-plus',
    name: 'Comment Plus'
  },
  {
    id: 'comment-question',
    name: 'Comment Question'
  },
  {
    id: 'comment-quote',
    name: 'Comment Quote'
  },
  {
    id: 'comment-slash',
    name: 'Comment Slash'
  },
  {
    id: 'comment-smile',
    name: 'Comment Smile'
  },
  {
    id: 'comment-sms',
    name: 'Comment sms'
  },
  {
    id: 'comment-text',
    name: 'Comment Text'
  },
  {
    id: 'comment-xmark',
    name: 'Comment X Mark'
  },
  {
    id: 'comments',
    name: 'comments'
  },
  {
    id: 'comments-dollar',
    name: 'Comments Dollar'
  },
  {
    id: 'comments-question',
    name: 'Comments Question'
  },
  {
    id: 'comments-question-check',
    name: 'Comments Question-check'
  },
  {
    id: 'compact-disc',
    name: 'Compact Disc'
  },
  {
    id: 'compass',
    name: 'Compass'
  },
  {
    id: 'compass-drafting',
    name: 'Compass drafting'
  },
  {
    id: 'compass-slash',
    name: 'Compass Slash'
  },
  {
    id: 'compress',
    name: 'Compress'
  },
  {
    id: 'compress-wide',
    name: 'Compress Wide'
  },
  {
    id: 'computer-classic',
    name: 'Classic Computer'
  },
  {
    id: 'computer-mouse',
    name: 'Computer mouse'
  },
  {
    id: 'computer-mouse-scrollwheel',
    name: 'Computer mouse scrollwheel'
  },
  {
    id: 'computer-speaker',
    name: 'Computer Speaker'
  },
  {
    id: 'container-storage',
    name: 'Container Storage'
  },
  {
    id: 'conveyor-belt',
    name: 'Conveyor Belt'
  },
  {
    id: 'conveyor-belt-boxes',
    name: 'Conveyor belt boxes'
  },
  {
    id: 'conveyor-belt-empty',
    name: 'Conveyor Belt-empty'
  },
  {
    id: 'cookie',
    name: 'Cookie'
  },
  {
    id: 'cookie-bite',
    name: 'Cookie Bite'
  },
  {
    id: 'copy',
    name: 'Copy'
  },
  {
    id: 'copyright',
    name: 'Copyright'
  },
  {
    id: 'corn',
    name: 'Corn'
  },
  {
    id: 'corner',
    name: 'Corner'
  },
  {
    id: 'couch',
    name: 'Couch'
  },
  {
    id: 'cow',
    name: 'Cow'
  },
  {
    id: 'cowbell',
    name: 'Cowbell'
  },
  {
    id: 'cowbell-circle-plus',
    name: 'Cowbell circle plus'
  },
  {
    id: 'crate-apple',
    name: 'Apple Crate'
  },
  {
    id: 'crate-empty',
    name: 'Crate Empty'
  },
  {
    id: 'credit-card',
    name: 'Credit Card'
  },
  {
    id: 'credit-card-blank',
    name: 'Credit Card Blank'
  },
  {
    id: 'credit-card-front',
    name: 'Credit Card Front'
  },
  {
    id: 'cricket-bat-ball',
    name: 'Cricket bat ball'
  },
  {
    id: 'croissant',
    name: 'Croissant'
  },
  {
    id: 'crop',
    name: 'crop'
  },
  {
    id: 'crop-simple',
    name: 'Crop simple'
  },
  {
    id: 'cross',
    name: 'Cross'
  },
  {
    id: 'crosshairs',
    name: 'Crosshairs'
  },
  {
    id: 'crow',
    name: 'Crow'
  },
  {
    id: 'crown',
    name: 'Crown'
  },
  {
    id: 'crutch',
    name: 'Crutch'
  },
  {
    id: 'crutches',
    name: 'Crutches'
  },
  {
    id: 'cruzeiro-sign',
    name: 'Cruzeiro Sign'
  },
  {
    id: 'crystal-ball',
    name: 'Crystal Ball'
  },
  {
    id: 'cube',
    name: 'Cube'
  },
  {
    id: 'cubes',
    name: 'Cubes'
  },
  {
    id: 'cup-straw',
    name: 'Cup Straw'
  },
  {
    id: 'cup-straw-swoosh',
    name: 'Cup Straw with Swoosh'
  },
  {
    id: 'cup-togo',
    name: 'Cup togo'
  },
  {
    id: 'curling-stone',
    name: 'Curling stone'
  },
  {
    id: 'd',
    name: 'D'
  },
  {
    id: 'dagger',
    name: 'Dagger'
  },
  {
    id: 'dash',
    name: 'Dash'
  },
  {
    id: 'database',
    name: 'Database'
  },
  {
    id: 'deer',
    name: 'Deer'
  },
  {
    id: 'deer-rudolph',
    name: 'Deer Rudolph'
  },
  {
    id: 'delete-left',
    name: 'Delete left'
  },
  {
    id: 'delete-right',
    name: 'Delete Right'
  },
  {
    id: 'democrat',
    name: 'Democrat'
  },
  {
    id: 'desktop',
    name: 'Desktop'
  },
  {
    id: 'desktop-arrow-down',
    name: 'Desktop Arrow-down'
  },
  {
    id: 'dharmachakra',
    name: 'Dharmachakra'
  },
  {
    id: 'diagram-lean-canvas',
    name: 'Diagram Lean-canvas'
  },
  {
    id: 'diagram-nested',
    name: 'Diagram Nested'
  },
  {
    id: 'diagram-project',
    name: 'Project Diagram'
  },
  {
    id: 'diagram-sankey',
    name: 'Diagram Sankey'
  },
  {
    id: 'diagram-venn',
    name: 'Venn Diagram'
  },
  {
    id: 'dial',
    name: 'Dial'
  },
  {
    id: 'dial-high',
    name: 'Dial High'
  },
  {
    id: 'dial-low',
    name: 'Dial Low'
  },
  {
    id: 'dial-max',
    name: 'Dial Max'
  },
  {
    id: 'dial-med',
    name: 'Dial Med'
  },
  {
    id: 'dial-med-low',
    name: 'Dial Med-low'
  },
  {
    id: 'dial-min',
    name: 'Dial Min'
  },
  {
    id: 'dial-off',
    name: 'Dial Off'
  },
  {
    id: 'diamond',
    name: 'Diamond'
  },
  {
    id: 'diamond-turn-right',
    name: 'Diamond turn right'
  },
  {
    id: 'dice',
    name: 'Dice'
  },
  {
    id: 'dice-d10',
    name: 'Dice D10'
  },
  {
    id: 'dice-d12',
    name: 'Dice D12'
  },
  {
    id: 'dice-d20',
    name: 'Dice D20'
  },
  {
    id: 'dice-d4',
    name: 'Dice D4'
  },
  {
    id: 'dice-d6',
    name: 'Dice D6'
  },
  {
    id: 'dice-d8',
    name: 'Dice D8'
  },
  {
    id: 'dice-five',
    name: 'Dice Five'
  },
  {
    id: 'dice-four',
    name: 'Dice Four'
  },
  {
    id: 'dice-one',
    name: 'Dice One'
  },
  {
    id: 'dice-six',
    name: 'Dice Six'
  },
  {
    id: 'dice-three',
    name: 'Dice Three'
  },
  {
    id: 'dice-two',
    name: 'Dice Two'
  },
  {
    id: 'diploma',
    name: 'Diploma'
  },
  {
    id: 'disc-drive',
    name: 'Disc Drive'
  },
  {
    id: 'disease',
    name: 'Disease'
  },
  {
    id: 'display',
    name: 'Display'
  },
  {
    id: 'display-arrow-down',
    name: 'Display Arrow-down'
  },
  {
    id: 'display-code',
    name: 'Display code'
  },
  {
    id: 'display-medical',
    name: 'Display medical'
  },
  {
    id: 'display-slash',
    name: 'Display slash'
  },
  {
    id: 'distribute-spacing-horizontal',
    name: 'Distribute Spacing Horizontal'
  },
  {
    id: 'distribute-spacing-vertical',
    name: 'Distribute Spacing Vertical'
  },
  {
    id: 'ditto',
    name: 'Ditto'
  },
  {
    id: 'divide',
    name: 'Divide'
  },
  {
    id: 'dna',
    name: 'DNA'
  },
  {
    id: 'do-not-enter',
    name: 'Do Not-enter'
  },
  {
    id: 'dog',
    name: 'Dog'
  },
  {
    id: 'dog-leashed',
    name: 'Leashed Dog'
  },
  {
    id: 'dollar-sign',
    name: 'Dollar Sign'
  },
  {
    id: 'dolly',
    name: 'Dolly'
  },
  {
    id: 'dolly-empty',
    name: 'Dolly Empty'
  },
  {
    id: 'dolphin',
    name: 'Dolphin'
  },
  {
    id: 'dong-sign',
    name: 'Dong Sign'
  },
  {
    id: 'door-closed',
    name: 'Door Closed'
  },
  {
    id: 'door-open',
    name: 'Door Open'
  },
  {
    id: 'dove',
    name: 'Dove'
  },
  {
    id: 'down',
    name: 'Down'
  },
  {
    id: 'down-from-line',
    name: 'Down from line'
  },
  {
    id: 'down-left',
    name: 'Down Left'
  },
  {
    id: 'down-left-and-up-right-to-center',
    name: 'Down left and up right to center'
  },
  {
    id: 'down-long',
    name: 'Down long'
  },
  {
    id: 'down-right',
    name: 'Down Right'
  },
  {
    id: 'down-to-line',
    name: 'Down to line'
  },
  {
    id: 'download',
    name: 'Download'
  },
  {
    id: 'dragon',
    name: 'Dragon'
  },
  {
    id: 'draw-circle',
    name: 'Draw Circle'
  },
  {
    id: 'draw-polygon',
    name: 'Draw Polygon'
  },
  {
    id: 'draw-square',
    name: 'Draw Square'
  },
  {
    id: 'dreidel',
    name: 'Dreidel'
  },
  {
    id: 'drone',
    name: 'Drone'
  },
  {
    id: 'drone-front',
    name: 'Drone front'
  },
  {
    id: 'droplet',
    name: 'Droplet'
  },
  {
    id: 'droplet-degree',
    name: 'Droplet degree'
  },
  {
    id: 'droplet-percent',
    name: 'Droplet percent'
  },
  {
    id: 'droplet-slash',
    name: 'Droplet slash'
  },
  {
    id: 'drum',
    name: 'Drum'
  },
  {
    id: 'drum-steelpan',
    name: 'Drum Steelpan'
  },
  {
    id: 'drumstick',
    name: 'Drumstick'
  },
  {
    id: 'drumstick-bite',
    name: 'Drumstick with Bite Taken Out'
  },
  {
    id: 'dryer',
    name: 'Dryer'
  },
  {
    id: 'dryer-heat',
    name: 'Dryer heat'
  },
  {
    id: 'duck',
    name: 'Duck'
  },
  {
    id: 'dumbbell',
    name: 'Dumbbell'
  },
  {
    id: 'dumpster',
    name: 'Dumpster'
  },
  {
    id: 'dumpster-fire',
    name: 'Dumpster Fire'
  },
  {
    id: 'dungeon',
    name: 'Dungeon'
  },
  {
    id: 'e',
    name: 'E'
  },
  {
    id: 'ear',
    name: 'Ear'
  },
  {
    id: 'ear-deaf',
    name: 'Ear deaf'
  },
  {
    id: 'ear-listen',
    name: 'Ear listen'
  },
  {
    id: 'ear-muffs',
    name: 'Ear Muffs'
  },
  {
    id: 'earth-africa',
    name: 'Earth africa'
  },
  {
    id: 'earth-americas',
    name: 'Earth americas'
  },
  {
    id: 'earth-asia',
    name: 'Earth asia'
  },
  {
    id: 'earth-europa',
    name: 'Earth europa'
  },
  {
    id: 'eclipse',
    name: 'Eclipse'
  },
  {
    id: 'egg',
    name: 'Egg'
  },
  {
    id: 'egg-fried',
    name: 'Fried Egg'
  },
  {
    id: 'eggplant',
    name: 'Eggplant'
  },
  {
    id: 'eject',
    name: 'eject'
  },
  {
    id: 'elephant',
    name: 'Elephant'
  },
  {
    id: 'elevator',
    name: 'Elevator'
  },
  {
    id: 'ellipsis',
    name: 'Ellipsis'
  },
  {
    id: 'ellipsis-stroke',
    name: 'Ellipsis stroke'
  },
  {
    id: 'ellipsis-stroke-vertical',
    name: 'Ellipsis stroke vertical'
  },
  {
    id: 'ellipsis-vertical',
    name: 'Ellipsis vertical'
  },
  {
    id: 'empty-set',
    name: 'Empty Set'
  },
  {
    id: 'engine',
    name: 'Engine'
  },
  {
    id: 'engine-warning',
    name: 'Engine Warning'
  },
  {
    id: 'envelope',
    name: 'Envelope'
  },
  {
    id: 'envelope-dot',
    name: 'Envelope dot'
  },
  {
    id: 'envelope-open',
    name: 'Envelope Open'
  },
  {
    id: 'envelope-open-dollar',
    name: 'Envelope Open-dollar'
  },
  {
    id: 'envelope-open-text',
    name: 'Envelope Open-text'
  },
  {
    id: 'envelopes',
    name: 'Envelopes'
  },
  {
    id: 'envelopes-bulk',
    name: 'Envelopes bulk'
  },
  {
    id: 'equals',
    name: 'Equals'
  },
  {
    id: 'eraser',
    name: 'eraser'
  },
  {
    id: 'escalator',
    name: 'Escalator'
  },
  {
    id: 'ethernet',
    name: 'Ethernet'
  },
  {
    id: 'euro-sign',
    name: 'Euro Sign'
  },
  {
    id: 'exclamation',
    name: 'exclamation'
  },
  {
    id: 'expand',
    name: 'Expand'
  },
  {
    id: 'expand-wide',
    name: 'Expand Wide'
  },
  {
    id: 'eye',
    name: 'Eye'
  },
  {
    id: 'eye-dropper',
    name: 'Eye Dropper'
  },
  {
    id: 'eye-dropper-full',
    name: 'Eye Dropper-full'
  },
  {
    id: 'eye-dropper-half',
    name: 'Eye Dropper-half'
  },
  {
    id: 'eye-evil',
    name: 'Evil Eye'
  },
  {
    id: 'eye-low-vision',
    name: 'Eye low vision'
  },
  {
    id: 'eye-slash',
    name: 'Eye Slash'
  },
  {
    id: 'eyes',
    name: 'Eyes'
  },
  {
    id: 'f',
    name: 'F'
  },
  {
    id: 'face-angry',
    name: 'Face angry'
  },
  {
    id: 'face-angry-horns',
    name: 'Face Angry Horns'
  },
  {
    id: 'face-anguished',
    name: 'Face Anguished'
  },
  {
    id: 'face-anxious-sweat',
    name: 'Face Anxious Sweat'
  },
  {
    id: 'face-astonished',
    name: 'Face Astonished'
  },
  {
    id: 'face-confounded',
    name: 'Face Confounded'
  },
  {
    id: 'face-confused',
    name: 'Face Confused'
  },
  {
    id: 'face-cowboy-hat',
    name: 'Face Cowboy Hat'
  },
  {
    id: 'face-disappointed',
    name: 'Face Disappointed'
  },
  {
    id: 'face-disguise',
    name: 'Face Disguise'
  },
  {
    id: 'face-dizzy',
    name: 'Face dizzy'
  },
  {
    id: 'face-downcast-sweat',
    name: 'Face Downcast Sweat'
  },
  {
    id: 'face-drooling',
    name: 'Face Drooling'
  },
  {
    id: 'face-explode',
    name: 'Face Explode'
  },
  {
    id: 'face-expressionless',
    name: 'Face Expressionless'
  },
  {
    id: 'face-eyes-xmarks',
    name: 'Face Eyes Xmarks'
  },
  {
    id: 'face-fearful',
    name: 'Face Fearful'
  },
  {
    id: 'face-flushed',
    name: 'Face flushed'
  },
  {
    id: 'face-frown',
    name: 'Face frown'
  },
  {
    id: 'face-frown-open',
    name: 'Face frown open'
  },
  {
    id: 'face-frown-slight',
    name: 'Face Frown Slight'
  },
  {
    id: 'face-glasses',
    name: 'Face Glasses and Smile'
  },
  {
    id: 'face-grimace',
    name: 'Face grimace'
  },
  {
    id: 'face-grin',
    name: 'Face grin'
  },
  {
    id: 'face-grin-beam',
    name: 'Face grin beam'
  },
  {
    id: 'face-grin-beam-sweat',
    name: 'Face grin beam sweat'
  },
  {
    id: 'face-grin-hearts',
    name: 'Face grin hearts'
  },
  {
    id: 'face-grin-squint',
    name: 'Face grin squint'
  },
  {
    id: 'face-grin-squint-tears',
    name: 'Face grin squint tears'
  },
  {
    id: 'face-grin-stars',
    name: 'Face grin stars'
  },
  {
    id: 'face-grin-tears',
    name: 'Face grin tears'
  },
  {
    id: 'face-grin-tongue',
    name: 'Face grin tongue'
  },
  {
    id: 'face-grin-tongue-squint',
    name: 'Face grin tongue squint'
  },
  {
    id: 'face-grin-tongue-wink',
    name: 'Face grin tongue wink'
  },
  {
    id: 'face-grin-wide',
    name: 'Face grin wide'
  },
  {
    id: 'face-grin-wink',
    name: 'Face grin wink'
  },
  {
    id: 'face-hand-over-mouth',
    name: 'Face with Hand over Mouth'
  },
  {
    id: 'face-hand-yawn',
    name: 'Face Hand Yawn'
  },
  {
    id: 'face-head-bandage',
    name: 'Face Head Bandage'
  },
  {
    id: 'face-hushed',
    name: 'Face Hushed'
  },
  {
    id: 'face-icicles',
    name: 'Face Icicles'
  },
  {
    id: 'face-kiss',
    name: 'Face kiss'
  },
  {
    id: 'face-kiss-beam',
    name: 'Face Kiss Beam'
  },
  {
    id: 'face-kiss-closed-eyes',
    name: 'Face Kiss Closed Eyes'
  },
  {
    id: 'face-kiss-wink-heart',
    name: 'Face Kiss Wink Heart'
  },
  {
    id: 'face-laugh',
    name: 'Face Laugh'
  },
  {
    id: 'face-laugh-beam',
    name: 'Face Laugh Beam'
  },
  {
    id: 'face-laugh-squint',
    name: 'Face Laugh Squint'
  },
  {
    id: 'face-laugh-wink',
    name: 'Face Laugh Wink'
  },
  {
    id: 'face-lying',
    name: 'Face Lying'
  },
  {
    id: 'face-mask',
    name: 'Face Mask'
  },
  {
    id: 'face-meh',
    name: 'Face meh'
  },
  {
    id: 'face-meh-blank',
    name: 'Face Meh Blank'
  },
  {
    id: 'face-monocle',
    name: 'Face Monocle'
  },
  {
    id: 'face-nauseated',
    name: 'Face Nauseated'
  },
  {
    id: 'face-nose-steam',
    name: 'Face Nose Steam'
  },
  {
    id: 'face-party',
    name: 'Face Party'
  },
  {
    id: 'face-pensive',
    name: 'Face Pensive'
  },
  {
    id: 'face-persevering',
    name: 'Face Persevering'
  },
  {
    id: 'face-pleading',
    name: 'Face Pleading'
  },
  {
    id: 'face-pouting',
    name: 'Face Pouting'
  },
  {
    id: 'face-raised-eyebrow',
    name: 'Face Raised Eyebrow'
  },
  {
    id: 'face-relieved',
    name: 'Face Relieved'
  },
  {
    id: 'face-rolling-eyes',
    name: 'Face Rolling Eyes'
  },
  {
    id: 'face-sad-cry',
    name: 'Face Sad Cry'
  },
  {
    id: 'face-sad-sweat',
    name: 'Face Sad Sweat'
  },
  {
    id: 'face-sad-tear',
    name: 'Face Sad Tear'
  },
  {
    id: 'face-scream',
    name: 'Face Scream'
  },
  {
    id: 'face-shush',
    name: 'Face Shush'
  },
  {
    id: 'face-sleeping',
    name: 'Face Sleeping'
  },
  {
    id: 'face-sleepy',
    name: 'Face Sleepy'
  },
  {
    id: 'face-smile',
    name: 'Face Smile'
  },
  {
    id: 'face-smile-beam',
    name: 'Face Smile Beam'
  },
  {
    id: 'face-smile-halo',
    name: 'Face Smile Halo'
  },
  {
    id: 'face-smile-hearts',
    name: 'Face Smile Hearts'
  },
  {
    id: 'face-smile-horns',
    name: 'Face Smile Horns'
  },
  {
    id: 'face-smile-plus',
    name: 'Face Smile Plus'
  },
  {
    id: 'face-smile-relaxed',
    name: 'Face Smile Relaxed'
  },
  {
    id: 'face-smile-tear',
    name: 'Face Smile Tear'
  },
  {
    id: 'face-smile-tongue',
    name: 'Face Smile Tongue'
  },
  {
    id: 'face-smile-upside-down',
    name: 'Face Smile Upside Down'
  },
  {
    id: 'face-smile-wink',
    name: 'Face Smile Wink'
  },
  {
    id: 'face-smiling-hands',
    name: 'Face Smiling Hands'
  },
  {
    id: 'face-smirking',
    name: 'Face Smirking'
  },
  {
    id: 'face-sunglasses',
    name: 'Face Sunglasses'
  },
  {
    id: 'face-surprise',
    name: 'Face Surprise'
  },
  {
    id: 'face-swear',
    name: 'Face Swear'
  },
  {
    id: 'face-thermometer',
    name: 'Face Thermometer'
  },
  {
    id: 'face-thinking',
    name: 'Face Thinking'
  },
  {
    id: 'face-tired',
    name: 'Face Tired'
  },
  {
    id: 'face-tissue',
    name: 'Face Tissue'
  },
  {
    id: 'face-tongue-money',
    name: 'Face Tongue Money'
  },
  {
    id: 'face-tongue-sweat',
    name: 'Face Tongue Sweat'
  },
  {
    id: 'face-unamused',
    name: 'Face Unamused'
  },
  {
    id: 'face-viewfinder',
    name: 'Face Viewfinder'
  },
  {
    id: 'face-vomit',
    name: 'Face Vomit'
  },
  {
    id: 'face-weary',
    name: 'Face Weary'
  },
  {
    id: 'face-woozy',
    name: 'Face Woozy'
  },
  {
    id: 'face-worried',
    name: 'Face Worried'
  },
  {
    id: 'face-zany',
    name: 'Face Zany'
  },
  {
    id: 'face-zipper',
    name: 'Face Zipper'
  },
  {
    id: 'family',
    name: 'Family'
  },
  {
    id: 'family-dress',
    name: 'Family Dress'
  },
  {
    id: 'family-pants',
    name: 'Family Pants'
  },
  {
    id: 'fan',
    name: 'Fan'
  },
  {
    id: 'fan-table',
    name: 'Fan Table'
  },
  {
    id: 'farm',
    name: 'Farm'
  },
  {
    id: 'faucet',
    name: 'Faucet'
  },
  {
    id: 'faucet-drip',
    name: 'Faucet Drip'
  },
  {
    id: 'fax',
    name: 'Fax'
  },
  {
    id: 'feather',
    name: 'Feather'
  },
  {
    id: 'feather-pointed',
    name: 'Feather pointed'
  },
  {
    id: 'fence',
    name: 'Fence'
  },
  {
    id: 'ferris-wheel',
    name: 'Ferris Wheel'
  },
  {
    id: 'field-hockey-stick-ball',
    name: 'Field hockey stick ball'
  },
  {
    id: 'file',
    name: 'File'
  },
  {
    id: 'file-arrow-down',
    name: 'File arrow down'
  },
  {
    id: 'file-arrow-up',
    name: 'File arrow up'
  },
  {
    id: 'file-audio',
    name: 'Audio File'
  },
  {
    id: 'file-binary',
    name: 'File Binary'
  },
  {
    id: 'file-certificate',
    name: 'File Certificate'
  },
  {
    id: 'file-chart-column',
    name: 'File chart column'
  },
  {
    id: 'file-chart-pie',
    name: 'Pie Chart File'
  },
  {
    id: 'file-check',
    name: 'Check File'
  },
  {
    id: 'file-code',
    name: 'Code File'
  },
  {
    id: 'file-contract',
    name: 'File Contract'
  },
  {
    id: 'file-csv',
    name: 'File CSV'
  },
  {
    id: 'file-dashed-line',
    name: 'File dashed line'
  },
  {
    id: 'file-excel',
    name: 'Excel File'
  },
  {
    id: 'file-exclamation',
    name: 'Exclamation File'
  },
  {
    id: 'file-export',
    name: 'File Export'
  },
  {
    id: 'file-heart',
    name: 'File Heart'
  },
  {
    id: 'file-image',
    name: 'Image File'
  },
  {
    id: 'file-import',
    name: 'File Import'
  },
  {
    id: 'file-invoice',
    name: 'File Invoice'
  },
  {
    id: 'file-invoice-dollar',
    name: 'File Invoice with US Dollar'
  },
  {
    id: 'file-lines',
    name: 'File lines'
  },
  {
    id: 'file-lock',
    name: 'File Lock'
  },
  {
    id: 'file-magnifying-glass',
    name: 'File magnifying glass'
  },
  {
    id: 'file-medical',
    name: 'Medical File'
  },
  {
    id: 'file-minus',
    name: 'Minus File'
  },
  {
    id: 'file-music',
    name: 'File Music'
  },
  {
    id: 'file-pdf',
    name: 'PDF File'
  },
  {
    id: 'file-pen',
    name: 'File pen'
  },
  {
    id: 'file-plus',
    name: 'Plus File'
  },
  {
    id: 'file-plus-minus',
    name: 'File Plus Minus'
  },
  {
    id: 'file-powerpoint',
    name: 'Powerpoint File'
  },
  {
    id: 'file-prescription',
    name: 'File Prescription'
  },
  {
    id: 'file-signature',
    name: 'File Signature'
  },
  {
    id: 'file-slash',
    name: 'File Slash'
  },
  {
    id: 'file-spreadsheet',
    name: 'Spreadsheet File'
  },
  {
    id: 'file-user',
    name: 'User File'
  },
  {
    id: 'file-video',
    name: 'Video File'
  },
  {
    id: 'file-waveform',
    name: 'File waveform'
  },
  {
    id: 'file-word',
    name: 'Word File'
  },
  {
    id: 'file-xmark',
    name: 'File X Mark'
  },
  {
    id: 'file-zipper',
    name: 'File zipper'
  },
  {
    id: 'files',
    name: 'Files'
  },
  {
    id: 'files-medical',
    name: 'Medical Files'
  },
  {
    id: 'fill',
    name: 'Fill'
  },
  {
    id: 'fill-drip',
    name: 'Fill Drip'
  },
  {
    id: 'film',
    name: 'Film'
  },
  {
    id: 'film-canister',
    name: 'Film Canister'
  },
  {
    id: 'film-simple',
    name: 'Film simple'
  },
  {
    id: 'film-slash',
    name: 'Film Slash'
  },
  {
    id: 'films',
    name: 'Films'
  },
  {
    id: 'filter',
    name: 'Filter'
  },
  {
    id: 'filter-circle-dollar',
    name: 'Filter Circle Dollar'
  },
  {
    id: 'filter-circle-xmark',
    name: 'Filter Circle X Mark'
  },
  {
    id: 'filter-list',
    name: 'Filter List'
  },
  {
    id: 'filter-slash',
    name: 'Filter Slash'
  },
  {
    id: 'filters',
    name: 'Filters'
  },
  {
    id: 'fingerprint',
    name: 'Fingerprint'
  },
  {
    id: 'fire',
    name: 'fire'
  },
  {
    id: 'fire-extinguisher',
    name: 'fire-extinguisher'
  },
  {
    id: 'fire-flame',
    name: 'Fire flame'
  },
  {
    id: 'fire-flame-curved',
    name: 'Fire flame curved'
  },
  {
    id: 'fire-flame-simple',
    name: 'Fire flame simple'
  },
  {
    id: 'fire-hydrant',
    name: 'Fire Hydrant'
  },
  {
    id: 'fire-smoke',
    name: 'Fire and Smoke'
  },
  {
    id: 'fireplace',
    name: 'Fireplace'
  },
  {
    id: 'fish',
    name: 'Fish'
  },
  {
    id: 'fish-bones',
    name: 'Fish Bones'
  },
  {
    id: 'fish-cooked',
    name: 'Cooked Fish'
  },
  {
    id: 'fishing-rod',
    name: 'Fishing Rod'
  },
  {
    id: 'flag',
    name: 'flag'
  },
  {
    id: 'flag-checkered',
    name: 'flag-checkered'
  },
  {
    id: 'flag-pennant',
    name: 'Flag pennant'
  },
  {
    id: 'flag-swallowtail',
    name: 'Flag swallowtail'
  },
  {
    id: 'flag-usa',
    name: 'United States of America Flag'
  },
  {
    id: 'flashlight',
    name: 'Flashlight'
  },
  {
    id: 'flask',
    name: 'Flask'
  },
  {
    id: 'flask-round-poison',
    name: 'Flask round poison'
  },
  {
    id: 'flask-round-potion',
    name: 'Flask round potion'
  },
  {
    id: 'floppy-disk',
    name: 'Floppy Disk'
  },
  {
    id: 'floppy-disk-circle-arrow-right',
    name: 'Floppy Disk Arrow Right'
  },
  {
    id: 'floppy-disk-circle-xmark',
    name: 'Floppy Disk X Mark'
  },
  {
    id: 'floppy-disk-pen',
    name: 'Floppy Disk-pen'
  },
  {
    id: 'floppy-disks',
    name: 'Floppy Disks'
  },
  {
    id: 'florin-sign',
    name: 'Florin Sign'
  },
  {
    id: 'flower',
    name: 'Flower'
  },
  {
    id: 'flower-daffodil',
    name: 'Flower Daffodil'
  },
  {
    id: 'flower-tulip',
    name: 'Flower Tulip'
  },
  {
    id: 'flute',
    name: 'Flute'
  },
  {
    id: 'flux-capacitor',
    name: 'Flux Capacitor'
  },
  {
    id: 'flying-disc',
    name: 'Flying Disc'
  },
  {
    id: 'folder',
    name: 'Folder'
  },
  {
    id: 'folder-arrow-down',
    name: 'Folder arrow down'
  },
  {
    id: 'folder-arrow-up',
    name: 'Folder arrow up'
  },
  {
    id: 'folder-blank',
    name: 'Folder Blank'
  },
  {
    id: 'folder-bookmark',
    name: 'Folder Bookmark'
  },
  {
    id: 'folder-gear',
    name: 'Folder gear'
  },
  {
    id: 'folder-grid',
    name: 'Folder Grid'
  },
  {
    id: 'folder-heart',
    name: 'Folder Heart'
  },
  {
    id: 'folder-image',
    name: 'Folder Image'
  },
  {
    id: 'folder-magnifying-glass',
    name: 'Folder magnifying glass'
  },
  {
    id: 'folder-medical',
    name: 'Folder Medical'
  },
  {
    id: 'folder-minus',
    name: 'Folder Minus'
  },
  {
    id: 'folder-music',
    name: 'Folder Music'
  },
  {
    id: 'folder-open',
    name: 'Folder Open'
  },
  {
    id: 'folder-plus',
    name: 'Folder Plus'
  },
  {
    id: 'folder-tree',
    name: 'Folder Tree'
  },
  {
    id: 'folder-user',
    name: 'Folder User'
  },
  {
    id: 'folder-xmark',
    name: 'Folder X Mark'
  },
  {
    id: 'folders',
    name: 'Folders'
  },
  {
    id: 'font',
    name: 'font'
  },
  {
    id: 'font-awesome',
    name: 'Font Awesome'
  },
  {
    id: 'font-case',
    name: 'Font Case'
  },
  {
    id: 'football',
    name: 'Football Ball'
  },
  {
    id: 'football-helmet',
    name: 'Football Helmet'
  },
  {
    id: 'fork',
    name: 'Fork'
  },
  {
    id: 'fork-knife',
    name: 'Fork knife'
  },
  {
    id: 'forklift',
    name: 'Forklift'
  },
  {
    id: 'forward',
    name: 'forward'
  },
  {
    id: 'forward-fast',
    name: 'Forward fast'
  },
  {
    id: 'forward-step',
    name: 'Forward step'
  },
  {
    id: 'franc-sign',
    name: 'Franc Sign'
  },
  {
    id: 'french-fries',
    name: 'French Fries'
  },
  {
    id: 'frog',
    name: 'Frog'
  },
  {
    id: 'function',
    name: 'Function'
  },
  {
    id: 'futbol',
    name: 'Futbol ball'
  },
  {
    id: 'g',
    name: 'G'
  },
  {
    id: 'galaxy',
    name: 'Galaxy'
  },
  {
    id: 'gallery-thumbnails',
    name: 'Gallery Thumbnails'
  },
  {
    id: 'game-board',
    name: 'Game Board'
  },
  {
    id: 'game-board-simple',
    name: 'Game board simple'
  },
  {
    id: 'game-console-handheld',
    name: 'Handheld Game Console'
  },
  {
    id: 'gamepad',
    name: 'Gamepad'
  },
  {
    id: 'gamepad-modern',
    name: 'Gamepad modern'
  },
  {
    id: 'garage',
    name: 'Garage'
  },
  {
    id: 'garage-car',
    name: 'Garage Car'
  },
  {
    id: 'garage-open',
    name: 'Garage Open'
  },
  {
    id: 'gas-pump',
    name: 'Gas Pump'
  },
  {
    id: 'gas-pump-slash',
    name: 'Gas Pump Slash'
  },
  {
    id: 'gauge',
    name: 'Gauge'
  },
  {
    id: 'gauge-low',
    name: 'Gauge low'
  },
  {
    id: 'gauge-max',
    name: 'Gauge max'
  },
  {
    id: 'gauge-med',
    name: 'Gauge med'
  },
  {
    id: 'gauge-min',
    name: 'Gauge min'
  },
  {
    id: 'gauge-simple',
    name: 'Gauge simple'
  },
  {
    id: 'gauge-simple-low',
    name: 'Gauge simple low'
  },
  {
    id: 'gauge-simple-max',
    name: 'Gauge simple max'
  },
  {
    id: 'gauge-simple-med',
    name: 'Gauge simple med'
  },
  {
    id: 'gauge-simple-min',
    name: 'Gauge simple min'
  },
  {
    id: 'gavel',
    name: 'Gavel'
  },
  {
    id: 'gear',
    name: 'Gear'
  },
  {
    id: 'gears',
    name: 'Gears'
  },
  {
    id: 'gem',
    name: 'Gem'
  },
  {
    id: 'genderless',
    name: 'Genderless'
  },
  {
    id: 'ghost',
    name: 'Ghost'
  },
  {
    id: 'gif',
    name: 'Gif'
  },
  {
    id: 'gift',
    name: 'gift'
  },
  {
    id: 'gift-card',
    name: 'Gift Card'
  },
  {
    id: 'gifts',
    name: 'Gifts'
  },
  {
    id: 'gingerbread-man',
    name: 'Gingerbread Man'
  },
  {
    id: 'glass',
    name: 'Glass'
  },
  {
    id: 'glass-citrus',
    name: 'Glass Citrus'
  },
  {
    id: 'glass-empty',
    name: 'Glass Empty'
  },
  {
    id: 'glass-half',
    name: 'Glass Half'
  },
  {
    id: 'glasses',
    name: 'Glasses'
  },
  {
    id: 'glasses-round',
    name: 'Glasses round'
  },
  {
    id: 'globe',
    name: 'Globe'
  },
  {
    id: 'globe-snow',
    name: 'Globe Snow'
  },
  {
    id: 'globe-stand',
    name: 'Globe Stand'
  },
  {
    id: 'goal-net',
    name: 'Goal Net'
  },
  {
    id: 'golf-ball-tee',
    name: 'Golf ball tee'
  },
  {
    id: 'golf-club',
    name: 'Golf Club'
  },
  {
    id: 'golf-flag-hole',
    name: 'Golf Flag + Hole'
  },
  {
    id: 'gopuram',
    name: 'Gopuram'
  },
  {
    id: 'graduation-cap',
    name: 'Graduation Cap'
  },
  {
    id: 'gramophone',
    name: 'Gramophone'
  },
  {
    id: 'grapes',
    name: 'Grapes'
  },
  {
    id: 'grate',
    name: 'Grate'
  },
  {
    id: 'grate-droplet',
    name: 'Grate Droplet'
  },
  {
    id: 'greater-than',
    name: 'Greater Than'
  },
  {
    id: 'greater-than-equal',
    name: 'Greater Than Equal To'
  },
  {
    id: 'grid',
    name: 'Grid'
  },
  {
    id: 'grid-2',
    name: 'Grid 2'
  },
  {
    id: 'grid-2-plus',
    name: 'Grid 2-plus'
  },
  {
    id: 'grid-4',
    name: 'Grid 4'
  },
  {
    id: 'grid-5',
    name: 'Grid 5'
  },
  {
    id: 'grid-dividers',
    name: 'Grid Dividers'
  },
  {
    id: 'grid-horizontal',
    name: 'Grid Horizontal'
  },
  {
    id: 'grip',
    name: 'Grip'
  },
  {
    id: 'grip-lines',
    name: 'Grip Lines'
  },
  {
    id: 'grip-lines-vertical',
    name: 'Grip Lines Vertical'
  },
  {
    id: 'grip-vertical',
    name: 'Grip Vertical'
  },
  {
    id: 'guarani-sign',
    name: 'Guarani Sign'
  },
  {
    id: 'guitar',
    name: 'Guitar'
  },
  {
    id: 'guitar-electric',
    name: 'Guitar Electric'
  },
  {
    id: 'guitars',
    name: 'Guitars'
  },
  {
    id: 'gun',
    name: 'Gun'
  },
  {
    id: 'gun-slash',
    name: 'Gun Slash'
  },
  {
    id: 'gun-squirt',
    name: 'Squirt Gun'
  },
  {
    id: 'h',
    name: 'H'
  },
  {
    id: 'h1',
    name: 'H1 Heading'
  },
  {
    id: 'h2',
    name: 'H2 Heading'
  },
  {
    id: 'h3',
    name: 'H3 Heading'
  },
  {
    id: 'h4',
    name: 'H4'
  },
  {
    id: 'hammer',
    name: 'Hammer'
  },
  {
    id: 'hammer-war',
    name: 'Hammer War'
  },
  {
    id: 'hamsa',
    name: 'Hamsa'
  },
  {
    id: 'hand',
    name: 'Paper (Hand)'
  },
  {
    id: 'hand-back-fist',
    name: 'Rock (Hand)'
  },
  {
    id: 'hand-back-point-down',
    name: 'Hand Back-point-down'
  },
  {
    id: 'hand-back-point-left',
    name: 'Hand Back-point-left'
  },
  {
    id: 'hand-back-point-ribbon',
    name: 'Hand Back-point-ribbon'
  },
  {
    id: 'hand-back-point-right',
    name: 'Hand Back-point-right'
  },
  {
    id: 'hand-back-point-up',
    name: 'Hand Back-point-up'
  },
  {
    id: 'hand-dots',
    name: 'Hand dots'
  },
  {
    id: 'hand-fingers-crossed',
    name: 'Hand Fingers-crossed'
  },
  {
    id: 'hand-fist',
    name: 'Raised Fist'
  },
  {
    id: 'hand-heart',
    name: 'Hand with Heart'
  },
  {
    id: 'hand-holding',
    name: 'Hand Holding'
  },
  {
    id: 'hand-holding-box',
    name: 'Hand Holding Box'
  },
  {
    id: 'hand-holding-dollar',
    name: 'Hand holding dollar'
  },
  {
    id: 'hand-holding-droplet',
    name: 'Hand holding droplet'
  },
  {
    id: 'hand-holding-heart',
    name: 'Hand Holding Heart'
  },
  {
    id: 'hand-holding-magic',
    name: 'Hand Holding-magic'
  },
  {
    id: 'hand-holding-medical',
    name: 'Hand Holding Medical Cross'
  },
  {
    id: 'hand-holding-seedling',
    name: 'Hand Holding Seedling'
  },
  {
    id: 'hand-holding-skull',
    name: 'Hand Holding a Skull'
  },
  {
    id: 'hand-horns',
    name: 'Hands Horns'
  },
  {
    id: 'hand-lizard',
    name: 'Lizard (Hand)'
  },
  {
    id: 'hand-love',
    name: 'Hand Love'
  },
  {
    id: 'hand-middle-finger',
    name: 'Hand with Middle Finger Raised'
  },
  {
    id: 'hand-peace',
    name: 'Peace (Hand)'
  },
  {
    id: 'hand-point-down',
    name: 'Hand Pointing Down'
  },
  {
    id: 'hand-point-left',
    name: 'Hand Pointing Left'
  },
  {
    id: 'hand-point-ribbon',
    name: 'Hand Point-ribbon'
  },
  {
    id: 'hand-point-right',
    name: 'Hand Pointing Right'
  },
  {
    id: 'hand-point-up',
    name: 'Hand Pointing Up'
  },
  {
    id: 'hand-pointer',
    name: 'Pointer (Hand)'
  },
  {
    id: 'hand-scissors',
    name: 'Scissors (Hand)'
  },
  {
    id: 'hand-sparkles',
    name: 'Hand Sparkles'
  },
  {
    id: 'hand-spock',
    name: 'Spock (Hand)'
  },
  {
    id: 'hand-wave',
    name: 'Hand Wave'
  },
  {
    id: 'hands',
    name: 'Hands'
  },
  {
    id: 'hands-asl-interpreting',
    name: 'Hands american sign language interpreting'
  },
  {
    id: 'hands-bubbles',
    name: 'Hands bubbles'
  },
  {
    id: 'hands-clapping',
    name: 'Hands Clapping'
  },
  {
    id: 'hands-holding',
    name: 'Hands holding'
  },
  {
    id: 'hands-holding-diamond',
    name: 'Hands holding diamond'
  },
  {
    id: 'hands-holding-dollar',
    name: 'Hands holding dollar'
  },
  {
    id: 'hands-holding-heart',
    name: 'Hands holding heart'
  },
  {
    id: 'hands-praying',
    name: 'Hands praying'
  },
  {
    id: 'handshake',
    name: 'Handshake'
  },
  {
    id: 'handshake-angle',
    name: 'Handshake angle'
  },
  {
    id: 'handshake-simple',
    name: 'Handshake simple'
  },
  {
    id: 'handshake-simple-slash',
    name: 'Handshake simple slash'
  },
  {
    id: 'handshake-slash',
    name: 'Handshake Slash'
  },
  {
    id: 'hanukiah',
    name: 'Hanukiah'
  },
  {
    id: 'hard-drive',
    name: 'Hard drive'
  },
  {
    id: 'hashtag',
    name: 'Hashtag'
  },
  {
    id: 'hat-chef',
    name: 'Chef Hat'
  },
  {
    id: 'hat-cowboy',
    name: 'Cowboy Hat'
  },
  {
    id: 'hat-cowboy-side',
    name: 'Cowboy Hat Side'
  },
  {
    id: 'hat-santa',
    name: 'Santa\'s Hat'
  },
  {
    id: 'hat-winter',
    name: 'Hat Winter'
  },
  {
    id: 'hat-witch',
    name: 'Witch\'s Hat'
  },
  {
    id: 'hat-wizard',
    name: 'Wizard\'s Hat'
  },
  {
    id: 'head-side',
    name: 'Head Side'
  },
  {
    id: 'head-side-brain',
    name: 'Head Side with Brain'
  },
  {
    id: 'head-side-cough',
    name: 'Head Side Cough'
  },
  {
    id: 'head-side-cough-slash',
    name: 'Head Side-cough-slash'
  },
  {
    id: 'head-side-goggles',
    name: 'Head side goggles'
  },
  {
    id: 'head-side-headphones',
    name: 'Head Side with Headphones'
  },
  {
    id: 'head-side-heart',
    name: 'Head Side-heart'
  },
  {
    id: 'head-side-mask',
    name: 'Head Side Mask'
  },
  {
    id: 'head-side-medical',
    name: 'Head Side with Medical Symbol'
  },
  {
    id: 'head-side-virus',
    name: 'Head Side Virus'
  },
  {
    id: 'heading',
    name: 'heading'
  },
  {
    id: 'headphones',
    name: 'headphones'
  },
  {
    id: 'headphones-simple',
    name: 'Headphones simple'
  },
  {
    id: 'headset',
    name: 'Headset'
  },
  {
    id: 'heart',
    name: 'Heart'
  },
  {
    id: 'heart-crack',
    name: 'Heart crack'
  },
  {
    id: 'heart-half',
    name: 'Heart Half'
  },
  {
    id: 'heart-half-stroke',
    name: 'Heart half stroke'
  },
  {
    id: 'heart-pulse',
    name: 'Heart pulse'
  },
  {
    id: 'heat',
    name: 'Heat'
  },
  {
    id: 'helicopter',
    name: 'Helicopter'
  },
  {
    id: 'helmet-battle',
    name: 'Battle Helmet'
  },
  {
    id: 'helmet-safety',
    name: 'Helmet safety'
  },
  {
    id: 'hexagon',
    name: 'Hexagon'
  },
  {
    id: 'hexagon-divide',
    name: 'Hexagon Divide'
  },
  {
    id: 'hexagon-minus',
    name: 'Hexagon minus'
  },
  {
    id: 'hexagon-plus',
    name: 'Hexagon plus'
  },
  {
    id: 'hexagon-xmark',
    name: 'Hexagon X Mark'
  },
  {
    id: 'high-definition',
    name: 'High Definition'
  },
  {
    id: 'highlighter',
    name: 'Highlighter'
  },
  {
    id: 'highlighter-line',
    name: 'Highlighter Line'
  },
  {
    id: 'hippo',
    name: 'Hippo'
  },
  {
    id: 'hockey-mask',
    name: 'Hockey Mask'
  },
  {
    id: 'hockey-puck',
    name: 'Hockey Puck'
  },
  {
    id: 'hockey-stick-puck',
    name: 'Hockey Stick + Puck'
  },
  {
    id: 'hockey-sticks',
    name: 'Hockey Sticks'
  },
  {
    id: 'holly-berry',
    name: 'Holly Berry'
  },
  {
    id: 'hood-cloak',
    name: 'Hood Cloak'
  },
  {
    id: 'horizontal-rule',
    name: 'Horizontal Rule'
  },
  {
    id: 'horse',
    name: 'Horse'
  },
  {
    id: 'horse-head',
    name: 'Horse Head'
  },
  {
    id: 'horse-saddle',
    name: 'Horse Saddle'
  },
  {
    id: 'hospital',
    name: 'hospital'
  },
  {
    id: 'hospital-user',
    name: 'Hospital with User'
  },
  {
    id: 'hospital-wide',
    name: 'Hospital wide'
  },
  {
    id: 'hospitals',
    name: 'Hospitals'
  },
  {
    id: 'hot-tub-person',
    name: 'Hot tub person'
  },
  {
    id: 'hotdog',
    name: 'Hot Dog'
  },
  {
    id: 'hotel',
    name: 'Hotel'
  },
  {
    id: 'hourglass',
    name: 'Hourglass'
  },
  {
    id: 'hourglass-empty',
    name: 'Hourglass empty'
  },
  {
    id: 'hourglass-end',
    name: 'Hourglass End'
  },
  {
    id: 'hourglass-start',
    name: 'Hourglass Start'
  },
  {
    id: 'house',
    name: 'House'
  },
  {
    id: 'house-blank',
    name: 'House Simple Blank'
  },
  {
    id: 'house-building',
    name: 'House Building'
  },
  {
    id: 'house-chimney',
    name: 'House Chimney'
  },
  {
    id: 'house-chimney-blank',
    name: 'House Blank'
  },
  {
    id: 'house-chimney-crack',
    name: 'House crack'
  },
  {
    id: 'house-chimney-heart',
    name: 'House Heart'
  },
  {
    id: 'house-chimney-medical',
    name: 'House medical'
  },
  {
    id: 'house-chimney-user',
    name: 'House User'
  },
  {
    id: 'house-chimney-window',
    name: 'House with Window + Chimney'
  },
  {
    id: 'house-crack',
    name: 'House Simple Crack'
  },
  {
    id: 'house-day',
    name: 'House Day'
  },
  {
    id: 'house-flood',
    name: 'Flooded House'
  },
  {
    id: 'house-heart',
    name: 'House Simple Heart'
  },
  {
    id: 'house-laptop',
    name: 'House laptop'
  },
  {
    id: 'house-medical',
    name: 'House Simple Medical'
  },
  {
    id: 'house-night',
    name: 'House Night'
  },
  {
    id: 'house-person-leave',
    name: 'House person leave'
  },
  {
    id: 'house-person-return',
    name: 'House person return'
  },
  {
    id: 'house-signal',
    name: 'House Signal'
  },
  {
    id: 'house-tree',
    name: 'House Tree'
  },
  {
    id: 'house-turret',
    name: 'House Turret'
  },
  {
    id: 'house-user',
    name: 'Home User'
  },
  {
    id: 'house-window',
    name: 'House Window'
  },
  {
    id: 'hryvnia-sign',
    name: 'Hryvnia sign'
  },
  {
    id: 'hurricane',
    name: 'Hurricane'
  },
  {
    id: 'hyphen',
    name: 'Hyphen'
  },
  {
    id: 'i',
    name: 'I'
  },
  {
    id: 'i-cursor',
    name: 'I Beam Cursor'
  },
  {
    id: 'ice-cream',
    name: 'Ice Cream'
  },
  {
    id: 'ice-skate',
    name: 'Ice Skate'
  },
  {
    id: 'icicles',
    name: 'Icicles'
  },
  {
    id: 'icons',
    name: 'Icons'
  },
  {
    id: 'id-badge',
    name: 'Identification Badge'
  },
  {
    id: 'id-card',
    name: 'Identification Card'
  },
  {
    id: 'id-card-clip',
    name: 'Id card clip'
  },
  {
    id: 'igloo',
    name: 'Igloo'
  },
  {
    id: 'image',
    name: 'Image'
  },
  {
    id: 'image-landscape',
    name: 'Image landscape'
  },
  {
    id: 'image-polaroid',
    name: 'Polaroid Image'
  },
  {
    id: 'image-polaroid-user',
    name: 'Image Polaroid-user'
  },
  {
    id: 'image-portrait',
    name: 'Image portrait'
  },
  {
    id: 'image-slash',
    name: 'Image Slash'
  },
  {
    id: 'image-user',
    name: 'Image User'
  },
  {
    id: 'images',
    name: 'Images'
  },
  {
    id: 'images-user',
    name: 'Images User'
  },
  {
    id: 'inbox',
    name: 'inbox'
  },
  {
    id: 'inbox-full',
    name: 'Inbox Full'
  },
  {
    id: 'inbox-in',
    name: 'Inbox In'
  },
  {
    id: 'inbox-out',
    name: 'Inbox Out'
  },
  {
    id: 'inboxes',
    name: 'Inboxes'
  },
  {
    id: 'indent',
    name: 'Indent'
  },
  {
    id: 'indian-rupee-sign',
    name: 'Indian Rupee-sign'
  },
  {
    id: 'industry',
    name: 'Industry'
  },
  {
    id: 'industry-windows',
    name: 'Industry windows'
  },
  {
    id: 'infinity',
    name: 'Infinity'
  },
  {
    id: 'info',
    name: 'Info'
  },
  {
    id: 'inhaler',
    name: 'Inhaler'
  },
  {
    id: 'input-numeric',
    name: 'Input Numeric'
  },
  {
    id: 'input-pipe',
    name: 'Input Pipe'
  },
  {
    id: 'input-text',
    name: 'Input Text'
  },
  {
    id: 'integral',
    name: 'Integral'
  },
  {
    id: 'intersection',
    name: 'Intersection'
  },
  {
    id: 'island-tropical',
    name: 'Tropical Island'
  },
  {
    id: 'italic',
    name: 'italic'
  },
  {
    id: 'j',
    name: 'J'
  },
  {
    id: 'jack-o-lantern',
    name: 'Jack-o\'-lantern'
  },
  {
    id: 'jedi',
    name: 'Jedi'
  },
  {
    id: 'jet-fighter',
    name: 'Jet fighter'
  },
  {
    id: 'joint',
    name: 'Joint'
  },
  {
    id: 'joystick',
    name: 'Joystick'
  },
  {
    id: 'jug',
    name: 'Jug'
  },
  {
    id: 'k',
    name: 'K'
  },
  {
    id: 'kaaba',
    name: 'Kaaba'
  },
  {
    id: 'kazoo',
    name: 'Kazoo'
  },
  {
    id: 'kerning',
    name: 'Kerning'
  },
  {
    id: 'key',
    name: 'key'
  },
  {
    id: 'key-skeleton',
    name: 'Key Skeleton'
  },
  {
    id: 'key-skeleton-left-right',
    name: 'Key Skeleton Left Right'
  },
  {
    id: 'keyboard',
    name: 'Keyboard'
  },
  {
    id: 'keyboard-brightness',
    name: 'Keyboard Brightness'
  },
  {
    id: 'keyboard-brightness-low',
    name: 'Keyboard Brightness-low'
  },
  {
    id: 'keyboard-down',
    name: 'Keyboard Down'
  },
  {
    id: 'keyboard-left',
    name: 'Keyboard Left'
  },
  {
    id: 'keynote',
    name: 'Keynote'
  },
  {
    id: 'khanda',
    name: 'Khanda'
  },
  {
    id: 'kidneys',
    name: 'Kidneys'
  },
  {
    id: 'kip-sign',
    name: 'Kip Sign'
  },
  {
    id: 'kit-medical',
    name: 'Kit medical'
  },
  {
    id: 'kite',
    name: 'Kite'
  },
  {
    id: 'kiwi-bird',
    name: 'Kiwi Bird'
  },
  {
    id: 'kiwi-fruit',
    name: 'Kiwi Fruit'
  },
  {
    id: 'knife',
    name: 'Knife'
  },
  {
    id: 'knife-kitchen',
    name: 'Knife Kitchen'
  },
  {
    id: 'l',
    name: 'L'
  },
  {
    id: 'lacrosse-stick',
    name: 'Lacrosse Stick'
  },
  {
    id: 'lacrosse-stick-ball',
    name: 'Lacrosse Stick + Ball'
  },
  {
    id: 'lambda',
    name: 'Lambda'
  },
  {
    id: 'lamp',
    name: 'Lamp'
  },
  {
    id: 'lamp-desk',
    name: 'Lamp Desk'
  },
  {
    id: 'lamp-floor',
    name: 'Lamp Floor'
  },
  {
    id: 'lamp-street',
    name: 'Lamp Street'
  },
  {
    id: 'landmark',
    name: 'Landmark'
  },
  {
    id: 'landmark-dome',
    name: 'Landmark dome'
  },
  {
    id: 'language',
    name: 'Language'
  },
  {
    id: 'laptop',
    name: 'Laptop'
  },
  {
    id: 'laptop-arrow-down',
    name: 'Laptop Arrow-down'
  },
  {
    id: 'laptop-code',
    name: 'Laptop Code'
  },
  {
    id: 'laptop-medical',
    name: 'Laptop Medical'
  },
  {
    id: 'laptop-mobile',
    name: 'Laptop mobile'
  },
  {
    id: 'laptop-slash',
    name: 'Laptop Slash'
  },
  {
    id: 'lari-sign',
    name: 'Lari Sign'
  },
  {
    id: 'lasso',
    name: 'Lasso'
  },
  {
    id: 'lasso-sparkles',
    name: 'Lasso Sparkles'
  },
  {
    id: 'layer-group',
    name: 'Layer Group'
  },
  {
    id: 'layer-minus',
    name: 'Layer Minus'
  },
  {
    id: 'layer-plus',
    name: 'Layer Plus'
  },
  {
    id: 'leaf',
    name: 'leaf'
  },
  {
    id: 'leaf-heart',
    name: 'Leaf with a Heart'
  },
  {
    id: 'leaf-maple',
    name: 'Maple Leaf'
  },
  {
    id: 'leaf-oak',
    name: 'Oak Leaf'
  },
  {
    id: 'left',
    name: 'Left'
  },
  {
    id: 'left-from-line',
    name: 'Left from line'
  },
  {
    id: 'left-long',
    name: 'Left long'
  },
  {
    id: 'left-right',
    name: 'Left right'
  },
  {
    id: 'left-to-line',
    name: 'Left to line'
  },
  {
    id: 'lemon',
    name: 'Lemon'
  },
  {
    id: 'less-than',
    name: 'Less Than'
  },
  {
    id: 'less-than-equal',
    name: 'Less Than Equal To'
  },
  {
    id: 'life-ring',
    name: 'Life Ring'
  },
  {
    id: 'light-ceiling',
    name: 'Light Ceiling'
  },
  {
    id: 'light-switch',
    name: 'Light Switch'
  },
  {
    id: 'light-switch-off',
    name: 'Light Switch-off'
  },
  {
    id: 'light-switch-on',
    name: 'Light Switch-on'
  },
  {
    id: 'lightbulb',
    name: 'Lightbulb'
  },
  {
    id: 'lightbulb-dollar',
    name: 'Lightbulb Dollar'
  },
  {
    id: 'lightbulb-exclamation',
    name: 'Lightbulb Exclamation'
  },
  {
    id: 'lightbulb-exclamation-on',
    name: 'Lightbulb Exclamation-on'
  },
  {
    id: 'lightbulb-on',
    name: 'Lightbulb On'
  },
  {
    id: 'lightbulb-slash',
    name: 'Lightbulb Slash'
  },
  {
    id: 'lights-holiday',
    name: 'Holiday Lights'
  },
  {
    id: 'line-columns',
    name: 'Line Columns'
  },
  {
    id: 'line-height',
    name: 'Line Height'
  },
  {
    id: 'link',
    name: 'Link'
  },
  {
    id: 'link-horizontal',
    name: 'Link Horizontal'
  },
  {
    id: 'link-horizontal-slash',
    name: 'Link Horizontal Slash'
  },
  {
    id: 'link-simple',
    name: 'Link Simple'
  },
  {
    id: 'link-simple-slash',
    name: 'Link Simple Slash'
  },
  {
    id: 'link-slash',
    name: 'Link Slash'
  },
  {
    id: 'lips',
    name: 'Lips'
  },
  {
    id: 'lira-sign',
    name: 'Turkish Lira Sign'
  },
  {
    id: 'list',
    name: 'List'
  },
  {
    id: 'list-check',
    name: 'List check'
  },
  {
    id: 'list-dropdown',
    name: 'List Dropdown'
  },
  {
    id: 'list-music',
    name: 'List Music'
  },
  {
    id: 'list-ol',
    name: 'list-ol'
  },
  {
    id: 'list-radio',
    name: 'List Radio'
  },
  {
    id: 'list-timeline',
    name: 'List Timeline'
  },
  {
    id: 'list-tree',
    name: 'List Tree'
  },
  {
    id: 'list-ul',
    name: 'list-ul'
  },
  {
    id: 'litecoin-sign',
    name: 'Litecoin Sign'
  },
  {
    id: 'loader',
    name: 'Loader'
  },
  {
    id: 'location',
    name: 'Location'
  },
  {
    id: 'location-arrow',
    name: 'location-arrow'
  },
  {
    id: 'location-check',
    name: 'Location check'
  },
  {
    id: 'location-crosshairs',
    name: 'Location crosshairs'
  },
  {
    id: 'location-crosshairs-slash',
    name: 'Location crosshairs slash'
  },
  {
    id: 'location-dot',
    name: 'Location dot'
  },
  {
    id: 'location-dot-slash',
    name: 'Location dot slash'
  },
  {
    id: 'location-exclamation',
    name: 'Location exclamation'
  },
  {
    id: 'location-minus',
    name: 'Location minus'
  },
  {
    id: 'location-pen',
    name: 'Location pen'
  },
  {
    id: 'location-plus',
    name: 'Location plus'
  },
  {
    id: 'location-question',
    name: 'Location question'
  },
  {
    id: 'location-slash',
    name: 'Location slash'
  },
  {
    id: 'location-smile',
    name: 'Location smile'
  },
  {
    id: 'location-xmark',
    name: 'Location X Mark'
  },
  {
    id: 'lock',
    name: 'lock'
  },
  {
    id: 'lock-keyhole',
    name: 'Lock keyhole'
  },
  {
    id: 'lock-keyhole-open',
    name: 'Lock keyhole open'
  },
  {
    id: 'lock-open',
    name: 'Lock Open'
  },
  {
    id: 'loveseat',
    name: 'Loveseat'
  },
  {
    id: 'luchador-mask',
    name: 'Luchador mask'
  },
  {
    id: 'lungs',
    name: 'Lungs'
  },
  {
    id: 'lungs-virus',
    name: 'Lungs Virus'
  },
  {
    id: 'm',
    name: 'M'
  },
  {
    id: 'mace',
    name: 'Mace'
  },
  {
    id: 'magnet',
    name: 'magnet'
  },
  {
    id: 'magnifying-glass',
    name: 'Magnifying glass'
  },
  {
    id: 'magnifying-glass-dollar',
    name: 'Magnifying glass dollar'
  },
  {
    id: 'magnifying-glass-location',
    name: 'Magnifying glass location'
  },
  {
    id: 'magnifying-glass-minus',
    name: 'Magnifying glass minus'
  },
  {
    id: 'magnifying-glass-plus',
    name: 'Magnifying glass plus'
  },
  {
    id: 'mailbox',
    name: 'Mailbox'
  },
  {
    id: 'manat-sign',
    name: 'Manat Sign'
  },
  {
    id: 'mandolin',
    name: 'Mandolin'
  },
  {
    id: 'mango',
    name: 'Mango'
  },
  {
    id: 'manhole',
    name: 'Manhole'
  },
  {
    id: 'map',
    name: 'Map'
  },
  {
    id: 'map-location',
    name: 'Map location'
  },
  {
    id: 'map-location-dot',
    name: 'Map location dot'
  },
  {
    id: 'map-pin',
    name: 'Map Pin'
  },
  {
    id: 'marker',
    name: 'Marker'
  },
  {
    id: 'mars',
    name: 'Mars'
  },
  {
    id: 'mars-and-venus',
    name: 'Mars and Venus'
  },
  {
    id: 'mars-double',
    name: 'Mars Double'
  },
  {
    id: 'mars-stroke',
    name: 'Mars Stroke'
  },
  {
    id: 'mars-stroke-right',
    name: 'Mars stroke right'
  },
  {
    id: 'mars-stroke-up',
    name: 'Mars stroke up'
  },
  {
    id: 'martini-glass',
    name: 'Martini glass'
  },
  {
    id: 'martini-glass-citrus',
    name: 'Martini glass citrus'
  },
  {
    id: 'martini-glass-empty',
    name: 'Martini glass empty'
  },
  {
    id: 'mask',
    name: 'Mask'
  },
  {
    id: 'mask-face',
    name: 'Face Mask'
  },
  {
    id: 'mask-snorkel',
    name: 'Mask Snorkel'
  },
  {
    id: 'masks-theater',
    name: 'Masks theater'
  },
  {
    id: 'maximize',
    name: 'Maximize'
  },
  {
    id: 'meat',
    name: 'Meat'
  },
  {
    id: 'medal',
    name: 'Medal'
  },
  {
    id: 'megaphone',
    name: 'Megaphone'
  },
  {
    id: 'melon',
    name: 'Melon'
  },
  {
    id: 'melon-slice',
    name: 'Melon Slice'
  },
  {
    id: 'memo',
    name: 'Memo'
  },
  {
    id: 'memo-circle-check',
    name: 'Memo Circle-check'
  },
  {
    id: 'memo-pad',
    name: 'Memo Pad'
  },
  {
    id: 'memory',
    name: 'Memory'
  },
  {
    id: 'menorah',
    name: 'Menorah'
  },
  {
    id: 'mercury',
    name: 'Mercury'
  },
  {
    id: 'message',
    name: 'Message'
  },
  {
    id: 'message-arrow-down',
    name: 'Message arrow down'
  },
  {
    id: 'message-arrow-up',
    name: 'Message arrow up'
  },
  {
    id: 'message-arrow-up-right',
    name: 'Message Arrow-up-right'
  },
  {
    id: 'message-bot',
    name: 'Message Bot'
  },
  {
    id: 'message-captions',
    name: 'Message captions'
  },
  {
    id: 'message-check',
    name: 'Message check'
  },
  {
    id: 'message-code',
    name: 'Message Code'
  },
  {
    id: 'message-dollar',
    name: 'Message dollar'
  },
  {
    id: 'message-dots',
    name: 'Message dots'
  },
  {
    id: 'message-exclamation',
    name: 'Message exclamation'
  },
  {
    id: 'message-image',
    name: 'Message image'
  },
  {
    id: 'message-lines',
    name: 'Message lines'
  },
  {
    id: 'message-medical',
    name: 'Message medical'
  },
  {
    id: 'message-middle',
    name: 'Message middle'
  },
  {
    id: 'message-middle-top',
    name: 'Message middle top'
  },
  {
    id: 'message-minus',
    name: 'Message minus'
  },
  {
    id: 'message-music',
    name: 'Message music'
  },
  {
    id: 'message-pen',
    name: 'Message pen'
  },
  {
    id: 'message-plus',
    name: 'Message plus'
  },
  {
    id: 'message-question',
    name: 'Message Question'
  },
  {
    id: 'message-quote',
    name: 'Message Quote'
  },
  {
    id: 'message-slash',
    name: 'Message slash'
  },
  {
    id: 'message-smile',
    name: 'Message smile'
  },
  {
    id: 'message-sms',
    name: 'Message Sms'
  },
  {
    id: 'message-text',
    name: 'Message text'
  },
  {
    id: 'message-xmark',
    name: 'Message X Mark'
  },
  {
    id: 'messages',
    name: 'Messages'
  },
  {
    id: 'messages-dollar',
    name: 'Messages dollar'
  },
  {
    id: 'messages-question',
    name: 'Messages Question'
  },
  {
    id: 'meteor',
    name: 'Meteor'
  },
  {
    id: 'meter',
    name: 'Meter'
  },
  {
    id: 'meter-bolt',
    name: 'Meter Bolt'
  },
  {
    id: 'meter-droplet',
    name: 'Meter Droplet'
  },
  {
    id: 'meter-fire',
    name: 'Meter Fire'
  },
  {
    id: 'microchip',
    name: 'Microchip'
  },
  {
    id: 'microchip-ai',
    name: 'Microchip Ai'
  },
  {
    id: 'microphone',
    name: 'microphone'
  },
  {
    id: 'microphone-lines',
    name: 'Microphone lines'
  },
  {
    id: 'microphone-lines-slash',
    name: 'Microphone lines slash'
  },
  {
    id: 'microphone-slash',
    name: 'Microphone Slash'
  },
  {
    id: 'microphone-stand',
    name: 'Microphone Stand'
  },
  {
    id: 'microscope',
    name: 'Microscope'
  },
  {
    id: 'microwave',
    name: 'Microwave'
  },
  {
    id: 'mill-sign',
    name: 'Mill Sign'
  },
  {
    id: 'minimize',
    name: 'Minimize'
  },
  {
    id: 'minus',
    name: 'minus'
  },
  {
    id: 'mistletoe',
    name: 'Mistletoe'
  },
  {
    id: 'mitten',
    name: 'Mitten'
  },
  {
    id: 'mobile',
    name: 'Mobile'
  },
  {
    id: 'mobile-button',
    name: 'Mobile button'
  },
  {
    id: 'mobile-notch',
    name: 'Mobile notch'
  },
  {
    id: 'mobile-screen',
    name: 'Mobile screen'
  },
  {
    id: 'mobile-screen-button',
    name: 'Mobile screen button'
  },
  {
    id: 'mobile-signal',
    name: 'Mobile Signal'
  },
  {
    id: 'mobile-signal-out',
    name: 'Mobile Signal Out'
  },
  {
    id: 'money-bill',
    name: 'Money Bill'
  },
  {
    id: 'money-bill-1',
    name: 'Money bill 1'
  },
  {
    id: 'money-bill-1-wave',
    name: 'Money bill 1 wave'
  },
  {
    id: 'money-bill-simple',
    name: 'Money Bill-simple'
  },
  {
    id: 'money-bill-simple-wave',
    name: 'Money Bill-simple-wave'
  },
  {
    id: 'money-bill-wave',
    name: 'Wavy Money Bill'
  },
  {
    id: 'money-bills',
    name: 'Money Bills'
  },
  {
    id: 'money-bills-simple',
    name: 'Money bills simple'
  },
  {
    id: 'money-check',
    name: 'Money Check'
  },
  {
    id: 'money-check-dollar',
    name: 'Money check dollar'
  },
  {
    id: 'money-check-dollar-pen',
    name: 'Money check dollar pen'
  },
  {
    id: 'money-check-pen',
    name: 'Money check pen'
  },
  {
    id: 'money-from-bracket',
    name: 'Money From Bracket'
  },
  {
    id: 'money-simple-from-bracket',
    name: 'Money Simple From Bracket'
  },
  {
    id: 'monitor-waveform',
    name: 'Monitor waveform'
  },
  {
    id: 'monkey',
    name: 'Monkey'
  },
  {
    id: 'monument',
    name: 'Monument'
  },
  {
    id: 'moon',
    name: 'Moon'
  },
  {
    id: 'moon-cloud',
    name: 'Moon with Cloud'
  },
  {
    id: 'moon-over-sun',
    name: 'Moon over sun'
  },
  {
    id: 'moon-stars',
    name: 'Moon with Stars'
  },
  {
    id: 'moped',
    name: 'Moped'
  },
  {
    id: 'mortar-pestle',
    name: 'Mortar Pestle'
  },
  {
    id: 'mosque',
    name: 'Mosque'
  },
  {
    id: 'motorcycle',
    name: 'Motorcycle'
  },
  {
    id: 'mountain',
    name: 'Mountain'
  },
  {
    id: 'mountains',
    name: 'Mountains'
  },
  {
    id: 'mp3-player',
    name: 'MP3 Player'
  },
  {
    id: 'mug',
    name: 'Mug'
  },
  {
    id: 'mug-hot',
    name: 'Mug Hot'
  },
  {
    id: 'mug-marshmallows',
    name: 'Mug with Marshmallows'
  },
  {
    id: 'mug-saucer',
    name: 'Mug saucer'
  },
  {
    id: 'mug-tea',
    name: 'Mug Tea'
  },
  {
    id: 'mug-tea-saucer',
    name: 'Mug Tea-saucer'
  },
  {
    id: 'music',
    name: 'Music'
  },
  {
    id: 'music-note',
    name: 'Music note'
  },
  {
    id: 'music-note-slash',
    name: 'Music note slash'
  },
  {
    id: 'music-slash',
    name: 'Music Slash'
  },
  {
    id: 'n',
    name: 'N'
  },
  {
    id: 'naira-sign',
    name: 'Naira Sign'
  },
  {
    id: 'narwhal',
    name: 'Narwhal'
  },
  {
    id: 'nesting-dolls',
    name: 'Nesting Dolls'
  },
  {
    id: 'network-wired',
    name: 'Wired Network'
  },
  {
    id: 'neuter',
    name: 'Neuter'
  },
  {
    id: 'newspaper',
    name: 'Newspaper'
  },
  {
    id: 'nfc',
    name: 'NFC'
  },
  {
    id: 'nfc-lock',
    name: 'NFC Lock'
  },
  {
    id: 'nfc-magnifying-glass',
    name: 'NFC Magnifying Glass'
  },
  {
    id: 'nfc-pen',
    name: 'NFC Pen'
  },
  {
    id: 'nfc-signal',
    name: 'NFC Signal'
  },
  {
    id: 'nfc-slash',
    name: 'NFC Slash'
  },
  {
    id: 'nfc-trash',
    name: 'NFC Trash'
  },
  {
    id: 'not-equal',
    name: 'Not Equal'
  },
  {
    id: 'notdef',
    name: 'Notdef'
  },
  {
    id: 'note',
    name: 'Note'
  },
  {
    id: 'note-medical',
    name: 'Note Medical'
  },
  {
    id: 'note-sticky',
    name: 'Note sticky'
  },
  {
    id: 'notebook',
    name: 'Notebook'
  },
  {
    id: 'notes',
    name: 'Notes'
  },
  {
    id: 'notes-medical',
    name: 'Medical Notes'
  },
  {
    id: 'o',
    name: 'O'
  },
  {
    id: 'object-group',
    name: 'Object Group'
  },
  {
    id: 'object-ungroup',
    name: 'Object Ungroup'
  },
  {
    id: 'objects-align-bottom',
    name: 'Objects Align Bottom'
  },
  {
    id: 'objects-align-center-horizontal',
    name: 'Objects Align Center Horizontal'
  },
  {
    id: 'objects-align-center-vertical',
    name: 'Objects Align Center Vertical'
  },
  {
    id: 'objects-align-left',
    name: 'Objects Align Left'
  },
  {
    id: 'objects-align-right',
    name: 'Objects Align Right'
  },
  {
    id: 'objects-align-top',
    name: 'Objects Align Top'
  },
  {
    id: 'objects-column',
    name: 'Objects Column'
  },
  {
    id: 'octagon',
    name: 'Octagon'
  },
  {
    id: 'octagon-divide',
    name: 'Octagon Divide'
  },
  {
    id: 'octagon-exclamation',
    name: 'Octagon Exclamation'
  },
  {
    id: 'octagon-minus',
    name: 'Octagon minus'
  },
  {
    id: 'octagon-plus',
    name: 'Octagon plus'
  },
  {
    id: 'octagon-xmark',
    name: 'Octagon X Mark'
  },
  {
    id: 'oil-can',
    name: 'Oil Can'
  },
  {
    id: 'oil-can-drip',
    name: 'Oil Can-drip'
  },
  {
    id: 'oil-temperature',
    name: 'Oil temperature'
  },
  {
    id: 'olive',
    name: 'Olive'
  },
  {
    id: 'olive-branch',
    name: 'Olive Branch'
  },
  {
    id: 'om',
    name: 'Om'
  },
  {
    id: 'omega',
    name: 'Omega'
  },
  {
    id: 'option',
    name: 'Option'
  },
  {
    id: 'ornament',
    name: 'Ornament'
  },
  {
    id: 'otter',
    name: 'Otter'
  },
  {
    id: 'outdent',
    name: 'Outdent'
  },
  {
    id: 'outlet',
    name: 'Outlet'
  },
  {
    id: 'oven',
    name: 'Oven'
  },
  {
    id: 'overline',
    name: 'Overline'
  },
  {
    id: 'p',
    name: 'P'
  },
  {
    id: 'pager',
    name: 'Pager'
  },
  {
    id: 'paint-brush',
    name: 'Paint Brush'
  },
  {
    id: 'paint-brush-fine',
    name: 'Paint brush fine'
  },
  {
    id: 'paint-roller',
    name: 'Paint Roller'
  },
  {
    id: 'paintbrush-pencil',
    name: 'Paintbrush Pencil'
  },
  {
    id: 'palette',
    name: 'Palette'
  },
  {
    id: 'pallet',
    name: 'Pallet'
  },
  {
    id: 'pallet-box',
    name: 'Pallet Box'
  },
  {
    id: 'pallet-boxes',
    name: 'Pallet with Boxes'
  },
  {
    id: 'panorama',
    name: 'Panorama'
  },
  {
    id: 'paper-plane',
    name: 'Paper Plane'
  },
  {
    id: 'paper-plane-top',
    name: 'Paper plane top'
  },
  {
    id: 'paperclip',
    name: 'Paperclip'
  },
  {
    id: 'paperclip-vertical',
    name: 'Paperclip Vertical'
  },
  {
    id: 'parachute-box',
    name: 'Parachute Box'
  },
  {
    id: 'paragraph',
    name: 'paragraph'
  },
  {
    id: 'paragraph-left',
    name: 'Paragraph left'
  },
  {
    id: 'party-bell',
    name: 'Party Bell'
  },
  {
    id: 'party-horn',
    name: 'Party Horn'
  },
  {
    id: 'passport',
    name: 'Passport'
  },
  {
    id: 'paste',
    name: 'Paste'
  },
  {
    id: 'pause',
    name: 'pause'
  },
  {
    id: 'paw',
    name: 'Paw'
  },
  {
    id: 'paw-claws',
    name: 'Paw Claws'
  },
  {
    id: 'paw-simple',
    name: 'Paw simple'
  },
  {
    id: 'peace',
    name: 'Peace'
  },
  {
    id: 'peach',
    name: 'Peach'
  },
  {
    id: 'peapod',
    name: 'Peapod'
  },
  {
    id: 'pear',
    name: 'Pear'
  },
  {
    id: 'pedestal',
    name: 'Pedestal'
  },
  {
    id: 'pegasus',
    name: 'Pegasus'
  },
  {
    id: 'pen',
    name: 'Pen'
  },
  {
    id: 'pen-circle',
    name: 'Pen Circle'
  },
  {
    id: 'pen-clip',
    name: 'Pen clip'
  },
  {
    id: 'pen-clip-slash',
    name: 'Pen clip slash'
  },
  {
    id: 'pen-fancy',
    name: 'Pen Fancy'
  },
  {
    id: 'pen-fancy-slash',
    name: 'Pen Fancy-slash'
  },
  {
    id: 'pen-field',
    name: 'Pen Field'
  },
  {
    id: 'pen-line',
    name: 'Pen Line'
  },
  {
    id: 'pen-nib',
    name: 'Pen Nib'
  },
  {
    id: 'pen-paintbrush',
    name: 'Pen paintbrush'
  },
  {
    id: 'pen-ruler',
    name: 'Pen ruler'
  },
  {
    id: 'pen-slash',
    name: 'Pen Slash'
  },
  {
    id: 'pen-swirl',
    name: 'Pen Swirl'
  },
  {
    id: 'pen-to-square',
    name: 'Pen to square'
  },
  {
    id: 'pencil',
    name: 'pencil'
  },
  {
    id: 'pencil-slash',
    name: 'Pencil Slash'
  },
  {
    id: 'people',
    name: 'People'
  },
  {
    id: 'people-arrows-left-right',
    name: 'People arrows left right'
  },
  {
    id: 'people-carry-box',
    name: 'People carry box'
  },
  {
    id: 'people-dress',
    name: 'People Dress'
  },
  {
    id: 'people-dress-simple',
    name: 'People Dress-simple'
  },
  {
    id: 'people-pants',
    name: 'People Pants'
  },
  {
    id: 'people-pants-simple',
    name: 'People Pants-simple'
  },
  {
    id: 'people-simple',
    name: 'People Simple'
  },
  {
    id: 'pepper-hot',
    name: 'Hot Pepper'
  },
  {
    id: 'percent',
    name: 'Percent'
  },
  {
    id: 'period',
    name: 'Period'
  },
  {
    id: 'person',
    name: 'Person'
  },
  {
    id: 'person-biking',
    name: 'Person biking'
  },
  {
    id: 'person-biking-mountain',
    name: 'Person biking mountain'
  },
  {
    id: 'person-booth',
    name: 'Person Entering Booth'
  },
  {
    id: 'person-carry-box',
    name: 'Person carry box'
  },
  {
    id: 'person-digging',
    name: 'Person digging'
  },
  {
    id: 'person-dolly',
    name: 'Person and Dolly'
  },
  {
    id: 'person-dolly-empty',
    name: 'Person and Empty Dolly'
  },
  {
    id: 'person-dots-from-line',
    name: 'Person dots from line'
  },
  {
    id: 'person-dress',
    name: 'Person dress'
  },
  {
    id: 'person-dress-simple',
    name: 'Person Dress-simple'
  },
  {
    id: 'person-from-portal',
    name: 'Person from portal'
  },
  {
    id: 'person-hiking',
    name: 'Person hiking'
  },
  {
    id: 'person-pinball',
    name: 'Person Pinball'
  },
  {
    id: 'person-praying',
    name: 'Person praying'
  },
  {
    id: 'person-pregnant',
    name: 'Person Pregnant'
  },
  {
    id: 'person-running',
    name: 'Person running'
  },
  {
    id: 'person-seat',
    name: 'Person in a Seat'
  },
  {
    id: 'person-seat-reclined',
    name: 'Person Seat-reclined'
  },
  {
    id: 'person-sign',
    name: 'Person Holding Sign'
  },
  {
    id: 'person-simple',
    name: 'Person Simple'
  },
  {
    id: 'person-skating',
    name: 'Person skating'
  },
  {
    id: 'person-ski-jumping',
    name: 'Person ski jumping'
  },
  {
    id: 'person-ski-lift',
    name: 'Person ski lift'
  },
  {
    id: 'person-skiing',
    name: 'Person skiing'
  },
  {
    id: 'person-skiing-nordic',
    name: 'Person skiing nordic'
  },
  {
    id: 'person-sledding',
    name: 'Person sledding'
  },
  {
    id: 'person-snowboarding',
    name: 'Person snowboarding'
  },
  {
    id: 'person-snowmobiling',
    name: 'Person snowmobiling'
  },
  {
    id: 'person-swimming',
    name: 'Person swimming'
  },
  {
    id: 'person-to-portal',
    name: 'Person to portal'
  },
  {
    id: 'person-walking',
    name: 'Person walking'
  },
  {
    id: 'person-walking-with-cane',
    name: 'Person walking with cane'
  },
  {
    id: 'peseta-sign',
    name: 'Peseta Sign'
  },
  {
    id: 'peso-sign',
    name: 'Peso Sign'
  },
  {
    id: 'phone',
    name: 'Phone'
  },
  {
    id: 'phone-arrow-down-left',
    name: 'Phone arrow down left'
  },
  {
    id: 'phone-arrow-up-right',
    name: 'Phone arrow up right'
  },
  {
    id: 'phone-flip',
    name: 'Phone flip'
  },
  {
    id: 'phone-hangup',
    name: 'Phone hangup'
  },
  {
    id: 'phone-missed',
    name: 'Phone Missed'
  },
  {
    id: 'phone-office',
    name: 'Office Phone'
  },
  {
    id: 'phone-plus',
    name: 'Phone Plus'
  },
  {
    id: 'phone-rotary',
    name: 'Rotary Phone'
  },
  {
    id: 'phone-slash',
    name: 'Phone Slash'
  },
  {
    id: 'phone-volume',
    name: 'Phone Volume'
  },
  {
    id: 'phone-xmark',
    name: 'Phone Xmark'
  },
  {
    id: 'photo-film',
    name: 'Photo film'
  },
  {
    id: 'photo-film-music',
    name: 'Photo film music'
  },
  {
    id: 'pi',
    name: 'Pi'
  },
  {
    id: 'piano',
    name: 'Piano'
  },
  {
    id: 'piano-keyboard',
    name: 'Piano Keyboard'
  },
  {
    id: 'pie',
    name: 'Pie'
  },
  {
    id: 'pig',
    name: 'Pig'
  },
  {
    id: 'piggy-bank',
    name: 'Piggy Bank'
  },
  {
    id: 'pills',
    name: 'Pills'
  },
  {
    id: 'pinata',
    name: 'Pinata'
  },
  {
    id: 'pinball',
    name: 'Pinball'
  },
  {
    id: 'pineapple',
    name: 'Pineapple'
  },
  {
    id: 'pipe',
    name: 'Pipe'
  },
  {
    id: 'pipe-smoking',
    name: 'Pipe Smoking'
  },
  {
    id: 'pizza',
    name: 'Pizza'
  },
  {
    id: 'pizza-slice',
    name: 'Pizza Slice'
  },
  {
    id: 'place-of-worship',
    name: 'Place of Worship'
  },
  {
    id: 'plane',
    name: 'plane'
  },
  {
    id: 'plane-arrival',
    name: 'Plane Arrival'
  },
  {
    id: 'plane-departure',
    name: 'Plane Departure'
  },
  {
    id: 'plane-engines',
    name: 'Plane engines'
  },
  {
    id: 'plane-prop',
    name: 'Plane Prop'
  },
  {
    id: 'plane-slash',
    name: 'Plane Slash'
  },
  {
    id: 'plane-tail',
    name: 'Plane Tail'
  },
  {
    id: 'plane-up',
    name: 'Plane Up'
  },
  {
    id: 'plane-up-slash',
    name: 'Plane Up-slash'
  },
  {
    id: 'planet-moon',
    name: 'Planet with Moon'
  },
  {
    id: 'planet-ringed',
    name: 'Ringed Planet'
  },
  {
    id: 'play',
    name: 'play'
  },
  {
    id: 'play-pause',
    name: 'Play Pause'
  },
  {
    id: 'plug',
    name: 'Plug'
  },
  {
    id: 'plus',
    name: 'plus'
  },
  {
    id: 'plus-minus',
    name: 'Plus Minus'
  },
  {
    id: 'podcast',
    name: 'Podcast'
  },
  {
    id: 'podium',
    name: 'Podium'
  },
  {
    id: 'podium-star',
    name: 'Podium with Star'
  },
  {
    id: 'police-box',
    name: 'Police Box'
  },
  {
    id: 'poll-people',
    name: 'Poll People'
  },
  {
    id: 'poo',
    name: 'Poo'
  },
  {
    id: 'poo-bolt',
    name: 'Poo bolt'
  },
  {
    id: 'pool-8-ball',
    name: 'Pool 8-ball'
  },
  {
    id: 'poop',
    name: 'Poop'
  },
  {
    id: 'popcorn',
    name: 'Popcorn'
  },
  {
    id: 'power-off',
    name: 'Power Off'
  },
  {
    id: 'prescription',
    name: 'Prescription'
  },
  {
    id: 'prescription-bottle',
    name: 'Prescription Bottle'
  },
  {
    id: 'prescription-bottle-medical',
    name: 'Prescription bottle medical'
  },
  {
    id: 'presentation-screen',
    name: 'Presentation screen'
  },
  {
    id: 'print',
    name: 'print'
  },
  {
    id: 'print-magnifying-glass',
    name: 'Print magnifying glass'
  },
  {
    id: 'print-slash',
    name: 'Print Slash'
  },
  {
    id: 'projector',
    name: 'Projector'
  },
  {
    id: 'pump-medical',
    name: 'Pump Medical'
  },
  {
    id: 'pump-soap',
    name: 'Pump Soap'
  },
  {
    id: 'pumpkin',
    name: 'Pumpkin'
  },
  {
    id: 'puzzle-piece',
    name: 'Puzzle Piece'
  },
  {
    id: 'puzzle-piece-simple',
    name: 'Puzzle piece simple'
  },
  {
    id: 'q',
    name: 'Q'
  },
  {
    id: 'qrcode',
    name: 'qrcode'
  },
  {
    id: 'question',
    name: 'Question'
  },
  {
    id: 'quidditch',
    name: 'Quidditch'
  },
  {
    id: 'quote-left',
    name: 'quote-left'
  },
  {
    id: 'quote-right',
    name: 'quote-right'
  },
  {
    id: 'quotes',
    name: 'Quotes'
  },
  {
    id: 'r',
    name: 'R'
  },
  {
    id: 'rabbit',
    name: 'Rabbit'
  },
  {
    id: 'rabbit-running',
    name: 'Rabbit running'
  },
  {
    id: 'racquet',
    name: 'Racquet'
  },
  {
    id: 'radar',
    name: 'Radar'
  },
  {
    id: 'radiation',
    name: 'Radiation'
  },
  {
    id: 'radio',
    name: 'Radio'
  },
  {
    id: 'radio-tuner',
    name: 'Radio tuner'
  },
  {
    id: 'rainbow',
    name: 'Rainbow'
  },
  {
    id: 'raindrops',
    name: 'Raindrops'
  },
  {
    id: 'ram',
    name: 'Ram'
  },
  {
    id: 'ramp-loading',
    name: 'Ramp Loading'
  },
  {
    id: 'raygun',
    name: 'Raygun'
  },
  {
    id: 'receipt',
    name: 'Receipt'
  },
  {
    id: 'record-vinyl',
    name: 'Record Vinyl'
  },
  {
    id: 'rectangle',
    name: 'Rectangle'
  },
  {
    id: 'rectangle-ad',
    name: 'Rectangle ad'
  },
  {
    id: 'rectangle-barcode',
    name: 'Rectangle barcode'
  },
  {
    id: 'rectangle-code',
    name: 'Rectangle Code'
  },
  {
    id: 'rectangle-list',
    name: 'Rectangle list'
  },
  {
    id: 'rectangle-pro',
    name: 'Rectangle Pro'
  },
  {
    id: 'rectangle-terminal',
    name: 'Rectangle Terminal'
  },
  {
    id: 'rectangle-vertical',
    name: 'Rectangle vertical'
  },
  {
    id: 'rectangle-vertical-history',
    name: 'Rectangle Vertical-history'
  },
  {
    id: 'rectangle-wide',
    name: 'Wide Rectangle'
  },
  {
    id: 'rectangle-xmark',
    name: 'Rectangle X Mark'
  },
  {
    id: 'rectangles-mixed',
    name: 'Rectangles Mixed'
  },
  {
    id: 'recycle',
    name: 'Recycle'
  },
  {
    id: 'reel',
    name: 'Reel'
  },
  {
    id: 'refrigerator',
    name: 'Refrigerator'
  },
  {
    id: 'registered',
    name: 'Registered Trademark'
  },
  {
    id: 'repeat',
    name: 'Repeat'
  },
  {
    id: 'repeat-1',
    name: 'Repeat 1'
  },
  {
    id: 'reply',
    name: 'Reply'
  },
  {
    id: 'reply-all',
    name: 'reply-all'
  },
  {
    id: 'reply-clock',
    name: 'Reply clock'
  },
  {
    id: 'republican',
    name: 'Republican'
  },
  {
    id: 'restroom',
    name: 'Restroom'
  },
  {
    id: 'restroom-simple',
    name: 'Restroom Simple'
  },
  {
    id: 'retweet',
    name: 'Retweet'
  },
  {
    id: 'rhombus',
    name: 'Rhombus'
  },
  {
    id: 'ribbon',
    name: 'Ribbon'
  },
  {
    id: 'right',
    name: 'Right'
  },
  {
    id: 'right-from-bracket',
    name: 'Right from bracket'
  },
  {
    id: 'right-from-line',
    name: 'Right from line'
  },
  {
    id: 'right-left',
    name: 'Right left'
  },
  {
    id: 'right-long',
    name: 'Right long'
  },
  {
    id: 'right-to-bracket',
    name: 'Right to bracket'
  },
  {
    id: 'right-to-line',
    name: 'Right to line'
  },
  {
    id: 'ring',
    name: 'Ring'
  },
  {
    id: 'rings-wedding',
    name: 'Rings Wedding'
  },
  {
    id: 'road',
    name: 'road'
  },
  {
    id: 'robot',
    name: 'Robot'
  },
  {
    id: 'robot-astromech',
    name: 'Robot Astromech'
  },
  {
    id: 'rocket',
    name: 'rocket'
  },
  {
    id: 'rocket-launch',
    name: 'Rocket Launch'
  },
  {
    id: 'roller-coaster',
    name: 'Roller Coaster'
  },
  {
    id: 'rotate',
    name: 'Rotate'
  },
  {
    id: 'rotate-exclamation',
    name: 'Rotate Exclamation'
  },
  {
    id: 'rotate-left',
    name: 'Rotate Left'
  },
  {
    id: 'rotate-right',
    name: 'Rotate Right'
  },
  {
    id: 'route',
    name: 'Route'
  },
  {
    id: 'route-highway',
    name: 'Route Highway'
  },
  {
    id: 'route-interstate',
    name: 'Route Interstate'
  },
  {
    id: 'router',
    name: 'Router'
  },
  {
    id: 'rss',
    name: 'rss'
  },
  {
    id: 'ruble-sign',
    name: 'Ruble Sign'
  },
  {
    id: 'rugby-ball',
    name: 'Rugby Ball'
  },
  {
    id: 'ruler',
    name: 'Ruler'
  },
  {
    id: 'ruler-combined',
    name: 'Ruler Combined'
  },
  {
    id: 'ruler-horizontal',
    name: 'Ruler Horizontal'
  },
  {
    id: 'ruler-triangle',
    name: 'Ruler Triangle'
  },
  {
    id: 'ruler-vertical',
    name: 'Ruler Vertical'
  },
  {
    id: 'rupee-sign',
    name: 'Indian Rupee Sign'
  },
  {
    id: 'rupiah-sign',
    name: 'Rupiah Sign'
  },
  {
    id: 'rv',
    name: 'R.V.'
  },
  {
    id: 's',
    name: 'S'
  },
  {
    id: 'sack',
    name: 'Sack'
  },
  {
    id: 'sack-dollar',
    name: 'Sack of Money'
  },
  {
    id: 'salad',
    name: 'Salad'
  },
  {
    id: 'sandwich',
    name: 'Sandwich'
  },
  {
    id: 'satellite',
    name: 'Satellite'
  },
  {
    id: 'satellite-dish',
    name: 'Satellite Dish'
  },
  {
    id: 'sausage',
    name: 'Sausage'
  },
  {
    id: 'saxophone',
    name: 'Saxophone'
  },
  {
    id: 'saxophone-fire',
    name: 'Saxophone fire'
  },
  {
    id: 'scale-balanced',
    name: 'Scale balanced'
  },
  {
    id: 'scale-unbalanced',
    name: 'Scale unbalanced'
  },
  {
    id: 'scale-unbalanced-flip',
    name: 'Scale unbalanced flip'
  },
  {
    id: 'scalpel',
    name: 'Scalpel'
  },
  {
    id: 'scalpel-line-dashed',
    name: 'Scalpel line dashed'
  },
  {
    id: 'scanner',
    name: 'Scanner'
  },
  {
    id: 'scanner-gun',
    name: 'Scanner gun'
  },
  {
    id: 'scanner-keyboard',
    name: 'Scanner Keyboard'
  },
  {
    id: 'scanner-touchscreen',
    name: 'Scanner Touchscreen'
  },
  {
    id: 'scarecrow',
    name: 'Scarecrow'
  },
  {
    id: 'scarf',
    name: 'Scarf'
  },
  {
    id: 'school',
    name: 'School'
  },
  {
    id: 'scissors',
    name: 'Scissors'
  },
  {
    id: 'screen-users',
    name: 'Screen users'
  },
  {
    id: 'screencast',
    name: 'Screencast'
  },
  {
    id: 'screwdriver',
    name: 'Screwdriver'
  },
  {
    id: 'screwdriver-wrench',
    name: 'Screwdriver wrench'
  },
  {
    id: 'scribble',
    name: 'Scribble'
  },
  {
    id: 'scroll',
    name: 'Scroll'
  },
  {
    id: 'scroll-old',
    name: 'Scroll Old'
  },
  {
    id: 'scroll-torah',
    name: 'Scroll torah'
  },
  {
    id: 'scrubber',
    name: 'Scrubber'
  },
  {
    id: 'scythe',
    name: 'Scythe'
  },
  {
    id: 'sd-card',
    name: 'Sd Card'
  },
  {
    id: 'sd-cards',
    name: 'Sd Cards'
  },
  {
    id: 'seal',
    name: 'Seal'
  },
  {
    id: 'seal-exclamation',
    name: 'Seal Exclamation'
  },
  {
    id: 'seal-question',
    name: 'Seal Question'
  },
  {
    id: 'seat-airline',
    name: 'Seat Airline'
  },
  {
    id: 'section',
    name: 'Section'
  },
  {
    id: 'seedling',
    name: 'Seedling'
  },
  {
    id: 'semicolon',
    name: 'Semicolon'
  },
  {
    id: 'send-back',
    name: 'Send Back'
  },
  {
    id: 'send-backward',
    name: 'Send Backward'
  },
  {
    id: 'sensor',
    name: 'Sensor'
  },
  {
    id: 'sensor-cloud',
    name: 'Sensor cloud'
  },
  {
    id: 'sensor-fire',
    name: 'Sensor Fire'
  },
  {
    id: 'sensor-on',
    name: 'Sensor On'
  },
  {
    id: 'sensor-triangle-exclamation',
    name: 'Sensor triangle exclamation'
  },
  {
    id: 'server',
    name: 'Server'
  },
  {
    id: 'shapes',
    name: 'Shapes'
  },
  {
    id: 'share',
    name: 'Share'
  },
  {
    id: 'share-all',
    name: 'Share All'
  },
  {
    id: 'share-from-square',
    name: 'Share from square'
  },
  {
    id: 'share-nodes',
    name: 'Share nodes'
  },
  {
    id: 'sheep',
    name: 'Sheep'
  },
  {
    id: 'shekel-sign',
    name: 'Shekel Sign'
  },
  {
    id: 'shelves',
    name: 'Shelves'
  },
  {
    id: 'shelves-empty',
    name: 'Shelves Empty'
  },
  {
    id: 'shield',
    name: 'shield'
  },
  {
    id: 'shield-blank',
    name: 'Shield blank'
  },
  {
    id: 'shield-check',
    name: 'shield'
  },
  {
    id: 'shield-cross',
    name: 'Shield Cross'
  },
  {
    id: 'shield-exclamation',
    name: 'Shield Exclamation'
  },
  {
    id: 'shield-keyhole',
    name: 'Shield Keyhole'
  },
  {
    id: 'shield-minus',
    name: 'Shield Minus'
  },
  {
    id: 'shield-plus',
    name: 'Shield Plus'
  },
  {
    id: 'shield-slash',
    name: 'Shield Slash'
  },
  {
    id: 'shield-virus',
    name: 'Shield Virus'
  },
  {
    id: 'shield-xmark',
    name: 'Shield X Mark'
  },
  {
    id: 'ship',
    name: 'Ship'
  },
  {
    id: 'shirt',
    name: 'T-Shirt'
  },
  {
    id: 'shirt-long-sleeve',
    name: 'Long Sleeve Shirt'
  },
  {
    id: 'shirt-running',
    name: 'Running Shirt'
  },
  {
    id: 'shirt-tank-top',
    name: 'Tank Top Shirt'
  },
  {
    id: 'shish-kebab',
    name: 'Shish Kebab'
  },
  {
    id: 'shoe-prints',
    name: 'Shoe Prints'
  },
  {
    id: 'shop',
    name: 'Shop'
  },
  {
    id: 'shop-slash',
    name: 'Shop slash'
  },
  {
    id: 'shovel',
    name: 'Shovel'
  },
  {
    id: 'shovel-snow',
    name: 'Shovel Snow'
  },
  {
    id: 'shower',
    name: 'Shower'
  },
  {
    id: 'shower-down',
    name: 'Shower down'
  },
  {
    id: 'shredder',
    name: 'Shredder'
  },
  {
    id: 'shuffle',
    name: 'Shuffle'
  },
  {
    id: 'shuttle-space',
    name: 'Shuttle space'
  },
  {
    id: 'shuttlecock',
    name: 'Shuttlecock'
  },
  {
    id: 'sickle',
    name: 'Sickle'
  },
  {
    id: 'sidebar',
    name: 'Sidebar'
  },
  {
    id: 'sidebar-flip',
    name: 'Sidebar Flip'
  },
  {
    id: 'sigma',
    name: 'Sigma (Summation)'
  },
  {
    id: 'sign-hanging',
    name: 'Sign hanging'
  },
  {
    id: 'signal',
    name: 'signal'
  },
  {
    id: 'signal-bars',
    name: 'Signal bars'
  },
  {
    id: 'signal-bars-fair',
    name: 'Signal bars fair'
  },
  {
    id: 'signal-bars-good',
    name: 'Signal bars good'
  },
  {
    id: 'signal-bars-slash',
    name: 'Signal bars slash'
  },
  {
    id: 'signal-bars-weak',
    name: 'Signal bars weak'
  },
  {
    id: 'signal-fair',
    name: 'Signal fair'
  },
  {
    id: 'signal-good',
    name: 'Signal good'
  },
  {
    id: 'signal-slash',
    name: 'Signal Slash'
  },
  {
    id: 'signal-stream',
    name: 'Signal Stream'
  },
  {
    id: 'signal-stream-slash',
    name: 'Signal Stream Slash'
  },
  {
    id: 'signal-strong',
    name: 'Signal strong'
  },
  {
    id: 'signal-weak',
    name: 'Signal weak'
  },
  {
    id: 'signature',
    name: 'Signature'
  },
  {
    id: 'signature-lock',
    name: 'Signature Lock'
  },
  {
    id: 'signature-slash',
    name: 'Signature Slash'
  },
  {
    id: 'signs-post',
    name: 'Signs post'
  },
  {
    id: 'sim-card',
    name: 'SIM Card'
  },
  {
    id: 'sim-cards',
    name: 'Sim Cards'
  },
  {
    id: 'sink',
    name: 'Sink'
  },
  {
    id: 'siren',
    name: 'Siren'
  },
  {
    id: 'siren-on',
    name: 'Siren On'
  },
  {
    id: 'sitemap',
    name: 'Sitemap'
  },
  {
    id: 'skeleton',
    name: 'Skeleton'
  },
  {
    id: 'ski-boot',
    name: 'Ski Boot'
  },
  {
    id: 'ski-boot-ski',
    name: 'Ski Boot on Ski'
  },
  {
    id: 'skull',
    name: 'Skull'
  },
  {
    id: 'skull-cow',
    name: 'Cow Skull'
  },
  {
    id: 'skull-crossbones',
    name: 'Skull & Crossbones'
  },
  {
    id: 'slash',
    name: 'Slash'
  },
  {
    id: 'slash-back',
    name: 'Slash Back'
  },
  {
    id: 'slash-forward',
    name: 'Slash Forward'
  },
  {
    id: 'sleigh',
    name: 'Sleigh'
  },
  {
    id: 'slider',
    name: 'Slider'
  },
  {
    id: 'sliders',
    name: 'Sliders'
  },
  {
    id: 'sliders-simple',
    name: 'Sliders Simple'
  },
  {
    id: 'sliders-up',
    name: 'Sliders up'
  },
  {
    id: 'slot-machine',
    name: 'Slot Machine'
  },
  {
    id: 'smog',
    name: 'Smog'
  },
  {
    id: 'smoke',
    name: 'Smoke'
  },
  {
    id: 'smoking',
    name: 'Smoking'
  },
  {
    id: 'snake',
    name: 'Snake'
  },
  {
    id: 'snooze',
    name: 'Snooze'
  },
  {
    id: 'snow-blowing',
    name: 'Snow Blowing'
  },
  {
    id: 'snowflake',
    name: 'Snowflake'
  },
  {
    id: 'snowflakes',
    name: 'Snowflakes'
  },
  {
    id: 'snowman',
    name: 'Snowman'
  },
  {
    id: 'snowman-head',
    name: 'Snowman head'
  },
  {
    id: 'snowplow',
    name: 'Snowplow'
  },
  {
    id: 'soap',
    name: 'Soap'
  },
  {
    id: 'socks',
    name: 'Socks'
  },
  {
    id: 'solar-panel',
    name: 'Solar Panel'
  },
  {
    id: 'solar-system',
    name: 'Solar System'
  },
  {
    id: 'sort',
    name: 'Sort'
  },
  {
    id: 'sort-down',
    name: 'Sort Down (Descending)'
  },
  {
    id: 'sort-up',
    name: 'Sort Up (Ascending)'
  },
  {
    id: 'spa',
    name: 'Spa'
  },
  {
    id: 'space-station-moon',
    name: 'Moon Space Station'
  },
  {
    id: 'space-station-moon-construction',
    name: 'Space station moon construction'
  },
  {
    id: 'spade',
    name: 'Spade'
  },
  {
    id: 'spaghetti-monster-flying',
    name: 'Spaghetti monster flying'
  },
  {
    id: 'sparkles',
    name: 'Sparkles'
  },
  {
    id: 'speaker',
    name: 'Speaker'
  },
  {
    id: 'speakers',
    name: 'Speakers'
  },
  {
    id: 'spell-check',
    name: 'Spell Check'
  },
  {
    id: 'spider',
    name: 'Spider'
  },
  {
    id: 'spider-black-widow',
    name: 'Black Widow Spider'
  },
  {
    id: 'spider-web',
    name: 'Spider Web'
  },
  {
    id: 'spinner',
    name: 'Spinner'
  },
  {
    id: 'spinner-third',
    name: 'Spinner Third'
  },
  {
    id: 'split',
    name: 'Split'
  },
  {
    id: 'splotch',
    name: 'Splotch'
  },
  {
    id: 'spoon',
    name: 'Spoon'
  },
  {
    id: 'spray-can',
    name: 'Spray Can'
  },
  {
    id: 'spray-can-sparkles',
    name: 'Spray Can Sparkles'
  },
  {
    id: 'sprinkler',
    name: 'Sprinkler'
  },
  {
    id: 'square',
    name: 'Square'
  },
  {
    id: 'square-0',
    name: 'Square 0'
  },
  {
    id: 'square-1',
    name: 'Square 1'
  },
  {
    id: 'square-2',
    name: 'Square 2'
  },
  {
    id: 'square-3',
    name: 'Square 3'
  },
  {
    id: 'square-4',
    name: 'Square 4'
  },
  {
    id: 'square-5',
    name: 'Square 5'
  },
  {
    id: 'square-6',
    name: 'Square 6'
  },
  {
    id: 'square-7',
    name: 'Square 7'
  },
  {
    id: 'square-8',
    name: 'Square 8'
  },
  {
    id: 'square-9',
    name: 'Square 9'
  },
  {
    id: 'square-a',
    name: 'Square A'
  },
  {
    id: 'square-ampersand',
    name: 'Square Ampersand'
  },
  {
    id: 'square-arrow-down',
    name: 'Square arrow down'
  },
  {
    id: 'square-arrow-down-left',
    name: 'Square Arrow-down-left'
  },
  {
    id: 'square-arrow-down-right',
    name: 'Square Arrow-down-right'
  },
  {
    id: 'square-arrow-left',
    name: 'Square arrow left'
  },
  {
    id: 'square-arrow-right',
    name: 'Square arrow right'
  },
  {
    id: 'square-arrow-up',
    name: 'Square arrow up'
  },
  {
    id: 'square-arrow-up-left',
    name: 'Square Arrow-up-left'
  },
  {
    id: 'square-arrow-up-right',
    name: 'Square arrow up right'
  },
  {
    id: 'square-b',
    name: 'Square B'
  },
  {
    id: 'square-bolt',
    name: 'Square Bolt'
  },
  {
    id: 'square-c',
    name: 'Square C'
  },
  {
    id: 'square-caret-down',
    name: 'Square caret down'
  },
  {
    id: 'square-caret-left',
    name: 'Square caret left'
  },
  {
    id: 'square-caret-right',
    name: 'Square caret right'
  },
  {
    id: 'square-caret-up',
    name: 'Square caret up'
  },
  {
    id: 'square-check',
    name: 'Square check'
  },
  {
    id: 'square-chevron-down',
    name: 'Square chevron down'
  },
  {
    id: 'square-chevron-left',
    name: 'Square chevron left'
  },
  {
    id: 'square-chevron-right',
    name: 'Square chevron right'
  },
  {
    id: 'square-chevron-up',
    name: 'Square chevron up'
  },
  {
    id: 'square-code',
    name: 'Square Code'
  },
  {
    id: 'square-d',
    name: 'Square D'
  },
  {
    id: 'square-dashed',
    name: 'Square Dashed'
  },
  {
    id: 'square-divide',
    name: 'Square Divide'
  },
  {
    id: 'square-dollar',
    name: 'Square dollar'
  },
  {
    id: 'square-down',
    name: 'Square down'
  },
  {
    id: 'square-down-left',
    name: 'Square Down-left'
  },
  {
    id: 'square-down-right',
    name: 'Square Down-right'
  },
  {
    id: 'square-e',
    name: 'Square E'
  },
  {
    id: 'square-ellipsis',
    name: 'Square Ellipsis'
  },
  {
    id: 'square-ellipsis-vertical',
    name: 'Square Ellipsis Vertical'
  },
  {
    id: 'square-envelope',
    name: 'Square envelope'
  },
  {
    id: 'square-exclamation',
    name: 'Square exclamation'
  },
  {
    id: 'square-f',
    name: 'Square F'
  },
  {
    id: 'square-fragile',
    name: 'Square fragile'
  },
  {
    id: 'square-full',
    name: 'Square Full'
  },
  {
    id: 'square-g',
    name: 'Square G'
  },
  {
    id: 'square-h',
    name: 'Square h'
  },
  {
    id: 'square-heart',
    name: 'Square heart'
  },
  {
    id: 'square-i',
    name: 'Square I'
  },
  {
    id: 'square-info',
    name: 'Square info'
  },
  {
    id: 'square-j',
    name: 'Square J'
  },
  {
    id: 'square-k',
    name: 'Square K'
  },
  {
    id: 'square-l',
    name: 'Square L'
  },
  {
    id: 'square-left',
    name: 'Square left'
  },
  {
    id: 'square-m',
    name: 'Square M'
  },
  {
    id: 'square-minus',
    name: 'Square minus'
  },
  {
    id: 'square-n',
    name: 'Square N'
  },
  {
    id: 'square-o',
    name: 'Square O'
  },
  {
    id: 'square-p',
    name: 'Square P'
  },
  {
    id: 'square-parking',
    name: 'Square parking'
  },
  {
    id: 'square-parking-slash',
    name: 'Square parking slash'
  },
  {
    id: 'square-pen',
    name: 'Square pen'
  },
  {
    id: 'square-phone',
    name: 'Square phone'
  },
  {
    id: 'square-phone-flip',
    name: 'Square phone flip'
  },
  {
    id: 'square-phone-hangup',
    name: 'Square phone hangup'
  },
  {
    id: 'square-plus',
    name: 'Square plus'
  },
  {
    id: 'square-poll-horizontal',
    name: 'Square poll horizontal'
  },
  {
    id: 'square-poll-vertical',
    name: 'Square poll vertical'
  },
  {
    id: 'square-q',
    name: 'Square Q'
  },
  {
    id: 'square-question',
    name: 'Square question'
  },
  {
    id: 'square-quote',
    name: 'Square Quote'
  },
  {
    id: 'square-r',
    name: 'Square R'
  },
  {
    id: 'square-right',
    name: 'Square right'
  },
  {
    id: 'square-root',
    name: 'Square Root'
  },
  {
    id: 'square-root-variable',
    name: 'Square root variable'
  },
  {
    id: 'square-rss',
    name: 'Square rss'
  },
  {
    id: 'square-s',
    name: 'Square S'
  },
  {
    id: 'square-share-nodes',
    name: 'Square share nodes'
  },
  {
    id: 'square-sliders',
    name: 'Square sliders'
  },
  {
    id: 'square-sliders-vertical',
    name: 'Square sliders vertical'
  },
  {
    id: 'square-small',
    name: 'Square Small'
  },
  {
    id: 'square-star',
    name: 'Square Star'
  },
  {
    id: 'square-t',
    name: 'Square T'
  },
  {
    id: 'square-terminal',
    name: 'Square Terminal'
  },
  {
    id: 'square-this-way-up',
    name: 'Square this way up'
  },
  {
    id: 'square-u',
    name: 'Square U'
  },
  {
    id: 'square-up',
    name: 'Square up'
  },
  {
    id: 'square-up-left',
    name: 'Square Up-left'
  },
  {
    id: 'square-up-right',
    name: 'Square up right'
  },
  {
    id: 'square-user',
    name: 'Square User'
  },
  {
    id: 'square-v',
    name: 'Square V'
  },
  {
    id: 'square-w',
    name: 'Square W'
  },
  {
    id: 'square-x',
    name: 'Square X'
  },
  {
    id: 'square-xmark',
    name: 'Square X Mark'
  },
  {
    id: 'square-y',
    name: 'Square Y'
  },
  {
    id: 'square-z',
    name: 'Square Z'
  },
  {
    id: 'squirrel',
    name: 'Squirrel'
  },
  {
    id: 'staff',
    name: 'Staff'
  },
  {
    id: 'stairs',
    name: 'Stairs'
  },
  {
    id: 'stamp',
    name: 'Stamp'
  },
  {
    id: 'standard-definition',
    name: 'Standard Definition'
  },
  {
    id: 'star',
    name: 'Star'
  },
  {
    id: 'star-and-crescent',
    name: 'Star and Crescent'
  },
  {
    id: 'star-christmas',
    name: 'Christmas Star'
  },
  {
    id: 'star-exclamation',
    name: 'Exclamation Star'
  },
  {
    id: 'star-half',
    name: 'star-half'
  },
  {
    id: 'star-half-stroke',
    name: 'Star half stroke'
  },
  {
    id: 'star-of-david',
    name: 'Star of David'
  },
  {
    id: 'star-of-life',
    name: 'Star of Life'
  },
  {
    id: 'star-sharp',
    name: 'Star Sharp'
  },
  {
    id: 'star-sharp-half',
    name: 'Star Sharp-half'
  },
  {
    id: 'star-sharp-half-stroke',
    name: 'Star sharp half stroke'
  },
  {
    id: 'star-shooting',
    name: 'Star Shooting'
  },
  {
    id: 'starfighter',
    name: 'Starfighter'
  },
  {
    id: 'starfighter-twin-ion-engine',
    name: 'Starfighter twin ion engine'
  },
  {
    id: 'starfighter-twin-ion-engine-advanced',
    name: 'Starfighter twin ion engine advanced'
  },
  {
    id: 'stars',
    name: 'Stars'
  },
  {
    id: 'starship',
    name: 'Starship'
  },
  {
    id: 'starship-freighter',
    name: 'Starship Freighter'
  },
  {
    id: 'steak',
    name: 'Steak'
  },
  {
    id: 'steering-wheel',
    name: 'Wheel Steering'
  },
  {
    id: 'sterling-sign',
    name: 'Sterling sign'
  },
  {
    id: 'stethoscope',
    name: 'Stethoscope'
  },
  {
    id: 'stocking',
    name: 'Stocking'
  },
  {
    id: 'stomach',
    name: 'Stomach'
  },
  {
    id: 'stop',
    name: 'stop'
  },
  {
    id: 'stopwatch',
    name: 'Stopwatch'
  },
  {
    id: 'stopwatch-20',
    name: 'Stopwatch 20'
  },
  {
    id: 'store',
    name: 'Store'
  },
  {
    id: 'store-slash',
    name: 'Store Slash'
  },
  {
    id: 'strawberry',
    name: 'Strawberry'
  },
  {
    id: 'street-view',
    name: 'Street View'
  },
  {
    id: 'stretcher',
    name: 'Stretcher'
  },
  {
    id: 'strikethrough',
    name: 'Strikethrough'
  },
  {
    id: 'stroopwafel',
    name: 'Stroopwafel'
  },
  {
    id: 'subscript',
    name: 'subscript'
  },
  {
    id: 'suitcase',
    name: 'Suitcase'
  },
  {
    id: 'suitcase-medical',
    name: 'Suitcase medical'
  },
  {
    id: 'suitcase-rolling',
    name: 'Suitcase Rolling'
  },
  {
    id: 'sun',
    name: 'Sun'
  },
  {
    id: 'sun-bright',
    name: 'Sun bright'
  },
  {
    id: 'sun-cloud',
    name: 'Sun with Cloud'
  },
  {
    id: 'sun-dust',
    name: 'Sun Dust'
  },
  {
    id: 'sun-haze',
    name: 'Sun Haze'
  },
  {
    id: 'sunglasses',
    name: 'Sunglasses'
  },
  {
    id: 'sunrise',
    name: 'Sunrise'
  },
  {
    id: 'sunset',
    name: 'Sunset'
  },
  {
    id: 'superscript',
    name: 'superscript'
  },
  {
    id: 'swatchbook',
    name: 'Swatchbook'
  },
  {
    id: 'sword',
    name: 'Sword'
  },
  {
    id: 'sword-laser',
    name: 'Sword Laser'
  },
  {
    id: 'sword-laser-alt',
    name: 'Sword Laser Alternate'
  },
  {
    id: 'swords',
    name: 'Swords'
  },
  {
    id: 'swords-laser',
    name: 'Swords Laser'
  },
  {
    id: 'symbols',
    name: 'Symbols'
  },
  {
    id: 'synagogue',
    name: 'Synagogue'
  },
  {
    id: 'syringe',
    name: 'Syringe'
  },
  {
    id: 't',
    name: 'T'
  },
  {
    id: 'table',
    name: 'table'
  },
  {
    id: 'table-cells',
    name: 'Table cells'
  },
  {
    id: 'table-cells-large',
    name: 'Table cells large'
  },
  {
    id: 'table-columns',
    name: 'Table columns'
  },
  {
    id: 'table-layout',
    name: 'Table Layout'
  },
  {
    id: 'table-list',
    name: 'Table list'
  },
  {
    id: 'table-picnic',
    name: 'Table Picnic'
  },
  {
    id: 'table-pivot',
    name: 'Table Pivot'
  },
  {
    id: 'table-rows',
    name: 'Table rows'
  },
  {
    id: 'table-tennis-paddle-ball',
    name: 'Table tennis paddle ball'
  },
  {
    id: 'table-tree',
    name: 'Table Tree'
  },
  {
    id: 'tablet',
    name: 'Tablet'
  },
  {
    id: 'tablet-button',
    name: 'Tablet button'
  },
  {
    id: 'tablet-rugged',
    name: 'Rugged Tablet'
  },
  {
    id: 'tablet-screen',
    name: 'Tablet screen'
  },
  {
    id: 'tablet-screen-button',
    name: 'Tablet screen button'
  },
  {
    id: 'tablets',
    name: 'Tablets'
  },
  {
    id: 'tachograph-digital',
    name: 'Tachograph digital'
  },
  {
    id: 'taco',
    name: 'Taco'
  },
  {
    id: 'tag',
    name: 'tag'
  },
  {
    id: 'tags',
    name: 'tags'
  },
  {
    id: 'tally',
    name: 'Tally'
  },
  {
    id: 'tally-1',
    name: 'Tally 1'
  },
  {
    id: 'tally-2',
    name: 'Tally 2'
  },
  {
    id: 'tally-3',
    name: 'Tally 3'
  },
  {
    id: 'tally-4',
    name: 'Tally 4'
  },
  {
    id: 'tape',
    name: 'Tape'
  },
  {
    id: 'taxi',
    name: 'Taxi'
  },
  {
    id: 'taxi-bus',
    name: 'Taxi Bus'
  },
  {
    id: 'teddy-bear',
    name: 'Teddy Bear'
  },
  {
    id: 'teeth',
    name: 'Teeth'
  },
  {
    id: 'teeth-open',
    name: 'Teeth Open'
  },
  {
    id: 'telescope',
    name: 'Telescope'
  },
  {
    id: 'temperature-arrow-down',
    name: 'Temperature arrow down'
  },
  {
    id: 'temperature-arrow-up',
    name: 'Temperature arrow up'
  },
  {
    id: 'temperature-empty',
    name: 'Temperature empty'
  },
  {
    id: 'temperature-full',
    name: 'Temperature full'
  },
  {
    id: 'temperature-half',
    name: 'Temperature half'
  },
  {
    id: 'temperature-high',
    name: 'High Temperature'
  },
  {
    id: 'temperature-list',
    name: 'Temperature List'
  },
  {
    id: 'temperature-low',
    name: 'Low Temperature'
  },
  {
    id: 'temperature-quarter',
    name: 'Temperature quarter'
  },
  {
    id: 'temperature-snow',
    name: 'Temperature snow'
  },
  {
    id: 'temperature-sun',
    name: 'Temperature Sun'
  },
  {
    id: 'temperature-three-quarters',
    name: 'Temperature three quarters'
  },
  {
    id: 'tenge-sign',
    name: 'Tenge sign'
  },
  {
    id: 'tennis-ball',
    name: 'Tennis Ball'
  },
  {
    id: 'terminal',
    name: 'Terminal'
  },
  {
    id: 'text',
    name: 'Text'
  },
  {
    id: 'text-height',
    name: 'text-height'
  },
  {
    id: 'text-size',
    name: 'Text Size'
  },
  {
    id: 'text-slash',
    name: 'Text slash'
  },
  {
    id: 'text-width',
    name: 'Text Width'
  },
  {
    id: 'thermometer',
    name: 'Thermometer'
  },
  {
    id: 'theta',
    name: 'Theta'
  },
  {
    id: 'thought-bubble',
    name: 'Thought Bubble'
  },
  {
    id: 'thumbs-down',
    name: 'thumbs-down'
  },
  {
    id: 'thumbs-up',
    name: 'thumbs-up'
  },
  {
    id: 'thumbtack',
    name: 'Thumbtack'
  },
  {
    id: 'tick',
    name: 'Tick'
  },
  {
    id: 'ticket',
    name: 'Ticket'
  },
  {
    id: 'ticket-airline',
    name: 'Ticket Airline'
  },
  {
    id: 'ticket-simple',
    name: 'Ticket simple'
  },
  {
    id: 'tickets-airline',
    name: 'Tickets Airline'
  },
  {
    id: 'tilde',
    name: 'Tilde'
  },
  {
    id: 'timeline',
    name: 'Timeline'
  },
  {
    id: 'timeline-arrow',
    name: 'Timeline Arrow'
  },
  {
    id: 'timer',
    name: 'Timer'
  },
  {
    id: 'tire',
    name: 'Tire'
  },
  {
    id: 'tire-flat',
    name: 'Tire Flat'
  },
  {
    id: 'tire-pressure-warning',
    name: 'Tire Pressure-warning'
  },
  {
    id: 'tire-rugged',
    name: 'Tire Rugged'
  },
  {
    id: 'toggle-off',
    name: 'Toggle Off'
  },
  {
    id: 'toggle-on',
    name: 'Toggle On'
  },
  {
    id: 'toilet',
    name: 'Toilet'
  },
  {
    id: 'toilet-paper',
    name: 'Toilet Paper'
  },
  {
    id: 'toilet-paper-blank',
    name: 'Toilet paper blank'
  },
  {
    id: 'toilet-paper-blank-under',
    name: 'Toilet paper blank under'
  },
  {
    id: 'toilet-paper-slash',
    name: 'Toilet Paper Slash'
  },
  {
    id: 'toilet-paper-under',
    name: 'Toilet paper under'
  },
  {
    id: 'toilet-paper-under-slash',
    name: 'Toilet paper under slash'
  },
  {
    id: 'tomato',
    name: 'Tomato'
  },
  {
    id: 'tombstone',
    name: 'Tombstone'
  },
  {
    id: 'tombstone-blank',
    name: 'Tombstone blank'
  },
  {
    id: 'toolbox',
    name: 'Toolbox'
  },
  {
    id: 'tooth',
    name: 'Tooth'
  },
  {
    id: 'toothbrush',
    name: 'Toothbrush'
  },
  {
    id: 'torii-gate',
    name: 'Torii Gate'
  },
  {
    id: 'tornado',
    name: 'Tornado'
  },
  {
    id: 'tower-broadcast',
    name: 'Tower broadcast'
  },
  {
    id: 'tower-control',
    name: 'Tower Control'
  },
  {
    id: 'tractor',
    name: 'Tractor'
  },
  {
    id: 'trademark',
    name: 'Trademark'
  },
  {
    id: 'traffic-cone',
    name: 'Traffic Cone'
  },
  {
    id: 'traffic-light',
    name: 'Traffic Light'
  },
  {
    id: 'traffic-light-go',
    name: 'Traffic Light-go'
  },
  {
    id: 'traffic-light-slow',
    name: 'Traffic Light-slow'
  },
  {
    id: 'traffic-light-stop',
    name: 'Traffic Light-stop'
  },
  {
    id: 'trailer',
    name: 'Trailer'
  },
  {
    id: 'train',
    name: 'Train'
  },
  {
    id: 'train-subway',
    name: 'Train subway'
  },
  {
    id: 'train-subway-tunnel',
    name: 'Train subway tunnel'
  },
  {
    id: 'train-tram',
    name: 'Train tram'
  },
  {
    id: 'transformer-bolt',
    name: 'Transformer Bolt'
  },
  {
    id: 'transgender',
    name: 'Transgender'
  },
  {
    id: 'transporter',
    name: 'Transporter'
  },
  {
    id: 'transporter-1',
    name: 'Transporter 1'
  },
  {
    id: 'transporter-2',
    name: 'Transporter 2'
  },
  {
    id: 'transporter-3',
    name: 'Transporter 3'
  },
  {
    id: 'transporter-4',
    name: 'Transporter 4'
  },
  {
    id: 'transporter-5',
    name: 'Transporter 5'
  },
  {
    id: 'transporter-6',
    name: 'Transporter 6'
  },
  {
    id: 'transporter-7',
    name: 'Transporter 7'
  },
  {
    id: 'transporter-empty',
    name: 'Transporter Empty'
  },
  {
    id: 'trash',
    name: 'Trash'
  },
  {
    id: 'trash-arrow-up',
    name: 'Trash arrow up'
  },
  {
    id: 'trash-can',
    name: 'Trash can'
  },
  {
    id: 'trash-can-arrow-up',
    name: 'Trash can arrow up'
  },
  {
    id: 'trash-can-check',
    name: 'Trash Can-check'
  },
  {
    id: 'trash-can-clock',
    name: 'Trash Can-clock'
  },
  {
    id: 'trash-can-list',
    name: 'Trash Can-list'
  },
  {
    id: 'trash-can-plus',
    name: 'Trash Can-plus'
  },
  {
    id: 'trash-can-slash',
    name: 'Trash can slash'
  },
  {
    id: 'trash-can-undo',
    name: 'Trash can undo'
  },
  {
    id: 'trash-can-xmark',
    name: 'Trash Can-xmark'
  },
  {
    id: 'trash-check',
    name: 'Trash Check'
  },
  {
    id: 'trash-clock',
    name: 'Trash Clock'
  },
  {
    id: 'trash-list',
    name: 'Trash List'
  },
  {
    id: 'trash-plus',
    name: 'Trash Plus'
  },
  {
    id: 'trash-slash',
    name: 'Trash Slash'
  },
  {
    id: 'trash-undo',
    name: 'Trash Undo'
  },
  {
    id: 'trash-xmark',
    name: 'Trash Xmark'
  },
  {
    id: 'treasure-chest',
    name: 'Treasure Chest'
  },
  {
    id: 'tree',
    name: 'Tree'
  },
  {
    id: 'tree-christmas',
    name: 'Christmas Tree'
  },
  {
    id: 'tree-deciduous',
    name: 'Tree deciduous'
  },
  {
    id: 'tree-decorated',
    name: 'Tree Decorated'
  },
  {
    id: 'tree-large',
    name: 'Tree Large'
  },
  {
    id: 'tree-palm',
    name: 'Palm Tree'
  },
  {
    id: 'trees',
    name: 'Trees'
  },
  {
    id: 'triangle',
    name: 'Triangle'
  },
  {
    id: 'triangle-exclamation',
    name: 'Triangle exclamation'
  },
  {
    id: 'triangle-instrument',
    name: 'Triangle instrument'
  },
  {
    id: 'triangle-person-digging',
    name: 'Triangle person digging'
  },
  {
    id: 'trophy',
    name: 'trophy'
  },
  {
    id: 'trophy-star',
    name: 'Trophy star'
  },
  {
    id: 'truck',
    name: 'truck'
  },
  {
    id: 'truck-bolt',
    name: 'Truck Bolt'
  },
  {
    id: 'truck-clock',
    name: 'Truck clock'
  },
  {
    id: 'truck-container',
    name: 'Truck Container'
  },
  {
    id: 'truck-container-empty',
    name: 'Truck Container-empty'
  },
  {
    id: 'truck-fast',
    name: 'Truck fast'
  },
  {
    id: 'truck-flatbed',
    name: 'Truck Flatbed'
  },
  {
    id: 'truck-front',
    name: 'Truck Front'
  },
  {
    id: 'truck-medical',
    name: 'Truck medical'
  },
  {
    id: 'truck-monster',
    name: 'Truck Monster'
  },
  {
    id: 'truck-moving',
    name: 'Truck Moving'
  },
  {
    id: 'truck-pickup',
    name: 'Truck Side'
  },
  {
    id: 'truck-plow',
    name: 'Truck Plow'
  },
  {
    id: 'truck-ramp',
    name: 'Truck Ramp'
  },
  {
    id: 'truck-ramp-box',
    name: 'Truck ramp box'
  },
  {
    id: 'truck-ramp-couch',
    name: 'Truck ramp couch'
  },
  {
    id: 'truck-tow',
    name: 'Truck Tow'
  },
  {
    id: 'trumpet',
    name: 'Trumpet'
  },
  {
    id: 'tty',
    name: 'TTY'
  },
  {
    id: 'tty-answer',
    name: 'Tty Answer'
  },
  {
    id: 'tugrik-sign',
    name: 'Tugrik Sign'
  },
  {
    id: 'turkey',
    name: 'Turkey'
  },
  {
    id: 'turkish-lira-sign',
    name: 'Turkish Lira-sign'
  },
  {
    id: 'turn-down',
    name: 'Turn down'
  },
  {
    id: 'turn-down-left',
    name: 'Turn Down Left'
  },
  {
    id: 'turn-up',
    name: 'Turn up'
  },
  {
    id: 'turntable',
    name: 'Turntable'
  },
  {
    id: 'turtle',
    name: 'Turtle'
  },
  {
    id: 'tv',
    name: 'Television'
  },
  {
    id: 'tv-music',
    name: 'TV Music'
  },
  {
    id: 'tv-retro',
    name: 'Retro Televison'
  },
  {
    id: 'typewriter',
    name: 'Typewriter'
  },
  {
    id: 'u',
    name: 'U'
  },
  {
    id: 'ufo',
    name: 'U.F.O.'
  },
  {
    id: 'ufo-beam',
    name: 'U.F.O. with Beam'
  },
  {
    id: 'umbrella',
    name: 'Umbrella'
  },
  {
    id: 'umbrella-beach',
    name: 'Umbrella Beach'
  },
  {
    id: 'umbrella-simple',
    name: 'Umbrella simple'
  },
  {
    id: 'underline',
    name: 'Underline'
  },
  {
    id: 'unicorn',
    name: 'Unicorn'
  },
  {
    id: 'uniform-martial-arts',
    name: 'Martial Arts Uniform'
  },
  {
    id: 'union',
    name: 'Union'
  },
  {
    id: 'universal-access',
    name: 'Universal Access'
  },
  {
    id: 'unlock',
    name: 'unlock'
  },
  {
    id: 'unlock-keyhole',
    name: 'Unlock keyhole'
  },
  {
    id: 'up',
    name: 'Up'
  },
  {
    id: 'up-down',
    name: 'Up down'
  },
  {
    id: 'up-down-left-right',
    name: 'Up down left right'
  },
  {
    id: 'up-from-line',
    name: 'Up from line'
  },
  {
    id: 'up-left',
    name: 'Up Left'
  },
  {
    id: 'up-long',
    name: 'Up long'
  },
  {
    id: 'up-right',
    name: 'Up Right'
  },
  {
    id: 'up-right-and-down-left-from-center',
    name: 'Up right and down left from center'
  },
  {
    id: 'up-right-from-square',
    name: 'Up right from square'
  },
  {
    id: 'up-to-line',
    name: 'Up to line'
  },
  {
    id: 'upload',
    name: 'Upload'
  },
  {
    id: 'usb-drive',
    name: 'USB Drive'
  },
  {
    id: 'user',
    name: 'User'
  },
  {
    id: 'user-alien',
    name: 'User Alien'
  },
  {
    id: 'user-astronaut',
    name: 'User Astronaut'
  },
  {
    id: 'user-bounty-hunter',
    name: 'User Bounty Hunter'
  },
  {
    id: 'user-check',
    name: 'User Check'
  },
  {
    id: 'user-chef',
    name: 'User Chef'
  },
  {
    id: 'user-clock',
    name: 'User Clock'
  },
  {
    id: 'user-cowboy',
    name: 'User Cowboy'
  },
  {
    id: 'user-crown',
    name: 'User Crown'
  },
  {
    id: 'user-doctor',
    name: 'User doctor'
  },
  {
    id: 'user-doctor-message',
    name: 'User doctor message'
  },
  {
    id: 'user-gear',
    name: 'User gear'
  },
  {
    id: 'user-graduate',
    name: 'User Graduate'
  },
  {
    id: 'user-group',
    name: 'User group'
  },
  {
    id: 'user-group-crown',
    name: 'User group crown'
  },
  {
    id: 'user-hair-buns',
    name: 'User Hair in Buns'
  },
  {
    id: 'user-headset',
    name: 'User Headset'
  },
  {
    id: 'user-helmet-safety',
    name: 'User helmet safety'
  },
  {
    id: 'user-injured',
    name: 'User Injured'
  },
  {
    id: 'user-large',
    name: 'User large'
  },
  {
    id: 'user-large-slash',
    name: 'User large slash'
  },
  {
    id: 'user-lock',
    name: 'User Lock'
  },
  {
    id: 'user-minus',
    name: 'User Minus'
  },
  {
    id: 'user-music',
    name: 'User Music'
  },
  {
    id: 'user-ninja',
    name: 'User Ninja'
  },
  {
    id: 'user-nurse',
    name: 'Nurse'
  },
  {
    id: 'user-pen',
    name: 'User pen'
  },
  {
    id: 'user-pilot',
    name: 'User Pilot'
  },
  {
    id: 'user-pilot-tie',
    name: 'User Pilot-tie'
  },
  {
    id: 'user-plus',
    name: 'User Plus'
  },
  {
    id: 'user-police',
    name: 'User Police'
  },
  {
    id: 'user-police-tie',
    name: 'User Police Tie'
  },
  {
    id: 'user-robot',
    name: 'User Robot'
  },
  {
    id: 'user-secret',
    name: 'User Secret'
  },
  {
    id: 'user-shakespeare',
    name: 'User Shakespeare'
  },
  {
    id: 'user-shield',
    name: 'User Shield'
  },
  {
    id: 'user-slash',
    name: 'User Slash'
  },
  {
    id: 'user-tag',
    name: 'User Tag'
  },
  {
    id: 'user-tie',
    name: 'User Tie'
  },
  {
    id: 'user-unlock',
    name: 'User Unlock'
  },
  {
    id: 'user-visor',
    name: 'User Visor'
  },
  {
    id: 'user-xmark',
    name: 'User X Mark'
  },
  {
    id: 'users',
    name: 'Users'
  },
  {
    id: 'users-gear',
    name: 'Users gear'
  },
  {
    id: 'users-medical',
    name: 'Users with Medical Symbol'
  },
  {
    id: 'users-slash',
    name: 'Users Slash'
  },
  {
    id: 'utensils',
    name: 'Utensils'
  },
  {
    id: 'utility-pole',
    name: 'Utility Pole'
  },
  {
    id: 'utility-pole-double',
    name: 'Utility Pole-double'
  },
  {
    id: 'v',
    name: 'V'
  },
  {
    id: 'vacuum',
    name: 'Vacuum'
  },
  {
    id: 'vacuum-robot',
    name: 'Robot Vacuum'
  },
  {
    id: 'value-absolute',
    name: 'Value Absolute'
  },
  {
    id: 'van-shuttle',
    name: 'Van shuttle'
  },
  {
    id: 'vault',
    name: 'Vault'
  },
  {
    id: 'vector-circle',
    name: 'Vector Circle'
  },
  {
    id: 'vector-polygon',
    name: 'Vector Polygon'
  },
  {
    id: 'vector-square',
    name: 'Vector Square'
  },
  {
    id: 'venus',
    name: 'Venus'
  },
  {
    id: 'venus-double',
    name: 'Venus Double'
  },
  {
    id: 'venus-mars',
    name: 'Venus Mars'
  },
  {
    id: 'vest',
    name: 'vest'
  },
  {
    id: 'vest-patches',
    name: 'vest-patches'
  },
  {
    id: 'vial',
    name: 'Vial'
  },
  {
    id: 'vials',
    name: 'Vials'
  },
  {
    id: 'video',
    name: 'Video'
  },
  {
    id: 'video-arrow-down-left',
    name: 'Video Arrow-down-left'
  },
  {
    id: 'video-arrow-up-right',
    name: 'Video Arrow-up-right'
  },
  {
    id: 'video-plus',
    name: 'Video Plus'
  },
  {
    id: 'video-slash',
    name: 'Video Slash'
  },
  {
    id: 'vihara',
    name: 'Vihara'
  },
  {
    id: 'violin',
    name: 'Violin'
  },
  {
    id: 'virus',
    name: 'Virus'
  },
  {
    id: 'virus-slash',
    name: 'Virus Slash'
  },
  {
    id: 'viruses',
    name: 'Viruses'
  },
  {
    id: 'voicemail',
    name: 'Voicemail'
  },
  {
    id: 'volcano',
    name: 'Volcano'
  },
  {
    id: 'volleyball',
    name: 'Volleyball Ball'
  },
  {
    id: 'volume',
    name: 'Volume'
  },
  {
    id: 'volume-high',
    name: 'Volume high'
  },
  {
    id: 'volume-low',
    name: 'Volume low'
  },
  {
    id: 'volume-off',
    name: 'Volume Off'
  },
  {
    id: 'volume-slash',
    name: 'Volume Slash'
  },
  {
    id: 'volume-xmark',
    name: 'Volume X Mark'
  },
  {
    id: 'vr-cardboard',
    name: 'Cardboard VR'
  },
  {
    id: 'w',
    name: 'W'
  },
  {
    id: 'wagon-covered',
    name: 'Covered Wagon'
  },
  {
    id: 'walker',
    name: 'Walker'
  },
  {
    id: 'walkie-talkie',
    name: 'Walkie Talkie'
  },
  {
    id: 'wallet',
    name: 'Wallet'
  },
  {
    id: 'wand',
    name: 'Wand'
  },
  {
    id: 'wand-magic',
    name: 'Wand magic'
  },
  {
    id: 'wand-magic-sparkles',
    name: 'Wand magic sparkles'
  },
  {
    id: 'wand-sparkles',
    name: 'Wand sparkles'
  },
  {
    id: 'warehouse',
    name: 'Warehouse'
  },
  {
    id: 'warehouse-full',
    name: 'Warehouse full'
  },
  {
    id: 'washing-machine',
    name: 'Washing machine'
  },
  {
    id: 'watch',
    name: 'Watch'
  },
  {
    id: 'watch-apple',
    name: 'Watch Apple'
  },
  {
    id: 'watch-calculator',
    name: 'Calculator Watch'
  },
  {
    id: 'watch-fitness',
    name: 'Watch Fitness'
  },
  {
    id: 'watch-smart',
    name: 'Watch Smart'
  },
  {
    id: 'water',
    name: 'Water'
  },
  {
    id: 'water-arrow-down',
    name: 'Water arrow down'
  },
  {
    id: 'water-arrow-up',
    name: 'Water arrow up'
  },
  {
    id: 'water-ladder',
    name: 'Water ladder'
  },
  {
    id: 'watermelon-slice',
    name: 'Watermelon Slice'
  },
  {
    id: 'wave-pulse',
    name: 'Wave Pulse'
  },
  {
    id: 'wave-sine',
    name: 'Sine Wave'
  },
  {
    id: 'wave-square',
    name: 'Square Wave'
  },
  {
    id: 'wave-triangle',
    name: 'Triangle Wave'
  },
  {
    id: 'waveform',
    name: 'Waveform'
  },
  {
    id: 'waveform-lines',
    name: 'Waveform lines'
  },
  {
    id: 'weight-hanging',
    name: 'Hanging Weight'
  },
  {
    id: 'weight-scale',
    name: 'Weight scale'
  },
  {
    id: 'whale',
    name: 'Whale'
  },
  {
    id: 'wheat',
    name: 'Wheat'
  },
  {
    id: 'wheat-awn',
    name: 'Wheat awn'
  },
  {
    id: 'wheat-awn-slash',
    name: 'Wheat Awn Slash'
  },
  {
    id: 'wheat-slash',
    name: 'Wheat Slash'
  },
  {
    id: 'wheelchair',
    name: 'Wheelchair'
  },
  {
    id: 'wheelchair-move',
    name: 'Wheelchair move'
  },
  {
    id: 'whiskey-glass',
    name: 'Whiskey glass'
  },
  {
    id: 'whiskey-glass-ice',
    name: 'Whiskey glass ice'
  },
  {
    id: 'whistle',
    name: 'Whistle'
  },
  {
    id: 'wifi',
    name: 'WiFi'
  },
  {
    id: 'wifi-exclamation',
    name: 'Wifi Exclamation'
  },
  {
    id: 'wifi-fair',
    name: 'Wifi fair'
  },
  {
    id: 'wifi-slash',
    name: 'Wifi Slash'
  },
  {
    id: 'wifi-weak',
    name: 'Wifi weak'
  },
  {
    id: 'wind',
    name: 'Wind'
  },
  {
    id: 'wind-turbine',
    name: 'Wind Turbine'
  },
  {
    id: 'wind-warning',
    name: 'Wind Warning'
  },
  {
    id: 'window',
    name: 'Window'
  },
  {
    id: 'window-flip',
    name: 'Window flip'
  },
  {
    id: 'window-frame',
    name: 'House Window'
  },
  {
    id: 'window-frame-open',
    name: 'House Window Open'
  },
  {
    id: 'window-maximize',
    name: 'Window Maximize'
  },
  {
    id: 'window-minimize',
    name: 'Window Minimize'
  },
  {
    id: 'window-restore',
    name: 'Window Restore'
  },
  {
    id: 'windsock',
    name: 'Windsock'
  },
  {
    id: 'wine-bottle',
    name: 'Wine Bottle'
  },
  {
    id: 'wine-glass',
    name: 'Wine Glass'
  },
  {
    id: 'wine-glass-crack',
    name: 'Wine glass crack'
  },
  {
    id: 'wine-glass-empty',
    name: 'Wine glass empty'
  },
  {
    id: 'won-sign',
    name: 'Won Sign'
  },
  {
    id: 'wreath',
    name: 'Wreath'
  },
  {
    id: 'wrench',
    name: 'Wrench'
  },
  {
    id: 'wrench-simple',
    name: 'Wrench Simple'
  },
  {
    id: 'x',
    name: 'X'
  },
  {
    id: 'x-ray',
    name: 'X-Ray'
  },
  {
    id: 'xmark',
    name: 'X Mark'
  },
  {
    id: 'xmark-to-slot',
    name: 'X Mark to Slot'
  },
  {
    id: 'y',
    name: 'Y'
  },
  {
    id: 'yen-sign',
    name: 'Yen Sign'
  },
  {
    id: 'yin-yang',
    name: 'Yin Yang'
  },
  {
    id: 'z',
    name: 'Z'
  }
];
