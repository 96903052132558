import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckChecklist, CheckChecklistDataApi, CheckChecklistRepository, CheckResult } from '@blink/api';
import { CheckTranslateService } from '@blink/util';
import { Router } from '@angular/router';
import { BlinkIcon, uiCancelButton, UiDialogService, uiPrimaryButton } from '@blink/ui';
import { Platform, PopoverController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { ResultsLineItemDropdownComponent } from './results-line-item-dropdown.component';

@Component({
  selector: 'bc-results-line-item',
  templateUrl: './results-line-item.component.html',
  styleUrls: ['./results-line-item.component.scss']
})
export class ResultsLineItemComponent {

  @Input() result: CheckResult;
  @Output() reload = new EventEmitter<void>();
  checklist$: Observable<CheckChecklist>;
  userCanDelete = false;
  checklistName: string;
  protected readonly BlinkIcon = BlinkIcon;

  constructor(public t: CheckTranslateService,
              private router: Router,
              private uiDialogService: UiDialogService,
              private popoverController: PopoverController,
              private checklistRepo: CheckChecklistRepository,
              private checklistDataApi: CheckChecklistDataApi,
              public platform: Platform) {
  }

  get contextActionsAvailable() {
    return this.userCanDelete;
  }

  ngOnInit(): void {
    this.checklist$ = this.checklistRepo.get(this.result.ChecklistId).pipe(
      tap(checklist => {
        this.userCanDelete = checklist?.CurrentUserCanUpdate;
        this.checklistName = checklist?.Name;
      })
    );
  }

  async deleteChecklistData($event: MouseEvent, checklistData: CheckResult) {
    $event.stopPropagation();
    this.uiDialogService.confirm({
      title: this.t.results.delete.title,
      text: this.t.results.delete.message,
      textOptions: {checklistName: this.checklistName},
      buttons: [
        uiCancelButton(),
        uiPrimaryButton(
          this.t.global.delete,
          () => this.checklistDataApi.delete(checklistData.Id).subscribe(() => {
              this.reload.emit();
              this.uiDialogService.toast(this.t.results.delete.success, 'success');
            }
          ))
      ]
    });
  }

  async openDropdown(event: MouseEvent, checklistData: CheckResult) {
    event.stopPropagation();
    const dropdown = await this.popoverController.create({
      component: ResultsLineItemDropdownComponent,
      event: event,
      showBackdrop: false
    });

    dropdown.onWillDismiss().then(result => {
      if (result.role === 'deleteChecklistData') {
        this.deleteChecklistData(event, checklistData)
      }
    });

    await dropdown.present();
  }

  openChecklistData(checklistData: CheckResult) {
    void this.router.navigate([
        '/results',
        checklistData.ChecklistId,
        'detail',
        checklistData.Id
      ],
      {
        state: {checklistData: checklistData}
      });
  }

}
