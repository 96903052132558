import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstUppercase'
})
export class FirstLetterUpperCasePipe implements PipeTransform {
  transform(value: string) {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
  }
}
