export default {
  config: {
    checklist: {
      checklist: 'Predložak obrasca',
      checklists: 'Predlošci obrazaca',
      none: 'Nema dostupnih obrazaca.',
      new: 'Novi predložak obrasca',
      edit: 'Uredi predložak obrasca',
      saved: 'Predložak obrasca je spremljen.',
      delete: 'Brisanje predloška obrasca',
      deleteChecklistResults: 'Također izbrišite sve dovršene obrasce predloška obrasca {{name}}?',
      deleteInfo: 'Ako izbrišete predložak obrasca, nepovratno će se izbrisati i svi ispunjeni obrasci! <br><br>Da biste zadržali ispunjene obrasce, deaktivirajte predložak obrasca.',
      deleted: 'Predložak obrasca je izbrisan.',
      duplicate: 'Duplicirani predložak obrasca',
      duplicateInfo: 'Funkciju dupliciranja možete koristiti za dupliciranje predložaka obrazaca',
      duplicateMessage: 'Želite li duplicirati predložak obrasca "{{checklistName}}" sa svim sadržajem?',
      duplicated: 'Predložak obrasca je dupliciran.',
      templateName: 'Naziv obrasca',
      activeInfo: 'Mogu se ispuniti samo aktivni predlošci; neaktivni predlošci mogu se uređivati samo u upravljanju predlošcima.',
      locationRequired: 'Odabir objekta je obavezan',
      locationRequiredInfo: 'Ako je aktivirano, potrebno je odabrati objekt ili skenirati QR kod da biste ispunili obrazac.',
      loginUserRequired: 'Odabir zaposlenika je obavezan',
      loginUserRequiredInfo: 'Ako je aktiviran, potrebno je odabrati zaposlenika koji će ispuniti obrazac.',
      allLocationAllowed: 'Dopusti sve objekte',
      allowOnlyCertainLocations: 'Dopusti samo određene objekte',
      public: 'Javni oblik',
      publicInfo: 'Napravite javnu poveznicu pomoću koje obrazac može ispuniti bilo koji korisnik. Nije potrebna prijava ili aplikacija. Možete odrediti datum valjanosti do kada bi poveznica trebala vrijediti.',
      preview: 'Omogući pregled',
      previewInfo: 'Ako je aktiviran, gumb "Pregled" uvijek će biti prikazan na dnu obrasca prilikom ispunjavanja. Kao alternativu stalnom gumbu za pretpregled, također možete postaviti element sadržaja "Pregled" bilo gdje na obrascu (ova funkcija ne mora biti aktivirana).',
      shortUrlExpireDate: 'Datum isteka',
      sysadminOnly: 'Samo administratori sustava',
      allUsers: 'Svi korisnici',
      usersOfPermissionGroup: 'Korisnici ovih autorizacijskih grupa',
      emailConfig: 'Konfiguracija e-pošte',
      emailConfigInfo: 'Ako ovdje unesete adrese e-pošte, ispunjeni obrasci bit će automatski poslani na te adrese e-pošte.',
      emailSaved: 'E-mail adresa je spremljena',
      emailRemoved: 'Adresa e-pošte je izbrisana',
      importExportInfo: 'Funkcija izvoza omogućuje vam izvoz predložaka obrasca i povezanih predložaka izvješća kako biste ih ponovno uvezli u drugi Blink Check klijent ili sustav.',
      import: 'Uvoz predloška obrasca',
      importInfo: 'Odaberite datoteku .check-b64 predloška obrasca koji želite uvesti.',
      imported: 'Obrazac je uspješno kreiran.',
      templatesInAppFolder: 'Predlošci u imeniku aplikacija:',
      invalidImport: 'Odabrana datoteka nije važeći predložak obrasca.',
      exported: 'Predložak obrasca je izvezen',
      offline: 'Uspostavite internetsku vezu za uređivanje predložaka obrazaca.',
      checklistContainsCreateTask: 'Predložak obrasca sadrži element "Kreiraj ulaznicu" i stoga se ne može postaviti kao javni. Uklonite element "Izradi kartu".'
    },
    category: {
      category: 'kategorija',
      categories: 'Kategorije',
      saved: 'Kategorija je spremljena.',
      editTitle: 'Uredi kategoriju',
      content: 'elementi',
      newCategory: 'Nova kategorija',
      name: 'Ime kategorije',
      edit: 'Uredi kategoriju',
      duplicated: 'Kategorija je duplicirana.',
      noCategories: 'Još nema kategorija. Stvorite nove kategorije pomoću gumba "Dodaj kategoriju".',
      inactive: 'neaktivan',
      showInactive: 'Prikaži neaktivne',
      duplicateCategoryConfirmHeader: 'Duplicirana kategorija',
      duplicateCategoryConfirm: 'Želite li duplicirati kategoriju "{{categoryName}}" sa svim njezinim sadržajem?',
      delete: 'Izbriši kategoriju',
      deleteCategoryConfirm: 'Jeste li sigurni da želite izbrisati kategoriju "{{categoryName}}"?',
      deleteSuccess: 'Kategorija je uklonjena.'
    },
    group: {
      group: 'Stranica',
      groups: 'stranice',
      created: 'Stranica je napravljena.',
      updated: 'Promjene na stranici su spremljene.',
      updateError: 'Stranica nije mogla biti spremljena. Molimo pokušajte ponovo kasnije.',
      rename: 'Preimenuj stranicu',
      saved: 'Stranica je spremljena.',
      toggleSort: 'Promjena sortiranja',
      editGroupName: 'Uredi naziv stranice',
      new: 'Nova stranica',
      name: 'Naziv stranice',
      duplicated: 'Stranica je duplicirana.',
      noGroups: 'Još nema stranica. Stvorite nove stranice pomoću gumba "Dodaj stranicu".',
      noItems: 'Još nema elemenata. Stvorite nove elemente pomoću gumba "Dodaj element".',
      add: 'Dodaj stranicu',
      infoElement: 'Info element',
      inputElement: 'Ulazni element',
      addInfoElement: 'Dodaj info element',
      addInputElement: 'Dodaj ulazni element',
      delete: 'Izbriši stranicu',
      deleteGroupConfirm: 'Jeste li sigurni da želite izbrisati stranicu "{{groupName}}"?',
      chooseGroup: 'Na koju stranicu treba dodati element?',
      duplicateGroupConfirmHeader: 'Duplicirana stranica',
      duplicateGroupConfirm: 'Želite li duplicirati stranicu "{{groupName}}" sa svim sadržajem?',
      deleteSuccess: 'Stranica je uklonjena.'
    },
    item: {
      add: 'Dodaj element',
      created: 'Stavka je stvorena',
      saved: 'Stavka je spremljena.',
      chooseItemType: 'Odaberite stavku',
      values: 'Vrijednosti',
      value: 'Vrijednost',
      ratingNumber: 'Evaluacija',
      addItemValue: 'Dodajte novu vrijednost',
      addItemValueSuccess: 'Dodana je nova vrijednost',
      updateItemValueSuccess: 'Vrijednost je ažurirana',
      removeItemValueSuccess: 'Vrijednost je uklonjena',
      removeItemValue: 'Ukloni vrijednost',
      takePictureButtonText: 'Odaberite sliku',
      editTitle: 'Uredi element',
      editDependenciesTitle: 'Uredi ovisnosti',
      required: 'Obavezno polje',
      name: 'Naziv elementa',
      description: 'izborni opis elementa',
      points: 'Bodovi',
      duplicated: 'Stavka je duplicirana.',
      duplicateItemConfirmHeader: 'Duplicirani element',
      duplicateItemConfirm: 'Želite li duplicirati element "{{itemName}}" sa svim njegovim sadržajem?',
      delete: {
        title: 'Izbriši stavku',
        message: 'Želite li stvarno izbrisati element “{{itemName}}”?',
        success: 'Stavka je uklonjena'
      },
      moveItem: 'Premjesti element',
      move: 'Potez',
      moveItemInfo: 'Odaberite kategoriju i stranicu na koju želite premjestiti trenutnu stavku.',
      itemMoved: 'Stavka je premještena'
    },
    editItem: {
      name: 'Prezime',
      nameInfo: 'Ovaj tekst se prikazuje neposredno prije elementa unosa.',
      link: 'Adresa veze',
      linkInfo: 'Ova će adresa biti dostupna putem gumba. Unesite važeći URL.',
      description: 'Opis',
      descriptionInfo: 'Ako želite dati informacije o tome kako ga ispuniti, možete ga unijeti ovdje.',
      linkDescription: 'oznaka gumba',
      linkDescriptionInfo: 'Unesite oznaku za gumb.',
      values: 'Vrijednosti',
      valuesInfo: 'Za svaku vrijednost možete odrediti broj ocjene. Ocjenjivanje možete izvršiti kasnije koristeći broj ocjene. Broj ocjene nije obavezan.',
      value: 'Vrijednost',
      valuePlaceholder: 'Nova vrijednost',
      ratingNumber: 'Evaluacija',
      requiredField: 'Obavezno polje',
      requiredFieldInfo: 'Ako je aktivirano kao obavezno polje, tada ovo polje mora biti ispunjeno kako bi se obrazac mogao poslati.',
      externalNo: 'Eksterni broj',
      externalNoInfo: 'Dodatne informacije za sučelja ili evaluacije putem vanjskih sustava.',
      editable: 'Može se uređivati',
      editableInfo: 'Da bi korisnici mogli unositi podatke, element se mora moći uređivati.',
      defaultValue: 'Navedite zadanu vrijednost',
      defaultValueInfo: 'Za ovaj element možete odrediti standardnu dodjelu.',
      defaultValueInfoEditable: 'Vrijednost može promijeniti korisnik obrasca.',
      visible: 'vidljivost',
      visibleInfo: 'Vidljivost kontrolira može li uređivač vidjeti element ili ne.',
      externalNoAleradyUsed: 'Ovaj vanjski broj je već u upotrebi.',
      taskTypeId: 'vrsta ulaznice',
      loginUserId: 'korisnik',
      locationId: 'ime objekta',
      videoId: 'video broj',
      videoType: 'video usluge',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'vimeo',
      videoInfo: 'Ovdje unesite jedinstveni ID videozapisa. To možete pronaći, primjerice, u URL-u videozapisa. <br/>Primjer: https://www.youtube.com/watch?v=XXXXX ili na Vimeu https://vimeo.com/XXXXX. <br/>U ovom slučaju, XXXXX bi trebalo unijeti u polje.',
      defaultTaskTitle: 'Zadani naslov ulaznice',
      defaultTaskDescription: 'Zadani opis karte',
      titleEditable: 'Korisnik može uređivati naslov ulaznice',
      descriptionEditable: 'Korisnik može uređivati opis ulaznice',
      dueDateEditable: 'Datum dospijeća ulaznice može uređivati korisnik',
      addReportAttachment: 'Ispunjeni obrazac priložite ulaznici kao PDF',
      noTaskTypesFound: 'Nisu pronađene vrste karata. Vrstama ulaznica može se upravljati putem Blink Active.',
      noLoginUsersFound: 'Nije moguće pronaći korisnike. Korisnicima se može upravljati putem Blink Managera.',
      noLocationsFound: 'Nije moguće pronaći nijedan predmet. Objektima se može upravljati putem Blink Managera.',
      createTaskItemInfo: 'S ovom funkcijom možete stvoriti Blink Active Tickets. Možete navesti naslov i opis ili zamoliti korisnika da ih ispuni. Objekt se dodjeljuje automatski, a odgovorni upravitelj objekta dodjeljuje se kao procesor.',
      duplicatedValuesNotAllowed: 'Duplikati vrijednosti nisu dopušteni.',
      valueLengthExceeded: 'Unesena vrijednost je predugačka i bit će skraćena.'
    },
    itemTypes: {
      PreviewButton: 'pregled dokumenta',
      Select: 'Odabir s popisa',
      Signature: 'Potpis',
      Email: 'unos e-pošte',
      SendEmailTo: 'E-mail slanje',
      Date: 'Datum',
      Time: 'vrijeme',
      Boolean: 'Da-ne pitanje',
      TakePicture: 'fotografirati',
      TakeMultiPicture: 'fotografirati',
      Scan: 'QR / EAN skeniranje',
      Input: 'unos teksta',
      Textarea: 'Višeredni unos teksta',
      Link: 'povezivanje',
      Picture: 'prikaz slike',
      Video: 'video prikaz',
      Text: 'prikaz teksta',
      Header: 'naslov',
      Rate: 'Evaluacija',
      RateSmiley: 'smajli ocjena',
      CreateTask: 'Izradi ulaznicu',
      FileUpload: 'Učitaj datoteke',
      Currency: 'valuta'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Pogledajte pregled obrasca.',
      Select: 'Definirajte sve stavke popisa iz kojih se jedna može odabrati.',
      Signature: 'Polje za digitalni potpis (može se koristiti prstom)',
      Email: 'Korisnik može dati valjanu adresu e-pošte.',
      SendEmailTo: 'Korisnik može dati valjanu e-mail adresu na koju će ispunjeni obrazac biti poslan u PDF-u.',
      Date: 'Korisnik može odrediti datum putem kalendara',
      Time: 'Korisnik može odrediti vrijeme',
      Boolean: 'Korisnik bira da ili ne',
      TakePicture: 'Korisnik može snimiti fotografiju fotoaparatom ili učitati sliku.',
      TakeMultiPicture: 'Korisnik može snimiti više fotografija kamerom ili učitati slike.',
      Scan: 'Tražite od korisnika da skeniraju QR kod ili EAN. Rezultat se prikazuje u obrascu i sprema.',
      Input: 'Polje za unos teksta u jednom retku',
      Textarea: 'Višeredni unos teksta za duže komentare',
      Link: 'Link na vanjsku stranicu',
      Picture: 'Učitajte sliku koja će se prikazati prilikom ispunjavanja.',
      Video: 'Učitajte video koji će se prikazivati dok ispunjavate obrazac.',
      Text: 'Dodajte oblikovanje višerednim tekstovima.',
      Header: 'Veliki naslov za strukturiranje sadržaja',
      Rate: 'Ocjena 5 zvjezdica',
      RateSmiley: 'Izbor od 3 smajlića (sretan, neutralan, tužan)',
      CreateTask: 'Ulaznica se kreira za korisnika',
      FileUpload: 'Korisnik obrascu može priložiti neograničen broj datoteka.',
      Currency: 'Korisnik može odrediti iznos u valuti.'
    },
    template: {
      template: 'Predložak izvješća',
      templates: 'Predlošci izvješća',
      name: 'Prezime',
      description: 'Opis',
      customer: 'kupac',
      new: 'Novi predložak izvješća',
      edit: 'Uredi predložak izvješća',
      create: 'Stvorite predložak izvješća',
      public: 'Javnost',
      active: 'Aktivan',
      deleteConfirm: "Jeste li sigurni da želite izbrisati predložak izvješća '{{templateName}}'?",
      deleteSuccess: 'Predložak izvješća je uklonjen',
      editor: 'Dizajner predložaka',
      notForMobile: 'Dizajner predložaka nije optimiziran za mobilne uređaje. Za to koristite Blink Check u pregledniku.',
      noTemplates: 'Još nema predložaka izvješća.',
      custom: 'Individualni predložak',
      defaultWithPoints: 'Standardni predložak - s postotnim bodovima',
      default: 'Standardni predložak - bez postotnih bodova',
      createTemplateInfo: 'Predložak izvješća možete uređivati tek nakon što je izrađen.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Globalna grupa dopuštenja',
    notAllowed: 'Nemate dopuštenje za promjenu dopuštenja ovog predloška.',
    all: 'Obrazac mogu ispuniti svi korisnici',
    groups: 'Obrazac mogu ispuniti samo korisnici iz ovih autorizacijskih grupa',
    noGroupLicence: 'Licenca Blink Check za ovu grupu nije aktivirana.',
    overridingPermssion: 'Opcija "Postavke sustava" u grupi dopuštenja "Sysadmin" korisnicima automatski daje sva dopuštenja za ovaj obrazac.',
    onlyLicensedPermissionGroups: 'Prikazuju se samo autorizacijske grupe kojima je dodijeljena licenca Blink Check.',
    canUpdateChecklist: 'Uredi predložak obrasca',
    canFillChecklist: 'ispuni obrazac',
    canReadChecklistDataOwn: 'Pregledajte i procijenite obrasce koje ste sami ispunili',
    canReadChecklistDataOwnEmployees: 'Pregledajte i procijenite sve ispunjene obrasce od strane vaših zaposlenika',
    canReadChecklistDataOwnLocations: 'Pregledajte i procijenite sve ispunjene obrasce vlastitih objekata',
    canReadChecklistDataAll: 'Pregledajte i procijenite sve ispunjene obrasce'
  },
  companyConfig: {
    companyConfig: 'Konfiguracija stanara',
    logoInfo: 'Pojavljuje se u zaglavlju standardnih predložaka izvješća.',
    footer: 'podnožje',
    footerInfo: 'Pojavljuje se u podnožju standardnih predložaka izvješća.',
    offline: 'Uspostavite internetsku vezu kako biste mogli uređivati konfiguraciju.'
  },
  switchCompanyDialogHeader: 'Promijenite klijente',
  switchCompanyDialogText: 'Ako promijenite klijenta, svi obrasci u tijeku bit će poništeni. Želite li nastaviti?',
  results: {
    results: 'Evaluacija',
    details: 'pojedinosti',
    created: 'Stvoreno',
    result: 'Proizlaziti',
    noReportJsonExists: 'Izvješće još nije izrađeno. Izvješće će biti generirano s odgodom, pokušajte ponovno kasnije.',
    noResults: 'Još nema ispunjenih obrazaca.',
    noResultsForDateFilter: 'Za odabrano razdoblje nema popunjenih obrazaca.',
    periodFrom: 'Razdoblje od',
    periodTo: 'razdoblje gore',
    location: 'objekt',
    createUser: 'Stvoritelj',
    loginUser: 'Zaposlenici',
    checklist: 'Predložak obrasca',
    user: 'korisnik',
    recentResults: 'Trenutno ispunjeni obrasci',
    recent: 'Trenutno',
    pointOf: '{{ itemPoints }} od {{ maxItemPoints }} bodova',
    checklistCreatedByAt: 'Obrazac ispunio {{ firstName }} {{ lastName }} dana {{ createDate }} u {{ createTime }} popodne.',
    linkedTask: 'Povezana ulaznica: #{{ taskId }} {{ taskTitle }}',
    offline: 'Uspostavite internetsku vezu kako biste mogli ocijeniti ispunjene obrasce.',
    delete: {
      title: 'Brisanje ispunjenog obrasca',
      message: 'Želite li stvarno izbrisati ispunjeni obrazac “{{checklistName}}”?',
      success: 'Ispunjeni obrazac je uklonjen'
    },
    editReferenceTimestamp: 'Uredi referentni datum',
    editReferenceTimestampDescription: 'Referentni datum određuje vrijeme u kojem je obrazac ispunjen. To se može razlikovati od vremena u kojem je obrazac predan.'
  },
  dependencies: {
    dependency: 'Ovisnost',
    addTitle: 'Odaberite usporednu vrijednost',
    addMessage: 'Navedite vrijednost koja mora biti ispunjena da bi se stavka prikazala.',
    addTitleMulti: 'Odaberite usporedne vrijednosti',
    addMessageMulti: 'Navedite vrijednosti, od kojih jedna mora biti ispunjena da bi se stavka prikazala.',
    present: 'Dostupno',
    notPresent: 'Nedostupan',
    noResults: 'Nema prisutnih ovisnosti. Nove ovisnosti mogu se dodati putem Dodaj ovisnost.',
    noAvailableItems: 'U obrascu je premalo elemenata za stvaranje ovisnosti. Najprije dodajte još stavki.',
    noAvailableItemsInGroup: 'Na stranici ima premalo elemenata za stvaranje ovisnosti. Odaberite stranicu koja sadrži elemente.',
    dependencyAdded: 'Dodana je ovisnost.',
    dependencyRemoved: 'Ovisnost je uklonjena.',
    dependencyInfo: 'Nakon što dodate ovisnost, ovaj element ({{elementName}}) pojavit će se samo ako su ovisni elementi popunjeni.',
    true: 'Da',
    false: 'Ne',
    '#not_empty#': 'Dostupno',
    '#empty#': 'Nedostupan',
    frown: 'tužno',
    meh: 'ide ok',
    smile: 'Sretan'
  },
  noChecklists: 'Nema dostupnih obrazaca.',
  updating: 'Blink Check se ažurira.',
  installing: 'Instaliranje verzije {{version}}...',
  appName: 'Provjera treptaja',
  notAvailableInPublicChecklists: ' (Nije dostupno u predlošcima javnih obrazaca)',
  pdfAssignTemplate: 'Morate dodijeliti predložak izvješća obrascu prije nego što možete koristiti PDF preuzimanje.'
};
