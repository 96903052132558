export default {
  config: {
    checklist: {
      checklist: 'Szablon formularza',
      checklists: 'Szablony formularzy',
      none: 'Brak dostępnych formularzy.',
      new: 'Nowy szablon formularza',
      edit: 'Edytuj szablon formularza',
      saved: 'Szablon formularza został zapisany.',
      delete: 'Usuń szablon formularza',
      deleteChecklistResults: 'Usunąć także wszystkie wypełnione formularze z szablonu formularza {{name}}?',
      deleteInfo: 'Jeśli usuniesz szablon formularza, wszystkie wypełnione formularze również zostaną nieodwołalnie usunięte! <br><br>Aby zachować wypełnione formularze, dezaktywuj szablon formularza.',
      deleted: 'Szablon formularza został usunięty.',
      duplicate: 'Duplikat szablonu formularza',
      duplicateInfo: 'Funkcji duplikowania można używać do powielania szablonów formularzy',
      duplicateMessage: 'Czy chcesz zduplikować szablon formularza „{{checklistName}}” z całą zawartością?',
      duplicated: 'Szablon formularza został zduplikowany.',
      templateName: 'Nazwa formularza',
      activeInfo: 'Można wypełniać tylko aktywne szablony, nieaktywne szablony można edytować tylko w zarządzaniu szablonami.',
      locationRequired: 'Wybór obiektu jest obowiązkowy',
      locationRequiredInfo: 'Jeśli jest aktywowany, aby wypełnić formularz, należy wybrać obiekt lub zeskanować kod QR.',
      loginUserRequired: 'Wybór pracownika jest obowiązkowy',
      loginUserRequiredInfo: 'Jeśli jest aktywowany, należy wybrać pracownika, aby wypełnić formularz.',
      allLocationAllowed: 'Zezwalaj na wszystkie obiekty',
      allowOnlyCertainLocations: 'Zezwalaj tylko na określone obiekty',
      public: 'Forma publiczna',
      publicInfo: 'Utwórz publiczny link, za pomocą którego dowolny użytkownik będzie mógł wypełnić formularz. Nie jest wymagane logowanie ani aplikacja. Możesz określić datę ważności, do kiedy link powinien być ważny.',
      preview: 'Włącz podgląd',
      previewInfo: 'Jeśli jest aktywowany, podczas jego wypełniania na dole formularza będzie zawsze wyświetlany przycisk „Podgląd”. Alternatywą dla przycisku stałego podglądu jest możliwość umieszczenia elementu treści „Podgląd” w dowolnym miejscu formularza (funkcja ta nie musi być w tym celu aktywowana).',
      shortUrlExpireDate: 'Data wygaśnięcia',
      sysadminOnly: 'Tylko administratorzy systemu',
      allUsers: 'Wszyscy użytkownicy',
      usersOfPermissionGroup: 'Użytkownicy tych grup uprawnień',
      emailConfig: 'Konfiguracja poczty',
      emailConfigInfo: 'Jeśli wpiszesz tutaj adresy e-mail, wypełnione formularze zostaną automatycznie wysłane na te adresy e-mail.',
      emailSaved: 'Adres e-mail został zapisany',
      emailRemoved: 'Adres e-mail został usunięty',
      importExportInfo: 'Dzięki funkcji eksportu możesz eksportować szablony formularzy i powiązane szablony raportów, aby ponownie zaimportować je do innego klienta lub systemu Blink Check.',
      import: 'Importuj szablon formularza',
      importInfo: 'Wybierz plik .check-b64 szablonu formularza, który chcesz zaimportować.',
      imported: 'Formularz został utworzony pomyślnie.',
      templatesInAppFolder: 'Szablony w katalogu aplikacji:',
      invalidImport: 'Wybrany plik nie jest prawidłowym szablonem formularza.',
      exported: 'Szablon formularza został wyeksportowany',
      offline: 'Aby edytować szablony formularzy, nawiąż połączenie internetowe.',
      checklistContainsCreateTask: 'Szablon formularza zawiera element „Utwórz zgłoszenie” i dlatego nie można go ustawić jako publiczny. Usuń element „Utwórz bilet”.'
    },
    category: {
      category: 'Kategoria',
      categories: 'Kategorie',
      saved: 'Kategoria została zapisana.',
      editTitle: 'Edytuj kategorię',
      content: 'elementy',
      newCategory: 'Nowa kategoria',
      name: 'Nazwa Kategorii',
      edit: 'Edytuj kategorię',
      duplicated: 'Kategoria została zduplikowana.',
      noCategories: 'Nie ma jeszcze żadnych kategorii. Utwórz nowe kategorie za pomocą przycisku „Dodaj kategorię”.',
      inactive: 'nieaktywny',
      showInactive: 'Pokaż nieaktywne',
      duplicateCategoryConfirmHeader: 'Zduplikowana kategoria',
      duplicateCategoryConfirm: 'Czy chcesz zduplikować kategorię „{{categoryName}}” wraz z całą jej zawartością?',
      delete: 'Usuń kategorię',
      deleteCategoryConfirm: 'Czy na pewno chcesz usunąć kategorię „{{categoryName}}”?',
      deleteSuccess: 'Kategoria została usunięta.'
    },
    group: {
      group: 'Strona',
      groups: 'strony',
      created: 'Strona została utworzona.',
      updated: 'Zmiany na stronie zostały zapisane.',
      updateError: 'Nie udało się zapisać strony. Spróbuj ponownie później.',
      rename: 'Zmień nazwę strony',
      saved: 'Strona została zapisana.',
      toggleSort: 'Zmień sortowanie',
      editGroupName: 'Edytuj nazwę strony',
      new: 'Nowa strona',
      name: 'nazwa strony',
      duplicated: 'Strona została zduplikowana.',
      noGroups: 'Nie ma jeszcze żadnych stron. Utwórz nowe strony za pomocą przycisku „Dodaj stronę”.',
      noItems: 'Nie ma jeszcze żadnych elementów. Utwórz nowe elementy za pomocą przycisku „Dodaj element”.',
      add: 'Dodaj stronę',
      infoElement: 'Element informacyjny',
      inputElement: 'Element wejściowy',
      addInfoElement: 'Dodaj element informacyjny',
      addInputElement: 'Dodaj element wejściowy',
      delete: 'usuń stronę',
      deleteGroupConfirm: 'Czy na pewno chcesz usunąć stronę „{{groupName}}”?',
      chooseGroup: 'Na jaką stronę dodać element?',
      duplicateGroupConfirmHeader: 'Zduplikowana strona',
      duplicateGroupConfirm: 'Czy chcesz zduplikować stronę „{{groupName}}” z całą zawartością?',
      deleteSuccess: 'Strona została usunięta.'
    },
    item: {
      add: 'Dodaj element',
      created: 'Element został utworzony',
      saved: 'Element został zapisany.',
      chooseItemType: 'Wybierz przedmiot',
      values: 'Wartości',
      value: 'Wartość',
      ratingNumber: 'Ocena',
      addItemValue: 'Dodaj nową wartość',
      addItemValueSuccess: 'Dodano nową wartość',
      updateItemValueSuccess: 'Wartość została zaktualizowana',
      removeItemValueSuccess: 'Wartość została usunięta',
      removeItemValue: 'Usuń wartość',
      takePictureButtonText: 'Wybierz obraz',
      editTitle: 'Edytuj element',
      editDependenciesTitle: 'Edytuj zależności',
      required: 'Pole obowiązkowe',
      name: 'Nazwa elementu',
      description: 'opcjonalny opis elementu',
      points: 'Zwrotnica',
      duplicated: 'Element został zduplikowany.',
      duplicateItemConfirmHeader: 'Zduplikowany element',
      duplicateItemConfirm: 'Czy chcesz zduplikować element „{{itemName}}” z całą jego zawartością?',
      delete: {
        title: 'Usuń przedmiot',
        message: 'Czy na pewno chcesz usunąć element „{{itemName}}”?',
        success: 'Element został usunięty'
      },
      moveItem: 'Przesuń element',
      move: 'Przenosić',
      moveItemInfo: 'Proszę wybrać kategorię i stronę, do której ma zostać przeniesiony bieżący element.',
      itemMoved: 'Element został przeniesiony'
    },
    editItem: {
      name: 'Nazwisko',
      nameInfo: 'Tekst ten jest wyświetlany bezpośrednio przed elementem wejściowym.',
      link: 'Adres linku',
      linkInfo: 'Adres ten będzie dostępny za pomocą przycisku. Proszę podać poprawny adres URL.',
      description: 'Opis',
      descriptionInfo: 'Jeśli chcesz przekazać instrukcje dotyczące wypełniania formularza, możesz je wpisać tutaj.',
      linkDescription: 'Etykieta przycisku',
      linkDescriptionInfo: 'Wprowadź nazwę przycisku.',
      values: 'Wartości',
      valuesInfo: 'Dla każdej wartości można określić numer oceny. Oceny można przeprowadzić później, korzystając z numeru oceny. Numer oceny jest opcjonalny.',
      value: 'Wartość',
      valuePlaceholder: 'Nowa wartość',
      ratingNumber: 'Ocena',
      requiredField: 'Pole obowiązkowe',
      requiredFieldInfo: 'Jeżeli aktywowano jako pole obowiązkowe, wówczas pole to należy wypełnić, aby formularz mógł zostać wysłany.',
      externalNo: 'Numer zewnętrzny',
      externalNoInfo: 'Opcjonalne informacje dotyczące interfejsów lub ocen za pośrednictwem systemów zewnętrznych.',
      editable: 'Edytowalne',
      editableInfo: 'Aby użytkownicy mogli wprowadzać wpisy, element musi być edytowalny.',
      defaultValue: 'Określ wartość domyślną',
      defaultValueInfo: 'Można określić standardowe przypisanie tego elementu.',
      defaultValueInfoEditable: 'Wartość może zostać zmieniona przez użytkownika formularza.',
      visible: 'widoczność',
      visibleInfo: 'Widoczność określa, czy element może być widoczny dla redaktora, czy nie.',
      externalNoAleradyUsed: 'Ten numer zewnętrzny jest już zajęty.',
      taskTypeId: 'Typ biletu',
      loginUserId: 'użytkownik',
      locationId: 'Nazwa obiektu',
      videoId: 'Numer wideo',
      videoType: 'Usługa wideo',
      videoTypes_youtube: 'Youtube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Wprowadź tutaj unikalny identyfikator wideo. Można to znaleźć na przykład w adresie URL filmu. <br/>Przykład: https://www.youtube.com/watch?v=XXXXX lub na Vimeo https://vimeo.com/XXXXX. <br/>W takim przypadku w polu należy wpisać XXXXX.',
      defaultTaskTitle: 'Domyślny tytuł biletu',
      defaultTaskDescription: 'Domyślny opis biletu',
      titleEditable: 'Tytuł biletu edytowalny przez użytkownika',
      descriptionEditable: 'Opis biletu edytowalny przez użytkownika',
      dueDateEditable: 'Termin płatności biletu edytowalny przez użytkownika',
      addReportAttachment: 'Dołącz wypełniony formularz do biletu w formacie PDF',
      noTaskTypesFound: 'Nie znaleziono żadnych typów biletów. Rodzajami biletów można zarządzać za pomocą Blink Active.',
      noLoginUsersFound: 'Nie znaleziono żadnych użytkowników. Użytkownikami można zarządzać za pomocą Blink Managera.',
      noLocationsFound: 'Nie znaleziono żadnych obiektów. Obiektami można zarządzać poprzez Blink Manager.',
      createTaskItemInfo: 'Dzięki tej funkcji możesz tworzyć aktywne bilety Blink. Możesz określić tytuł i opis lub poprosić użytkownika o ich wypełnienie. Obiekt zostaje przypisany automatycznie, a odpowiedzialny menadżer obiektu zostaje przypisany jako procesor.',
      duplicatedValuesNotAllowed: 'Zduplikowane wartości są niedozwolone.',
      valueLengthExceeded: 'Wprowadzona wartość jest za długa i zostanie skrócona.'
    },
    itemTypes: {
      PreviewButton: 'Podgląd dokumentu',
      Select: 'Wybór z listy',
      Signature: 'Podpis',
      Email: 'Wprowadzanie wiadomości e-mail',
      SendEmailTo: 'Dostawa e-mailem',
      Date: 'Data',
      Time: 'czas',
      Boolean: 'Tak, nie, pytanie',
      TakePicture: 'Zrób zdjęcie',
      TakeMultiPicture: 'robić zdjęcia',
      Scan: 'Skan Qr/EAN',
      Input: 'Wprowadzanie tekstu',
      Textarea: 'Wprowadzanie tekstu wielowierszowego',
      Link: 'Łączenie',
      Picture: 'Wyświetlanie obrazu',
      Video: 'Reklama wideo',
      Text: 'Wyświetlanie tekstu',
      Header: 'nagłówek',
      Rate: 'Ocena',
      RateSmiley: 'Ocena uśmiechu',
      CreateTask: 'Utwórz bilet',
      FileUpload: 'Prześlij pliki',
      Currency: 'waluta'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Zobacz wersję poglądową formularza.',
      Select: 'Zdefiniuj dowolne elementy listy, z których można wybrać jeden.',
      Signature: 'Pole na podpis cyfrowy (można używać palcem)',
      Email: 'Użytkownik może podać prawidłowy adres e-mail.',
      SendEmailTo: 'Użytkownik może podać prawidłowy adres e-mail, na który zostanie przesłany wypełniony formularz w formacie PDF.',
      Date: 'Użytkownik może określić datę za pomocą kalendarza',
      Time: 'Użytkownik może określić godzinę',
      Boolean: 'Użytkownik wybiera tak lub nie',
      TakePicture: 'Użytkownik może zrobić zdjęcie aparatem lub przesłać obraz.',
      TakeMultiPicture: 'Użytkownik może wykonać wiele zdjęć za pomocą aparatu lub przesłać obrazy.',
      Scan: 'Wymagaj od użytkowników zeskanowania kodu QR lub EAN. Wynik jest wyświetlany w formularzu i zapisywany.',
      Input: 'Jednowierszowe pole do wprowadzania tekstu',
      Textarea: 'Wielowierszowe wprowadzanie tekstu w przypadku dłuższych komentarzy',
      Link: 'Link do strony zewnętrznej',
      Picture: 'Prześlij obraz, który będzie wyświetlany podczas wypełniania.',
      Video: 'Prześlij film, który będzie wyświetlany podczas wypełniania formularza.',
      Text: 'Dodaj formatowanie do tekstów wielowierszowych.',
      Header: 'Duży nagłówek porządkujący treść',
      Rate: 'Ocena 5 gwiazdek',
      RateSmiley: 'Do wyboru 3 buźki (szczęśliwa, neutralna, smutna)',
      CreateTask: 'Dla użytkownika tworzony jest bilet',
      FileUpload: 'Użytkownik może załączyć do formularza dowolną liczbę plików.',
      Currency: 'Użytkownik może określić kwotę w walucie.'
    },
    template: {
      template: 'Szablon raportu',
      templates: 'Szablony raportów',
      name: 'Nazwisko',
      description: 'Opis',
      customer: 'klient',
      new: 'Nowy szablon raportu',
      edit: 'Edytuj szablon raportu',
      create: 'Utwórz szablon raportu',
      public: 'Publiczny',
      active: 'Aktywny',
      deleteConfirm: 'Czy na pewno chcesz usunąć szablon raportu „{{templateName}}”?',
      deleteSuccess: 'Szablon raportu został usunięty',
      editor: 'Projektant szablonów',
      notForMobile: 'Projektant szablonów nie jest zoptymalizowany pod kątem urządzeń mobilnych. W tym celu użyj funkcji Blink Check w przeglądarce.',
      noTemplates: 'Nie ma jeszcze żadnych szablonów raportów.',
      custom: 'Indywidualny szablon',
      defaultWithPoints: 'Szablon standardowy - z punktami procentowymi',
      default: 'Szablon standardowy – bez punktów procentowych',
      createTemplateInfo: 'Szablon raportu można edytować dopiero po jego utworzeniu.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Globalna grupa uprawnień',
    notAllowed: 'Nie masz uprawnień do zmiany uprawnień tego szablonu.',
    all: 'Formularz może wypełnić każdy użytkownik',
    groups: 'Formularz mogą wypełnić wyłącznie użytkownicy z tych grup uprawnień',
    noGroupLicence: 'Miga Sprawdź, czy licencja dla tej grupy nie została aktywowana.',
    overridingPermssion: 'Opcja „Ustawienia systemu” w grupie uprawnień „Sysadmin” automatycznie przyznaje użytkownikom wszystkie uprawnienia do tego formularza.',
    onlyLicensedPermissionGroups: 'Wyświetlane są tylko grupy autoryzacji, do których przypisana jest licencja Blink Check.',
    canUpdateChecklist: 'Edytuj szablon formularza',
    canFillChecklist: 'wypełnij formularz',
    canReadChecklistDataOwn: 'Przeglądaj i oceniaj samodzielnie wypełnione formularze',
    canReadChecklistDataOwnEmployees: 'Przeglądaj i oceniaj wszystkie wypełnione formularze przez Twoich pracowników',
    canReadChecklistDataOwnLocations: 'Zobacz i oceń wszystkie ukończone formy własnych obiektów',
    canReadChecklistDataAll: 'Przeglądaj i oceniaj wszystkie wypełnione formularze'
  },
  companyConfig: {
    companyConfig: 'Konfiguracja najemcy',
    logoInfo: 'Pojawia się w nagłówku standardowych szablonów raportów.',
    footer: 'stopka',
    footerInfo: 'Pojawia się w stopce w standardowych szablonach raportów.',
    offline: 'Aby móc edytować konfigurację, należy nawiązać połączenie internetowe.'
  },
  switchCompanyDialogHeader: 'Zmień klientów',
  switchCompanyDialogText: 'Jeśli zmienisz klienta, wszystkie trwające formularze zostaną zresetowane. Czy chcesz kontynuować?',
  results: {
    results: 'Ocena',
    details: 'Detale',
    created: 'Utworzony',
    result: 'Wynik',
    noReportJsonExists: 'Raport nie został jeszcze utworzony. Raport zostanie wygenerowany z opóźnieniem. Spróbuj ponownie później.',
    noResults: 'Nie ma jeszcze wypełnionych formularzy.',
    noResultsForDateFilter: 'Brak wypełnionych formularzy dla wybranego okresu.',
    periodFrom: 'Okres',
    periodTo: 'okres w górę',
    location: 'obiekt',
    createUser: 'Twórca',
    loginUser: 'Pracownicy',
    checklist: 'Szablon formularza',
    user: 'użytkownik',
    recentResults: 'Aktualnie wypełnione formularze',
    recent: 'Aktualny',
    pointOf: '{{ itemPoints }} z {{ maxItemPoints }} punktów',
    checklistCreatedByAt: 'Formularz wypełniony do {{ firstName }} {{ lastName }} w dniu {{ createDate }} o godzinie {{ createTime }}.',
    linkedTask: 'Połączony bilet: #{{ taskId }} {{ taskTitle }}',
    offline: 'Aby móc ocenić wypełnione formularze, prosimy o nawiązanie połączenia internetowego.',
    delete: {
      title: 'Usuń wypełniony formularz',
      message: 'Czy na pewno chcesz usunąć wypełniony formularz „{{checklistName}}”?',
      success: 'Wypełniony formularz został usunięty'
    },
    editReferenceTimestamp: 'Edytuj datę referencyjną',
    editReferenceTimestampDescription: 'Data referencyjna określa godzinę, w której formularz został wypełniony. Może się ona różnić od chwili przesłania formularza.'
  },
  dependencies: {
    dependency: 'Zależność',
    addTitle: 'Proszę wybrać wartość porównawczą',
    addMessage: 'Proszę podać wartość jaka musi być spełniona aby pozycja została wyświetlona.',
    addTitleMulti: 'Proszę wybrać wartości porównawcze',
    addMessageMulti: 'Podaj wartości. Jedna z nich musi zostać spełniona, aby element został wyświetlony.',
    present: 'Dostępny',
    notPresent: 'Niedostępne',
    noResults: 'Brak zależności. Nowe zależności można dodać poprzez opcję Dodaj zależność.',
    noAvailableItems: 'W formularzu jest zbyt mało elementów, aby utworzyć zależności. Najpierw dodaj więcej elementów.',
    noAvailableItemsInGroup: 'Na stronie jest zbyt mało elementów, aby utworzyć zależności. Wybierz stronę zawierającą elementy.',
    dependencyAdded: 'Dodano zależność.',
    dependencyRemoved: 'Zależność została usunięta.',
    dependencyInfo: 'Po dodaniu zależności ten element ({{elementName}}) pojawi się tylko wtedy, gdy elementy zależne zostaną wypełnione.',
    true: 'Tak',
    false: 'NIE',
    '#not_empty#': 'Dostępny',
    '#empty#': 'Niedostępne',
    frown: 'Smutny',
    meh: 'Idzie OK',
    smile: 'Szczęśliwy'
  },
  noChecklists: 'Brak dostępnych formularzy.',
  updating: 'Trwa aktualizacja funkcji Blink Check.',
  installing: 'Instalowanie wersji {{version}}...',
  appName: 'Kontrola mrugnięcia',
  notAvailableInPublicChecklists: ' (Niedostępne w publicznych szablonach formularzy)',
  pdfAssignTemplate: 'Zanim będzie można pobrać plik PDF, należy przypisać szablon raportu do formularza.'
};
