import { Component, Input } from '@angular/core';
import { List } from "immutable";
import { MenuController, NavController, PopoverController } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: 'ui-select-company-popover',
  templateUrl: './select-company-popover.component.html',
  styleUrls: ['./select-company-popover.component.scss'],
})
export class SelectCompanyPopoverComponent {
  @Input() companies: List<any>;
  @Input() selectedCompany: any;

  constructor(public popoverController: PopoverController,
              public menuController: MenuController,
              public navController: NavController,
              public router: Router) {
  }

  selectCompany(company: any) {
    this.menuController.close('main-menu');
    this.popoverController.dismiss(company, 'companySelected');
  }

}
