export enum CheckItemType {
  Text = 'Text',
  Header = 'Header',
  Picture = 'Picture',
  Link = 'Link',
  PreviewButton = 'PreviewButton',
  Select = 'Select',
  Input = 'Input',
  Textarea = 'Textarea',
  Scan = 'Scan',
  TakePicture = 'TakePicture',
  Boolean = 'Boolean',
  Date = 'Date',
  Email = 'Email',
  SendEmailTo = 'SendEmailTo',
  Signature = 'Signature',
  Rate = 'Rate',
  RateSmiley = 'RateSmiley',
  Time = 'Time',
  TakeMultiPicture = 'TakeMultiPicture',
  CreateTask = 'CreateTask',
  Video = 'Video',
  FileUpload = 'FileUpload',
  Currency = 'Currency'
}
