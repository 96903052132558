import { Injectable } from '@angular/core';
import { TranslateGenericClass } from './translations';
import { GlobalTranslateService } from './global-translate.service';
import managerDe from './manager-translations/manager-de';

const suffix = 'blinkManager';

@Injectable({
  providedIn: 'root'
})
export class ManagerTranslateService extends TranslateGenericClass<typeof managerDe>() {
  constructor(public global: GlobalTranslateService) {
    super();

    global.setupService(this, suffix, managerDe);

    global.addTranslations('de', suffix, managerDe);
  }

}
