import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, from, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { SessionRepository } from '../session/session.repository';
import { AuthApi } from './auth.api';
import { UiDialogService } from "@blink/ui";
import { AuthService } from './auth.service';
import { isTokenExpired } from '../../shared/token-validator';
import { GlobalTranslateService } from "@blink/util";
import { HANDLE_ERROR } from '../../shared';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  errorLock = false;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenAction$ = this.refreshTokenSubject.asObservable();

  constructor(private sessionRepository: SessionRepository,
              private authApi: AuthApi,
              private authService: AuthService,
              private t: GlobalTranslateService,
              private uiDialogService: UiDialogService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.context.get(this.authApi.AUTH_INTERCEPTION)
      || request.url.startsWith('http://localhost')
      || request.url.includes('accessToken?identity=')
      || request.url.startsWith('./')
      || request.url.includes('assets.blink.online/appVersions')
      || request.url.includes("onlinestatus.blink.online/online.html")) {

      return next.handle(request);
    }

    const blinkAuthToken = this.sessionRepository.getAuthToken();
    const blinkRefreshToken = this.sessionRepository.getRefreshToken();
    if (blinkAuthToken || blinkRefreshToken) {
      if (!blinkAuthToken || isTokenExpired(blinkAuthToken)) {
        if (blinkRefreshToken) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenAction$.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(
                this.addBlinkAuthToken(request)).pipe(
              ))
            );
          } else {
            return this.handleRefreshToken(request, next);
          }
        } else {
          this.authService.logout();
          return EMPTY;
        }
      } else {
        return next.handle(this.addBlinkAuthToken(request)).pipe(
          catchError(response => this.handleError(request, response))
        );
      }
    } else {
      return next.handle(request).pipe(
        catchError(response => this.handleError(request, response))
      );
    }

  }

  handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);

    return from(this.authApi.refreshToken()).pipe(
      mergeMap(newBlinkAuthToken => {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(newBlinkAuthToken);
        return next.handle(this.addBlinkAuthToken(request)).pipe(
          catchError(response => this.handleError(request, response))
        );
      }),
      catchError(response => this.handleError(request, response, true))
    );
  }

  handleError(request: HttpRequest<any>, response, fromRefreshToken = false) {
    if (!this.errorLock && request.context.get(HANDLE_ERROR)) {
      this.errorLock = true;
      if (response.status === 403 && response.error.Message === 'InactiveUser') {
        this.showErrorAndLogout(this.t.ERROR.ERROR_403);
      } else if (response.status === 403 && fromRefreshToken) {
        this.refreshTokenAction$ = this.refreshTokenSubject.asObservable()
        this.showErrorAndLogout(this.t.ERROR.REFRESH_TOKEN_403);
      } else if ((response.status !== 403 && response.status !== 404 && response.status !== 409) ||
        (response.status === 403 && !request.url.includes('RegisterApp') && !request.url.includes('GenerateTwilioToken')) ||
        (response.status === 404 && !request.url.includes('Services.GetLocationQrCodeDetails') && !request.url.includes('ProfileThumbnail')) ||
        (response.status === 409 && !request.url.includes('Services.CreateManualTimeTracking'))) {
        this.uiDialogService.errorOccurred(response.status);
      }
      this.errorLock = false;
    }
    return throwError(response);
  }

  addBlinkAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    if (request.body instanceof FormData) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.sessionRepository.getAuthToken()}`
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json;odata.metadata=none',
          Accept: '*; charset=utf-8',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${this.sessionRepository.getAuthToken()}`
        }
      });
    }
  }

  private showErrorAndLogout(message: string) {
    this.uiDialogService.alert(message, this.t.error, () => {
      this.authService.logout();
    });
  }
}
