export default {
  barcodeScanner: {
    permissionHeader: 'oprávnění',
    permissionMessage: 'Pokud chcete udělit oprávnění k používání fotoaparátu, povolte jej v nastavení aplikace.',
    scanPrompt: 'Skenovat {{thing}}',
    enterPrompt: 'Zadejte {{thing}}',
    blinkId: 'ID mrknutí',
    isNoBlinkId: 'Naskenovaný QR kód není platným Blink ID',
    objectCode: 'Kód objektu'
  },
  camera: {
    promptLabelHeader: 'Vyberte zdroj',
    promptLabelPicture: 'vyfotit',
    promptLabelPhoto: 'Vyberte z galerie'
  },
  network: {
    changed: {
      connected: 'Navázáno připojení k internetu',
      disconnected: 'Internetové připojení bylo přerušeno'
    }
  },
  file: { downloadStarted: 'Stahování začalo.' },
  nfc: {
    wrongCode: 'Naskenovaný QR kód není objektový kód!',
    error: 'Chyba NFC'
  }
};
