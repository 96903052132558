import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Component } from '@angular/core';

interface UiInputDateProps extends FormlyFieldProps {
  presentation: 'date' | 'time';
  minDate: string;
  maxDate: string;
}

@Component({
  selector: 'ui-formly-input-date',
  template: `
    <ui-input-date
      class="blink-item-input ion-margin-bottom"
      [label]="props.label"
      [value]="model[key.toString()]"
      (valueChange)="onValueChange($event)"
      [presentation]="props.presentation"
      [minDate]="props.minDate"
      [maxDate]="props.maxDate">
    </ui-input-date>
  `,
})
export class UiInputDateFieldTypeComponent extends FieldType<FieldTypeConfig<UiInputDateProps>> {
  onValueChange($event: any) {
    this.formControl.patchValue($event);
    setTimeout(() => {
      this.formControl.markAsDirty();
    })
  }
}
