import { NgModule } from "@angular/core";
import { UiFormlyFormFieldModule } from "./wrappers/form-field/form-field.module";
import { UiFormlyInputModule } from "./templates/input/input.module";
import { TranslateService } from "@ngx-translate/core";
import { registerTranslateExtension } from "./translate.extension";
import { FORMLY_CONFIG } from "@ngx-formly/core";
import { UiFormlyToggleModule } from "./templates/toggle/toggle.module";
import { UiFormlyRadioModule } from "./templates/radio/radio.module";
import { UiFormlyRadioGroupModule } from "./templates/radio-group/radio-group.module";
import { UiFormlyDateModule } from './templates/date/date.module';
import { UiFormlyTextAreaModule } from './templates/textarea/textarea.module';
import { UiFormlyDisplayTitleModule } from './templates/display-title/ui-display-title.module';
import { UiFormlyDisplayDescriptionModule } from './templates/display-description/ui-display-description.module';

@NgModule({
  imports: [
    UiFormlyFormFieldModule,
    UiFormlyInputModule,
    UiFormlyToggleModule,
    UiFormlyRadioModule,
    UiFormlyRadioGroupModule,
    UiFormlyDateModule,
    UiFormlyTextAreaModule,
    UiFormlyDisplayTitleModule,
    UiFormlyDisplayDescriptionModule,
    // UiFormlyInputSelectModule
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [TranslateService]
    }
  ],
  exports: [UiFormlyFormFieldModule]
})
export class UiFormlyModule {
}
