import { Injectable } from '@angular/core';
import { getRegistry } from '@ngneat/elf';
import { log, MeTranslateService } from '@blink/util';
import { SessionRepository } from '../session/session.repository';
import { ChatSessionRepository } from '../chat/chat-session.repository';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PinLockService } from '../pin-lock/pin-lock.service';
import { v4 as uuidv4 } from 'uuid';
import { AuthApi } from './auth.api';
import { uiCancelButton, UiDialogService, uiPrimaryButton } from '@blink/ui';

@Injectable({providedIn: 'root'})
export class AuthService {
  logoutLock = false;
  private logoutSubject = new Subject<boolean>();
  logout$ = this.logoutSubject.asObservable();

  constructor(private sessionRepository: SessionRepository,
              private chatSessionRepository: ChatSessionRepository,
              private router: Router,
              private pinLockService: PinLockService,
              private authApi: AuthApi,
              private uiDialogService: UiDialogService,
              private t: MeTranslateService) {
  }

  logout() {
    try {
      if (this.sessionRepository.appInstanceIdentifier && (this.sessionRepository.registeredOnServer || this.chatSessionRepository.chatEnabled)) {
        this.authApi.unRegisterApp(this.sessionRepository.appInstanceIdentifier);
      }
    } finally {
      this.logoutLock = true;
      getRegistry().forEach(store => {
        log('logout store', store.name);
        store.reset()
      });
      this.chatSessionRepository.store.reset();
      this.chatSessionRepository.setChatEnabled(false);
      this.logoutSubject.next(true);
      setTimeout(() => {
        this.logoutLock = false;
        this.router.navigate(['login']);
        this.pinLockService.removePinLock();
        this.sessionRepository.updateAppInstanceIdentifier(uuidv4());
      }, 0);
      setTimeout(() => {
        window.location.reload();
      }, 1500) // very high because of debounce time in persistStateService
    }
  }

  confirmLogout() {
    this.uiDialogService.confirm({
      title: this.t.global.logout,
      text: this.chatSessionRepository.store.getValue().chatEnabled ? this.t.confirmLogoutWithChat : this.t.confirmLogout,
      buttons: [
        uiCancelButton(),
        uiPrimaryButton(this.t.global.logout, () => this.logout())
      ]
    });
  }

}
