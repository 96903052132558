import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { HttpClientService } from '../../shared/http-client.service';
import { CompanyConfiguration } from '../../../app/company-config/company-configuration';
import { checkServiceUrl } from '@blink/util';
import { ResultsExcelExportParams, SessionRepository } from '@blink/api';


@Injectable({
  providedIn: 'root'
})
export class OmCheckService {

  get apiUrl() {
    return checkServiceUrl(this.sessionRepo.getSystem());
  }

  constructor(
    private httpClientService: HttpClientService,
    private sessionRepo: SessionRepository) {

  }

  pdfDownload(dataId: number) {
    return this.httpClientService.post(`${this.apiUrl}/odata/v1/ChecklistDatas(${dataId})/Default.GetDocument`, {});
  }

  excelDownload(checklistDataIds: List<number>) {
    return this.httpClientService.post(`${this.apiUrl}/odata/v1/ChecklistDatas/Default.ExcelExport`, {
      ChecklistDataIds: checklistDataIds
    });
  }

  excelExportSearchResult(params: ResultsExcelExportParams) {
    return this.httpClientService.post(`${this.apiUrl}/odata/v1/ChecklistDatas/ExcelExportSearchResult`, params);
  }


  getCompanyConfig(companyId: number): Promise<CompanyConfiguration> {
    return this.httpClientService.get(`${this.apiUrl}/odata/v1/CompanyConfigurations?$filter=CompanyId eq ${companyId}`)
      .then(response => response[0]);
  }

  createCompanyConfig(config: CompanyConfiguration) {
    return this.httpClientService.post(`${this.apiUrl}/odata/v1/CompanyConfigurations`, config);
  }

  updateCompanyConfig(config: CompanyConfiguration) {
    return this.httpClientService.put(`${this.apiUrl}/odata/v1/CompanyConfigurations(${config.Id})`, config);
  }
}
