import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { RouterModule } from '@angular/router';
import { BlinkUiModule } from '@blink/ui';
import { UtilModule } from '@blink/util';
import {
  BlinkCheckComponentsModule,
  BlinkImageSelectComponent,
  RateDisplayComponent,
  VideoDisplayComponent
} from '@blink/check/shared/components/legacy';


const sharedComponents = [
  // CypressDirective
];

const sharedModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ScrollingModule,
  TranslateModule,

  IonicModule,

  BlinkCheckComponentsModule,
  BlinkUiModule,
  UtilModule
];

@NgModule({
  declarations: [
    ...sharedComponents,
  ],
  imports: [...sharedModules],
  exports: [
    ...sharedComponents,
    ...sharedModules,
    RateDisplayComponent,
    VideoDisplayComponent,
    BlinkImageSelectComponent
  ]
})
export class SharedModule {
}
