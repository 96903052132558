<ng-container *ngIf="checklist$ | async as checklist">
  <ion-item (click)="openChecklistData(result)" button>
    <ion-row class="checklistDataLineItem ion-align-items-center">
      <ion-col size-xs="2" size-sm="1">
        <div class="icon-container">
          <i class="fal fa-{{checklist?.Icon}} fa- lg"></i>
        </div>
      </ion-col>
      <ion-col size-xs="6" size-md="3" class="ion-no-padding">
        <ion-row>
          <b>{{ checklist?.Name }}</b>
        </ion-row>
        <ion-row>
          <small>
            <ui-icon [icon]="BlinkIcon.Clock" [fixedWidth]="true" class="mr-5"></ui-icon>
            {{ result.ReferenceTimestamp | date: 'EEEEEE' }}
            {{ result.ReferenceTimestamp | date: 'short' }}
          </small>
        </ion-row>
      </ion-col>
      <ion-col size-xs="4"
               size-sm="2"
               [pushSm]="contextActionsAvailable ? 5 : 6"
               class="ion-no-padding ion-text-end">
        <ng-container *ngIf="result.MaxItemPoints && result.MaxItemPoints !== 0">
          <ion-row class="ion-justify-content-end">
            {{ result.PercentItemPoints | number:'1.0-0' }}%
          </ion-row>
          <ion-row class="ion-justify-content-end">
            <small class="ion-text-nowrap"><i class="fa fa-chart-pie"></i> {{ result.ItemPoints }}
              / {{ result.MaxItemPoints }}</small>
          </ion-row>
        </ng-container>
      </ion-col>
      <ion-col size-xs="2" size-sm="auto" class="ion-hide-sm-up">

      </ion-col>
      <ion-col size-xs="5" size-sm="3" pull-sm="2" class="loginUser ion-no-padding">
        <ion-row>
          <small *ngIf="result.LoginUser">
            <ui-icon [icon]="BlinkIcon.User" [fixedWidth]="true" class="mr-5"></ui-icon>
            {{ result.LoginUser.LastName }}, {{ result.LoginUser.FirstName }}
          </small>

          <ng-container *ngIf="!result.LoginUser">
            &nbsp;
          </ng-container>
        </ion-row>
        <ion-row>
          <small *ngIf="result.CreateUser">
            <ui-icon [icon]="BlinkIcon.User" [fixedWidth]="true" class="mr-5"></ui-icon>
            {{ result.CreateUser.LastName }}, {{ result.CreateUser.FirstName }}
          </small>
        </ion-row>
      </ion-col>
      <ion-col size-xs="4" size-sm="2" pull-sm="2" class="location ion-no-padding">
        <ion-row>
          &nbsp;
        </ion-row>
        <ion-row>
          <small *ngIf="result.Location?.Name">
            <ui-icon [icon]="BlinkIcon.Location" [fixedWidth]="true" class="mr-5"></ui-icon>
            {{ result.Location.Name }}
          </small>
        </ion-row>
      </ion-col>

      <ion-col size-xs="1" class="ion-no-padding ion-text-end">
        <ng-container *ngIf="platform.width() > 500">
          <ion-button
            *ngIf="userCanDelete"
            color="danger"
            fill="outline"
            [uiTooltip]="t.global.delete" uiTooltipFlow="left"
            (click)="deleteChecklistData($event, result)">
            <ui-icon slot="icon-only" [icon]="BlinkIcon.Delete" [fixedWidth]="true"></ui-icon>
          </ion-button>
        </ng-container>
        <ng-container *ngIf="platform.width() < 501">
          <ion-button
            *ngIf="contextActionsAvailable"
            fill="clear"
            (click)="openDropdown($event, result)">
            <ui-icon [icon]="BlinkIcon.EllipsisVertical"></ui-icon>
          </ion-button>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-item>
</ng-container>
