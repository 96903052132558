export default {
  home: {
    pageTitle: 'Kezdőlap',
    welcome: 'Üdvözöljük a Blink me alkalmazásban. Jó csevegést,\n\nA Blink csapatod',
    startChat: 'kezdődik',
    ticket: 'jegy',
    timeLicenseMissing: 'Ön nem kapott Blink Time licencet!'
  },
  conversations: { pageTitle: 'hírek', yourChats: 'A csevegéseid', chat: 'Csevegés' },
  settings: {
    settings: 'Beállítások',
    pushEnabled: 'Push értesítések fogadása',
    system: 'rendszer',
    languageSelection: 'nyelvválasztás',
    currentSettings: 'Jelenlegi beállítások',
    user: 'felhasználó',
    appVersion: 'Verzió (alkalmazás)',
    apiVersion: 'Verzió (API)',
    deviceInfo: 'Készülék verzió',
    buildVersion: 'verzió (build)',
    helpAndDataPolicy: 'Segítség és adatvédelem',
    text1: 'Ügyfélszolgálati csapatunk szívesen segít Önnek, ha bármilyen kérdése van:',
    phone: 'telefon',
    mail: 'email',
    information: 'info',
    documentation: 'Online dokumentáció',
    documentationText: 'Online dokumentációnkban útmutatásokat és videókat talál a Blink összes fontos területéhez.',
    dataPolicy: 'adat védelem',
    support: 'Támogatás',
    moreActions: 'További intézkedések',
    protocol: 'jegyzőkönyv',
    showChatUserSecret: 'Csevegés megosztása másik eszközzel',
    scanCodeAnotherDevice: 'Olvassa be az alábbi QR-kódot új készülékén, vagy írja be személyes chat-kódját az új készüléken!',
    yourPersonalCode: 'Az Ön személyes chat kódja',
    permissions: {
      title: 'értesítés',
      subtitle: 'Szükséges a csevegőüzenetekről szóló push értesítések, a pislogási értesítések és a matricák fogadásához.',
      grantPermission: 'engedélyt adni',
      camera: 'kamera',
      cameraHint: 'Objektumkódon keresztüli időrögzítéshez szükséges.',
      nfc: 'NFC',
      nfcHint: 'Időrögzítéshez szükséges NFC címkén keresztül.',
      media: 'Kép és kép felvétele a könyvtárból',
      mediaHint: 'Szükséges az idővel és jegyekkel kapcsolatos képek és dokumentumok rögzítéséhez.',
      location: 'Elhelyezkedés',
      locationHint: 'Szükséges rögzíteni a tartózkodási helyét az időrögzítés időpontjában.',
      notifications: 'Értesítések',
      notificationsHint: 'Szükséges a csevegőüzenetekről szóló push értesítések, a pislogási értesítések és a matricák fogadásához.',
      locationDisabledOnSystem: 'A helymeghatározási szolgáltatások le vannak tiltva. Kérjük, aktiválja ezt a készülék beállításaiban.'
    }
  },
  connectionState: {
    connecting: 'Csatlakozás...',
    connected: 'Összekötve.',
    disconnecting: 'A kapcsolat megszakadt...',
    disconnected: 'a kapcsolat megszakadt',
    denied: 'A kapcsolat nem hozható létre.'
  },
  noOneToFind: 'Egyik sem található.',
  nothingToFind: 'Semmit sem találni.',
  noLocationFound: 'Nem található objektum.',
  searchPlaceholder: 'Keress...',
  employeeSearchPlaceholder: 'Név keresztnév',
  typeToSearch: 'Koppintson a kereséshez...',
  typeToSearchEmployee: 'A csevegés indításához írja be a személy nevét',
  createChatWithEmployee: 'Munkatársakat keresünk',
  createChatWithLocationTeam: 'Keresés objektum',
  createTeamleaderChat: 'Az összes alkalmazottam',
  yourLanguageSelection: 'Az Ön által választott nyelv:',
  loginInfo: 'A regisztrációhoz kérjük, olvassa be személyes QR-kódját:',
  updating: 'A Blink me frissítés alatt áll.',
  notice: 'Értesítés',
  importantNotice: 'Fontos jegyzet',
  continue: 'További',
  intro: {
    slide1: {
      header: 'Üdvözöljük',
      text: 'Jó szórakozást a Blink me alkalmazáshoz.'
    },
    slide2: {
      header: 'Csevegés',
      text: 'Csevegés közvetlenül kollégáival, vagy megtudhatja a legfrissebb híreket érdekes csoportoktól.'
    },
    slide3: {
      header: 'Jegyek',
      text: 'Itt láthatja a feladatait és létrehozhat alkalmazásokat.'
    }
  },
  pleaseWait: 'Kérlek várj...',
  skip: 'Kihagyás',
  startAgain: 'Kezdje újra',
  lock: {
    createPinHeader: 'Az Ön személyes PIN-kódja',
    createPinInfo: 'Adatai védelme érdekében itt beállíthat egy PIN-kódot, amelyet minden alkalommal meg kell adnia a Blink me alkalmazás használatakor.',
    validatePin: 'Kérjük, adja meg újra a PIN-kódot.',
    tryAgain: 'A PIN kód nem egyezik. Kérjük, próbálja meg újra.',
    triedToOften: 'Sajnos a PIN-kód nem egyezik. Szeretné újraindítani a folyamatot, vagy kihagyja és később megtenni?',
    enterPinHeader: 'Pin bejegyzés',
    enterPinInfo: 'Kérjük, adja meg PIN-kódját az alkalmazás feloldásához.',
    remainingTrys: '{{amount}} további próbálkozás. Ezután újra be kell jelentkeznie az alkalmazásba.',
    tooManyEnters: 'Sajnos a PIN-kóddal történő regisztráció már nem lehetséges. Kérjük, jelentkezzen be újra a Blink ID kártyájával.',
    reSignIn: 'Új regisztráció',
    setpin: 'PIN-kód megadása az alkalmazás használatakor',
    skip: 'Nem akar most PIN-kódot rendelni? Ezt a funkciót bármikor aktiválhatja az alkalmazás beállításaiban, később is.',
    dontUsePin: 'ne használjon PIN-kódot',
    deactivatePinHeader: 'PIN letiltása',
    deactivatePinInfo: 'Kérjük, adja meg PIN-kódját a védelem kikapcsolásához.'
  },
  active: {
    yourTickets: 'A jegyeid',
    createNewTask: 'Hozzon létre egy új jegyet',
    assignee: 'szerkesztő',
    location: 'tárgy',
    dueDate: 'Esedékes',
    createTask: 'Jegy létrehozása',
    taskCreated: 'A jegy létrejött.',
    backToTasks: 'Vissza a jegyekhez',
    createdAt: 'készült ekkor',
    attachments: 'Mellékletek',
    addFileOrImage: 'Fájl vagy fénykép hozzáadása',
    addImage: 'adj hozzá egy fényképet',
    comments: 'Hozzászólások',
    addComment: 'Megjegyzés hozzáadása',
    addCommentInfo: 'A megjegyzést bárki láthatja, aki megtekintheti ezt a jegyet.',
    commentCreated: 'A megjegyzés elmentve.',
    showClosed: 'Az elkészült jegyek megtekintése',
    resetFilter: 'állítsa vissza az összes szűrőt',
    changeTicketStatusTo: 'Jegy állapotának módosítása a következőre:',
    editTask: 'Jegy szerkesztése',
    saveTask: 'Mentse el a jegyet',
    taskSaved: 'A jegyet elmentettük.',
    deleteImage: 'Tiszta kép',
    deleteImageConfirmation: 'Valóban törölni szeretné a képet?',
    deleteAttachment: 'Melléklet törlése',
    deleteAttachmentConfirmation: 'Biztosan törli ezt a mellékletet?',
    deleteComment: 'Megjegyzés törlése',
    deleteCommentConfirmation: 'Biztosan törölni szeretnéd a megjegyzést?',
    changeAssignee: 'Szerkesztő módosítása',
    duplicateTask: 'Második jegy',
    duplicateTaskConfirmation: 'Másolni szeretné ezt a jegyet?',
    taskDuplicated: 'A jegy megkettőzött.',
    deleteTask: 'Jegy törlése',
    deleteTaskConfirmation: 'Biztosan törli ezt a jegyet?',
    taskDeleted: 'A jegyet törölték.',
    assigneeChanged: 'Processzor megváltozott.',
    statusChanged: 'A jegy állapota megváltozott.',
    startDate: 'kezdődik',
    endDate: 'Vége',
    doctorVisitDate: 'Az orvos látogatásának időpontja',
    doctorVisitDescription: 'Orvoslátogatás időpontja: {{date}}',
    chooseDate: 'Válasszon egy dátumot',
    comment: 'Megjegyzés (nem kötelező)',
    dateError: 'A befejező dátum nem lehet korábbi a kezdő dátumnál',
    employee: 'Alkalmazottak',
    pleaseConnectToLoad: 'Kérjük, hozzon létre internetkapcsolatot a jegyek betöltéséhez.'
  },
  emptyState: {
    chats: 'Hozzon létre egy csevegést!',
    chat: 'Írd meg az első üzenetet :)',
    tasks: 'Nem található jegy.',
    attachments: 'Nem érhetők el mellékletek.',
    comments: 'Nincsenek megjegyzések.',
    vacation: 'Az idei évre nincs beírva szabadság'
  },
  formError: {
    min: 'Az értéknek nagyobbnak kell lennie, mint {{value}}.',
    min2: 'Az értéknek nagyobbnak vagy egyenlőnek kell lennie, mint {{value}}.',
    max: 'Az értéknek kisebbnek vagy egyenlőnek kell lennie, mint {{value}}.',
    requiredBecause: 'Az érték szükséges, mert a {{name}} ki van töltve.',
    isRequired: '{{name}} kötelező.',
    numberInUse: 'A személyi szám már használatban van.',
    maxlength: 'A {{name}} nem tartalmazhat több mint {{value}} karaktert.',
    faultyBlinkId: 'A pislogási azonosító helytelen'
  },
  ngSelect: {
    typeToSeach: 'Kérjük, adjon meg 3 vagy több betűt.',
    loading: 'adatok betöltése...',
    notFound: 'Nem található {{title}}.',
    clearAll: 'Visszaállítás alapértelmezettre'
  },
  placeholder: {
    employee: 'Keresés vezeték- és utónév, személyi szám, pislogási azonosító alapján',
    location: 'Keresés név, szám vagy tárgykód alapján',
    date: 'Válasszon egy dátumot',
    searchTasks: 'Jegyek keresése...'
  },
  hasChat: 'Kezdje el a csevegést most',
  hasNoChatYet: 'Sajnos még nem telepítettem a Blink me alkalmazást.',
  noBlinkActiveLicence: 'Nincs engedély',
  noBlinkActiveLicenceInfo: 'Jelenleg nem rendelkezik licenccel ehhez az alkalmazáshoz. Kérjük, forduljon a feletteséhez.',
  noBlinkMeLicence: 'Nincs engedély',
  noBlinkMeLicenceInfo: 'Ez az alkalmazás nem használható az Ön rendszerével. Kérjük, használja a Blink Time alkalmazást vagy a Playstore-t.',
  noSuitableAppFound: 'Nem található alkalmazás a fájl megnyitásához.',
  myChats: 'A csevegéseid',
  hasNoChatsHome: 'Kezdj el csevegni most! Beszélhet minden kollégájával, és részt vehet tárgy- és csapatcsoportokban.',
  hasNoTicketsHome: 'Nyílt jegy nem kapható.',
  myTickets: 'A jegyeid',
  myEmployees: 'Az alkalmazottaim',
  group: 'csoport',
  confirmLogoutWithChat: 'Ha kijelentkezik, a chat üzenetei törlődnek. Szeretné folytatni?',
  messageNotDecryptable: 'Az üzenetet nem sikerült visszafejteni.',
  chat: {
    userAlreadyExists: 'Már beállította a csevegést egy másik eszközön. A csevegés használatához ezen az eszközön össze kell kapcsolnia az eszközöket. Alternatív megoldásként újraindíthatja a beállítást, de ekkor a csevegés már nem használható a másik eszközön.',
    linkDevices: 'Eszközök összekapcsolása',
    withoutChat: 'Folytatás csevegés nélkül',
    setupAgain: 'Csevegés alaphelyzetbe állítása',
    setupAgainText: 'Ha újra beállítja a csevegést, a meglévő egyéni csevegések összes üzenete törlődik, és a chat a továbbiakban nem használható a többi eszközön! Akarod folytatni?',
    LinkDevicesText: 'Az eszközök összekapcsolásához csevegéshez nyissa meg a Beállításokat másik eszközén, és válassza a „Csevegés megosztása más eszközzel” lehetőséget.',
    personalToken: 'Az Ön személyes chat kódja',
    wrongCode: 'A megadott chat kód helytelen.',
    beingPrepared: 'A chat előkészítés alatt áll.',
    twilioAccessDenied: 'Nem sikerült létrehozni a kapcsolatot a csevegőszolgáltatással. Kérjük, lépjen kapcsolatba a Blink ügyfélszolgálatával.',
    notificationsDenied: 'Az értesítések le vannak tiltva az eszközön/böngészőn. Nyissa meg a Beállításokat, és engedélyezze az értesítéseket a Blink me-hez, hogy értesítéseket kaphasson. Ezután aktiválhatja az értesítéseket a személyes beállításokban.',
    deleted: 'A csevegés törölve lett',
    unavailable: 'A chat nem érhető el'
  },
  protocol: {
    SEND_EMAIL: 'Küldés e-mailben',
    DELETE: 'Napló törlése',
    deleteMessage: 'Valóban törölni szeretné a naplót? Ez a művelet nem visszavonható.',
    Send: 'Küld',
    ConfirmMessage: 'Az alkalmazás naplója most el lesz küldve a Blink Supportnak.',
    SuccessMessage: 'A napló sikeresen elküldve a Blink Supportnak.'
  },
  timeTracking: {
    title: 'Időmérés',
    start: 'Rajt',
    startDescription: 'Kattintson a Start gombra az időkövetés elindításához',
    started: 'elindult',
    stop: 'Megállni',
    duration: 'Idő hossza',
    syncButtonLabel: 'Offline rögzített feltöltési idők',
    offlineSyncMessage: 'Az elmentett idők átviteléhez online kell lenni.',
    successfulSync: 'Az időpontok feltöltve',
    scanNowQrCode: 'QR-kód',
    scanNowQrCodeDescription: 'Olvassa be a QR-kódot az objektumban.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Tartsa okostelefonját a tárgyon lévő NFC-matricához.',
    nfcDescription: 'Érzékelés NFC-n keresztül: Tartsa mobiltelefonját az objektumkódhoz képest',
    noTimeTrackingMethodAvailableDescription: 'Ehhez a felhasználóhoz nincs beállítva időkövetési módszer. Kérjük, lépjen kapcsolatba a csoportvezetővel.',
    noLocationCode: 'Nincs elérhető kód',
    noLocationCodeDescription: 'Válassza ezt a lehetőséget, ha nincs QR-kód vagy NFC-matrica az objektumon. Hozzászólás megadása kötelező.',
    noLocationCodeConfirm: 'Biztosan objektumkód nélkül akarja elindítani? A leállításhoz megjegyzést kell írnia.',
    wrongCode: 'A beolvasott QR-kód nem tárgykód!',
    readNotice: 'Kérjük, olvassa el az utasításokat!',
    warning: 'Figyelem',
    lessThanAMinuteTitle: 'Teljes időrögzítés',
    lessThanAMinute: 'Kevesebb, mint 1 perce kezdte. Biztosan abba akarod hagyni?',
    continue: 'folytassa a rögzítést',
    timeAlert: {
      differenceInMinutesHint: 'Az okostelefonon lévő idő eltér a szerveren töltött időtől. A különbség naplózásra kerül',
      differentTimeZoneHint: 'Az okostelefon időzónája eltér a szerver időzónájától. A különbség naplózásra kerül.'
    },
    leaveComment: {
      withCode: 'Az objektumra/szolgáltatásra vonatkozó információ nem volt egyértelmű. Kérjük, mondja el, melyik ingatlanban/szolgáltatásban dolgozott.',
      withoutCode: 'Indításkor vagy leállításkor nem rögzítették az objektumkódot. Kérjük, fejtse ki, miért indult el/állt meg tárgy nélkül.'
    },
    comment: 'megjegyzés',
    enterComment: 'Megjegyzés az időkövetéshez',
    differentQrCode: {
      title: 'Objektum módosítása?',
      continueInLocation: 'Folytassa a munkát itt: {{locationName}}.',
      automaticStopInLocation: 'Az időrögzítés a(z) {{locationName}} területen automatikusan leáll.',
      stopInLocation: 'Az időrögzítés leállítása itt: {{locationName}}.',
      stop: 'Megállni',
      stopNoLocation: 'Állítsa le az időrögzítést.',
      continueNoOldLocation: 'Az előző objektum időrögzítése automatikusan leáll.',
      continueWorking: 'Dolgozz tovább',
      continueWorkingInNewLocation: 'Folytassa a munkát új ingatlanban.',
      stopWithLocationActivity: 'Időrögzítés leállítása ekkor: {{locationActivity}}.',
      continueWithNewLocationActivity: 'Folytassa a munkát a következővel: {{locationActivity}}',
      stopOldLocationActivity: 'Az időrögzítés a(z) {{locationActivity}} napon automatikusan leáll.',
      stopOldNoLocationActivity: 'Az előadás nélküli időrögzítés automatikusan leáll.',
      continueNoLocationActivity: 'Folytassa a munkát teljesítmény nélkül.',
      sameLocationTitle: 'Változtat a teljesítményen?',
      differentLocationTitle: 'Objektum módosítása?'
    },
    saveAndStop: 'Mentés és leállítás',
    noLocationScanned: '- Nincs tárgy -',
    noLocationFound: '- Az objektum nem tiszta -',
    timerDifference: '{{h}} óra {{mm}} perc',
    stopSuccess: 'Az idő sikeresen rögzítve',
    stopSuccessToast: 'Az időrögzítés sikeresen leállt.',
    stopTimeTracking: 'Megállni',
    manual: {
      time: 'Idő',
      locationSelect: 'Válassza ki az objektumot',
      loadMore: 'load Tovább',
      trackTime: 'Rekordidő',
      location: 'tárgy',
      locationActivity: 'Teljesítmény',
      date: 'Dátum',
      startTime: 'Tól től',
      endTime: 'Amíg',
      create: 'megerősít',
      createAgain: 'Rögzítsen további időt ehhez az objektumhoz',
      errorUntil: 'Az időtől kevesebbnek kell lennie, mint az időnek',
      worktimeDifference: 'Munkaórák:',
      worktimeDifferenceValue: '{{hours}} óra {{minutes}} perc',
      worktimeDifferenceValueMinutes: '{{minutes}} perc',
      success: 'Az idő sikeresen rögzítve',
      break: 'Szünet:',
      breakMinutes: '{{minutes}} perc',
      breakHoursAndMinutes: '{{hours}} óra {{minutes}} perc',
      noActivity: '- nincs teljesítmény -',
      locationPlaceHolder: 'Keresés név, szám vagy tárgykód alapján',
      conflictError: 'Már van időrögzítés a megadott időszakokban. Az átfedések nem megengedettek. Kérjük, ellenőrizze adatait.',
      currentDayDuration: 'Már rögzítették',
      expectedDuration: 'Várható munkaidő',
      expectedDurationIncludedCurrent: 'beleértve az aktuális bemenetet'
    },
    differenceInMinutesHint: 'Az okostelefonon lévő idő eltér a szerveren töltött időtől. A különbség naplózásra kerül',
    differentTimeZoneHint: 'Az okostelefon időzónája eltér a szerver időzónájától. A különbség naplózásra kerül.',
    proposed: {
      capture: 'Elfog',
      time: 'Idő',
      captureTime: 'Rekordidő',
      button: 'megerősít',
      title: 'Erősítse meg a munkaidőt',
      includingBreak: '(beleértve a szünetet is)',
      from: 'Tól től',
      to: 'Amíg',
      break: 'Szünet',
      hour: 'Óra',
      confirm: {
        description: 'Kérjük, erősítse meg munkaidejét',
        confirm: 'Igen ez így van',
        editButton: 'Nem, ez nem helyes'
      },
      edit: {
        description: 'Kérjük, adja meg az időtartamot',
        breakIncluded: 'Beleértve a {{ duration }} szünetet',
        comment: 'megjegyzés',
        yourComment: 'a te hozzászólásod',
        minutes: 'percek',
        hours: 'Órák'
      },
      reasons: {
        overtime: 'Túlóra az ügyfél miatt',
        faster: 'Gyorsabban elkészült',
        representative: 'képviselő vagyok',
        other: 'Vegyes'
      },
      description: 'A munkavégzés befejezése után azonnal erősítse meg munkaidejét.'
    },
    codeNotAssignable: 'A beolvasott QR-kód nem rendelhető hozzá!',
    running: 'Időrögzítés fut'
  },
  timeFramePicker: {
    title: 'Kérjük, válasszon időszakot',
    today: 'Ma',
    currentWeek: 'Jelenlegi hét',
    currentMonth: 'Jelenlegi hónap',
    custom: 'Egyedi',
    startDate: 'kezdődik',
    endDate: 'Vége'
  },
  timeSheet: {
    title: 'Idők és tervezés',
    noPlannings: 'Semmi tervezés',
    planned: 'Tervezett',
    workingTime: 'munkaórák',
    workloadDuration: 'Heti munkaidő',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Kellene',
    workedDuration: 'Is',
    hasWorkload: 'Dolgozz aznap',
    pleaseConnectToLoad: 'Kérjük, csatlakozzon az internethez az idők betöltéséhez.',
    footer: {
      planned: 'Tervezett',
      worklogsTotal: 'Összes óra',
      workloadTotal: 'Munkaidő szerződés szerint',
      difference: 'különbség'
    },
    homepageTitle: 'A munkanapod'
  },
  planning: {
    includingPause: 'Szünet tartalmazza:',
    planRoute: 'Tervezze meg az útvonalat',
    title: 'tervezés',
    modifyMinutes: 'Átalánydíj:'
  },
  worklog: {
    title: 'Felvett idő',
    absenceTitle: 'hiány',
    workingTime: 'munkaórák',
    breakDuration: 'Szünet',
    reduce: 'átalánydíj',
    attachments: 'Mellékletek',
    comments: 'Hozzászólások',
    comment: 'megjegyzés',
    noLocation: 'Nincs objektum megadva',
    changedBy: 'Az idő megváltozott. Régi idő: {{firstName}}, új érték: {{lastName}}.',
    confirmAttachmentDelete: 'Biztosan törli ezt a mellékletet?',
    attachmentDeleted: 'A melléklet törölve',
    confirmCommentDelete: 'Biztosan törölni akarod ezt a megjegyzést?',
    commentDeleted: 'A megjegyzés törölve lett'
  },
  tabs: {
    home: 'itthon',
    times: 'alkalommal',
    chat: 'Csevegés',
    tickets: 'Jegyek',
    more: 'több'
  },
  profile: {
    title: 'profil',
    testing: 'Tesztelés',
    testingDescription: 'Ez a terület csak akkor látható, ha tesztrendszerhez csatlakozik.',
    testingAllowQrCodeOnWeb: 'A QR-kód kézi bevitelének engedélyezése',
    pushNotifications: 'Értesítések',
    reloadUserContext: 'A UserContext újratöltése',
    pushNotificationSuccessful: 'A push értesítés sikeresen érkezett',
    editProfilePicture: 'megváltoztatni a profilkèpet',
    editProfilePictureTitle: 'Válassza ki a szakaszt',
    pushNotificationNotice: 'A teszthez a „Bérlői beállítások szerkesztése” engedély szükséges.',
    send: 'Küld',
    profilePicture: 'Profil kép'
  },
  help: { title: 'Segítség' },
  myVacations: {
    vacation: 'Vakáció',
    amountAvailableVacationDays: 'Még {{amount}} nap szabadság áll rendelkezésre',
    totalVacationDays: 'Nyaralási jogosultság {{ total }} nap',
    remainingVacationDays: '({{ total }} nap {{ sign }} {{ remaining }} nap hátralévő szabadság {{ previousYear }})',
    xDaysVacations: '{{ total }} nap szabadság',
    title: 'A szabadságod'
  },
  externalLinks: {
    title: 'Külső linkek',
    notice: 'Ezt a linket az Ön cége biztosította. Kilép a Blink rendszerből.'
  },
  stickers: {
    title: 'A matricáid',
    sticker: 'Matricák',
    sender: 'Befogadó',
    text: 'Amivel kapcsolatban',
    date: 'Dátum',
    emptyState: 'Sajnos még nincs matricája',
    senderDescription: 'Ezt a matricát {{sender}} küldte Önnek, ekkor: {{date}}',
    readed: 'olvas',
    notReaded: 'még nem olvasta el',
    newStickerToaster: 'Új pislogó matrica!',
    open: 'Nyisd ki',
    next: 'További',
    titleCases: {
      case1: 'Gratulálunk az 1. matricához!',
      case2: 'Remek! Már van <b>{{count}}</b> matricája!',
      case3: 'Gratulálunk a 10. matricádhoz!',
      case4: '30 matrica - wow!',
      case5: 'Mostanra egyre zsúfolódik itt: <b>{{count}}</b> matrica!'
    },
    confirmDelete: 'Biztosan törölni szeretné ezeket a matricákat?'
  },
  autoTranslate: {
    isTranslated: 'Automatikusan lefordítva',
    translationError: 'Az automatikus fordítás jelenleg nem lehetséges'
  },
  confirmLogout: 'Valóban ki akar jelentkezni?',
  message: 'hírek',
  migration: {
    wrongVersion: 'A Blink me jelenlegi verziójában a Blink Time alkalmazáson keresztüli bejelentkezés nem támogatott. Kérjük, frissítse a Blink me alkalmazást a legújabb verzióra'
  },
  eLearning: {
    pendingTab: 'Teendő ({{value}})',
    completedTab: 'Kész ({{value}})',
    completed: 'Befejezve',
    pending: 'Nyisd ki',
    dueFalling: 'Lejárt',
    title: 'E-learning',
    course: 'tanfolyam',
    dueDate: 'Ig érvényes',
    topics: 'tantárgyak',
    completeDate: 'Befejeződött',
    status: { Pending: 'nyisd ki', Done: 'elkészült' },
    infos: {
      pendingExists: 'Itt megtalálja az összes nyitott képzését.',
      finishOverdue: 'Kérjük, hogy a már esedékes képzést a lehető leggyorsabban végezze el.',
      allCompleted: 'Kiváló! Sikeresen elvégezte az összes képzést.',
      emptyState: 'Nincs hozzárendelt képzése.'
    }
  },
  checklists: {
    title: 'Űrlapok',
    description: 'Töltsön ki egy űrlapot egy objektumhoz',
    button: 'Válassza ki az objektumot'
  }
};
