import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-card',
  templateUrl: './ui-card.component.html',
  styleUrls: ['./ui-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiCardComponent {
  @Input() title: string;

  @Input()
  @HostBinding('class.ui-card--item')
  item = false;
}
