<ion-card [color]="typeInfo.color" class="info-box">
  <ion-card-content>
    <ion-row class="ion-align-items-center">
      <ion-col size="auto">
        <ion-text>
          <ui-icon [icon]="typeInfo.icon" size="2xl" class="mr-10"></ui-icon>
        </ion-text>
      </ion-col>
      <ion-col>
        <ion-text [innerHTML]="translate | translate | safeHtml"></ion-text>
        <ng-content></ng-content>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
