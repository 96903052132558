import { Component } from '@angular/core';
import { GlobalTranslateService } from '@blink/util';
import { BlinkIcon } from '@blink/ui';

@Component({
  selector: 'blink-login-success-page',
  templateUrl: './login-success.html',
  styleUrls: ['./login-success.scss']
})
export class LoginSuccessComponent {
  protected readonly BlinkIcon = BlinkIcon;

  constructor(public t: GlobalTranslateService) {
  }

}
