import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AuthModeEnum, EmailValidator, GlobalTranslateService, logComponent, PhoneValidator } from '@blink/util';
import { AuthApi, LoginParams } from '@blink/api';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../../../login.service';


@Component({
  selector: 'blink-select-auth-mode-page',
  templateUrl: './select-auth-mode.html',
  styleUrls: ['./select-auth-mode.scss']
})
export class SelectAuthModeComponent {
  protected readonly AuthModeEnum = AuthModeEnum;
  form: FormGroup;
  loginParams: LoginParams;

  constructor(private router: Router,
              public auth: AuthApi,
              public t: GlobalTranslateService,
              public translate: TranslateService,
              public alertCtrl: AlertController,
              private loginService: LoginService,
              public zone: NgZone,
              public fb: FormBuilder,
              public cdr: ChangeDetectorRef) {
    logComponent(this);

    this.loginParams = this.loginService.loginParams;
  }

  ionViewWillEnter() {
    this.setState();
  }

  setState() {
    this.authMode = AuthModeEnum.EMAIL;
  }

  get authMode() {
    return this.loginParams?.authMode;
  }

  set authMode(value: AuthModeEnum) {
    this.zone.run(() => {
      this.loginParams.email = '';
      this.loginParams.phoneNumber = '';

      this.loginParams.authMode = value;

      this.buildForm();

      this.cdr.markForCheck();
    });
  }

  buildForm() {
    if (this.authMode == AuthModeEnum.EMAIL) {
      this.form = this.fb.group({
        authModeValue: ['', Validators.compose([EmailValidator, Validators.required])]
      });
    } else if (this.authMode == AuthModeEnum.SMS) {
      this.form = this.fb.group({
        authModeValue: ['', Validators.compose([PhoneValidator, Validators.required])]
      });
    }
  }

  register() {
    if (this.loginParams.authMode === AuthModeEnum.SMS) {
      this.loginParams.phoneNumber = `+49${this.form.get('authModeValue').value.trim().replace(/^(\+\+?[1-9]{2}?)|([0]{2}[1-9]{2})/, '').replace(/^0/, '')}`;
    }

    if (this.loginParams.authMode === AuthModeEnum.EMAIL) {
      this.loginParams.email = this.form.get('authModeValue').value;
    }


    this.auth.registerPasswordless(this.loginParams)
      .pipe(catchError(() => of(this.error())))
      .subscribe(() => this.router.navigate(['/login', 'enter-code']));
  }

  error() {
    this.alertCtrl.create({
      header: this.translate.instant('typedGlobal.error'),
      message: this.translate.instant('typedGlobal.REGISTRATION.ERROR'),
      buttons: [
        {
          text: this.translate.instant('typedGlobal.ok')
        }
      ]
    }).then(alert => alert.present());
  }
}
