import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractInputComponent } from '../abstracts/input.component';
import { formatCurrency } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputCustomEvent } from '@ionic/angular';

@Component({
  selector: 'ui-input-currency',
  templateUrl: './ui-input-currency.component.html',
  styleUrls: ['./ui-input-currency.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputCurrencyComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiInputCurrencyComponent extends AbstractInputComponent implements OnInit {
  @Input() locale = 'de';
  @Input() currency = '€';
  internalValue = '';
  valid = true;

  override ngOnInit() {
    super.ngOnInit();
    if (this.value) {
      this.internalValue = formatCurrency(this.value, this.locale, this.currency);
    }
  }

  formatCurrency(ev: InputCustomEvent) {
    const inputValue = ev.target.value as string;
    this.formatValueToCurrency(inputValue);
  }

  private formatValueToCurrency(inputValue: string) {
    this.internalValue = inputValue;
    if (inputValue === null || inputValue === '') {
      this.value = null;
    }

    if (inputValue && inputValue !== '') {
      inputValue = inputValue.replace('€', '')
        .replace(/ /g, '')
        .replace(/[.,]/g, (match: string) => match === '.' ? ',' : '.')
        .replace(/,/g, '');

      const inputFloat = parseFloat(parseFloat(inputValue).toFixed(2));
      const formattedValue: string = formatCurrency(inputFloat, this.locale, this.currency);

      setTimeout(() => {
        if (this.validate(formattedValue)) {
          this.valid = true;
          this.internalValue = formattedValue;
          this.value = inputFloat;
        } else {
          this.valid = false;
          this.value = null;
        }
      });
    }
  }

  private validate(formattedValue: string): boolean {
    return formattedValue !== '∞ €';
  }
}
