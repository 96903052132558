import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { FormlyFieldConfig, ɵFormlyAttributes as FormlyAttributes } from '@ngx-formly/core';

@Directive({
  // eslint-disable-next-line
  selector: '[ionFormlyAttributes]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(ionFocus)': 'onFocus($event)',
    '(ionBlur)': 'onBlur($event)',
    '(ionChange)': 'onChange($event)',
  },
})
export class IonFormlyAttributesDirective extends FormlyAttributes {
  @Input('ionFormlyAttributes') override field: FormlyFieldConfig;
}
