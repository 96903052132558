import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { UiFormlyFieldRadioGroupComponent } from "./radio-group.type";
import { UiFormlyFormFieldModule } from "../../wrappers/form-field/form-field.module";

@NgModule({
  declarations: [UiFormlyFieldRadioGroupComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: "radio-group",
          component: UiFormlyFieldRadioGroupComponent
          // wrappers: ['ui-form-field']
        }
      ]
    })
  ]
})
export class UiFormlyRadioGroupModule {
}
