import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CheckTranslateService } from '@blink/util';
import { BlinkIcon } from '@blink/ui';
import { CheckChecklistData } from '@blink/api';

@Component({
  selector: 'bc-results-line-item--dropdown',
  template: `
    <ion-list lines="none">
      <ion-item button (click)="popoverController.dismiss(checklistData, 'deleteChecklistData')">
        <ui-icon slot="start" [icon]="BlinkIcon.Delete" [fixedWidth]="true"></ui-icon>
        {{ t.global.delete | translate }}
      </ion-item>
    </ion-list>
  `
})
export class ResultsLineItemDropdownComponent {
  BlinkIcon = BlinkIcon;

  @Input() checklistData: CheckChecklistData;

  constructor(public popoverController: PopoverController,
              public t: CheckTranslateService) {
  }
}
