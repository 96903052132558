import { Injectable } from '@angular/core';
import { DataStorageService } from '../shared/data-storage.service';
import { of, ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap, take, } from 'rxjs/operators';
import { Store } from '@ngneat/elf';
import { persistState } from '@ngneat/elf-persist-state';
import { Capacitor } from "@capacitor/core";

@Injectable({ providedIn: 'root' })
export class PersistStateService {
  indexedDbAvailable = true;
  initialized$ = new ReplaySubject<void>(1);

  constructor(private dataStorage: DataStorageService) {
  }

  async init() {
    await this.dataStorage.init();
    await this.testAvailability();
    await this.dataStorage.openStore();
    this.initialized$.next();
  }

  async testAvailability() {
    if (Capacitor.getPlatform() === 'web') {
      await this.dataStorage.openStore('testAvailability');
      await this.dataStorage.setItem('test', 'test')
        .catch(() => {
          console.log('no indexedDB')
          this.indexedDbAvailable = false
        });
      // await this.dataStorage.closeStore('testAvailability')
    }
  }

  persistState<S extends Store>(store: S, debounce = true) {
    return this.initialized$
      .pipe(
        take(1),
        switchMap(() => {
          if (!this.indexedDbAvailable) {
            console.log('we are on web and have no indexedDb')
            return of(null);
          }

          if (debounce) {
            return persistState(store, {
              storage: this,
              source: () => store.pipe(debounceTime(1000))
            }).initialized$;
          } else {
            return persistState(store, {
              storage: this
            }).initialized$;
          }
        })
      )
  }

  setItem(key: string, value: Record<string, any>) {
    return this.initialized$.pipe(
      switchMap(() => this.dataStorage.setItem(key, JSON.stringify(value))),
      map(() => true)
    );
  }

  getItem(key: string) {
    return this.initialized$.pipe(
      switchMap(() => this.dataStorage.getItem(key)),
      map(v => v ? JSON.parse(v) : v),
    );
  }

  removeItem(key: string) {
    return this.initialized$.pipe(
      switchMap(() => this.dataStorage.removeItem(key)),
      map(() => true)
    );
  }

}
