export default {
  config: {
    checklist: {
      checklist: 'Form Template',
      checklists: 'Form Templates',
      none: 'No forms available.',
      new: 'New Form Template',
      edit: 'Edit form template',
      saved: 'Form template has been saved.',
      delete: 'Delete form template',
      deleteChecklistResults: 'Delete all completed forms of the {{name}} form template as well?',
      deleteInfo: 'If you delete the form template, all completed forms will also be irrevocably deleted! <br><br>To keep the filled forms, deactivate the form template.',
      deleted: 'Form template has been deleted.',
      duplicate: 'Duplicate Form Template',
      duplicateInfo: 'Duplicate function allows you to duplicate form template',
      duplicateMessage: 'Do you want to duplicate the form template "{{checklistName}}" with all its contents?',
      duplicated: 'Form template was duplicated.',
      templateName: 'Form name',
      activeInfo: 'Only active templates can be filled in, inactive templates can only be edited in the template management.',
      locationRequired: 'Object selection mandatory',
      locationRequiredInfo: 'If activated, it is mandatory to select an object or scan the QR code to fill in the form.',
      loginUserRequired: 'Employee selection duty',
      loginUserRequiredInfo: 'If enabled, then it is mandatory to select an employee to fill out the form.',
      allLocationAllowed: 'Allow all objects',
      allowOnlyCertainLocations: 'Allow only certain objects',
      public: 'Public form',
      publicInfo: 'Create a public link with which the form can be filled out by any user. No login and no app is required. You can specify a validity date until when the link should be valid.',
      preview: 'Enable preview',
      previewInfo: 'If enabled, a "Preview" button will always be displayed at the bottom of the form when filling it out. As an alternative to the permanent preview button, you can also place the "Preview" content element anywhere in the form (this function does not have to be activated).',
      shortUrlExpireDate: 'Expiration date',
      sysadminOnly: 'System admins only',
      allUsers: 'All users',
      usersOfPermissionGroup: 'Users of these authorization groups',
      emailConfig: 'Email configuration',
      emailConfigInfo: 'If you enter e-mail addresses here, completed forms will automatically be sent to these e-mail addresses.',
      emailSaved: 'E-mail address was saved',
      emailRemoved: 'Email address was deleted',
      importExportInfo: 'The export function allows you to export form templates and associated report templates to re-import them into another Blink Check client or system.',
      import: 'Import Form Template',
      importInfo: 'Please select the .check-b64 file of the form template you want to import.',
      imported: 'Form was created successfully.',
      templatesInAppFolder: 'Templates in the app directory:',
      invalidImport: 'Selected file is not a valid form template.',
      exported: 'Form template was exported',
      offline: 'Please connect to the Internet to be able to edit form templates.',
      checklistContainsCreateTask: "Form template contains element 'Create ticket' and therefore cannot be set to public. Please remove the 'Create ticket' element."
    },
    category: {
      category: 'Category',
      categories: 'Categories',
      saved: 'Category was saved.',
      editTitle: 'Edit category',
      content: 'Elements',
      newCategory: 'New category',
      name: 'Category name',
      edit: 'Edit category',
      duplicated: 'Category was duplicated.',
      noCategories: 'There are no categories available yet. Create new categories using the "Add category" button.',
      inactive: 'inactive',
      showInactive: 'Show inactive',
      duplicateCategoryConfirmHeader: 'Duplicate category',
      duplicateCategoryConfirm: 'Do you want to duplicate the category "{{categoryName}}" with all its contents?',
      delete: 'Delete category',
      deleteCategoryConfirm: 'Do you really want to delete the category "{{categoryName}}"?',
      deleteSuccess: 'Category has been removed.'
    },
    group: {
      group: 'Page',
      groups: 'Pages',
      created: 'The page was created.',
      updated: 'The changes to the page have been saved.',
      updateError: 'Page could not be saved. Please try again later.',
      rename: 'Rename page',
      saved: 'Page was saved.',
      toggleSort: 'Change sort order',
      editGroupName: 'Edit page name',
      new: 'New page',
      name: 'Page name',
      duplicated: 'Page was duplicated.',
      noGroups: 'There are no pages available yet. Create new pages using the "Add page" button.',
      noItems: 'There are no elements available yet. Create new elements using the "Add element" button.',
      add: 'Add page',
      infoElement: 'Info element',
      inputElement: 'Input element',
      addInfoElement: 'Add info element',
      addInputElement: 'Add input element',
      delete: 'Delete page',
      deleteGroupConfirm: 'Do you really want to delete the page "{{groupName}}"?',
      chooseGroup: 'In which page should the element be added?',
      duplicateGroupConfirmHeader: 'Duplicate page',
      duplicateGroupConfirm: 'Do you want to duplicate the page "{{groupName}}" with all its contents?',
      deleteSuccess: 'Page was removed.'
    },
    item: {
      add: 'Add item',
      created: 'Element was created',
      saved: 'Element was saved.',
      chooseItemType: 'Select element',
      values: 'Values',
      value: 'Value',
      ratingNumber: 'Evaluation',
      addItemValue: 'Add new value',
      addItemValueSuccess: 'New value was added',
      updateItemValueSuccess: 'Value was updated',
      removeItemValueSuccess: 'Value was removed',
      removeItemValue: 'Remove value',
      takePictureButtonText: 'Select image',
      editTitle: 'Edit element',
      editDependenciesTitle: 'Edit dependencies',
      required: 'Mandatory field',
      name: 'Element name',
      description: 'optional description of the element',
      points: 'Points',
      duplicated: 'Element was duplicated.',
      duplicateItemConfirmHeader: 'Duplicate element',
      duplicateItemConfirm: 'Do you want to duplicate the element "{{itemName}}" with all its contents?',
      delete: {
        title: 'Delete element',
        message: 'Do you really want to delete the "{{itemName}}" element?',
        success: 'Element was removed'
      },
      moveItem: 'Move element',
      move: 'Move',
      moveItemInfo: 'Please select the category and the page to which the current item should be moved.',
      itemMoved: 'Element was moved'
    },
    editItem: {
      name: 'Name',
      nameInfo: 'This text is displayed directly in front of the input element.',
      link: 'Link address',
      linkInfo: 'This address will be accessible via a button. Please enter a valid URL.',
      description: 'Description',
      descriptionInfo: 'If you would like to provide instructions for filling it out, you can enter them here.',
      linkDescription: 'Button designation',
      linkDescriptionInfo: 'Enter a name for the button.',
      values: 'Values',
      valuesInfo: 'You can enter a valuation number for each value. You can perform evaluations later on the basis of the evaluation number. The evaluation number is optional.',
      value: 'Value',
      valuePlaceholder: 'New value',
      ratingNumber: 'Evaluation',
      requiredField: 'Mandatory field',
      requiredFieldInfo: 'If activated as a mandatory field, then this field must be filled so that the form can be submitted.',
      externalNo: 'External number',
      externalNoInfo: 'Optional specification for interfaces or evaluations via external systems.',
      editable: 'Editable',
      editableInfo: 'The element must be editable so that users can make entries.',
      defaultValue: 'Set default value',
      defaultValueInfo: 'You can specify a default assignment for this element.',
      defaultValueInfoEditable: 'The value can be changed by the user of the form.',
      visible: 'Visibility',
      visibleInfo: 'Visibility controls whether the element can be seen by the editor or not.',
      externalNoAleradyUsed: 'This External Number is already in use.',
      taskTypeId: 'Ticket type',
      loginUserId: 'User',
      locationId: 'Object name',
      videoId: 'Video number',
      videoType: 'Video Service',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Enter the unique video ID here. You will find this, for example, in the URL of the video. <br/>Example: https://www.youtube.com/watch?v=XXXXX or at Vimeo https://vimeo.com/XXXXX. <br/>In this case, the XXXXX would have to be entered in the field.',
      defaultTaskTitle: 'Predefined title of the ticket',
      defaultTaskDescription: 'Given description of the ticket',
      titleEditable: 'Title of the ticket editable by the user',
      descriptionEditable: 'Description of the ticket editable by the user',
      dueDateEditable: 'Due date of the ticket editable by the user',
      addReportAttachment: 'Attach completed form as PDF to ticket',
      noTaskTypesFound: 'No ticket types could be found. The ticket types can be managed via Blink Active.',
      noLoginUsersFound: 'No users could be found. The users can be managed via the Blink Manager.',
      noLocationsFound: 'No objects could be found. The objects can be managed via the Blink Manager.',
      createTaskItemInfo: 'With this function you can create Blink Active tickets. You can specify a title and description or have the user fill them in. The object is assigned automatically, and the responsible object manager is assigned as the agent.',
      duplicatedValuesNotAllowed: 'Duplicate values are not allowed.',
      valueLengthExceeded: 'The entered value is too long and will be shortened.'
    },
    itemTypes: {
      PreviewButton: 'Document preview',
      Select: 'Selection from list',
      Signature: 'Signature',
      Email: 'Email input',
      SendEmailTo: 'Email dispatch',
      Date: 'Date',
      Time: 'Time',
      Boolean: 'Yes / No - question',
      TakePicture: 'Take picture',
      TakeMultiPicture: 'Take pictures',
      Scan: 'Qr/ EAN scan',
      Input: 'Text input',
      Textarea: 'Multiline text input',
      Link: 'Link',
      Picture: 'Image display',
      Video: 'Video display',
      Text: 'Text Display',
      Header: 'Headline',
      Rate: 'Evaluation',
      RateSmiley: 'Smiley rating',
      CreateTask: 'Create ticket',
      FileUpload: 'Upload files',
      Currency: 'Currency'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Show them a preview version of the form.',
      Select: 'Define any list items from which one can be selected.',
      Signature: 'Field for digital signature (usable with finger)',
      Email: 'User can specify a valid e-mail address.',
      SendEmailTo: 'User can specify a valid e-mail address to which the completed form will be sent as a PDF.',
      Date: 'User can specify a date via a calendar',
      Time: 'User can specify a time',
      Boolean: 'User selects Yes or No',
      TakePicture: 'The user can take a photo with the camera or upload a picture.',
      TakeMultiPicture: 'The user can take multiple photos with the camera or upload images.',
      Scan: 'Ask users to scan a QR code or EAN. The result is displayed and saved in the form.',
      Input: 'Single line text input field',
      Textarea: 'Multiline text input for longer comments',
      Link: 'Link to external page',
      Picture: 'Upload an image that will be displayed when you fill in the form.',
      Video: 'Upload a video that will be displayed when you fill in the form.',
      Text: 'Deposit multi-line texts with formatting.',
      Header: 'Large headline to structure the content',
      Rate: '5 star rating',
      RateSmiley: 'Choice of 3 smiley faces (happy, neutral, sad)',
      CreateTask: 'A ticket is created for a user',
      FileUpload: 'The user can attach several arbitrary files to the form.',
      Currency: 'The user can specify an amount in a currency.'
    },
    template: {
      template: 'Report template',
      templates: 'Report templates',
      name: 'Name',
      description: 'Description',
      customer: 'Customer',
      new: 'New report template',
      edit: 'Edit report template',
      create: 'Create report template',
      public: 'Public',
      active: 'Active',
      deleteConfirm: "Do you really want to delete the '{{templateName}}' report template?",
      deleteSuccess: 'Report template removed',
      editor: 'Template Designer',
      notForMobile: 'The template designer is not optimized for mobile devices. Please use Blink Check in the browser for this.',
      noTemplates: 'There are no report templates available yet.',
      custom: 'Custom template',
      defaultWithPoints: 'Standard template - with percentage points',
      default: 'Standard template - without percentage points',
      createTemplateInfo: 'You can edit the report template only after it is created.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Global authorization group',
    notAllowed: 'You do not have permission to change the permissions of this template.',
    all: 'The form can be filled by all users',
    groups: 'The form can only be filled out by users of these authorization groups',
    noGroupLicence: 'Blink Check license for this group is not activated.',
    overridingPermssion: 'The "System setting" option in the "Sysadmin" permission group automatically gives users all permissions on this form.',
    onlyLicensedPermissionGroups: 'Only permission groups to which a Blink Check license is assigned are displayed.',
    canUpdateChecklist: 'Edit form template',
    canFillChecklist: 'Fill out form',
    canReadChecklistDataOwn: 'Display and evaluate self-completed forms',
    canReadChecklistDataOwnEmployees: 'Display and evaluate all completed forms of own employees',
    canReadChecklistDataOwnLocations: 'Display and evaluate all completed forms of own objects',
    canReadChecklistDataAll: 'Display and evaluate all completed forms'
  },
  companyConfig: {
    companyConfig: 'Client configuration',
    logoInfo: 'Displayed in the header in the standard report templates.',
    footer: 'Footer',
    footerInfo: 'Displayed in the footer in the standard report templates.',
    offline: 'Please connect to the Internet to be able to edit the configuration.'
  },
  switchCompanyDialogHeader: 'Change client',
  switchCompanyDialogText: 'When you change the client, all forms in process are reset. Do you want to continue?',
  results: {
    results: 'Evaluation',
    details: 'Details',
    created: 'Created',
    result: 'Result',
    noReportJsonExists: 'The report has not been created yet. The report is created with a time delay, please try again later.',
    noResults: 'There are no completed forms yet.',
    noResultsForDateFilter: 'There are no completed forms for the selected period.',
    periodFrom: 'Period from',
    periodTo: 'Period until',
    location: 'Object',
    createUser: 'Creator',
    loginUser: 'Employees',
    checklist: 'Form Template',
    user: 'User',
    recentResults: 'Currently completed forms',
    recent: 'Current',
    pointOf: '{{ itemPoints }} from {{ maxItemPoints }} Points',
    checklistCreatedByAt: 'Form filled in by {{ firstName }} {{ lastName }} on {{ createDate }} at {{ createTime }} {{ createTime }}.',
    linkedTask: 'Linked ticket: #{ taskId }} {{ taskTitle }}',
    offline: 'Please connect to the Internet to evaluate completed forms.',
    delete: {
      title: 'Delete filled form',
      message: 'Do you really want to delete the completed form "{{checklistName}}"?',
      success: 'Completed form was removed'
    },
    editReferenceTimestamp: 'Edit reference date',
    editReferenceTimestampDescription: 'The reference date determines the time when the form was filled in. This may be different from the time when the form was submitted.'
  },
  dependencies: {
    dependency: 'Dependency',
    addTitle: 'Please select comparison value',
    addMessage: 'Please specify the value that must be met for the element to be displayed.',
    addTitleMulti: 'Please select comparison values',
    addMessageMulti: 'Please specify the values, one of which must be met for the element to be displayed.',
    present: 'Available',
    notPresent: 'Not available',
    noResults: 'No dependencies available. New dependencies can be added via "Add dependency".',
    noAvailableItems: 'There are too few elements in the form to create dependencies. Please add more elements first.',
    noAvailableItemsInGroup: 'There are too few elements in the page to create dependencies. Please select a page that contains elements.',
    dependencyAdded: 'Dependency was added.',
    dependencyRemoved: 'Dependency has been removed.',
    dependencyInfo: 'Once you add a dependency this element ({{elementName}}) will only appear if the dependent element(s) have been populated.',
    true: 'Yes',
    false: 'No',
    '#not_empty#': 'Available',
    '#empty#': 'Not available',
    frown: 'Sad',
    meh: 'Goes like this',
    smile: 'Happy'
  },
  noChecklists: 'No forms available.',
  updating: 'Blink Check is being updated.',
  installing: 'Installing version {{version}}...',
  appName: 'Blink check',
  notAvailableInPublicChecklists: ' (Not available in public form templates)',
  pdfAssignTemplate: 'You must assign a report template to the form before you can use the PDF download.'
};
