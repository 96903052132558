import { List } from 'immutable';

type ItemWithId = { Id?: number | string }

export const push = (array, item) => {
  return [...array, item];
};

export const replace = <T extends ItemWithId>(array: T[], item: T): T[] => {
  const list = List(array);
  const index = list.findIndex(i => i.Id === item.Id)
  return list.set(index, item).toArray();
}

export const remove = <T extends ItemWithId>(array: T[], id: number | string): T[] => {
  return List(array).filter(i => i.Id !== id).toArray();
}

export function toArray(iterable, start?, end?) {
  if (!iterable || !iterable.length) {
    return [];
  }
  if (typeof iterable === "string") {
    iterable = iterable.split("");
  }
  if (Array.prototype.slice) {
    return Array.prototype.slice.call(iterable, start || 0, end || iterable.length);
  }

  const array = [];
  let i;
  start = start || 0;
  end = end ? ((end < 0) ? iterable.length + end : end) : iterable.length;

  for (i = start; i < end; i++) {
    array.push(iterable[i]);
  }
  return array;
}
