import { NgModule } from '@angular/core';
import { EmployeeSearchComponent } from './employee-search.component';
import { IonicModule } from '@ionic/angular';
import { BlinkUiModule } from '@blink/ui';
import { TranslateModule } from '@ngx-translate/core';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UtilModule } from '@blink/util';

@NgModule({
  declarations: [EmployeeSearchComponent],
  exports: [EmployeeSearchComponent],
  imports: [
    IonicModule,
    BlinkUiModule,
    TranslateModule,
    AsyncPipe,
    ScrollingModule,
    NgIf,
    UtilModule,
    NgForOf
  ]
})
export class EmployeeSearchModule {
}
