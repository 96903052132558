export default {
  barcodeScanner: {
    permissionHeader: 'Permissão',
    permissionMessage: 'Se você deseja dar permissão para usar sua câmera, habilite-a nas configurações do aplicativo.',
    scanPrompt: 'Escanear {{thing}}',
    enterPrompt: 'Digite {{thing}}',
    blinkId: 'ID do pisca',
    isNoBlinkId: 'O código QR digitalizado não é um Blink ID válido',
    objectCode: 'Código do objeto'
  },
  camera: {
    promptLabelHeader: 'Selecionar fonte',
    promptLabelPicture: 'Tirar uma fotografia',
    promptLabelPhoto: 'Selecionar da galeria'
  },
  network: {
    changed: {
      connected: 'Ligação à Internet estabelecida',
      disconnected: 'Ligação à Internet interrompida'
    }
  },
  file: { downloadStarted: 'Descarga iniciada.' },
  nfc: {
    wrongCode: 'O código QR digitalizado não é um código de objeto!',
    error: 'Erro NFC'
  }
};
