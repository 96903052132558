export default {
  barcodeScanner: {
    permissionHeader: 'Άδεια',
    permissionMessage: 'Εάν θέλετε να δώσετε άδεια χρήσης της κάμεράς σας, ενεργοποιήστε την στις ρυθμίσεις της εφαρμογής.',
    scanPrompt: 'Σάρωση {{thing}}',
    enterPrompt: 'Εισαγάγετε {{thing}}',
    blinkId: 'Αναγνωριστικό αναλαμπής',
    isNoBlinkId: 'Ο σαρωμένος κωδικός QR δεν είναι έγκυρο αναγνωριστικό αναλαμπής',
    objectCode: 'Κωδικός αντικειμένου'
  },
  camera: {
    promptLabelHeader: 'Επιλέξτε πηγή',
    promptLabelPicture: 'Βγάλτε μια φωτογραφία',
    promptLabelPhoto: 'Επιλέξτε από τη γκαλερί'
  },
  network: {
    changed: {
      connected: 'Δημιουργία σύνδεσης στο Internet',
      disconnected: 'Διακοπή σύνδεσης στο Internet'
    }
  },
  file: { downloadStarted: 'Η λήψη ξεκίνησε.' },
  nfc: {
    wrongCode: 'Ο σαρωμένος κωδικός QR δεν είναι κωδικός αντικειμένου!',
    error: 'Σφάλμα NFC'
  }
};
