import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckItemDependency } from './item-dependency';
import { CheckItemDependencyRepository } from './item-dependency.repository';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckItemDependencyApi {
  private api = this.apiManager
    .createApiForEndpoint<CheckItemDependency>(
      'odata/v1',
      'ItemDependencies',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private itemDependencyRepo: CheckItemDependencyRepository) {
  }

  create(itemDependency: Partial<CheckItemDependency>) {
    return this.api.post({ body: itemDependency }).pipe(tap(
      d => this.itemDependencyRepo.sync([d], false)
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.itemDependencyRepo.delete(id)
    ));
  }
}
