import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared/api-manager';
import { BlinkMeUserContext, TimeTrackingContextRepository } from '../../time-tracking';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import { SessionRepository } from './session.repository';
import { LogRepository } from '../../log/log.repository';
import { CompanyRepository } from '../company/company.repository';

@Injectable({ providedIn: 'root' })
export class SessionApi {

  constructor(private apiManager: ApiManager,
              private timeTrackingContextRepository: TimeTrackingContextRepository,
              private sessionRepository: SessionRepository,
              private logRepository: LogRepository,
              private companyRepository: CompanyRepository) {
  }

  fetchUserContext(includeTimeTrackingContext = true) {
    const start = dayjs();
    const options = {
      key: 'single',
      service: BlinkService.Core
    };
    return this.apiManager.get('api/v2', `UserContext?includeTimeTrackingContext=${includeTimeTrackingContext}`, options)
      .pipe(
        map((userContext: BlinkMeUserContext) => {
          this.logRepository.addWithExecuteTime('UserContext - fetch', start, dayjs());
          this.timeTrackingContextRepository.update(userContext);
          this.sessionRepository.setUserContext(userContext);
          if (userContext.Company?.Id) {
            this.companyRepository.setActiveCompany(userContext.Company.Id);
          }
          return userContext;
        })
      );
  }
}
