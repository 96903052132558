import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UiFormDialogOptions } from '../dialog.service';
import { UiButton, uiCancelButton } from '../../shared/button';
import { ApplicationInsightsService } from '@blink/util';

@Component({
  selector: 'ui-dialog-form',
  templateUrl: './ui-dialog-form.component.html',
  styleUrls: ['./ui-dialog-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiDialogFormComponent implements OnInit {
  form = new FormGroup({});

  options: UiFormDialogOptions;

  cancelButton = uiCancelButton();

  constructor(private modalController: ModalController,
              private appInsights: ApplicationInsightsService) {
    window['formModal'] = this;
  }

  ngOnInit() {
    this.appInsights.logPageView(this.options.title, 'UiDialogFormComponent')
  }

  // dirty means it has been changed.
  get formDirtyAndValid() {
    return this.form.dirty && this.form.valid;
  }

  @HostListener('window:keydown.escape', ['$event'])
  handleEscape() {
    this.handle(null);
  }

  @HostListener('window:keydown.enter')
  handleEnter() {
    if (this.formDirtyAndValid) {
      this.handle(this.options.action);
    } else {
      if (this.form.invalid) {
        this.form.markAsDirty();
      }
    }
  }

  handle(button: UiButton) {
    if (button?.handler) {
      this.appInsights.logEvent('[UiDialogFormComponent] execute handler', { action: button.label });
      button.handler({
        Id: this.options.model.Id,
        ...this.form.value
      });
    } else {
      this.appInsights.logEvent('[UiDialogFormComponent] dismiss');
    }
    this.modalController.dismiss();
  }
}
