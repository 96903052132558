<ui-page [modalDismiss]="true"
         [title]="t.searchLoginUser">
  <div class="ion-padding" fixed style="background-color: white; color: black;">
    <ion-row #searchRow class="ion-align-items-center">
      <ion-col size="12">
        <ion-item class="blink-item-input" color="input">
          <ion-input (ionInput)="search($event)"
                     placeholder="{{ t.searchLoginUserPlaceholder | translate}}"
                     type="text"
                     label="{{ t.searchTerm | translate }}"
                     labelPlacement="stacked">
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="auto">
        <ion-toggle (ionChange)="toggleShowInactive($event)">
          {{ t.showInactive | translate }}
        </ion-toggle>
      </ion-col>
    </ion-row>
  </div>

  <ng-container *ngIf="loginUsers$ | async as loginUsers; else spinner">
    <ion-item *ngFor="let loginUser of loginUsers"
              (click)="onLoginUserSelected(loginUser)"
              [cyId]="loginUser.Id"
              button
              cy="login-user" style="--min-height: 60px">
      <ion-label>
        <h3>
          {{ loginUser.displayName }}
          <span *ngIf="!loginUser.Active"
                class="text-italic">
            ({{ t.inactive | translate }})
          </span>
        </h3>
      </ion-label>
      <ion-button fill="outline" slot="end">
        {{ t.select | translate }}
      </ion-button>
    </ion-item>
  </ng-container>

  <ng-template #spinner>
    <div class="spinner-container">
      <ion-spinner color="primary"></ion-spinner>
    </div>
  </ng-template>

</ui-page>
