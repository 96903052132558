<div class="mobile-tabs">
  <ion-row>
    <ion-col *ngFor="let tab of tabs"
             class="ion-no-padding">
      <ion-button [routerLink]="tab.routerLink"
                  (click)="closeMenu()"
                  fill="clear" class="ion-no-padding"
                  routerLinkActive="active">
        <div>
          <div>
            <i class="fal {{tab.icon}} fa-fw fa-xl"></i>
          </div>
          <div class="mt-5">{{tab.label | translate}}</div>
        </div>
      </ion-button>
    </ion-col>
  </ion-row>
</div>
