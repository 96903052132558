import { Environment } from './environment-model';

export const environment: Environment = {
  production: true,

  variables: {
    system: null,
    login: null,
    password: null,
    instrumentationKey: 'a292b36f-75ae-4b94-970d-17e4e2d69550'
  }
};
