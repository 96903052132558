import { NgModule } from '@angular/core';
import { ChecklistLineItemComponent } from './checklist-lineitem/checklist-lineitem.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { BlinkUiModule } from '@blink/ui';
import { ChecklistDropdownComponent } from './checklist-dropdown-popover/checklist-dropdown.component';
import { SelectChecklistComponent } from './select-checklist/select-checklist.component';
import { AsyncPipe, CommonModule, SlicePipe } from '@angular/common';
import { UtilModule } from '@blink/util';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RateDisplayComponent } from './check-displays/rate-display/rate-display.component';
import { VideoDisplayComponent } from './check-displays/video-display/video-display.component';
import { BlinkImageSelectComponent } from './blink-image-select/blink-image-select.component';
import { CostCentreSearchComponent } from './cost-centre-search/cost-centre-search.component';
import { LoginUserSearchComponent } from './loginuser-search/loginuser-search.component';
import { LocationChecklistSelectComponent } from './location-checklist-select/location-checklist-select.component';
import { EmployeeSearchModule } from '@blink/check/shared/components/employee-search';
import { LocationSelectModalComponent } from './location-select-modal/location-select.component';
import { MeLocationChecklistSelectComponent } from './me-location-checklist-select/me-location-checklist-select.component';

const exportedDeclarations = [
  ChecklistLineItemComponent,
  ChecklistDropdownComponent,
  SelectChecklistComponent,
  RateDisplayComponent,
  VideoDisplayComponent,
  BlinkImageSelectComponent,
  LoginUserSearchComponent,
  CostCentreSearchComponent,
  LocationSelectModalComponent,
];

@NgModule({
  imports: [
    TranslateModule,
    IonicModule,
    BlinkUiModule,
    CommonModule,
    SlicePipe,
    AsyncPipe,
    UtilModule,
    ScrollingModule,
    EmployeeSearchModule,
  ],
  declarations: [
    ...exportedDeclarations,
    LocationChecklistSelectComponent,
    MeLocationChecklistSelectComponent,
  ],
  exports: [...exportedDeclarations],
})
export class BlinkCheckComponentsModule {}
