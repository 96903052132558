import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UiFormlyFormFieldModule } from '../../wrappers/form-field/form-field.module';
import { UiInputDateFieldTypeComponent } from './date.type';
import { UiInputsModule } from '../../../inputs/ui-inputs.module';

@NgModule({
  declarations: [UiInputDateFieldTypeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'date',
          component: UiInputDateFieldTypeComponent
          // wrappers: ['ui-form-field']
        },
        {
          name: 'ui-input-date', // legacy
          component: UiInputDateFieldTypeComponent
          // wrappers: ['ui-form-field']
        }
      ]
    }),
    UiInputsModule
  ]
})
export class UiFormlyDateModule {
}
