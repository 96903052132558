import { createStore } from '@ngneat/elf';
import {
  deleteAllEntities,
  selectAllEntities,
  selectEntityByPredicate,
  setEntities,
  withEntities
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';

import { BlinkEmployee } from './employee';
import { displayName } from '../display-name';
import { PersistStateService } from '../../shared';

const store = createStore(
  { name: 'core_employees' },
  withEntities<BlinkEmployee, 'Id'>({ idKey: 'Id' })
);

@Injectable({ providedIn: 'root' })
export class BlinkEmployeeRepository {
  employees$ = store.pipe(selectAllEntities());

  constructor(private persistStateService: PersistStateService) {
    persistStateService.persistState(store).subscribe();
  }

  setEmployees(employees: BlinkEmployee[]) {
    employees = employees.map(employee => ({
      ...employee,
      displayName: displayName(employee.FirstName, employee.LastName)
    }));
    store.update(setEntities(employees));
  }

  getEmployeeByLoginUserId(loginUserId: number) {
    return store.pipe(selectEntityByPredicate(employee =>
      employee.LoginUserId === loginUserId
    ));
  }

  reset() {
    store.update(deleteAllEntities());
  }
}
