import { Injectable } from '@angular/core';
import { AuthApi, AuthResponse, LoginParams, SessionRepository } from '@blink/api';
import { AuthModeEnum, GlobalTranslateService, logService } from '@blink/util';
import { uiCancelButton, UiDialogService, uiPrimaryButton } from '@blink/ui';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';


@Injectable()
export class LoginService {
  constructor(private t: GlobalTranslateService,
              private router: Router,
              private uiDialogService: UiDialogService,
              private sessionRepository: SessionRepository,
              private authApi: AuthApi) {
    logService(this);
  }

  private _loginParams: LoginParams;

  get loginParams() {
    return this._loginParams;
  }

  startBlinkIdLoginFlow() {
    void this.router.navigate(['/login', 'blink-id']);
  }

  startCredentialsLoginFlow() {
    void this.router.navigate(['/login', 'credentials']);
  }

  showForgotPassword(system: string) {
    this.uiDialogService.form({
      title: this.t.FORGOT_PASSWORD.FORGOT_PASSWORD,
      small: true,
      fields: [
        {
          key: 'info',
          type: 'ui-info',
          props: {
            type: 'Info'
          }
        },
        {
          key: 'system',
          type: 'input',
          props: {
            label: this.t.REGISTER_MANUALLY_PAGE.company,
            required: true,
          }
        },
        {
          key: "email",
          type: "input",
          props: {
            label: this.t.FORGOT_PASSWORD.FORGOT_PASSWORD_PLACEHOLDER,
            required: true,
            autofocus: true
          }
        }
      ],
      model: {
        info: this.t.FORGOT_PASSWORD.FORGOT_PASSWORD_TEXT,
        system: system
      },
      action: uiPrimaryButton(this.t.send, (result) => {
        this.authApi.forgotPassword(result.email, result.system)
          .subscribe({
            next: () => void this.uiDialogService.toast(this.t.FORGOT_PASSWORD.emailSent),
            error: () => {
              this.uiDialogService.errorOccurred()
            }
          })
      })
    });
  }

  showGotNoCode() {
    this.uiDialogService.confirm({
      title: this.t.NO_CODE_PAGE.PAGE_TITLE,
      text: this.t.NO_CODE_PAGE.TEXT2,
      buttons: [
        uiCancelButton(),
        uiPrimaryButton(this.t.NO_CODE_PAGE.RESEND_CODE, (result) => {
          this.login({
            ...this.loginParams,
            authMode: AuthModeEnum.SIMPLE
          });
          void this.uiDialogService.toast(this.t.ENTER_CODE_PAGE.CODE_SENT);
        })
      ],
    });
  }

  async login(loginParams: LoginParams) {
    console.log('loginparams', loginParams);
    this._loginParams = loginParams;
    if (!(loginParams.system.length > 0 && loginParams.password.length > 0)) {
      this.uiDialogService.alert(this.t.REGISTER_MANUALLY_PAGE.WRONG_CREDENTIALS);
      return;
    }

    return await firstValueFrom(this.authApi.login(loginParams))
      .then((auth: AuthResponse) => {
        this.handleAuthResponse(auth, loginParams.blinkId);
      });
  }

  private handleAuthResponse(auth: AuthResponse, blinkId: string = null) {
    if (auth.id_token) {
      this.sessionRepository.setTokens(auth.id_token, auth.refresh_token, blinkId);
      void this.router.navigate(['/login', 'success']);
    } else {
      switch (auth.token_type) {
        case 'error':
        case 'locked':
        case 'new':
          this.uiDialogService.alert(this.t.REGISTRATION.INVALID_CARD);
          break;
        case 'assigned':
          void this.router.navigate(['/login', 'select-auth-mode']);
          break;
        case 'no match':
          this.uiDialogService.alert(this.t.REGISTER_MANUALLY_PAGE.WRONG_CREDENTIALS);
          break;
        case 'noBlinkCheckLicence':
          console.log('No Blink Check Licence!');
          break;
        default:
          try {
            const tokenTypeJson = JSON.parse(auth.token_type);
            this.loginParams.authMode = tokenTypeJson.type;
            if (tokenTypeJson.value) {
              if (this.loginParams.authMode === AuthModeEnum.EMAIL) {
                this.loginParams.email = tokenTypeJson.value;
              } else {
                this.loginParams.phoneNumber = tokenTypeJson.value;
              }
            }
          } catch (err) {
            this.uiDialogService.alert(this.t.REGISTER_MANUALLY_PAGE.WRONG_CREDENTIALS);
          }
          void this.router.navigate(['/login', 'enter-code']);
          break;
      }
    }
  }
}
