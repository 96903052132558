import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckTemplate } from './template';

@Injectable({
  providedIn: 'root'
})
export class CheckTemplateApiService {
  private api = this.apiManager
    .createApiForEndpoint<CheckTemplate>(
      'odata/v1',
      'Templates',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager) {
  }

  create(name: string, companyId: number) {
    const options = {
      body: {
        Name: name,
        CompanyId: companyId
      }
    };
    return this.api.post(options);
  }

  editorUrl(templateId: number, checklistId: number): Observable<string> {
    const options = {
      key: templateId,
      action: 'Default.Editor',
      body: { ChecklistId: checklistId }
    };
    return this.api.post(options)
      .pipe(map((res) => {
        return JSON.parse((res as any).value);
      }));
  }

}
