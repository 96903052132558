<ui-page [title]="t.FORGOT_PASSWORD.reset.resetPassword">
  <ion-content>
    <ui-card class="login-card-margin register-scan-location ion-text-center">
      <div class="ion-padding" *ngIf="form && !error">
        <p class="ion-margin-start">
          {{ t.FORGOT_PASSWORD.reset.passwordValidation | translate }}
        </p>
        <form [formGroup]="form" (ngSubmit)="doReset()">
          <ion-item class="blink-item-input ion-margin-bottom" color="input">
            <ion-input type="password"
                       formControlName="Password"
                       label="{{ t.FORGOT_PASSWORD.reset.password1 | translate }}"
                       labelPlacement="floating">
            </ion-input>
          </ion-item>
          <p *ngIf="passwordValidationError">{{ t.FORGOT_PASSWORD.reset.validationError | translate }}</p>
          <ion-item class="blink-item-input ion-margin-bottom" color="input">
            <ion-input type="password"
                       formControlName="PasswordRepeat"
                       label="{{ t.FORGOT_PASSWORD.reset.password2 | translate }}"
                       labelPlacement="floating">
            </ion-input>
          </ion-item>
          <p *ngIf="passwordRepeatValidationError">{{ t.FORGOT_PASSWORD.reset.validationError | translate }}</p>
          <p *ngIf="passwordsEqualError">{{ t.FORGOT_PASSWORD.reset.passwordsNotEqual | translate }}</p>
          <ion-button expand="block" size="large" [disabled]="!form.valid" type="submit">
            {{ t.FORGOT_PASSWORD.reset.resetPassword | translate }}
          </ion-button>
        </form>
      </div>

      <div class="ion-padding" *ngIf="error">
        <ion-button (click)="forgotPassword()" *ngIf="error === 'invalidToken'">
          {{ t.FORGOT_PASSWORD.reset.renewCode | translate }}
        </ion-button>

        <ion-button fill="outline" (click)="backToRoot()">
          <span class="text-black">{{ t.FORGOT_PASSWORD.reset.backToRoot | translate }}</span>
        </ion-button>
      </div>
    </ui-card>
  </ion-content>
</ui-page>

