import { BlinkPermission, BlinkPermissions } from "./blink-user-context";

export function permissionCheck(blinkPermissions: BlinkPermissions, permission: BlinkPermission | BlinkPermission[]) {
  const permissions = Array.isArray(permission)
    ? permission
    : [permission];
  if (!blinkPermissions) {
    return false;
  }
  return permissions
    .map(permission => blinkPermissions[permission])
    .some(permission => permission);
}
