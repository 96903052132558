export default {
  config: {
    checklist: {
      checklist: 'Modello di modulo',
      checklists: 'Modelli di moduli',
      none: 'Nessun modulo disponibile.',
      new: 'Nuovo modello di modulo',
      edit: 'Modifica modello di modulo',
      saved: 'Il modello del modulo è stato salvato.',
      delete: 'Elimina modello di modulo',
      deleteChecklistResults: 'Eliminare anche tutti i moduli completati del modello di modulo {{name}}?',
      deleteInfo: 'Se elimini il modello del modulo, anche tutti i moduli compilati verranno irrevocabilmente eliminati! <br><br>Per conservare i moduli compilati, disattivare il modello del modulo.',
      deleted: 'Il modello del modulo è stato eliminato.',
      duplicate: 'Modello di modulo duplicato',
      duplicateInfo: 'È possibile utilizzare la funzione di duplicazione per duplicare i modelli di modulo',
      duplicateMessage: 'Vuoi duplicare il modello di modulo "{{checklistName}}" con tutto il contenuto?',
      duplicated: 'Il modello del modulo è stato duplicato.',
      templateName: 'Nome del modulo',
      activeInfo: 'È possibile compilare solo i modelli attivi; i modelli inattivi possono essere modificati solo nella gestione dei modelli.',
      locationRequired: "La selezione dell'oggetto è obbligatoria",
      locationRequiredInfo: 'Se attivato, per compilare il modulo è necessario selezionare un oggetto o scansionare il codice QR.',
      loginUserRequired: 'La selezione dei dipendenti è obbligatoria',
      loginUserRequiredInfo: 'Se attivato, è necessario selezionare un dipendente per compilare il modulo.',
      allLocationAllowed: 'Consenti tutti gli oggetti',
      allowOnlyCertainLocations: 'Consenti solo determinati oggetti',
      public: 'Forma pubblica',
      publicInfo: 'Crea un collegamento pubblico con cui il modulo può essere compilato da qualsiasi utente. Non è richiesto alcun accesso o app. È possibile specificare una data di validità fino a quando il collegamento dovrebbe essere valido.',
      preview: 'Abilita anteprima',
      previewInfo: `Se attivato, verrà sempre visualizzato un pulsante “Anteprima” nella parte inferiore del modulo durante la compilazione. In alternativa al pulsante di anteprima permanente, puoi anche posizionare l'elemento di contenuto "Anteprima" in un punto qualsiasi del modulo (non è necessario attivare questa funzione).`,
      shortUrlExpireDate: 'Data di scadenza',
      sysadminOnly: 'Solo amministratori di sistema',
      allUsers: 'Tutti gli utenti',
      usersOfPermissionGroup: 'Utenti di questi gruppi di autorizzazione',
      emailConfig: 'Configurazione della posta elettronica',
      emailConfigInfo: 'Se inserisci qui gli indirizzi email, i moduli compilati verranno automaticamente inviati a questi indirizzi email.',
      emailSaved: "L'indirizzo e-mail è stato salvato",
      emailRemoved: "L'indirizzo e-mail è stato eliminato",
      importExportInfo: 'La funzione di esportazione consente di esportare modelli di moduli e modelli di report associati per reimportarli in un altro client o sistema Blink Check.',
      import: 'Importa modello di modulo',
      importInfo: 'Seleziona il file .check-b64 del modello di modulo che desideri importare.',
      imported: 'Il modulo è stato creato correttamente.',
      templatesInAppFolder: "Modelli nella directory dell'app:",
      invalidImport: 'Il file selezionato non è un modello di modulo valido.',
      exported: 'Il modello del modulo è stato esportato',
      offline: 'Stabilisci una connessione Internet per modificare i modelli di modulo.',
      checklistContainsCreateTask: `Il modello di modulo contiene l'elemento "Crea ticket" e pertanto non può essere impostato su pubblico. Rimuovi l'elemento "Crea biglietto".`
    },
    category: {
      category: 'categoria',
      categories: 'Categorie',
      saved: 'La categoria è stata salvata.',
      editTitle: 'Modifica categoria',
      content: 'elementi',
      newCategory: 'Nuova categoria',
      name: 'Nome della categoria',
      edit: 'Modifica categoria',
      duplicated: 'La categoria è stata duplicata.',
      noCategories: 'Non ci sono ancora categorie. Crea nuove categorie utilizzando il pulsante "Aggiungi categoria".',
      inactive: 'inattivo',
      showInactive: 'Mostra quelli inattivi',
      duplicateCategoryConfirmHeader: 'Categoria duplicata',
      duplicateCategoryConfirm: 'Vuoi duplicare la categoria "{{categoryName}}" con tutti i suoi contenuti?',
      delete: 'Elimina categoria',
      deleteCategoryConfirm: 'Sei sicuro di voler eliminare la categoria "{{categoryName}}"?',
      deleteSuccess: 'La categoria è stata rimossa.'
    },
    group: {
      group: 'Pagina',
      groups: 'pagine',
      created: 'La pagina è stata creata.',
      updated: 'Le modifiche alla pagina sono state salvate.',
      updateError: 'Impossibile salvare la pagina. Per favore riprova più tardi.',
      rename: 'Rinomina pagina',
      saved: 'La pagina è stata salvata.',
      toggleSort: 'Cambia ordinamento',
      editGroupName: 'Modifica il nome della pagina',
      new: 'Nuova pagina',
      name: 'Nome della pagina',
      duplicated: 'La pagina è stata duplicata.',
      noGroups: 'Non ci sono ancora pagine. Crea nuove pagine utilizzando il pulsante "Aggiungi pagina".',
      noItems: 'Non ci sono ancora elementi. Crea nuovi elementi utilizzando il pulsante "Aggiungi elemento".',
      add: 'Aggiungi pagina',
      infoElement: 'Elemento informativo',
      inputElement: 'Elemento di input',
      addInfoElement: 'Aggiungi elemento informativo',
      addInputElement: 'Aggiungi elemento di input',
      delete: 'Elimina pagina',
      deleteGroupConfirm: 'Sei sicuro di voler eliminare la pagina "{{groupName}}"?',
      chooseGroup: "A quale pagina deve essere aggiunto l'elemento?",
      duplicateGroupConfirmHeader: 'Pagina duplicata',
      duplicateGroupConfirm: 'Vuoi duplicare la pagina "{{groupName}}" con tutto il contenuto?',
      deleteSuccess: 'La pagina è stata rimossa.'
    },
    item: {
      add: 'Aggiungi elemento',
      created: "L'elemento è stato creato",
      saved: "L'articolo è stato salvato.",
      chooseItemType: 'Scegliere oggetto',
      values: 'Valori',
      value: 'Valore',
      ratingNumber: 'Valutazione',
      addItemValue: 'Aggiungi nuovo valore',
      addItemValueSuccess: 'È stato aggiunto un nuovo valore',
      updateItemValueSuccess: 'Il valore è stato aggiornato',
      removeItemValueSuccess: 'Il valore è stato rimosso',
      removeItemValue: 'Rimuovi valore',
      takePictureButtonText: "Seleziona l'immagine",
      editTitle: 'Modifica elemento',
      editDependenciesTitle: 'Modifica dipendenze',
      required: 'Campo obbligatorio',
      name: "Nome dell'elemento",
      description: "descrizione facoltativa dell'elemento",
      points: 'Punti',
      duplicated: "L'articolo è stato duplicato.",
      duplicateItemConfirmHeader: 'Elemento duplicato',
      duplicateItemConfirm: `Vuoi duplicare l'elemento "{{itemName}}" con tutto il suo contenuto?`,
      delete: {
        title: 'Cancella oggetto',
        message: "Vuoi davvero eliminare l'elemento “{{itemName}}”?",
        success: "L'articolo è stato rimosso"
      },
      moveItem: 'Sposta elemento',
      move: 'Mossa',
      moveItemInfo: "Seleziona la categoria e la pagina in cui spostare l'elemento corrente.",
      itemMoved: "L'elemento è stato spostato"
    },
    editItem: {
      name: 'Cognome',
      nameInfo: "Questo testo viene visualizzato direttamente prima dell'elemento di input.",
      link: 'Indirizzo del collegamento',
      linkInfo: 'Questo indirizzo sarà accessibile tramite un pulsante. Per favore, inserisci un URL valido.',
      description: 'Descrizione',
      descriptionInfo: 'Se desideri fornire istruzioni per la compilazione del modulo, puoi inserirle qui.',
      linkDescription: 'designazione del pulsante',
      linkDescriptionInfo: 'Immettere un nome per il pulsante.',
      values: 'Valori',
      valuesInfo: 'È possibile specificare un numero di valutazione per ciascun valore. È possibile effettuare valutazioni in un secondo momento utilizzando il numero di valutazione. Il numero di valutazione è facoltativo.',
      value: 'Valore',
      valuePlaceholder: 'Nuovo valore',
      ratingNumber: 'Valutazione',
      requiredField: 'Campo obbligatorio',
      requiredFieldInfo: 'Se attivato come campo obbligatorio, questo campo deve essere compilato affinché il modulo possa essere inviato.',
      externalNo: 'Numero esterno',
      externalNoInfo: 'Informazioni facoltative per interfacce o valutazioni tramite sistemi esterni.',
      editable: 'Modificabile',
      editableInfo: "Affinché gli utenti possano inserire voci, l'elemento deve essere modificabile.",
      defaultValue: 'Specificare il valore predefinito',
      defaultValueInfo: "È possibile specificare un'assegnazione standard per questo elemento.",
      defaultValueInfoEditable: "Il valore può essere modificato dall'utente del modulo.",
      visible: 'visibilità',
      visibleInfo: "La visibilità controlla se l'elemento può essere visto dall'editor o meno.",
      externalNoAleradyUsed: 'Questo numero esterno è già in uso.',
      taskTypeId: 'tipo di biglietto',
      loginUserId: 'utente',
      locationId: "Nome dell'oggetto",
      videoId: 'Numero del video',
      videoType: 'servizi video',
      videoTypes_youtube: 'Youtube',
      videoType_vimeo: 'vimeo',
      videoInfo: "Inserisci qui l'ID video univoco. Puoi trovarlo, ad esempio, nell'URL del video. <br/>Esempio: https://www.youtube.com/watch?v=XXXXX o su Vimeo https://vimeo.com/XXXXX. <br/>In questo caso nel campo dovrebbe essere inserito XXXXX.",
      defaultTaskTitle: 'Titolo predefinito del biglietto',
      defaultTaskDescription: 'Descrizione predefinita del biglietto',
      titleEditable: "Titolo del ticket modificabile dall'utente",
      descriptionEditable: "Descrizione del biglietto modificabile dall'utente",
      dueDateEditable: "Data di scadenza del biglietto modificabile dall'utente",
      addReportAttachment: 'Allega il modulo compilato al biglietto in formato PDF',
      noTaskTypesFound: 'Non è stato trovato alcun tipo di biglietto. I tipi di biglietto possono essere gestiti tramite Blink Active.',
      noLoginUsersFound: 'Nessun utente trovato. Gli utenti possono essere gestiti tramite Blink Manager.',
      noLocationsFound: 'Non è stato possibile trovare oggetti. Gli oggetti possono essere gestiti tramite Blink Manager.',
      createTaskItemInfo: "Con questa funzione puoi creare Ticket Blink Active. È possibile specificare il titolo e la descrizione oppure chiedere all'utente di compilarli. L'oggetto viene assegnato automaticamente e il responsabile dell'oggetto viene assegnato come editor.",
      duplicatedValuesNotAllowed: 'Non sono consentiti valori duplicati.',
      valueLengthExceeded: 'Il valore inserito è troppo lungo e verrà abbreviato.'
    },
    itemTypes: {
      PreviewButton: 'anteprima del documento',
      Select: "Selezione dall'elenco",
      Signature: 'Firma',
      Email: 'inserimento e-mail',
      SendEmailTo: 'Invio di posta elettronica',
      Date: 'Data',
      Time: 'tempo',
      Boolean: 'Si nessuna domanda',
      TakePicture: 'fare una foto',
      TakeMultiPicture: 'fare foto',
      Scan: 'Scansione QR/EAN',
      Input: "l'immissione di testo",
      Textarea: 'Inserimento di testo su più righe',
      Link: 'collegamento',
      Picture: 'visualizzazione delle immagini',
      Video: 'visualizzazione video',
      Text: 'visualizzazione del testo',
      Header: 'titolo',
      Rate: 'Valutazione',
      RateSmiley: 'valutazione dello smiley',
      CreateTask: 'Crea biglietto',
      FileUpload: 'caricare files',
      Currency: 'valuta'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Visualizza una versione di anteprima del modulo.',
      Select: "Definire eventuali elementi dell'elenco da cui è possibile selezionarne uno.",
      Signature: 'Campo per la firma digitale (utilizzabile con il dito)',
      Email: "L'utente può fornire un indirizzo email valido.",
      SendEmailTo: "L'utente può fornire un indirizzo e-mail valido al quale verrà inviato il modulo compilato in formato PDF.",
      Date: "L'utente può specificare una data tramite un calendario",
      Time: "L'utente può specificare un orario",
      Boolean: "L'utente seleziona sì o no",
      TakePicture: "L'utente può scattare una foto con la fotocamera o caricare un'immagine.",
      TakeMultiPicture: "L'utente può scattare più foto con la fotocamera o caricare immagini.",
      Scan: 'Richiedi agli utenti di scansionare un codice QR o EAN. Il risultato viene visualizzato nel modulo e salvato.',
      Input: 'Campo di immissione testo a riga singola',
      Textarea: 'Inserimento di testo su più righe per commenti più lunghi',
      Link: 'Collegamento al sito esterno',
      Picture: "Carica un'immagine che verrà visualizzata durante la compilazione.",
      Video: 'Carica un video che verrà mostrato mentre compili il modulo.',
      Text: 'Aggiungi formattazione ai testi su più righe.',
      Header: 'Intestazione ampia per strutturare il contenuto',
      Rate: 'Valutazione a 5 stelle',
      RateSmiley: 'Scelta tra 3 faccine sorridenti (felice, neutrale, triste)',
      CreateTask: 'Viene creato un ticket per un utente',
      FileUpload: "L'utente può allegare un numero qualsiasi di file al modulo.",
      Currency: "L'utente può specificare un importo in una valuta."
    },
    template: {
      template: 'Modello di rapporto',
      templates: 'Modelli di report',
      name: 'Cognome',
      description: 'Descrizione',
      customer: 'cliente',
      new: 'Nuovo modello di rapporto',
      edit: 'Modifica modello di rapporto',
      create: 'Crea modello di rapporto',
      public: 'Pubblico',
      active: 'Attivo',
      deleteConfirm: "Sei sicuro di voler eliminare il modello di rapporto '{{templateName}}'?",
      deleteSuccess: 'Il modello di rapporto è stato rimosso',
      editor: 'Progettista di modelli',
      notForMobile: 'Il designer dei modelli non è ottimizzato per i dispositivi mobili. A questo scopo utilizzare Blink Check nel browser.',
      noTemplates: 'Non sono ancora presenti modelli di report.',
      custom: 'Modello individuale',
      defaultWithPoints: 'Modello standard - con punti percentuali',
      default: 'Modello standard - senza punti percentuali',
      createTemplateInfo: 'È possibile modificare il modello di report solo dopo che è stato creato.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Gruppo di autorizzazione globale',
    notAllowed: "Non hai l'autorizzazione per modificare le autorizzazioni di questo modello.",
    all: 'Il modulo può essere compilato da tutti gli utenti',
    groups: 'Il modulo può essere compilato solo dagli utenti di questi gruppi di autorizzazione',
    noGroupLicence: 'La licenza Blink Check per questo gruppo non è attivata.',
    overridingPermssion: `L'opzione "Impostazioni di sistema" nel gruppo di autorizzazioni "Amministratore di sistema" fornisce automaticamente agli utenti tutte le autorizzazioni per questo modulo.`,
    onlyLicensedPermissionGroups: 'Vengono visualizzati solo i gruppi di autorizzazione a cui è assegnata una licenza Blink Check.',
    canUpdateChecklist: 'Modifica modello di modulo',
    canFillChecklist: 'Compilare il modulo',
    canReadChecklistDataOwn: 'Visualizza e valuta i moduli autocompilati',
    canReadChecklistDataOwnEmployees: 'Visualizza e valuta tutti i moduli compilati dai tuoi dipendenti',
    canReadChecklistDataOwnLocations: 'Visualizza e valuta tutte le forme completate dei tuoi oggetti',
    canReadChecklistDataAll: 'Visualizza e valuta tutti i moduli compilati'
  },
  companyConfig: {
    companyConfig: "Configurazione dell'inquilino",
    logoInfo: "Viene visualizzato nell'intestazione nei modelli di report standard.",
    footer: 'piè di pagina',
    footerInfo: 'Viene visualizzato nel piè di pagina nei modelli di report standard.',
    offline: 'Stabilire una connessione Internet per poter modificare la configurazione.'
  },
  switchCompanyDialogHeader: 'Cambia clienti',
  switchCompanyDialogText: 'Se cambi cliente, tutti i moduli in corso verranno ripristinati. Vuoi continuare?',
  results: {
    results: 'Valutazione',
    details: 'Dettagli',
    created: 'Creato',
    result: 'Risultato',
    noReportJsonExists: 'Il rapporto non è stato ancora creato. Il rapporto verrà generato con un ritardo, riprova più tardi.',
    noResults: 'Non ci sono ancora moduli completati.',
    noResultsForDateFilter: 'Non ci sono moduli compilati per il periodo selezionato.',
    periodFrom: 'Periodo di',
    periodTo: 'periodo scaduto',
    location: 'oggetto',
    createUser: 'Creatore',
    loginUser: 'Dipendenti',
    checklist: 'Modello di modulo',
    user: 'utente',
    recentResults: 'Moduli attualmente completati',
    recent: 'Attuale',
    pointOf: '{{ itemPoints }} di {{ maxItemPoints }} punti',
    checklistCreatedByAt: 'Modulo compilato da {{ firstName }} {{ lastName }} il {{ createDate }} alle {{ createTime }} pm.',
    linkedTask: 'Biglietto collegato: #{{ taskId }} {{ taskTitle }}',
    offline: 'Si prega di stabilire una connessione Internet per poter valutare i moduli compilati.',
    delete: {
      title: 'Elimina il modulo compilato',
      message: 'Vuoi veramente eliminare il modulo compilato “{{checklistName}}”?',
      success: 'Il modulo compilato è stato rimosso'
    },
    editReferenceTimestamp: 'Modifica data di riferimento',
    editReferenceTimestampDescription: "La data di riferimento determina l'ora in cui è stato compilato il modulo. Questo potrebbe differire dal momento in cui è stato inviato il modulo."
  },
  dependencies: {
    dependency: 'Dipendenza',
    addTitle: 'Seleziona il valore di confronto',
    addMessage: "Fornisci il valore che deve essere soddisfatto affinché l'articolo venga visualizzato.",
    addTitleMulti: 'Seleziona i valori di confronto',
    addMessageMulti: "Fornisci i valori, uno dei quali deve essere soddisfatto affinché l'articolo venga visualizzato.",
    present: 'Disponibile',
    notPresent: 'Non disponibile',
    noResults: 'Nessuna dipendenza presente. È possibile aggiungere nuove dipendenze tramite Aggiungi dipendenza.',
    noAvailableItems: 'Sono presenti troppo pochi elementi nel modulo per creare dipendenze. Per favore aggiungi prima altri elementi.',
    noAvailableItemsInGroup: 'Sono presenti troppo pochi elementi nella pagina per creare dipendenze. Seleziona una pagina che contenga elementi.',
    dependencyAdded: 'È stata aggiunta la dipendenza.',
    dependencyRemoved: 'La dipendenza è stata rimossa.',
    dependencyInfo: 'Una volta aggiunta una dipendenza, questo elemento ({{elementName}}) apparirà solo se gli elementi dipendenti sono stati popolati.',
    true: 'SÌ',
    false: 'NO',
    '#not_empty#': 'Disponibile',
    '#empty#': 'Non disponibile',
    frown: 'Triste',
    meh: 'Sta andando bene',
    smile: 'Contento'
  },
  noChecklists: 'Nessun modulo disponibile.',
  updating: 'Blink Check è in fase di aggiornamento.',
  installing: 'Installazione della versione {{version}}...',
  appName: 'Controllo del battito di ciglia',
  notAvailableInPublicChecklists: ' (Non disponibile nei modelli di moduli pubblici)',
  pdfAssignTemplate: 'È necessario assegnare un modello di report al modulo prima di poter utilizzare il download del PDF.'
};
