import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { InfoType } from '../../../enums/info-type';

interface UiDisplayDescriptionProps extends FormlyFieldProps {
  infoType: keyof typeof InfoType;
}

@Component({
  selector: 'ui-formly-info',
  template: `
    <small class="mt-0 mb-15 text-gray">{{ props.label }}</small>`
})
export class UiFormlyDisplayDescriptionFieldTypeComponent extends FieldType<FieldTypeConfig<UiDisplayDescriptionProps>> {
}
