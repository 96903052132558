export default {
  home: {
    pageTitle: 'Моите форми',
    locationChecklists_label: 'Попълнете формуляр за обект',
    generalChecklists_label: 'Попълнете общ формуляр',
    processingChecklists_label: 'Формата е в ход',
    checklistDatas_label: 'Попълнени формуляри',
    selectLocation: 'Изберете обект',
    scanQrCode: 'Сканирайте обектния код',
    showAll: '...Покажи всички',
    showLess: '... показвай по-малко'
  },
  fill: {
    checklists: 'Форми',
    fillChecklist: 'попълнете формуляра',
    checklistNotFound: 'За съжаление формулярът, който поискахте, не можа да бъде намерен.',
    checklistOfflineLoginUserRequired: 'Тази форма изисква избор на служител. Моля, установете интернет връзка за достъп до шаблона на формуляр.',
    checklistNotOfflineAvailable: 'Шаблонът на формуляр не се запазва офлайн, моля, свържете се с интернет за достъп до шаблона на формуляр.',
    resetConfirm: 'Наистина ли искате да нулирате формуляра?',
    categoryAppeared: 'Добавена е категория „{{categoryName}}“.',
    groupAppeared: 'Добавена е страница „{{groupName}}“.',
    itemAppearedInGroup: 'Нови въпроси бяха добавени към страницата „{{groupName}}“.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Към следващия въпрос',
    containerUriExpired: 'За съжаление периодът за качване е изтекъл.',
    finishMessage: 'Вие сте въвели всички необходими данни и вече можете да попълните формуляра.',
    offlineMessage: 'Моля, свържете се с интернет, за да попълните формуляра.',
    offlinePreviewMessage: 'Моля, свържете се с интернет, за да генерирате визуализация.',
    checklistUpdatedHeader: 'Шаблонът на формуляр е променен',
    checklistUpdatedMessage: 'Шаблонът на формуляр е променен. Моля, проверете отново въведените данни, преди да изпратите формуляра.',
    checklistLocationRequiredHeader: 'Изберете обект',
    checklistLocationRequiredMessage: 'Трябва да изберете обект, за да попълните този формуляр.',
    finishSuccess: 'Формулярът беше успешно попълнен и всички данни бяха обработени.',
    finishError: 'Формулярът не може да бъде попълнен поради грешка. Моля, свържете се с поддръжката.',
    createdWith: 'Тази форма е създадена с',
    preview: 'предварителен преглед',
    fileUploadFileMissingHeader: 'Файловете не са намерени',
    fileUploadFileMissingMessage: 'Следните файлове са избрани, но не могат да бъдат намерени на устройството: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'да', no: 'Не' },
    scan: 'сканиране',
    email: { invalidEmail: 'Моля, проверете въведеното.' },
    takePicture: { picture: 'Снимка', buttonText: 'добавете снимка' },
    createTask: {
      taskTitle: 'Заглавие на билета',
      taskDescription: 'Описание на билета',
      taskDueDate: 'Краен срок за билета'
    }
  },
  noChecklistsForLocationFound: 'Няма форма за този обект. Моля, изберете друг имот или попълнете общ формуляр.',
  selectChecklistByLocation: 'Изберете формуляр за обект'
};
