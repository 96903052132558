export default {
  home: {
    pageTitle: 'Οι φόρμες μου',
    locationChecklists_label: 'Συμπληρώστε μια φόρμα για ένα αντικείμενο',
    generalChecklists_label: 'Συμπληρώστε τη γενική φόρμα',
    processingChecklists_label: 'Φόρμα σε εξέλιξη',
    checklistDatas_label: 'Συμπληρωμένα έντυπα',
    selectLocation: 'Επιλέξτε αντικείμενο',
    scanQrCode: 'Σάρωση κωδικού αντικειμένου',
    showAll: '...προβολή όλων',
    showLess: '... Δείξε λιγότερο'
  },
  fill: {
    checklists: 'Έντυπα',
    fillChecklist: 'συμπληρώστε τη φόρμα',
    checklistNotFound: 'Δυστυχώς, η φόρμα που ζητήσατε δεν βρέθηκε.',
    checklistOfflineLoginUserRequired: 'Αυτό το έντυπο απαιτεί την επιλογή υπαλλήλου. Δημιουργήστε μια σύνδεση στο διαδίκτυο για πρόσβαση στο πρότυπο φόρμας.',
    checklistNotOfflineAvailable: 'Το πρότυπο φόρμας δεν αποθηκεύεται εκτός σύνδεσης, συνδεθείτε στο Διαδίκτυο για να αποκτήσετε πρόσβαση στο πρότυπο φόρμας.',
    resetConfirm: 'Θέλετε πραγματικά να επαναφέρετε τη φόρμα;',
    categoryAppeared: 'Η κατηγορία "{{categoryName}}" προστέθηκε.',
    groupAppeared: 'Η σελίδα "{{groupName}}" προστέθηκε.',
    itemAppearedInGroup: 'Νέες ερωτήσεις προστέθηκαν στη σελίδα "{{groupName}}".',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Πάμε στην επόμενη ερώτηση',
    containerUriExpired: 'Δυστυχώς η περίοδος μεταφόρτωσης έχει λήξει.',
    finishMessage: 'Έχετε εισαγάγει όλα τα απαραίτητα δεδομένα και τώρα μπορείτε να συμπληρώσετε τη φόρμα.',
    offlineMessage: 'Συνδεθείτε στο διαδίκτυο για να συμπληρώσετε τη φόρμα.',
    offlinePreviewMessage: 'Συνδεθείτε στο Διαδίκτυο για να δημιουργήσετε μια προεπισκόπηση.',
    checklistUpdatedHeader: 'Το πρότυπο φόρμας άλλαξε',
    checklistUpdatedMessage: 'Το πρότυπο φόρμας έχει αλλάξει. Ελέγξτε ξανά τις καταχωρίσεις σας πριν υποβάλετε τη φόρμα.',
    checklistLocationRequiredHeader: 'Επιλέξτε αντικείμενο',
    checklistLocationRequiredMessage: 'Πρέπει να επιλέξετε ένα αντικείμενο για να συμπληρώσετε αυτήν τη φόρμα.',
    finishSuccess: 'Η φόρμα συμπληρώθηκε με επιτυχία και όλα τα δεδομένα υποβλήθηκαν σε επεξεργασία.',
    finishError: 'Δεν ήταν δυνατή η συμπλήρωση της φόρμας λόγω σφάλματος. Επικοινωνήστε με την υποστήριξη.',
    createdWith: 'Αυτή η φόρμα δημιουργήθηκε με',
    preview: 'προεπισκόπηση',
    fileUploadFileMissingHeader: 'Τα αρχεία δεν βρέθηκαν',
    fileUploadFileMissingMessage: 'Τα ακόλουθα αρχεία έχουν επιλεγεί αλλά δεν μπορούν να βρεθούν στη συσκευή: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Ναί', no: 'Οχι' },
    scan: 'σάρωση',
    email: { invalidEmail: 'Ελέγξτε την καταχώρισή σας.' },
    takePicture: { picture: 'Εικόνα', buttonText: 'προσθεσε μια φωτογραφια' },
    createTask: {
      taskTitle: 'Ο τίτλος του εισιτηρίου',
      taskDescription: 'Περιγραφή του εισιτηρίου',
      taskDueDate: 'Ημερομηνία λήξης εισιτηρίου'
    }
  },
  noChecklistsForLocationFound: 'Δεν υπάρχει φόρμα για αυτό το αντικείμενο. Επιλέξτε άλλο ακίνητο ή συμπληρώστε μια γενική φόρμα.',
  selectChecklistByLocation: 'Επιλέξτε φόρμα για αντικείμενο'
};
