import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiExternalLinkModalComponent } from './ui-external-link-modal.component';
import { UiExternalLinkOptions } from './ui-external-link-options';

@Injectable({
  providedIn: 'root'
})
export class UiExternalLinkService {

  private modalController = inject(ModalController)

  async confirm(externalLinkOptions: UiExternalLinkOptions) {
    await this.modalController.create({
      component: UiExternalLinkModalComponent,
      cssClass: 'normal-modal',
      backdropDismiss: true,
      componentProps: {
        externalLink: externalLinkOptions
      }
    }).then(async (modal) => {
      await modal.present();
    });
  }
}
