import { Observable, retry, timer } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LogRepository } from '../../log/log.repository';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  logRepository = inject(LogRepository);

  shouldRetry(error: HttpErrorResponse, index: number) {
    //in case of 408 Request Timeout or 504 Gateway Timeout, always retry 3 times
    const requestTimeout = error.status === 408 || error.status === 504;

    //In case of failed refreshToken we always retry 3 times, unless the refreshToken does not exist anymore
    const isTokenRefresh = error && error.url && error.url.includes('Token/Refresh') &&
      (error.error['Message'] !== 'specified refresh_token does not exist');

    if (requestTimeout || isTokenRefresh) {
      this.logRepository.add(`RetryInterceptor: Retrying request (${index}). Url: ${error.url}. Error: ${error.message}`, true);
      return timer(1000 * index);
    }

    throw error;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(retry({ count: 3, delay: this.shouldRetry }));
  }
}
