export default {
  barcodeScanner: {
    permissionHeader: 'İzin',
    permissionMessage: 'Kameranızı kullanma izni vermek istiyorsanız, uygulama ayarlarında etkinleştirin.',
    scanPrompt: "{{thing}}'i tara",
    enterPrompt: '{{thing}} girin',
    blinkId: 'Blink Kimliği',
    isNoBlinkId: 'Taranan QR kodu geçerli bir Blink ID değil',
    objectCode: 'Nesne Kodu'
  },
  camera: {
    promptLabelHeader: 'Kaynak seçin',
    promptLabelPicture: 'Bir fotoğraf çekin',
    promptLabelPhoto: 'Galeriden seçin'
  },
  network: {
    changed: {
      connected: 'İnternet bağlantısı kuruldu',
      disconnected: 'İnternet bağlantısı kesildi'
    }
  },
  file: { downloadStarted: 'İndirme başladı.' },
  nfc: {
    wrongCode: 'Taranan QR kodu bir nesne kodu değildir!',
    error: 'NFC hatası'
  }
};
