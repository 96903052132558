import { Injectable } from '@angular/core';
import { ApiManager, ApiTypeAndVersion, BlinkService } from '../shared/api-manager';
import { catchError, Observable, of } from 'rxjs';
import { BlinkVersion } from './version';

@Injectable({
  providedIn: 'root'
})
export class VersionApi {

  constructor(private apiManager: ApiManager) {
  }

  getApiVersion(): Observable<BlinkVersion> {
    return this.getVersion('api/v1', BlinkService.Core);
  }

  getCheckApiVersion(): Observable<BlinkVersion> {
    return this.getVersion('odata/v1', BlinkService.Check);
  }

  getActiveVersion() {
    return this.getVersion('odata/v1', BlinkService.Active);
  }

  getBaseDataVersion() {
    return this.getVersion('odata/v1', BlinkService.BaseData);
  }

  getInsightVersion() {
    return this.getVersion('odata/v1', BlinkService.Insight)
  }


  private getVersion(api: ApiTypeAndVersion, service: BlinkService): Observable<BlinkVersion> {
    return this.apiManager.get(api, 'Version', { key: 'single', service: service })
      .pipe(
        catchError(() => {
          return of({
            Version: 'n/a',
          } as BlinkVersion);
        })
      )
  }

}
