import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trackById } from '@blink/util';
import { CheckChecklist } from '@blink/api';
import { Observable } from 'rxjs';
import { BlinkIcon } from '@blink/ui';
import { Capacitor } from '@capacitor/core';
import { FillChecklistTranslateService } from '@blink/check/shared/services/fill-checklist';

@Component({
  selector: 'check-select-checklist',
  templateUrl: './select-checklist.component.html',
  styleUrls: ['./select-checklist.component.scss']
})
export class SelectChecklistComponent {
  trackById = trackById;
  BlinkIcon = BlinkIcon;

  generalChecklistsPageCount = 3;
  isApp: boolean;

  @Input() notStartedChecklists$: Observable<CheckChecklist[]>;
  @Input() startedChecklists$: Observable<CheckChecklist[]>;
  @Input() locationChecklistsExists$: Observable<boolean>;
  @Input() showLocationSelectButton: boolean;

  @Output() notStartedChecklistClick = new EventEmitter<CheckChecklist>();
  @Output() startedChecklistClick = new EventEmitter<CheckChecklist>();
  @Output() locationSelectButtonClick = new EventEmitter<void>();
  @Output() scanQrCode = new EventEmitter<string>();

  constructor(
    public t: FillChecklistTranslateService
  ) {
    this.isApp = Capacitor.isNativePlatform();
  }
}
