import { Component, Input, OnChanges } from '@angular/core';
import { NetworkService } from '@blink/capacitor-plugins';
import { GlobalTranslateService } from '@blink/util';
import { VideoDataModel } from '@blink/check/shared/types/main';

@Component({
  selector: 'check-video-display',
  templateUrl: './video-display.component.html',
  styleUrls: ['./video-display.component.scss'],
})
export class VideoDisplayComponent implements OnChanges {
  @Input() value: string;

  videoDataModel: VideoDataModel;

  constructor(public t: GlobalTranslateService,
              private networkService: NetworkService) {
  }

  ngOnChanges() {
    if (this.value && this.value !== '') {
      try {
        this.videoDataModel = new VideoDataModel(JSON.parse(this.value));
      } catch (e) {
        console.log('Error creating VideoDataModel.', e);
      }
    }
  }

  get isOnline(): boolean {
    return this.networkService.connected;
  }

  get youTubeUrl(): string {
    return `https://www.youtube.com/embed/${this.videoDataModel.videoId}?origin=http://localhost:4400/`;
  }

  get vimeoUrl(): string {
    return `https://player.vimeo.com/video/${this.videoDataModel.videoId}`;
  }

}
