export default {
  barcodeScanner: {
    permissionHeader: 'Autorizzazione',
    permissionMessage: "Se vuoi autorizzare l'uso della fotocamera, abilitala nelle impostazioni dell'app.",
    scanPrompt: 'Scansiona {{thing}}',
    enterPrompt: 'Inserisci {{thing}}',
    blinkId: 'ID lampeggiante',
    isNoBlinkId: 'Il codice QR scansionato non è un Blink ID valido',
    objectCode: 'Codice oggetto'
  },
  camera: {
    promptLabelHeader: 'seleziona la fonte',
    promptLabelPicture: 'Fai una foto',
    promptLabelPhoto: 'Seleziona dalla galleria'
  },
  network: {
    changed: {
      connected: 'Connessione Internet stabilita',
      disconnected: 'Connessione Internet interrotta'
    }
  },
  file: { downloadStarted: 'Il download è iniziato.' },
  nfc: {
    wrongCode: 'Il codice QR scansionato non è un codice oggetto!',
    error: 'Errore NFC'
  }
};
