import { CheckItemType } from './item-type';

export const getCheckItemIcon = (type: CheckItemType) => {
  switch (type) {
    case CheckItemType.Select:
      return 'list-alt';
    case CheckItemType.Signature:
      return 'signature';
    case CheckItemType.SendEmailTo:
    case CheckItemType.Email:
      return 'at';
    case CheckItemType.Date:
      return 'calendar';
    case CheckItemType.Time:
      return 'clock';
    case CheckItemType.Boolean:
      return 'check-square';
    case CheckItemType.TakePicture:
      return 'camera';
    case CheckItemType.TakeMultiPicture:
      return 'images';
    case CheckItemType.Scan:
      return 'qrcode';
    case CheckItemType.Input:
    case CheckItemType.Textarea:
      return 'align-left';
    case CheckItemType.Link:
      return 'link';
    case CheckItemType.Picture:
      return 'image';
    case CheckItemType.Text:
      return 'font';
    case CheckItemType.Header:
      return 'heading';
    case CheckItemType.Rate:
      return 'star';
    case CheckItemType.RateSmiley:
      return 'smile';
    case CheckItemType.CreateTask:
      return 'clipboard-list-check';
    case CheckItemType.Video:
      return 'video';
    case CheckItemType.PreviewButton:
      return 'file-pdf';
    case CheckItemType.Currency:
      return 'euro-sign';
    case CheckItemType.FileUpload:
      return 'folder-arrow-up';
    default:
      return 'icons';
  }
};
