export interface BlinkCompanyFeature {
  Id: number;
  InternalId: CompanyFeatureInternalId;
}

export enum CompanyFeatureInternalId {
  TimeLimits = 'TimeLimits',
  Stickers = 'Sticker',
  Toolsense = 'Toolsense'
}
