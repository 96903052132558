import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_NAME, APP_VERSION } from '@blink/util';


@Injectable()
export class ClientInfoInterceptor implements HttpInterceptor {

  constructor(@Inject(APP_NAME) private appName: string,
              @Inject(APP_VERSION) private appVersion: string) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(`https://null-api.blink.online`) ||
      request.url.startsWith('https://media.us1.twilio.com') ||
      request.url.startsWith('https://media.ie1.twilio.com')) {
      return next.handle(request);
    }

    return next.handle(this.addClientInfo(request));
  }

  addClientInfo(request): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Client-Info': `${this.appName} ${this.appVersion}`
      }
    });
  }
}
