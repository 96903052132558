export default {
  home: {
    pageTitle: 'Startseite',
    welcome: 'Willkommen in der Blink me App. Viel Spaß beim Chatten,\n\nDein Blink Team',
    startChat: 'Start',
    ticket: 'Ticket',
    timeLicenseMissing: 'Ihnen wurde keine Blink Time Lizenz zugewiesen!',
  },
  conversations: {
    pageTitle: 'Nachrichten',
    yourChats: 'Deine Chats',
    chat: 'Chat'
  },
  settings: {
    settings: 'Einstellungen',
    pushEnabled: 'Push Benachrichtungen erhalten',
    system: 'System',
    languageSelection: 'Sprachauswahl',
    currentSettings: 'Aktuelle Einstellungen',
    user: 'Benutzer',
    appVersion: 'Version (App)',
    apiVersion: 'Version (Api)',
    deviceInfo: 'Geräteversion',
    buildVersion: 'Version (Build)',
    helpAndDataPolicy: 'Hilfe und Datenschutz',
    text1: 'Unser Support Team steht Ihnen bei Fragen gerne zur Verfügung:',
    phone: 'Telefon',
    mail: 'E-Mail',
    information: 'Info',
    documentation: 'Online-Dokumentation',
    documentationText: 'In unserer Online Dokumentation finden Sie Anleitungen und Videos für alle wichtigen Bereiche von Blink.',
    dataPolicy: 'Datenschutz',
    support: 'Support',
    moreActions: 'Weitere Aktionen',
    protocol: 'Protokoll',
    showChatUserSecret: 'Chat für anderes Gerät freigeben',
    scanCodeAnotherDevice: 'Scannen Sie den folgenden QR-Code auf Ihrem neuen Gerät oder geben Sie Ihren persönlichen Chat-Code auf dem neue Gerät ein!',
    yourPersonalCode: 'Ihre persönlicher Chat-Code',
    permissions: {
      title: 'Benachrichtigung',
      subtitle: 'Notwendig um Push Benachrichtigungen für Chat-Nachrichten, Blink Benachrichtigungen und Sticker zu erhalten.',
      grantPermission: 'Berechtigung erteilen',
      camera: 'Kamera',
      cameraHint: 'Notwendig für die Zeiterfassung per Objekt-Code.',
      nfc: 'NFC',
      nfcHint: 'Notwendig für die Zeiterfassung per NFC-Tag.',
      media: 'Bild aufnehmen und Bild aus Bibliothek',
      mediaHint: 'Notwendig um Bilder und Dokumente zu Ihren Zeiten und Tickets zu erfassen.',
      location: 'Standort',
      locationHint: 'Notwendig um Ihren Standort zum Zeitpunkt der Zeiterfassung zu erfassen.',
      locationDisabledOnSystem: 'Standortdienste sind deaktiviert. Bitte aktivieren Sie diese in den Einstellungen Ihres Geräts.',
      notifications: 'Push Benachrichtigungen',
      notificationsHint: 'Notwendig um Push Benachrichtigungen für Chat-Nachrichten, Blink Benachrichtigungen und Sticker zu erhalten.'
    }
  },
  connectionState: {
    connecting: 'Verbinde...',
    connected: 'Verbunden.',
    disconnecting: 'Verbindung wird getrennt...',
    disconnected: 'Verbindung getrennt',
    denied: 'Verbindung konnte nicht hergestellt werden.'
  },
  noOneToFind: 'Keiner zu finden.',
  nothingToFind: 'Nichts zu finden.',
  noLocationFound: 'Keine Objekte gefunden.',
  searchPlaceholder: 'Suchen...',
  employeeSearchPlaceholder: 'Name, Vorname',
  typeToSearch: 'Zum Suchen tippen...',
  typeToSearchEmployee: 'Gib den Namen der Person an um einen Chat zu starten',
  createChatWithEmployee: 'Mitarbeiter suchen',
  createChatWithLocationTeam: 'Objekt suchen',
  createTeamleaderChat: 'Alle meine Mitarbeiter',
  yourLanguageSelection: 'Deine Sprachauswahl:',
  loginInfo: 'Um dich anzumelden scanne bitte deinen persönlichen QR Code:',
  updating: 'Blink me wird aktualisiert.',
  notice: 'Hinweis',
  importantNotice: 'Wichtiger Hinweis',
  continue: 'Weiter',
  intro: {
    slide1: {
      header: 'Willkommen',
      text: 'Viel Spaß mit deiner Blink me App.'
    },
    slide2: {
      header: 'Chat',
      text: 'Chatte direkt mit deinen Kollegen oder erfahre Aktuelles aus interessanten Gruppen.'
    },
    slide3: {
      header: 'Tickets',
      text: 'Hier kannst du deine Aufgaben sehen und Anträge erstellen.'
    }
  },
  pleaseWait: 'Bitte warten...',
  skip: 'Überspringen',
  startAgain: 'Neu starten',
  lock: {
    createPinHeader: 'Deine persönliche PIN',
    createPinInfo: 'Zum Schutz deiner Daten kannst du hier eine PIN festlegen, die du bei jeder Verwendung der Blink me App eingeben musst.',
    validatePin: 'Bitte gebe die PIN noch einmal ein.',
    tryAgain: 'PIN stimmt nicht überein. Bitte versuche es noch einmal.',
    triedToOften: 'Leider stimmt deine PIN nicht überein. Möchtest Du den Prozess neu starten oder überspringen und später vornehmen?',
    enterPinHeader: 'PIN Eingabe',
    enterPinInfo: 'Bitte gib deine Pin ein, um die App zu entsperren.',
    remainingTrys: 'Noch {{amount}} Versuche. Dann muss die Anmeldung in der App neu durchgeführt werden.',
    tooManyEnters: 'Eine Anmeldung mit PIN ist leider nicht mehr möglich. Bitte melde Dich mit Deiner Blink ID Karte neu an.',
    reSignIn: 'Neu anmelden',
    setpin: 'PIN Eingabe bei App Nutzung',
    skip: 'Möchtest Du jetzt keine PIN vergeben? Du kannst diese Funktion jederzeit über die Einstellungen der App auch später aktivieren.',
    dontUsePin: 'keine PIN verwenden',
    deactivatePinHeader: 'PIN Deaktivieren',
    deactivatePinInfo: 'Bitte gib deine Pin ein, um den Schutz zu deaktivieren.'
  },
  active: {
    yourTickets: 'Deine Tickets',
    createNewTask: 'Neues Ticket anlegen',
    assignee: 'Bearbeiter',
    location: 'Objekt',
    dueDate: 'Fällig am',
    createTask: 'Ticket anlegen',
    taskCreated: 'Das Ticket wurde angelegt.',
    backToTasks: 'Zurück zu Tickets',
    createdAt: 'angelegt am',
    attachments: 'Anhänge',
    addFileOrImage: 'Datei oder Foto hinzufügen',
    addImage: 'Foto hinzufügen',
    comments: 'Kommentare',
    commentCreated: 'Kommentar wurde gespeichert.',
    showClosed: 'Abgeschlossene Tickets anzeigen',
    resetFilter: 'alle Filter zurücksetzen',
    changeTicketStatusTo: 'Ticketstatus ändern in:',
    editTask: 'Ticket bearbeiten',
    saveTask: 'Ticket speichern',
    taskSaved: 'Das Ticket wurde gespeichert.',
    deleteImage: 'Bild löschen',
    deleteImageConfirmation: 'Möchten Sie das Bild wirklich löschen?',
    deleteAttachment: 'Anhang löschen',
    deleteAttachmentConfirmation: 'Möchten Sie diesen Anhang wirklich löschen?',
    changeAssignee: 'Bearbeiter ändern',
    duplicateTask: 'Ticket duplizieren',
    duplicateTaskConfirmation: 'Wollen Sie dieses Ticket duplizieren',
    taskDuplicated: 'Ticket wurde dupliziert.',
    deleteTask: 'Ticket löschen',
    deleteTaskConfirmation: 'Wollen Sie dieses Ticket wirklich löschen?',
    taskDeleted: 'Das Ticket wurde gelöscht.',
    assigneeChanged: 'Bearbeiter wurde geändert.',
    statusChanged: 'Der Ticketstatus wurde geändert.',
    startDate: 'Start',
    endDate: 'Ende',
    doctorVisitDate: 'Datum des Arztbesuches',
    doctorVisitDescription: "Datum des Arztbesuches: {{date}}",
    chooseDate: 'Wählen Sie ein Datum',
    comment: 'Kommentar (optional)',
    dateError: 'Das Ende Datum darf nicht vor dem Start Datum liegen',
    employee: 'Mitarbeiter',
    pleaseConnectToLoad: 'Bitte stellen Sie eine Internetverbindung her um Ihre Tickets zu laden.'
  },
  emptyState: {
    chats: 'Erstelle einen Chat!',
    chat: 'Schreibe die erste Nachricht :)',
    tasks: 'Keine Tickets zu finden.',
    attachments: 'Keine Anhänge vorhanden.',
    comments: 'Keine Kommentare vorhanden.',
    vacation: 'Kein Urlaub für dieses Jahr eingetragen'
  },
  formError: {
    min: 'Wert muss größer als {{value}} sein.',
    min2: 'Wert muss größer oder gleich {{value}} sein.',
    max: 'Wert muss kleiner oder gleich {{value}} sein.',
    requiredBecause: 'Wert benötigt weil {{name}} ausgefüllt ist.',
    isRequired: '{{name}} ist Pflicht.',
    numberInUse: 'Personalnummer ist bereits in Verwendung.',
    maxlength: '{{name}} darf nicht mehr als {{value}} Zeichen enthalten.',
    faultyBlinkId: 'Blink ID fehlerhaft'
  },
  ngSelect: {
    typeToSeach: 'Bitte 3 Buchstaben oder mehr eingeben.',
    loading: 'lade Daten...',
    notFound: 'Keine {{title}} gefunden.',
    clearAll: 'Zurücksetzen'
  },
  placeholder: {
    employee: 'Suchen nach Vor- und Nachname, Personal-Nr., Blink ID',
    location: 'Suche nach Name, Nummer oder Objekt-Codes',
    date: 'Wähle ein Datum',
    searchTasks: 'Suche Tickets...'
  },
  hasChat: 'Jetzt Chat starten',
  hasNoChatYet: 'hat die Blink me App leider noch nicht installiert.',
  noBlinkActiveLicence: 'Keine Lizenz',
  noBlinkActiveLicenceInfo: 'Sie verfügen derzeit über keine Lizenz für diese App. Bitte wenden sie sich an ihren Vorgesetzten.',
  noBlinkMeLicence: 'Keine Lizenz',
  noBlinkMeLicenceInfo: 'Diese App kann nicht mit Ihrem System genutzt werden. Bitte nutzen Sie die Blink Time aus dem App- oder Playstore.',
  noSuitableAppFound: 'Keine App zum öffnen der Datei gefunden.',
  myChats: 'Deine Chats',
  hasNoChatsHome: 'Starte jetzt mit dem Chat! Du kannst dich mit allen Kollegen unterhalten und in Objekt und Teamgruppen teilnehmen.',
  hasNoTicketsHome: 'Keine offenen Tickets vorhanden.',
  myTickets: 'Deine Tickets',
  myEmployees: 'Meine Mitarbeiter',
  group: 'Gruppe',
  confirmLogoutWithChat: 'Wenn Sie sich abmelden werden ihre Chat Nachrichten gelöscht. Wollen Sie fortfahren?',
  confirmLogout: 'Möchten Sie sich wirklich abmelden?',
  message: 'Nachricht',
  messageNotDecryptable: 'Nachricht konnte nicht entschlüsselt werden.',
  chat: {
    userAlreadyExists: 'Sie haben den Chat bereits auf einem anderen Gerät eingerichtet. Um den Chat auch auf diesem Gerät nutzen zu können, müssen Sie die Geräte verknüpfen. Alternativ können Sie die Einrichtung neu starten, dann kann der Chat auf dem anderen Gerät aber nicht mehr genutzt werden.',
    linkDevices: 'Geräte verknüpfen',
    withoutChat: 'Ohne Chat fortsetzen',
    setupAgain: 'Chat neu einrichten',
    setupAgainText: 'Wenn Sie den Chat neu einrichten, werden alle Nachrichten der bestehenden Einzelchats gelöscht und der Chat kann auf den anderen Geräten nicht mehr genutzt werden! Möchten Sie fortsetzen?',
    LinkDevicesText: 'Um die Geräte für den Chat zu verknüpfen, öffnen Sie auf Ihrem anderen Gerät die Einstellungen und wählen “Chat für anderes Gerät freigeben',
    personalToken: 'Ihr persönlicher Chat-Code',
    wrongCode: 'Der angegebene Chat-Code ist nicht korrekt.',
    beingPrepared: 'Chat wird vorbereitet.',
    twilioAccessDenied: 'Die Verbindung zum Chat-Service konnte nicht hergestellt werden. Bitte kontaktieren Sie den Blink Support.',
    notificationsDenied: 'Benachrichtigungen sind auf Ihrem Gerät/Browser deaktiviert. Gehen Sie in die Einstellungen und aktivieren Benachrichtigungen für Blink me um Benachrichtigungen zu erhalten. Anschließend können Sie in den persönlichen Einstellungen Benachrichtigungen aktivieren.',
    deleted: "Chat wurde gelöscht",
    unavailable: "Chat ist nicht verfügbar"
  },
  protocol: {
    SEND_EMAIL: 'Per E-Mail verschicken',
    DELETE: 'Protokoll löschen',
    deleteMessage: 'Wollen Sie das Protokoll wirklich löschen? Diese Aktion lässt sich nicht rückgängig machen.',
    Send: 'Senden',
    ConfirmMessage: 'Das Protokoll der App wird nun an den Blink-Support gesendet.',
    SuccessMessage: 'Das Protokoll wurde erfolgreich an den Blink-Support gesendet.',
  },
  timeTracking: {
    title: 'Zeiterfassung',
    start: 'Starten',
    startDescription: 'Click start to start time tracking',
    started: 'Gestartet',
    running: 'Zeiterfassung läuft',
    stop: 'Stoppen',
    duration: 'Dauer',
    syncButtonLabel: 'Offline erfasste Zeiten hochladen',
    offlineSyncMessage: 'Um die gespeicherten Zeiten zu übertragen müssen Sie online sein.',
    successfulSync: 'Zeiten werden hochgeladen',
    scanNowQrCode: 'QR Code',
    scanNowQrCodeDescription: 'Scannen Sie den QR-Code im Objekt.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Halten Sie Ihr Smartphone an den NFC-Aufkleber im Objekt.',
    nfcDescription: 'Erfassung per NFC: Halten Sie Ihr Handy an den Objekt Code',
    noTimeTrackingMethodAvailableDescription: 'Für diesen Benutzer ist keine Zeiterfassungsmethode konfiguriert. Bitte wenden Sie sich an Ihren Teamleiter.',
    noLocationCode: 'Kein Code vorhanden',
    noLocationCodeDescription: 'Wählen Sie diese Option wenn kein QR-Code oder NFC Aufkleber im Objekt vorhanden ist. Angabe eines Kommentares ist Pflicht.',
    noLocationCodeConfirm: 'Sind Sie sicher, dass Sie ohne Objekt-Code starten wollen? Sie müssen zum Stoppen einen Kommentar angeben.',
    wrongCode: 'Der gescannte QR-Code ist kein Objekt-Code!',
    codeNotAssignable: 'Der gescannte QR-Code kann nicht zugeordnet werden!',
    readNotice: 'Bitte Hinweise lesen!',
    warning: 'Warnung',
    lessThanAMinuteTitle: 'Zeiterfassung abschließen',
    lessThanAMinute: 'Sie haben vor weniger als 1 Minute gestartet. Sind Sie sicher, dass Sie stoppen möchten?',
    continue: 'Erfassung fortsetzen',
    timeAlert: {
      differenceInMinutesHint: 'Die Uhrzeit von Ihrem Smartphone weicht von der Uhrzeit des Servers ab. Die Differenz wird protokolliert',
      differentTimeZoneHint: 'Die Zeitzone von Ihrem Smartphone weicht von der Zeitzone des Servers ab. Die Differenz wird protokolliert.'
    },
    leaveComment: {
      withCode: 'Die Angabe des Objektes/Leistung war nicht eindeutig. Bitte teilen Sie uns mit, in welchem Objekt/Leistung Sie gearbeitet haben.',
      withoutCode: 'Es wurde bei Start oder Stop kein Objekt-Code erfasst. Bitte begründen Sie, warum Sie ohne Objekt gestartet/gestoppt haben.'
    },
    comment: 'Kommentar',
    enterComment: 'Kommentar zur Zeiterfassung',
    differentQrCode: {
      title: 'Objekt wechseln?',
      continueInLocation: 'Weiterarbeiten in {{locationName}}.',
      automaticStopInLocation: 'Die Zeiterfassung in {{locationName}} wird automatisch gestoppt.',
      stopInLocation: 'Zeiterfassung in {{locationName}} stoppen.',
      stop: 'Stoppen',
      stopNoLocation: 'Zeiterfassung stoppen.',
      continueNoOldLocation: 'Die Zeiterfassung auf das vorige Objekt wird automatisch gestoppt.',
      continueWorking: 'Weiterarbeiten',
      continueWorkingInNewLocation: 'Weiterarbeiten in neuem Objekt.',
      stopWithLocationActivity: 'Zeiterfassung auf {{locationActivity}} stoppen.',
      continueWithNewLocationActivity: 'Weiterarbeiten mit {{locationActivity}}',
      stopOldLocationActivity: 'Die Zeiterfassung auf {{locationActivity}} wird automatisch gestoppt.',
      stopOldNoLocationActivity: 'Die Zeiterfassung ohne Leistung wird automatisch gestoppt.',
      continueNoLocationActivity: 'Weiterarbeiten ohne Leistung.',
      sameLocationTitle: 'Leistung wechseln?',
      differentLocationTitle: 'Objekt wechseln?'
    },
    saveAndStop: 'Speichern und Stoppen',
    noLocationScanned: "- Kein Objekt -",
    noLocationFound: "- Objekt nicht eindeutig -",
    timerDifference: '{{h}} Stunden {{mm}} Minuten',
    stopSuccess: 'Zeit erfolgreich erfasst',
    stopSuccessToast: 'Zeiterfassung erfolgreich gestoppt.',
    stopTimeTracking: 'Stoppen',
    manual: {
      time: 'Zeit',
      locationSelect: 'Objekt auswählen',
      loadMore: "Mehr laden",
      trackTime: 'Zeit erfassen',
      location: 'Objekt',
      locationActivity: 'Leistung',
      date: 'Datum',
      startTime: 'Von',
      endTime: 'Bis',
      create: 'Bestätigen',
      createAgain: 'Weitere Zeit für dieses Objekt erfassen',
      errorUntil: "Von Zeit muss kleiner als Bis Zeit sein",
      worktimeDifference: 'Arbeitszeit:',
      worktimeDifferenceValue: '{{hours}} Stunden {{minutes}} Minuten',
      worktimeDifferenceValueMinutes: '{{minutes}} Minuten',
      success: 'Zeit erfolgreich erfasst',
      break: 'Pause: ',
      breakMinutes: '{{minutes}} Minuten',
      breakHoursAndMinutes: '{{hours}} Stunden {{minutes}} Minuten',
      noActivity: '- keine Leistung -',
      locationPlaceHolder: 'Suche nach Name, Nummer oder Objekt-Codes',
      conflictError: 'Es gibt bereits eine Zeiterfassung in den angegebenen Zeiträumen. Überschneidungen sind nicht erlaubt. Bitte kontrollieren Sie Ihre Angaben.',
      currentDayDuration: 'Bereits erfasst',
      expectedDuration: 'Voraussichtliche Arbeitszeit',
      expectedDurationIncludedCurrent: 'inklusive aktueller Eingabe'
    },
    differenceInMinutesHint: 'Die Uhrzeit von Ihrem Smartphone weicht von der Uhrzeit des Servers ab. Die Differenz wird protokolliert',
    differentTimeZoneHint: 'Die Zeitzone von Ihrem Smartphone weicht von der Zeitzone des Servers ab. Die Differenz wird protokolliert.',
    proposed: {
      capture: "Erfassen",
      description: 'Bestätige deine Arbeitszeit, sobald du mit deiner Tätigkeit im Objekt fertig bist.',
      time: 'Zeit',
      captureTime: 'Zeit bestätigen',
      button: 'Bestätigen',
      title: 'Arbeitszeit bestätigen',
      includingBreak: '(inkl. Pause)',
      from: 'Von',
      to: 'Bis',
      break: 'Pause',
      hour: 'Uhr',
      confirm: {
        description: 'Bitte bestätigen Sie Ihre Arbeitszeit',
        confirm: 'Ja, stimmt',
        editButton: 'Nein, stimmt nicht'
      },
      edit: {
        description: 'Bitte geben Sie die Dauer an',
        breakIncluded: 'Inklusive {{ duration }} Pause',
        comment: 'Kommentar',
        yourComment: 'Ihr Kommentar',
        minutes: 'Minuten',
        hours: 'Stunden'
      },
      reasons: {
        overtime: 'Mehrarbeit wegen Kunde',
        faster: 'Schneller fertig',
        representative: 'Ich wer Vertretung',
        other: 'Sonstiges'
      }
    }
  },
  timeFramePicker: {
    title: 'Bitte Zeitraum auswählen',
    today: 'Heute',
    currentWeek: 'Aktuelle Woche',
    currentMonth: 'Aktueller Monat',
    custom: 'Benutzerdefiniert',
    startDate: 'Start',
    endDate: 'Ende'
  },
  timeSheet: {
    title: "Zeiten & Planungen",
    noPlannings: 'Keine Planung',
    planned: 'Geplant',
    workingTime: 'Arbeitszeit',
    workloadDuration: 'Wochenarbetiszeit',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Soll',
    workedDuration: 'Ist',
    hasWorkload: 'Arbeitet an diesem Tag',
    pleaseConnectToLoad: 'Bitte stellen Sie eine Internetverbindung her um Ihre Zeiten zu laden.',
    homepageTitle: "Dein Arbeitstag",
    footer: {
      planned: 'Geplant',
      worklogsTotal: 'Stunden gesamt',
      workloadTotal: 'Stunden laut Vertrag',
      difference: 'Differenz'
    }
  },
  planning: {
    includingPause: 'Pause inkl.: ',
    modifyMinutes: 'Pauschale: ',
    planRoute: 'Route planen',
    title: 'Planung'
  },
  worklog: {
    title: 'Erfasste Zeit',
    absenceTitle: 'Abwesenheit',
    workingTime: 'Arbeitszeit',
    breakDuration: 'Pause',
    reduce: 'Pauschale',
    attachments: 'Anhänge',
    comments: 'Kommentare',
    comment: 'Kommentar',
    noLocation: 'Kein Objekt angegeben',
    changedBy: 'Zeit wurde geändert von {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Wollen Sie diesen Anhang wirklich löschen?',
    attachmentDeleted: "Anhang wurde gelöscht",
    confirmCommentDelete: 'Wollen Sie diesen Kommentar wirklich löschen?',
    commentDeleted: "Kommentar wurde gelöscht"
  },
  tabs: {
    home: 'Home',
    times: 'Zeiten',
    chat: 'Chat',
    tickets: 'Tickets',
    more: 'mehr',
  },
  profile: {
    title: 'Profil',
    testing: 'Testen',
    testingDescription: 'Dieser Bereich ist nur sichtbar, wenn man mit einem Testsystem verbunden ist.',
    testingAllowQrCodeOnWeb: 'Manuelle Eingabe von QR-Code erlauben',
    pushNotifications: 'Push Benachrichtigungen',
    reloadUserContext: 'UserContext neu laden',
    pushNotificationSuccessful: 'Push Benachrichtigung erfolgreich empfangen',
    editProfilePicture: 'Profilbild ändern',
    editProfilePictureTitle: 'Ausschnitt wählen',
    pushNotificationNotice: 'Für den Test ist Berechtigung “Mandanten-Einstellung bearbeiten” notwendig.',
    send: 'Senden',
    profilePicture: 'Profilbild'
  },
  help: {
    title: 'Hilfe'
  },
  myVacations: {
    vacation: 'Urlaub',
    title: 'Dein Urlaub',
    amountAvailableVacationDays: '{{amount}} Tage Urlaub noch verfügbar',
    totalVacationDays: 'Urlaubsanspruch {{ total }} Tage',
    remainingVacationDays: '({{ total }} Tage {{ sign }} {{ remaining }} Tage Resturlaub {{ previousYear }})',
    xDaysVacations: '{{ total }} Tage Urlaub'
  },
  externalLinks: {
    title: 'Externer Links',
    notice: 'Dieser Link wurde von Ihrem Unternehmen bereitgestellt. Sie verlassen das Blink System.'
  },
  stickers: {
    title: 'Deine Sticker',
    sticker: 'Sticker',
    sender: 'Empfänger',
    text: 'Betreff',
    date: 'Datum',
    emptyState: 'Du hast leider noch keine Sticker',
    senderDescription: 'Dieser Sticker wurde dir gesendet von {{sender}} am {{date}}',
    readed: 'gelesen',
    notReaded: 'noch nicht gelesen',
    newStickerToaster: 'Neuer Blink Sticker!',
    open: "Öffnen",
    next: 'Weiter',
    confirmDelete: 'Wollen Sie diese Sticker wirklich löschen?',
    titleCases: {
      case1: 'Herzlichen Glückwunsch zum 1. Sticker!',
      case2: 'Super, jetzt hast du schon <b>{{count}}</b> Sticker!',
      case3: 'Glückwunsch zum 10. Sticker!',
      case4: '30 Sticker - wow!',
      case5: 'Jetzt wird es voll hier: <b>{{count}}</b> Sticker!',
    },
  },
  autoTranslate: {
    isTranslated: 'Automatisch übersetzt',
    translationError: 'Automatische Übersetzung aktuell nicht möglich'
  },
  eLearning: {
    pendingTab: `Zu erledigen ({{value}})`,
    completedTab: `Erledigt ({{value}})`,
    completed: `Erledigt`,
    pending: 'Offen',
    dueFalling: 'Überfällig',
    title: 'E-Learning',
    course: 'Kurs',
    dueDate: 'Fällig bis',
    topics: 'Themen',
    completeDate: "Abgeschlossen am",
    status: {
      Pending: 'offen',
      Done: 'erledigt'
    },
    infos: {
      pendingExists: 'Hier findest du alle deine offenen Schulungen.',
      finishOverdue: 'Bitte mache die Schulungen, die schon fällig sind, so schnell wie möglich.',
      allCompleted: 'Super! Du hast alle deine Schulungen erfolgreich abgeschlossen.',
      emptyState: 'Du hast keine zugewiesenen Schulungen.'
    }
  },
  migration: {
    wrongVersion: 'In der aktuellen Version von Blink me ist die Anmeldung über die Blink Time App nicht unterstützt. Bitte aktualisieren Sie die Blink me App auf die neuste Version'
  },
  checklists: {
    title: 'Formulare',
    description: 'Formular für ein Objekt ausfüllen',
    button: 'Objekt wählen',
  }
}
