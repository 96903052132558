import { BlinkUserContext } from "../../core/session/blink-user-context";

export interface BlinkMeUserContext extends BlinkUserContext {
  TimeTrackingContext: TimeTrackingContext;
}


export interface TimeTrackingContext {
  AutoStopDurationHours: number;
  TimeTrackingMode: TimeTrackingMode;
  LocationActivities: TimeTrackingLocationActivity[];
  LocationQrCodes: TimeTrackingLocationQrCode[];
  Locations: TimeTrackingLocation[];
  ProposedDurations: TimeTrackingProposedDuration[];
}


export enum TimeTrackingMode {
  Exact = 'Exact',
  Proposed = 'Proposed'
}

export interface TimeTrackingLocationActivity {
  ActivityTypeName: string;
  Description: string;
  Id: number;
  LocationId: number;
}

export interface TimeTrackingLocationQrCode {
  Id: number;
  LocationActivityId: number;
  LocationId: number;
  QrCode: string;
}

export interface TimeTrackingLocation {
  Id: number;
  Comments: TimeTrackingLocationComment[];
  Name: string;
  EmployeeHint: string
}

export interface TimeTrackingLocationComment {
  Date: string;
  Text: string;
}

export interface TimeTrackingProposedDuration {
  Date: string; // required
  LocationId?: number; // optional
  ProposedDurationMinutes?: number; // required
  BreakDurationMinutes?: number; // optional
}

export interface TimeTrackingModes {
  timeTrackingMode: TimeTrackingMode;
  isExact: boolean;
  isProposed: boolean;
}
