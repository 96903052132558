import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { UiFormlyFieldRadioComponent } from "./radio.type";
import { UiFormlyFormFieldModule } from "../../wrappers/form-field/form-field.module";

@NgModule({
  declarations: [UiFormlyFieldRadioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: "radio",
          component: UiFormlyFieldRadioComponent
          // wrappers: ['ui-form-field']
        }
      ]
    })
  ]
})
export class UiFormlyRadioModule {
}
