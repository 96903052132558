import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CompanyRepository, SessionRepository } from '@blink/api';
import { GlobalTranslateService } from '@blink/util';

@Component({
  selector: 'blink-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
})
export class SelectCompanyComponent {
  $companies = this.companyRepository.companies$;

  constructor(
    public t: GlobalTranslateService,
    public modalController: ModalController,
    public menuController: MenuController,
    public navController: NavController,
    public router: Router,
    private companyRepository: CompanyRepository,
  ) {}

  selectCompany(company: any) {
    this.menuController.close('main-menu');
    this.modalController.dismiss(company, 'companySelected');
  }
}
