export default {
  home: {
    pageTitle: 'Moji obrasci',
    locationChecklists_label: 'Ispunite obrazac za objekt',
    generalChecklists_label: 'Ispunite opći obrazac',
    processingChecklists_label: 'Obrazac u tijeku',
    checklistDatas_label: 'Ispunjeni obrasci',
    selectLocation: 'Odaberite objekt',
    scanQrCode: 'Skeniraj objektni kod',
    showAll: '...Pokaži sve',
    showLess: '... pokaži manje'
  },
  fill: {
    checklists: 'Obrasci',
    fillChecklist: 'ispuni obrazac',
    checklistNotFound: 'Nažalost, obrazac koji ste tražili nije moguće pronaći.',
    checklistOfflineLoginUserRequired: 'Ovaj obrazac zahtijeva odabir zaposlenika. Uspostavite internetsku vezu za pristup predlošku obrasca.',
    checklistNotOfflineAvailable: 'Predložak obrasca nije spremljen izvanmrežno, povežite se s internetom da biste pristupili predlošku obrasca.',
    resetConfirm: 'Želite li stvarno poništiti obrazac?',
    categoryAppeared: 'Dodana je kategorija "{{categoryName}}".',
    groupAppeared: 'Stranica "{{groupName}}" je dodana.',
    itemAppearedInGroup: 'Nova su pitanja dodana na stranicu "{{groupName}}".',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Na sljedeće pitanje',
    containerUriExpired: 'Nažalost, rok za učitavanje je istekao.',
    finishMessage: 'Unijeli ste sve potrebne podatke i sada možete ispuniti obrazac.',
    offlineMessage: 'Molimo spojite se na internet kako biste ispunili obrazac.',
    offlinePreviewMessage: 'Povežite se s internetom za generiranje pregleda.',
    checklistUpdatedHeader: 'Predložak obrasca promijenjen',
    checklistUpdatedMessage: 'Predložak obrasca je promijenjen. Prije slanja obrasca ponovo provjerite svoje unose.',
    checklistLocationRequiredHeader: 'Odaberite objekt',
    checklistLocationRequiredMessage: 'Morate odabrati objekt da biste ispunili ovaj obrazac.',
    finishSuccess: 'Obrazac je uspješno ispunjen i svi podaci su obrađeni.',
    finishError: 'Obrazac se ne može ispuniti zbog greške. Molimo kontaktirajte podršku.',
    createdWith: 'Ovaj obrazac je kreiran sa',
    preview: 'pretpregled',
    fileUploadFileMissingHeader: 'Datoteke nisu pronađene',
    fileUploadFileMissingMessage: 'Sljedeće datoteke su odabrane, ali se ne mogu pronaći na uređaju: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Da', no: 'Ne' },
    scan: 'skenirati',
    email: { invalidEmail: 'Provjerite svoj unos.' },
    takePicture: { picture: 'Slika', buttonText: 'dodajte fotografiju' },
    createTask: {
      taskTitle: 'Naslov ulaznice',
      taskDescription: 'Opis ulaznice',
      taskDueDate: 'Datum dospijeća ulaznice'
    }
  },
  noChecklistsForLocationFound: 'Ne postoji obrazac za ovaj objekt. Odaberite drugu nekretninu ili ispunite opći obrazac.',
  selectChecklistByLocation: 'Odaberite obrazac za objekt'
};
