import { fromJS, Map } from 'immutable';

export enum VideoTypes {
  YouTube = 'youtube',
  Vimeo = 'vimeo',
}

export class VideoDataModel {
  data: Map<any, any>;

  constructor(data: any) {
    this.data = fromJS(data).toMap();
  }

  get videoId(): string {
    return this.data.get('VideoId');
  }

  set videoId(value: string) {
    this.data = this.data.set('VideoId', value);
  }

  get videoType(): VideoTypes {
    return this.data.get('VideoType') as VideoTypes;
  }

  set videoType(value: VideoTypes) {
    this.data = this.data.set('VideoType', value);
  }
}
