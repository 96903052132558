import { Directory, Filesystem } from '@capacitor/filesystem';

export const createFolder = async (path: string) => {
  try {
    await Filesystem.mkdir({
      path,
      directory: Directory.Documents,
      recursive: false
    });
  } catch (e) {
    console.log(`Directoy "${path}" already existed. Skipping.`);
  }
}
