export function toNumber(value: any): number {
  return Number(String(value).replace(',', '.'));
}

export function toArray(iterable, start?, end?) {
  if (!iterable || !iterable.length) {
    return [];
  }
  if (typeof iterable === 'string') {
    iterable = iterable.split('');
  }
  if (Array.prototype.slice) {
    return Array.prototype.slice.call(iterable, start || 0, end || iterable.length);
  }

  const array = [];
  let i;
  start = start || 0;
  end = end ? ((end < 0) ? iterable.length + end : end) : iterable.length;

  for (i = start; i < end; i++) {
    array.push(iterable[i]);
  }
  return array;
}

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '');
}

export function reduce(entries, field = 'duration'): any {
  let value = entries.map(entry => toNumber(entry[field])).reduce((sum: number, duration: number) => sum + duration);
  value = Math.round(value * 100) / 100;

  return isNaN(value) ? 0 : value;
}

export function isIos() {
  const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];

  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
}

export function isAndroid() {
  const iDevices = [
    'Android Simulator',
    'Android'
  ];

  if (navigator.userAgent) {
    while (iDevices.length) {
      if (navigator.userAgent.indexOf(iDevices.pop()) > 0) {
        return true;
      }
    }
  }

  return false;
}


// to.js
export default function to(promise) {
  return promise
    .then(data => {
      return [null, data];
    })
    .catch(err => [err]);
}

// blink ;)


export function openUrl(url) {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.setAttribute('href', url);
  anchor.setAttribute('target', '_blank');

  anchor.click();

  anchor.remove();
}

