export default {
  barcodeScanner: {
    permissionHeader: 'autorizáciu',
    permissionMessage: 'Ak chcete udeliť povolenie na používanie fotoaparátu, povoľte ho v nastaveniach aplikácie.',
    scanPrompt: 'Skenovať {{thing}}',
    enterPrompt: 'Zadajte {{thing}}',
    blinkId: 'ID blikania',
    isNoBlinkId: 'Naskenovaný QR kód nie je platným Blink ID',
    objectCode: 'Kód objektu'
  },
  camera: {
    promptLabelHeader: 'Vyberte zdroj',
    promptLabelPicture: 'odfotiť',
    promptLabelPhoto: 'Vyberte z galérie'
  },
  network: {
    changed: {
      connected: 'Internetové pripojenie vytvorené',
      disconnected: 'Internetové pripojenie bolo prerušené'
    }
  },
  file: { downloadStarted: 'Sťahovanie sa začalo.' },
  nfc: {
    wrongCode: 'Naskenovaný QR kód nie je objektový kód!',
    error: 'Chyba NFC'
  }
};
