import { Injectable } from '@angular/core';
import { TranslateGenericClass } from './translations';
import { GlobalTranslateService } from './global-translate.service';
import checkAr from './check-translations/check-ar';
import checkBg from './check-translations/check-bg';
import checkDe from './check-translations/check-de';
import checkEl from './check-translations/check-el';
import checkEn from './check-translations/check-en';
import checkEs from './check-translations/check-es';
import checkFa from './check-translations/check-fa';
import checkFr from './check-translations/check-fr';
import checkHr from './check-translations/check-hr';
import checkHu from './check-translations/check-hu';
import checkIt from './check-translations/check-it';
import checkPl from './check-translations/check-pl';
import checkPt from './check-translations/check-pt';
import checkRo from './check-translations/check-ro';
import checkRu from './check-translations/check-ru';
import checkTr from './check-translations/check-tr';

const suffix = 'blinkCheck';

@Injectable({
  providedIn: 'root'
})
export class CheckTranslateService extends TranslateGenericClass<typeof checkDe>() {
  constructor(public global: GlobalTranslateService) {
    super();

    global.setupService(this, suffix, checkDe);

    global.addTranslations('ar', suffix, checkAr);
    global.addTranslations('bg', suffix, checkBg);
    global.addTranslations('de', suffix, checkDe);
    global.addTranslations('el', suffix, checkEl);
    global.addTranslations('en', suffix, checkEn);
    global.addTranslations('es', suffix, checkEs);
    global.addTranslations('fa', suffix, checkFa);
    global.addTranslations('fr', suffix, checkFr);
    global.addTranslations('hr', suffix, checkHr);
    global.addTranslations('hu', suffix, checkHu);
    global.addTranslations('it', suffix, checkIt);
    global.addTranslations('pl', suffix, checkPl);
    global.addTranslations('pt', suffix, checkPt);
    global.addTranslations('ro', suffix, checkRo);
    global.addTranslations('ru', suffix, checkRu);
    global.addTranslations('tr', suffix, checkTr);
  }

}
