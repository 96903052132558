import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuItem } from '../menu-item';
import { MenuController } from "@ionic/angular";

@Component({
  selector: 'ui-mobile-tabs',
  templateUrl: './ui-mobile-tabs.component.html',
  styleUrls: ['./ui-mobile-tabs.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiMobileTabsComponent {
  @Input() tabs: Array<MenuItem>

  constructor(private menuController: MenuController) {
  }

  closeMenu() {
    void this.menuController.close();
  }
}
