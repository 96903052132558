import { CheckItem } from './item/item';
import { CheckItemType } from './item/item-type';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export interface CheckDependency {
  Id: number;
  DependencyId: number;
  Dependency: string;
  ChecklistId?: number;
  CategoryId?: number;
  GroupId?: number;
  ItemId?: number;

  fulfilled: boolean;
}

export const checkDependency = (dependency: CheckDependency, dependencyItem: CheckItem, value: any) => {
  let compareDependency = null;
  if (dependency.Dependency) {
    try {
      compareDependency = JSON.parse(dependency.Dependency);
    } catch (e) {
      console.log('Could not parse Dependency...');
    }
  }

  let dependencyFulfilled = false;

  if (!dependencyItem.fillVisible) {
    return dependencyFulfilled;
  }
  switch (dependencyItem.ItemType) {
    case CheckItemType.Boolean:
      dependencyFulfilled = dependencyItem && String(value) === compareDependency;
      break;

    case CheckItemType.Select:
    case CheckItemType.Rate:
    case CheckItemType.RateSmiley: {
      const dependencyValue = dependencyItem.itemValues
        .find(itemValue => itemValue.Name === String(value));


      if (Array.isArray(compareDependency)) {
        dependencyFulfilled = dependencyItem && dependencyValue && compareDependency?.includes(String(dependencyValue.Id));
      } else {
        dependencyFulfilled = dependencyItem && dependencyValue && compareDependency === String(dependencyValue.Id);
      }
      break;
    }
    default:
      if (compareDependency == '#empty#') {
        dependencyFulfilled = !value;
        break;
      }
      if (compareDependency == '#not_empty#') {
        dependencyFulfilled = !!value;
        break;
      }
    {
      const extractedCompareOperator = compareDependency.match(/#<_|#>_|#<=_|#>=_|#==_/)[0].replace('#', '').replace('_', '');
      if (extractedCompareOperator) {
        const compareValue = compareDependency.replace(/#/g, '').replace(extractedCompareOperator, '').replace('_', '');
        switch (dependencyItem.ItemType) {
          case CheckItemType.Date:
            dependencyFulfilled = compareDates(extractedCompareOperator, value, compareValue);
            break;
          case CheckItemType.Time:
            dependencyFulfilled = compareTimes(extractedCompareOperator, value, compareValue);
            break;
          case CheckItemType.Currency:
            dependencyFulfilled = compareNumbers(extractedCompareOperator, value, compareValue);
            break;
        }
      }
    }
      break;
  }
  return dependencyFulfilled;
};

const compareNumbers = (compareOperator: string, value: string, compareValue: string) => {
  if (!value || !compareValue) {
    return false;
  }
  const numberValue = Number(value);
  const numberCompareValue = Number(compareValue);
  switch (compareOperator) {
    case '<':
      return numberValue < numberCompareValue;
    case '>':
      return numberValue > numberCompareValue;
    case '<=':
      return numberValue <= numberCompareValue;
    case '>=':
      return numberValue >= numberCompareValue;
    case '==':
      return numberValue === numberCompareValue;
  }
  return false;
}

const compareDates = (compareOperator: string, value?: string, compareValue?: string) => {
  if (!value || !compareValue) {
    return false;
  }
  const dateValue = dayjs(value).startOf('day');
  const dateCompareValue = dayjs(compareValue).startOf('day');

  switch (compareOperator) {
    case '<':
      return dateValue.isBefore(dateCompareValue);
    case '>':
      return dateValue.isAfter(dateCompareValue);
    case '<=':
      return dateValue.isSameOrBefore(dateCompareValue);
    case '>=':
      return dateValue.isSameOrAfter(dateCompareValue);
    case '==':
      return dateValue.isSame(dateCompareValue);
  }
  return false;
}

const compareTimes = (compareOperator: string, value?: string, compareValue?: string) => {
  if (!value || !compareValue) {
    return false;
  }
  const valueTimeString = dayjs(value).format('HH:mm');
  switch (compareOperator) {
    case '<':
      return valueTimeString < compareValue;
    case '>':
      return valueTimeString > compareValue;
    case '<=':
      return valueTimeString <= compareValue;
    case '>=':
      return valueTimeString >= compareValue;
    case '==':
      return valueTimeString === compareValue;
  }
  return false;
}
