import { isDevMode } from '@angular/core';

export function logComponent(component) {
  logThis(component, 'PAGE');
}

export function logService(service) {
  logThis(service, 'SERVICE');
}

function logThis(component, name) {
  if (isDevMode()) {
    window[component.constructor.name] = component;
    // console.log(`${name} LOAD`, component);
  }
}

export function log(message, obj = null) {
  if (isDevMode()) {
    if (obj) {
      console.log(message, obj);
    } else {
      console.log(message);
    }
  }
}
