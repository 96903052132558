<div class="modal-container">
  <ui-modal-header [title]="t.externalLinks.title"></ui-modal-header>

  <ion-card style="background-color: rgba(112,175,26,.2);">
    <ion-card-header>
      <ion-card-subtitle>
        <ui-icon [icon]="BlinkIcon.Info"></ui-icon>
        {{ t.importantNotice | translate }}
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content> {{ t.externalLinks.notice | translate }}</ion-card-content>
  </ion-card>

  <ion-grid class="ion-padding">
    <ion-row>
      <ion-col size="1">
        <ui-icon [customIcon]="externalLink.icon" size="lg"></ui-icon>
      </ion-col>
      <ion-col>
        {{ externalLink.name }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col offset="1">
        <span style="font-size: 12px; color: black; display: block"> {{ externalLink.description }} </span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-footer>
    <ion-row class="ion-justify-content-end">
      <ion-col size="auto">
        <ion-button fill="solid"
                    (click)="continue()">
          {{ t.continue | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</div>
