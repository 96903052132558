import { AbstractControl, FormControl } from '@angular/forms';
import { toArray } from './array-util';

// REGEX from Blink Me
// export const EMAIL_REGEXP = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
// REGEX from Blink Check => really used
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(value: string) {
  return EMAIL_REGEXP.test(value);
}

export function validateMailFormat(control: FormControl) {
  if (control.value !== '' && (control.value.length <= 5 || !isValidEmail(control.value))) {
    return {
      mailFormat: {
        valid: false
      }
    };
  }

  return null;
}

export function EmailValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {

  if (control.value !== '' && !EMAIL_REGEXP.test(control.value)) {
    return {
      valid: false
    };
  } else {
    return null;
  }
}

export function PhoneValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const regex = /^(\+?\d{1,4}[\w\s-])?(?!0+\s+,?$)\d{9,11}\s*,?$/;

  if (control.value !== '' && !regex.test(control.value)) {
    return {
      valid: false
    };
  } else {
    return null;
  }
}

export function BlinkIdValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  if (!control.value) {
    return { valid: false }
  }
  const toCheck = control.value.substring(0, control.value.length - 1).replace(/-/gi, '');
  const checkNo = Number(control.value.substring(control.value.length - 1));

  let sum = 0;
  let multiplicator = 3;
  const intArray = toArray(toCheck);

  intArray.reverse();

  intArray.forEach(i => {
    sum += i * multiplicator;
    multiplicator = multiplicator === 3 ? 1 : 3;
  });

  const retval = Number((10 - (sum % 10)) % 10);

  return retval === checkNo ? null : {
    valid: false
  };
}

export const validateBlinkCode = (control: AbstractControl) => {
  const codeRegex = /[a-zA-Z0-9]*-[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/;
  return codeRegex.test(control.value) ? null : {
    code: {
      valid: false
    }
  }
}
