export type PermissionGroupType = 'Global' | 'Company';

export interface CheckPermissionGroup {
  PermissionGroupId: number;
  ChecklistId: number;
  CanUpdateChecklist: boolean;
  CanFillChecklist: boolean;
  CanReadChecklistDataOwnEmployees: boolean;
  CanReadChecklistDataOwnLocations: boolean;
  CanReadChecklistDataAll: boolean;
  Type?: PermissionGroupType;
  Id?: number;

  permissionGroupName?: string;
  hasBlinkCheckLicense?: boolean;
  canEditSystemSettings?: boolean;
}

export const createCheckPermissionGroup =
  (checklistId, permissionGroupId: number, permissionGroupType: PermissionGroupType): CheckPermissionGroup => {
    return {
      PermissionGroupId: permissionGroupId,
      ChecklistId: checklistId,
      CanUpdateChecklist: false,
      CanFillChecklist: false,
      CanReadChecklistDataOwnEmployees: false,
      CanReadChecklistDataOwnLocations: false,
      CanReadChecklistDataAll: false,
      Type: permissionGroupType
    };
  }

