export default {
  config: {
    checklist: {
      checklist: 'Шаблон формы',
      checklists: 'Шаблоны форм',
      none: 'Нет доступных форм.',
      new: 'Новый шаблон формы',
      edit: 'Изменить шаблон формы',
      saved: 'Шаблон формы сохранен.',
      delete: 'Удалить шаблон формы',
      deleteChecklistResults: 'Также удалить все заполненные формы шаблона формы {{name}}?',
      deleteInfo: 'Если вы удалите шаблон формы, все заполненные формы также будут безвозвратно удалены! <br><br>Чтобы сохранить заполненные формы, деактивируйте шаблон формы.',
      deleted: 'Шаблон формы удален.',
      duplicate: 'Дубликат шаблона формы',
      duplicateInfo: 'Вы можете использовать функцию дублирования для дублирования шаблонов форм.',
      duplicateMessage: 'Хотите продублировать шаблон формы «{{checklistName}}» со всем содержимым?',
      duplicated: 'Шаблон формы продублирован.',
      templateName: 'Имя формы',
      activeInfo: 'Заполнять можно только активные шаблоны, неактивные шаблоны можно редактировать только в управлении шаблонами.',
      locationRequired: 'Выбор объекта обязателен',
      locationRequiredInfo: 'Если активировано, необходимо выбрать объект или отсканировать QR-код, чтобы заполнить форму.',
      loginUserRequired: 'Отбор сотрудников обязателен',
      loginUserRequiredInfo: 'Если активировано, необходимо выбрать сотрудника для заполнения формы.',
      allLocationAllowed: 'Разрешить все объекты',
      allowOnlyCertainLocations: 'Разрешить только определенные объекты',
      public: 'публичная форма',
      publicInfo: 'Создайте публичную ссылку, с помощью которой форму сможет заполнить любой пользователь. Никакой логин или приложение не требуется. Вы можете указать дату действия, до которой ссылка должна быть действительна.',
      preview: 'Включить предварительный просмотр',
      previewInfo: 'Если эта опция активирована, кнопка «Предварительный просмотр» всегда будет отображаться внизу формы при ее заполнении. В качестве альтернативы постоянной кнопке предварительного просмотра вы также можете разместить элемент контента «Предварительный просмотр» в любом месте формы (эту функцию не нужно активировать).',
      shortUrlExpireDate: 'Дата истечения срока действия',
      sysadminOnly: 'Только системные администраторы',
      allUsers: 'Все пользователи',
      usersOfPermissionGroup: 'Пользователи этих групп авторизации',
      emailConfig: 'Конфигурация электронной почты',
      emailConfigInfo: 'Если вы введете здесь адреса электронной почты, заполненные формы будут автоматически отправлены на эти адреса электронной почты.',
      emailSaved: 'Адрес электронной почты сохранен.',
      emailRemoved: 'Адрес электронной почты удален',
      importExportInfo: 'Функция экспорта позволяет экспортировать шаблоны форм и связанные шаблоны отчетов для повторного импорта их в другой клиент или систему Blink Check.',
      import: 'Импортировать шаблон формы',
      importInfo: 'Выберите файл .check-b64 шаблона формы, который вы хотите импортировать.',
      imported: 'Форма успешно создана.',
      templatesInAppFolder: 'Шаблоны в каталоге приложения:',
      invalidImport: 'Выбранный файл не является допустимым шаблоном формы.',
      exported: 'Шаблон формы экспортирован.',
      offline: 'Установите подключение к Интернету, чтобы редактировать шаблоны форм.',
      checklistContainsCreateTask: 'Шаблон формы содержит элемент «Создать заявку», поэтому его нельзя сделать общедоступным. Пожалуйста, удалите элемент «Создать заявку».'
    },
    category: {
      category: 'категория',
      categories: 'Категории',
      saved: 'Категория сохранена.',
      editTitle: 'Изменить категорию',
      content: 'элементы',
      newCategory: 'Новая категория',
      name: 'Название категории',
      edit: 'Изменить категорию',
      duplicated: 'Категория дублируется.',
      noCategories: 'Категорий пока нет. Создавайте новые категории с помощью кнопки «Добавить категорию».',
      inactive: 'неактивный',
      showInactive: 'Показать неактивные',
      duplicateCategoryConfirmHeader: 'повторяющаяся категория',
      duplicateCategoryConfirm: 'Вы хотите продублировать категорию «{{categoryName}}» со всем ее содержимым?',
      delete: 'Удалить категорию',
      deleteCategoryConfirm: 'Вы уверены, что хотите удалить категорию "{{categoryName}}"?',
      deleteSuccess: 'Категория удалена.'
    },
    group: {
      group: 'Страница',
      groups: 'страницы',
      created: 'Страница создана.',
      updated: 'Изменения на странице сохранены.',
      updateError: 'Не удалось сохранить страницу. Пожалуйста, повторите попытку позже.',
      rename: 'Переименовать страницу',
      saved: 'Страница сохранена.',
      toggleSort: 'Изменить сортировку',
      editGroupName: 'Изменить название страницы',
      new: 'Новая страница',
      name: 'название страницы',
      duplicated: 'Страница дублирована.',
      noGroups: 'Страниц пока нет. Создавайте новые страницы с помощью кнопки «Добавить страницу».',
      noItems: 'Элементов пока нет. Создавайте новые элементы с помощью кнопки «Добавить элемент».',
      add: 'добавить страницу',
      infoElement: 'информационный элемент',
      inputElement: 'Элемент ввода',
      addInfoElement: 'Добавить информационный элемент',
      addInputElement: 'Добавить элемент ввода',
      delete: 'Удалить страницу',
      deleteGroupConfirm: 'Вы уверены, что хотите удалить страницу "{{groupName}}"?',
      chooseGroup: 'На какую страницу следует добавить элемент?',
      duplicateGroupConfirmHeader: 'Дублировать страницу',
      duplicateGroupConfirm: 'Хотите дублировать страницу «{{groupName}}» со всем содержимым?',
      deleteSuccess: 'Страница удалена.'
    },
    item: {
      add: 'Добавить элемент',
      created: 'Объект создан',
      saved: 'Товар сохранен.',
      chooseItemType: 'Выберите предмет',
      values: 'Ценности',
      value: 'Ценить',
      ratingNumber: 'Оценка',
      addItemValue: 'Добавить новое значение',
      addItemValueSuccess: 'Добавлено новое значение',
      updateItemValueSuccess: 'Значение обновлено',
      removeItemValueSuccess: 'Значение удалено',
      removeItemValue: 'Удалить значение',
      takePictureButtonText: 'Выберите изображение',
      editTitle: 'Редактировать элемент',
      editDependenciesTitle: 'Редактировать зависимости',
      required: 'Обязательное поле',
      name: 'Имя элемента',
      description: 'необязательное описание элемента',
      points: 'Точки',
      duplicated: 'Товар был дублирован.',
      duplicateItemConfirmHeader: 'Дублирующийся элемент',
      duplicateItemConfirm: 'Вы хотите продублировать элемент «{{itemName}}» со всем содержимым?',
      delete: {
        title: 'Удалить пункт',
        message: 'Вы действительно хотите удалить элемент «{{itemName}}»?',
        success: 'Объект был удален'
      },
      moveItem: 'Переместить элемент',
      move: 'Двигаться',
      moveItemInfo: 'Пожалуйста, выберите категорию и страницу, на которую нужно переместить текущий элемент.',
      itemMoved: 'Объект был перемещен'
    },
    editItem: {
      name: 'Фамилия',
      nameInfo: 'Этот текст отображается непосредственно перед элементом ввода.',
      link: 'Адрес ссылки',
      linkInfo: 'Этот адрес будет доступен через кнопку. Пожалуйста, введите корректный адрес.',
      description: 'Описание',
      descriptionInfo: 'Если вы хотите предоставить инструкции по заполнению формы, вы можете ввести их здесь.',
      linkDescription: 'Метка кнопки',
      linkDescriptionInfo: 'Введите имя для кнопки.',
      values: 'Ценности',
      valuesInfo: 'Для каждого значения можно указать рейтинговый номер. Вы можете провести оценку позже, используя рейтинговый номер. Номер рейтинга не является обязательным.',
      value: 'Ценить',
      valuePlaceholder: 'Новое значение',
      ratingNumber: 'Оценка',
      requiredField: 'Обязательное поле',
      requiredFieldInfo: 'Если активировано как обязательное поле, то это поле необходимо заполнить, чтобы форму можно было отправить.',
      externalNo: 'Внешний номер',
      externalNoInfo: 'Дополнительная информация для интерфейсов или оценок через внешние системы.',
      editable: 'Редактируемый',
      editableInfo: 'Чтобы пользователи могли вносить записи, элемент должен быть редактируемым.',
      defaultValue: 'Укажите значение по умолчанию',
      defaultValueInfo: 'Для этого элемента можно указать стандартное назначение.',
      defaultValueInfoEditable: 'Значение может быть изменено пользователем формы.',
      visible: 'видимость',
      visibleInfo: 'Видимость определяет, будет ли элемент виден редактору или нет.',
      externalNoAleradyUsed: 'Этот внешний номер уже используется.',
      taskTypeId: 'Тип билета',
      loginUserId: 'пользователь',
      locationId: 'Имя объекта',
      videoId: 'Номер видео',
      videoType: 'Видеосервис',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Вимео',
      videoInfo: 'Введите здесь уникальный идентификатор видео. Вы можете найти это, например, в URL-адресе видео. <br/>Пример: https://www.youtube.com/watch?v=XXXXX или на Vimeo https://vimeo.com/XXXXX. <br/>В этом случае в поле необходимо будет ввести XXXXX.',
      defaultTaskTitle: 'Название билета по умолчанию',
      defaultTaskDescription: 'Описание билета по умолчанию',
      titleEditable: 'Название билета может редактироваться пользователем',
      descriptionEditable: 'Описание билета редактируется пользователем',
      dueDateEditable: 'Срок оплаты билета редактируется пользователем',
      addReportAttachment: 'Прикрепите заполненную форму к билету в формате PDF.',
      noTaskTypesFound: 'Типы билетов не найдены. Типами билетов можно управлять через Blink Active.',
      noLoginUsersFound: 'Не удалось найти пользователей. Управлять пользователями можно через Blink Manager.',
      noLocationsFound: 'Объекты не найдены. Объектами можно управлять через Blink Manager.',
      createTaskItemInfo: 'С помощью этой функции вы можете создавать активные билеты Blink. Вы можете указать заголовок и описание или попросить пользователя заполнить их. Объект назначается автоматически, а ответственный менеджер объекта назначается обработчиком.',
      duplicatedValuesNotAllowed: 'Повторяющиеся значения не допускаются.',
      valueLengthExceeded: 'Введенное значение слишком длинное и будет сокращено.'
    },
    itemTypes: {
      PreviewButton: 'Предварительный просмотр документа',
      Select: 'Выбор из списка',
      Signature: 'Подпись',
      Email: 'Ввод электронной почты',
      SendEmailTo: 'Доставка по электронной почте',
      Date: 'Дата',
      Time: 'время',
      Boolean: 'Да-нет вопрос',
      TakePicture: 'сделать фотографию',
      TakeMultiPicture: 'делать фотографии',
      Scan: 'Qr/EAN-сканирование',
      Input: 'Ввод текста',
      Textarea: 'Многострочный ввод текста',
      Link: 'Связывание',
      Picture: 'Отображение изображения',
      Video: 'Видеообъявление',
      Text: 'Текстовый дисплей',
      Header: 'Заголовок',
      Rate: 'Оценка',
      RateSmiley: 'Смайлик рейтинг',
      CreateTask: 'Создать билет',
      FileUpload: 'Загрузить файлы',
      Currency: 'валюта'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Просмотрите предварительную версию формы.',
      Select: 'Определите любые элементы списка, из которых можно выбрать один.',
      Signature: 'Поле для цифровой подписи (можно использовать пальцем)',
      Email: 'Пользователь может указать действительный адрес электронной почты.',
      SendEmailTo: 'Пользователь может указать действующий адрес электронной почты, на который будет отправлена заполненная форма в формате PDF.',
      Date: 'Пользователь может указать дату через календарь',
      Time: 'Пользователь может указать время',
      Boolean: 'Пользователь выбирает да или нет',
      TakePicture: 'Пользователь может сделать фотографию с помощью камеры или загрузить изображение.',
      TakeMultiPicture: 'Пользователь может сделать несколько фотографий с помощью камеры или загрузить изображения.',
      Scan: 'Требуйте от пользователей сканирования QR-кода или EAN. Результат отображается в форме и сохраняется.',
      Input: 'Однострочное поле ввода текста',
      Textarea: 'Многострочный ввод текста для более длинных комментариев',
      Link: 'Ссылка на внешний сайт',
      Picture: 'Загрузите изображение, которое будет отображаться при заполнении.',
      Video: 'Загрузите видео, которое будет показываться при заполнении формы.',
      Text: 'Добавляйте форматирование к многострочным текстам.',
      Header: 'Большой заголовок для структурирования контента.',
      Rate: '5-звездочный рейтинг',
      RateSmiley: 'Выбор из 3 смайликов (счастливый, нейтральный, грустный)',
      CreateTask: 'Для пользователя создается билет',
      FileUpload: 'Пользователь может прикрепить к форме любое количество файлов.',
      Currency: 'Пользователь может указать сумму в валюте.'
    },
    template: {
      template: 'Шаблон отчета',
      templates: 'Шаблоны отчетов',
      name: 'Фамилия',
      description: 'Описание',
      customer: 'клиент',
      new: 'Новый шаблон отчета',
      edit: 'Изменить шаблон отчета',
      create: 'Создать шаблон отчета',
      public: 'Общественный',
      active: 'Активный',
      deleteConfirm: 'Вы уверены, что хотите удалить шаблон отчета «{{templateName}}»?',
      deleteSuccess: 'Шаблон отчета удален.',
      editor: 'Дизайнер шаблонов',
      notForMobile: 'Конструктор шаблонов не оптимизирован для мобильных устройств. Для этого используйте Blink Check в браузере.',
      noTemplates: 'Шаблонов отчетов пока нет.',
      custom: 'Индивидуальный шаблон',
      defaultWithPoints: 'Стандартный шаблон – с процентными пунктами',
      default: 'Стандартный шаблон – без процентных пунктов',
      createTemplateInfo: 'Редактировать шаблон отчета можно только после его создания.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Глобальная группа разрешений',
    notAllowed: 'У вас нет разрешения на изменение разрешений этого шаблона.',
    all: 'Форму могут заполнить все пользователи',
    groups: 'Форму могут заполнить только пользователи из данных групп авторизации.',
    noGroupLicence: 'Лицензия Blink Check для этой группы не активирована.',
    overridingPermssion: 'Параметр «Системные настройки» в группе разрешений «Сисадмин» автоматически предоставляет пользователям все разрешения для этой формы.',
    onlyLicensedPermissionGroups: 'Отображаются только группы авторизации, которым назначена лицензия Blink Check.',
    canUpdateChecklist: 'Изменить шаблон формы',
    canFillChecklist: 'заполнять формы',
    canReadChecklistDataOwn: 'Просмотр и оценка самостоятельно заполненных форм',
    canReadChecklistDataOwnEmployees: 'Просмотрите и оцените все заполненные формы вашими сотрудниками',
    canReadChecklistDataOwnLocations: 'Просмотрите и оцените все заполненные формы собственных объектов',
    canReadChecklistDataAll: 'Просмотр и оценка всех заполненных форм'
  },
  companyConfig: {
    companyConfig: 'Конфигурация арендатора',
    logoInfo: 'Появляется в заголовке стандартных шаблонов отчетов.',
    footer: 'нижний колонтитул',
    footerInfo: 'Появляется в нижнем колонтитуле стандартных шаблонов отчетов.',
    offline: 'Пожалуйста, установите подключение к Интернету, чтобы иметь возможность редактировать конфигурацию.'
  },
  switchCompanyDialogHeader: 'Сменить клиентов',
  switchCompanyDialogText: 'Если вы измените клиента, все текущие формы будут сброшены. Вы хотите продолжать?',
  results: {
    results: 'Оценка',
    details: 'Подробности',
    created: 'Созданный',
    result: 'Результат',
    noReportJsonExists: 'Отчет еще не создан. Отчет будет создан с задержкой. Повторите попытку позже.',
    noResults: 'Заполненных форм пока нет.',
    noResultsForDateFilter: 'За выбранный период нет заполненных форм.',
    periodFrom: 'Время',
    periodTo: 'период вверх',
    location: 'объект',
    createUser: 'Создатель',
    loginUser: 'Сотрудники',
    checklist: 'Шаблон формы',
    user: 'пользователь',
    recentResults: 'Заполненные на данный момент формы',
    recent: 'Текущий',
    pointOf: '{{ itemPoints }} из {{ maxItemPoints }} баллов',
    checklistCreatedByAt: 'Форма заполнена {{ firstName }} {{ lastName }} {{ createDate }} в {{ createTime }} вечера.',
    linkedTask: 'Связанный билет: #{{ taskId }} {{ taskTitle }}',
    offline: 'Пожалуйста, установите подключение к Интернету, чтобы иметь возможность оценить заполненные формы.',
    delete: {
      title: 'Удалить заполненную форму',
      message: 'Вы действительно хотите удалить заполненную форму «{{checklistName}}»?',
      success: 'Заполненная форма удалена.'
    },
    editReferenceTimestamp: 'Изменить базовую дату',
    editReferenceTimestampDescription: 'Базовая дата определяет время заполнения формы. Это может отличаться от времени отправки формы.'
  },
  dependencies: {
    dependency: 'Зависимость',
    addTitle: 'Пожалуйста, выберите значение для сравнения',
    addMessage: 'Укажите значение, которое должно быть соблюдено для отображения элемента.',
    addTitleMulti: 'Пожалуйста, выберите сравнительные значения',
    addMessageMulti: 'Укажите значения, одно из которых должно быть соблюдено, чтобы элемент отображался.',
    present: 'Доступный',
    notPresent: 'Не существует',
    noResults: 'Никаких зависимостей нет. Новые зависимости можно добавить с помощью команды «Добавить зависимость».',
    noAvailableItems: 'В форме слишком мало элементов для создания зависимостей. Пожалуйста, сначала добавьте больше элементов.',
    noAvailableItemsInGroup: 'На странице слишком мало элементов для создания зависимостей. Пожалуйста, выберите страницу, содержащую элементы.',
    dependencyAdded: 'Добавлена зависимость.',
    dependencyRemoved: 'Зависимость удалена.',
    dependencyInfo: 'После добавления зависимости этот элемент ({{elementName}}) появится только в том случае, если зависимые элементы были заполнены.',
    true: 'Да',
    false: 'Нет',
    '#not_empty#': 'Доступный',
    '#empty#': 'Не существует',
    frown: 'Грустный',
    meh: 'Идет так',
    smile: 'Счастливый'
  },
  noChecklists: 'Нет доступных форм.',
  updating: 'Blink Check обновляется.',
  installing: 'Установка версии {{version}}...',
  appName: 'Проверка мигания',
  notAvailableInPublicChecklists: ' (Недоступно в шаблонах общедоступных форм)',
  pdfAssignTemplate: 'Прежде чем использовать загрузку PDF-файла, необходимо назначить шаблон отчета форме.'
};
