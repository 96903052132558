import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable } from 'rxjs';
import { UiDialogService } from '@blink/ui';
import { Network } from '@capacitor/network';
import { CapacitorPluginTranslateService } from '../i18n/capacitor-plugin-translate.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public status$: Observable<boolean>;
  private connectedSubject = new BehaviorSubject<boolean>(null);
  private system: string = null;
  private intervalId;
  private responseDateSubject = new BehaviorSubject<string>(null);
  public responseDate$: Observable<string> = this.responseDateSubject.asObservable();

  constructor(private uiDialogService: UiDialogService,
              private t: CapacitorPluginTranslateService,
              private ngZone: NgZone,
              private http: HttpClient) {
    this.status$ = this.connectedSubject.asObservable().pipe(debounceTime(500));

    Network.addListener('networkStatusChange', async status => {
      setTimeout(() => this.getStatus(), 500);
    });

    this.getStatus();

    window['networkService'] = this;
  }

  public get connected(): boolean {
    return this.connectedSubject.value;
  }

  public changeSystem(system) {
    this.system = system;
  }

  private getStatus() {
    Network.getStatus().then(status => {
      this.ngZone.run(async () => {
        if (this.connected !== status.connected) {
          this.connectedSubject.next(status.connected);
          if (status.connected) {
            await this.generate204();
          }
        }
      });
    });
  }

  private async generate204() {
    setTimeout(async () => {
      if (this.connected) {
        const refreshTokenOptions: any = {
          responseType: 'text' as const,
          headers: new HttpHeaders({
            'Content-Type': this.system ? 'application/json' : 'text/html',
            'Accept': 'text/html; *; charset=utf-8',
            'Access-Control-Allow-Origin': '*'
          }),
          observe: 'response'
        };

        const generateUrl = this.system ? `https://${this.system}-api.blink.online/api/v1/generate_204` : 'https://onlinestatus.blink.online/online.html';
        return this.http.get(generateUrl, refreshTokenOptions).toPromise().then(async (response) => {
          const connectResult = (this.system && response['status'] === 204) || (!this.system && response['status'] === 200);

          if (connectResult) {
            clearInterval(this.intervalId);
          } else {
            await this.startInterval();
          }

          if (this.connected !== connectResult) {
            await this.updateNetworkStatus(connectResult);
          }

          this.responseDateSubject.next(response['headers'].get('date'));
          return response;
        }).catch((e) => {
          console.error(e);
          this.updateNetworkStatus(false);
          this.startInterval();
        });
      }
    }, 5000);
  }

  private async updateNetworkStatus(connected: boolean) {
    this.connectedSubject.next(connected);
    if (this.connected !== connected) {
      if (connected) {
        await this.uiDialogService.toast(this.t.network.changed.connected);
      } else {
        await this.uiDialogService.toast(this.t.network.changed.disconnected);
      }
    }
  }

  private async startInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => this.generate204(), 15000);
  }
}
