import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { devTools } from '@ngneat/elf-devtools';
import { enableElfProdMode } from "@ngneat/elf";

if (environment.production) {
  enableProdMode();
  enableElfProdMode()
} else {
  devTools({ name: 'Blink Check Elf-Storage' });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
