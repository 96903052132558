export default {
  config: {
    checklist: {
      checklist: 'Form şablonu',
      checklists: 'Form şablonları',
      none: 'Form mevcut değil.',
      new: 'Yeni form şablonu',
      edit: 'Form şablonunu düzenle',
      saved: 'Form şablonu kaydedildi.',
      delete: 'Form şablonunu sil',
      deleteChecklistResults: '{{name}} form şablonunun tamamlanmış tüm formları da silinsin mi?',
      deleteInfo: 'Form şablonunu silerseniz, doldurulmuş tüm formlar da geri alınamayacak şekilde silinecektir! <br><br>Tamamlanan formları saklamak için form şablonunu devre dışı bırakın.',
      deleted: 'Form şablonu silindi.',
      duplicate: 'Yinelenen form şablonu',
      duplicateInfo: 'Form şablonlarını çoğaltmak için çoğaltma işlevini kullanabilirsiniz',
      duplicateMessage: '"{{checklistName}}" form şablonunu tüm içerikle birlikte kopyalamak istiyor musunuz?',
      duplicated: 'Form şablonu kopyalandı.',
      templateName: 'Form adı',
      activeInfo: 'Yalnızca etkin şablonlar doldurulabilir; etkin olmayan şablonlar yalnızca şablon yönetiminde düzenlenebilir.',
      locationRequired: 'Nesne seçimi zorunludur',
      locationRequiredInfo: 'Etkinleştirilirse, formu doldurmak için bir nesnenin seçilmesi veya QR kodunun taranması gerekir.',
      loginUserRequired: 'Çalışan seçimi zorunludur',
      loginUserRequiredInfo: 'Etkinleştirilirse formu dolduracak bir çalışanın seçilmesi gerekir.',
      allLocationAllowed: 'Tüm nesnelere izin ver',
      allowOnlyCertainLocations: 'Yalnızca belirli nesnelere izin ver',
      public: 'Herkese açık form',
      publicInfo: 'Formun herhangi bir kullanıcı tarafından doldurulabileceği genel bir bağlantı oluşturun. Giriş veya uygulama gerekmez. Bağlantının geçerli olması gereken zamana kadar bir geçerlilik tarihi belirleyebilirsiniz.',
      preview: 'Önizlemeyi etkinleştir',
      previewInfo: 'Etkinleştirilirse, formu doldururken her zaman alt kısmında bir "Önizleme" düğmesi görüntülenecektir. Kalıcı önizleme düğmesine alternatif olarak "Önizleme" içerik öğesini formun herhangi bir yerine de yerleştirebilirsiniz (bu işlevin etkinleştirilmesine gerek yoktur).',
      shortUrlExpireDate: 'Son kullanma tarihi',
      sysadminOnly: 'Yalnızca sistem yöneticileri',
      allUsers: 'Tüm kullanıcılar',
      usersOfPermissionGroup: 'Bu yetki gruplarının kullanıcıları',
      emailConfig: 'E-posta yapılandırması',
      emailConfigInfo: 'Buraya e-posta adreslerini girerseniz, doldurulan formlar otomatik olarak bu e-posta adreslerine gönderilecektir.',
      emailSaved: 'E-posta adresi kaydedildi',
      emailRemoved: 'E-posta adresi silindi',
      importExportInfo: 'Dışa aktarma işlevi, form şablonlarını ve ilgili rapor şablonlarını başka bir Göz Kırpma Denetimi istemcisine veya sistemine yeniden aktarmak üzere dışa aktarmanıza olanak tanır.',
      import: 'Form şablonunu içe aktar',
      importInfo: 'Lütfen içe aktarmak istediğiniz form şablonunun .check-b64 dosyasını seçin.',
      imported: 'Form başarıyla oluşturuldu.',
      templatesInAppFolder: 'Uygulama dizinindeki şablonlar:',
      invalidImport: 'Seçilen dosya geçerli bir form şablonu değil.',
      exported: 'Form şablonu dışa aktarıldı',
      offline: 'Form şablonlarını düzenlemek için lütfen internet bağlantısı kurun.',
      checklistContainsCreateTask: "Form şablonu 'Bilet oluştur' öğesini içeriyor ve bu nedenle genel olarak ayarlanamaz. Lütfen 'Bilet oluştur' öğesini kaldırın."
    },
    category: {
      category: 'kategori',
      categories: 'Kategoriler',
      saved: 'Kategori kaydedildi.',
      editTitle: 'Kategoriyi düzenle',
      content: 'elementler',
      newCategory: 'Yeni kategori',
      name: 'Kategori adı',
      edit: 'Kategoriyi düzenle',
      duplicated: 'Kategori kopyalandı.',
      noCategories: 'Henüz kategori yok. “Kategori ekle” butonunu kullanarak yeni kategoriler oluşturun.',
      inactive: 'aktif değil',
      showInactive: 'Aktif olmayanları göster',
      duplicateCategoryConfirmHeader: 'Yinelenen kategori',
      duplicateCategoryConfirm: '"{{categoryName}}" kategorisini tüm içeriğiyle birlikte kopyalamak istiyor musunuz?',
      delete: 'Kategoriyi sil',
      deleteCategoryConfirm: '"{{categoryName}}" kategorisini silmek istediğinizden emin misiniz?',
      deleteSuccess: 'Kategori kaldırıldı.'
    },
    group: {
      group: 'Sayfa',
      groups: 'sayfalar',
      created: 'Sayfa oluşturuldu.',
      updated: 'Sayfadaki değişiklikler kaydedildi.',
      updateError: 'Sayfa kaydedilemedi. Lütfen daha sonra tekrar deneyiniz.',
      rename: 'Sayfayı yeniden adlandır',
      saved: 'Sayfa kaydedildi.',
      toggleSort: 'Sıralamayı değiştir',
      editGroupName: 'Sayfa adını düzenle',
      new: 'Yeni sayfa',
      name: 'Sayfanın adı',
      duplicated: 'Sayfa kopyalandı.',
      noGroups: 'Henüz sayfa yok. “Sayfa Ekle” butonunu kullanarak yeni sayfalar oluşturun.',
      noItems: 'Henüz hiçbir unsur yok. “Öğe ekle” düğmesini kullanarak yeni öğeler oluşturun.',
      add: 'Sayfa ekle',
      infoElement: 'Bilgi öğesi',
      inputElement: 'Giriş öğesi',
      addInfoElement: 'Bilgi öğesi ekle',
      addInputElement: 'Giriş öğesi ekle',
      delete: 'Sayfayı sil',
      deleteGroupConfirm: '"{{groupName}}" sayfasını silmek istediğinizden emin misiniz?',
      chooseGroup: 'Öğe hangi sayfaya eklenmelidir?',
      duplicateGroupConfirmHeader: 'yinelenen sayfa',
      duplicateGroupConfirm: '"{{groupName}}" sayfasını tüm içerikle birlikte kopyalamak istiyor musunuz?',
      deleteSuccess: 'sayfa kaldırıldı.'
    },
    item: {
      add: 'öğe eklemek',
      created: 'Öğe oluşturuldu',
      saved: 'Öğe kaydedildi.',
      chooseItemType: 'Öğeyi seçin',
      values: 'Değerler',
      value: 'Değer',
      ratingNumber: 'Değerlendirme',
      addItemValue: 'Yeni değer ekleyin',
      addItemValueSuccess: 'Yeni değer eklendi',
      updateItemValueSuccess: 'Değer güncellendi',
      removeItemValueSuccess: 'Değer kaldırıldı',
      removeItemValue: 'Değeri kaldır',
      takePictureButtonText: 'Fotoğraf seç',
      editTitle: 'ögeyi düzenle',
      editDependenciesTitle: 'Bağımlılıkları düzenle',
      required: 'Zorunlu alan',
      name: 'Öğenin adı',
      description: 'öğenin isteğe bağlı açıklaması',
      points: 'Puanlar',
      duplicated: 'Öğe kopyalandı.',
      duplicateItemConfirmHeader: 'Yinelenen öğe',
      duplicateItemConfirm: '"{{itemName}}" öğesini tüm içeriğiyle birlikte kopyalamak istiyor musunuz?',
      delete: {
        title: 'Öğeyi silmek',
        message: '“{{itemName}}” öğesini gerçekten silmek istiyor musunuz?',
        success: 'Öğe kaldırıldı'
      },
      moveItem: 'Öğeyi taşı',
      move: 'Taşınmak',
      moveItemInfo: 'Lütfen mevcut öğenin taşınması gereken kategoriyi ve sayfayı seçin.',
      itemMoved: 'Öğe taşındı'
    },
    editItem: {
      name: 'Soyadı',
      nameInfo: 'Bu metin doğrudan giriş öğesinin önünde görüntülenir.',
      link: 'Bağlantı adresi',
      linkInfo: 'Bu adrese bir buton aracılığıyla erişilebilecek. Lütfen geçerli bir adres girin.',
      description: 'Tanım',
      descriptionInfo: 'Formun doldurulmasına ilişkin talimatlar vermek isterseniz buraya girebilirsiniz.',
      linkDescription: 'Düğme etiketi',
      linkDescriptionInfo: 'Düğme için bir ad girin.',
      values: 'Değerler',
      valuesInfo: 'Her değer için bir derecelendirme numarası belirleyebilirsiniz. Derecelendirme numarasını kullanarak değerlendirmeleri daha sonra gerçekleştirebilirsiniz. Derecelendirme numarası isteğe bağlıdır.',
      value: 'Değer',
      valuePlaceholder: 'Yeni değer',
      ratingNumber: 'Değerlendirme',
      requiredField: 'Zorunlu alan',
      requiredFieldInfo: 'Zorunlu alan olarak etkinleştirildiyse formun gönderilebilmesi için bu alanın doldurulması gerekir.',
      externalNo: 'Harici numara',
      externalNoInfo: 'Harici sistemler aracılığıyla arayüzler veya değerlendirmeler için isteğe bağlı bilgiler.',
      editable: 'Düzenlenebilir',
      editableInfo: 'Kullanıcıların giriş yapabilmesi için öğenin düzenlenebilir olması gerekir.',
      defaultValue: 'Varsayılan değeri belirtin',
      defaultValueInfo: 'Bu öğe için standart bir atama belirleyebilirsiniz.',
      defaultValueInfoEditable: 'Değer formun kullanıcısı tarafından değiştirilebilir.',
      visible: 'görünürlük',
      visibleInfo: 'Görünürlük, öğenin editör tarafından görülüp görülmeyeceğini kontrol eder.',
      externalNoAleradyUsed: 'Bu harici numara zaten kullanılıyor.',
      taskTypeId: 'Bilet tipi',
      loginUserId: 'kullanıcı',
      locationId: 'Nesne adı',
      videoId: 'Video numarası',
      videoType: 'Video hizmeti',
      videoTypes_youtube: 'Youtube',
      videoType_vimeo: 'Vimeo',
      videoInfo: "Benzersiz video kimliğini buraya girin. Bunu örneğin videonun URL'sinde bulabilirsiniz. <br/>Örnek: https://www.youtube.com/watch?v=XXXXX veya Vimeo'da https://vimeo.com/XXXXX. <br/>Bu durumda alana XXXXX girilmesi gerekir.",
      defaultTaskTitle: 'Biletin varsayılan başlığı',
      defaultTaskDescription: 'Biletin varsayılan açıklaması',
      titleEditable: 'Kullanıcı tarafından düzenlenebilir bilet başlığı',
      descriptionEditable: 'Kullanıcı tarafından düzenlenebilir bilet açıklaması',
      dueDateEditable: 'Kullanıcı tarafından düzenlenebilir bilet son tarihi',
      addReportAttachment: 'Doldurulmuş formu bilete PDF olarak ekleyin',
      noTaskTypesFound: 'Hiçbir bilet türü bulunamadı. Bilet türleri Blink Active üzerinden yönetilebilir.',
      noLoginUsersFound: 'Hiçbir kullanıcı bulunamadı. Kullanıcılar Blink Manager aracılığıyla yönetilebilir.',
      noLocationsFound: 'Hiçbir nesne bulunamadı. Nesneler Blink Manager aracılığıyla yönetilebilir.',
      createTaskItemInfo: 'Bu fonksiyonla Blink Aktif Biletler oluşturabilirsiniz. Başlığı ve açıklamayı belirtebilir veya kullanıcının doldurmasını sağlayabilirsiniz. Nesne otomatik olarak atanır ve sorumlu nesne yöneticisi editör olarak atanır.',
      duplicatedValuesNotAllowed: 'Yinelenen değerlere izin verilmez.',
      valueLengthExceeded: 'Girilen değer çok uzun ve kısaltılacak.'
    },
    itemTypes: {
      PreviewButton: 'Belge önizlemesi',
      Select: 'Listeden seçim',
      Signature: 'İmza',
      Email: 'E-posta girişi',
      SendEmailTo: 'E-posta teslimi',
      Date: 'Tarih',
      Time: 'zaman',
      Boolean: 'Evet soru yok',
      TakePicture: 'fotoğraf çek',
      TakeMultiPicture: 'fotoğraf çek',
      Scan: 'Qr/EAN taraması',
      Input: 'Metin girişi',
      Textarea: 'Çok satırlı metin girişi',
      Link: 'Bağlama',
      Picture: 'Görüntü ekranı',
      Video: 'Video reklam',
      Text: 'Metin görüntüleme',
      Header: 'başlık',
      Rate: 'Değerlendirme',
      RateSmiley: 'Gülen yüz derecelendirmesi',
      CreateTask: 'Bilet oluştur',
      FileUpload: 'Dosyaları yükle',
      Currency: 'para birimi'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Formun önizleme sürümünü görüntüleyin.',
      Select: 'Aralarından seçilebilecek herhangi bir liste öğesini tanımlayın.',
      Signature: 'Dijital imza alanı (parmakla kullanılabilir)',
      Email: 'Kullanıcı geçerli bir e-posta adresi sağlayabilir.',
      SendEmailTo: 'Kullanıcı, doldurulan formun PDF olarak gönderileceği geçerli bir e-posta adresi sağlayabilir.',
      Date: 'Kullanıcı bir takvim aracılığıyla bir tarih belirleyebilir',
      Time: 'Kullanıcı bir zaman belirleyebilir',
      Boolean: 'Kullanıcı evet veya hayır seçeneğini seçer',
      TakePicture: 'Kullanıcı kamerayla fotoğraf çekebilir veya bir resim yükleyebilir.',
      TakeMultiPicture: 'Kullanıcı kamerayla birden fazla fotoğraf çekebilir veya görüntü yükleyebilir.',
      Scan: 'Kullanıcıların bir QR kodu veya EAN taramasını zorunlu kılın. Sonuç formda görüntülenir ve kaydedilir.',
      Input: 'Tek satırlı metin giriş alanı',
      Textarea: 'Daha uzun yorumlar için çok satırlı metin girişi',
      Link: 'Harici siteye bağlantı',
      Picture: 'Doldururken görüntülenecek bir resim yükleyin.',
      Video: 'Formu doldururken gösterilecek bir video yükleyin.',
      Text: 'Çok satırlı metinlere biçimlendirme ekleyin.',
      Header: 'İçeriği yapılandırmak için büyük başlık',
      Rate: '5 yıldızlı derecelendirme',
      RateSmiley: '3 gülen yüz seçeneği (mutlu, nötr, üzgün)',
      CreateTask: 'Bir kullanıcı için bir bilet oluşturulur',
      FileUpload: 'Kullanıcı forma istediği sayıda dosya ekleyebilir.',
      Currency: 'Kullanıcı para birimi cinsinden bir tutar belirleyebilir.'
    },
    template: {
      template: 'Rapor şablonu',
      templates: 'Rapor şablonları',
      name: 'Soyadı',
      description: 'Tanım',
      customer: 'müşteri',
      new: 'Yeni rapor şablonu',
      edit: 'Rapor şablonunu düzenle',
      create: 'Rapor şablonu oluştur',
      public: 'Halk',
      active: 'Aktif',
      deleteConfirm: "'{{templateName}}' rapor şablonunu silmek istediğinizden emin misiniz?",
      deleteSuccess: 'Rapor şablonu kaldırıldı',
      editor: 'Şablon Tasarımcısı',
      notForMobile: "Şablon tasarımcısı mobil cihazlar için optimize edilmemiştir. Bunun için lütfen tarayıcıda Blink Check'i kullanın.",
      noTemplates: 'Henüz rapor şablonu yok.',
      custom: 'Bireysel şablon',
      defaultWithPoints: 'Standart şablon - yüzde puanlı',
      default: 'Standart şablon - yüzde puanı olmadan',
      createTemplateInfo: 'Rapor şablonunu yalnızca oluşturulduktan sonra düzenleyebilirsiniz.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Küresel izin grubu',
    notAllowed: 'Bu şablonun izinlerini değiştirme izniniz yok.',
    all: 'Formu tüm kullanıcılar doldurabilir',
    groups: 'Form yalnızca bu yetki gruplarındaki kullanıcılar tarafından doldurulabilir.',
    noGroupLicence: 'Bu grup için Göz Kırpma Kontrolü lisansı etkinleştirilmedi.',
    overridingPermssion: '"Sysadmin" izin grubundaki "Sistem Ayarları" seçeneği, kullanıcılara bu forma ilişkin tüm izinleri otomatik olarak verir.',
    onlyLicensedPermissionGroups: 'Yalnızca Göz Kırpma Denetimi lisansının atandığı yetkilendirme grupları görüntülenir.',
    canUpdateChecklist: 'Form şablonunu düzenle',
    canFillChecklist: 'formu doldur',
    canReadChecklistDataOwn: 'Kendi kendine doldurulan formları görüntüleyin ve değerlendirin',
    canReadChecklistDataOwnEmployees: 'Çalışanlarınızın doldurduğu tüm formları görüntüleyin ve değerlendirin',
    canReadChecklistDataOwnLocations: 'Kendi nesnelerinizin tamamlanmış tüm formlarını görüntüleyin ve değerlendirin',
    canReadChecklistDataAll: 'Tamamlanan tüm formları görüntüleyin ve değerlendirin'
  },
  companyConfig: {
    companyConfig: 'Kiracı yapılandırması',
    logoInfo: 'Standart rapor şablonlarında başlıkta görünür.',
    footer: 'altbilgi',
    footerInfo: 'Standart rapor şablonlarında alt bilgide görünür.',
    offline: 'Yapılandırmayı düzenleyebilmek için lütfen bir internet bağlantısı kurun.'
  },
  switchCompanyDialogHeader: 'istemcileri değiştir',
  switchCompanyDialogText: 'İstemciyi değiştirirseniz, devam eden tüm formlar sıfırlanacaktır. Devam etmek istiyor musun?',
  results: {
    results: 'Değerlendirme',
    details: 'Detaylar',
    created: 'Oluşturuldu',
    result: 'Sonuç',
    noReportJsonExists: 'Rapor henüz oluşturulmadı. Rapor gecikmeli olarak oluşturulacak, lütfen daha sonra tekrar deneyin.',
    noResults: 'Henüz doldurulmuş form yok.',
    noResultsForDateFilter: 'Seçilen dönem için doldurulmuş form yok.',
    periodFrom: 'Dönemi',
    periodTo: 'dönem doldu',
    location: 'nesne',
    createUser: 'yaratıcı',
    loginUser: 'Çalışanlar',
    checklist: 'form şablonu',
    user: 'kullanıcı',
    recentResults: 'Şu anda doldurulmuş formlar',
    recent: 'Akım',
    pointOf: '{{ maxItemPoints }} puan üzerinden {{ itemPoints }}',
    checklistCreatedByAt: "Form {{ firstName }} {{ lastName }} tarafından {{ createDate }} tarihinde öğleden sonra {{ createTime }}'da dolduruldu.",
    linkedTask: 'Bağlantılı bilet: #{{ taskId }} {{ taskTitle }}',
    offline: 'Doldurduğunuz formları değerlendirebilmek için lütfen internet bağlantınızı kurunuz.',
    delete: {
      title: 'Tamamlanan formu sil',
      message: 'Doldurulmuş “{{checklistName}}” formunu gerçekten silmek istiyor musunuz?',
      success: 'Doldurulmuş form kaldırıldı'
    },
    editReferenceTimestamp: 'Referans tarihini düzenle',
    editReferenceTimestampDescription: 'Referans tarihi formun tamamlandığı zamanı belirler. Bu, formun gönderildiği zamandan farklı olabilir.'
  },
  dependencies: {
    dependency: 'Bağımlılık',
    addTitle: 'Lütfen karşılaştırma değerini seçin',
    addMessage: 'Lütfen öğenin görüntülenmesi için karşılanması gereken değeri belirtin.',
    addTitleMulti: 'Lütfen karşılaştırma değerlerini seçin',
    addMessageMulti: 'Lütfen öğenin görüntülenmesi için bunlardan birinin karşılanması gereken değerleri sağlayın.',
    present: 'Mevcut',
    notPresent: 'Kullanım dışı',
    noResults: 'Hiçbir bağımlılık mevcut değil. Bağımlılık Ekle yoluyla yeni bağımlılıklar eklenebilir.',
    noAvailableItems: 'Formda bağımlılık oluşturamayacak kadar az öğe var. Lütfen önce daha fazla öğe ekleyin.',
    noAvailableItemsInGroup: 'Sayfada bağımlılık oluşturamayacak kadar az öğe var. Lütfen öğeler içeren bir sayfa seçin.',
    dependencyAdded: 'Bağımlılık eklendi.',
    dependencyRemoved: 'Bağımlılık kaldırıldı.',
    dependencyInfo: 'Bir bağımlılık eklediğinizde, bu öğe ({{elementName}}) yalnızca bağımlı öğeler doldurulmuşsa görünecektir.',
    true: 'Evet',
    false: 'HAYIR',
    '#not_empty#': 'Mevcut',
    '#empty#': 'Kullanım dışı',
    frown: 'Üzgün',
    meh: 'her şey yolunda gidiyor',
    smile: 'Mutlu'
  },
  noChecklists: 'Form mevcut değil.',
  updating: 'Göz Kırpma Kontrolü güncelleniyor.',
  installing: '{{version}} sürümü yükleniyor...',
  appName: 'Yanıp sönme kontrolü',
  notAvailableInPublicChecklists: ' (Genel form şablonlarında mevcut değildir)',
  pdfAssignTemplate: 'PDF indirmeyi kullanmadan önce forma bir rapor şablonu atamanız gerekir.'
};
