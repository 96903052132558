export default {
  config: {
    checklist: {
      checklist: 'plantilla de formulario',
      checklists: 'plantillas de formulario',
      none: 'No hay formularios disponibles.',
      new: 'Nueva plantilla de formulario',
      edit: 'Editar plantilla de formulario',
      saved: 'Se ha guardado la plantilla de formulario.',
      delete: 'Eliminar plantilla de formulario',
      deleteChecklistResults: '¿Eliminar también todos los formularios completados de la plantilla de formulario {{name}}?',
      deleteInfo: 'Si elimina la plantilla de formulario, ¡todos los formularios completados también se eliminarán irrevocablemente! <br><br>Para conservar los formularios completados, desactive la plantilla de formulario.',
      deleted: 'Se eliminó la plantilla de formulario.',
      duplicate: 'Plantilla de formulario duplicado',
      duplicateInfo: 'Puede utilizar la función duplicar para duplicar plantillas de formulario',
      duplicateMessage: '¿Quieres duplicar la plantilla de formulario "{{checklistName}}" con todo el contenido?',
      duplicated: 'La plantilla del formulario estaba duplicada.',
      templateName: 'Nombre del formulario',
      activeInfo: 'Solo se pueden completar las plantillas activas; las plantillas inactivas solo se pueden editar en la administración de plantillas.',
      locationRequired: 'Selección de objeto obligatoria',
      locationRequiredInfo: 'Si está activado, se debe seleccionar un objeto o escanear el código QR para completar el formulario.',
      loginUserRequired: 'La selección de empleados es obligatoria.',
      loginUserRequiredInfo: 'Si se activa, se debe seleccionar un empleado para completar el formulario.',
      allLocationAllowed: 'Permitir todos los objetos',
      allowOnlyCertainLocations: 'Permitir solo ciertos objetos',
      public: 'Forma pública',
      publicInfo: 'Crear un enlace público con el que cualquier usuario pueda rellenar el formulario. No es necesario iniciar sesión ni aplicar ninguna aplicación. Puede especificar una fecha de validez hasta la cual el enlace debería ser válido.',
      preview: 'Habilitar vista previa',
      previewInfo: 'Si está activado, siempre se mostrará un botón de "Vista previa" en la parte inferior del formulario al completarlo. Como alternativa al botón de vista previa permanente, también puede colocar el elemento de contenido "Vista previa" en cualquier lugar del formulario (no es necesario activar esta función).',
      shortUrlExpireDate: 'Fecha de caducidad',
      sysadminOnly: 'Solo administradores del sistema',
      allUsers: 'Todos los usuarios',
      usersOfPermissionGroup: 'Usuarios de estos grupos de autorización.',
      emailConfig: 'Configuración de correo electrónico',
      emailConfigInfo: 'Si ingresa direcciones de correo electrónico aquí, los formularios completos se enviarán automáticamente a estas direcciones de correo electrónico.',
      emailSaved: 'La dirección de correo electrónico ha sido guardada.',
      emailRemoved: 'La dirección de correo electrónico ha sido eliminada.',
      importExportInfo: 'La función de exportación le permite exportar plantillas de formularios y plantillas de informes asociadas para volver a importarlas a otro cliente o sistema de Blink Check.',
      import: 'Importar plantilla de formulario',
      importInfo: 'Seleccione el archivo .check-b64 de la plantilla de formulario que desea importar.',
      imported: 'El formulario se creó correctamente.',
      templatesInAppFolder: 'Plantillas en el directorio de la aplicación:',
      invalidImport: 'El archivo seleccionado no es una plantilla de formulario válida.',
      exported: 'La plantilla de formulario ha sido exportada',
      offline: 'Establezca una conexión a Internet para editar plantillas de formulario.',
      checklistContainsCreateTask: 'La plantilla de formulario contiene el elemento "Crear ticket" y, por lo tanto, no se puede configurar como pública. Elimine el elemento "Crear ticket".'
    },
    category: {
      category: 'categoría',
      categories: 'Categorías',
      saved: 'La categoría ha sido guardada.',
      editTitle: 'Editar categoria',
      content: 'elementos',
      newCategory: 'Nueva categoría',
      name: 'Nombre de la categoría',
      edit: 'Editar categoria',
      duplicated: 'La categoría fue duplicada.',
      noCategories: 'Aún no hay categorías. Cree nuevas categorías usando el botón "Agregar categoría".',
      inactive: 'inactivo',
      showInactive: 'Mostrar los inactivos',
      duplicateCategoryConfirmHeader: 'categoría duplicada',
      duplicateCategoryConfirm: '¿Quieres duplicar la categoría "{{categoryName}}" con todo su contenido?',
      delete: 'Eliminar categoría',
      deleteCategoryConfirm: '¿Está seguro de que desea eliminar la categoría "{{categoryName}}"?',
      deleteSuccess: 'La categoría ha sido eliminada.'
    },
    group: {
      group: 'Página',
      groups: 'paginas',
      created: 'La página ha sido creada.',
      updated: 'Los cambios en la página se han guardado.',
      updateError: 'No se pudo guardar la página. Por favor, inténtelo de nuevo más tarde.',
      rename: 'Cambiar nombre de página',
      saved: 'La página ha sido guardada.',
      toggleSort: 'Cambiar clasificación',
      editGroupName: 'Editar nombre de página',
      new: 'nueva pagina',
      name: 'Nombre de la pagina',
      duplicated: 'La página fue duplicada.',
      noGroups: 'Aún no hay páginas. Cree nuevas páginas usando el botón "Agregar página".',
      noItems: 'Aún no hay elementos. Cree nuevos elementos usando el botón "Agregar elemento".',
      add: 'Añadir página',
      infoElement: 'Elemento de información',
      inputElement: 'Elemento de entrada',
      addInfoElement: 'Agregar elemento de información',
      addInputElement: 'Agregar elemento de entrada',
      delete: 'Eliminar página',
      deleteGroupConfirm: '¿Está seguro de que desea eliminar la página "{{groupName}}"?',
      chooseGroup: '¿En qué página se debe agregar el elemento?',
      duplicateGroupConfirmHeader: 'Página duplicada',
      duplicateGroupConfirm: '¿Quieres duplicar la página "{{groupName}}" con todo el contenido?',
      deleteSuccess: 'La página ha sido eliminada.'
    },
    item: {
      add: 'Agregar elemento',
      created: 'El artículo fue creado',
      saved: 'El artículo ha sido guardado.',
      chooseItemType: 'Seleccione un artículo',
      values: 'Valores',
      value: 'Valor',
      ratingNumber: 'Evaluación',
      addItemValue: 'Agregar nuevo valor',
      addItemValueSuccess: 'Se agregó nuevo valor',
      updateItemValueSuccess: 'El valor ha sido actualizado.',
      removeItemValueSuccess: 'El valor ha sido eliminado.',
      removeItemValue: 'Quitar valor',
      takePictureButtonText: 'Seleccionar imagen',
      editTitle: 'Editar elemento',
      editDependenciesTitle: 'Editar dependencias',
      required: 'Campo obligatorio',
      name: 'Nombre del elemento',
      description: 'descripción opcional del elemento',
      points: 'Puntos',
      duplicated: 'El artículo fue duplicado.',
      duplicateItemConfirmHeader: 'Elemento duplicado',
      duplicateItemConfirm: '¿Quieres duplicar el elemento "{{itemName}}" con todo el contenido?',
      delete: {
        title: 'Eliminar elemento',
        message: '¿Realmente desea eliminar el elemento "{{itemName}}"?',
        success: 'El artículo ha sido eliminado.'
      },
      moveItem: 'Mover elemento',
      move: 'Mover',
      moveItemInfo: 'Seleccione la categoría y la página a la que desea mover el elemento actual.',
      itemMoved: 'El artículo ha sido movido'
    },
    editItem: {
      name: 'Apellido',
      nameInfo: 'Este texto se muestra directamente delante del elemento de entrada.',
      link: 'Dirección del enlace',
      linkInfo: 'Esta dirección será accesible mediante un botón. Por favor introduzca un URL válido.',
      description: 'Descripción',
      descriptionInfo: 'Si desea proporcionar instrucciones para completar el formulario, puede ingresarlas aquí.',
      linkDescription: 'Etiqueta del botón',
      linkDescriptionInfo: 'Introduzca un nombre para el botón.',
      values: 'Valores',
      valuesInfo: 'Puede especificar un número de calificación para cada valor. Podrás realizar evaluaciones posteriormente utilizando el número de calificación. El número de calificación es opcional.',
      value: 'Valor',
      valuePlaceholder: 'Nuevo valor',
      ratingNumber: 'Evaluación',
      requiredField: 'Campo obligatorio',
      requiredFieldInfo: 'Si se activa como campo obligatorio, este campo debe completarse para que se pueda enviar el formulario.',
      externalNo: 'Número externo',
      externalNoInfo: 'Información opcional para interfaces o evaluaciones a través de sistemas externos.',
      editable: 'Editable',
      editableInfo: 'Para que los usuarios puedan realizar entradas, el elemento debe ser editable.',
      defaultValue: 'Especificar valor predeterminado',
      defaultValueInfo: 'Puede especificar una asignación estándar para este elemento.',
      defaultValueInfoEditable: 'El valor puede ser cambiado por el usuario del formulario.',
      visible: 'visibilidad',
      visibleInfo: 'La visibilidad controla si el editor puede ver el elemento o no.',
      externalNoAleradyUsed: 'Este número externo ya está en uso.',
      taskTypeId: 'Tipo de billete',
      loginUserId: 'usuario',
      locationId: 'Nombre del objeto',
      videoId: 'Número de vídeo',
      videoType: 'Servicio de vídeo',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Ingrese la identificación única del video aquí. Puedes encontrar esto, por ejemplo, en la URL del vídeo. <br/>Ejemplo: https://www.youtube.com/watch?v=XXXXX o en Vimeo https://vimeo.com/XXXXX. <br/>En este caso, habría que introducir XXXXX en el campo.',
      defaultTaskTitle: 'Título predeterminado del billete',
      defaultTaskDescription: 'Descripción predeterminada del ticket',
      titleEditable: 'Título del ticket editable por el usuario',
      descriptionEditable: 'Descripción del ticket editable por el usuario',
      dueDateEditable: 'Fecha de vencimiento del ticket editable por el usuario',
      addReportAttachment: 'Adjunte el formulario completo al billete en formato PDF',
      noTaskTypesFound: 'No se pudieron encontrar tipos de boletos. Los tipos de boletos se pueden administrar a través de Blink Active.',
      noLoginUsersFound: 'No se pudieron encontrar usuarios. Los usuarios se pueden administrar a través de Blink Manager.',
      noLocationsFound: 'No se pudieron encontrar objetos. Los objetos se pueden gestionar a través del Blink Manager.',
      createTaskItemInfo: 'Con esta función puedes crear Blink Active Tickets. Puede especificar el título y la descripción o hacer que el usuario lo complete. El objeto se asigna automáticamente y el administrador de objetos responsable se asigna como editor.',
      duplicatedValuesNotAllowed: 'No se permiten valores duplicados.',
      valueLengthExceeded: 'El valor ingresado es demasiado largo y será acortado.'
    },
    itemTypes: {
      PreviewButton: 'Vista previa del documento',
      Select: 'Selección de la lista',
      Signature: 'Firma',
      Email: 'Entrada de correo electrónico',
      SendEmailTo: 'Entrega de correo electrónico',
      Date: 'Fecha',
      Time: 'tiempo',
      Boolean: 'Pregunta de sí o no',
      TakePicture: 'tomar la foto',
      TakeMultiPicture: 'tomar fotos',
      Scan: 'Escaneo QR/EAN',
      Input: 'Entrada de texto',
      Textarea: 'Entrada de texto de varias líneas',
      Link: 'Enlace',
      Picture: 'Visualización de imágenes',
      Video: 'Anuncio de vídeo',
      Text: 'Visualización de texto',
      Header: 'titular',
      Rate: 'Evaluación',
      RateSmiley: 'Calificación de emoticones',
      CreateTask: 'Crear Ticket',
      FileUpload: 'Subir archivos',
      Currency: 'divisa'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Vea una versión preliminar del formulario.',
      Select: 'Defina cualquier elemento de la lista entre los que se pueda seleccionar.',
      Signature: 'Campo para firma digital (se puede utilizar con el dedo)',
      Email: 'El usuario puede proporcionar una dirección de correo electrónico válida.',
      SendEmailTo: 'El usuario puede proporcionar una dirección de correo electrónico válida a la que se enviará el formulario completo en formato PDF.',
      Date: 'El usuario puede especificar una fecha a través de un calendario.',
      Time: 'El usuario puede especificar una hora',
      Boolean: 'El usuario selecciona sí o no',
      TakePicture: 'El usuario puede tomar una foto con la cámara o subir una imagen.',
      TakeMultiPicture: 'El usuario puede tomar varias fotografías con la cámara o cargar imágenes.',
      Scan: 'Requerir que los usuarios escaneen un código QR o EAN. El resultado se muestra en el formulario y se guarda.',
      Input: 'Campo de entrada de texto de una sola línea',
      Textarea: 'Entrada de texto de varias líneas para comentarios más largos',
      Link: 'Enlace al sitio externo',
      Picture: 'Sube una imagen que se mostrará al completar.',
      Video: 'Sube un vídeo que se mostrará mientras completas el formulario.',
      Text: 'Agregue formato a textos de varias líneas.',
      Header: 'Título grande para estructurar el contenido.',
      Rate: 'calificación de 5 estrellas',
      RateSmiley: 'Elección de 3 caras sonrientes (feliz, neutral, triste)',
      CreateTask: 'Se crea un ticket para un usuario.',
      FileUpload: 'El usuario puede adjuntar cualquier número de archivos al formulario.',
      Currency: 'El usuario puede especificar un monto en una moneda.'
    },
    template: {
      template: 'Reportar plantilla',
      templates: 'Plantillas de informes',
      name: 'Apellido',
      description: 'Descripción',
      customer: 'cliente',
      new: 'Nueva plantilla de informe',
      edit: 'Editar plantilla de informe',
      create: 'Crear plantilla de informe',
      public: 'Público',
      active: 'Activo',
      deleteConfirm: "¿Está seguro de que desea eliminar la plantilla de informe '{{templateName}}'?",
      deleteSuccess: 'Se ha eliminado la plantilla de informe.',
      editor: 'Diseñador de plantillas',
      notForMobile: 'El diseñador de plantillas no está optimizado para dispositivos móviles. Utilice Blink Check en el navegador para esto.',
      noTemplates: 'Aún no hay plantillas de informes.',
      custom: 'plantilla individual',
      defaultWithPoints: 'Plantilla estándar - con puntos porcentuales',
      default: 'Plantilla estándar - sin puntos porcentuales',
      createTemplateInfo: 'Solo puede editar la plantilla de informe después de haberla creado.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Grupo de permisos globales',
    notAllowed: 'No tiene permiso para cambiar los permisos de esta plantilla.',
    all: 'El formulario puede ser cumplimentado por todos los usuarios.',
    groups: 'El formulario solo lo pueden completar los usuarios de estos grupos de autorización.',
    noGroupLicence: 'Parpadeo Verificar que la licencia para este grupo no esté activada.',
    overridingPermssion: 'La opción "Configuración del sistema" en el grupo de permisos "Administrador de sistemas" otorga automáticamente a los usuarios todos los permisos para este formulario.',
    onlyLicensedPermissionGroups: 'Solo se muestran los grupos de autorización a los que se asigna una licencia de Blink Check.',
    canUpdateChecklist: 'Editar plantilla de formulario',
    canFillChecklist: 'rellenar el formulario',
    canReadChecklistDataOwn: 'Ver y evaluar formularios autocompletados',
    canReadChecklistDataOwnEmployees: 'Vea y evalúe todos los formularios completados por sus empleados',
    canReadChecklistDataOwnLocations: 'Vea y evalúe todos los formularios completados de sus propios objetos.',
    canReadChecklistDataAll: 'Ver y evaluar todos los formularios completados'
  },
  companyConfig: {
    companyConfig: 'Configuración de inquilino',
    logoInfo: 'Aparece en el encabezado de las plantillas de informes estándar.',
    footer: 'pie de página',
    footerInfo: 'Aparece en el pie de página de las plantillas de informes estándar.',
    offline: 'Establezca una conexión a Internet para poder editar la configuración.'
  },
  switchCompanyDialogHeader: 'Cambiar clientes',
  switchCompanyDialogText: 'Si cambia de cliente, se restablecerán todos los formularios en curso. ¿Quieres continuar?',
  results: {
    results: 'Evaluación',
    details: 'Detalles',
    created: 'Creado',
    result: 'Resultado',
    noReportJsonExists: 'El informe aún no se ha creado. El informe se generará con retraso; inténtelo de nuevo más tarde.',
    noResults: 'Aún no hay formularios completos.',
    noResultsForDateFilter: 'No hay formularios completos para el período seleccionado.',
    periodFrom: 'Periodo de',
    periodTo: 'periodo arriba',
    location: 'objeto',
    createUser: 'Creador',
    loginUser: 'Empleados',
    checklist: 'plantilla de formulario',
    user: 'usuario',
    recentResults: 'Formularios actualmente completados',
    recent: 'Actual',
    pointOf: '{{ itemPoints }} de {{ maxItemPoints }} puntos',
    checklistCreatedByAt: 'Formulario completado por {{ firstName }} {{ lastName }} el {{ createDate }} a las {{ createTime }} pm.',
    linkedTask: 'Ticket vinculado: #{{ taskId }} {{ taskTitle }}',
    offline: 'Establezca una conexión a Internet para poder evaluar los formularios completados.',
    delete: {
      title: 'Eliminar formulario completado',
      message: '¿Realmente desea eliminar el formulario completo "{{checklistName}}"?',
      success: 'El formulario completado ha sido eliminado.'
    },
    editReferenceTimestamp: 'Editar fecha de referencia',
    editReferenceTimestampDescription: 'La fecha de referencia determina el momento en que se completó el formulario. Esto puede diferir del momento en que se envió el formulario.'
  },
  dependencies: {
    dependency: 'Dependencia',
    addTitle: 'Por favor seleccione el valor de comparación',
    addMessage: 'Proporcione el valor que se debe cumplir para que se muestre el artículo.',
    addTitleMulti: 'Por favor seleccione valores de comparación',
    addMessageMulti: 'Proporcione los valores, uno de los cuales debe cumplirse para que se muestre el artículo.',
    present: 'Disponible',
    notPresent: 'Indisponible',
    noResults: 'No hay dependencias presentes. Se pueden agregar nuevas dependencias mediante Agregar dependencia.',
    noAvailableItems: 'Hay muy pocos elementos en el formulario para crear dependencias. Primero agregue más elementos.',
    noAvailableItemsInGroup: 'Hay muy pocos elementos en la página para crear dependencias. Seleccione una página que contenga elementos.',
    dependencyAdded: 'Se agregó dependencia.',
    dependencyRemoved: 'Se ha eliminado la dependencia.',
    dependencyInfo: 'Una vez que agregue una dependencia, este elemento ({{elementName}}) solo aparecerá si los elementos dependientes se han completado.',
    true: 'Sí',
    false: 'No',
    '#not_empty#': 'Disponible',
    '#empty#': 'Indisponible',
    frown: 'Triste',
    meh: 'va bien',
    smile: 'Feliz'
  },
  noChecklists: 'No hay formularios disponibles.',
  updating: 'Blink Check se está actualizando.',
  installing: 'Instalando la versión {{version}}....',
  appName: 'cheque de parpadeo',
  notAvailableInPublicChecklists: ' (No disponible en plantillas de formulario público)',
  pdfAssignTemplate: 'Debe asignar una plantilla de informe al formulario antes de poder utilizar la descarga en PDF.'
};
