import { ControlValueAccessor } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({ template: '' })
export class AbstractNgModelComponent<T = any> implements ControlValueAccessor {
  @Input() cid: string = uuid();
  @Input() disabled: boolean;

  get value(): T {
    return this._value;
  }

  @Input() set value(value: T) {
    this._value = value;
    this.notifyValueChange();
  }

  @Output() valueChange = new EventEmitter();


  onChange: (value: T) => undefined;
  onTouched: () => undefined;

  protected _value: T;
  protected cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);


  // constructor(public injector: Injector) {
  //   this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
  // }

  notifyValueChange(): void {
    this.valueChange.emit(this._value); // quick hack to use it outside of form
    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  writeValue(value: T): void {
    this._value = value;
    setTimeout(() => this.cdRef.detectChanges(), 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
