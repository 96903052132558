import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs';

export function isTokenExpired(token: string) {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);

  return (decoded as any).exp < dayjs().add(1, 'minute').unix();
}
