import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  HostListener,
  inject,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractInputComponent } from '../abstracts/input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlinkIcon } from '../../ui-icon';
import { merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EventType, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface UiInputSelectItem {
  id: number | string;
  name: string;

  [x: string | number | symbol]: any;
}

export interface UiInputSelectApiItem {
  Id: number | string;
  Name: string;

  [x: string | number | symbol]: any;
}

export type UiInputSelectList = UiInputSelectItem[] | UiInputSelectApiItem[] | string[];

@Component({
  selector: 'ui-input-select',
  templateUrl: './ui-input-select.component.html',
  styleUrls: ['./ui-input-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiInputSelectComponent extends AbstractInputComponent implements AfterViewInit {
  BlinkIcon = BlinkIcon;
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  @Input() override placeholder = this.t.pleaseChoose;

  staticItems$ = new ReplaySubject<UiInputSelectList>(1);

  @Input() set items(items: UiInputSelectList) {
    this.staticItems$.next(items);
  }

  mergedItems$: Observable<Array<UiInputSelectItem>>;
  typeaheadLoading = false;
  typeaheadInput$ = new Subject<string>();

  @Input() loading = false;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() searchable = false;
  @Input() virtualScroll = false;
  @Input() multiple = false;
  @Input() notFoundText = this.t.noEntitiesFound;
  @Input() allowCustom = false;
  @Input() addCustomText = this.t.addCustom;
  @Input() wrapText = false;
  @Input() typeahead: (query: string) => Observable<Array<UiInputSelectItem>>;

  isOpen = false;
  constructor() {
    super();

    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(x => x.type === EventType.NavigationEnd)
      )
      .subscribe(() => {
        this.close();
      });
  }

  ngAfterViewInit() {
    if (this.typeahead) {
      this.ngSelect.searchable = true;
      this.ngSelect.typeahead = this.typeaheadInput$;
    }
    this.mergedItems$ = merge(
      this.staticItems$,
      this.typeaheadInput$.pipe(
        filter(term => Boolean(term)),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.typeaheadLoading = true),
        switchMap(term => this.typeahead(term).pipe(
          catchError(() => of([])),
          tap(() => this.typeaheadLoading = false)
        ))
      )
    ).pipe(
      filter(items => Boolean(items)),
      map(items => {
        if (items && items.length) {
          if (typeof items[0] === 'string') {
            items = items.map(i => ({ id: i, name: i }));
          }
        }

        // mapping for new API Model
        items = items.map(i => {
          if (i.Id && i.Name) {
            return { id: i.Id, name: i.Name };
          }
          return i;
        });

        return items as UiInputSelectItem[];
      })
    );
  }

  @HostListener('click', ['$event'])
  open() {
    if (!this.isOpen && !this.readonly) {
      this.isOpen = true;
      setTimeout(() => {
        document.addEventListener(
          'click',
          () => this.close(),
          { once: true }
        );
      });
    }
  }

  close() {
    this.isOpen = false;
    this.ngSelect.blur();
    this.cdRef.detectChanges();
  }

  clear(event) {
    event.stopPropagation();
    this.ngSelect.handleClearClick();
    this.ngSelect.blur()
  }

  createCustom(term: string) {
    return { id: term, name: term };
  }
}
