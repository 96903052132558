import { Injectable } from '@angular/core';
import { TranslateGenericClass } from './translations';
import { GlobalTranslateService } from './global-translate.service';
import meAr from './me-translations/me-ar';
import meBg from './me-translations/me-bg';
import meDe from './me-translations/me-de';
import meEl from './me-translations/me-el';
import meEn from './me-translations/me-en';
import meEs from './me-translations/me-es';
import meFa from './me-translations/me-fa';
import meFr from './me-translations/me-fr';
import meHr from './me-translations/me-hr';
import meHu from './me-translations/me-hu';
import meIt from './me-translations/me-it';
import mePl from './me-translations/me-pl';
import mePt from './me-translations/me-pt';
import meRo from './me-translations/me-ro';
import meRu from './me-translations/me-ru';
import meTr from './me-translations/me-tr';
import meUk from './me-translations/me-uk';
import meCs from './me-translations/me-cs';
import meSk from './me-translations/me-sk';

const suffix = 'blinkMe';

export const MeLanguages = [
  {
    name: 'Arabic',
    code: 'ar',
    supported: true
  },
  {
    name: 'Bulgarian',
    code: 'bg',
    supported: true
  },
  {
    name: 'German',
    code: 'de',
    supported: true
  },
  {
    name: 'English',
    code: 'en',
    supported: true
  },
  {
    name: 'Spanish',
    code: 'es',
    supported: true
  },
  {
    name: 'Persian',
    code: 'fa',
    supported: true
  },
  {
    name: 'French',
    code: 'fr',
    supported: true
  },
  {
    name: 'Greek',
    code: 'el',
    supported: true
  },
  {
    name: 'Croatian',
    code: 'hr',
    supported: true
  },
  {
    name: 'Hungarian',
    code: 'hu',
    supported: true
  },
  {
    name: 'Italian',
    code: 'it',
    supported: true
  },
  {
    name: 'Polish',
    code: 'pl',
    supported: true
  },
  {
    name: 'Portuguese',
    code: 'pt',
    supported: true
  },
  {
    name: 'Romanian',
    code: 'ro',
    supported: true
  },
  {
    name: 'Russian',
    code: 'ru',
    supported: true
  },
  {
    name: 'Turkish',
    code: 'tr',
    supported: true
  },
  {
    name: 'Ukrainian',
    code: 'uk',
    supported: true
  },
  {
    name: 'Czech',
    code: 'cs',
    supported: true
  },
  {
    name: 'Slovak',
    code: 'sk',
    supported: true
  }
];

@Injectable({
  providedIn: 'root'
})
export class MeTranslateService extends TranslateGenericClass<typeof meDe>() {
  constructor(public global: GlobalTranslateService) {
    super();

    global.setupService(this, suffix, meDe);

    global.addTranslations('ar', suffix, meAr);
    global.addTranslations('bg', suffix, meBg);
    global.addTranslations('de', suffix, meDe);
    global.addTranslations('el', suffix, meEl);
    global.addTranslations('en', suffix, meEn);
    global.addTranslations('es', suffix, meEs);
    global.addTranslations('fa', suffix, meFa);
    global.addTranslations('fr', suffix, meFr);
    global.addTranslations('hr', suffix, meHr);
    global.addTranslations('hu', suffix, meHu);
    global.addTranslations('it', suffix, meIt);
    global.addTranslations('pl', suffix, mePl);
    global.addTranslations('pt', suffix, mePt);
    global.addTranslations('ro', suffix, meRo);
    global.addTranslations('ru', suffix, meRu);
    global.addTranslations('tr', suffix, meTr);
    global.addTranslations('uk', suffix, meUk);
    global.addTranslations('cs', suffix, meCs);
    global.addTranslations('sk', suffix, meSk);
  }

  getSupportedLanguage(language: string) {
    const supported = MeLanguages.find(l => l.code === language && l.supported);
    return supported ? supported.code : 'en';
  }
}
