import { NgModule } from '@angular/core';
import { UiIconComponent } from './ui-icon.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UiIconComponent],
  imports: [CommonModule],
  exports: [UiIconComponent],
})
export class UiIconModule {}
