import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';

import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckGroup } from './group';
import { CheckGroupRepository } from './group.repository';
import { CheckChecklistApi } from '../checklist';

@Injectable({ providedIn: 'root' })
export class CheckGroupApiService {
  private api = this.apiManager
    .createApiForEndpoint<CheckGroup>(
      'odata/v1',
      'Groups',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private groupRepo: CheckGroupRepository,
              private checklistApi: CheckChecklistApi) {
  }

  create(group: Partial<CheckGroup>) {
    return this.api.post({ body: group }).pipe(tap(
      created => {
        this.groupRepo.sync([created], false);
      }
    ));
  }

  duplicate(group: CheckGroup) {
    const query = {
      key: group.Id,
      action: 'Duplicate'
    };
    return this.api.post(query)
      .pipe(
        switchMap(() => this.checklistApi.getFull(group.ChecklistId))
      );
  }

  update(group: Partial<CheckGroup>) {
    return this.api.patch({ key: group.Id, body: group, oDataReturnRepresentation: true }).pipe(tap(
      (updated) => this.groupRepo.sync([updated], false)
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.groupRepo.delete(id)
    ));
  }
}
