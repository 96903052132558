export default {
  config: {
    checklist: {
      checklist: 'Modelo de formulário',
      checklists: 'Modelos de formulário',
      none: 'Não há formulários disponíveis.',
      new: 'Novo modelo de formulário',
      edit: 'Editar modelo de formulário',
      saved: 'O modelo de formulário foi salvo.',
      delete: 'Excluir modelo de formulário',
      deleteChecklistResults: 'Excluir também todos os formulários preenchidos do modelo de formulário {{name}}?',
      deleteInfo: 'Se você excluir o modelo de formulário, todos os formulários preenchidos também serão excluídos irrevogavelmente! <br><br>Para manter os formulários preenchidos, desative o modelo de formulário.',
      deleted: 'O modelo de formulário foi excluído.',
      duplicate: 'Modelo de formulário duplicado',
      duplicateInfo: 'Você pode usar a função duplicar para duplicar modelos de formulário',
      duplicateMessage: 'Deseja duplicar o modelo de formulário "{{checklistName}}" com todo o conteúdo?',
      duplicated: 'O modelo de formulário foi duplicado.',
      templateName: 'Nome do formulario',
      activeInfo: 'Apenas os modelos ativos podem ser preenchidos; os modelos inativos só podem ser editados na gestão de modelos.',
      locationRequired: 'A seleção do objeto é obrigatória',
      locationRequiredInfo: 'Se ativado, um objeto deve ser selecionado ou o código QR escaneado para preencher o formulário.',
      loginUserRequired: 'Seleção de funcionários é obrigatória',
      loginUserRequiredInfo: 'Se ativado, um funcionário deverá ser selecionado para preencher o formulário.',
      allLocationAllowed: 'Permitir todos os objetos',
      allowOnlyCertainLocations: 'Permitir apenas determinados objetos',
      public: 'Formulário público',
      publicInfo: 'Crie um link público com o qual o formulário possa ser preenchido por qualquer usuário. Nenhum login ou aplicativo é necessário. Você pode especificar uma data de validade até quando o link deverá ser válido.',
      preview: 'Ativar visualização',
      previewInfo: 'Se ativado, um botão “Visualizar” sempre será exibido na parte inferior do formulário ao preenchê-lo. Como alternativa ao botão de visualização permanente, você também pode colocar o elemento de conteúdo “Visualizar” em qualquer lugar do formulário (esta função não precisa ser ativada).',
      shortUrlExpireDate: 'Data de validade',
      sysadminOnly: 'Somente administradores de sistema',
      allUsers: 'Todos os usuários',
      usersOfPermissionGroup: 'Usuários desses grupos de autorização',
      emailConfig: 'Configuração de e-mail',
      emailConfigInfo: 'Se você inserir endereços de e-mail aqui, os formulários preenchidos serão enviados automaticamente para esses endereços de e-mail.',
      emailSaved: 'O endereço de e-mail foi salvo',
      emailRemoved: 'O endereço de e-mail foi excluído',
      importExportInfo: 'A função de exportação permite exportar modelos de formulário e modelos de relatório associados para reimportá-los para outro cliente ou sistema Blink Check.',
      import: 'Modelo de formulário de importação',
      importInfo: 'Selecione o arquivo .check-b64 do modelo de formulário que deseja importar.',
      imported: 'O formulário foi criado com sucesso.',
      templatesInAppFolder: 'Modelos no diretório do aplicativo:',
      invalidImport: 'O arquivo selecionado não é um modelo de formulário válido.',
      exported: 'O modelo de formulário foi exportado',
      offline: 'Estabeleça uma conexão com a Internet para editar modelos de formulário.',
      checklistContainsCreateTask: 'O modelo de formulário contém o elemento ‘Criar ticket’ e, portanto, não pode ser definido como público. Remova o elemento ‘Criar ticket’.'
    },
    category: {
      category: 'categoria',
      categories: 'Categorias',
      saved: 'A categoria foi salva.',
      editTitle: 'Editar categoria',
      content: 'elementos',
      newCategory: 'Nova categoria',
      name: 'Nome da Categoria',
      edit: 'Editar categoria',
      duplicated: 'A categoria foi duplicada.',
      noCategories: 'Ainda não há categorias. Crie novas categorias usando o botão “Adicionar categoria”.',
      inactive: 'inativo',
      showInactive: 'Mostrar os inativos',
      duplicateCategoryConfirmHeader: 'Categoria duplicada',
      duplicateCategoryConfirm: 'Deseja duplicar a categoria "{{categoryName}}" com todo o seu conteúdo?',
      delete: 'Excluir categoria',
      deleteCategoryConfirm: 'Tem certeza de que deseja excluir a categoria "{{categoryName}}"?',
      deleteSuccess: 'A categoria foi removida.'
    },
    group: {
      group: 'Página',
      groups: 'Páginas',
      created: 'A página foi criada.',
      updated: 'As alterações na página foram salvas.',
      updateError: 'A página não pôde ser salva. Por favor, tente novamente mais tarde.',
      rename: 'Renomear página',
      saved: 'A página foi salva.',
      toggleSort: 'Alterar classificação',
      editGroupName: 'Editar nome da página',
      new: 'Nova página',
      name: 'Nome da página',
      duplicated: 'A página foi duplicada.',
      noGroups: 'Ainda não há páginas. Crie novas páginas usando o botão “Adicionar página”.',
      noItems: 'Ainda não há elementos. Crie novos elementos usando o botão “Adicionar elemento”.',
      add: 'Adicionar Página',
      infoElement: 'Elemento de informação',
      inputElement: 'Elemento de entrada',
      addInfoElement: 'Adicionar elemento de informação',
      addInputElement: 'Adicionar elemento de entrada',
      delete: 'Excluir página',
      deleteGroupConfirm: 'Tem certeza de que deseja excluir a página "{{groupName}}"?',
      chooseGroup: 'Em qual página o elemento deve ser adicionado?',
      duplicateGroupConfirmHeader: 'página duplicada',
      duplicateGroupConfirm: 'Deseja duplicar a página "{{groupName}}" com todo o conteúdo?',
      deleteSuccess: 'a página foi removida.'
    },
    item: {
      add: 'adicionar Item',
      created: 'O item foi criado',
      saved: 'O item foi salvo.',
      chooseItemType: 'Selecionar item',
      values: 'Valores',
      value: 'Valor',
      ratingNumber: 'Avaliação',
      addItemValue: 'Adicionar novo valor',
      addItemValueSuccess: 'Novo valor foi adicionado',
      updateItemValueSuccess: 'O valor foi atualizado',
      removeItemValueSuccess: 'o valor foi removido',
      removeItemValue: 'remover valor',
      takePictureButtonText: 'Selecione a imagem',
      editTitle: 'editar item',
      editDependenciesTitle: 'Editar dependências',
      required: 'Campo obrigatório',
      name: 'Nome do elemento',
      description: 'descrição opcional do elemento',
      points: 'Pontos',
      duplicated: 'O item foi duplicado.',
      duplicateItemConfirmHeader: 'item duplicado',
      duplicateItemConfirm: 'Deseja duplicar o elemento "{{itemName}}" com todo o conteúdo?',
      delete: {
        title: 'Apagar item',
        message: 'Você realmente deseja excluir o elemento “{{itemName}}"?',
        success: 'O item foi removido'
      },
      moveItem: 'Mover elemento',
      move: 'Mover',
      moveItemInfo: 'Selecione a categoria e a página para onde mover o item atual.',
      itemMoved: 'O item foi movido'
    },
    editItem: {
      name: 'Sobrenome',
      nameInfo: 'Este texto é exibido diretamente na frente do elemento de entrada.',
      link: 'Endereço do link',
      linkInfo: 'Este endereço estará acessível através de um botão. Por favor, insira um URL válido.',
      description: 'Descrição',
      descriptionInfo: 'Se desejar fornecer instruções para preencher o formulário, você pode inseri-las aqui.',
      linkDescription: 'Etiqueta do botão',
      linkDescriptionInfo: 'Insira um nome para o botão.',
      values: 'Valores',
      valuesInfo: 'Você pode especificar um número de classificação para cada valor. Você pode realizar avaliações posteriormente usando o número da classificação. O número da classificação é opcional.',
      value: 'Valor',
      valuePlaceholder: 'Novo valor',
      ratingNumber: 'Avaliação',
      requiredField: 'Campo obrigatório',
      requiredFieldInfo: 'Se ativado como campo obrigatório, este campo deverá ser preenchido para que o formulário possa ser enviado.',
      externalNo: 'Número externo',
      externalNoInfo: 'Informações opcionais para interfaces ou avaliações via sistemas externos.',
      editable: 'Editável',
      editableInfo: 'Para que os usuários façam entradas, o elemento deve ser editável.',
      defaultValue: 'Especifique o valor padrão',
      defaultValueInfo: 'Você pode especificar uma atribuição padrão para este elemento.',
      defaultValueInfoEditable: 'O valor pode ser alterado pelo usuário do formulário.',
      visible: 'visibilidade',
      visibleInfo: 'A visibilidade controla se o elemento pode ser visto pelo editor ou não.',
      externalNoAleradyUsed: 'Este número externo já está em uso.',
      taskTypeId: 'Tipo de bilhete',
      loginUserId: 'do utilizador',
      locationId: 'Nome do objeto',
      videoId: 'Número do vídeo',
      videoType: 'Serviço de vídeo',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Insira o ID exclusivo do vídeo aqui. Você pode encontrar isso, por exemplo, no URL do vídeo. <br/>Exemplo: https://www.youtube.com/watch?v=XXXXX ou no Vimeo https://vimeo.com/XXXXX. <br/>Neste caso, XXXXX teria que ser inserido no campo.',
      defaultTaskTitle: 'Título padrão do ticket',
      defaultTaskDescription: 'Descrição padrão do ticket',
      titleEditable: 'Título do ticket editável pelo usuário',
      descriptionEditable: 'Descrição do ticket editável pelo usuário',
      dueDateEditable: 'Data de vencimento do ticket editável pelo usuário',
      addReportAttachment: 'Anexe o formulário preenchido ao ticket em formato PDF',
      noTaskTypesFound: 'Nenhum tipo de ingresso foi encontrado. Os tipos de tickets podem ser gerenciados via Blink Active.',
      noLoginUsersFound: 'Nenhum usuário foi encontrado. Os usuários podem ser gerenciados através do Blink Manager.',
      noLocationsFound: 'Nenhum objeto foi encontrado. Os objetos podem ser gerenciados através do Blink Manager.',
      createTaskItemInfo: 'Com esta função você pode criar Tickets Blink Active. Você pode especificar o título e a descrição ou solicitar que o usuário os preencha. O objeto é atribuído automaticamente e o gerenciador de objetos responsável é atribuído como processador.',
      duplicatedValuesNotAllowed: 'Valores duplicados não são permitidos.',
      valueLengthExceeded: 'O valor inserido é muito longo e será reduzido.'
    },
    itemTypes: {
      PreviewButton: 'Visualização do documento',
      Select: 'Seleção da lista',
      Signature: 'Assinatura',
      Email: 'Entrada de e-mail',
      SendEmailTo: 'Entrega de e-mail',
      Date: 'Data',
      Time: 'tempo',
      Boolean: 'Sim-não pergunta',
      TakePicture: 'tire uma foto',
      TakeMultiPicture: 'tirar fotos',
      Scan: 'Varredura QR/EAN',
      Input: 'Entrada de texto',
      Textarea: 'Entrada de texto multilinha',
      Link: 'Vinculando',
      Picture: 'Exibição de imagem',
      Video: 'Anúncio em vídeo',
      Text: 'Exibição de texto',
      Header: 'título',
      Rate: 'Avaliação',
      RateSmiley: 'Avaliação sorridente',
      CreateTask: 'Criar ingresso',
      FileUpload: 'Fazer upload de arquivos',
      Currency: 'moeda'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Veja uma prévia do formulário.',
      Select: 'Defina quaisquer itens da lista dos quais um pode ser selecionado.',
      Signature: 'Campo para assinatura digital (pode ser usado com o dedo)',
      Email: 'O usuário pode fornecer um endereço de e-mail válido.',
      SendEmailTo: 'O usuário pode fornecer um endereço de e-mail válido para o qual o formulário preenchido será enviado em PDF.',
      Date: 'O usuário pode especificar uma data por meio de um calendário',
      Time: 'O usuário pode especificar um horário',
      Boolean: 'O usuário escolhe sim ou não',
      TakePicture: 'O usuário pode tirar uma foto com a câmera ou fazer upload de uma imagem.',
      TakeMultiPicture: 'O usuário pode tirar diversas fotos com a câmera ou fazer upload de imagens.',
      Scan: 'Exija que os usuários leiam um código QR ou EAN. O resultado é exibido no formulário e salvo.',
      Input: 'Campo de entrada de texto de linha única',
      Textarea: 'Entrada de texto multilinha para comentários mais longos',
      Link: 'Link para site externo',
      Picture: 'Faça upload de uma imagem que será exibida durante o preenchimento.',
      Video: 'Envie um vídeo que será mostrado enquanto você preenche o formulário.',
      Text: 'Adicione formatação a textos com várias linhas.',
      Header: 'Título grande para estruturar o conteúdo',
      Rate: 'Avaliação de 5 estrelas',
      RateSmiley: 'Escolha de 3 carinhas sorridentes (feliz, neutra, triste)',
      CreateTask: 'Um ticket é criado para um usuário',
      FileUpload: 'O usuário pode anexar qualquer número de arquivos ao formulário.',
      Currency: 'O usuário pode especificar um valor em uma moeda.'
    },
    template: {
      template: 'Modelo de relatório',
      templates: 'Modelos de relatório',
      name: 'Sobrenome',
      description: 'Descrição',
      customer: 'cliente',
      new: 'Novo modelo de relatório',
      edit: 'Editar modelo de relatório',
      create: 'Criar modelo de relatório',
      public: 'Público',
      active: 'Ativo',
      deleteConfirm: "Tem certeza de que deseja excluir o modelo de relatório '{{templateName}}'?",
      deleteSuccess: 'O modelo de relatório foi removido',
      editor: 'Designer de modelo',
      notForMobile: 'O designer de modelo não está otimizado para dispositivos móveis. Use o Blink Check no navegador para isso.',
      noTemplates: 'Ainda não há modelos de relatório.',
      custom: 'Modelo individual',
      defaultWithPoints: 'Modelo padrão – com pontos percentuais',
      default: 'Modelo padrão - sem pontos percentuais',
      createTemplateInfo: 'Você só poderá editar o modelo de relatório após ele ter sido criado.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Grupo de permissão global',
    notAllowed: 'Você não tem permissão para alterar as permissões deste modelo.',
    all: 'O formulário pode ser preenchido por todos os usuários',
    groups: 'O formulário só pode ser preenchido por usuários desses grupos de autorização',
    noGroupLicence: 'A licença do Blink Check para este grupo não está ativada.',
    overridingPermssion: 'A opção "Configurações do sistema" no grupo de permissões "Sysadmin" concede automaticamente aos usuários todas as permissões para este formulário.',
    onlyLicensedPermissionGroups: 'Somente grupos de autorização aos quais uma licença Blink Check está atribuída são exibidos.',
    canUpdateChecklist: 'Editar modelo de formulário',
    canFillChecklist: 'preencha o formulário',
    canReadChecklistDataOwn: 'Visualizar e avaliar formulários autopreenchidos',
    canReadChecklistDataOwnEmployees: 'Visualize e avalie todos os formulários preenchidos por seus funcionários',
    canReadChecklistDataOwnLocations: 'Visualize e avalie todos os formulários preenchidos de seus próprios objetos',
    canReadChecklistDataAll: 'Visualize e avalie todos os formulários preenchidos'
  },
  companyConfig: {
    companyConfig: 'Configuração do locatário',
    logoInfo: 'Aparece no cabeçalho dos modelos de relatório padrão.',
    footer: 'rodapé',
    footerInfo: 'Aparece no rodapé em modelos de relatório padrão.',
    offline: 'Estabeleça uma conexão com a Internet para poder editar a configuração.'
  },
  switchCompanyDialogHeader: 'Alterar clientes',
  switchCompanyDialogText: 'Se você trocar de cliente, todos os formulários em andamento serão redefinidos. Você quer continuar?',
  results: {
    results: 'Avaliação',
    details: 'Detalhes',
    created: 'Criada',
    result: 'Resultado',
    noReportJsonExists: 'O relatório ainda não foi criado. O relatório será gerado com atraso. Tente novamente mais tarde.',
    noResults: 'Ainda não há formulários preenchidos.',
    noResultsForDateFilter: 'Não há formulários preenchidos para o período selecionado.',
    periodFrom: 'Período de',
    periodTo: 'período acima',
    location: 'objeto',
    createUser: 'O Criador',
    loginUser: 'Funcionários',
    checklist: 'Modelo de formulário',
    user: 'do utilizador',
    recentResults: 'Formulários atualmente preenchidos',
    recent: 'Atual',
    pointOf: '{{ itemPoints }} de {{ maxItemPoints }} pontos',
    checklistCreatedByAt: 'Formulário preenchido até {{ firstName }} {{ lastName }} de {{ createDate }} às {{ createTime }} horas.',
    linkedTask: 'Bilhete vinculado: #{{ taskId }} {{ taskTitle }}',
    offline: 'Por favor, estabeleça uma conexão com a Internet para poder avaliar os formulários preenchidos.',
    delete: {
      title: 'Excluir formulário preenchido',
      message: 'Você realmente deseja excluir o formulário preenchido “{{checklistName}}”?',
      success: 'O formulário preenchido foi removido'
    },
    editReferenceTimestamp: 'Editar data de referência',
    editReferenceTimestampDescription: 'A data de referência determina a hora em que o formulário foi preenchido. Isso pode ser diferente do momento em que o formulário foi enviado.'
  },
  dependencies: {
    dependency: 'Dependência',
    addTitle: 'Selecione o valor de comparação',
    addMessage: 'Forneça o valor que deve ser atendido para que o item seja exibido.',
    addTitleMulti: 'Selecione valores de comparação',
    addMessageMulti: 'Forneça os valores, um dos quais deve ser atendido para que o item seja exibido.',
    present: 'Disponível',
    notPresent: 'Indisponível',
    noResults: 'Nenhuma dependência presente. Novas dependências podem ser adicionadas através de Adicionar Dependência.',
    noAvailableItems: 'Existem poucos elementos no formulário para criar dependências. Adicione mais itens primeiro.',
    noAvailableItemsInGroup: 'Existem poucos elementos na página para criar dependências. Selecione uma página que contenha itens.',
    dependencyAdded: 'Dependência foi adicionada.',
    dependencyRemoved: 'A dependência foi removida.',
    dependencyInfo: 'Depois de adicionar uma dependência, este item ({{elementName}}) só aparecerá se os itens dependentes tiverem sido preenchidos.',
    true: 'Sim',
    false: 'Não',
    '#not_empty#': 'Disponível',
    '#empty#': 'Indisponível',
    frown: 'Triste',
    meh: 'Está indo bem',
    smile: 'Feliz'
  },
  noChecklists: 'Não há formulários disponíveis.',
  updating: 'O Blink Check está sendo atualizado.',
  installing: 'Instalando a versão {{version}}...',
  appName: 'Verificação de piscar',
  notAvailableInPublicChecklists: ' (Não disponível em modelos de formulário público)',
  pdfAssignTemplate: 'Você deve atribuir um modelo de relatório ao formulário antes de poder usar o download do PDF.'
};
