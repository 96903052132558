<ion-item class="blink-item-input item-interactive"
          lines="none"
          color="input"
          [class.is-open]="isOpen">
  <ion-label *ngIf="label"
             position="stacked"
             [class.required]="required">
    {{ label | translate }}
  </ion-label>
  <ng-select #ngSelect
             [(ngModel)]="value"
             [isOpen]="isOpen"
             [virtualScroll]="true"
             [items]="iconSelectList"
             [searchable]="true"
             bindValue="id"
             bindLabel="name"
             [placeholder]="placeholder | translate">
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      <ng-container>
        <i class="far fa-{{item.id}} fa-fw mr-5"></i>
        {{ item.name }}
      </ng-container>
    </ng-template>
  </ng-select>
  <ion-button class="input-select-action-button mr-5"
              slot="end" fill="clear">
    <ui-icon class="dropdown-chevron" slot="end"
             [icon]="BlinkIcon.ChevronDown"
             style="margin: auto;">
    </ui-icon>
  </ion-button>
</ion-item>
