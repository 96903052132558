import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ENV_PROD, ENV_VARS_SYSTEM, GlobalTranslateService, logComponent, systemFromCheckUrl } from '@blink/util';
import { LoginService } from '../login.service';
import { AuthModeEnum } from '@blink/util';
import { BlinkLoginConfigService, BlinkLoginConfig } from '../utils/injection-tokens';


@Component({
  selector: 'blink-credentials-flow',
  templateUrl: 'credentials-flow.html',
  styleUrls: ['./credentials-flow.scss']
})
export class CredentialsFlowComponent {
  form: any;
  loading = false;
  system: string;

  constructor(public loginService: LoginService,
              public t: GlobalTranslateService,
              public translate: TranslateService,
              @Inject(ENV_PROD) private environmentProd: boolean,
              @Inject(ENV_VARS_SYSTEM) private fallbackSystem: string,
              @Inject(BlinkLoginConfigService) private loginConfig: BlinkLoginConfig,
              public fb: FormBuilder) {
    logComponent(this);
    this.system = systemFromCheckUrl(this.environmentProd, this.fallbackSystem);

    this.form = this.fb.group({
      company: [this.system, Validators.required],
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    if (!environmentProd) {
      this.form.patchValue({
        company: this.fallbackSystem,
        username: this.loginConfig.devUsername,
        password: this.loginConfig.devPassword
      })
    }

  }

  tryLogin() {
    if (this.form.valid) {
      this.login();
    }
  }

  private async login() {
    this.loading = true;

    const params = {
      login: this.form.value.username.trim(),
      password: this.form.value.password.trim(),
      system: this.form.value.company,
      authMode: AuthModeEnum.PASSWORD
    };

    try {
      await this.loginService.login(params);
    } finally {
      this.loading = false;
    }
  }

  forgotPassword() {
    this.loginService.showForgotPassword(this.system);
  }

}
