export default {
  home: {
    pageTitle: 'Az én űrlapjaim',
    locationChecklists_label: 'Töltsön ki egy űrlapot egy objektumhoz',
    generalChecklists_label: 'Töltse ki az általános űrlapot',
    processingChecklists_label: 'Űrlap folyamatban',
    checklistDatas_label: 'Kitöltött űrlapok',
    selectLocation: 'válassza ki az objektumot',
    scanQrCode: 'Objektumkód beolvasása',
    showAll: '... Mutasd az összeset',
    showLess: '... mutass kevesebbet'
  },
  fill: {
    checklists: 'Űrlapok',
    fillChecklist: 'kitölteni az űrlapot',
    checklistNotFound: 'Sajnos az Ön által kért űrlap nem található.',
    checklistOfflineLoginUserRequired: 'Ez az űrlap megköveteli a munkavállaló kiválasztását. Kérjük, hozzon létre internetkapcsolatot az űrlapsablon eléréséhez.',
    checklistNotOfflineAvailable: 'Az űrlapsablon nincs offline módban mentve, kérjük, csatlakozzon az internethez az űrlapsablon eléréséhez.',
    resetConfirm: 'Valóban vissza szeretné állítani az űrlapot?',
    categoryAppeared: 'A(z) "{{categoryName}}" kategória hozzáadva.',
    groupAppeared: 'A(z) "{{groupName}}" oldal hozzáadva.',
    itemAppearedInGroup: 'Új kérdések kerültek fel a(z) "{{groupName}}" oldalra.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Tovább a következő kérdésre',
    containerUriExpired: 'Sajnos a feltöltési időszak lejárt.',
    finishMessage: 'Az összes szükséges adatot megadta, és most már kitöltheti az űrlapot.',
    offlineMessage: 'Az űrlap kitöltéséhez csatlakozzon az internethez.',
    offlinePreviewMessage: 'Kérjük, csatlakozzon az internethez az előnézet létrehozásához.',
    checklistUpdatedHeader: 'Az űrlapsablon megváltozott',
    checklistUpdatedMessage: 'Az űrlapsablon megváltozott. Kérjük, ellenőrizze újra a bejegyzéseket az űrlap elküldése előtt.',
    checklistLocationRequiredHeader: 'Válassza ki az objektumot',
    checklistLocationRequiredMessage: 'Az űrlap kitöltéséhez ki kell választania egy objektumot.',
    finishSuccess: 'Az űrlapot sikeresen kitöltöttük, és minden adatot feldolgoztunk.',
    finishError: 'Az űrlapot hiba miatt nem lehetett kitölteni. Kérjük, forduljon az ügyfélszolgálathoz.',
    createdWith: 'Ez az űrlap a következővel készült:',
    preview: 'előnézet',
    fileUploadFileMissingHeader: 'A fájlok nem találhatók',
    fileUploadFileMissingMessage: 'A következő fájlok kiválasztva, de nem találhatók az eszközön: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Igen', no: 'Nem' },
    scan: 'letapogatás',
    email: { invalidEmail: 'Kérjük, ellenőrizze a bejegyzést.' },
    takePicture: { picture: 'Kép', buttonText: 'adj hozzá egy fényképet' },
    createTask: {
      taskTitle: 'A jegy címe',
      taskDescription: 'A jegy leírása',
      taskDueDate: 'Jegy esedékessége'
    }
  },
  noChecklistsForLocationFound: 'Ennek az objektumnak nincs formája. Kérjük, válasszon másik ingatlant, vagy töltse ki az általános űrlapot.',
  selectChecklistByLocation: 'Válassza ki az objektum űrlapját'
};
