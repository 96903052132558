import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared';
import {
  CheckChecklistData,
  CheckChecklistItemData,
  CheckResult,
  CheckResultItemDataTask,
  CheckStorageFile,
  CheckStorageLink,
  GetResultPageRequest,
  GetResultsRequestParams,
  ResultsExcelExportParams
} from './types';
import { CheckChecklistDataRepository } from './checklist-data.repository';
import { first, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { uiCancelButton, UiColor, UiDialogService, UiLoadingService } from '@blink/ui';
import { firstValueFrom, forkJoin, from, Observable, of } from 'rxjs';
import { BlockBlobParallelUploadOptions, ContainerClient } from '@azure/storage-blob';
import { Router } from '@angular/router';
import { CheckGroupRepository } from '../group/group.repository';
import { CheckItemRepository } from '../item/item.repository';
import { CheckGroupDependencyRepository } from '../group-dependency/group-dependency.repository';
import { CheckCategoryDependencyRepository } from '../category-dependency/category-dependency.repository';
import { CheckItemDependencyRepository } from '../item-dependency/item-dependency.repository';
import { FillItemFileUploadService, HANDLE_FILE_UPLOAD } from './fill-item-file-upload.service';
import { CheckChecklistRepository, ChecklistSubmitFacade } from '../checklist';
import { CheckItemFillFacade } from '../item/item.fill-facade';
import { CompanyRepository } from '../../core';
import dayjs from 'dayjs';
import { UiPaginationResponse, UiPaginatorPageRequest } from '@blink/ui-pagination';
import { deUmlaut, removeSpecialCharacters } from '@blink/util';


// todo optimize viewProperty clearer in ApiManager
const clearChecklistDataViewProperties = (checklistData: CheckChecklistData) => {
  delete checklistData.id;
  checklistData.Items = checklistData.Items.map(item => {
      const mappedItem = {
        ...item,
        ItemData: JSON.stringify(item.ItemData),
        ItemDataPoints: item.ItemDataPoints ? item.ItemDataPoints : null
      };
      delete mappedItem.id;
      delete mappedItem.checklistId;
      return mappedItem;
    }
  );
  delete checklistData.versionTag;
  delete checklistData.loginUserDisplayName;
  delete checklistData.locationDisplayName;
  return checklistData;
};

const createBlobsInContainer = async (containerClient: ContainerClient, files: File[], updateStatus: (fileName, bytes, loadedBytes) => void) => {
  for (const file of files) {
    const blobClient = containerClient.getBlockBlobClient(file.name);
    const options: BlockBlobParallelUploadOptions = {
      blobHTTPHeaders: {
        blobContentType: file.type
      },
      onProgress: (progress) => {
        updateStatus(file.name, file.size, progress.loadedBytes);
      }
    };
    await blobClient.uploadData(file, options);
  }
};


@Injectable({ providedIn: 'root' })
export class CheckChecklistDataApi {
  private api =
    this.apiManager.createApiForEndpoint<CheckChecklistData>(
      'odata/v1',
      'ChecklistDatas',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private checklistDataRepo: CheckChecklistDataRepository,
              private checklistRepo: CheckChecklistRepository,
              private groupRepo: CheckGroupRepository,
              private categoryDependencyRepo: CheckCategoryDependencyRepository,
              private groupDependencyRepo: CheckGroupDependencyRepository,
              private itemDependencyRepo: CheckItemDependencyRepository,
              private itemRepo: CheckItemRepository,
              private itemFillFacade: CheckItemFillFacade,
              private checklistSubmitFacade: ChecklistSubmitFacade,
              private uploadFileService: FillItemFileUploadService,
              private uiDialogService: UiDialogService,
              private uiLoading: UiLoadingService,
              private router: Router,
              private companyRepository: CompanyRepository) {
    window['checklistDataApi'] = this;
  }

  previewChecklist(checklistId: number) {
    const query = {
      action: 'GetDocumentPreview'
    };

    firstValueFrom(this.checklistSubmitFacade.getChecklistDataForSubmit(checklistId).pipe(
      switchMap(checklistData => {
        return this.api.post({
          ...query,
          body: {
            ChecklistData: clearChecklistDataViewProperties(checklistData)
          }
        }) as unknown as Observable<{ value: string }>;
      }),
      map((response) => response.value),
      take(1)
    )).then(previewPdfBase64 => {
      this.uiDialogService.showPdf(previewPdfBase64, 'Vorschau');
    });
  }

  patchReferenceTimestamp(checklistDataId: number, timestamp: string) {
    const options = {
      key: checklistDataId,
      body: { ReferenceTimestamp: timestamp }
    }
    return this.api.patch(options);
  }

  async submitChecklist(checklistId: number, skipAuth = false, skipConfirm = false) {

    const handler = () => {
      this.checklistRepo.setFillProcessing(checklistId, true);
      this.uiLoading.setWriteLoading(true);

      firstValueFrom(this.checklistSubmitFacade.getChecklistDataForSubmit(checklistId).pipe(
        tap(checklistData => this.itemRepo.saveEmailHistory(checklistData.Items)),
        switchMap((checklistData) =>
          this.uploadFilesAndDeleteLocalData(checklistData)),
        switchMap((checklistData: CheckChecklistData) => {
            this.resetChecklist(checklistId);
            return this.create(checklistData, skipAuth)
              .pipe(
                tap(() => this.checklistRepo.setFillProcessing(checklistId, false)),
                tap(() => this.router.navigate(['fill', 'finish', checklistId])
                ));
          }
        )
      )).finally(() => this.uiLoading.setWriteLoading(false));
    }

    if (!skipConfirm) {
      this.uiDialogService.confirm({
        title: 'typedGlobal.finish',
        text: 'fillChecklist.fill.finishMessage',
        buttons: [
          uiCancelButton(),
          {
            label: 'typedGlobal.finish',
            color: UiColor.Primary,
            fill: 'solid',
            handler,
            cy: 'confirm-submit-checklist-button'
          }
        ]
      });
    } else {
      handler();
    }
  }

  syncDependencies(checklistId: number, checklistData: CheckChecklistData) {

    const itemsInOrder = new Map();

    this.checklistRepo.getWithAll(checklistId).pipe(take(1))
      .subscribe(checklist => {
        const checklistCategories = checklist.categories;
        checklistCategories.forEach(checklistCategory => {
          const checklistGroups = checklist.groups
            .filter(x => x.CategoryId === checklistCategory.Id);
          checklistGroups.forEach(checklistGroup => {
            const checklistItems = checklist.items
              .filter(x => x.GroupId === checklistGroup.Id);
            checklistItems.forEach(checklistItem => {
              const checklistDataItem = checklistData.Items?.find(x => x.ItemId === checklistItem.Id)
              if (checklistDataItem) {
                itemsInOrder.set(checklistItem.Id, checklistDataItem.ItemData);
              }
            });
          });
        });

        from(itemsInOrder).pipe(
          mergeMap(item => this.itemFillFacade.getManyForFill([item[0]])
            .pipe(
              first(),
              tap(checklistForFillItems => {
                const checklistForFillItem = checklistForFillItems[0];
                if (checklistForFillItem) {
                  this.checklistDataRepo.updateDependency(checklistForFillItem, item[1]);
                }
              })
            )
          )
        ).subscribe().unsubscribe();
      });
  }

  resetChecklist(checklistId: number) {
    this.checklistDataRepo.delete(checklistId);
    this.groupRepo.resetGroupSeenForChecklist(checklistId);
    this.categoryDependencyRepo.resetDependenciesForChecklist(checklistId);
    this.groupDependencyRepo.resetDependenciesForChecklist(checklistId);
    this.itemDependencyRepo.resetDependenciesForChecklist(checklistId);
    this.itemRepo.resetItemsForChecklist(checklistId);
  }

  generateStorageLink(itemId: number): Observable<CheckStorageLink> {
    const query = {
      body: { ItemId: itemId },
      service: BlinkService.Check
    };

    const result = this.apiManager
      .post('odata/v1', 'FileUploadUrls/Generate', query);

    return this.addServiceWorkerBypassParam(result);
  }

  uploadFilesAndDeleteLocalData(checklistData: CheckChecklistData): Observable<CheckChecklistData> {
    const uploadItemDatas = checklistData.Items.filter(x => {
      return this.isFileUploadItem(x);
    });

    if (uploadItemDatas.length === 0) {
      return of(checklistData);
    }

    const progressFunction = this.uploadFileService.showUploadStatusModal(uploadItemDatas.map(x => x.ItemId));

    const itemDict = {};
    uploadItemDatas.forEach(x => {
      itemDict[x.ItemId] = this.generateStorageLink(x.ItemId)
        .pipe(
          switchMap(async storageLink => {
            const containerClient = new ContainerClient(storageLink.ContainerUriWithSasToken);
            const files = await this.uploadFileService.getFilesFor(x);

            if (files) {
              await createBlobsInContainer(
                containerClient,
                files,
                progressFunction
              );
              await this.uploadFileService.clearFileUploadsFor(x.ItemId);
              return storageLink.ContainerIdentifier;
            }
            return null;
          })
        );
    });

    // forkJoin returns Observable<{ [itemId: number]: string }> where string eq azure blob container id
    return forkJoin(itemDict).pipe(
      map(uploadResult => {

        const newItems = checklistData.Items.map(x => {
          if (this.isFileUploadItem(x)) {
            x.ItemData = uploadResult[x.ItemId] ?? 'HANDLE_NO_FILES';
          }
          return x;
        });

        const filteredItems = newItems.filter(x => x.ItemData !== 'HANDLE_NO_FILES');

        return {
          ...checklistData,
          Items: filteredItems
        };
      })
    );
  }

  public isFileUploadItem(x: CheckChecklistItemData) {
    return typeof (x.ItemData) === 'string' && x.ItemData.startsWith(HANDLE_FILE_UPLOAD);
  }

  getChecklistDataAttachments(checklistDataId: number): Observable<CheckStorageFile[]> {
    const query = {
      key: checklistDataId,
      action: 'GetAttachments'
    };
    return this.api.post(query) as unknown as Observable<CheckStorageFile[]>;
  }

  delete(checklistDataId: number) {
    return this.api.delete({ key: checklistDataId }).pipe(tap(
      () => this.checklistDataRepo.delete(checklistDataId)
    ));
  }

  getRecentResults() {
    const query = {
      select: [
        'Id',
        'CompanyId',
        'LoginUserId',
        'CreateUserId',
        'ChecklistId',
        'CreateDate',
        'LocationId',
        'ItemPoints',
        'MaxItemPoints',
        'PercentItemPoints',
        'ReferenceTimestamp'
      ],
      expand: [
        'Location',
        'LoginUser',
        'CreateUser'
      ],
      filter: {
        CompanyId: this.companyRepository.getActiveCompanyId()
      },
      orderBy: 'CreateDate desc',
      top: 5
    }

    return this.api.get(query) as unknown as Observable<CheckResult[]>;
  }


  getResultDetails(checklistDataId: number) {
    const query = {
      key: checklistDataId,
      expand: [
        'LoginUser',
        'Location',
        'CreateUser'
      ]
    }
    return (this.api.get(query) as unknown as Observable<CheckResult>).pipe(
      map(result => {
        if (result.ReportJson) {
          result.report = JSON.parse(result.ReportJson)[0]
        }
        return result;
      })
    );
  }

  getResultTasks(checklistDataId: number) {
    const query = {
      key: checklistDataId,
      action: 'Default.GetTasks'
    }
    return this.api.post(query) as unknown as Observable<CheckResultItemDataTask[]>;
  }

  getResultPdf(checklistDataId: number) {
    const query = {
      key: checklistDataId,
      action: 'Default.GetDocument'
    }
    return this.api.post(query).pipe(
      map(response => (response as unknown as { value: string }).value)
    );
  }

  getExcelExportSearchResult(params: ResultsExcelExportParams) {
    const query = {
      action: 'ExcelExportSearchResult',
      body: params
    };
    return this.api.post(query).pipe(
      map(response => (response as unknown as { value: string }).value)
    );
  }

  getResultPage(params: UiPaginatorPageRequest<GetResultPageRequest>): Observable<UiPaginationResponse<CheckResult>> {
    const query = {
      count: true,
      top: params.perPage,
      skip: params.perPage * (params.page - 1),
      select: [
        'Id',
        'CompanyId',
        'LoginUserId',
        'CreateUserId',
        'ChecklistId',
        'CreateDate',
        'LocationId',
        'ItemPoints',
        'MaxItemPoints',
        'PercentItemPoints',
        'ReferenceTimestamp'
      ],
      expand: [
        'Location',
        'LoginUser',
        'CreateUser'
      ],
      orderBy: 'ReferenceTimestamp desc',
      filter: []
    };

    const af = params.filter.advanced;

    query.filter.push({ CompanyId: this.companyRepository.getActiveCompanyId() });

    const searchTerm = params.filter.searchTerm;
    if (searchTerm) {
      const keywordFilter =
        removeSpecialCharacters(deUmlaut(searchTerm.trim()))
          .split(' ')//split by space
          .filter((elem, index, self) => index === self.indexOf(elem)) //unique kw
          .map(keyword => `contains(Keywords, '${keyword}')`);

      query.filter.push({
        and: keywordFilter
      });
    }
    if (af.StartDate && af.EndDate) {
      query.filter.push({
        ReferenceTimestamp: {
          gt: dayjs(af.StartDate).toDate(),
          lt: dayjs(af.EndDate).endOf('day').toDate()
        }
      });
    }
    if (af.LocationId) {
      query.filter.push({ LocationId: af.LocationId });
    }
    if (af.CreateUserId) {
      query.filter.push({ CreateUserId: af.CreateUserId });
    }
    if (af.LoginUserId) {
      query.filter.push({ LoginUserId: af.LoginUserId });
    }
    if (af.ChecklistId) {
      query.filter.push({ ChecklistId: af.ChecklistId });
    }
    if (af.Tag) {
      query.filter.push(`Tags/any(tag: tag eq '${af.Tag}')`);
    }
    return (this.api.get(query) as unknown as Observable<{ data: CheckResult[], count: number }>).pipe(
      map(res => {
        return {
          currentPage: params.page,
          perPage: params.perPage,
          total: res.count,
          data: res.data
        };
      })
    );
  }

  private create(checklistData: CheckChecklistData, skipAuth = false) {
    clearChecklistDataViewProperties(checklistData);
    const query = {
      body: checklistData,
      skipAuth: skipAuth
    };
    return this.api.post(query);
  }

  private addServiceWorkerBypassParam(result: Observable<any>) {
    return result.pipe(
      map(storageLink => ({
        ...storageLink,
        ContainerUriWithSasToken: storageLink.ContainerUriWithSasToken + '&ngsw-bypass='
      }))
    );
  }
}
