export default {
  barcodeScanner: {
    permissionHeader: 'Berechtigung',
    permissionMessage: 'Wenn Sie die Berechtigung zur Verwendung Ihrer Kamera erteilen möchten, aktivieren Sie diese in den App-Einstellungen.',
    scanPrompt: '{{thing}} scannen',
    enterPrompt: '{{thing}} eingeben',
    blinkId: 'Blink-ID',
    isNoBlinkId: 'Der gescannte QR-Code ist keine gültige Blink-ID',
    objectCode: 'Objekt Code',
  },
  camera: {
    promptLabelHeader: 'Quelle wählen',
    promptLabelPicture: 'Foto aufnehmen',
    promptLabelPhoto: 'Aus Galerie auswählen'
  },
  network: {
    changed: {
      connected: 'Internetverbindung hergestellt',
      disconnected: 'Internetverbindung unterbrochen'
    }
  },
  file: {
    downloadStarted: 'Download wurde gestartet.'
  },
  nfc: {
    wrongCode: 'Der gescannte QR-Code ist kein Objekt-Code!',
    error: 'NFC Fehler',
  }
}
