/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum BlinkIcon {
  faStyle = 'fal',

  Home = 'fa-home',
  Profile = 'fa-user',
  Friends = 'fa-user-friends',
  Sticker = 'fa-face-smile-hearts',
  User = 'fa-user',
  UserEdit = 'fa-user-edit',
  Checklist = 'fa-list-check',
  Dependencies = 'fa-project-diagram',
  Configuration = 'fa-cog',
  Document = 'fa-file-alt',
  QrCode = 'fa-qrcode',
  Tickets = 'fa-clipboard-list',
  Chat = 'fa-comments',
  TimeSheet = 'fa-calendar-day',
  Clock = 'fa-clock',

  InputElements = 'fa-inbox-in',
  InfoElements = 'fa-info',


  Create = 'fa-plus text-success',
  CreateStyleless = 'fa-plus',
  ChevronDown = 'fa-chevron-down',
  ChevronUp = 'fa-chevron-up',
  ChevronLeft = 'fa-chevron-left',
  CaretDown = 'fa-caret-down',

  Close = 'fa-times',
  X = 'fa-times',

  Info = 'fa-circle-info',
  Help = 'fa-circle-question',

  TransitionForward = 'fa-angle-double-right',
  TransitionBackward = 'fa-angle-double-left',
  // CreateWorklog = 'fa-alarm-plus',
  // Approve = 'fa-check',
  // ApproveWorklog = 'fa-check',
  // RevertWorklogToNew = 'fa-undo',
  // BookWorklog = 'fa-check-double',
  // ExportWorklog = 'fa-download',


  Edit = 'fa-pen',
  Save = 'fa-save',
  Duplicate = 'fa-clone',
  Delete = 'fa-trash-alt text-danger',
  Details = 'fa-list',
  Sort = 'fa-arrows',
  Move = 'fa-share-square',
  EllipsisVertical = 'fa-ellipsis-v-alt',
  EllipsisHorizontal = 'fa-ellipsis',
  Link = 'fa-link',
  List = 'fa-list-ul',
  Filter = 'fa-filter-list',

  Import = 'fa-file-import',
  Export = 'fa-file-export',

  Comments = 'fa-comment-alt-lines',
  Camera = 'fa-camera',
  Alarm = 'fa-exclamation-triangle',
  LocationActivity = 'fa-clipboard-check',
  Absence = 'fa-calendar-times',
  Representative = 'fa-people-arrows',
  ChangeDate = 'fa-calendar-alt',
  SplitWorklog = 'fa-cut',
  SetToPlannedDuration = 'fa-redo',

  Company = 'fa-briefcase',
  Location = 'fa-building',
  EmployeeInLocation = 'fa-building-user',
  Email = 'fa-mail-bulk',
  Permissions = 'fa-shield-alt',
  Success = 'fa-comment-alt-smile',

  Offline = 'fa-wifi-slash',

  Vacation = 'fa-umbrella-beach',

  Meh = 'fa-meh',
  Back = 'fa-arrow-left',
  Forward = 'fa-arrow-right',

  Translate = 'fa-language',

  Tasks = 'fa-clipboard-list',
  Protocol = 'fa-clipboard-list',
  Worklog = 'fa-calendar-check',
  Planning = 'fa-calendar-day',

  Download = 'fa-file-export',
  Copy = 'fa-clone',
  Reset = 'fa-redo',

  EmployeeHints = 'fa-message-exclamation',
  Address = 'fa-location-dot',
  Employee = 'fa-user',
  Employees = 'fa-user-friends',
  BaseData = 'fa-database',
  Organisation = 'fa-sitemap',
  Templates = 'fa-pencil-ruler',
  CustomAction = 'fa-chart-bar',
  Area = 'fa-send-backward',
  Shift = 'fa-layer-group',
  WorklogsAndAbsences = 'fa-clock',
  Orders = 'fa-cart-shopping',
  LocationPlanningEntries = 'fa-clipboard-list', // Kontrollvorgaben

  Deactivate = 'fa-circle-xmark',
  Activate = 'fa-circle-check',
  Impersonate = 'fa-person',

  Phone = 'fa-phone-alt',
  Terminal = 'fa-mobile-notch',
  Images = 'fa-images',
  Folder = 'fa-folder-open',
  Csv = 'fa-file-csv',
  Pdf = 'fa-file-pdf',
  Machines = 'fa-vacuum',
  Empty = 'fa-telescope',

  Check = 'fa-check',
  Calendar = 'fa-calendar',

  IdCard = 'fa-id-card',
  Licence = 'fa-key',

  AlphaUp = 'fa-sort-alpha-up',
  AlphaDown = 'fa-sort-alpha-down',
  NumericUp = 'fa-sort-numeric-up',
  NumericDown = 'fa-sort-numeric-down',
  AmountUp = 'fa-sort-amount-up',
  AmountDown = 'fa-sort-amount-down',

  Refresh = 'fa-sync',
  News = 'fa-bell',
  CloudUpload = 'fa-cloud-arrow-up',
  Break = 'fa-mug-hot',
  Stop = 'fa-circle-stop',
  Play = 'fa-circle-play',
  ELearning = 'fa-chalkboard-user',
  FaceParty = 'face-party',
  QuickActions = 'fa-ellipsis-vertical',
  Eye = 'fa-eye',
  Attachment = 'fa-paperclip',
  Comment = 'fa-message-lines',
  DownloadAttachment = 'fa-download',
  Execute = 'fa-play'
}
