import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { BlinkUiModule } from '@blink/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ChecklistPropertyPipe } from './check';
import { HasPermissionPipe } from './core/permissions/hasPermission.pipe';

@NgModule({
  declarations: [
    ChecklistPropertyPipe,
    HasPermissionPipe,
  ],
  exports: [ChecklistPropertyPipe, HasPermissionPipe],
  imports: [CommonModule, IonicModule, BlinkUiModule, TranslateModule],
  // exports: [StoreModule]
})
export class ApiModule {}
