import { createStore, select, setProp, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  selectAllEntities,
  selectEntity,
  selectMany,
  updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CheckItem } from './item';
import { CheckItemDependencyRepository } from '../item-dependency/item-dependency.repository';
import { CheckItemValueRepository } from '../item-value/item-value.repository';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';
import { PersistStateService } from '../../shared';
import { CheckChecklistItemData } from '../checklist-data/types/checklist-data';
import { CheckItemType } from './item-type';


interface ItemRepositoryProps {
  checklistItemIdMap: IdMap;
  groupItemIdMap: IdMap;
  emailHistory: { [x: number]: string[] };
}

const store = createStore(
  checkStoreConfig('items'),
  withEntities<CheckItem, 'Id'>({ idKey: 'Id' }),
  withProps<ItemRepositoryProps>({
    checklistItemIdMap: {},
    groupItemIdMap: {},
    emailHistory: {}
  })
);

@Injectable({ providedIn: 'root' })
export class CheckItemRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();
  items$ = store.pipe(selectAllEntities());

  constructor(private itemValueRepo: CheckItemValueRepository,
              private itemDependencyRepo: CheckItemDependencyRepository,
              private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(items: CheckItem[], isCompleteSync: boolean, removeAbsentForChecklistId: number = null) {
    const deleteItemIds = store
      .getValue()
      .checklistItemIdMap[removeAbsentForChecklistId];

    store.update(
      deleteEntities(deleteItemIds),
      upsertEntities(items),
      idMapper('checklistItemIdMap', items, 'ChecklistId', isCompleteSync),
      idMapper('groupItemIdMap', items, 'GroupId', isCompleteSync)
    );
  }

  delete(id) {
    store.update(
      deleteEntities(id),
      removeMappedId('checklistItemIdMap', id),
      removeMappedId('groupItemIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistItemIdMap = store.getValue().checklistItemIdMap;
    let deleteItemIds = [];
    checklistIds.forEach(x => {
      deleteItemIds = deleteItemIds.concat(checklistItemIdMap[x]);
    });
    store.update(
      deleteEntities(deleteItemIds),
      removeKeys('checklistItemIdMap', checklistIds)
    );
  }

  saveEmailHistory(itemDatas: CheckChecklistItemData[]) {
    store.pipe(
      selectMany(itemDatas.map(x => x.ItemId)),
      take(1)
    ).subscribe(items => {
      const emailItems = items.filter(x => x.ItemType === CheckItemType.Email || x.ItemType === CheckItemType.SendEmailTo);
      const emailHistoryState = store.getValue().emailHistory;
      emailItems.forEach(x => {
        let emailHistory = emailHistoryState[x.Id];
        if (!emailHistory) {
          emailHistory = [];
        }
        const emailAddress = itemDatas.find(y => y.ItemId === x.Id).ItemData;
        if (!!emailAddress && !emailHistory.includes(emailAddress)) {
          emailHistory.push(emailAddress);
        }
        if (emailHistory.length > 5) {
          emailHistory = emailHistory.splice(emailHistory.length - 5);
        }
        emailHistoryState[x.Id] = emailHistory;
      });
      store.update(setProp('emailHistory', { ...emailHistoryState }));
    });
  }

  get(itemId: number) {
    return store.pipe(selectEntity(itemId));
  }

  getSync(itemId: number) {
    return store.getValue().entities[itemId];
  }

  getChecklistItemIdsSync(checklistId: number) {
    return store.getValue().checklistItemIdMap[checklistId];
  }

  getEmailHistory() {
    return store.pipe(select(state => state.emailHistory));
  }

  getFull(itemId: number) {
    return combineLatest([
      this.get(itemId),
      this.itemValueRepo.getItemItemValues(itemId),
      this.itemDependencyRepo.getItemDependencies(itemId)
    ]).pipe(
      map(([
             item,
             itemValues,
             itemDependencies
           ]) =>
        ({ ...item, itemValues, itemDependencies })
      ));
  }

  getMany(itemIds: number[]): Observable<CheckItem[]> {
    if (itemIds) {
      return store.pipe(selectMany(itemIds));
    }
    return of([]);
  }


  getGroupItemIdsForFill(groupId: number): Observable<number[]> {
    return store.pipe(
      select(state => state.groupItemIdMap[groupId]),
      map(itemIds => itemIds ? itemIds : [])
    );
  }

  getChecklistItemIds(id: number): Observable<number[]> {
    return store.pipe(
      select(state => state.checklistItemIdMap[id] || [])
    );
  }


  resetItemsForChecklist(checklistId: number) {
    store.update(
      updateEntitiesByPredicate(
        item => item.ChecklistId === checklistId,
        { fillContainerUriWithSasToken: undefined }
      )
    );
  }
}

