export default {
  home: {
    pageTitle: 'Mes formulaires',
    locationChecklists_label: 'Remplir un formulaire pour un objet',
    generalChecklists_label: 'Remplir le formulaire général',
    processingChecklists_label: 'Formulaire en cours',
    checklistDatas_label: 'Formulaires remplis',
    selectLocation: 'Sélectionner un objet',
    scanQrCode: 'Scanner le code objet',
    showAll: '...Afficher tout',
    showLess: '... Montrer moins'
  },
  fill: {
    checklists: 'Formes',
    fillChecklist: 'remplir le formulaire',
    checklistNotFound: 'Malheureusement, le formulaire que vous avez demandé est introuvable.',
    checklistOfflineLoginUserRequired: "Ce formulaire nécessite la sélection d'un employé. Veuillez établir une connexion Internet pour accéder au modèle de formulaire.",
    checklistNotOfflineAvailable: "Le modèle de formulaire n'est pas enregistré hors ligne, veuillez vous connecter à Internet pour accéder au modèle de formulaire.",
    resetConfirm: 'Voulez-vous vraiment réinitialiser le formulaire ?',
    categoryAppeared: 'La catégorie "{{categoryName}}" a été ajoutée.',
    groupAppeared: 'La page "{{groupName}}" a été ajoutée.',
    itemAppearedInGroup: 'De nouvelles questions ont été ajoutées à la page "{{groupName}}".',
    openGroupsAvailable: 'groupes ouvertsdisponibles',
    toNextGroup: 'Passons à la question suivante',
    containerUriExpired: 'Malheureusement, la période de téléchargement a expiré.',
    finishMessage: 'Vous avez saisi toutes les données nécessaires et pouvez maintenant remplir le formulaire.',
    offlineMessage: 'Veuillez vous connecter à Internet pour remplir le formulaire.',
    offlinePreviewMessage: 'Veuillez vous connecter à Internet pour générer un aperçu.',
    checklistUpdatedHeader: 'Modèle de formulaire modifié',
    checklistUpdatedMessage: 'Le modèle de formulaire a été modifié. Veuillez vérifier à nouveau vos entrées avant de soumettre le formulaire.',
    checklistLocationRequiredHeader: 'Sélectionner un objet',
    checklistLocationRequiredMessage: 'Vous devez sélectionner un objet pour remplir ce formulaire.',
    finishSuccess: 'Le formulaire a été complété avec succès et toutes les données ont été traitées.',
    finishError: "Le formulaire n'a pas pu être complété en raison d'une erreur. Veuillez contacter l'assistance.",
    createdWith: 'Ce formulaire a été créé avec',
    preview: 'Aperçu',
    fileUploadFileMissingHeader: 'Fichiers introuvables',
    fileUploadFileMissingMessage: "Les fichiers suivants ont été sélectionnés mais sont introuvables sur l'appareil : {{ missingFiles }}"
  },
  item: {
    boolean: { yes: 'Oui', no: 'Non' },
    scan: 'analyse',
    email: { invalidEmail: 'Veuillez vérifier votre entrée.' },
    takePicture: { picture: 'Image', buttonText: 'ajouter une photo' },
    createTask: {
      taskTitle: 'Titre du billet',
      taskDescription: 'Description du billet',
      taskDueDate: "Date d'échéance du billet"
    }
  },
  noChecklistsForLocationFound: "Il n'existe aucun formulaire pour cet objet. Veuillez sélectionner une autre propriété ou remplir un formulaire général.",
  selectChecklistByLocation: "Sélectionner le formulaire pour l'objet"
};
