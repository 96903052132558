import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ErrorHandlerService } from "./error-handler.service";
import { isJson } from "../../utils";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class HttpClientService {

  constructor(private http: HttpClient,
              private errorHandlerService: ErrorHandlerService) {
  }

  get(url: string) {
    return this.request("GET", url);
  }

  post(url: string, data: any) {
    return this.request("POST", url, data);
  }

  patch(url: string, data: any) {
    return this.request("PATCH", url, data);
  }

  put(url: string, data: any) {
    return this.request("PUT", url, data);
  }

  del(url: string) {
    return this.request("DELETE", url, {
      responseType: "text"
    });
  }

  private request(action: string, url: string, data?: any) {
    this.requestStart();
    let request: any;

    switch (action) {
      case "GET":
        request = this.http.get(url);
        break;
      case "POST":
        request = this.http.post(url, JSON.stringify(data));
        break;
      case "PUT":
        request = this.http.put(url, JSON.stringify(data));
        break;
      case "DELETE":
        request = this.http.delete(url);
        break;
      case "PATCH":
        request = this.http.patch(url, JSON.stringify(data));
        break;
    }

    return request.pipe(map((res: HttpResponse<any>) => {
      //logger.log('FETCH API', url, res);
      if (res && res.body) {
        return res.body;
      } else {
        return res;
      }
    }))
      .toPromise()
      .then((response: any) => {
        response = isJson(response) ? JSON.parse(response) : response;
        this.requestStop();

        if ((response || !isNaN(response)) && (response !== null && !response.error)) {
          if (response["@odata.count"]) {
            return response;
          } else {
            return response.value ? response.value : response;
          }
        }
      })
      .catch((initialError: any) => {
        this.handleError(initialError);

        if (initialError && initialError.status === 408) {
          setTimeout(() => {
            return this.request(action, url, data);
          }, 500);
        }

        throw initialError;
      });
  }

  private handleError(err: any) {
    // this.store.dispatch({type: APP_ACTIONS.RESET});

    this.errorHandlerService.handleError(err);
    switch (err.status) {
      case 0:
        // this.store.dispatch({type: APP_ACTIONS.SET_ERROR, payload: 100});
        break;
      case 401:
        //this.localStorage.removeItem('auth_token');
        // this.events.publish('error:401');
        break;
      case 400:
        break;
      case 404:
        // this.store.dispatch({type: APP_ACTIONS.SET_ERROR, payload: 404});
        break;
      case 403:
        // this.store.dispatch({type: APP_ACTIONS.SET_ERROR, payload: 403});
        break;
      case 500:
        // this.store.dispatch({type: APP_ACTIONS.SET_ERROR, payload: 500});
        break;
      default:
        break;
    }
  }

  private requestStart() {
    // this.store.dispatch({type: APP_ACTIONS.INCREMENT});
  }

  private requestStop() {
    // this.store.dispatch({type: APP_ACTIONS.DECREMENT});
  }
}
