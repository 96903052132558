import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { InfoType } from '../../../enums/info-type';

interface UiDisplayTitleProps extends FormlyFieldProps {
  infoType: keyof typeof InfoType;
}

@Component({
  selector: 'ui-formly-info',
  template: `
    <h5 class="mt-0">{{ props.label }}</h5>`
})
export class UiFormlyDisplayTitleFieldTypeComponent extends FieldType<FieldTypeConfig<UiDisplayTitleProps>> {
}
