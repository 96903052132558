import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SessionRepository } from '../core';
import { firstValueFrom } from 'rxjs';


export const authGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const sessionRepository = inject(SessionRepository);
  const router = inject(Router);

 // console.log('authGuard', await firstValueFrom(sessionRepository.sessionReady$));
  if (sessionRepository.getAuthToken()) {
    return true;
  }

  // Redirect to the login page
  await router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  return false;
};

