<ion-list lines="none">
  <ion-item button="" (click)="popoverController.dismiss(null, 'duplicate')" *ngIf="showDuplicateButton">
    <ui-icon slot="start" [icon]="BlinkIcon.Duplicate" [fixedWidth]="true"></ui-icon>
    {{ t.duplicate | translate }}
  </ion-item>
  <ion-item button="" (click)="popoverController.dismiss(null, 'export')" *ngIf="showExportButton">
    <ui-icon slot="start" [icon]="BlinkIcon.Export" [fixedWidth]="true"></ui-icon>
    {{ t.export | translate }}
  </ion-item>
  <ion-item button="" (click)="popoverController.dismiss(null, 'copyPublicLink')" *ngIf="showCopyPublicLinkButton">
    <ui-icon slot="start" [icon]="BlinkIcon.Link" [fixedWidth]="true"></ui-icon>
    {{ t.copyLink | translate }}
  </ion-item>
</ion-list>
