import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CameraService } from '@blink/capacitor-plugins';
import { BlinkIcon } from '@blink/ui';
import { FillChecklistTranslateService } from '@blink/check/shared/services/fill-checklist';

@Component({
  selector: 'blink-image-select',
  templateUrl: './blink-image-select.component.html',
  styleUrls: ['./blink-image-select.component.scss'],
})
export class BlinkImageSelectComponent {
  BlinkIcon = BlinkIcon

  @ViewChild('fileInput') fileInputObj;
  @Input() imageBase64;
  @Output() imageChanged = new EventEmitter<string>();


  isApp: boolean;

  constructor(public platform: Platform,
              public translate: TranslateService,
              public t: FillChecklistTranslateService,
              private cameraService: CameraService) {
    this.isApp = platform.is('hybrid');
  }

  takePicture() {
    this.cameraService.getPhotoAsBase64()
      .then(base64 => this.imageChanged.emit(base64));
  }

  deletePicture() {
    this.imageChanged.emit(null);
    this.fileInputObj.nativeElement.value = null;
  }
}
