import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';

import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckItem } from './item';
import { CheckItemRepository } from './item.repository';
import { CheckChecklistApi } from '../checklist';

@Injectable({
  providedIn: 'root'
})
export class CheckItemApi {
  private api = this.apiManager
    .createApiForEndpoint<CheckItem>(
      'odata/v1',
      'Items',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private itemRepo: CheckItemRepository,
              private checklistApi: CheckChecklistApi) {
  }

  create(item: Partial<CheckItem>) {
    return this.api.post({ body: item }).pipe(tap(
      newItem => this.itemRepo.sync([newItem], false)
    ));
  }

  duplicate(item: CheckItem) {
    const query = {
      key: item.Id,
      action: 'Duplicate'
    };
    return this.api.post(query)
      .pipe(
        switchMap(() => this.checklistApi.getFull(item.ChecklistId))
      );
  }

  update(item: Partial<CheckItem>) {
    const query = {
      key: item.Id,
      oDataReturnRepresentation: true,
      body: item
    }

    return this.api.patch(query).pipe(
      tap((item: CheckItem) => {
        this.itemRepo.delete(item.Id); // relevant if item was moved
        this.itemRepo.sync([item], false);
      }
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.itemRepo.delete(id)
    ));
  }
}
