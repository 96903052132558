import { ChangeDetectorRef, Component, inject, NgZone, OnInit } from '@angular/core';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';

import { BlinkAuthService } from '../services/blinkApi/shared/blink-auth.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { filter, map, take } from 'rxjs/operators';
import { UpdateService } from '../services/shared/UpdateService';
import { BlinkIcon, MenuItem, uiCancelButton, UiDialogService, uiPrimaryButton } from '@blink/ui';
import { NetworkService } from '@blink/capacitor-plugins';
import { CheckTranslateService, GlobalTranslateService } from '@blink/util';
import { FillChecklistService, FillChecklistTranslateService } from '@blink/check/shared/services/fill-checklist';
import {
  AuthService,
  BlinkCompanyApi,
  BlinkUserContext,
  CheckChecklistApi,
  CheckChecklistDataRepository,
  CompanyRepository,
  SessionApi,
  SessionRepository
} from '@blink/api';
import { combineLatest, repeat } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import * as localforage from 'localforage';
import { App, App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { BlinkApps, BlinkCompany } from '@blink/shared-blink-types';
import { CompanyService } from '@blink/shared/feature/companies/main';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  userContext: BlinkUserContext = null;

  companies$ = this.companyRepository.companies$;
  selectedCompanyId$ = this.companyRepository.selectedCompanyId$;
  initialized = false;

  menuTabs: Array<MenuItem> = [
    {
      id: 'tab_home',
      label: this.t.global.home,
      icon: BlinkIcon.Home,
      routerLink: 'home'
    },
    {
      id: 'tab_profile',
      label: this.t.global.profile,
      icon: BlinkIcon.Profile,
      routerLink: 'help'
    }
  ];

  menuItems: Array<MenuItem> = [
    {
      id: 'home',
      label: this.fillCheckTranslate.fill.checklists,
      icon: BlinkIcon.Checklist,
      routerLink: 'home',
      children: [
        {
          id: 'results',
          label: this.t.results.results,
          icon: BlinkIcon.Document,
          routerLink: 'results'
        },
        {
          id: 'config',
          label: this.t.global.templates,
          icon: BlinkIcon.Checklist,
          routerLink: 'config'
        }
      ],
      open: true
    },
    {
      id: 'companyConfig',
      label: this.t.global.configuration,
      icon: BlinkIcon.Configuration,
      routerLink: 'companyConfig',
      hidden: true
    }
  ];

  devicePlatform: 'web' | 'ios' | 'android';
  debug = false

  localUrl: string = null;

  keyboardVisible = false;
  keyboardOpen = false;

  private companyApi = inject(BlinkCompanyApi);
  private companyService = inject(CompanyService);

  constructor(public router: Router,
              private platform: Platform,
              private menuController: MenuController,
              private uiDialogService: UiDialogService,
              private globalTranslate: GlobalTranslateService,
              public t: CheckTranslateService,
              private fillCheckTranslate: FillChecklistTranslateService,
              private blinkAuthService: BlinkAuthService,
              private authService: AuthService,
              private networkService: NetworkService,
              public updateService: UpdateService,
              private checklistApi: CheckChecklistApi,
              private checklistDataRepo: CheckChecklistDataRepository,
              private sessionRepository: SessionRepository,
              private sessionApi: SessionApi,
              public fillChecklistService: FillChecklistService,
              private navController: NavController,
              private modalController: ModalController,
              private zone: NgZone,
              private changeDetectorRef: ChangeDetectorRef,
              private companyRepository: CompanyRepository) {
    if (!environment.production) {
      this.debug = true;
      window['AppComponent'] = this;

      localforage.config({
        driver: localforage.INDEXEDDB,
        name: 'storageIDB',
        storeName: 'storage_table'
      });
      window['localforage'] = localforage
    }

    this.setupHardwareBackButton();
    this.addDeepLinkingListener();
    this.setupKeyboard();
  }

  get isOnline(): boolean {
    return this.networkService.connected;
  }

  ngOnInit(): void {
    this.devicePlatform = Capacitor.getPlatform() as any;
    this.menuController.enable(true, 'main-menu');

    this.sessionRepository.userContext$
      .pipe(
        filter(userContext => userContext != null)
      )
      .subscribe(async (userContext) => {
          this.userContext = userContext;
          this.makePermissionBasedMenuItemsVisible();

          if (!userContext.BlinkApps.some(app => app.InternalId === BlinkApps.BlinkCheck || app.InternalId === BlinkApps.BlinkCheckUnlimited)) {
            this.uiDialogService.alert(this.t.noCheckLicenseInfo, this.t.noCheckLicense, () => {
              this.blinkAuthService.logout();
              this.authService.logout();
            });
            return;
          }

          if (this.companyRepository.getActiveCompanyId() == null) {
            if (userContext['Company'] !== null) {
              this.companyRepository.setActiveCompany(userContext['Company'].Id);
            } else {
              await this.companyApi.fetchCompanies();
              await this.companyService.selectCompany();
            }
          }

          if (this.localUrl) {
            this.router.navigateByUrl(this.localUrl);
            this.localUrl = null;
          }
        }
      );

    combineLatest([
      this.networkService.status$,
      this.sessionRepository.isLoggedIn$,
      this.sessionRepository.system$,
      this.sessionRepository.sessionReady$])
      .subscribe(async ([isOnline, isLoggedIn, blinkSystem, sessionReady]) => {

        if (isOnline && isLoggedIn && blinkSystem !== null) {
          this.sessionApi.fetchUserContext(false)
            .pipe(
              repeat({ delay: 4 * 60 * 60 * 1000 })
            )
            .subscribe();
        }
      });

    this.networkService.status$
      .pipe(take(2))
      .subscribe((connectionStatus) => {
        if (connectionStatus) {
          combineLatest([
            this.sessionRepository.system$,
            this.companyRepository.selectedCompanyId$
          ]).pipe(
            map(([system, selectedCompanyId]) => {
              if (system && selectedCompanyId) {
                this.loadCompanyData();
              }
            })
          ).subscribe();
        }
      });
    this.setTranslation('de');
  }

  loadCompanyData() {
    this.sessionApi.fetchUserContext(false);
    void this.companyApi.fetchCompanies();

    // todo other companies checklists from stores
    this.checklistApi.getList(this.companyRepository.getActiveCompanyId());

    this.blinkAuthService.checkVersion();
  }

  async setTranslation(languageKey: string) {
    registerLocaleData(localeDe, languageKey);
    return this.globalTranslate.initialize(languageKey);
  }

  switchCompany(selectedCompany: BlinkCompany) {
    if (!selectedCompany) {
      return;
    }

    let currentCompanyId: number;
    this.selectedCompanyId$.subscribe(id => {
      currentCompanyId = id;
    }).unsubscribe();
    if (currentCompanyId === selectedCompany.Id) {
      return;
    }

    const switchCompanyHandler = () => {
      this.checklistDataRepo.deleteAll();
      this.companyService.onCompanySelected(selectedCompany);
    }

    this.checklistDataRepo.getCount().subscribe(count => {
      if (count > 0) {
        this.uiDialogService.confirm({
          title: this.t.switchCompanyDialogHeader,
          text: this.t.switchCompanyDialogText,
          buttons: [
            uiCancelButton(),
            uiPrimaryButton(
              this.t.global.proceed,
              () => switchCompanyHandler(),
              true
            )
          ]
        });
      } else {
        switchCompanyHandler();
      }
    }).unsubscribe();


  }

  private makePermissionBasedMenuItemsVisible() {
    const showConfigurationMenuItem: boolean = this.sessionRepository.getUserHasPermissions(['CanEditCompanies', 'CanEditSystemSettings']);
    if (showConfigurationMenuItem) {
      this.menuItems = this.menuItems.map(item => item.id == 'companyConfig' ? { ...item, hidden: false } : item);
    }
  }

  private addDeepLinkingListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        if (event.url.indexOf('.blink-check.de/#/') > -1) {
          const slug = event.url.split('.blink-check.de/#').pop();
          if (slug) {
            if (this.userContext) {
              this.router.navigateByUrl(slug);
            } else {
              this.localUrl = slug;
            }
          }
        }
      });
    });
  }

  private setupHardwareBackButton() {
    this.platform.backButton.subscribeWithPriority(1, async (processNextHandler) => {
      const topModal = await this.modalController.getTop();
      if (topModal) {
        topModal.dismiss();
      } else if (this.router.url.includes('/home')) {
        CapacitorApp.exitApp();
      } else {
        this.navController.pop();
      }
    });
  }

  private setupKeyboard() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidShow', () => {
        // console.log('keyboardDidShow', {isAlreadyOpen: this.keyboardVisible})
        if (Capacitor.getPlatform() === 'ios' && !this.keyboardOpen && document.activeElement) {
          setTimeout(() => {
            console.log('activeElement:', document.activeElement)
            document.activeElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 500);
        }
        this.keyboardOpen = true;
      });

      Keyboard.addListener('keyboardWillShow', () => {
        // console.log('keyboardWillShow')
        this.keyboardVisible = true;
        this.changeDetectorRef.detectChanges();
      });

      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboardWillHide')
        this.keyboardVisible = false;
        this.keyboardOpen = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  }
}
