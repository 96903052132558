import { createStore, select, setProp, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteEntitiesByPredicate,
  getActiveEntity,
  getAllEntities,
  resetActiveId,
  selectActiveEntity,
  selectEntitiesCountByPredicate,
  setActiveId,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { TimeTracking, TimeTrackingMethod, TimeTrackingStatus, TimeTrackingType } from './time-tracking';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import { PersistStateService } from '../../shared';
import { TimeTrackingContextRepository, TimeTrackingLocation } from '../time-tracking-context';
import { MeTranslateService } from '@blink/util';


export interface TimeTrackingProposedProps {
  start: TimeTracking,
  stop: TimeTracking,
  location: TimeTrackingLocation,
  duration: number,
  breakDuration: number,
  showConfirm: boolean
}

interface TimeTrackingRepoProps {
  syncInProgress: boolean;
  uiSyncInProgress: boolean;
  timeDifferenceInMinutesFromServer: number;
  testing: {
    allowQrCodeOnWeb: boolean
  },
  proposed: TimeTrackingProposedProps
}

const store = createStore(
  { name: 'time-tracking' },
  withActiveId(),
  withEntities<TimeTracking, 'Uuid'>({ idKey: 'Uuid' }),
  withProps<TimeTrackingRepoProps>({
    syncInProgress: false,
    uiSyncInProgress: false,
    timeDifferenceInMinutesFromServer: 0,
    testing: { allowQrCodeOnWeb: false },
    proposed: {
      start: null,
      stop: null,
      location: null,
      duration: 0,
      breakDuration: 0,
      showConfirm: false
    }
  })
);

@Injectable({ providedIn: 'root' })
export class TimeTrackingRepository {
  store = store;
  selectActive$: Observable<TimeTracking> = store.pipe(selectActiveEntity());
  syncInProgress$ = store.pipe(select(state => state.syncInProgress));
  uiSyncInProgress$ = store.pipe(select(state => state.uiSyncInProgress));
  isTesting$ = store.pipe(select(state => state.testing.allowQrCodeOnWeb));

  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();

  startTime$ = this.selectActive$.pipe(map(active => {
    return active ? active.Timestamp : null;
  }));

  divergentTime$ = store.pipe(
    select(state => state.timeDifferenceInMinutesFromServer),
    map(timeDifference => {
      return timeDifference > 15 || timeDifference < -15;
    }));

  activeLocationName$ = combineLatest([
    this.timeTrackingContextRepository.selectActive$,
    this.selectActive$,
    this.timeTrackingContextRepository.store.pipe(select(t => t.Locations))
  ]).pipe(
    map(([ttLocationQrCode, timeTracking, locations]) => {
      if (ttLocationQrCode) {
        const location = locations.find(t => t.Id === ttLocationQrCode.LocationId);
        return location?.Name;
      } else if (timeTracking) {
        if (timeTracking.LocationQrCode) {
          return this.t.timeTracking.noLocationFound
        } else {
          return this.t.timeTracking.noLocationScanned;
        }
      }

      return null;
    }));


  startTimer$ = combineLatest([
    this.selectActive$,
    timer(0, 1000)
  ]).pipe(
    map(([activeTimeTracking]) => {
      if (activeTimeTracking) {
        return dayjs()
          .startOf('day')
          .set('seconds', dayjs().diff(dayjs(activeTimeTracking.Timestamp), 'seconds'))
          .format('HH:mm:ss');
      }
      return null;
    }));

  startTimerHoursAndMinutes$ = combineLatest([
    this.selectActive$,
    timer(0, 1000)
  ]).pipe(
    map(([activeTimeTracking]) => {
      if (activeTimeTracking) {
        const now = dayjs().add(30, "seconds").startOf('minute');
        const timeStamp = dayjs(activeTimeTracking.Timestamp).add(30, "seconds").startOf('minute');
        const diff = dayjs().startOf('day').set('seconds', now.diff(timeStamp, 'seconds'));
        return {
          hours: diff.format('H'),
          minutes: diff.format('m')
        };
      }
      return null;
    }));

  failedTimeTrackingEventsCount$ = store.pipe(
    selectEntitiesCountByPredicate((e) =>
      e.SyncStatus === TimeTrackingStatus.Failed || e.SyncStatus === TimeTrackingStatus.Offline)
  );

  constructor(private persistStateService: PersistStateService,
              private timeTrackingContextRepository: TimeTrackingContextRepository,
              private t: MeTranslateService) {
    this.persistStateService.persistState(store, false).subscribe(() => {
      this.setSyncInProgress(false);
      this.setUiSyncInProgress(false);
      this.persistentStateReadySubject.next(true);
      this.removeOldTrackings();
    });
  }

  get getDifferenceInMinutes(): number {
    return store.getValue().timeDifferenceInMinutesFromServer;
  }

  getAllTrackingsForSync(): TimeTracking[] {
    return this.store.query(getAllEntities())
      .filter(t => t.SyncStatus !== TimeTrackingStatus.Successful)
      .sort((a, b) => dayjs(a.Timestamp).unix() - dayjs(b.Timestamp).unix())
      .slice(0, 50);
  }

  add(timeTracking: TimeTracking) {
    store.update(addEntities(timeTracking));
    if (timeTracking.Type === TimeTrackingType.Start) {
      this.setActive(timeTracking);
    } else {
      this.resetActive();
    }
  }

  addMultiple(timeTrackings: TimeTracking[]) {
    store.update(addEntities(timeTrackings));
    const timeTracking = timeTrackings.find(t => t.Type === TimeTrackingType.Start && t.TimeTrackingMethod !== TimeTrackingMethod.Proposed);
    if (timeTracking && timeTracking.Type === TimeTrackingType.Start) {
      this.setActive(timeTracking);
    } else {
      this.resetActive();
    }
  }


  setActive(timeTracking: TimeTracking) {
    store.update(setActiveId(timeTracking.Uuid));

    if (timeTracking.LocationQrCode) {
      this.timeTrackingContextRepository.store.update(setActiveId(timeTracking.LocationQrCode));
    } else {
      this.timeTrackingContextRepository.store.update(resetActiveId());
    }
  }


  update(timeTrackings: TimeTracking | TimeTracking[]) {
    store.update(upsertEntities(timeTrackings));
  }

  updateStatus(timeTrackingUuids: string[], status: TimeTrackingStatus) {
    store.update(updateEntities(timeTrackingUuids, { SyncStatus: status }));
  }

  removeOldTrackings() {
    store.update(deleteEntitiesByPredicate(
      ({
         SyncStatus,
         Timestamp
       }) => SyncStatus === TimeTrackingStatus.Successful && dayjs().diff(dayjs(Timestamp), 'days') >= 40));
  }

  resetActive() {
    store.update(resetActiveId());
    this.timeTrackingContextRepository.store.update(resetActiveId());
  }

  setSyncInProgress(sync: boolean) {
    store.update(setProp('syncInProgress', sync));
  }

  setUiSyncInProgress(sync: boolean) {
    store.update(setProp('uiSyncInProgress', sync));
  }

  getStart() {
    return store.query(getActiveEntity());
  }

  setDifferenceInMinutes(difference: number) {
    store.update(setProp('timeDifferenceInMinutesFromServer', difference));
  }

  toggleIsTesting() {
    store.update(setProp('testing', { allowQrCodeOnWeb: !store.getValue().testing.allowQrCodeOnWeb }));
  }

  getProposed(): TimeTrackingProposedProps {
    return store.query(state => state.proposed);
  }

  setProposed(TimeTrackingProposedProps: TimeTrackingProposedProps) {
    store.update(setProp('proposed', TimeTrackingProposedProps));
  }

  updateProposed(startTime: string, comment: string) {
    store.update(setProp('proposed', {
      ...store.getValue().proposed,
      start: {
        ...store.getValue().proposed.start,
        Timestamp: startTime,
        Comment: (store.getValue().proposed.start.Comment ? ', ' : '') + comment
      },
      stop: {
        ...store.getValue().proposed.stop,
        Comment: (store.getValue().proposed.stop.Comment ? ', ' : '') + comment
      }
    }));
  }

  clearProposed() {
    store.update(setProp('proposed', {
      start: null,
      stop: null,
      location: null,
      duration: 0,
      breakDuration: 0,
      showConfirm: false
    }));
  }
}
