import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UiLoadingService {
  private readLoadingSubject = new BehaviorSubject(false);
  readLoading$ = this.readLoadingSubject.asObservable()
    .pipe(
      distinctUntilChanged(),
      debounceTime(200)
    );
  private writeLoadingSubject = new BehaviorSubject(false);
  writeLoading$ = this.writeLoadingSubject.asObservable()
    .pipe(
      // tap(writeLoading => console.log({writeLoading})),
      distinctUntilChanged()
    );


  constructor() {
    window['loadingService'] = this;

    // this.writeLoadingSubject.next(true)
  }

  showReadLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null)
      .pipe(
        tap(() => this.setReadLoading(true)),
        concatMap(() => obs$),
        finalize(() => this.setReadLoading(false))
      );
  }

  showWriteLoaderUntilCompleted<T>(obs$: Observable<T>, showLoader = false): Observable<T> {
    // console.log('show write loader');
    return of(null)
      .pipe(
        tap(() => showLoader ? this.setReadLoading(true) : true),
        concatMap(() => obs$),
        finalize(() => showLoader ? this.setReadLoading(false) : true)
        // tap(() => this.setWriteLoading(true)),
        // concatMap(() => obs$)
        // finalize(() => this.setWriteLoading(false))
      );
  }

  setReadLoading(loading: boolean) {
    this.readLoadingSubject.next(loading);
  }

  setWriteLoading(loading: boolean) {
    this.writeLoadingSubject.next(loading);
  }
}
