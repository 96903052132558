export default {
  cancel: 'Прекъсване',
  blinkSlogan: 'Blink - Приложението за почистващи фирми за сгради',
  all: 'Всички',
  hello: 'Здравейте',
  description: 'Описание',
  ok: 'Добре',
  reset: 'Нулиране на',
  edit: 'Редактиране на',
  finish: 'Заключване',
  notice: 'Забележка',
  content: 'Съдържание',
  back: 'Обратно',
  close: 'Затвори',
  backToHome: 'Обратно към началната страница',
  proceed: 'Още',
  done: 'Готов',
  delete: 'Изтриване на',
  successful: 'Успешен',
  error: 'Грешка',
  ERROR_OCCURRED: 'Възникнала е грешка или данните не са верни. Моля, опитайте отново.',
  internetRequired: 'Изисква се интернет',
  configuration: 'Конфигурация',
  logo: 'Лого',
  uploadLogo: 'Качване на лого',
  deleteLogo: 'Изтриване на логото',
  duplicate: 'Дублиране на',
  importExport: 'Внос / износ',
  import: 'Внос',
  doImport: 'Внос',
  export: 'Експорт',
  doExport: 'Експорт',
  excelExport: 'Експорт на Excel',
  excelExportDownloaded: 'Изтегляне на експорт на Excel',
  excelExportError: 'Експортът на Excel е неуспешен',
  pdfDownload: 'Изтегляне на PDF',
  pdfDownloaded: 'Изтеглени PDF файлове',
  configSaved: 'Конфигурацията е запазена.',
  send: 'Изпрати',
  connecting: 'Свържете се...',
  or: 'или',
  select: 'Изберете',
  today: 'Днес',
  camera: 'Камера',
  gallery: 'Галерия',
  files: 'Файлове',
  upload: 'Качване на файлове',
  chooseFiles: 'Изберете файлове',
  filesTransferring: 'Предаване на файлове...',
  filesTransferringWait: 'Моля, изчакайте, докато файловете бъдат предадени.',
  filesTransferred: 'Файловете са успешно предадени.',
  download: 'Изтегляне',
  showInactive: 'Показване на неактивни',
  copyLink: 'Копиране на връзката',
  linkCopied: 'Връзката е копирана в клипборда.',
  permissions: 'Разрешения',
  email: 'Електронна поща',
  emailConfig: 'Конфигуриране на електронна поща',
  title: 'Заглавие',
  user: 'Потребител',
  logout: 'Излизане от системата',
  system: 'Система',
  appVersion: 'Версия (приложение)',
  apiVersionCore: 'Версия\n(Основен API)',
  apiVersionCheck: 'Версия\n(Проверете API)',
  deviceInfo: 'Информация за устройството',
  installBlinkTime: 'Инсталиране на времето за мигане',
  installBlinkMe: 'Инсталиране на Blink Me',
  installing: 'Версия {{version}} е инсталирана ...',
  companyRequired: 'Изберете клиент',
  companyRequiredInfo: 'Трябва да изберете клиент, за да продължите като администратор.',
  pleaseChoose: 'Моля, изберете',
  pleaseFill: 'Моля, попълнете',
  noEntitiesFound: 'Не бяха открити никакви елементи.',
  addCustom: 'Създаване на елемент:',
  typeToSearchText: 'Моля, въведете два или повече символа...',
  loading: 'Данните са заредени...',
  offline: 'Офлайн',
  youAreOffline: 'Вие сте офлайн!',
  by: 'от',
  date: 'Дата',
  time: 'Време',
  searchTitle: 'Търсене',
  searchTerm: 'Търсене на ключова дума',
  searchLoginUser: 'Търсене на служители',
  searchLoginUserPlaceholder: 'Търсене по име или фамилия',
  location: 'Обект',
  searchLocation: 'Обект за търсене',
  searchLocationPlaceholder: 'Търсене по име на обект, номер или тагове',
  deactivate: 'Деактивиране на',
  active: 'Активен',
  inactive: 'Неактивен',
  tag: 'Ден',
  tags: 'Етикети',
  color: 'Цвят',
  icon: 'Икона',
  language: 'Език',
  general: 'Обща информация',
  yes: 'Да',
  no: 'Не',
  searchThrough: 'Преглед на',
  name: 'Име',
  create: 'Създаване на',
  save: 'Запазете',
  home: 'Начало',
  profile: 'Профил',
  templates: 'Шаблони',
  noAssignment: 'Неназначен',
  sort: {
    change: 'Поръчка за промяна',
    save: 'Запазване на последователността',
    saved: 'Последователността е запазена.'
  },
  signature: {
    signature: 'Подпис',
    pleaseSign: 'Моля, подпишете се',
    clickHereToSign: 'Кликнете тук, за да подпишете',
    addSignature: 'Добавяне на подпис',
    save: 'Поемете управлението',
    delete: 'Изтриване на'
  },
  formError: {
    min: 'Стойността трябва да е по-голяма от {{value}}.',
    min2: 'Стойността трябва да е по-голяма или равна на {{value}}.',
    max: 'Стойността трябва да е по-малка или равна на {{value}}.',
    requiredBecause: 'Необходима стойност, защото {{name}} е попълнено.',
    isRequired: '{{name}} е задължително.',
    numberInUse: 'Номерът на персонала вече се използва.',
    maxlength: '{{name}} не трябва да съдържа повече от {{value}} символи.',
    email: 'Моля, въведете валиден имейл адрес.',
    faultyBlinkId: 'Дефектен идентификатор на мигане'
  },
  scan: {
    choose: 'Присвояване на нов идентификатор на мигане',
    text: 'Сканирайте QR кода върху регистрационната карта.',
    scan_now: 'Сканиране сега',
    enter_manually: 'Въведете ръчно'
  },
  removeImage: 'Изтриване на изображението',
  tickets: { assignee: 'Редактор', dueTo: 'Срок за плащане до' },
  ngSelect: {
    typeToSearch: 'Моля, въведете 3 или повече букви.',
    loading: 'данни за зареждане...',
    notFound: 'Не е намерено {{title}}.',
    clearAll: 'Нулиране на'
  },
  help: {
    pageTitle: 'Помощ',
    text1: 'Нашият екип за поддръжка с удоволствие ще отговори на всички ваши въпроси:',
    phone: 'Телефон',
    mail: 'Електронна поща',
    information: 'Информация',
    documentation: 'Онлайн документация',
    documentationText: 'В нашата онлайн документация ще намерите инструкции и видеоклипове за всички важни области на Blink.',
    support: 'Подкрепа'
  },
  REGISTRATION: {
    WELCOME: 'Добре дошли!',
    chooseMethod: 'Кой метод искате да използвате за регистрация?',
    withBlinkId: 'Карта за самоличност BLINK',
    withCredentials: 'Потребителско име / парола',
    REGISTER: 'Регистрация',
    ALREADY_REGISTERED: 'Имам акаунт в Blink.',
    INVALID_CARD: 'Картата не е валидна.',
    noBlinkId: 'Сканираният QR код не е валиден Blink ID',
    scanBlinkId: 'Сканиране на мигач ID',
    ERROR: 'Регистрацията не може да бъде завършена. Моля, въведете друг имейл адрес или мобилен номер.',
    NO_INTERNET_EXPLORER: 'За съжаление Blink не поддържа Internet Explorer. Моля, използвайте Microsoft Edge, Firefox или Chrome. Благодарим ви за разбирането.',
    loginNow: 'Регистрирайте се сега',
    help: 'Помощ при регистрация',
    helpText: 'Приложението Blink Manager ви позволява да управлявате времето, персонала и конфигурацията на системата. Blink Manager е предназначен само за ръководители, управители на имоти, мениджъри и администрация! За чисто записване на времето използвайте приложението "Blink Time"!<br/><br/>Има два метода, които можете да използвате за регистрация:<br/><br/><b>Извършване на регистрация с лична карта Blink</b><br/><br/>Ако имате лична карта Blink, тогава изберете този метод. Когато се регистрирате за първи път, ще бъдете помолени да посочите имейл адрес или номер на мобилен телефон. Той ни е необходим, за да ви изпратим еднократна парола за регистрация. Не е необходимо да помните еднократната парола. Когато се регистрирате отново, ще ви изпратим нова еднократна парола.<br/><br/><b>Влизане с потребителско име и парола</b><br/><br/><br/>Употребителското име и паролата ще получите само от системния си администратор при поискване. Този метод е достъпен само за потребители с административни права.<br/><br/><b>Поддръжка</b><br/><br/>Ако имате проблеми с влизането в системата, напишете имейл на support@blink-time.de или се обадете на 0911 240 330 0.',
    userOffline: 'Моля, свържете се с интернет, за да използвате Blink me.',
    login: 'Регистрирайте се сега',
    codeError: 'Моля, проверете заявката си и опитайте отново.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Поздравления!',
    YOU_DID_IT: 'Направили сте го.',
    SIGNED_IN: 'Регистриран',
    SIGNED_IN_TEXT: 'Успешно сте се регистрирали и вече можете да използвате приложението.',
    START_USAGE: 'Сега можете да използвате приложението.',
    START: 'Начало'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Изпратен ви е имейл, с който можете да промените данните си за достъп.',
    FORGOT_PASSWORD: 'Забравена парола?',
    errors: {
      notAllowed: 'За този потребител не може да бъде променена паролата.',
      invalidToken: 'Кодът за възстановяване на паролата ви е изтекъл. Моля, поискайте нов код.'
    },
    reset: {
      backToRoot: 'Обратно към страницата за вход',
      renewCode: 'Заявка за нов код',
      resetPassword: 'Възстановяване на паролата',
      success: 'Вашата парола е успешно променена.',
      password1: 'Новата ви парола',
      password2: 'Повтаряне на паролата',
      validationError: 'Паролата не отговаря на нашите указания за пароли!',
      passwordsNotEqual: 'Паролите не са идентични! Моля, проверете записа си.',
      passwordValidation: 'Паролата трябва да е дълга поне 6 символа и да съдържа една буква, една цифра и един специален символ.'
    },
    FORGOT_PASSWORD_TEXT: 'Моля, въведете вашия имейл адрес и целевата система, за да нулирате паролата си.',
    FORGOT_PASSWORD_PLACEHOLDER: 'електронна поща'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Проверка на данните',
    TEXT: 'Ако трябва да възстановим паролата ви, ще ни е необходим вашият имейл адрес и по желание мобилният ви номер.',
    EMAIL: 'Адрес на електронна поща',
    MOBILE: 'Мобилен номер'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Въведете код',
    EMAIL_TEXT: 'Въведете кода, който ви изпратихме на следния имейл адрес:',
    SMS_TEXT: 'Въведете кода, който ви изпратихме на следния мобилен номер:',
    NO_CODE: 'Не съм получил код.',
    CODE_LENGTH_ERROR: 'Въведеният код е по-дълъг от шест символа. Моля, въведете кода си отново.',
    CODE_SENT: 'Кодът е изпратен.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Няма код',
    TEXT1: 'Не сте получили код?',
    RESEND_CODE: 'Изпратете кода отново',
    TEXT2: 'Ако вашият адрес на електронна поща или мобилен номер, съхранявани в системата, са се променили, моля, свържете се с вашия администратор.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Моля, въведете своя Blink ID и код. Цялата информация ще намерите в регистрационната си карта.',
    textCredentials: 'Вашият системен администратор трябва да ви предостави потребителското име и паролата.',
    BLINK_ID_INVALID: 'Дефектен идентификатор на мигане',
    loginCardNotFound: 'Идентификаторът на мига е вече присвоен или не съществува.',
    WRONG_CREDENTIALS: 'Въведените данни не са верни. Моля, проверете въведените данни.',
    loginViaBlinkId: 'Регистрация с BlinkID',
    loginViaCredentials: 'Влизане с данни за достъп',
    CODE: 'Код',
    codePlaceholder: 'Вашият личен код за достъп',
    company: 'Система',
    password: 'Парола',
    FORGOT_PASSWORD: 'Забравих паролата си',
    username: 'електронна поща'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Идентификация',
    TEXT_1: 'За да можем да ви идентифицираме по уникален начин, ще ви изпратим еднократен код чрез SMS или имейл.',
    TEXT_2: 'Моля, въведете мобилния си номер или имейл адреса си за тази цел.'
  },
  update: {
    update: 'Актуализация',
    latestVersion: 'Налична е нова версия. Моля, актуализирайте приложението си, за да се възползвате напълно от нея.',
    warnVersion: 'Използвате остаряла версия на това приложение. Моля, актуализирайте я възможно най-скоро.',
    blockVersion: 'Използвате остаряла версия на това приложение, която вече не се поддържа. Моля, актуализирайте я сега.'
  },
  inProgress: 'В ход',
  video_offline: 'Видеоклиповете не могат да се гледат в офлайн режим, моля свържете се с интернет.',
  location_offline: 'Обектът не е запазен офлайн, моля свържете се с интернет, за да извлечете QR кода.',
  invalidObjectCode: 'Няма валиден обектен код.',
  invalidValue: 'невалидна стойност',
  ERROR: {
    ERROR_400: 'Възникна грешка. Моля, опитайте отново по-късно. (Код на грешка: 400)',
    ERROR_403: 'Нямате разрешение да извършите това действие',
    REFRESH_TOKEN_403: 'Вашите данни за достъп вече не са валидни. Моля, влезте отново!',
    ERROR_500: 'Възникна грешка. Моля, опитайте отново по-късно. (Код на грешка: 500)'
  },
  list: {
    entityPluralDefault: 'Резултати',
    emptyState: 'Не съществува {{entityPlural}}.',
    noResults: 'Не може да се намери {{entityPlural}}.',
    selectAll: 'Изберете всички {{entityPlural}}',
    massAction: 'Промоция за {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Изберете всички {{count}}',
    massActionDeSelectAllGlobal: 'Демаркирайте всички {{count}}'
  },
  languages: {
    ar: 'арабски',
    de: 'Немски',
    es: 'испански',
    fr: 'Френски',
    hr: 'хърватски',
    it: 'Италиански',
    pt: 'португалски',
    ru: 'Руски',
    bg: 'български',
    en: 'Английски',
    fa: 'персийски',
    el: 'Гръцки',
    hu: 'унгарски',
    pl: 'полски',
    ro: 'румънски',
    tr: 'Турски',
    uk: 'украински',
    cs: 'чешки',
    sk: 'словашки'
  },
  now: 'Сега',
  allCompanies: 'Всички клиенти',
  createDate: 'Дата на създаване',
  company: 'клиент',
  new: 'Нов',
  of: 'от',
  request: {
    deleteTitle: 'Изтриване на {{item}}',
    confirmDelete: 'Наистина ли искате да изтриете записа „{{itemName}}“?',
    itemAdded: '{{item}} е създаден.',
    itemAddedError: '{{item}} не можа да бъде създаден.',
    itemSaved: '{{item}} е запазено.',
    itemSavedError: '{{item}} не можа да бъде запазен.',
    itemRemoved: '{{item}} е изтрито.',
    itemRemovedError: '{{item}} не можа да бъде изтрито.'
  },
  news: { pageTitle: 'Новини', edit: 'Редактиране на новини' },
  profiles: {
    loginProfiles: 'Профили за влизане',
    add: 'Профил за вход',
    logout: 'Ако излезете, всички локални данни и профили ще бъдат изтрити! Искате ли да се отпишете?',
    login: 'Регистрирам',
    deleteConfirmation: 'Наистина ли искате да изтриете профила?',
    changed: 'Профилът е променен успешно',
    deleted: 'Профилът е изтрит успешно'
  },
  deleteAttachment: {
    confirmTitle: 'Изтриване на прикачения файл',
    confirmText: 'Сигурни ли сте, че искате да изтриете този прикачен файл?'
  },
  comments: {
    addComment: 'добави коментар',
    addCommentInfo: 'Коментарът е видим за всеки, който може да види този билет.',
    comment: 'коментар',
    deleteComment: 'Изтриване на коментар',
    deleteCommentConfirmation: 'Наистина ли искате да изтриете коментара?'
  }
};
