export default {
  home: {
    pageTitle: 'mis formularios',
    locationChecklists_label: 'Rellenar un formulario para un objeto',
    generalChecklists_label: 'Llenar formulario general',
    processingChecklists_label: 'formulario en progreso',
    checklistDatas_label: 'Formularios completados',
    selectLocation: 'Seleccionar objeto',
    scanQrCode: 'Escanear código objeto',
    showAll: '...mostrar todo',
    showLess: '... Muestra menos'
  },
  fill: {
    checklists: 'Formularios',
    fillChecklist: 'rellenar el formulario',
    checklistNotFound: 'Lamentablemente, no se pudo encontrar el formulario que solicitó.',
    checklistOfflineLoginUserRequired: 'Este formulario requiere la selección de un empleado. Establezca una conexión a Internet para acceder a la plantilla del formulario.',
    checklistNotOfflineAvailable: 'La plantilla de formulario no se guarda sin conexión; conéctese a Internet para acceder a la plantilla de formulario.',
    resetConfirm: '¿Realmente quieres restablecer el formulario?',
    categoryAppeared: 'Se ha agregado la categoría "{{categoryName}}".',
    groupAppeared: 'Se ha agregado la página "{{groupName}}".',
    itemAppearedInGroup: 'Se han agregado nuevas preguntas a la página "{{groupName}}".',
    openGroupsAvailable: 'openGroupsDisponible',
    toNextGroup: 'A la siguiente pregunta',
    containerUriExpired: 'Lamentablemente el período de carga ha expirado.',
    finishMessage: 'Ha introducido todos los datos necesarios y ahora puede completar el formulario.',
    offlineMessage: 'Por favor conéctese a Internet para completar el formulario.',
    offlinePreviewMessage: 'Conéctese a Internet para generar una vista previa.',
    checklistUpdatedHeader: 'Plantilla de formulario cambiada',
    checklistUpdatedMessage: 'La plantilla del formulario ha sido cambiada. Por favor revise sus entradas nuevamente antes de enviar el formulario.',
    checklistLocationRequiredHeader: 'Seleccionar objeto',
    checklistLocationRequiredMessage: 'Debe seleccionar un objeto para completar este formulario.',
    finishSuccess: 'El formulario se completó con éxito y se procesaron todos los datos.',
    finishError: 'El formulario no se pudo completar debido a un error. Por favor contacte al soporte.',
    createdWith: 'Este formulario fue creado con',
    preview: 'avance',
    fileUploadFileMissingHeader: 'Archivos no encontrados',
    fileUploadFileMissingMessage: 'Se han seleccionado los siguientes archivos pero no se pueden encontrar en el dispositivo: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Sí', no: 'No' },
    scan: 'escanear',
    email: { invalidEmail: 'Por favor revisa tu entrada.' },
    takePicture: { picture: 'Imagen', buttonText: 'agregar una foto' },
    createTask: {
      taskTitle: 'Título del billete',
      taskDescription: 'Descripción del billete',
      taskDueDate: 'Fecha de vencimiento del boleto'
    }
  },
  noChecklistsForLocationFound: 'No existe ningún formulario para este objeto. Por favor seleccione otra propiedad o complete un formulario general.',
  selectChecklistByLocation: 'Seleccionar formulario para objeto'
};
