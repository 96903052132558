import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthApi, LoginParams } from '@blink/api';
import { AuthModeEnum, GlobalTranslateService, logComponent } from '@blink/util';
import { LoginService } from '../../../login.service';

@Component({
  selector: 'blink-enter-verification-code-page',
  templateUrl: './enter-verification-code.html',
  styleUrls: ['./enter-verification-code.scss']
})
export class EnterVerificationCodeComponent {
  @ViewChild('codeInput') codeInputEl: HTMLIonInputElement;

  loginParams: LoginParams;
  codeSubject = new Subject<string>();

  constructor(public platform: Platform,
              public router: Router,
              public route: ActivatedRoute,
              public navCtrl: NavController,
              public authApi: AuthApi,
              public alertCtrl: AlertController,
              public toastCtrl: ToastController,
              private loginService: LoginService,
              public t: GlobalTranslateService,
              public translate: TranslateService) {
    logComponent(this);

    this.loginParams = this.loginService.loginParams;

    if (this.loginParams.auth) {
      if (this.loginParams.auth.type === 'email') {
        this.loginParams.authMode = AuthModeEnum.EMAIL;
        this.loginParams.email = this.loginParams.auth.value;
      } else {
        this.loginParams.authMode = AuthModeEnum.SMS;
        this.loginParams.phoneNumber = this.loginParams.auth.value;
      }
    }
  }

  ngAfterViewInit() {
    this.subscribeCodeInput();
  }

  subscribeCodeInput() {
    this.codeInputEl.value = '';
    this.focusCodeInput();

    this.codeSubject.pipe(
      filter(value => value.length === 6),
      take(1)
    ).toPromise().then(code => this.login(code));
  }

  focusCodeInput() {
    setTimeout(() => this.codeInputEl.setFocus());
  }


  login(code: string) {
    this.loginParams.code = code;
    return this.loginService.login(this.loginParams);
  }

  noCodeReceived() {
    this.loginService.showGotNoCode();
  }

  get showEmailText(): boolean {
    return this.loginParams.authMode === AuthModeEnum.EMAIL;
  }


  get displayAuthTarget() {
    if (this.loginParams.email) {
      return this.loginParams.email;
    } else if (this.loginParams.phoneNumber) {
      return this.loginParams.phoneNumber;
    }
    return '';
  }
}
