export default {
  config: {
    checklist: {
      checklist: 'Modèle de formulaire',
      checklists: 'Modèles de formulaire',
      none: 'Aucun formulaire disponible.',
      new: 'Nouveau modèle de formulaire',
      edit: 'Modifier le modèle de formulaire',
      saved: 'Le modèle de formulaire a été enregistré.',
      delete: 'Supprimer le modèle de formulaire',
      deleteChecklistResults: 'Supprimer également tous les formulaires remplis du modèle de formulaire {{name}} ?',
      deleteInfo: 'Si vous supprimez le modèle de formulaire, tous les formulaires complétés seront également irrévocablement supprimés ! <br><br>Pour conserver les formulaires complétés, désactivez le modèle de formulaire.',
      deleted: 'Le modèle de formulaire a été supprimé.',
      duplicate: 'Modèle de formulaire en double',
      duplicateInfo: 'Vous pouvez utiliser la fonction de duplication pour dupliquer des modèles de formulaire',
      duplicateMessage: 'Souhaitez-vous dupliquer le modèle de formulaire « {{checklistName}} » avec tout le contenu ?',
      duplicated: 'Le modèle de formulaire a été dupliqué.',
      templateName: 'Nom de forme',
      activeInfo: 'Seuls les modèles actifs peuvent être remplis ; les modèles inactifs ne peuvent être modifiés que dans la gestion des modèles.',
      locationRequired: 'La sélection des objets est obligatoire',
      locationRequiredInfo: "S'il est activé, un objet doit être sélectionné ou le code QR scanné afin de remplir le formulaire.",
      loginUserRequired: 'La sélection des employés est obligatoire',
      loginUserRequiredInfo: 'Si activé, un employé doit être sélectionné pour remplir le formulaire.',
      allLocationAllowed: 'Autoriser tous les objets',
      allowOnlyCertainLocations: 'Autoriser uniquement certains objets',
      public: 'Formulaire public',
      publicInfo: "Créez un lien public avec lequel le formulaire peut être rempli par n'importe quel utilisateur. Aucune connexion ni application n'est requise. Vous pouvez spécifier une date de validité jusqu'à laquelle le lien doit être valide.",
      preview: "Activer l'aperçu",
      previewInfo: "S'il est activé, un bouton « Aperçu » sera toujours affiché en bas du formulaire lors de son remplissage. Comme alternative au bouton d'aperçu permanent, vous pouvez également placer l'élément de contenu « Aperçu » n'importe où sur le formulaire (cette fonction n'a pas besoin d'être activée).",
      shortUrlExpireDate: "Date d'expiration",
      sysadminOnly: 'Administrateurs système uniquement',
      allUsers: 'Tous les utilisateurs',
      usersOfPermissionGroup: "Utilisateurs de ces groupes d'autorisation",
      emailConfig: 'Configuration de la messagerie',
      emailConfigInfo: 'Si vous entrez des adresses e-mail ici, les formulaires remplis seront automatiquement envoyés à ces adresses e-mail.',
      emailSaved: "L'adresse e-mail a été enregistrée",
      emailRemoved: "L'adresse e-mail a été supprimée",
      importExportInfo: "La fonction d'exportation vous permet d'exporter des modèles de formulaires et des modèles de rapports associés pour les réimporter dans un autre client ou système Blink Check.",
      import: "Modèle de formulaire d'importation",
      importInfo: 'Veuillez sélectionner le fichier .check-b64 du modèle de formulaire que vous souhaitez importer.',
      imported: 'Le formulaire a été créé avec succès.',
      templatesInAppFolder: "Modèles dans le répertoire de l'application :",
      invalidImport: "Le fichier sélectionné n'est pas un modèle de formulaire valide.",
      exported: 'Le modèle de formulaire a été exporté',
      offline: 'Veuillez établir une connexion Internet pour modifier les modèles de formulaire.',
      checklistContainsCreateTask: "Le modèle de formulaire contient l’élément « Créer un ticket » et ne peut donc pas être défini comme public. Veuillez supprimer l'élément « Créer un ticket »."
    },
    category: {
      category: 'catégorie',
      categories: 'Catégories',
      saved: 'La catégorie a été enregistrée.',
      editTitle: 'Modifier la catégorie',
      content: 'éléments',
      newCategory: 'Nouvelle catégorie',
      name: 'Nom de catégorie',
      edit: 'Modifier la catégorie',
      duplicated: 'La catégorie a été dupliquée.',
      noCategories: "Il n'y a pas encore de catégories. Créez de nouvelles catégories en utilisant le bouton « Ajouter une catégorie ».",
      inactive: 'inactif',
      showInactive: 'Afficher les inactifs',
      duplicateCategoryConfirmHeader: 'Catégorie en double',
      duplicateCategoryConfirm: 'Souhaitez-vous dupliquer la catégorie "{{categoryName}}" avec tout son contenu ?',
      delete: 'Supprimer la catégorie',
      deleteCategoryConfirm: 'Êtes-vous sûr de vouloir supprimer la catégorie "{{categoryName}}" ?',
      deleteSuccess: 'La catégorie a été supprimée.'
    },
    group: {
      group: 'Page',
      groups: 'pages',
      created: 'La page a été créée.',
      updated: 'Les modifications apportées à la page ont été enregistrées.',
      updateError: "La page n'a pas pu être enregistrée. Veuillez réessayer plus tard.",
      rename: 'Renommer la page',
      saved: 'La page a été enregistrée.',
      toggleSort: 'Changer le tri',
      editGroupName: 'Modifier le nom de la page',
      new: 'Nouvelle page',
      name: 'Nom de la page',
      duplicated: 'La page a été dupliquée.',
      noGroups: "Il n'y a pas encore de pages. Créez de nouvelles pages en utilisant le bouton « Ajouter une page ».",
      noItems: "Il n'y a pas encore d'éléments. Créez de nouveaux éléments en utilisant le bouton « Ajouter un élément ».",
      add: 'Ajouter une page',
      infoElement: "Élément d'information",
      inputElement: "élément d'entrée",
      addInfoElement: "Ajouter un élément d'information",
      addInputElement: "Ajouter un élément d'entrée",
      delete: 'Supprimer la page',
      deleteGroupConfirm: 'Êtes-vous sûr de vouloir supprimer la page "{{groupName}}" ?',
      chooseGroup: "À quelle page l'élément doit-il être ajouté ?",
      duplicateGroupConfirmHeader: 'page en double',
      duplicateGroupConfirm: 'Voulez-vous dupliquer la page "{{groupName}}" avec tout le contenu ?',
      deleteSuccess: 'La page a été supprimée.'
    },
    item: {
      add: 'Ajouter un élément',
      created: "L'élément a été créé",
      saved: "L'élément a été enregistré.",
      chooseItemType: 'sélectionner un article',
      values: 'Valeurs',
      value: 'Valeur',
      ratingNumber: 'Évaluation',
      addItemValue: 'Ajouter une nouvelle valeur',
      addItemValueSuccess: 'Une nouvelle valeur a été ajoutée',
      updateItemValueSuccess: 'La valeur a été mise à jour',
      removeItemValueSuccess: 'la valeur a été supprimée',
      removeItemValue: 'supprimer la valeur',
      takePictureButtonText: 'Sélectionner une image',
      editTitle: "Modifier l'élément",
      editDependenciesTitle: 'Modifier les dépendances',
      required: 'Champ obligatoire',
      name: "Nom de l'élément",
      description: "description facultative de l'élément",
      points: 'Points',
      duplicated: "L'élément a été dupliqué.",
      duplicateItemConfirmHeader: 'Élément en double',
      duplicateItemConfirm: 'Voulez-vous dupliquer l\'élément "{{itemName}}" avec tout son contenu?',
      delete: {
        title: "effacer l'article",
        message: "Voulez-vous vraiment supprimer l'élément « {{itemName}} » ?",
        success: "L'élément a été supprimé"
      },
      moveItem: "Déplacer l'élément",
      move: 'Se déplacer',
      moveItemInfo: "Veuillez sélectionner la catégorie et la page vers lesquelles l'élément actuel doit être déplacé.",
      itemMoved: "L'élément a été déplacé"
    },
    editItem: {
      name: 'Nom de famille',
      nameInfo: "Ce texte est affiché directement avant l'élément d'entrée.",
      link: 'Adresse du lien',
      linkInfo: 'Cette adresse sera accessible via un bouton. Veuillez saisir une URL valide.',
      description: 'Description',
      descriptionInfo: 'Si vous souhaitez fournir des instructions pour remplir le formulaire, vous pouvez les saisir ici.',
      linkDescription: 'Etiquette du bouton',
      linkDescriptionInfo: 'Entrez un nom pour le bouton.',
      values: 'Valeurs',
      valuesInfo: "Vous pouvez spécifier un numéro de notation pour chaque valeur. Vous pouvez effectuer des évaluations ultérieurement à l'aide du numéro d'évaluation. Le numéro de notation est facultatif.",
      value: 'Valeur',
      valuePlaceholder: 'Nouvelle valeur',
      ratingNumber: 'Évaluation',
      requiredField: 'Champ obligatoire',
      requiredFieldInfo: "S'il est activé comme champ obligatoire, alors ce champ doit être rempli pour que le formulaire puisse être soumis.",
      externalNo: 'Numéro externe',
      externalNoInfo: 'Informations facultatives pour les interfaces ou les évaluations via des systèmes externes.',
      editable: 'Modifiable',
      editableInfo: "Pour que les utilisateurs puissent effectuer des entrées, l'élément doit être modifiable.",
      defaultValue: 'Spécifier la valeur par défaut',
      defaultValueInfo: 'Vous pouvez spécifier une affectation standard pour cet élément.',
      defaultValueInfoEditable: "La valeur peut être modifiée par l'utilisateur du formulaire.",
      visible: 'visibilité',
      visibleInfo: "La visibilité contrôle si l'élément peut être vu ou non par l'éditeur.",
      externalNoAleradyUsed: 'Ce numéro externe est déjà utilisé.',
      taskTypeId: 'Type de billet',
      loginUserId: 'utilisateur',
      locationId: "Nom de l'objet",
      videoId: 'Numéro de vidéo',
      videoType: 'Service vidéo',
      videoTypes_youtube: 'Youtube',
      videoType_vimeo: 'Viméo',
      videoInfo: "Entrez ici l'identifiant vidéo unique. Vous pouvez le trouver, par exemple, dans l'URL de la vidéo. <br/>Exemple : https://www.youtube.com/watch?v=XXXXX ou sur Vimeo https://vimeo.com/XXXXX. <br/>Dans ce cas, XXXXX devra être saisi dans le champ.",
      defaultTaskTitle: 'Titre par défaut du ticket',
      defaultTaskDescription: 'Description par défaut du ticket',
      titleEditable: "Titre du ticket modifiable par l'utilisateur",
      descriptionEditable: "Description du ticket modifiable par l'utilisateur",
      dueDateEditable: "Date d'échéance du billet modifiable par l'utilisateur",
      addReportAttachment: 'Joindre le formulaire complété au billet au format PDF',
      noTaskTypesFound: "Aucun type de billet n'a pu être trouvé. Les types de tickets peuvent être gérés via Blink Active.",
      noLoginUsersFound: "Aucun utilisateur n'a pu être trouvé. Les utilisateurs peuvent être gérés via Blink Manager.",
      noLocationsFound: "Aucun objet n'a pu être trouvé. Les objets peuvent être gérés via le Blink Manager.",
      createTaskItemInfo: "Avec cette fonction, vous pouvez créer des tickets Blink Active. Vous pouvez spécifier le titre et la description ou demander à l'utilisateur de les remplir. L'objet est attribué automatiquement et le gestionnaire d'objets responsable est désigné comme éditeur.",
      duplicatedValuesNotAllowed: 'Les valeurs en double ne sont pas autorisées.',
      valueLengthExceeded: 'La valeur saisie est trop longue et sera raccourcie.'
    },
    itemTypes: {
      PreviewButton: 'Aperçu du document',
      Select: 'Sélection dans la liste',
      Signature: 'Signature',
      Email: 'Saisie par e-mail',
      SendEmailTo: 'Envoi par e-mail',
      Date: 'Date',
      Time: 'temps',
      Boolean: 'Question oui-non',
      TakePicture: 'prendre une photo',
      TakeMultiPicture: 'prendre des photos',
      Scan: 'Analyse Qr/EAN',
      Input: 'Saisie de texte',
      Textarea: 'Saisie de texte sur plusieurs lignes',
      Link: 'Mise en relation',
      Picture: 'Affichage des images',
      Video: 'Annonce vidéo',
      Text: 'Affichage du texte',
      Header: 'gros titre',
      Rate: 'Évaluation',
      RateSmiley: 'Évaluation des smileys',
      CreateTask: 'Créer un ticket',
      FileUpload: 'Télécharger des fichiers',
      Currency: 'devise'
    },
    itemTypeDescriptions: {
      PreviewButton: "Afficher une version d'aperçu du formulaire.",
      Select: 'Définissez tous les éléments de liste parmi lesquels un peut être sélectionné.',
      Signature: 'Champ pour signature numérique (peut être utilisé avec le doigt)',
      Email: "L'utilisateur peut fournir une adresse e-mail valide.",
      SendEmailTo: "L'utilisateur peut fournir une adresse e-mail valide à laquelle le formulaire complété sera envoyé au format PDF.",
      Date: "L'utilisateur peut spécifier une date via un calendrier",
      Time: "L'utilisateur peut spécifier une heure",
      Boolean: "L'utilisateur sélectionne oui ou non",
      TakePicture: "L'utilisateur peut prendre une photo avec l'appareil photo ou télécharger une image.",
      TakeMultiPicture: "L'utilisateur peut prendre plusieurs photos avec l'appareil photo ou télécharger des images.",
      Scan: 'Demander aux utilisateurs de scanner un code QR ou un EAN. Le résultat est affiché dans le formulaire et enregistré.',
      Input: 'Champ de saisie de texte sur une seule ligne',
      Textarea: 'Saisie de texte sur plusieurs lignes pour des commentaires plus longs',
      Link: 'Lien vers un site externe',
      Picture: 'Téléchargez une image qui sera affichée lors du remplissage.',
      Video: 'Téléchargez une vidéo qui sera affichée pendant que vous remplissez le formulaire.',
      Text: 'Ajoutez une mise en forme aux textes multilignes.',
      Header: 'Gros titre pour structurer le contenu',
      Rate: 'Note 5 étoiles',
      RateSmiley: 'Choix de 3 visages souriants (heureux, neutre, triste)',
      CreateTask: 'Un ticket est créé pour un utilisateur',
      FileUpload: "L'utilisateur peut joindre n'importe quel nombre de fichiers au formulaire.",
      Currency: "L'utilisateur peut spécifier un montant dans une devise."
    },
    template: {
      template: 'Modèle de rapport',
      templates: 'Modèles de rapport',
      name: 'Nom de famille',
      description: 'Description',
      customer: 'client',
      new: 'Nouveau modèle de rapport',
      edit: 'Modifier le modèle de rapport',
      create: 'Créer un modèle de rapport',
      public: 'Publique',
      active: 'Actif',
      deleteConfirm: 'Êtes-vous sûr de vouloir supprimer le modèle de rapport « {{templateName}} » ?',
      deleteSuccess: 'Le modèle de rapport a été supprimé',
      editor: 'Concepteur de modèles',
      notForMobile: "Le concepteur de modèles n'est pas optimisé pour les appareils mobiles. Veuillez utiliser Blink Check dans le navigateur pour cela.",
      noTemplates: "Il n'existe pas encore de modèles de rapport.",
      custom: 'Modèle individuel',
      defaultWithPoints: 'Modèle standard - avec points de pourcentage',
      default: 'Modèle standard - sans points de pourcentage',
      createTemplateInfo: "Vous ne pouvez modifier le modèle de rapport qu'après sa création."
    }
  },
  checklistPermission: {
    globalPermissionGroup: "Groupe d'autorisations globales",
    notAllowed: "Vous n'êtes pas autorisé à modifier les autorisations de ce modèle.",
    all: 'Le formulaire peut être rempli par tous les utilisateurs',
    groups: "Le formulaire ne peut être rempli que par les utilisateurs de ces groupes d'autorisation",
    noGroupLicence: 'La licence Blink Check pour ce groupe n’est pas activée.',
    overridingPermssion: "L'option « Paramètres système » dans le groupe d'autorisations « Administrateur système » donne automatiquement aux utilisateurs toutes les autorisations sur ce formulaire.",
    onlyLicensedPermissionGroups: "Seuls les groupes d'autorisations auxquels une licence Blink Check est attribuée sont affichés.",
    canUpdateChecklist: 'Modifier le modèle de formulaire',
    canFillChecklist: 'remplir le formulaire',
    canReadChecklistDataOwn: 'Afficher et évaluer les formulaires auto-remplis',
    canReadChecklistDataOwnEmployees: 'Consultez et évaluez tous les formulaires remplis par vos employés',
    canReadChecklistDataOwnLocations: 'Visualisez et évaluez tous les formulaires remplis de vos propres objets',
    canReadChecklistDataAll: 'Afficher et évaluer tous les formulaires remplis'
  },
  companyConfig: {
    companyConfig: 'Configuration du locataire',
    logoInfo: 'Apparaît dans l’en-tête des modèles de rapport standard.',
    footer: 'bas de page',
    footerInfo: 'Apparaît dans le pied de page des modèles de rapport standard.',
    offline: 'Veuillez établir une connexion Internet pour pouvoir modifier la configuration.'
  },
  switchCompanyDialogHeader: 'Changer de client',
  switchCompanyDialogText: 'Si vous changez de client, tous les formulaires en cours seront réinitialisés. Voulez-vous continuer?',
  results: {
    results: 'Évaluation',
    details: 'Détails',
    created: 'Créé',
    result: 'Résultat',
    noReportJsonExists: "Le rapport n'a pas encore été créé. Le rapport sera généré avec un certain retard, veuillez réessayer plus tard.",
    noResults: 'Il n’y a pas encore de formulaires remplis.',
    noResultsForDateFilter: "Il n'y a aucun formulaire complété pour la période sélectionnée.",
    periodFrom: 'Période de',
    periodTo: 'période écoulée',
    location: 'objet',
    createUser: 'Créateur',
    loginUser: 'Employés',
    checklist: 'modèle de formulaire',
    user: 'utilisateur',
    recentResults: 'Formulaires actuellement remplis',
    recent: 'Actuel',
    pointOf: '{{ itemPoints }} sur {{ maxItemPoints }} points',
    checklistCreatedByAt: 'Formulaire rempli par {{ firstName }} {{ lastName }} le {{ createDate }} à {{ createTime }} pm.',
    linkedTask: 'Billet lié : #{{ taskId }} {{ taskTitle }}',
    offline: 'Veuillez établir une connexion Internet afin de pouvoir évaluer les formulaires remplis.',
    delete: {
      title: 'Supprimer le formulaire complété',
      message: 'Voulez-vous vraiment supprimer le formulaire « {{checklistName}} » complété ?',
      success: 'Le formulaire complété a été supprimé'
    },
    editReferenceTimestamp: 'Modifier la date de référence',
    editReferenceTimestampDescription: "La date de référence détermine l'heure à laquelle le formulaire a été rempli. Cela peut différer de l’heure à laquelle le formulaire a été soumis."
  },
  dependencies: {
    dependency: 'Dépendance',
    addTitle: 'Veuillez sélectionner une valeur de comparaison',
    addMessage: "Veuillez indiquer la valeur qui doit être respectée pour que l'élément soit affiché.",
    addTitleMulti: 'Veuillez sélectionner des valeurs de comparaison',
    addMessageMulti: "Veuillez fournir les valeurs, dont l'une doit être remplie pour que l'élément s'affiche.",
    present: 'Disponible',
    notPresent: 'Indisponible',
    noResults: 'Aucune dépendance présente. De nouvelles dépendances peuvent être ajoutées via Add Dependency.',
    noAvailableItems: "Le formulaire contient trop peu d'éléments pour créer des dépendances. Veuillez d'abord ajouter d'autres éléments.",
    noAvailableItemsInGroup: "Il y a trop peu d'éléments sur la page pour créer des dépendances. Veuillez sélectionner une page contenant des éléments.",
    dependencyAdded: 'Une dépendance a été ajoutée.',
    dependencyRemoved: 'La dépendance a été supprimée.',
    dependencyInfo: "Une fois que vous avez ajouté une dépendance, cet élément ({{elementName}}) n'apparaîtra que si le ou les éléments dépendants ont été renseignés.",
    true: 'Oui',
    false: 'Non',
    '#not_empty#': 'Disponible',
    '#empty#': 'Indisponible',
    frown: 'Triste',
    meh: 'Ça va',
    smile: 'Heureux'
  },
  noChecklists: 'Aucun formulaire disponible.',
  updating: 'Blink Check est en cours de mise à jour.',
  installing: 'Installation de la version {{version}}...',
  appName: 'Vérification clignotante',
  notAvailableInPublicChecklists: ' (Non disponible dans les modèles de formulaires publics)',
  pdfAssignTemplate: 'Vous devez attribuer un modèle de rapport au formulaire avant de pouvoir utiliser le téléchargement PDF.'
};
