<ng-container *ngIf="value === '1'">
  <i class="far fa-star fa-{{size}}"></i>
</ng-container>
<ng-container *ngIf="value === '2'">
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
</ng-container>
<ng-container *ngIf="value === '3'">
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
</ng-container>
<ng-container *ngIf="value === '4'">
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
</ng-container>
<ng-container *ngIf="value === '5'">
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
  <i class="far fa-star fa-{{size}}"></i>
</ng-container>
