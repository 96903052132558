import { createStore } from '@ngneat/elf';
import { deleteAllEntities, selectAllEntities, setEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';

import { BlinkLocation } from './location';
import { map, take } from 'rxjs/operators';
import { PersistStateService } from '../../shared';


const store = createStore(
  { name: 'core_locations' },
  withEntities<BlinkLocation, 'Id'>({ idKey: 'Id' })
);

@Injectable({ providedIn: 'root' })
export class BlinkLocationRepository {
  locations$ = store.pipe(
    selectAllEntities(),
    map(locations => locations.sort((a, b) => a.Name.localeCompare(b.Name)))
  );

  constructor(private persistStateService: PersistStateService) {
    persistStateService.persistState(store).subscribe();
  }


  setLocations(locations: BlinkLocation[]) {
    store.update(setEntities(locations));
  }

  upsertLocations(locations: BlinkLocation[]) {
    store.update(upsertEntities(locations));
  }

  getLocationByQrCode(code: string) {
    return store.pipe(
      // selectEntityByPredicate(...), was not working because something...
      selectAllEntities(),
      map(locations => locations.find(location => location.LocationQrCodes
        .some(c => c.LocationQrCodeId === code))
      ),
      take(1)
    );
  }

  reset() {
    store.update(deleteAllEntities());
  }
}
