import { Injectable } from '@angular/core';
import { ApiManager, BlinkService, TypedQuery } from '../../shared';
import { map } from "rxjs/operators";
import { displayName } from "../display-name";
import { firstValueFrom } from 'rxjs';
import { BlinkLoginUser } from './login-user.model';
import { CompanyRepository } from '../company/company.repository';

@Injectable({ providedIn: 'root' })
export class LoginUserApi {
  private api = this.apiManager
    .createApiForEndpoint<BlinkLoginUser>(
      'odata/v1',
      'LoginUsers',
      BlinkService.Core
    );

  constructor(private apiManager: ApiManager,
              private companyRepository: CompanyRepository) {
  }

  getLoginUsersForCheck(): Promise<BlinkLoginUser[]> {
    const query: TypedQuery<BlinkLoginUser> = {
      filter: {
        and: [
          //{ Active: true },
          { Type: { ne: 'Terminal' } },
          { Type: { ne: 'Customer' } },
          {
            or: [
              { 'PermissionGroup/CompanyId': { eq: null } },
              { 'PermissionGroup/CompanyId': this.companyRepository.getActiveCompanyId() }
            ]
          }
        ]
      },
      select: [
        'Id',
        'Active',
        'ExternalId',
        'FirstName',
        'LastName'
      ]
    };
    return firstValueFrom(this.api.get<BlinkLoginUser[]>(query).pipe(map(loginUser => loginUser.map(loginUser => ({
      ...loginUser,
      displayName: displayName(loginUser.FirstName, loginUser.LastName)
    })))));
  }

  updateLanguage(loginUserId: number, languageCode: string) {
    const options = {
      key: loginUserId,
      body: { LanguageCode: languageCode }
    }
    return this.api.patch(options);
  }
}
