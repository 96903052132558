import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiFormlyWrapperFormFieldComponent } from './form-field.wrapper';
import { IonFormlyAttributesDirective } from './formly.attributes';

@NgModule({
  declarations: [UiFormlyWrapperFormFieldComponent, IonFormlyAttributesDirective],
  exports: [IonFormlyAttributesDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,

    FormlyModule.forChild({
      wrappers: [
        {
          name: 'ui-form-field',
          component: UiFormlyWrapperFormFieldComponent
        }
      ]
    })
  ]
})
export class UiFormlyFormFieldModule {
}
