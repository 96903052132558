import { Component } from '@angular/core';
import { MeTranslateService } from '@blink/util';
import { ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { UiExternalLinkOptions } from './ui-external-link-options';
import { BlinkIcon } from '../../ui-icon';

@Component({
  selector: 'ui-external-link-modal',
  templateUrl: './ui-external-link-modal.component.html',
  styleUrls: ['./ui-external-link-modal.component.scss']
})
export class UiExternalLinkModalComponent {
  externalLink: UiExternalLinkOptions;
  protected readonly BlinkIcon = BlinkIcon;

  constructor(public t: MeTranslateService,
              public modalController: ModalController) {
  }

  async continue() {
    await this.modalController.dismiss();

    if (Capacitor.isNativePlatform()) {
      window.open(this.externalLink.url, '_self');
    } else {
      window.open(this.externalLink.url, '_blank');
    }
  }
}
