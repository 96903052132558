import { Component } from '@angular/core';
import { IonItem, IonLabel } from '@ionic/angular';
import { FieldWrapper, FormlyFieldConfig, FormlyFieldProps as CoreFormlyFieldProps } from '@ngx-formly/core';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  hideRequiredMarker?: boolean;
  itemLines?: IonItem['lines'];
  labelPosition?: IonLabel['position'];
}

@Component({
  selector: 'ui-formly-wrapper-ion-form-field',
  template: `
    <ion-item class="blink-item-input ion-margin-bottom"
              color="input"
              lines="none">
      <ng-template #fieldComponent></ng-template>
    </ion-item>

    <small *ngIf="showError" class="text-danger">
      <formly-validation-message [field]="field"></formly-validation-message>
    </small>
  `
})
export class UiFormlyWrapperFormFieldComponent extends FieldWrapper<FormlyFieldConfig<FormlyFieldProps>> {
}
