import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home';
import { HelpComponent } from './help/help.component';
import { CompanyConfigComponent } from './company-config/company-config.component';
import { authGuard } from '@blink/api';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'checklist-home',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard]
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [authGuard]
  },
  {
    path: 'fill',
    loadChildren: () => import('@blink/check/feature/fill-checklist')
      .then(m => m.FillChecklistModule)
  },
  {
    path: 'public/:hash',
    redirectTo: 'fill/public/:hash'
  },

  {
    path: 'config',
    loadChildren: () => import('./config-checklists/config-checklists.module')
      .then(m => m.ConfigChecklistsModule),
    canActivate: [authGuard]
  },

  {
    path: 'companyConfig',
    component: CompanyConfigComponent,
    canActivate: [authGuard]
  },

  {
    path: 'results',
    loadChildren: () => import('@blink/check/feature/results').then(x => x.CheckResultsModule),
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
