export default {
  home: {
    pageTitle: 'Moje formuláre',
    locationChecklists_label: 'Vyplňte formulár pre objekt',
    generalChecklists_label: 'Vyplňte všeobecný formulár',
    processingChecklists_label: 'Forma prebieha',
    checklistDatas_label: 'Vyplnené formuláre',
    selectLocation: 'Vyberte objekt',
    scanQrCode: 'Naskenujte kód objektu',
    showAll: '... ukázať všetko',
    showLess: '... ukázať menej'
  },
  fill: {
    checklists: 'Formuláre',
    fillChecklist: 'vyplňte formulár',
    checklistNotFound: 'Žiaľ, požadovaný formulár sa nepodarilo nájsť.',
    checklistOfflineLoginUserRequired: 'Tento formulár vyžaduje výber zamestnanca. Ak chcete získať prístup k šablóne formulára, vytvorte internetové pripojenie.',
    checklistNotOfflineAvailable: 'Šablóna formulára nie je uložená offline. Ak chcete získať prístup k šablóne formulára, pripojte sa k internetu.',
    resetConfirm: 'Naozaj chcete obnoviť formulár?',
    categoryAppeared: 'Bola pridaná kategória „{{categoryName}}“.',
    groupAppeared: 'Stránka „{{groupName}}“ bola pridaná.',
    itemAppearedInGroup: 'Na stránku „{{groupName}}“ boli pridané nové otázky.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'K ďalšej otázke',
    containerUriExpired: 'Obdobie nahrávania bohužiaľ vypršalo.',
    finishMessage: 'Zadali ste všetky potrebné údaje a teraz môžete vyplniť formulár.',
    offlineMessage: 'Ak chcete vyplniť formulár, pripojte sa k internetu.',
    offlinePreviewMessage: 'Ak chcete vygenerovať ukážku, pripojte sa k internetu.',
    checklistUpdatedHeader: 'Šablóna formulára zmenená',
    checklistUpdatedMessage: 'Šablóna formulára bola zmenená. Pred odoslaním formulára znova skontrolujte svoje záznamy.',
    checklistLocationRequiredHeader: 'Vyberte objekt',
    checklistLocationRequiredMessage: 'Na vyplnenie tohto formulára musíte vybrať objekt.',
    fileUploadFileMissingHeader: 'Súbory sa nenašli',
    fileUploadFileMissingMessage: 'Nasledujúce súbory boli vybraté, ale v zariadení ich nemožno nájsť: {{ missingFiles }}',
    finishSuccess: 'Formulár bol úspešne vyplnený a všetky údaje boli spracované.',
    finishError: 'Formulár nebolo možné vyplniť kvôli chybe. Kontaktujte podporu.',
    createdWith: 'Tento formulár bol vytvorený pomocou',
    preview: 'Náhľad'
  },
  item: {
    boolean: { yes: 'Áno', no: 'Nie' },
    scan: 'skenovať',
    email: { invalidEmail: 'Prosím skontrolujte svoj vstup.' },
    takePicture: { picture: 'Obrázok', buttonText: 'pridaj fotku' },
    createTask: {
      taskTitle: 'Názov lístka',
      taskDescription: 'Popis lístka',
      taskDueDate: 'Dátum splatnosti lístka'
    }
  },
  noChecklistsForLocationFound: 'Pre tento objekt neexistuje žiadny formulár. Vyberte si inú nehnuteľnosť alebo vyplňte všeobecný formulár.',
  selectChecklistByLocation: 'Vyberte formulár pre objekt'
};
