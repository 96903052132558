import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ModalController, ToastController } from '@ionic/angular';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  showUpdateButton = false;

  constructor(swUpdate: SwUpdate,
              private modalController: ModalController,
              private toastController: ToastController) {
    swUpdate.versionUpdates.pipe(
      tap(updateEvent => console.log({ updateEvent })),
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
    ).subscribe(evt => {
      this.showUpdateToastFor(evt.latestVersion.appData['version']);
    });
  }

  async showUpdateToastFor(version: string) {
    const toast = await this.toastController.create({
      header: 'Neue Version verfügbar!',
      message: `Blink.Check ${version} steht bereit.`,
      icon: 'information-circle',
      position: 'top',
      buttons: [
        {
          side: 'start',
          text: 'Jetzt aktivieren',
          handler: () => {
            window.location.reload();
          }
        }, {
          text: 'Später',
          role: 'cancel',
          handler: () => {
            this.showUpdateButton = true;
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();
  }
}
