import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { convertBase64toBlob } from '@blink/util';
import { FileDownloadOptions } from './file-download-options';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { saveAs } from 'file-saver';
import { UiDialogService } from '@blink/ui';
import { CapacitorPluginTranslateService } from '../i18n/capacitor-plugin-translate.service';
import { createFolder } from './create-folder';
import write_blob from 'capacitor-blob-writer';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private fileOpener: FileOpener,
              private uiDialogService: UiDialogService,
              private t: CapacitorPluginTranslateService) {
  }

  async downloadFile(options: FileDownloadOptions) {
    const currentDate = new Date().toLocaleString().replace(/[,:\s/]/g, '-');
    const datedFileName = `${currentDate}-${options.fileName}`;

    if (Capacitor.isNativePlatform()) {
      let writtenFilePath: string;
      await createFolder(options.folder);
      if (options.blob) {
        writtenFilePath = await write_blob({
          directory: Directory.Documents,
          path: `${options.folder}/${datedFileName}`,
          recursive: true,
          blob: options.blob
        });
      } else if (options.base64Data) {
        const savedFile = await Filesystem.writeFile({
          path: `${options.folder}/${datedFileName}`,
          data: options.base64Data,
          directory: Directory.Documents
        });
        writtenFilePath = savedFile.uri;
      }
      if (options.mimeType) {
        return this.fileOpener.showOpenWithDialog(writtenFilePath, options.mimeType);
      } else {
        this.uiDialogService.toast(this.t.file.downloadStarted);
      }
    } else {
      if (options.base64Data) {
        options.blob = convertBase64toBlob(options.base64Data, options.mimeType);
      }
      saveAs(options.blob, datedFileName);
      this.uiDialogService.toast(this.t.file.downloadStarted);
    }
  }

  async downloadExcelFile(fileBlob: Blob, fileName: string) {
    const options: FileDownloadOptions = {
      folder: fileName,
      fileName: `${fileName}.xlsx`,
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      blob: fileBlob
    };
    await this.downloadFile(options)
  }
}
