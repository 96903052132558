import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckPermissionGroup } from './permission-group';

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionGroupApiService {
  private api = this.apiManager
    .createApiForEndpoint<CheckPermissionGroup>(
      'odata/v1',
      'ChecklistPermissionGroups',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager) {
  }

  post(checkPermissionGroup: CheckPermissionGroup) {
    return this.api.post({ body: checkPermissionGroup });
  }

  getForChecklist(checklistId: number): Observable<Array<CheckPermissionGroup>> {
    const query = {
      filter: { ChecklistId: checklistId }
    };
    return this.api.get(query);
  }

  update(checkPermissionGroup: CheckPermissionGroup) {
    const request = {
      key: checkPermissionGroup.Id,
      body: checkPermissionGroup
    };
    return this.api.patch(request);
  }

  createOrUpdateMany(checkPermissionGroups: Array<CheckPermissionGroup>) {
    return from(checkPermissionGroups).pipe(
      concatMap(cpg => {
        if (cpg.Id) {
          return this.update(cpg);
        } else {
          return this.post(cpg);
        }
      })
    );
  }
}
