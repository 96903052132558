export default {
  cancel: 'Отменить',
  blinkSlogan: 'Blink - приложение для уборщиков зданий',
  all: 'Все',
  hello: 'Здравствуйте',
  description: 'Описание',
  ok: 'Хорошо',
  reset: 'Сброс',
  edit: 'Редактировать',
  finish: 'Замок',
  notice: 'Примечание',
  content: 'Содержание',
  back: 'Назад',
  close: 'Закрыть',
  backToHome: 'Вернуться на главную страницу',
  proceed: 'Далее',
  done: 'Готовые',
  delete: 'Удалить',
  successful: 'Успешный',
  error: 'Ошибка',
  ERROR_OCCURRED: 'Произошла ошибка или данные не верны. Пожалуйста, попробуйте еще раз.',
  internetRequired: 'Необходим Интернет',
  configuration: 'Конфигурация',
  logo: 'Логотип',
  uploadLogo: 'Загрузить логотип',
  deleteLogo: 'Удалить логотип',
  duplicate: 'Дубликат',
  importExport: 'Импорт/экспорт',
  import: 'Импорт',
  doImport: 'Импорт',
  export: 'Экспорт',
  doExport: 'Экспорт',
  excelExport: 'Экспорт в Excel',
  excelExportDownloaded: 'Загружен экспорт в Excel',
  excelExportError: 'Не удалось выполнить экспорт в Excel',
  pdfDownload: 'PDF Download',
  pdfDownloaded: 'PDF загружен',
  configSaved: 'Конфигурация сохранена.',
  send: 'Отправить',
  connecting: 'Подключение...',
  or: 'или',
  select: 'Выберите',
  today: 'Сегодня',
  camera: 'Камера',
  gallery: 'Галерея',
  files: 'Файлы',
  upload: 'Загрузка файлов',
  chooseFiles: 'Выберите файлы',
  filesTransferring: 'Передача файлов...',
  filesTransferringWait: 'Пожалуйста, подождите, пока файлы будут переданы.',
  filesTransferred: 'Файлы успешно переданы.',
  download: 'Скачать',
  showInactive: 'Показать неактивность',
  copyLink: 'Копирование ссылки',
  linkCopied: 'Ссылка была скопирована в буфер обмена.',
  permissions: 'Полномочия',
  email: 'E-mail',
  emailConfig: 'Конфигурация электронной почты',
  title: 'Название',
  user: 'Пользователь',
  logout: 'Выход из системы',
  system: 'Система',
  appVersion: 'Версия (приложение)',
  apiVersionCore: 'Версия\n(Core API)',
  apiVersionCheck: 'Версия\n(Проверьте API)',
  deviceInfo: 'Информация об устройстве',
  installBlinkTime: 'Установить время мигания',
  installBlinkMe: 'Установить Blink Me',
  installing: 'Версия {{version}} установлена ...',
  companyRequired: 'Выберите клиента',
  companyRequiredInfo: 'Для продолжения работы в качестве системного администратора необходимо выбрать клиента.',
  pleaseChoose: 'Пожалуйста, выберите',
  pleaseFill: 'Пожалуйста, заполните',
  noEntitiesFound: 'Никаких элементов найти не удалось.',
  addCustom: 'Создать элемент:',
  typeToSearchText: 'Пожалуйста, введите два или более символов...',
  loading: 'Данные загружаются...',
  offline: 'Offline',
  youAreOffline: 'Вы не в сети!',
  by: 'с сайта',
  date: 'Дата',
  time: 'Время',
  searchTitle: 'Поиск',
  searchTerm: 'Ключевое слово для поиска',
  searchLoginUser: 'Поиск сотрудников',
  searchLoginUserPlaceholder: 'Поиск по фамилии или имени',
  location: 'Объект',
  searchLocation: 'Объект поиска',
  searchLocationPlaceholder: 'Поиск по имени, номеру или тегам объекта',
  deactivate: 'Деактивировать',
  active: 'Активный',
  inactive: 'Неактивный',
  tag: 'День',
  tags: 'Теги',
  color: 'Цвет',
  icon: 'Иконка',
  language: 'Язык',
  general: 'Общие сведения',
  yes: 'Да',
  no: 'Нет',
  searchThrough: 'Просмотреть',
  name: 'Название',
  create: 'Создать',
  save: 'Сохранить',
  home: 'Главная',
  profile: 'Профиль',
  templates: 'Шаблоны',
  noAssignment: 'Нераспределенный',
  sort: {
    change: 'Заказ на изменение',
    save: 'Сохранить последовательность',
    saved: 'Последовательность была сохранена.'
  },
  signature: {
    signature: 'Подпись',
    pleaseSign: 'Пожалуйста, подпишите',
    clickHereToSign: 'Нажмите здесь, чтобы подписать',
    addSignature: 'Добавить подпись',
    save: 'Взять на себя',
    delete: 'Удалить'
  },
  formError: {
    min: 'Значение должно быть больше, чем {{value}}.',
    min2: 'Значение должно быть больше или равно {{value}}.',
    max: 'Значение должно быть меньше или равно {{value}}.',
    requiredBecause: 'Значение необходимо, так как {{name}} заполнено.',
    isRequired: '{{name}} является обязательным.',
    numberInUse: 'Кадровый номер уже используется.',
    maxlength: '{{name}} не должно содержать более {{value}} символов.',
    email: 'Пожалуйста, введите действительный адрес электронной почты.',
    faultyBlinkId: 'Неисправность идентификатора мигания'
  },
  scan: {
    choose: 'Назначение нового идентификатора мигания',
    text: 'Отсканируйте QR-код на регистрационной карточке.',
    scan_now: 'Сканировать сейчас',
    enter_manually: 'Ввести вручную'
  },
  removeImage: 'Удалить изображение',
  tickets: { assignee: 'Редактор', dueTo: 'Срок исполнения до' },
  ngSelect: {
    typeToSearch: 'Пожалуйста, введите 3 буквы или больше.',
    loading: 'данные о загрузке...',
    notFound: 'Не найдено ни одного {{title}}.',
    clearAll: 'Сброс'
  },
  help: {
    pageTitle: 'Справка',
    text1: 'Наша служба поддержки будет рада ответить на любые ваши вопросы:',
    phone: 'Телефон',
    mail: 'E-mail',
    information: 'Информация',
    documentation: 'Документация в режиме онлайн',
    documentationText: 'В нашей онлайновой документации вы найдете инструкции и видеоролики по всем важным областям работы с Blink.',
    support: 'Поддержка'
  },
  REGISTRATION: {
    WELCOME: 'Добро пожаловать!',
    chooseMethod: 'Какой способ регистрации Вы хотите использовать?',
    withBlinkId: 'Идентификационная карта BLINK',
    withCredentials: 'Имя пользователя / пароль',
    REGISTER: 'Регистр',
    ALREADY_REGISTERED: 'У меня есть учетная запись Blink.',
    INVALID_CARD: 'Карта не действительна.',
    noBlinkId: 'Отсканированный QR-код не является действительным идентификатором Blink ID',
    scanBlinkId: 'Сканирование идентификатора миганием',
    ERROR: 'Регистрация не может быть завершена. Пожалуйста, введите другой адрес электронной почты или номер мобильного телефона.',
    NO_INTERNET_EXPLORER: 'К сожалению, Blink не поддерживает Internet Explorer. Пожалуйста, используйте Microsoft Edge, Firefox или Chrome. Благодарим Вас за понимание.',
    loginNow: 'Зарегистрируйтесь сейчас',
    help: 'Помощь в регистрации',
    helpText: 'Приложение Blink Manager позволяет управлять временем, персоналом и конфигурацией системы. Blink Manager предназначен только для супервайзеров, менеджеров по недвижимости, руководства и администрации! Для чистого учета рабочего времени используйте приложение "Blink Time App"! <br/><br/>Для регистрации можно использовать два способа:<br/><br/><b>Зарегистрироваться с помощью карты Blink ID</b><br/><br/>Если у вас есть карта Blink ID, то выберите этот способ. При первой регистрации вам будет предложено ввести адрес электронной почты или номер мобильного телефона. Это необходимо для того, чтобы выслать вам одноразовый пароль для регистрации. Запоминать одноразовый пароль не нужно. При повторной регистрации мы вышлем вам новый одноразовый пароль.<br/><br/><b>Вход в систему с именем пользователя и паролем</b><br/><br/>Имя пользователя и пароль вы получите только по запросу от системного администратора. Этот способ доступен только пользователям с правами администратора.<br/><br/><b>Поддержка</b><br/><br/>Если у Вас возникли проблемы при входе в систему, напишите письмо на адрес support@blink-time.de или позвоните по телефону 0911 240 330 0.',
    userOffline: 'Для использования Blink me подключитесь к Интернету.',
    login: 'Зарегистрируйтесь сейчас',
    codeError: 'Пожалуйста, проверьте свою запись и повторите попытку.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Поздравляем!',
    YOU_DID_IT: 'Вы сделали это.',
    SIGNED_IN: 'Зарегистрированный',
    SIGNED_IN_TEXT: 'Вы успешно зарегистрировались и теперь можете пользоваться приложением.',
    START_USAGE: 'Теперь вы можете воспользоваться приложением.',
    START: 'Начало'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Вам было отправлено электронное письмо, с помощью которого Вы можете изменить свои данные доступа.',
    FORGOT_PASSWORD: 'Забыли пароль?',
    errors: {
      notAllowed: 'Сброс пароля для этого пользователя невозможен.',
      invalidToken: 'Срок действия кода для сброса пароля истек. Пожалуйста, запросите новый код.'
    },
    reset: {
      backToRoot: 'Возврат к странице входа в систему',
      renewCode: 'Запрос нового кода',
      resetPassword: 'Сброс пароля',
      success: 'Ваш пароль был успешно сброшен.',
      password1: 'Ваш новый пароль',
      password2: 'Повторить пароль',
      validationError: 'Пароль не соответствует нашим рекомендациям по использованию паролей!',
      passwordsNotEqual: 'Пароли не совпадают! Пожалуйста, проверьте свою запись.',
      passwordValidation: 'Пароль должен состоять не менее чем из 6 символов, содержать букву, цифру и специальный символ.'
    },
    FORGOT_PASSWORD_TEXT: 'Пожалуйста, введите свой адрес электронной почты и целевую систему, чтобы сбросить пароль.',
    FORGOT_PASSWORD_PLACEHOLDER: 'электронная почта'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Контрольные данные',
    TEXT: 'Если нам потребуется сбросить ваш пароль, нам понадобится ваш адрес электронной почты и, по желанию, номер мобильного телефона.',
    EMAIL: 'Адрес электронной почты',
    MOBILE: 'Номер мобильного телефона'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Введите код',
    EMAIL_TEXT: 'Введите код, который мы отправили на следующий адрес электронной почты:',
    SMS_TEXT: 'Введите код, который мы отправили на указанный ниже номер мобильного телефона:',
    NO_CODE: 'Я не получил код.',
    CODE_LENGTH_ERROR: 'Введенный код длиннее шести символов. Пожалуйста, введите код еще раз.',
    CODE_SENT: 'Код отправлен.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Нет кода',
    TEXT1: 'Вы не получили код?',
    RESEND_CODE: 'Отправить код еще раз',
    TEXT2: 'Если ваш адрес электронной почты или номер мобильного телефона, хранящийся в системе, изменился, обратитесь к администратору.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Пожалуйста, введите свой идентификатор и код Blink. Вся информация содержится в регистрационной карточке.',
    textCredentials: 'Имя пользователя и пароль должен предоставить системный администратор.',
    BLINK_ID_INVALID: 'Неисправность идентификатора мигания',
    loginCardNotFound: 'Идентификатор Blink ID либо уже назначен, либо не существует.',
    WRONG_CREDENTIALS: 'Введенные данные не верны. Пожалуйста, проверьте введенные данные.',
    loginViaBlinkId: 'Регистрация с помощью BlinkID',
    loginViaCredentials: 'Вход в систему с данными доступа',
    CODE: 'Код',
    codePlaceholder: 'Ваш персональный код доступа',
    company: 'Система',
    password: 'Пароль',
    FORGOT_PASSWORD: 'Я забыл свой пароль',
    username: 'электронная почта'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Идентификация',
    TEXT_1: 'Для того чтобы мы могли однозначно идентифицировать вас, мы отправим вам одноразовый код по SMS или электронной почте.',
    TEXT_2: 'Для этого введите номер своего мобильного телефона или адрес электронной почты.'
  },
  update: {
    update: 'Обновление',
    latestVersion: 'Доступна новая версия. Пожалуйста, обновите свое приложение, чтобы воспользоваться всеми его преимуществами.',
    warnVersion: 'Вы используете устаревшую версию этого приложения. Пожалуйста, обновите ее как можно скорее.',
    blockVersion: 'Вы используете устаревшую версию этого приложения, которая больше не поддерживается. Пожалуйста, обновите ее сейчас.'
  },
  inProgress: 'в обработке',
  video_offline: 'Видео невозможно просмотреть в автономном режиме, подключитесь к Интернету.',
  location_offline: 'Объект не сохраняется в автономном режиме. Подключитесь к Интернету, чтобы получить QR-код.',
  invalidObjectCode: 'Нет допустимого объектного кода.',
  invalidValue: 'Неверное значение',
  ERROR: {
    ERROR_400: 'Там была ошибка. Пожалуйста, повторите попытку позже. (Код ошибки: 400)',
    ERROR_403: 'У вас нет разрешения на выполнение этого действия.',
    REFRESH_TOKEN_403: 'Ваши данные доступа больше не действительны. Пожалуйста, войдите еще раз!',
    ERROR_500: 'Там была ошибка. Пожалуйста, повторите попытку позже. (Код ошибки: 500)'
  },
  list: {
    entityPluralDefault: 'Полученные результаты',
    emptyState: '{{entityPlural}} не существует.',
    noResults: '{{entityPlural}} не найден.',
    selectAll: 'Выбрать все {{entityPlural}}',
    massAction: 'Акция для {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Выбрать все {{count}}',
    massActionDeSelectAllGlobal: 'Отменить выбор всех {{count}}'
  },
  languages: {
    ar: 'арабский',
    de: 'Немецкий',
    es: 'испанский',
    fr: 'Французский',
    hr: 'хорватский',
    it: 'итальянский',
    pt: 'португальский',
    ru: 'Русский',
    bg: 'болгарский',
    en: 'Английский',
    fa: 'персидский',
    el: 'Греческий',
    hu: 'Венгерский',
    pl: 'Польский',
    ro: 'румынский',
    tr: 'турецкий',
    uk: 'украинец',
    cs: 'Чешский',
    sk: 'словацкий'
  },
  now: 'Сейчас',
  allCompanies: 'Все клиенты',
  createDate: 'Дата создания',
  company: 'клиент',
  new: 'Новый',
  of: 'от',
  request: {
    deleteTitle: 'Удалить {{item}}',
    confirmDelete: 'Вы действительно хотите удалить запись «{{itemName}}»?',
    itemAdded: '{{item}} создан.',
    itemAddedError: '{{item}} не удалось создать.',
    itemSaved: '{{item}} сохранен.',
    itemSavedError: '{{item}} не удалось сохранить.',
    itemRemoved: '{{item}} был удален.',
    itemRemovedError: '{{item}} не удалось удалить.'
  },
  news: { pageTitle: 'Новости', edit: 'Редактировать новостные отчеты' },
  profiles: {
    loginProfiles: 'Профили входа',
    add: 'Профиль входа',
    logout: 'Если вы выйдете из системы, все локальные данные и профили будут удалены! Вы хотите отказаться от подписки?',
    login: 'регистр',
    deleteConfirmation: 'Вы действительно хотите удалить профиль?',
    changed: 'Профиль успешно изменен',
    deleted: 'Профиль успешно удален'
  },
  deleteAttachment: {
    confirmTitle: 'Удалить вложение',
    confirmText: 'Вы уверены, что хотите удалить это вложение?'
  },
  comments: {
    addComment: 'добавить комментарий',
    addCommentInfo: 'Комментарий виден всем, кто может просмотреть этот билет.',
    comment: 'комментарий',
    deleteComment: 'Удалить комментарий',
    deleteCommentConfirmation: 'Вы действительно хотите удалить комментарий?'
  }
};
