import { ChangeDetectionStrategy, Component, forwardRef, HostListener, Input, ViewChild } from '@angular/core';
import { fontAwesomeIcons } from '../../shared/font-awesome-icons';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstracts/input.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BlinkIcon } from '../../ui-icon';

@Component({
  selector: 'ui-input-select-icon',
  templateUrl: './ui-input-select-icon.component.html',
  styleUrls: ['./ui-input-select-icon.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputSelectIconComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiInputSelectIconComponent extends AbstractInputComponent {
  protected readonly iconSelectList = fontAwesomeIcons
  protected readonly BlinkIcon = BlinkIcon;

  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  @Input() override label = this.t.icon;
  @Input() override placeholder = this.t.icon;

  isOpen = false;


  @HostListener('click', ['$event'])
  open() {
    if (!this.isOpen && !this.readonly) {
      this.isOpen = true;
      setTimeout(() => {
        document.addEventListener(
          'click',
          () => this.close(),
          { once: true }
        );
      });
    }
  }

  close() {
    this.isOpen = false;
    this.ngSelect.blur();
    this.cdRef.detectChanges();
  }

}
