import { CheckChecklist } from '../checklist/checklist';
import { CheckGroup } from './group';

export interface GroupNavigationIds {
  previousGroupId: number,
  nextGroupId: number
}

export const getGroupNavigationIds = (checklist: CheckChecklist, group: CheckGroup): GroupNavigationIds => {
  const categories = checklist.categories;
  const result = {
    previousGroupId: null,
    nextGroupId: null
  };

  const groupIsFirstGroup = categories[0].groups[0].Id === group.Id;
  if (groupIsFirstGroup) {
    result.previousGroupId = null;
  } else {
    const currentCategoryIndex =
      categories.findIndex(c => c.Id === group.CategoryId);

    const groupIndex = categories[currentCategoryIndex]
      .groups.findIndex(g => g.Id === group.Id);

    // necessary because checklistRepo.getForFill may not contain all groups while syncing Dependencies
    if (groupIndex === -1) {
      return result;
    }

    if (groupIndex > 0) {
      result.previousGroupId = categories[currentCategoryIndex].groups[groupIndex - 1].Id;
    } else {
      const previousCategoryGroups = categories[currentCategoryIndex - 1].groups;
      result.previousGroupId = previousCategoryGroups[previousCategoryGroups.length - 1].Id;
    }
  }

  const lastCategoryGroups = categories[categories.length - 1].groups;
  const groupIsLastGroup = lastCategoryGroups[lastCategoryGroups.length - 1].Id === group.Id;
  if (groupIsLastGroup) {
    result.nextGroupId = null;
  } else {
    const currentCategoryIndex =
      categories.findIndex(c => c.Id === group.CategoryId);

    const groupIndex = categories[currentCategoryIndex]
      .groups.findIndex(g => g.Id === group.Id);


    // necessary because checklistRepo.getForFill may not contain all groups while syncing Dependencies
    if (groupIndex === -1) {
      return result;
    }

    if (groupIndex < categories[currentCategoryIndex].groups.length - 1) {
      result.nextGroupId = categories[currentCategoryIndex].groups[groupIndex + 1].Id;
    } else {
      const nextCategoryGroups = categories[currentCategoryIndex + 1].groups;
      result.nextGroupId = nextCategoryGroups[0].Id;
    }
  }
  return result;
};
