import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, isDevMode } from '@angular/core';
import { UiDialogService } from '@blink/ui';
import { HANDLE_ERROR } from './api-manager';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private uiDialogService: UiDialogService) {
    // super();
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          if (request.context.get(HANDLE_ERROR)) {

            let errorMessage = '';

            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              console.log(error);
              errorMessage = `Development Error: \n\n Error Code: ${error.status} \n Message: ${error.message}`;
              if (error.error?.message) {
                errorMessage += `\n\n ${error.error.message}`;
              }
              if (error.error?.Message) {
                errorMessage += `\n\n ${error.error.Message}`;
              }
            }

            if (isDevMode()) {
              // window.alert(errorMessage);
              console.log('devmode');
              this.uiDialogService.errorOccurred(500);
            } else {
              console.log('errorOccured');
              this.uiDialogService.errorOccurred(500);
            }

            return throwError(() => new Error(errorMessage));
          } else {
            return throwError(() => error);
          }
        })
      );
  }
}
