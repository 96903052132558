import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from "@ionic/angular";
import { GlobalTranslateService, logComponent } from '@blink/util';
import { detectIE } from './utils/detect-internet-explorer';
import { BlinkIcon, UiDialogService, uiPrimaryButton } from '@blink/ui';
import { LoginService } from './login.service';
import { BlinkLoginConfig, BlinkLoginConfigService, LoginMethod } from './utils/injection-tokens';


@Component({
  selector: 'blink-login-shell-page',
  templateUrl: './login-shell.html',
  styleUrls: ['./login-shell.scss']
})
export class LoginShellComponent {
  initialized = true;
  protected readonly BlinkIcon = BlinkIcon;

  constructor(public platform: Platform,
              private loginService: LoginService,
              @Inject(BlinkLoginConfigService) private loginConfig: BlinkLoginConfig,
              public t: GlobalTranslateService,
              private uiDialogService: UiDialogService,
              public translate: TranslateService) {
    logComponent(this);

    if (this.loginConfig.allowedLoginMethod === LoginMethod.BLINK_ID) {
      this.startBlinkIdLoginFlow();
    } else if (this.loginConfig.allowedLoginMethod === LoginMethod.CREDENTIALS) {
      this.startCredentialsLoginFlow();
    }
  }

  get isInternetExplorer() {
    return detectIE();
  }

  startBlinkIdLoginFlow() {
    this.loginService.startBlinkIdLoginFlow();
  }

  startCredentialsLoginFlow() {
    this.loginService.startCredentialsLoginFlow();
  }

  showHelpText() {
    this.uiDialogService.confirm({
      title: this.t.REGISTRATION.help,
      text: this.t.REGISTRATION.helpText,
      textOptions: {cssClass: 'ion-text-center'},
      buttons: [
        uiPrimaryButton(this.t.ok, () => undefined)
      ]
    });
  }
}
