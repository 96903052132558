import { inject, Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CheckChecklistRepository } from './checklist.repository';
import { CheckChecklist } from './checklist';

@Pipe({
  name: 'checklistProperty'
})
export class ChecklistPropertyPipe implements PipeTransform {
  checklistRepository = inject(CheckChecklistRepository);

  transform(checklistId: number, property: keyof CheckChecklist): Observable<unknown> {
    if (property) {
      return this.checklistRepository.get(checklistId).pipe(
        map(x => x[property])
      )
    } else {
      return this.checklistRepository.get(checklistId);
    }

  }


}
