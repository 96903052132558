<ion-toggle [(ngModel)]="value"
            [disabled]="disabled"
            [ngModelOptions]="{standalone: true}"
            slot="start"
            [labelPlacement]="labelPlacement">
  {{ label | translate }}
</ion-toggle>
<div class="mt-10 mb-10">
  <small class="pt-0">{{ info | translate }}</small>
</div>

<div *ngIf="value">
  <ng-content select="[toggleChecked]"></ng-content>
</div>

<div class="mt-15"></div>

