import { InjectionToken } from '@angular/core';

export interface BlinkLoginConfig {
  devUsername: string;
  devPassword: string;
  allowedLoginMethod: LoginMethod;
}

export enum LoginMethod {
  BLINK_ID = 'blinkId',
  CREDENTIALS = 'credentials',
  BOTH = 'both'
}

export const BlinkLoginConfigService = new InjectionToken<BlinkLoginConfig>('login.config');

