import { createStore, filterNil, select, withProps } from '@ngneat/elf';
import { deleteEntities, selectEntity, selectMany, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { CheckCategory } from './category';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { CheckGroupRepository } from '../group/group.repository';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';
import { CheckCategoryDependencyRepository } from '../category-dependency/category-dependency.repository';
import { PersistStateService } from '../../shared';

interface CategoryRepoProps {
  checklistCategoriesIdMap: IdMap;
}

const store = createStore(
  checkStoreConfig('categories'),
  withEntities<CheckCategory, 'Id'>({ idKey: 'Id' }),
  withProps<CategoryRepoProps>({ checklistCategoriesIdMap: {} })
);


@Injectable({ providedIn: 'root' })
export class CheckCategoryRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();

  constructor(private categoryDependencyRepo: CheckCategoryDependencyRepository,
              private groupRepo: CheckGroupRepository,
              private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(categories: CheckCategory[], isCompleteUpdate: boolean, removeAbsentForChecklistId: number = null) {
    const deleteCategoryIds = store.getValue().checklistCategoriesIdMap[removeAbsentForChecklistId];
    store.update(
      deleteEntities(deleteCategoryIds),
      upsertEntities(categories),
      idMapper(
        'checklistCategoriesIdMap',
        categories,
        'ChecklistId',
        isCompleteUpdate
      )
    );
  }

  delete(id: number) {
    store.update(
      deleteEntities(id),
      removeMappedId('checklistCategoriesIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistCategoryIdMap = store.getValue().checklistCategoriesIdMap;
    let deleteCategoryIds = [];
    checklistIds.forEach(x => {
      deleteCategoryIds = deleteCategoryIds.concat(checklistCategoryIdMap[x]);
    });
    store.update(
      deleteEntities(deleteCategoryIds),
      removeKeys('checklistCategoriesIdMap', checklistIds)
    );
  }

  getSync(categoryId: number) {
    return store.getValue().entities[categoryId];
  }

  getMany(categoryIds: number[]): Observable<CheckCategory[]> {
    if (categoryIds) {
      return store.pipe(
        selectMany(categoryIds),
        map(categories => categories.sort((a, b) => a.Order - b.Order))
      );
    }
    return of([]);
  }

  getManyFullForFill(categoryIds: number[]): Observable<CheckCategory[]> {
    if (!categoryIds.length) {
      return of([]);
    }
    return combineLatest([
      store.pipe(selectMany(categoryIds)),
      combineLatest(categoryIds.map(categoryId => this.groupRepo.getCategoryGroupsForFill(categoryId))),
      combineLatest(categoryIds.map(categoryId => this.categoryDependencyRepo.getCategoryDependencies(categoryId)))
    ]).pipe(
      map(([categories, groupMap, dependencyMap]) => {
        return categories
          .map((category, i) => {
            const groups = groupMap[i].filter(g => g.fillVisible);
            const dependencies = dependencyMap[i];
            const noOrFulfilledDependencies =
              !dependencies.length
              || dependencies.some(d => d.fulfilled);
            const fillVisible = noOrFulfilledDependencies && groups.length > 0;
            return {
              ...category,
              groups,
              dependencies,
              fillVisible
            };
          })
          .sort((a, b) => a.Order - b.Order);
      })
    );
  }

  getFullForConfig(id: number) {
    return combineLatest([
      store.pipe(selectEntity(id), filterNil()),
      this.groupRepo.getCategoryGroupsForConfig(id)
    ]).pipe(map(([category, groups]) => {
      return ({ ...category, groups });
    }));
  }

  getChecklistCategoriesForFill(checklistId: number): Observable<CheckCategory[]> {
    return store.pipe(
      select(state => state.checklistCategoriesIdMap[checklistId]),
      map(categoryIds => categoryIds ? categoryIds : []),
      switchMap(categoryIds => this.getManyFullForFill(categoryIds))
    );
  }
}
