export default {
  config: {
    checklist: {
      checklist: 'Șablon de formular',
      checklists: 'Șabloane de formulare',
      none: 'Nu există formulare disponibile.',
      new: 'Nou șablon de formular',
      edit: 'Editați șablonul de formular',
      saved: 'Șablonul de formular a fost salvat.',
      delete: 'Ștergeți șablonul de formular',
      deleteChecklistResults: 'De asemenea, ștergeți toate formularele completate ale șablonului de formular {{name}}?',
      deleteInfo: 'Dacă ștergeți șablonul de formular, toate formularele completate vor fi, de asemenea, șterse irevocabil! <br><br>Pentru a păstra formularele completate, dezactivați șablonul de formular.',
      deleted: 'Șablonul de formular a fost șters.',
      duplicate: 'Șablon de formular duplicat',
      duplicateInfo: 'Puteți utiliza funcția de duplicare pentru a duplica șabloanele de formular',
      duplicateMessage: 'Doriți să duplicați șablonul de formular „{{checklistName}}” cu tot conținutul?',
      duplicated: 'Șablonul de formular a fost duplicat.',
      templateName: 'Numele formularului',
      activeInfo: 'Numai șabloanele active pot fi completate; șabloanele inactive pot fi editate numai în gestionarea șabloanelor.',
      locationRequired: 'Selectarea obiectelor este obligatorie',
      locationRequiredInfo: 'Dacă este activat, trebuie selectat un obiect sau scanat codul QR pentru a completa formularul.',
      loginUserRequired: 'Selectarea angajatului este obligatorie',
      loginUserRequiredInfo: 'Dacă este activat, trebuie selectat un angajat pentru a completa formularul.',
      allLocationAllowed: 'Permiteți toate obiectele',
      allowOnlyCertainLocations: 'Permiteți doar anumite obiecte',
      public: 'Forma publică',
      publicInfo: 'Creați un link public cu care formularul poate fi completat de orice utilizator. Nu este necesară autentificare și nicio aplicație. Puteți specifica o dată de valabilitate până când linkul ar trebui să fie valabil.',
      preview: 'Activați previzualizarea',
      previewInfo: 'Dacă este activat, un buton „Previzualizare” va fi întotdeauna afișat în partea de jos a formularului la completarea acestuia. Ca alternativă la butonul de previzualizare permanentă, puteți plasa și elementul de conținut „Previzualizare” oriunde în formular (această funcție nu trebuie activată).',
      shortUrlExpireDate: 'Data de expirare',
      sysadminOnly: 'Doar administratorii de sistem',
      allUsers: 'Toți utilizatorii',
      usersOfPermissionGroup: 'Utilizatorii acestor grupuri de autorizare',
      emailConfig: 'Configurare e-mail',
      emailConfigInfo: 'Dacă introduceți adrese de e-mail aici, formularele completate vor fi trimise automat la aceste adrese de e-mail.',
      emailSaved: 'Adresa de e-mail a fost salvată',
      emailRemoved: 'Adresa de e-mail a fost ștearsă',
      importExportInfo: 'Funcția de export vă permite să exportați șabloane de formulare și șabloane de raport asociate pentru a le reimporta într-un alt client sau sistem Blink Check.',
      import: 'Import șablon de formular',
      importInfo: 'Vă rugăm să selectați fișierul .check-b64 al șablonului de formular pe care doriți să îl importați.',
      imported: 'Formularul a fost creat cu succes.',
      templatesInAppFolder: 'Șabloane din directorul aplicației:',
      invalidImport: 'Fișierul selectat nu este un șablon de formular valid.',
      exported: 'Șablonul de formular a fost exportat',
      offline: 'Vă rugăm să stabiliți o conexiune la internet pentru a edita șabloanele de formulare.',
      checklistContainsCreateTask: 'Șablonul de formular conține elementul „Creați bilet” și, prin urmare, nu poate fi setat la public. Vă rugăm să eliminați elementul „Creați bilet”.'
    },
    category: {
      category: 'categorie',
      categories: 'Categorii',
      saved: 'Categoria a fost salvată.',
      editTitle: 'Editați categoria',
      content: 'elemente',
      newCategory: 'Categorie noua',
      name: 'Numele categoriei',
      edit: 'Editați categoria',
      duplicated: 'Categoria a fost duplicată.',
      noCategories: 'Nu există încă categorii. Creați categorii noi folosind butonul „Adăugați categorie”.',
      inactive: 'inactiv',
      showInactive: 'Afișați-le pe cele inactive',
      duplicateCategoryConfirmHeader: 'Categorie duplicat',
      duplicateCategoryConfirm: 'Doriți să duplicați categoria „{{categoryName}}” cu tot conținutul ei?',
      delete: 'Ștergeți categoria',
      deleteCategoryConfirm: 'Sigur doriți să ștergeți categoria „{{categoryName}}”?',
      deleteSuccess: 'Categoria a fost eliminată.'
    },
    group: {
      group: 'Pagină',
      groups: 'pagini',
      created: 'Pagina a fost creată.',
      updated: 'Modificările aduse paginii au fost salvate.',
      updateError: 'Pagina nu a putut fi salvată. Vă rugăm să încercați din nou mai târziu.',
      rename: 'Redenumiți pagina',
      saved: 'Pagina a fost salvată.',
      toggleSort: 'Schimbați sortarea',
      editGroupName: 'Editați numele paginii',
      new: 'Pagina noua',
      name: 'Numele paginii',
      duplicated: 'Pagina a fost duplicată.',
      noGroups: 'Nu există încă pagini. Creați pagini noi folosind butonul „Adăugați pagină”.',
      noItems: 'Nu există încă articole. Creați elemente noi folosind butonul „Adăugați element”.',
      add: 'Adauga pagina',
      infoElement: 'Element informativ',
      inputElement: 'Element de intrare',
      addInfoElement: 'Adăugați element de informații',
      addInputElement: 'Adăugați elementul de intrare',
      delete: 'șterge pagina',
      deleteGroupConfirm: 'Sigur doriți să ștergeți pagina „{{groupName}}”?',
      chooseGroup: 'Pe ce pagină ar trebui adăugat elementul?',
      duplicateGroupConfirmHeader: 'pagină duplicată',
      duplicateGroupConfirm: 'Doriți să duplicați pagina „{{groupName}}” cu tot conținutul?',
      deleteSuccess: 'pagina a fost eliminată.'
    },
    item: {
      add: 'adaugare element',
      created: 'Elementul a fost creat',
      saved: 'Elementul a fost salvat.',
      chooseItemType: 'Selectați elementul',
      values: 'Valori',
      value: 'Valoare',
      ratingNumber: 'Evaluare',
      addItemValue: 'Adăugați o nouă valoare',
      addItemValueSuccess: 'A fost adăugată o nouă valoare',
      updateItemValueSuccess: 'Valoarea a fost actualizată',
      removeItemValueSuccess: 'valoarea a fost eliminată',
      removeItemValue: 'eliminați valoarea',
      takePictureButtonText: 'Selectați imaginea',
      editTitle: 'editați elementul',
      editDependenciesTitle: 'Editați dependențele',
      required: 'Câmp obligatoriu',
      name: 'Numele elementului',
      description: 'descrierea opțională a elementului',
      points: 'Puncte',
      duplicated: 'Elementul a fost duplicat.',
      duplicateItemConfirmHeader: 'Element duplicat',
      duplicateItemConfirm: 'Doriți să duplicați elementul „{{itemName}}” cu tot conținutul său?',
      delete: {
        title: 'Sterge articolul',
        message: 'Chiar doriți să ștergeți elementul „{{itemName}}”?',
        success: 'Elementul a fost eliminat'
      },
      moveItem: 'Mutați elementul',
      move: 'Mișcare',
      moveItemInfo: 'Vă rugăm să selectați categoria și pagina în care ar trebui să fie mutat articolul curent.',
      itemMoved: 'Elementul a fost mutat'
    },
    editItem: {
      name: 'Nume de familie',
      nameInfo: 'Acest text este afișat direct înaintea elementului de intrare.',
      link: 'Adresa linkului',
      linkInfo: 'Această adresă va fi accesibilă printr-un buton. Vă rugăm să introduceți o adresă URL validă.',
      description: 'Descriere',
      descriptionInfo: 'Dacă doriți să furnizați instrucțiuni pentru completarea formularului, le puteți introduce aici.',
      linkDescription: 'Eticheta butonului',
      linkDescriptionInfo: 'Introduceți un nume pentru butonul.',
      values: 'Valori',
      valuesInfo: 'Puteți specifica un număr de evaluare pentru fiecare valoare. Puteți efectua evaluări ulterior folosind numărul de evaluare. Numărul de evaluare este opțional.',
      value: 'Valoare',
      valuePlaceholder: 'Noua valoare',
      ratingNumber: 'Evaluare',
      requiredField: 'Câmp obligatoriu',
      requiredFieldInfo: 'Dacă este activat ca câmp obligatoriu, atunci acest câmp trebuie completat pentru a putea fi trimis formularul.',
      externalNo: 'Număr extern',
      externalNoInfo: 'Informații opționale pentru interfețe sau evaluări prin sisteme externe.',
      editable: 'Editabil',
      editableInfo: 'Pentru ca utilizatorii să facă intrări, elementul trebuie să fie editabil.',
      defaultValue: 'Specificați valoarea implicită',
      defaultValueInfo: 'Puteți specifica o atribuire standard pentru acest element.',
      defaultValueInfoEditable: 'Valoarea poate fi modificată de către utilizatorul formularului.',
      visible: 'vizibilitate',
      visibleInfo: 'Vizibilitatea controlează dacă elementul poate fi văzut de editor sau nu.',
      externalNoAleradyUsed: 'Acest număr extern este deja utilizat.',
      taskTypeId: 'Tipul biletului',
      loginUserId: 'utilizator',
      locationId: 'Numele obiectului',
      videoId: 'Numărul video',
      videoType: 'Serviciu video',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Introduceți ID-ul unic al videoclipului aici. Puteți găsi acest lucru, de exemplu, în adresa URL a videoclipului. <br/>Exemplu: https://www.youtube.com/watch?v=XXXXX sau pe Vimeo https://vimeo.com/XXXXX. <br/>În acest caz, XXXXX ar trebui să fie introdus în câmp.',
      defaultTaskTitle: 'Titlul implicit al biletului',
      defaultTaskDescription: 'Descrierea implicită a biletului',
      titleEditable: 'Titlul biletului poate fi editat de utilizator',
      descriptionEditable: 'Descrierea biletului poate fi editată de utilizator',
      dueDateEditable: 'Data scadentă a biletului poate fi editată de utilizator',
      addReportAttachment: 'Atașați formularul completat la bilet ca PDF',
      noTaskTypesFound: 'Nu s-au găsit tipuri de bilete. Tipurile de bilete pot fi gestionate prin Blink Active.',
      noLoginUsersFound: 'Nu a putut fi găsit niciun utilizator. Utilizatorii pot fi gestionați prin Blink Manager.',
      noLocationsFound: 'Nu au putut fi găsite obiecte. Obiectele pot fi gestionate prin Blink Manager.',
      createTaskItemInfo: 'Cu această funcție puteți crea Blink Active Tickets. Puteți specifica titlul și descrierea sau puteți solicita utilizatorului să le completeze. Obiectul este atribuit automat, iar managerul de obiecte responsabil este desemnat ca editor.',
      duplicatedValuesNotAllowed: 'Nu sunt permise valori duplicate.',
      valueLengthExceeded: 'Valoarea introdusă este prea lungă și va fi scurtată.'
    },
    itemTypes: {
      PreviewButton: 'Previzualizarea documentului',
      Select: 'Selectare din listă',
      Signature: 'Semnătură',
      Email: 'Intrare e-mail',
      SendEmailTo: 'Livrare prin e-mail',
      Date: 'Data',
      Time: 'timp',
      Boolean: 'Da-nu intrebare',
      TakePicture: 'fa poza',
      TakeMultiPicture: 'a face poze',
      Scan: 'Scanare Qr/EAN',
      Input: 'Introducerea textului',
      Textarea: 'Introducere text pe mai multe linii',
      Link: 'Legătura',
      Picture: 'Afișarea imaginii',
      Video: 'Anunț video',
      Text: 'Afișare text',
      Header: 'titlu',
      Rate: 'Evaluare',
      RateSmiley: 'Evaluare smiley',
      CreateTask: 'Creați un bilet',
      FileUpload: 'Încărca fișiere',
      Currency: 'valută'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Vizualizați o versiune de previzualizare a formularului.',
      Select: 'Definiți orice elemente din listă din care poate fi selectat unul.',
      Signature: 'Câmp pentru semnătură digitală (poate fi folosit cu degetul)',
      Email: 'Utilizatorul poate furniza o adresă de e-mail validă.',
      SendEmailTo: 'Utilizatorul poate furniza o adresă de e-mail validă la care formularul completat va fi trimis sub formă de PDF.',
      Date: 'Utilizatorul poate specifica o dată printr-un calendar',
      Time: 'Utilizatorul poate specifica o oră',
      Boolean: 'Utilizatorul selectează da sau nu',
      TakePicture: 'Utilizatorul poate face o fotografie cu aparatul foto sau poate încărca o imagine.',
      TakeMultiPicture: 'Utilizatorul poate face mai multe fotografii cu camera sau poate încărca imagini.',
      Scan: 'Solicitați utilizatorilor să scaneze un cod QR sau EAN. Rezultatul este afișat în formular și salvat.',
      Input: 'Câmp de introducere a textului pe o singură linie',
      Textarea: 'Introducere text pe mai multe rânduri pentru comentarii mai lungi',
      Link: 'Link către site extern',
      Picture: 'Încărcați o imagine care va fi afișată la completare.',
      Video: 'Încărcați un videoclip care va fi afișat în timp ce completați formularul.',
      Text: 'Adăugați formatare la textele cu mai multe rânduri.',
      Header: 'Titlu mare pentru a structura conținutul',
      Rate: 'Evaluare de 5 stele',
      RateSmiley: 'Alegerea a 3 fețe zâmbitoare (fericite, neutre, triste)',
      CreateTask: 'Un bilet este creat pentru un utilizator',
      FileUpload: 'Utilizatorul poate atașa orice număr de fișiere la formular.',
      Currency: 'Utilizatorul poate specifica o sumă într-o monedă.'
    },
    template: {
      template: 'Șablon de raport',
      templates: 'Șabloane de rapoarte',
      name: 'Nume de familie',
      description: 'Descriere',
      customer: 'client',
      new: 'Nou șablon de raport',
      edit: 'Editați șablonul de raport',
      create: 'Creați șablon de raport',
      public: 'Public',
      active: 'Activ',
      deleteConfirm: 'Sigur doriți să ștergeți șablonul de raport „{{templateName}}”?',
      deleteSuccess: 'Șablonul de raport a fost eliminat',
      editor: 'Designer de șabloane',
      notForMobile: 'Designerul de șabloane nu este optimizat pentru dispozitive mobile. Vă rugăm să utilizați Blink Check în browser pentru aceasta.',
      noTemplates: 'Nu există încă șabloane de raport.',
      custom: 'Șablon individual',
      defaultWithPoints: 'Șablon standard - cu puncte procentuale',
      default: 'Șablon standard - fără puncte procentuale',
      createTemplateInfo: 'Puteți edita șablonul de raport numai după ce a fost creat.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Grup de permisiuni global',
    notAllowed: 'Nu aveți permisiunea de a modifica permisiunile acestui șablon.',
    all: 'Formularul poate fi completat de toți utilizatorii',
    groups: 'Formularul poate fi completat doar de utilizatorii din aceste grupuri de autorizare',
    noGroupLicence: 'Licența Blink Check pentru acest grup nu este activată.',
    overridingPermssion: 'Opțiunea „Setări de sistem” din grupul de permisiuni „Administrator de sistem” oferă automat utilizatorilor toate permisiunile pentru acest formular.',
    onlyLicensedPermissionGroups: 'Sunt afișate numai grupurile de autorizare cărora le este atribuită o licență Blink Check.',
    canUpdateChecklist: 'Editați șablonul de formular',
    canFillChecklist: 'completează formularul',
    canReadChecklistDataOwn: 'Vizualizați și evaluați formularele autocompletate',
    canReadChecklistDataOwnEmployees: 'Vizualizați și evaluați toate formularele completate de către angajații dvs',
    canReadChecklistDataOwnLocations: 'Vizualizați și evaluați toate formularele completate ale propriilor obiecte',
    canReadChecklistDataAll: 'Vizualizați și evaluați toate formularele completate'
  },
  companyConfig: {
    companyConfig: 'Configurarea chiriașului',
    logoInfo: 'Apare în antet în șabloanele standard de rapoarte.',
    footer: 'subsol',
    footerInfo: 'Apare în subsol în șabloanele standard de rapoarte.',
    offline: 'Vă rugăm să stabiliți o conexiune la internet pentru a putea edita configurația.'
  },
  switchCompanyDialogHeader: 'Schimbați clienții',
  switchCompanyDialogText: 'Dacă schimbați clientul, toate formularele în curs vor fi resetate. Doriți să continuați?',
  results: {
    results: 'Evaluare',
    details: 'Detalii',
    created: 'Creată',
    result: 'Rezultat',
    noReportJsonExists: 'Raportul nu a fost încă creat. Raportul este generat cu întârziere, vă rugăm să încercați din nou mai târziu.',
    noResults: 'Nu există încă formulare completate.',
    noResultsForDateFilter: 'Nu există formulare completate pentru perioada selectată.',
    periodFrom: 'Perioada de',
    periodTo: 'perioada în sus',
    location: 'obiect',
    createUser: 'Creator',
    loginUser: 'Angajatii',
    checklist: 'Șablon de formular',
    user: 'utilizator',
    recentResults: 'Formulare completate în prezent',
    recent: 'Actual',
    pointOf: '{{ itemPoints }} din {{ maxItemPoints }} puncte',
    checklistCreatedByAt: 'Formular completat de {{ firstName }} {{ lastName }} pe {{ createDate }} la {{ createTime }} seara.',
    linkedTask: 'Bilet conectat: #{{ taskId }} {{ taskTitle }}',
    offline: 'Vă rugăm să stabiliți o conexiune la internet pentru a putea evalua formularele completate.',
    delete: {
      title: 'Ștergeți formularul completat',
      message: 'Chiar doriți să ștergeți formularul completat „{{checklistName}}”?',
      success: 'Formularul completat a fost eliminat'
    },
    editReferenceTimestamp: 'Editați data de referință',
    editReferenceTimestampDescription: 'Data de referință determină ora la care a fost completat formularul. Acesta poate diferi de momentul la care a fost trimis formularul.'
  },
  dependencies: {
    dependency: 'Dependenţă',
    addTitle: 'Vă rugăm să selectați valoarea de comparație',
    addMessage: 'Vă rugăm să furnizați valoarea care trebuie îndeplinită pentru ca articolul să fie afișat.',
    addTitleMulti: 'Vă rugăm să selectați valorile de comparație',
    addMessageMulti: 'Vă rugăm să furnizați valorile, dintre care una trebuie îndeplinită pentru ca elementul să fie afișat.',
    present: 'Disponibil',
    notPresent: 'Indisponibil',
    noResults: 'Nu există dependențe. Noile dependențe pot fi adăugate prin „Adăugați dependență”.',
    noAvailableItems: 'Există prea puține elemente în formular pentru a crea dependențe. Vă rugăm să adăugați mai multe articole mai întâi.',
    noAvailableItemsInGroup: 'Există prea puține elemente în pagină pentru a crea dependențe. Vă rugăm să selectați o pagină care conține articole.',
    dependencyAdded: 'S-a adăugat dependența.',
    dependencyRemoved: 'Dependența a fost eliminată.',
    dependencyInfo: 'După ce adăugați o dependență, acest element ({{elementName}}) va apărea numai dacă elementul(ele) dependent(e) au fost completate.',
    true: 'da',
    false: 'Nu',
    '#not_empty#': 'Disponibil',
    '#empty#': 'Indisponibil',
    frown: 'Trist',
    meh: 'Merge bine',
    smile: 'Fericit'
  },
  noChecklists: 'Nu există formulare disponibile.',
  updating: 'Blink Check este în curs de actualizare.',
  installing: 'Se instalează versiunea {{version}}...',
  appName: 'Verificare clipire',
  notAvailableInPublicChecklists: ' (Nu este disponibil în șabloanele de formulare publice)',
  pdfAssignTemplate: 'Trebuie să atribuiți formularului un șablon de raport înainte de a putea folosi descărcarea PDF.'
};
