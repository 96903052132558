import { Injectable } from '@angular/core';
import { BlinkCompany } from '@blink/shared-blink-types';
import { ModalController } from '@ionic/angular';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { Router } from '@angular/router';
import { CompanyRepository } from '@blink/api';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private modalController: ModalController,
    private companyRepository: CompanyRepository,
    private router: Router,
  ) {}

  async selectCompany() {
    const selectCompanyModal = await this.modalController.create({
      component: SelectCompanyComponent,
      showBackdrop: true,
      backdropDismiss: false,
    });
    selectCompanyModal.onWillDismiss().then((response) => {
      this.onCompanySelected(response.data);
    });
    return selectCompanyModal.present();
  }

  onCompanySelected(selectedCompany: BlinkCompany) {
    this.companyRepository.setActiveCompany(selectedCompany.Id);

    const mainMenuRootPath = this.router.url
      .split('/')[1]
      .replace(/\blogin\b/gi, 'home');
    void this.router.navigate([mainMenuRootPath]);
  }
}
