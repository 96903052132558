import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BlinkIcon } from '@blink/ui';
import { PopoverController } from '@ionic/angular';
import { GlobalTranslateService } from '@blink/util';

@Component({
  selector: 'check-checklist-dropdown-popover',
  templateUrl: './checklist-dropdown.component.html',
  styleUrls: ['./checklist-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChecklistDropdownComponent {
  BlinkIcon = BlinkIcon;

  @Input() showExportButton= false;
  @Input() showDuplicateButton = false;
  @Input() showCopyPublicLinkButton= false;

  constructor(public popoverController: PopoverController,
              public t: GlobalTranslateService) {
  }
}
