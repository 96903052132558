<ui-page [customBackButtonVisible]=" options.backOptions?.showBackButton || false"
         (customBackButtonClick)="options.backOptions?.backButtonClick(); closeModal()"
         [customBackButtonIcon]="options.backOptions?.backButtonIcon || BlinkIcon.TransitionBackward"
         [title]="options.title"
         [cancelButtonVisible]="false">

  <div class="modal-content ion-padding"
       [cy]="options.cy">
    <p class="mt-0 mb-0"
       [innerHTML]="options.text | translate: options.textOptions">
    </p>
  </div>

  <ng-container customActions>
    <ion-button *ngFor="let button of options.buttons"
                [fill]="button.fill"
                [color]="button.color"
                (click)="handle(button)"
                [cy]="button.cy">
        <span [class.text-black]="button.fill === 'outline'">
          {{ button.label | translate }}
        </span>
    </ion-button>
  </ng-container>

</ui-page>


<!--&lt;!&ndash; duplication! &ndash;&gt;-->
<!--<ion-footer>-->
<!--  <ion-row class="ion-justify-content-end">-->
<!--    <ion-col size="auto">-->
<!--      <ion-button *ngFor="let button of options.buttons"-->
<!--                  [fill]="button.fill" [color]="button.color"-->
<!--                  (click)="handle(button)"-->
<!--                  [cy]="button.cy">-->
<!--        <span [class.text-black]="button.fill === 'outline'">-->
<!--          {{ button.label | translate }}-->
<!--        </span>-->
<!--      </ion-button>-->
<!--    </ion-col>-->
<!--  </ion-row>-->
<!--</ion-footer>-->

