import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreBlinkIdComponent } from './blink-id/blink-id';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { BInputTypeaheadComponent } from './b-input-typeahead/b-input-typeahead.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CoreBlinkIdComponent,
    BInputTypeaheadComponent
  ],
  exports: [
    CoreBlinkIdComponent,
    BInputTypeaheadComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslateModule
  ]
})
export class BlinkCoreModule {
}
