import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import SignaturePad from 'signature_pad';
import { GlobalTranslateService } from '@blink/util';

@Component({
  selector: 'ui-dialog-signature',
  styleUrls: ['ui-dialog-signature.component.scss'],
  templateUrl: 'ui-dialog-signature.component.html'
})
export class UiDialogSignatureComponent {
  @ViewChild('signatureCanvas', { static: true }) signatureCanvas;
  signaturePad: SignaturePad;

  constructor(public modalCtrl: ModalController,
              public t: GlobalTranslateService) {
    window['signature'] = this;
  }

  ionViewDidEnter() {
    const e = document.querySelector('ui-dialog-signature ion-content');

    this.signatureCanvas.nativeElement.width = e.clientWidth - 16 * 2;
    this.signatureCanvas.nativeElement.height = e.clientHeight - 110;

    this.signaturePad = new SignaturePad(
      this.signatureCanvas.nativeElement,
      {
        minWidth: 1.5,
        backgroundColor: '#ffffff',
        penColor: '#666a73'
      });
  }

  savePad() {
    const signatureBase64 = this.signaturePad.toDataURL('image/jpeg');
    this.modalCtrl.dismiss(signatureBase64);
  }

  clearPad() {
    this.signaturePad.clear();
  }
}
