export const resizeImage = (base64: string, max = 1000): Promise<string> => {
  return new Promise((res, rej) => {
    const img = new Image();

    img.src = base64;
    img.onload = ev => {

      const imgWidth = ev.currentTarget['width'];
      const imgHeight = ev.currentTarget['height'];

      console.log("imgWidth", imgWidth);
      console.log("imgHeight", imgHeight);

      if (imgWidth < max && imgHeight < max) {
        res(base64);
      } else {
        const largerSide = imgHeight > imgWidth ? imgHeight : imgWidth;
        const factor = largerSide / max;

        const newWidth = imgWidth / factor;
        const newHeight = imgHeight / factor;

        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL("image/jpeg", 0.92);
        res(data);
      }
    };
    img.onerror = error => rej(error);
  });
}
