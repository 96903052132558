export default {
  barcodeScanner: {
    permissionHeader: 'Engedély',
    permissionMessage: 'Ha engedélyt szeretne adni a kamera használatára, engedélyezze azt az alkalmazás beállításaiban.',
    scanPrompt: 'Scan {{thing}}',
    enterPrompt: 'Írja be a következőt: {{thing}}',
    blinkId: 'Blink ID',
    isNoBlinkId: 'A beolvasott QR-kód nem érvényes pislogási azonosító',
    objectCode: 'Object Code'
  },
  camera: {
    promptLabelHeader: 'forrás kiválasztása',
    promptLabelPicture: 'fotózni',
    promptLabelPhoto: 'Válassz a galériából'
  },
  network: {
    changed: {
      connected: 'Internet kapcsolat létrejött',
      disconnected: 'Az internetkapcsolat megszakadt'
    }
  },
  file: { downloadStarted: 'A letöltés elindult.' },
  nfc: { wrongCode: 'A beolvasott QR-kód nem tárgykód!', error: 'NFC hiba' }
};
