import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

type ToggleProps = FormlyFieldProps

export interface FormlyToggleFieldConfig extends FormlyFieldConfig<ToggleProps> {
  type: 'toggle' | Type<UiFormlyFieldToggleComponent>;
}

@Component({
  selector: 'ui-formly-field-ion-toggle',
  styles: [':host {margin: 0;}'],
  template: `
      <ion-item class="ion-margin-bottom"
                lines="none">
          <ion-toggle slot="start"
                      [formControl]="formControl"
                      [ionFormlyAttributes]="field"
                      labelPlacement="end">
              {{ props.label }}
          </ion-toggle>
      </ion-item>
      <ion-item lines="none" *ngIf="showError">
          <ion-label>
              <ion-text color="danger">
                  <p>
                      <formly-validation-message [field]="field"></formly-validation-message>
                  </p>
              </ion-text>
          </ion-label>
      </ion-item>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFormlyFieldToggleComponent extends FieldType<FieldTypeConfig<ToggleProps>> implements OnInit {

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    this.elementRef.nativeElement.setAttribute('slot', 'start');
  }
}
