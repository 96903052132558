<ui-page [title]="t.signature.signature"
         [modalDismiss]="true"
         [cancelButtonVisible]="false">
  <h5>{{ t.signature.pleaseSign | translate }}:</h5>
  <br/>
  <canvas class="signature-pad-canvas" #signatureCanvas></canvas>
</ui-page>

<ion-footer>
  <ion-row class="ion-justify-content-end">
    <ion-col size="auto">
      <ion-button fill="outline"
                  color="danger"
                  (click)="clearPad()">
        {{ t.signature.delete | translate }}
      </ion-button>
      <ion-button color="success"
                  (click)="savePad()"
                  cy="save-signature-button">
        {{ t.signature.save | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
