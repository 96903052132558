<ui-page [title]="t.searchLocation"
         [modalDismiss]="true"
         [contentPadding]="false">
  <div fixed class="ion-padding" style="background-color: white; color: black;">
    <ion-row class="ion-align-items-center" #searchRow>
      <ion-col size="12">
        <ion-item class="blink-item-input" color="input">
          <ion-input type="text" (ionInput)="search($event)"
                     placeholder="{{ t.searchLocationPlaceholder | translate}}"
                     label="{{ t.searchTerm | translate }}"
                     labelPlacement="stacked">
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="auto">
        <ion-toggle (ionChange)="toggleShowInactive($event)">
          {{ t.showInactive | translate }}
        </ion-toggle>
      </ion-col>
    </ion-row>
  </div>

  <cdk-virtual-scroll-viewport *ngIf="locations$ | async as locations; else spinner"
                               style="height: 100%"
                               itemSize="60" minBufferPx="1500" maxBufferPx="2000">
    <ion-item *cdkVirtualFor="let location of locations; trackBy: trackById"
              button style="--min-height: 60px; --inner-padding-start: 6px;"
              (click)="onCostCenterSelected(location)"
              cy="cost-centre" [cyId]="location.Id">
      <ion-label>
        <small>{{ location.CostCenterNumber }}</small>
        <h3>
          {{ location.Name }}
          <span class="text-italic"
                *ngIf="!location.IsActive">
              ({{ t.inactive | translate }})
            </span>
        </h3>
      </ion-label>
      <ion-button slot="end" fill="outline">
        {{ t.select | translate }}
      </ion-button>
    </ion-item>
  </cdk-virtual-scroll-viewport>

  <ng-template #spinner>
    <div class="spinner-container">
      <ion-spinner color="primary"></ion-spinner>
    </div>
  </ng-template>

</ui-page>
