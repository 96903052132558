import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CapacitorPluginTranslateService } from '../i18n/capacitor-plugin-translate.service';
import { TranslateService } from '@ngx-translate/core';
import { resizeImage } from '@blink/util';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(private translate: TranslateService,
              private t: CapacitorPluginTranslateService) {
  }

  /**
   * Uses Camera on Apps and FileInput in Browsers.
   * User can choose to take picture or select from gallery.
   */
  getPhotoAsBase64() {
    return Camera.getPhoto({
      saveToGallery: false,
      width: 1000,
      height: 1000,
      webUseInput: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      promptLabelHeader: this.translate.instant(this.t.camera.promptLabelHeader),
      promptLabelPicture: this.translate.instant(this.t.camera.promptLabelPicture),
      promptLabelPhoto: this.translate.instant(this.t.camera.promptLabelPhoto),
      promptLabelCancel: this.translate.instant(this.t.global.cancel)
    }).then((imageData) => {
      const base64Image = 'data:image/jpeg;base64,' + imageData.base64String;
      return resizeImage(base64Image)
    });
  }

  /**
   * Uses Gallery on Apps and FileInput in Browsers.
   * User can choose picture from gallery.
   */
  async choosePhotoAsBase64(options: { resize: number }) {
    return await this.chooseOrTake(CameraSource.Photos, options);
  }

  /**
   * Uses Gallery on Apps and FileInput in Browsers.
   * User can take picture.
   */
  async takePhotoAsBase64(options: { resize: number }) {
    return await this.chooseOrTake(CameraSource.Camera, options);
  }

  /**
   * Uses Gallery on Apps and FileInput in Browsers.
   * Based on CameraSource you can choose between taking picture or choosing from gallery without prompt
   */
  async chooseOrTake(cameraSource: CameraSource, options: { resize: number }) {
    return Camera.getPhoto({
      saveToGallery: false,
      width: options.resize,
      height: options.resize,
      webUseInput: true,
      resultType: CameraResultType.Base64,
      source: cameraSource,
      promptLabelHeader: this.translate.instant(this.t.camera.promptLabelHeader),
      promptLabelPicture: this.translate.instant(this.t.camera.promptLabelPicture),
      promptLabelPhoto: this.translate.instant(this.t.camera.promptLabelPhoto),
      promptLabelCancel: this.translate.instant(this.t.global.cancel)
    }).then((imageData) => {
      const base64Image = 'data:image/jpeg;base64,' + imageData.base64String;
      return resizeImage(base64Image, options.resize)
    });
  }

  async checkPermissions() {
    return await Camera.checkPermissions();
  }

  requestCameraPermissions() {
    return Camera.requestPermissions({ permissions: ['camera'] });
  }

  requestMediaPermissions() {
    return Camera.requestPermissions({ permissions: ['photos'] });
  }
}
