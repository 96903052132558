export default {
  barcodeScanner: {
    permissionHeader: 'ovlaštenje',
    permissionMessage: 'Ako želite dati dopuštenje za korištenje vaše kamere, omogućite je u postavkama aplikacije.',
    scanPrompt: 'Skeniraj {{thing}}',
    enterPrompt: 'Unesite {{thing}}',
    blinkId: 'Blink ID',
    isNoBlinkId: 'Skenirani QR kod nije važeći Blink ID',
    objectCode: 'objektni kod'
  },
  camera: {
    promptLabelHeader: 'odaberite izvor',
    promptLabelPicture: 'uslikaj',
    promptLabelPhoto: 'Odaberite iz galerije'
  },
  network: {
    changed: {
      connected: 'Internetska veza uspostavljena',
      disconnected: 'Internetska veza prekinuta'
    }
  },
  file: { downloadStarted: 'Preuzimanje je počelo.' },
  nfc: {
    wrongCode: 'Skenirani QR kod nije objektni kod!',
    error: 'NFC greška'
  }
};
