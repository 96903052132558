import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { of, ReplaySubject, Subject } from 'rxjs';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  selectMany,
  updateEntities,
  updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';

import { CheckGroupDependency } from './group-dependency';
import { CheckItem } from '../item/item';
import { checkDependency } from '../check-dependency';
import { PersistStateService } from '../../shared';

interface GroupDependencyRepoProps {
  checklistGroupDependencyIdMap: IdMap;
  groupDependenciesIdMap: IdMap;
}

const store = createStore(
  checkStoreConfig('groupDependencies'),
  withEntities<CheckGroupDependency, 'Id'>({ idKey: 'Id' }),
  withProps<GroupDependencyRepoProps>({
    groupDependenciesIdMap: {},
    checklistGroupDependencyIdMap: {}
  })
);

@Injectable({ providedIn: 'root' })
export class CheckGroupDependencyRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();
  groupAppeared$ = new Subject<number>();

  constructor(private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(dependencies: CheckGroupDependency[], isCompleteUpdate: boolean, removeAbsentForChecklistId: number = null) {
    const deleteGroupDependencyIds = store
      .getValue()
      .checklistGroupDependencyIdMap[removeAbsentForChecklistId];

    store.update(
      deleteEntities(deleteGroupDependencyIds),
      upsertEntities(dependencies),
      idMapper(
        'checklistGroupDependencyIdMap',
        dependencies,
        'ChecklistId',
        isCompleteUpdate
      ),
      idMapper(
        'groupDependenciesIdMap',
        dependencies,
        'GroupId',
        isCompleteUpdate
      )
    );
  }

  delete(id: number) {
    store.update(
      deleteEntities(id),
      removeMappedId('groupDependenciesIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistGroupDependencyIdMap = store.getValue().checklistGroupDependencyIdMap;
    let deleteGroupDependencyIds = [];
    checklistIds.forEach(x => {
      deleteGroupDependencyIds = deleteGroupDependencyIds.concat(checklistGroupDependencyIdMap[x]);
    });
    store.update(
      deleteEntities(deleteGroupDependencyIds),
      removeKeys('checklistGroupDependencyIdMap', checklistIds)
    );
  }

  getMany(ids: number[]) {
    if (ids) {
      return store.pipe(selectMany(ids));
    }
    return of([]);
  }

  getGroupDependencies(groupId: number) {
    return store.pipe(
      select(state => state.groupDependenciesIdMap[groupId]),
      map(groupDependencyIds => groupDependencyIds ? groupDependencyIds : []),
      switchMap(groupDependencyIds => this.getMany(groupDependencyIds))
    );
  }

  checkDependency(dependencyId: number, dependencyItem: CheckItem, value: any) {
    const dependency = store.getValue().entities[dependencyId];
    if (!dependency) {
      return;
    }
    const fulfilled = checkDependency(dependency, dependencyItem, value);

    if (!dependency.fulfilled && fulfilled) {
      this.groupAppeared$.next(dependency.GroupId);
    }

    store.update(updateEntities(
      dependencyId,
      dep => ({ ...dep, fulfilled })
    ));
  }

  resetDependenciesForChecklist(checklistId: number) {
    store.update(
      updateEntitiesByPredicate(
        dep => dep.ChecklistId === checklistId,
        { fulfilled: false }
      )
    );
  }

  resetDependenciesForItems(itemIds: number[]) {
    store.update(
      updateEntitiesByPredicate(
        x => {
          return itemIds.includes(x.DependencyId)
        },
        { fulfilled: false }
      )
    );
  }
}
