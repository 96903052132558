export default {
  cancel: 'Interromper',
  blinkSlogan: 'Blink - A aplicação para empresas de limpeza de edifícios',
  all: 'Todos',
  hello: 'Olá',
  description: 'Descrição',
  ok: 'Ok',
  reset: 'Reiniciar',
  edit: 'Editar',
  finish: 'Bloqueio',
  notice: 'Nota',
  content: 'Conteúdo',
  back: 'Voltar',
  close: 'Fechar',
  backToHome: 'Voltar à página inicial',
  proceed: 'Mais',
  done: 'Pronto',
  delete: 'Eliminar',
  successful: 'Bem-sucedido',
  error: 'Erro',
  ERROR_OCCURRED: 'Ocorreu um erro ou os dados não estão correctos. Por favor, tente novamente.',
  internetRequired: 'Internet necessária',
  configuration: 'Configuração',
  logo: 'Logótipo',
  uploadLogo: 'Carregar logótipo',
  deleteLogo: 'Eliminar o logótipo',
  duplicate: 'Duplicado',
  importExport: 'Importação / Exportação',
  import: 'Importação',
  doImport: 'Importação',
  export: 'Exportação',
  doExport: 'Exportação',
  excelExport: 'Exportação do Excel',
  excelExportDownloaded: 'Exportação do Excel descarregada',
  excelExportError: 'Falha na exportação do Excel',
  pdfDownload: 'Descarregar PDF',
  pdfDownloaded: 'PDF descarregado',
  configSaved: 'A configuração foi guardada.',
  send: 'Enviar',
  connecting: 'Ligar...',
  or: 'ou',
  select: 'Selecionar',
  today: 'Hoje',
  camera: 'Câmara',
  gallery: 'Galeria',
  files: 'Ficheiros',
  upload: 'Carregar ficheiros',
  chooseFiles: 'Selecionar ficheiros',
  filesTransferring: 'Transmitir ficheiros...',
  filesTransferringWait: 'Aguarde enquanto os ficheiros são transmitidos.',
  filesTransferred: 'Ficheiros transmitidos com sucesso.',
  download: 'Descarregar',
  showInactive: 'Mostrar inativo',
  copyLink: 'Copiar ligação',
  linkCopied: 'A ligação foi copiada para a área de transferência.',
  permissions: 'Autorizações',
  email: 'Correio eletrónico',
  emailConfig: 'Configuração do correio eletrónico',
  title: 'Título',
  user: 'Utilizador',
  logout: 'Terminar sessão',
  system: 'Sistema',
  appVersion: 'Versão (App)',
  apiVersionCore: 'Versão\n(API principal)',
  apiVersionCheck: 'Versão\n(Verificar API)',
  deviceInfo: 'Informações sobre o dispositivo',
  installBlinkTime: 'Instalar o tempo de intermitência',
  installBlinkMe: 'Instalar o Blink Me',
  installing: 'A versão {{version}} está instalada ...',
  companyRequired: 'Selecionar cliente',
  companyRequiredInfo: 'É necessário selecionar um cliente para continuar como administrador de sistema.',
  pleaseChoose: 'Por favor, seleccione',
  pleaseFill: 'Preencher',
  noEntitiesFound: 'Não foi possível encontrar nenhum elemento.',
  addCustom: 'Criar elemento:',
  typeToSearchText: 'Introduza dois ou mais caracteres...',
  loading: 'Os dados são carregados...',
  offline: 'Fora de linha',
  youAreOffline: 'Está offline!',
  by: 'de',
  date: 'Data',
  time: 'Tempo',
  searchTitle: 'Pesquisar',
  searchTerm: 'Pesquisar palavra-chave',
  searchLoginUser: 'Pesquisa de empregados',
  searchLoginUserPlaceholder: 'Procurar por nome ou nome próprio',
  location: 'Objeto',
  searchLocation: 'Objeto de pesquisa',
  searchLocationPlaceholder: 'Pesquisa por nome de objeto, número ou etiquetas',
  deactivate: 'Desativar',
  active: 'Ativo',
  inactive: 'Inativo',
  tag: 'Dia',
  tags: 'Etiquetas',
  color: 'Cor',
  icon: 'Ícone',
  language: 'Língua',
  general: 'Geral',
  yes: 'Sim',
  no: 'Não',
  searchThrough: 'Navegar',
  name: 'Nome',
  create: 'Criar',
  save: 'Guardar',
  home: 'Início',
  profile: 'Perfil',
  templates: 'Modelos',
  noAssignment: 'Não atribuído',
  sort: {
    change: 'Ordem de modificação',
    save: 'Gravar sequência',
    saved: 'A sequência foi guardada.'
  },
  signature: {
    signature: 'Assinatura',
    pleaseSign: 'Por favor, assine',
    clickHereToSign: 'Clique aqui para assinar',
    addSignature: 'Adicionar assinatura',
    save: 'Assumir o controlo',
    delete: 'Eliminar'
  },
  formError: {
    min: 'O valor tem de ser superior a {{value}}.',
    min2: 'O valor deve ser maior ou igual a {{value}}.',
    max: 'O valor deve ser menor ou igual a {{value}}.',
    requiredBecause: 'Valor necessário porque {{name}} está preenchido.',
    isRequired: '{{name}} é obrigatório.',
    numberInUse: 'O número pessoal já está a ser utilizado.',
    maxlength: 'O {{name}} não deve conter mais do que {{value}} caracteres.',
    email: 'Introduza um endereço de correio eletrónico válido.',
    faultyBlinkId: 'ID intermitente com defeito'
  },
  scan: {
    choose: 'Atribuir nova ID de intermitência',
    text: 'Digitalizar o código QR no cartão de registo.',
    scan_now: 'Verificar agora',
    enter_manually: 'Introduzir manualmente'
  },
  removeImage: 'Eliminar imagem',
  tickets: { assignee: 'Editor', dueTo: 'Vencimento até' },
  ngSelect: {
    typeToSearch: 'Introduza 3 letras ou mais.',
    loading: 'carregar dados...',
    notFound: 'Nenhum {{title}} encontrado.',
    clearAll: 'Reiniciar'
  },
  help: {
    pageTitle: 'Ajuda',
    text1: 'A nossa equipa de apoio terá todo o prazer em responder a quaisquer perguntas que possa ter:',
    phone: 'Telefone',
    mail: 'Correio eletrónico',
    information: 'Informações',
    documentation: 'Documentação em linha',
    documentationText: 'Na nossa documentação online encontrará instruções e vídeos para todas as áreas importantes do Blink.',
    support: 'Apoio'
  },
  REGISTRATION: {
    WELCOME: 'Bem-vindo!',
    chooseMethod: 'Que método gostaria de utilizar para se registar?',
    withBlinkId: 'Cartão de identificação BLINK',
    withCredentials: 'Nome de utilizador / Palavra-passe',
    REGISTER: 'Registo',
    ALREADY_REGISTERED: 'Tenho uma conta Blink.',
    INVALID_CARD: 'Cartão não válido.',
    noBlinkId: 'O código QR digitalizado não é uma ID Blink válida',
    scanBlinkId: 'Leitura de ID intermitente',
    ERROR: 'Não foi possível concluir o registo. Introduza um endereço de correio eletrónico ou um número de telemóvel diferente.',
    NO_INTERNET_EXPLORER: 'Infelizmente, o Blink não é compatível com o Internet Explorer. Por favor, utilize o Microsoft Edge, Firefox ou Chrome. Obrigado pela sua compreensão.',
    loginNow: 'Registar agora',
    help: 'Ajuda com o registo',
    helpText: 'A aplicação Blink Manager permite-lhe gerir os tempos, o pessoal e a configuração do sistema. O Blink Manager destina-se apenas a supervisores, gestores de propriedades, gerência e administração! Para um registo de tempo puro, utilize a "Blink Time App"!<br/><br/>Existem dois métodos que pode utilizar para se registar:<br/><br/><b>Inscrever-se com o cartão de identificação Blink</b><br/><br/>Se tiver um cartão de identificação Blink, escolha este método. Quando se registar pela primeira vez, ser-lhe-á pedido um endereço de e-mail ou um número de telemóvel. Precisamos dele para lhe enviar uma palavra-passe única para o registo. Não é necessário memorizar a palavra-passe única. Quando se registar novamente, enviar-lhe-emos uma nova palavra-passe única.<br/><br/><b>Entrar com nome de utilizador e palavra-passe</b><br/><br/>Só receberá o nome de utilizador e a palavra-passe do seu administrador de sistema a pedido. Este método só está disponível para os utilizadores com direitos administrativos.<br/><br/><b>Suporte</b><br/><br/>Se tiver problemas em iniciar sessão, escreva um e-mail para support@blink-time.de ou ligue para 0911 240 330 0.',
    userOffline: 'Ligue-se à Internet para utilizar o Blink me.',
    login: 'Registar agora',
    codeError: 'Por favor, verifique a sua entrada e tente novamente.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Parabéns!',
    YOU_DID_IT: 'Conseguiu-o.',
    SIGNED_IN: 'Registado',
    SIGNED_IN_TEXT: 'Registou-se com sucesso e pode agora utilizar a aplicação.',
    START_USAGE: 'Agora pode utilizar a aplicação.',
    START: 'Início'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Foi-lhe enviada uma mensagem eletrónica com a qual pode alterar os seus dados de acesso.',
    FORGOT_PASSWORD: 'Esqueceu-se da palavra-passe?',
    errors: {
      notAllowed: 'Não é possível repor a palavra-passe deste utilizador.',
      invalidToken: 'O código para redefinir a sua palavra-passe expirou. Solicite um novo código.'
    },
    reset: {
      backToRoot: 'Voltar à página de início de sessão',
      renewCode: 'Pedir novo código',
      resetPassword: 'Repor a palavra-passe',
      success: 'A sua palavra-passe foi reposta com sucesso.',
      password1: 'A sua nova palavra-passe',
      password2: 'Repetir a palavra-passe',
      validationError: 'A palavra-passe não está em conformidade com as nossas directrizes relativas a palavras-passe!',
      passwordsNotEqual: 'As palavras-passe não são idênticas! Por favor, verifique a sua entrada.',
      passwordValidation: 'A palavra-passe deve ter pelo menos 6 caracteres e conter uma letra, um número e um carácter especial.'
    },
    FORGOT_PASSWORD_TEXT: 'Por favor, insira seu endereço de e-mail e o sistema de destino para redefinir sua senha.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Verificar dados',
    TEXT: 'Se precisarmos de redefinir a sua palavra-passe, precisaremos do seu endereço de correio eletrónico e, opcionalmente, do seu número de telemóvel.',
    EMAIL: 'Endereço eletrónico',
    MOBILE: 'Número de telemóvel'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Introduzir código',
    EMAIL_TEXT: 'Introduza o código que enviámos para o seguinte endereço de correio eletrónico:',
    SMS_TEXT: 'Introduza o código que enviámos para o seguinte número de telemóvel:',
    NO_CODE: 'Não recebi um código.',
    CODE_LENGTH_ERROR: 'O código introduzido tem mais de seis caracteres. Por favor, introduza o seu código novamente.',
    CODE_SENT: 'O código foi enviado.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Sem código',
    TEXT1: 'Não recebeu um código?',
    RESEND_CODE: 'Enviar novamente o código',
    TEXT2: 'Se o seu endereço de correio eletrónico ou número de telemóvel armazenado no sistema tiver mudado, contacte o seu administrador.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Introduza o seu Blink ID e o seu código. Encontrará todas as informações no seu cartão de registo.',
    textCredentials: 'O administrador do sistema deve fornecer-lhe o nome de utilizador e a palavra-passe.',
    BLINK_ID_INVALID: 'ID intermitente com defeito',
    loginCardNotFound: 'A ID de intermitência já foi atribuída ou não existe.',
    WRONG_CREDENTIALS: 'Os dados introduzidos não estão correctos. Por favor, verifique a sua entrada.',
    loginViaBlinkId: 'Registo na BlinkID',
    loginViaCredentials: 'Iniciar sessão com dados de acesso',
    CODE: 'Código',
    codePlaceholder: 'O seu código de acesso pessoal',
    company: 'Sistema',
    password: 'Palavra-passe',
    FORGOT_PASSWORD: 'Esqueci-me da minha palavra-passe',
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Identificação',
    TEXT_1: 'Para podermos identificá-lo de forma exclusiva, enviar-lhe-emos um código único por SMS ou correio eletrónico.',
    TEXT_2: 'Para tal, introduza o seu número de telemóvel ou endereço de correio eletrónico.'
  },
  update: {
    update: 'Atualização',
    latestVersion: 'Está disponível uma nova versão. Actualize a sua aplicação para tirar o máximo partido da mesma.',
    warnVersion: 'Está a utilizar uma versão desactualizada desta aplicação. Actualize-a o mais rapidamente possível.',
    blockVersion: 'Está a utilizar uma versão desactualizada desta aplicação que já não é suportada. Actualize agora.'
  },
  inProgress: 'Em andamento',
  video_offline: 'Os vídeos não podem ser visualizados no modo offline. Conecte-se à Internet.',
  location_offline: 'O objeto não é salvo offline, conecte-se à Internet para recuperar o código QR.',
  invalidObjectCode: 'Nenhum código de objeto válido.',
  invalidValue: 'Valor inválido',
  ERROR: {
    ERROR_400: 'Ocorreu um erro. Por favor, tente novamente mais tarde. (Código de erro: 400)',
    ERROR_403: 'Você não tem permissão para realizar esta ação',
    REFRESH_TOKEN_403: 'Seus dados de acesso não são mais válidos. Faça login novamente!',
    ERROR_500: 'Ocorreu um erro. Por favor, tente novamente mais tarde. (Código de erro: 500)'
  },
  list: {
    entityPluralDefault: 'Resultados',
    emptyState: 'Não existe {{entityPlural}}.',
    noResults: 'Nenhum {{entityPlural}} foi encontrado.',
    selectAll: 'Selecionar tudo {{entityPlural}}',
    massAction: 'Promoção para {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Selecionar tudo {{count}}',
    massActionDeSelectAllGlobal: 'Desmarcar tudo {{count}}'
  },
  languages: {
    ar: 'árabe',
    de: 'Alemão',
    es: 'Espanhol',
    fr: 'Francês',
    hr: 'croata',
    it: 'italiano',
    pt: 'Português',
    ru: 'russo',
    bg: 'búlgaro',
    en: 'Inglês',
    fa: 'persa',
    el: 'grego',
    hu: 'húngaro',
    pl: 'polonês',
    ro: 'romena',
    tr: 'turco',
    uk: 'ucraniano',
    cs: 'Tcheco',
    sk: 'Eslovaco'
  },
  now: 'Agora',
  allCompanies: 'Todos os clientes',
  createDate: 'Data de criação',
  company: 'cliente',
  new: 'Novo',
  of: 'de',
  request: {
    deleteTitle: 'Excluir {{item}}',
    confirmDelete: "Você realmente deseja excluir a entrada '{{itemName}}'?",
    itemAdded: '{{item}} foi criado.',
    itemAddedError: '{{item}} não pôde ser criado.',
    itemSaved: '{{item}} foi salvo.',
    itemSavedError: '{{item}} não pôde ser salvo.',
    itemRemoved: '{{item}} foi excluído.',
    itemRemovedError: '{{item}} não pôde ser excluído.'
  },
  news: { pageTitle: 'Notícias', edit: 'Editar reportagens' },
  profiles: {
    loginProfiles: 'Perfis de login',
    add: 'Perfil de login',
    logout: 'Se você sair, todos os dados e perfis locais serão excluídos! Deseja cancelar a inscrição?',
    login: 'Registro',
    deleteConfirmation: 'Você realmente deseja excluir o perfil?',
    changed: 'Perfil alterado com sucesso',
    deleted: 'Perfil excluído com sucesso'
  },
  deleteAttachment: {
    confirmTitle: 'Excluir anexo',
    confirmText: 'Tem certeza de que deseja excluir este anexo?'
  },
  comments: {
    addComment: 'adicionar comentário',
    addCommentInfo: 'O comentário fica visível para qualquer pessoa que possa visualizar este ticket.',
    comment: 'Comente',
    deleteComment: 'Excluir comentário',
    deleteCommentConfirmation: 'Você realmente deseja excluir o comentário?'
  }
};
