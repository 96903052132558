import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShellComponent } from './app-shell/app-shell.component';
import {BlinkUiModule} from "@blink/ui";
import {IonicModule} from "@ionic/angular";

@NgModule({
  imports: [CommonModule, BlinkUiModule, IonicModule],
  declarations: [AppShellComponent],
  exports: [AppShellComponent],
})
export class ComponentsAppShellModule {}
