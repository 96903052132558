import { Injectable } from '@angular/core';
import { GlobalTranslateService, TranslateGenericClass } from '@blink/util';
import capacitorPluginAr from './capacitor-plugin-ar';
import capacitorPluginDe from './capacitor-plugin-de';
import capacitorPluginEn from './capacitor-plugin-en';
import capacitorPluginBg from './capacitor-plugin-bg';
import capacitorPluginEl from './capacitor-plugin-el';
import capacitorPluginEs from './capacitor-plugin-es';
import capacitorPluginRu from './capacitor-plugin-ru';
import capacitorPluginFa from './capacitor-plugin-fa';
import capacitorPluginFr from './capacitor-plugin-fr';
import capacitorPluginHr from './capacitor-plugin-hr';
import capacitorPluginHu from './capacitor-plugin-hu';
import capacitorPluginIt from './capacitor-plugin-it';
import capacitorPluginPl from './capacitor-plugin-pl';
import capacitorPluginPt from './capacitor-plugin-pt';
import capacitorPluginRo from './capacitor-plugin-ro';
import capacitorPluginTr from './capacitor-plugin-tr';
import { TranslateService } from '@ngx-translate/core';
import capacitorPluginUk from './capacitor-plugin-uk';
import capacitorPluginCs from './capacitor-plugin-cs';
import capacitorPluginSk from './capacitor-plugin-sk';

const suffix = 'capacitorPlugins';

@Injectable({
  providedIn: 'root'
})
export class CapacitorPluginTranslateService extends TranslateGenericClass<typeof capacitorPluginDe>() {
  constructor(public global: GlobalTranslateService,
              public translate: TranslateService) {
    super();

    global.setupService(this, suffix, capacitorPluginDe);
    global.addTranslations('ar', suffix, capacitorPluginAr);
    global.addTranslations('de', suffix, capacitorPluginDe);
    global.addTranslations('en', suffix, capacitorPluginEn);
    global.addTranslations('bg', suffix, capacitorPluginBg);
    global.addTranslations('el', suffix, capacitorPluginEl);
    global.addTranslations('es', suffix, capacitorPluginEs);
    global.addTranslations('fa', suffix, capacitorPluginFa);
    global.addTranslations('fr', suffix, capacitorPluginFr);
    global.addTranslations('hr', suffix, capacitorPluginHr);
    global.addTranslations('hu', suffix, capacitorPluginHu);
    global.addTranslations('it', suffix, capacitorPluginIt);
    global.addTranslations('pl', suffix, capacitorPluginPl);
    global.addTranslations('pt', suffix, capacitorPluginPt);
    global.addTranslations('ro', suffix, capacitorPluginRo);
    global.addTranslations('ru', suffix, capacitorPluginRu);
    global.addTranslations('tr', suffix, capacitorPluginTr);
    global.addTranslations('uk', suffix, capacitorPluginUk);
    global.addTranslations('cs', suffix, capacitorPluginCs);
    global.addTranslations('sk', suffix, capacitorPluginSk);
  }
}
