import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { InfoType } from '../../enums/info-type';

interface UiInfoProps extends FormlyFieldProps {
  infoType: keyof typeof InfoType;
}

@Component({
  selector: 'ui-formly-info',
  template: `
    <ui-info
      [type]="props.infoType"
      [translate]="model[key.toString()] || props.label">
    </ui-info>`
})
export class UiInfoFieldTypeComponent extends FieldType<FieldTypeConfig<UiInfoProps>> {
}
