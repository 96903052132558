import { inject, Injectable } from '@angular/core';
import { tap } from "rxjs/operators";
import { firstValueFrom } from "rxjs";
import { BlinkCompany, BlinkCompanyAddress } from '@blink/shared-blink-types';
import { SessionRepository } from '../session/session.repository';
import { ApiManager, BlinkService, TypedQuery } from '../../shared';
import { UiDialogService } from '@blink/ui';
import { ManagerTranslateService } from '@blink/util';
import { CompanyRepository } from './company.repository';

@Injectable({ providedIn: 'root' })
export class BlinkCompanyApi {

  private sessionRepository = inject(SessionRepository);
  private apiManager = inject(ApiManager);
  private uiDialogService = inject(UiDialogService);
  private t = inject(ManagerTranslateService);
  private companyRepository = inject(CompanyRepository);

  private api = this.apiManager
    .createApiForEndpoint<BlinkCompany>(
      'odata/v1',
      'Companies',
      BlinkService.Core
    );

  async fetchCompanies(): Promise<BlinkCompany[]> {
    const query: TypedQuery<BlinkCompany> = {
      select: [
        'Id',
        'Name',
        'CompanySettingsId',
      ],
      expand: [{
        Features: {
          select: ['Id', 'InternalId']
        }
      }],
      orderBy: ['Name']
    };

    return firstValueFrom(this.api.get<BlinkCompany[]>(query)
      .pipe(
        tap(companies => {
          this.companyRepository.upsertCompanies(companies)
        })
      ));
  }

  async fetchCompany(companyId: number): Promise<BlinkCompany> {
    const query: TypedQuery<BlinkCompany> = {
      key: companyId,
      select: [
        'Id',
        'Name',
        'CompanySettingsId',
      ],
      expand: [
        'BlinkApps',
        'Address'
      ]
    };

    return firstValueFrom(this.api.get<BlinkCompany>(query)
      .pipe(
        tap(company => this.companyRepository.upsertCompanies([company]))
      ));
  }

  async post(company: BlinkCompany): Promise<BlinkCompany> {
    const query = {
      body: company
    }
    return firstValueFrom(this.api.post(query).pipe(
      tap(company => this.companyRepository.upsertCompanies([company])),
      tap(() => this.uiDialogService.toast(this.t.company.created, 'success')),
    ));
  }

  updateAddressForCompany(companyId: number, addressData: Partial<BlinkCompanyAddress>) {
    const options = {
      key: companyId,
      func: 'Address',
      body: addressData
    };
    return this.api.put(options);
  }

  updateCompany(companyId: number, company: Partial<BlinkCompany>) {
    return this.api.patch({ key: companyId, body: company });
  }
}
