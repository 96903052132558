export const languages = [
  'de',
  'en',
  'ru',
  'tr',
  'it',
  'pl',
  'fr',
  'bg',
  'ro',
  'gr',
  'pt',
  'ar',
  'es',
  'fa',
  'hr',
  'hu'
];
