export default {
  home: {
    pageTitle: 'Мои формы',
    locationChecklists_label: 'Заполните форму на объект',
    generalChecklists_label: 'Заполните общую форму',
    processingChecklists_label: 'Форма в процессе',
    checklistDatas_label: 'Заполненные формы',
    selectLocation: 'выбрать объект',
    scanQrCode: 'Код объекта сканирования',
    showAll: '... Показать все',
    showLess: '... показывай меньше'
  },
  fill: {
    checklists: 'Формы',
    fillChecklist: 'заполнять формы',
    checklistNotFound: 'К сожалению, запрошенную вами форму найти не удалось.',
    checklistOfflineLoginUserRequired: 'Данная форма требует подбора сотрудника. Установите подключение к Интернету, чтобы получить доступ к шаблону формы.',
    checklistNotOfflineAvailable: 'Шаблон формы не сохраняется в автономном режиме. Чтобы получить доступ к шаблону формы, подключитесь к Интернету.',
    resetConfirm: 'Вы действительно хотите сбросить форму?',
    categoryAppeared: 'Добавлена категория "{{categoryName}}".',
    groupAppeared: 'Страница "{{groupName}}" добавлена.',
    itemAppearedInGroup: 'На страницу "{{groupName}}" добавлены новые вопросы.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Переходим к следующему вопросу',
    containerUriExpired: 'К сожалению, период загрузки истек.',
    finishMessage: 'Вы ввели все необходимые данные и теперь можете заполнить форму.',
    offlineMessage: 'Пожалуйста, подключитесь к Интернету, чтобы заполнить форму.',
    offlinePreviewMessage: 'Пожалуйста, подключитесь к Интернету, чтобы создать предварительный просмотр.',
    checklistUpdatedHeader: 'Шаблон формы изменен',
    checklistUpdatedMessage: 'Изменен шаблон формы. Пожалуйста, проверьте введенные данные еще раз перед отправкой формы.',
    checklistLocationRequiredHeader: 'Выберите объект',
    checklistLocationRequiredMessage: 'Чтобы заполнить эту форму, вам необходимо выбрать объект.',
    finishSuccess: 'Форма успешно заполнена и все данные обработаны.',
    finishError: 'Форма не может быть заполнена из-за ошибки. Пожалуйста, свяжитесь со службой поддержки.',
    createdWith: 'Эта форма была создана с',
    preview: 'предварительный просмотр',
    fileUploadFileMissingHeader: 'Файлы не найдены',
    fileUploadFileMissingMessage: 'Следующие файлы выбраны, но не могут быть найдены на устройстве: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Да', no: 'Нет' },
    scan: 'сканировать',
    email: { invalidEmail: 'Пожалуйста, проверьте вашу запись.' },
    takePicture: { picture: 'Картина', buttonText: 'добавить фото' },
    createTask: {
      taskTitle: 'Название билета',
      taskDescription: 'Описание билета',
      taskDueDate: 'Срок оплаты билета'
    }
  },
  noChecklistsForLocationFound: 'Для этого объекта нет формы. Пожалуйста, выберите другой объект размещения или заполните общую форму.',
  selectChecklistByLocation: 'Выберите форму для объекта'
};
