import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
// import { UiInputSelectList } from '@blink/ui';
import { UiInputSelectList } from '../../../inputs/ui-input-select/ui-input-select.component';

interface InputSelectProps extends FormlyFieldProps {
  items: UiInputSelectList;
  multiple: boolean;
}

@Component({
  selector: 'ui-formly-field-input-select',
  styles: [':host {margin: 0}'],
  template: `
    <ui-input-select [items]="props.items"
                     [label]="props.label"
                     [multiple]="props.multiple"
                     [formControl]="formControl">
    </ui-input-select>

  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFormlyFieldInputSelectComponent extends FieldType<FieldTypeConfig<InputSelectProps>> {

  constructor(private elementRef: ElementRef) {
    super();
  }
}
