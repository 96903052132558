import { createStore } from '@ngneat/elf';
import {
  deleteEntitiesByPredicate,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  upsertEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Profile } from './Profile';
import { PersistStateService } from '../../shared';
import { BlinkUserContext } from '../session/blink-user-context';

//{idKey: 'sid'}

const store = createStore(
  { name: 'profiles' },
  withEntities<Profile>(),
  withActiveId(),
);

@Injectable({ providedIn: 'root' })
export class ProfileRepository {
  profiles$ = store.pipe(selectAllEntities());
  activeProfile$ = store.pipe(selectActiveEntity());

  private repositoryReadySubject = new ReplaySubject<boolean>(1);
  repositoryReady$ = this.repositoryReadySubject.asObservable();

  constructor(persistStateService: PersistStateService) {
    persistStateService
      .persistState(store, false)
      .subscribe(() => this.repositoryReadySubject.next(true));
  }

  upsert(params: { userContext: BlinkUserContext, refreshToken: string, system: string, blinkId: string }) {
    const model: Profile = {
      id: `${params.system}-${params.userContext.EmployeeId}`,
      EmployeeId: params.userContext.EmployeeId,
      LoginUserId: params.userContext.LoginUserId,
      System: params.system,
      RefreshToken: params.refreshToken,
      BlinkId: params.blinkId,
      CompanyName: params.userContext.Company?.Name
    }
    store.update(upsertEntities([model]), setActiveId(model.id));
  }

  delete(id: string) {
    store.update(deleteEntitiesByPredicate((entity) => entity.id === id));
  }

  setActive(profile: Profile) {
    store.update(setActiveId(profile.id));
  }
}
