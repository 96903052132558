import { createStore, setProps, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
// import {logService} from '../../../../../../apps/me/src/utils/logger';
import { PersistStateService } from '../../shared';
import dayjs from 'dayjs';

// import { lockAfterMin } from 'apps/me/src/settings';

export interface PinLockRepositoryState {
  pinHash: number;
  lastUnlockDate: string;
}

const store = createStore(
  { name: 'core-chat-session' },
  withProps<PinLockRepositoryState>({
    pinHash: null,
    lastUnlockDate: null
  })
);

@Injectable({ providedIn: 'root' })
export class PinLockRepository {
  store = store;

  sessionReadySubject = new ReplaySubject<boolean>(1);
  sessionReady$ = this.sessionReadySubject.asObservable();

  constructor(private persistStateService: PersistStateService) {
    // logService(this);
    persistStateService.persistState(store, false).subscribe(() => {
      this.sessionReadySubject.next(true);
    });
  }

  updatePinHash(hash) {
    store.update(
      setProps(() => ({
        pinHash: hash,
        lastUnlockDate: hash ? new Date().toISOString() : null

      }))
    );
  }

  isLocked() {
    const pinHash = store.getValue().pinHash;
    const lastUnlockDate = store.getValue().lastUnlockDate;
    if (pinHash) {
      const minutesSinceLastUnlock = dayjs().diff(dayjs(lastUnlockDate), 'minutes');
      return minutesSinceLastUnlock > 15;
    }
    return false;
  }

  pinHash() {
    return store.getValue().pinHash;
  }

  lastUnlockDate() {
    return store.getValue().lastUnlockDate;
  }
}
