import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UiFormlyFieldInputComponent } from './input.type';
import { UiFormlyFormFieldModule } from '../../wrappers/form-field/form-field.module';
import { UtilModule } from "@blink/util";

@NgModule({
  declarations: [UiFormlyFieldInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'input',
          component: UiFormlyFieldInputComponent,
          wrappers: ['ui-form-field']
        },
        {name: 'string', extends: 'input'},
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }
      ]
    }),
    UtilModule
  ]
})
export class UiFormlyInputModule {
}
