import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Type } from "@angular/core";
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps } from "@ngx-formly/core";

type RadioGroupProps = FormlyFieldProps

export interface FormlyRadioGroupFieldConfig extends FormlyFieldConfig<RadioGroupProps> {
  type: "radioGroup" | Type<UiFormlyFieldRadioGroupComponent>;
}

@Component({
  selector: "ui-formly-field-ion-radiogroup",
  styles: [":host {margin: 0;}"],
  template: `
      <ion-list>
          <ion-radio-group [formControl]="formControl">
              <ion-item *ngFor="let item of field['items']" class="ion-margin-bottom" lines="none">
                  <ion-radio justify="start"
                             [value]="item.key"
                             labelPlacement="end">
                      {{item.props.label}}
                  </ion-radio>
              </ion-item>
          </ion-radio-group>
      </ion-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFormlyFieldRadioGroupComponent extends FieldType<FieldTypeConfig<RadioGroupProps>> implements OnInit {

  constructor(private elementRef: ElementRef) {
    super();

    window['UiFormlyFieldRadioGroupComponent'] = this;
  }

  ngOnInit() {
    this.elementRef.nativeElement.setAttribute("slot", "start");
  }
}
