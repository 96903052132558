import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { getRegistry } from '@ngneat/elf';
import { TranslateService } from '@ngx-translate/core';
import * as compareVersions from 'compare-versions';
import { appVersion } from '../../../settings';


@Injectable({
  providedIn: 'root'
})
export class BlinkAuthService {

  constructor(private http: HttpClient,
              private alertController: AlertController,
              private navController: NavController,
              private translate: TranslateService,
              private platform: Platform) {
  }


  logout() {
    getRegistry().forEach(store => store.reset());
    localStorage.clear();

    this.navController.navigateRoot(`login`);
  }


  checkVersion() {
    let shortVersion = appVersion;
    if (shortVersion.indexOf('-') >= 0) {
      shortVersion = shortVersion.substring(0, shortVersion.indexOf('-'));
    }

    if (shortVersion !== '0.0.0') {
      const options = {
        headers: new HttpHeaders({
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
      };
      return this.http.get('https://assets.blink.online/appVersions/BlinkCheck.json', options)
        .toPromise().then(data => {
          const latestVersion = data['latestVersion'];
          const warnVersion = data['warnVersionsBefore'];
          const blockVersion = data['blockVersionsBefore'];

          //if appVersion is lower or equals to blockVersion, blockIt.
          if (compareVersions.compare(shortVersion, blockVersion, '<')) {
            this.showUpdateDialog('update.blockVersion', true);
          }
          //if appVersion is lower or equals to warnVersion, warn user to update it.
          else if (compareVersions.compare(shortVersion, warnVersion, '<')) {
            this.showUpdateDialog('update.warnVersion');
          }
          //else if currentVersion is lower than latestVersion show info
          else if (compareVersions.compare(shortVersion, latestVersion, '<')) {
            this.showUpdateDialog('update.latestVersion');
          }
        }).catch(e => console.error(e));
    }
  }

  async showUpdateDialog(message: string, block = false) {
    // console.log('showUpdateDialog: ' + message)

    const buttons = [];
    if (!block) {
      buttons.push(this.translate.instant('typedGlobal.cancel'));
    }
    buttons.push({
      text: this.translate.instant('update.update'),
      cssClass: 'accept',
      handler: () => {
        if (this.platform.is("ios")) {
          window.open('https://apps.apple.com/de/app/blink-check/id1535948156');
        } else {
          window.open('https://play.google.com/store/apps/details?id=de.blink.check');
        }
        return !block;
      }
    });

    const alert = await this.alertController.create({
      message: this.translate.instant(message),
      buttons: buttons
    });
    alert.present();
  }
}
