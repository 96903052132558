export default {
  barcodeScanner: {
    permissionHeader: 'Permisiune',
    permissionMessage: 'Dacă doriți să acordați permisiunea de a utiliza camera, activați-o în setările aplicației.',
    scanPrompt: 'Scanează {{thing}}',
    enterPrompt: 'Introduceți {{thing}}',
    blinkId: 'ID intermitent',
    isNoBlinkId: 'Codul QR scanat nu este un ID Blink valid',
    objectCode: 'Cod obiect'
  },
  camera: {
    promptLabelHeader: 'Selectați sursa',
    promptLabelPicture: 'Faceți o fotografie',
    promptLabelPhoto: 'Selectați din galerie'
  },
  network: {
    changed: {
      connected: 'Conexiune la internet stabilită',
      disconnected: 'Conexiune la internet întreruptă'
    }
  },
  file: { downloadStarted: 'Descărcarea a început.' },
  nfc: {
    wrongCode: 'Codul QR scanat nu este un cod obiect!',
    error: 'Eroare NFC'
  }
};
