
export interface BlinkApp {
  Id: number,
  Active: boolean,
  InternalId: string,
  Name: string,
  Type: BlinkAppType
}

export enum BlinkApps {
  BlinkTime = '1',
  BlinkActive = '2',
  BlinkDrive = '3',
  BlinkCheck = '4',
  BlinkPlan = '5',
  BlinkInsight = '6',
  BlinkMe = '7',
  BlinkCheckUnlimited = '8',
  BlinkOrderStandard = '9',
  BlinkOrderProfessional = '10',
  BlinkOrderEnterprise = '11',
  BlinkCheckProfessional = '14',
}


export enum BlinkAppType {
  License = "License",
}
