import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Location } from '@angular/common';
import { IonContent, ModalController } from '@ionic/angular';
import { BlinkIcon } from '../ui-icon';
import { ApplicationInsightsService, GlobalTranslateService } from '@blink/util';
import { UiLoadingService } from '../loading/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-page',
  templateUrl: './ui-page.component.html',
  styleUrls: ['./ui-page.component.scss']
  // encapsulation: ViewEncapsulation.Emulated,
})
export class UiPageComponent implements AfterViewInit {
  BlinkIcon = BlinkIcon;

  @ViewChild('pageTemplate') pageTemplate;
  @ViewChild(IonContent) content: IonContent;

  @Input() menuButton = false;

  @Input() backHref: string;

  @Input() contentPadding = true;
  @Input() legacyWrapper = false;
  @Input() contentTitle: string;

  @Input() customBackButtonVisible = true;
  @Input() customBackButtonText: string = this.t.back;
  @Input() customBackButtonIcon: BlinkIcon = BlinkIcon.ChevronLeft;
  @Input() customBackButtonBlack = false;
  @Output() customBackButtonClick = new EventEmitter<void>();

  /**
   * @deprecated pls dont use this anymore
   */
  @Input() ionNoBorder = true;

  @Input() title: string;
  @Input() modalDismiss = false;


  @Input() addButtonVisible = true;
  @Input() addButtonLabel: string;
  @Output() addButtonClick = new EventEmitter<MouseEvent>();

  @Input() saveButtonVisible = true;
  @Input() saveButtonEnabled = true;
  @Input() saveButtonDisabled = false;
  @Input() saveButtonAsCreate = false;
  @Input() saveButtonCustomLabel: string;
  @Output() saveButtonClick = new EventEmitter<void>();

  @Input() exportButtonVisible = true;
  @Input() exportButtonEnabled = true;
  @Output() exportButtonClick = new EventEmitter<void>();

  @Input() deleteButtonVisible = true;
  @Input() deleteButtonEnabled = true;
  @Output() deleteButtonClick = new EventEmitter<void>();

  @Input() cancelButtonLabel = this.t.cancel;
  @Input() dismissButtonVisible = false;
  @Input() customButtonActionsVisible = false;
  @Input() cancelButtonVisible = true;
  @Output() cancelButtonClick = new EventEmitter<void>();

  constructor(private viewContainerRef: ViewContainerRef,
              public modalController: ModalController,
              public loading: UiLoadingService,
              public t: GlobalTranslateService,
              private appInsights: ApplicationInsightsService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private location: Location) {
    window['pageC'] = this;
  }

  get isModal() {
    return this.viewContainerRef?.element.nativeElement.parentElement.parentElement.tagName === 'ION-MODAL';
  }

  get _showDefaultBackButton() {
    return !this._showCustomBackButton
      && !this.modalDismiss
      && !this.menuButton
    // && !!this.backHref // not sure
  }

  get _showCustomBackButton() {
    return this.customBackButtonClick.observed
      && this.customBackButtonVisible;
  }

  get _showSaveButton() {
    return this.saveButtonClick.observed && this.saveButtonVisible && !this.saveButtonAsCreate;
  }

  get _showCreateButton() {
    return this.saveButtonClick.observed && this.saveButtonVisible && this.saveButtonAsCreate;
  }

  get _showDeleteButton() {
    return this.deleteButtonClick.observed && this.deleteButtonVisible;
  }

  get _showExportButton() {
    return this.exportButtonClick.observed && this.exportButtonVisible;
  }

  get _showCancelButton() {
    return (this.dismissButtonVisible || this.cancelButtonClick.observed || this.isModal)
      && this.cancelButtonVisible;
  }

  get _showFooter() {
    return this.dismissButtonVisible
      || this.isModal
      || this._showSaveButton
      || this._showCreateButton
      || this._showExportButton
      || this._showDeleteButton
      || this._showCancelButton
      || this.customButtonActionsVisible;
  }

  ngAfterViewInit() {
    setTimeout(() =>
        this.viewContainerRef.createEmbeddedView(this.pageTemplate),
      0
    );
  }

  // initClickHandler() {
  //   if (this._saveButtonClickHandlerPresent) {
  //     this.uiNavigation.hideTabs();
  //   }
  // }


  onAddClick(event: MouseEvent) {
    this.appInsights.logEvent(`[UiPage] Add`);
    this.addButtonClick.emit(event);
  }

  onSaveClick() {
    this.appInsights.logEvent(`[UiPage] Save`);
    this.saveButtonClick.emit();
  }

  onDeleteClick() {
    this.appInsights.logEvent(`[UiPage] Delete`);
    this.deleteButtonClick.emit();
  }

  onExportClick() {
    this.appInsights.logEvent(`[UiPage] Delete`);
    this.exportButtonClick.emit();
  }

  onDismissClick(logType: string) {
    this.appInsights.logEvent(`[CorePage] Dismiss ${logType}`);

    if (this.cancelButtonClick.observed) {
      this.cancelButtonClick.emit();
    } else {
      if (this.backHref) {
        this.router.navigate([this.backHref]);
      } else {
        this.modalController.dismiss();
      }
    }
  }
}
