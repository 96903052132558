export default {
  barcodeScanner: {
    permissionHeader: 'Zezwolenie',
    permissionMessage: 'Jeśli chcesz zezwolić na używanie kamery, włącz ją w ustawieniach aplikacji.',
    scanPrompt: 'Skanuj {{thing}}',
    enterPrompt: 'Wpisz {{thing}}',
    blinkId: 'Identyfikator migania',
    isNoBlinkId: 'Zeskanowany kod QR nie jest prawidłowym identyfikatorem migania',
    objectCode: 'Kod obiektu'
  },
  camera: {
    promptLabelHeader: 'Wybierz źródło',
    promptLabelPicture: 'zrobić zdjęcie',
    promptLabelPhoto: 'Wybierz z galerii'
  },
  network: {
    changed: {
      connected: 'Nawiązano połączenie internetowe',
      disconnected: 'Połączenie internetowe zostało przerwane'
    }
  },
  file: { downloadStarted: 'Pobieranie rozpoczęło się.' },
  nfc: {
    wrongCode: 'Zeskanowany kod QR nie jest kodem obiektowym!',
    error: 'Błąd NFC'
  }
};
