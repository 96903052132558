export default {
  home: {
    pageTitle: 'moje formularze',
    locationChecklists_label: 'Wypełnij formularz dla obiektu',
    generalChecklists_label: 'Wypełnij formularz ogólny',
    processingChecklists_label: 'formularz w toku',
    checklistDatas_label: 'Wypełnione formularze',
    selectLocation: 'Wybierz obiekt',
    scanQrCode: 'Zeskanuj kod obiektowy',
    showAll: '...Pokaż wszystko',
    showLess: '... Pokaż mniej'
  },
  fill: {
    checklists: 'Formularze',
    fillChecklist: 'wypełnij formularz',
    checklistNotFound: 'Niestety, nie udało się znaleźć żądanego formularza.',
    checklistOfflineLoginUserRequired: 'Formularz ten wymaga wyboru pracownika. Aby uzyskać dostęp do szablonu formularza, nawiąż połączenie internetowe.',
    checklistNotOfflineAvailable: 'Szablon formularza nie jest zapisany w trybie offline. Aby uzyskać dostęp do szablonu formularza, połącz się z Internetem.',
    resetConfirm: 'Czy na pewno chcesz zresetować formularz?',
    categoryAppeared: 'Dodano kategorię „{{categoryName}}”.',
    groupAppeared: 'Strona „{{groupName}}” została dodana.',
    itemAppearedInGroup: 'Do strony „{{groupName}}” dodano nowe pytania.',
    openGroupsAvailable: 'otwarteGrupyDostępne',
    toNextGroup: 'Przejdźmy do następnego pytania',
    containerUriExpired: 'Niestety okres przesyłania minął.',
    finishMessage: 'Wpisałeś wszystkie niezbędne dane i możesz teraz wypełnić formularz.',
    offlineMessage: 'Aby wypełnić formularz, połącz się z Internetem.',
    offlinePreviewMessage: 'Aby wygenerować podgląd, połącz się z Internetem.',
    checklistUpdatedHeader: 'Zmieniono szablon formularza',
    checklistUpdatedMessage: 'Zmieniono szablon formularza. Przed przesłaniem formularza sprawdź ponownie swoje wpisy.',
    checklistLocationRequiredHeader: 'Wybierz obiekt',
    checklistLocationRequiredMessage: 'Aby wypełnić ten formularz, musisz wybrać obiekt.',
    finishSuccess: 'Formularz został pomyślnie wypełniony i wszystkie dane zostały przetworzone.',
    finishError: 'Formularz nie mógł zostać wypełniony z powodu błędu. Skontaktuj się z pomocą techniczną.',
    createdWith: 'Ten formularz został stworzony za pomocą',
    preview: 'zapowiedź',
    fileUploadFileMissingHeader: 'Nie znaleziono plików',
    fileUploadFileMissingMessage: 'Wybrano następujące pliki, ale nie można ich znaleźć na urządzeniu: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Tak', no: 'NIE' },
    scan: 'skanowanie',
    email: { invalidEmail: 'Sprawdź swój wpis.' },
    takePicture: { picture: 'Zdjęcie', buttonText: 'Dodaj zdjęcie' },
    createTask: {
      taskTitle: 'Tytuł biletu',
      taskDescription: 'Opis biletu',
      taskDueDate: 'Termin płatności biletów'
    }
  },
  noChecklistsForLocationFound: 'Nie ma formy dla tego obiektu. Proszę wybrać inną nieruchomość lub wypełnić formularz ogólny.',
  selectChecklistByLocation: 'Wybierz formę dla obiektu'
};
