import { inject, Injectable } from '@angular/core';
import {
  CheckCategoryDependencyRepository,
  CheckCategoryRepository,
  CheckChecklistDataRepository,
  CheckChecklistRepository,
  CheckGroupDependencyRepository,
  CheckGroupRepository,
  CheckItemDependencyRepository,
  CheckItemRepository,
  CheckItemValueRepository,
  CompanyRepository,
  LogRepository,
  PersistStateService,
  SessionRepository
} from '@blink/api';
import { combineLatestWith } from 'rxjs';

@Injectable()
export class AppInitializeService {
  private persistStateService = inject(PersistStateService);

  //Repositories
  private sessionRepository = inject(SessionRepository);
  private companyRepository = inject(CompanyRepository);
  private logRepository = inject(LogRepository);
  private categoryRepository = inject(CheckCategoryRepository);
  private categoryDependencyRepository = inject(CheckCategoryDependencyRepository);
  private checklistRepository = inject(CheckChecklistRepository);
  private checklistDataRepository = inject(CheckChecklistDataRepository);
  private groupRepository = inject(CheckGroupRepository);
  private groupDependencyRepository = inject(CheckGroupDependencyRepository);
  private itemRepository = inject(CheckItemRepository);
  private itemDependencyRepository = inject(CheckItemDependencyRepository);
  private itemValueRepository = inject(CheckItemValueRepository);

  init() {
    void this.persistStateService.init();
    return () => {
      return new Promise((resolve) => {
        this.sessionRepository.sessionReady$.pipe(
          combineLatestWith(
            this.companyRepository.repositoryReady$,
            this.logRepository.persistentStateReady$,
            this.categoryRepository.persistentStateReady$,
            this.categoryDependencyRepository.persistentStateReady$,
            this.checklistRepository.persistentStateReady$,
            this.checklistDataRepository.persistentStateReady$,
            this.groupRepository.persistentStateReady$,
            this.groupDependencyRepository.persistentStateReady$,
            this.itemRepository.persistentStateReady$,
            this.itemDependencyRepository.persistentStateReady$,
            this.itemValueRepository.persistentStateReady$
          ),
        ).subscribe(() => {
          const sessionMigration = this.sessionRepository.getMigration;
          if (sessionMigration.ready && !sessionMigration.migrated) {
            this.companyRepository.migrate(sessionMigration);
            // this.sessionRepository.deleteAll();
            this.sessionRepository.setMigrationMigrated();
          }

          resolve(true);
        });
      })
    }
  }
}
