export default {
  home: {
    pageTitle: 'Meus formulários',
    locationChecklists_label: 'Preencha um formulário para um objeto',
    generalChecklists_label: 'Preencha o formulário geral',
    processingChecklists_label: 'Formulário em andamento',
    checklistDatas_label: 'Formulários preenchidos',
    selectLocation: 'Selecione o objeto',
    scanQrCode: 'Digitalizar código de objeto',
    showAll: '...mostre tudo',
    showLess: '... mostre menos'
  },
  fill: {
    checklists: 'Formulários',
    fillChecklist: 'preencha o formulário',
    checklistNotFound: 'Infelizmente, o formulário que você solicitou não foi encontrado.',
    checklistOfflineLoginUserRequired: 'Este formulário requer a seleção de um funcionário. Estabeleça uma conexão com a Internet para poder acessar o modelo de formulário.',
    checklistNotOfflineAvailable: 'O modelo de formulário não é salvo offline. Conecte-se à Internet para acessar o modelo de formulário.',
    resetConfirm: 'Você realmente deseja redefinir o formulário?',
    categoryAppeared: 'A categoria "{{categoryName}}" foi adicionada.',
    groupAppeared: 'A página "{{groupName}}" foi adicionada.',
    itemAppearedInGroup: 'Novas perguntas foram adicionadas à página "{{groupName}}".',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Vamos para a próxima pergunta',
    containerUriExpired: 'Infelizmente o período de upload expirou.',
    finishMessage: 'Você inseriu todos os dados necessários e agora pode preencher o formulário.',
    offlineMessage: 'Conecte-se à Internet para preencher o formulário.',
    offlinePreviewMessage: 'Conecte-se à Internet para gerar uma visualização.',
    checklistUpdatedHeader: 'Modelo de formulário alterado',
    checklistUpdatedMessage: 'O modelo de formulário foi alterado. Verifique suas entradas novamente antes de enviar o formulário.',
    checklistLocationRequiredHeader: 'Selecione o objeto',
    checklistLocationRequiredMessage: 'Você deve selecionar um objeto para preencher este formulário.',
    finishSuccess: 'O formulário foi preenchido com sucesso e todos os dados foram processados.',
    finishError: 'O formulário não pôde ser preenchido devido a um erro. Entre em contato com o suporte.',
    createdWith: 'Este formulário foi criado com',
    preview: 'visualização',
    fileUploadFileMissingHeader: 'Arquivos não encontrados',
    fileUploadFileMissingMessage: 'Os seguintes arquivos foram selecionados, mas não podem ser encontrados no dispositivo: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Sim', no: 'Não' },
    scan: 'Varredura',
    email: { invalidEmail: 'Por favor, verifique sua entrada.' },
    takePicture: { picture: 'Foto', buttonText: 'adicionar uma foto' },
    createTask: {
      taskTitle: 'Título do bilhete',
      taskDescription: 'Descrição do bilhete',
      taskDueDate: 'Data de vencimento do ingresso'
    }
  },
  noChecklistsForLocationFound: 'Não há forma para este objeto. Selecione outra propriedade ou preencha um formulário geral.',
  selectChecklistByLocation: 'Selecione o formulário para o objeto'
};
