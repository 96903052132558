export default {
  blinkSlogan: 'Blink – aplikace pro čističe budov',
  all: 'Všechno',
  hello: 'Ahoj',
  description: 'Popis',
  ok: 'OK',
  cancel: 'zrušení',
  reset: 'Obnovit do základního nastavení',
  edit: 'Upravit',
  finish: 'Kompletní',
  notice: 'Oznámení',
  content: 'Obsah',
  back: 'Zadní',
  close: 'Zavřít',
  backToHome: 'Zpět na domovskou stránku',
  proceed: 'Dále',
  done: 'Připraveno',
  delete: 'Vymazat',
  successful: 'Úspěšný',
  error: 'Chyba',
  ERROR_OCCURRED: 'Došlo k chybě nebo jsou data nesprávná. Prosím zkuste to znovu.',
  internetRequired: 'Vyžaduje internet',
  configuration: 'konfigurace',
  logo: 'logo',
  uploadLogo: 'Nahrajte logo',
  deleteLogo: 'Smazat logo',
  duplicate: 'Duplikát',
  importExport: 'Import Export',
  import: 'import',
  doImport: 'Import',
  export: 'vývozní',
  doExport: 'Vývozní',
  excelExport: 'Excel export',
  excelExportDownloaded: 'Excel export stažen',
  excelExportError: 'Export do Excelu se nezdařil',
  pdfDownload: 'Stáhnout PDF',
  pdfDownloaded: 'PDF staženo',
  configSaved: 'Konfigurace byla uložena.',
  send: 'Poslat',
  connecting: 'Připojit...',
  or: 'nebo',
  select: 'Vybrat',
  today: 'Dnes',
  invalidValue: 'neplatná hodnota',
  camera: 'Fotoaparát',
  gallery: 'galerie',
  files: 'soubory',
  upload: 'Nahrát soubory',
  chooseFiles: 'vybrat soubory',
  filesTransferring: 'Odeslat soubory...',
  filesTransferringWait: 'Počkejte prosím na přenos souborů.',
  filesTransferred: 'Soubory byly úspěšně přeneseny.',
  download: 'Stažení',
  showInactive: 'Zobrazit neaktivní',
  copyLink: 'Kopírovat odkaz',
  linkCopied: 'Odkaz byl zkopírován do schránky.',
  permissions: 'Oprávnění',
  email: 'e-mailem',
  emailConfig: 'Konfigurace e-mailu',
  title: 'titul',
  user: 'uživatel',
  logout: 'Odhlásit se',
  system: 'Systém',
  appVersion: 'Verze (aplikace)',
  apiVersionCore: 'verze\n(Core API)',
  apiVersionCheck: 'verze\n(Zkontrolujte API)',
  deviceInfo: 'Informace o zařízení',
  installBlinkTime: 'Nainstalujte Blink Time',
  installBlinkMe: 'Nainstalujte Blink Me',
  installing: 'Instalace verze {{version}}...',
  companyRequired: 'Vyberte klienta',
  companyRequiredInfo: 'Chcete-li pokračovat jako správce systému, musíte vybrat klienta.',
  pleaseChoose: 'Prosím vyber si',
  pleaseFill: 'Prosím vyplnit',
  noEntitiesFound: 'Nebyly nalezeny žádné položky.',
  addCustom: 'Vytvořit prvek:',
  typeToSearchText: 'Zadejte prosím dva nebo více znaků...',
  loading: 'Data se načítají...',
  offline: 'Offline',
  youAreOffline: 'Jsi offline!',
  by: 'z',
  date: 'datum',
  time: 'čas',
  searchTitle: 'Hledat',
  searchTerm: 'hledaný výraz',
  searchLoginUser: 'Hledáme zaměstnance',
  searchLoginUserPlaceholder: 'Hledejte podle příjmení nebo jména',
  location: 'objekt',
  searchLocation: 'Vyhledat objekt',
  searchLocationPlaceholder: 'Vyhledávání podle názvu objektu, čísla nebo značek',
  deactivate: 'Deaktivovat',
  active: 'Aktivní',
  inactive: 'Neaktivní',
  tag: 'Den',
  tags: 'značky',
  color: 'Barva',
  icon: 'Ikona',
  language: 'Jazyk',
  general: 'Obvykle',
  yes: 'Ano',
  no: 'Ne',
  searchThrough: 'Vyhledávání',
  name: 'Příjmení',
  create: 'Vytvořit',
  save: 'Uložit',
  home: 'Domov',
  profile: 'profil',
  templates: 'šablony',
  noAssignment: 'nepřidělené',
  sort: {
    change: 'změnit pořadí',
    save: 'Uložit objednávku',
    saved: 'Objednávka byla uložena.'
  },
  inProgress: 'Probíhá',
  video_offline: 'Videa nelze prohlížet v režimu offline, připojte se prosím k internetu.',
  location_offline: 'Objekt není uložen offline, připojte se k internetu a načtěte QR kód.',
  invalidObjectCode: 'Žádný platný kód objektu.',
  signature: {
    signature: 'Podpis',
    pleaseSign: 'Prosím podepište',
    clickHereToSign: 'Klikněte zde pro podpis',
    addSignature: 'Přidejte podpis',
    save: 'Převzít',
    delete: 'Vymazat'
  },
  formError: {
    min: 'Hodnota musí být větší než {{value}}.',
    min2: 'Hodnota musí být větší nebo rovna {{value}}.',
    max: 'Hodnota musí být menší nebo rovna {{value}}.',
    requiredBecause: 'Hodnota je povinná, protože {{name}} je vyplněno.',
    isRequired: '{{name}} je povinné.',
    numberInUse: 'Osobní číslo se již používá.',
    maxlength: '{{name}} nemůže obsahovat více než {{value}} znaků.',
    email: 'Prosím zadejte platnou emailovou adresu.',
    faultyBlinkId: 'Nesprávné ID blikání'
  },
  scan: {
    choose: 'Přiřadit nové Blink ID',
    text: 'Naskenujte QR kód na registrační kartě.',
    scan_now: 'Skenujte nyní',
    enter_manually: 'Zadejte ručně'
  },
  removeImage: 'Jasný obraz',
  tickets: { assignee: 'editor', dueTo: 'Platný do' },
  ngSelect: {
    typeToSearch: 'Zadejte prosím 3 nebo více písmen.',
    loading: 'načítání dat...',
    notFound: 'Nebylo nalezeno žádné {{title}}.',
    clearAll: 'Obnovit do základního nastavení'
  },
  help: {
    pageTitle: 'Pomoc',
    text1: 'Náš tým podpory vám rád pomůže, pokud budete mít nějaké dotazy:',
    phone: 'telefon',
    mail: 'e-mailem',
    information: 'info',
    documentation: 'Online dokumentace',
    documentationText: 'V naší online dokumentaci najdete pokyny a videa pro všechny důležité oblasti Blink.',
    support: 'Podpěra, podpora'
  },
  REGISTRATION: {
    WELCOME: 'Vítejte!',
    chooseMethod: 'Jakou metodu chcete použít k přihlášení?',
    withBlinkId: 'Blink ID karta',
    withCredentials: 'Uživatelské jméno heslo',
    REGISTER: 'zaregistrovat se',
    ALREADY_REGISTERED: 'Mám účet Blink.',
    INVALID_CARD: 'Karta není platná.',
    noBlinkId: 'Naskenovaný QR kód není platným Blink ID',
    scanBlinkId: 'Scan Blink ID',
    ERROR: 'Registraci nebylo možné dokončit. Zadejte prosím jinou e-mailovou adresu nebo mobilní číslo.',
    NO_INTERNET_EXPLORER: 'Internet Explorer bohužel Blink nepodporuje. Použijte prosím Microsoft Edge, Firefox nebo Chrome. Děkuji za pochopení.',
    loginNow: 'Zaregistrujte se nyní',
    help: 'Pomoc s registrací',
    helpText: 'Existují dva způsoby, jak se přihlásit:<br/><br/><b>Přihlášení pomocí Blink ID Card</b><br/><br/>Pokud máte Blink ID kartu, zvolte tuto metoda. Při první registraci budete požádáni o e-mailovou adresu nebo číslo mobilního telefonu. Potřebujeme to, abychom vám zaslali jednorázové heslo pro registraci. Nemusíte si pamatovat jednorázové heslo. Pokud se znovu přihlásíte, zašleme vám nové jednorázové heslo.<br/><br/><b>Přihlaste se pomocí uživatelského jména a hesla</b><br/><br/>Dostanete pouze své uživatelské jméno a heslo na vyžádání správce systému. Tato metoda je dostupná pouze uživatelům s právy správce.<br/><br/><b>Podpora</b><br/><br/>Pokud máte problémy s přihlášením, napište e-mail na podporu @blink- time.de nebo volejte 0911 240 330 0.',
    userOffline: 'Chcete-li používat Blink me, vytvořte si internetové připojení.',
    login: 'Zaregistrujte se nyní',
    codeError: 'Zkontrolujte prosím svůj vstup a zkuste to znovu.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Gratulujeme!',
    YOU_DID_IT: 'Udělal jsi to.',
    SIGNED_IN: 'Registrovaný',
    SIGNED_IN_TEXT: 'Úspěšně jste se přihlásili a nyní můžete aplikaci používat.',
    START_USAGE: 'Nyní můžete aplikaci používat.',
    START: 'začít'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Byl vám zaslán e-mail, pomocí kterého můžete změnit své přihlašovací údaje.',
    FORGOT_PASSWORD: 'Zapomněli jste heslo?',
    FORGOT_PASSWORD_TEXT: 'Pro resetování hesla zadejte svou e-mailovou adresu a cílový systém.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mailem',
    errors: {
      notAllowed: 'Pro tohoto uživatele nelze resetovat heslo.',
      invalidToken: 'Platnost kódu pro resetování hesla vypršela. Požádejte o nový kód.'
    },
    reset: {
      backToRoot: 'Zpět na přihlašovací stránku',
      renewCode: 'Vyžádejte si nový kód',
      resetPassword: 'obnovit heslo',
      success: 'Vaše heslo bylo úspěšně resetováno.',
      password1: 'vaše nové heslo',
      password2: 'Zopakovat heslo',
      validationError: 'Heslo není v souladu s našimi pokyny pro hesla!',
      passwordsNotEqual: 'Hesla nejsou totožná! Zkontrolujte prosím svůj záznam.',
      passwordValidation: 'Heslo musí mít alespoň 6 znaků a obsahovat písmeno, číslici a speciální znak.'
    }
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Zkontrolujte data',
    TEXT: 'Pokud potřebujeme obnovit vaše heslo, budeme potřebovat vaši e-mailovou adresu a volitelně vaše mobilní číslo.',
    EMAIL: 'Emailová adresa',
    MOBILE: 'Číslo mobilního telefonu'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Zadejte kód',
    EMAIL_TEXT: 'Zadejte kód, který jsme zaslali na následující e-mailovou adresu:',
    SMS_TEXT: 'Zadejte kód, který jsme zaslali na následující mobilní číslo:',
    NO_CODE: 'Neobdržel jsem kód.',
    CODE_LENGTH_ERROR: 'Zadaný kód je delší než šest znaků. Zadejte prosím svůj kód znovu.',
    CODE_SENT: 'Kód byl odeslán.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Žádný kód',
    TEXT1: 'Neobdrželi jste kód?',
    RESEND_CODE: 'Poslat kód znovu',
    TEXT2: 'Pokud se vaše e-mailová adresa nebo číslo mobilního telefonu uložené v systému změnilo, kontaktujte svého správce.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Zadejte své Blink ID a kód. Všechny informace najdete na registrační kartě.',
    textCredentials: 'Uživatelské jméno a heslo vám musí poskytnout správce systému.',
    BLINK_ID_INVALID: 'Nesprávné ID blikání',
    loginCardNotFound: 'Blink ID je buď již obsazeno, nebo neexistuje',
    WRONG_CREDENTIALS: 'Zadané údaje jsou nesprávné. Zkontrolujte prosím svůj záznam.',
    loginViaBlinkId: 'Přihlaste se pomocí BlinkID',
    loginViaCredentials: 'Registrace s přístupovými údaji',
    CODE: 'kód',
    codePlaceholder: 'Váš osobní přístupový kód',
    company: 'Systém',
    password: 'Heslo',
    FORGOT_PASSWORD: 'Zapomněl jsem heslo',
    username: 'e-mailem'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'ID',
    TEXT_1: 'Abychom vás mohli jednoznačně identifikovat, zašleme vám jednorázový kód prostřednictvím SMS nebo emailu.',
    TEXT_2: 'Uveďte prosím své mobilní číslo nebo e-mailovou adresu.'
  },
  update: {
    update: 'Aktualizovat',
    latestVersion: 'K dispozici je nová verze. Aktualizujte svou aplikaci, abyste ji mohli plně využít.',
    warnVersion: 'Používáte zastaralou verzi této aplikace. Aktualizujte prosím co nejdříve.',
    blockVersion: 'Používáte zastaralou verzi této aplikace, která již není podporována. Proveďte aktualizaci nyní.'
  },
  ERROR: {
    ERROR_400: 'Došlo k chybě. Prosím zkuste to znovu později. (Kód chyby: 400)',
    ERROR_403: 'K provedení této akce nemáte oprávnění',
    REFRESH_TOKEN_403: 'Vaše přístupové údaje již nejsou platné. Přihlaste se prosím znovu!',
    ERROR_500: 'Došlo k chybě. Prosím zkuste to znovu později. (Kód chyby: 500)'
  },
  list: {
    entityPluralDefault: 'Výsledek',
    emptyState: 'Žádné {{entityPlural}} neexistuje.',
    noResults: 'Nebylo nalezeno žádné {{entityPlural}}.',
    selectAll: 'Vybrat vše {{entityPlural}}',
    massAction: 'Propagace pro {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Vybrat vše {{count}}',
    massActionDeSelectAllGlobal: 'Zrušit výběr všech {{count}}'
  },
  languages: {
    ar: 'arabština',
    de: 'Němec',
    es: 'španělština',
    fr: 'francouzština',
    hr: 'chorvatský',
    it: 'italština',
    pt: 'portugalština',
    ru: 'ruština',
    bg: 'bulharský',
    en: 'Angličtina',
    fa: 'Peršan',
    el: 'řecký',
    hu: 'maďarský',
    pl: 'polština',
    ro: 'rumunština',
    tr: 'turečtina',
    uk: 'ukrajinština',
    cs: 'čeština',
    sk: 'Slovák'
  },
  now: 'Nyní',
  allCompanies: 'Všichni klienti',
  createDate: 'Datum vzniku',
  company: 'klienta',
  new: 'Nový',
  of: 'z',
  request: {
    deleteTitle: 'Smazat {{item}}',
    confirmDelete: "Opravdu chcete smazat záznam '{{itemName}}'?",
    itemAdded: '{{item}} byl vytvořen.',
    itemAddedError: '{{item}} nelze vytvořit.',
    itemSaved: '{{item}} bylo uloženo.',
    itemSavedError: '{{item}} nelze uložit.',
    itemRemoved: '{{item}} byl smazán.',
    itemRemovedError: '{{item}} nelze smazat.'
  },
  news: { pageTitle: 'Zprávy', edit: 'Upravte zprávy' },
  profiles: {
    loginProfiles: 'Přihlašovací profily',
    add: 'Přihlašovací profil',
    logout: 'Pokud se odhlásíte, všechna místní data a profily budou smazány! Chcete se odhlásit?',
    login: 'Registrovat',
    deleteConfirmation: 'Opravdu chcete smazat profil?',
    changed: 'Profil byl úspěšně změněn',
    deleted: 'Profil byl úspěšně smazán'
  },
  deleteAttachment: {
    confirmTitle: 'Smazat přílohu',
    confirmText: 'Opravdu chcete smazat tuto přílohu?'
  },
  comments: {
    addComment: 'přidat komentář',
    addCommentInfo: 'Komentář je viditelný pro každého, kdo může zobrazit tento tiket.',
    comment: 'komentář',
    deleteComment: 'Smazat komentář',
    deleteCommentConfirmation: 'Opravdu chcete smazat komentář?'
  }
};
