export default {
  barcodeScanner: {
    permissionHeader: 'Разрешение',
    permissionMessage: 'Ако искате да дадете разрешение за използване на вашата камера, активирайте го в настройките на приложението.',
    scanPrompt: 'Сканиране на {{thing}}',
    enterPrompt: 'Въведете {{thing}}',
    blinkId: 'Идентификация на мигане',
    isNoBlinkId: 'Сканираният QR код не е валиден Blink ID',
    objectCode: 'Обектен код'
  },
  camera: {
    promptLabelHeader: 'Изберете източник',
    promptLabelPicture: 'Направете снимка',
    promptLabelPhoto: 'Изберете от галерията'
  },
  network: {
    changed: {
      connected: 'Установена връзка с интернет',
      disconnected: 'Прекъсване на интернет връзката'
    }
  },
  file: { downloadStarted: 'Изтегляне започна.' },
  nfc: {
    wrongCode: 'Сканираният QR код не е код на обект!',
    error: 'Грешка при NFC'
  }
};
