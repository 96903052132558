<ion-row class="ion-justify-content-between" *ngIf="imageBase64">
  <ion-col size="auto">
    <img [src]="imageBase64" style="width: {{isApp ? 100: 200}}px">
  </ion-col>
  <ion-col size="auto">
    <ion-button fill="outline" color="danger" size="lg" [title]="t.global.removeImage | translate"
                (click)="deletePicture()">
      <ui-icon slot="icon-only" [icon]="BlinkIcon.Delete"></ui-icon>
    </ion-button>
  </ion-col>
</ion-row>

<ion-button (click)="takePicture()" *ngIf="!imageBase64">
  <i class="far fa-camera mr-5" slot="start"></i>
  {{ t.item.takePicture.buttonText | translate }}
</ion-button>
