import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlinkEmployee, BlinkLoginUser, LoginUserApi } from '@blink/api';
import { GlobalTranslateService, trackById } from '@blink/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'check-loginuser-search',
  templateUrl: './loginuser-search.component.html',
  styleUrls: ['./loginuser-search.component.scss']
})
export class LoginUserSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchRow') searchRowObj;
  trackById = trackById;

  subscriptions = new Subscription();

  searchTerm$ = new BehaviorSubject<string>('');
  showInactive$ = new BehaviorSubject(false);
  loginUsers$: Observable<BlinkLoginUser[]>;
  destroyRef = inject(DestroyRef);

  constructor(public modalController: ModalController,
              public t: GlobalTranslateService,
              private loginUserApi: LoginUserApi) {
  }

  ngOnInit() {
    this.loginUsers$ = combineLatest([
      this.loginUserApi.getLoginUsersForCheck(),
      this.searchTerm$,
      this.showInactive$
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([employees, searchTerm, showInactive]) =>
        employees
          .filter(x => {
            return x.displayName.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .filter(x => showInactive || x.Active)
          .sort((a, b) => a.displayName.localeCompare(b.displayName)))
    );
  }

  search(event) {
    this.searchTerm$.next(event.detail.value);
  }

  onLoginUserSelected(loginUser: BlinkLoginUser) {
    this.modalController.dismiss(loginUser, 'loginUserSelected');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleShowInactive(ev) {
    this.showInactive$.next(ev.detail.checked);
  }
}
