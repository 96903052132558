export default {
  barcodeScanner: {
    permissionHeader: 'تفويض',
    permissionMessage: 'إذا كنت تريد منح الإذن لاستخدام الكاميرا، فقم بتمكينها في إعدادات التطبيق.',
    scanPrompt: 'مسح {{thing}}',
    enterPrompt: 'أدخل {{thing}}',
    blinkId: 'معرف وميض',
    isNoBlinkId: 'رمز QR الممسوح ضوئيًا ليس معرف Blink صالحًا',
    objectCode: 'رمز التعليمة'
  },
  camera: {
    promptLabelHeader: 'اختر مصدر',
    promptLabelPicture: 'التقط صوره',
    promptLabelPhoto: 'اختر من المعرض'
  },
  network: {
    changed: {
      connected: 'تم تأسيس الاتصال بالإنترنت',
      disconnected: 'انقطع الاتصال بالإنترنت'
    }
  },
  file: { downloadStarted: 'لقد بدأ التنزيل.' },
  nfc: {
    wrongCode: 'رمز الاستجابة السريعة الممسوح ضوئيًا ليس رمزًا للكائن!',
    error: 'خطأ في NFC'
  }
};
