import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileType'
})
export class FileTypePipe implements PipeTransform {

  transform(value: string) {
    if (value) {
      const fileNameParts = value.split('.');
      const fileEnding = fileNameParts[fileNameParts.length - 1];
      switch (fileEnding) {
        case 'pdf':
          return 'pdf';
        case 'docx':
          return 'word';
        case 'xlsx':
          return 'excel';
        default:
          return 'alt';
      }
    } else return null;
  }

}
