export const standardColors = [
  '#fff568',
  '#ffc400',
  '#ff9800',
  '#ff6e40',
  '#f4511e',
  '#ff5252',
  '#e53935',
  '#e57373',
  '#f48fb1',
  '#9575cd',
  '#ba68c8',
  '#8c9eff',
  '#90caf9',
  '#64b5f6',
  '#d4e157',
  '#afb42b',
  '#9ccc65',
  '#bcaaa4',
  '#a1887f',
  '#a67c52',
  '#cfd8dc',
  '#90a4ae',
  '#78909c'
];
