export default {
  config: {
    checklist: {
      checklist: 'قالب النموذج',
      checklists: 'قوالب النماذج',
      none: 'لا توجد نماذج متاحة.',
      new: 'قالب النموذج الجديد',
      edit: 'تحرير قالب النموذج',
      saved: 'تم حفظ قالب النموذج.',
      delete: 'حذف قالب النموذج',
      deleteChecklistResults: 'هل تريد أيضًا حذف كافة النماذج المكتملة لقالب النموذج {{name}}؟',
      deleteInfo: 'إذا قمت بحذف قالب النموذج، فسيتم أيضًا حذف جميع النماذج المكتملة بشكل لا رجعة فيه! <br><br>للاحتفاظ بالنماذج المكتملة، قم بإلغاء تنشيط قالب النموذج.',
      deleted: 'تم حذف قالب النموذج.',
      duplicate: 'قالب نموذج مكرر',
      duplicateInfo: 'يمكنك استخدام الدالة المكررة لتكرار قوالب النماذج',
      duplicateMessage: 'هل تريد تكرار قالب النموذج "{{checklistName}}" بكل المحتوى؟',
      duplicated: 'تم تكرار قالب النموذج.',
      templateName: 'اسم النموذج',
      activeInfo: 'يمكن ملء القوالب النشطة فقط، ولا يمكن تحرير القوالب غير النشطة إلا في إدارة القوالب.',
      locationRequired: 'اختيار الكائن إلزامي',
      locationRequiredInfo: 'في حالة التنشيط، يجب تحديد كائن أو مسح رمز الاستجابة السريعة ضوئيًا لملء النموذج.',
      loginUserRequired: 'اختيار الموظفين إلزامي',
      loginUserRequiredInfo: 'في حالة التفعيل يجب اختيار موظف لملء النموذج.',
      allLocationAllowed: 'السماح لجميع الكائنات',
      allowOnlyCertainLocations: 'السماح بكائنات معينة فقط',
      public: 'النموذج العام',
      publicInfo: 'قم بإنشاء رابط عام يمكن من خلاله ملء النموذج من قبل أي مستخدم. لا يلزم تسجيل الدخول أو التطبيق. يمكنك تحديد تاريخ صلاحية حتى يصبح الارتباط صالحًا.',
      preview: 'تمكين المعاينة',
      previewInfo: 'في حالة تفعيله، سيتم دائمًا عرض زر "معاينة" في أسفل النموذج عند ملئه. كبديل لزر المعاينة الدائمة، يمكنك أيضًا وضع عنصر محتوى "المعاينة" في أي مكان في النموذج (لا يلزم تنشيط هذه الوظيفة).',
      shortUrlExpireDate: 'تاريخ الانتهاء',
      sysadminOnly: 'مدراء النظام فقط',
      allUsers: 'جميع المستخدمين',
      usersOfPermissionGroup: 'مستخدمو مجموعات الترخيص هذه',
      emailConfig: 'تكوين البريد الإلكتروني',
      emailConfigInfo: 'إذا قمت بإدخال عناوين البريد الإلكتروني هنا، فسيتم إرسال النماذج المكتملة تلقائيًا إلى عناوين البريد الإلكتروني هذه.',
      emailSaved: 'تم حفظ عنوان البريد الإلكتروني',
      emailRemoved: 'تم حذف عنوان البريد الإلكتروني',
      importExportInfo: 'تتيح لك وظيفة التصدير تصدير قوالب النماذج وقوالب التقارير المرتبطة بها لإعادة استيرادها إلى عميل أو نظام Blink Check آخر.',
      import: 'استيراد قالب النموذج',
      importInfo: 'الرجاء تحديد ملف .check-b64 الخاص بقالب النموذج الذي تريد استيراده.',
      imported: 'تم إنشاء النموذج بنجاح.',
      templatesInAppFolder: 'القوالب في دليل التطبيق:',
      invalidImport: 'الملف المحدد ليس قالب نموذج صالح.',
      exported: 'تم تصدير قالب النموذج',
      offline: 'الرجاء إنشاء اتصال بالإنترنت لتحرير قوالب النماذج.',
      checklistContainsCreateTask: 'يحتوي قالب النموذج على العنصر "إنشاء تذكرة" وبالتالي لا يمكن تعيينه للعامة. الرجاء إزالة عنصر "إنشاء تذكرة".'
    },
    category: {
      category: 'فئة',
      categories: 'فئات',
      saved: 'تم حفظ الفئة.',
      editTitle: 'تحرير الفئة',
      content: 'عناصر',
      newCategory: 'فئة جديدة',
      name: 'اسم التصنيف',
      edit: 'تحرير الفئة',
      duplicated: 'تم تكرار الفئة.',
      noCategories: 'لا توجد فئات بعد. قم بإنشاء فئات جديدة باستخدام زر "إضافة فئة".',
      inactive: 'غير نشط',
      showInactive: 'إظهار تلك غير النشطة',
      duplicateCategoryConfirmHeader: 'فئة مكررة',
      duplicateCategoryConfirm: 'هل تريد تكرار الفئة "{{categoryName}}" بكل محتوياتها؟',
      delete: 'حذف الفئة',
      deleteCategoryConfirm: 'هل أنت متأكد أنك تريد حذف الفئة "{{categoryName}}"؟',
      deleteSuccess: 'تمت إزالة الفئة.'
    },
    group: {
      group: 'صفحة',
      groups: 'الصفحات',
      created: 'تم إنشاء الصفحة.',
      updated: 'تم حفظ التغييرات التي تم إجراؤها على الصفحة.',
      updateError: 'لا يمكن حفظ الصفحة. الرجاء معاودة المحاولة في وقت لاحق.',
      rename: 'إعادة تسمية الصفحة',
      saved: 'تم حفظ الصفحة.',
      toggleSort: 'تغيير الفرز',
      editGroupName: 'تعديل اسم الصفحة',
      new: 'صفحة جديدة',
      name: 'اسم الصفحة',
      duplicated: 'تم تكرار الصفحة.',
      noGroups: 'لا توجد صفحات حتى الآن. قم بإنشاء صفحات جديدة باستخدام زر "إضافة صفحة".',
      noItems: 'لا توجد عناصر بعد. قم بإنشاء عناصر جديدة باستخدام زر "إضافة عنصر".',
      add: 'إضافة صفحة',
      infoElement: 'عنصر المعلومات',
      inputElement: 'عنصر الإدخال',
      addInfoElement: 'إضافة عنصر المعلومات',
      addInputElement: 'إضافة عنصر الإدخال',
      delete: 'حذف الصفحة',
      deleteGroupConfirm: 'هل أنت متأكد أنك تريد حذف الصفحة "{{groupName}}"؟',
      chooseGroup: 'ما هي الصفحة التي يجب إضافة العنصر إليها؟',
      duplicateGroupConfirmHeader: 'صفحة مكررة',
      duplicateGroupConfirm: 'هل تريد تكرار الصفحة "{{groupName}}" بكل محتواها؟',
      deleteSuccess: 'تمت إزالة الصفحة.'
    },
    item: {
      add: 'إضافة عنصر',
      created: 'تم إنشاء العنصر',
      saved: 'تم حفظ العنصر.',
      chooseItemType: 'حدد العنصر',
      values: 'قيم',
      value: 'قيمة',
      ratingNumber: 'تصنيف',
      addItemValue: 'أضف قيمة جديدة',
      addItemValueSuccess: 'تمت إضافة قيمة جديدة',
      updateItemValueSuccess: 'تم تحديث القيمة',
      removeItemValueSuccess: 'تمت إزالة القيمة',
      removeItemValue: 'إزالة القيمة',
      takePictureButtonText: 'اختر صورة',
      editTitle: 'تحرير العنصر',
      editDependenciesTitle: 'تحرير التبعيات',
      required: 'حقل الزامي',
      name: 'اسم العنصر',
      description: 'وصف اختياري للعنصر',
      points: 'نقاط',
      duplicated: 'تم تكرار العنصر.',
      duplicateItemConfirmHeader: 'عنصر مكرر',
      duplicateItemConfirm: 'هل تريد تكرار العنصر "{{itemName}}" بكل محتوياته؟',
      delete: {
        title: 'حذف العنصر',
        message: 'هل تريد حقًا حذف العنصر "{{itemName}}"؟',
        success: 'تمت إزالة العنصر'
      },
      moveItem: 'نقل العنصر',
      move: 'يؤجل',
      moveItemInfo: 'الرجاء تحديد الفئة والصفحة التي سيتم نقل العنصر الحالي إليها.',
      itemMoved: 'تم نقل العنصر'
    },
    editItem: {
      name: 'اسم العائلة',
      nameInfo: 'يتم عرض هذا النص مباشرة قبل عنصر الإدخال.',
      link: 'عنوان الرابط',
      linkInfo: 'سيتم الوصول إلى هذا العنوان عبر زر. أدخل رابط صحيح من فضلك.',
      description: 'وصف',
      descriptionInfo: 'إذا كنت ترغب في تقديم تعليمات لاستكمال النموذج، يمكنك إدخالها هنا.',
      linkDescription: 'تسمية الزر',
      linkDescriptionInfo: 'أدخل اسمًا للزر.',
      values: 'قيم',
      valuesInfo: 'يمكنك تحديد رقم تصنيف لكل قيمة. يمكنك استخدام رقم التصنيف لإجراء التقييمات لاحقًا. رقم التصنيف اختياري.',
      value: 'قيمة',
      valuePlaceholder: 'قيمة جديدة',
      ratingNumber: 'تصنيف',
      requiredField: 'حقل الزامي',
      requiredFieldInfo: 'إذا تم تفعيله كحقل إلزامي، فيجب ملء هذا الحقل حتى يمكن إرسال النموذج.',
      externalNo: 'رقم خارجي',
      externalNoInfo: 'معلومات اختيارية للواجهات أو التقييمات عبر الأنظمة الخارجية.',
      editable: 'قابل للتعديل',
      editableInfo: 'يجب أن يكون العنصر قابلاً للتحرير حتى يتمكن المستخدمون من إدخال البيانات.',
      defaultValue: 'تحديد القيمة الافتراضية',
      defaultValueInfo: 'يمكنك تحديد مهمة قياسية لهذا العنصر.',
      defaultValueInfoEditable: 'يمكن تغيير القيمة بواسطة مستخدم النموذج.',
      visible: 'الرؤية',
      visibleInfo: 'تتحكم الرؤية فيما إذا كان يمكن للمحرر رؤية العنصر أم لا.',
      externalNoAleradyUsed: 'هذا الرقم الخارجي قيد الاستخدام بالفعل.',
      taskTypeId: 'نوع التذكرة',
      loginUserId: 'مستخدم',
      locationId: 'اسم الكائن',
      videoId: 'رقم الفيديو',
      videoType: 'خدمة الفيديو',
      videoTypes_youtube: 'موقع YouTube',
      videoType_vimeo: 'فيميو',
      videoInfo: 'أدخل معرف الفيديو الفريد هنا. يمكنك العثور على هذا، على سبيل المثال، في عنوان URL للفيديو. <br/>مثال: https://www.youtube.com/watch?v=XXXXX أو على Vimeo https://vimeo.com/XXXXX. <br/>في هذه الحالة، يجب إدخال XXXXXX في الحقل.',
      defaultTaskTitle: 'العنوان الافتراضي للتذكرة',
      defaultTaskDescription: 'الوصف الافتراضي للتذكرة',
      titleEditable: 'عنوان التذكرة قابل للتعديل من قبل المستخدم',
      descriptionEditable: 'وصف التذكرة قابل للتعديل من قبل المستخدم',
      dueDateEditable: 'تاريخ استحقاق التذكرة قابل للتعديل من قبل المستخدم',
      addReportAttachment: 'قم بإرفاق النموذج المكتمل كملف PDF إلى التذكرة',
      noTaskTypesFound: 'لا يمكن العثور على أنواع التذاكر. يمكن إدارة أنواع التذاكر عبر Blink Active.',
      noLoginUsersFound: 'لا يمكن العثور على أي مستخدمين. يمكن إدارة المستخدمين عبر Blink Manager.',
      noLocationsFound: 'لا يمكن العثور على أي كائنات. يمكن إدارة الكائنات عبر Blink Manager.',
      createTaskItemInfo: 'باستخدام هذه الوظيفة، يمكنك إنشاء تذاكر Blink Active. يمكنك تحديد عنوان ووصف أو السماح للمستخدم بملئهما. يتم تعيين الكائن تلقائيًا، ويتم تعيين مدير الكائن المسؤول كمعالج.',
      duplicatedValuesNotAllowed: 'غير مسموح بالقيم المكررة.',
      valueLengthExceeded: 'القيمة المدخلة طويلة جدًا وسيتم اقتطاعها.'
    },
    itemTypes: {
      PreviewButton: 'معاينة الوثيقة',
      Select: 'الاختيار من القائمة',
      Signature: 'إمضاء',
      Email: 'إدخال البريد الإلكتروني',
      SendEmailTo: 'تسليم البريد الإلكتروني',
      Date: 'تاريخ',
      Time: 'وقت',
      Boolean: 'نعم لا سؤال',
      TakePicture: 'التقاط صورة',
      TakeMultiPicture: 'التقاط الصور',
      Scan: 'مسح QR/EAN',
      Input: 'إدخال النص',
      Textarea: 'إدخال نص متعدد الأسطر',
      Link: 'الربط',
      Picture: 'عرض الصور',
      Video: 'إعلان فيديو',
      Text: 'اظهار النص',
      Header: 'العنوان',
      Rate: 'تصنيف',
      RateSmiley: 'تصنيف مبتسم',
      CreateTask: 'إنشاء تذكرة',
      FileUpload: 'تحميل الملفات',
      Currency: 'عملة'
    },
    itemTypeDescriptions: {
      PreviewButton: 'عرض نسخة معاينة للنموذج.',
      Select: 'تحديد أي عناصر القائمة التي يمكن تحديد واحد منها.',
      Signature: 'حقل التوقيع الرقمي (يمكن استخدامه بالإصبع)',
      Email: 'يمكن للمستخدم تقديم عنوان بريد إلكتروني صالح.',
      SendEmailTo: 'يمكن للمستخدم تقديم عنوان بريد إلكتروني صالح سيتم إرسال النموذج المكتمل إليه كملف PDF.',
      Date: 'يمكن للمستخدم تحديد تاريخ عبر التقويم',
      Time: 'يمكن للمستخدم تحديد الوقت',
      Boolean: 'يختار المستخدم نعم أو لا',
      TakePicture: 'يمكن للمستخدم التقاط صورة بالكاميرا أو تحميل صورة.',
      TakeMultiPicture: 'يمكن للمستخدم التقاط صور متعددة بالكاميرا أو تحميل الصور.',
      Scan: 'مطالبة المستخدمين بمسح رمز الاستجابة السريعة أو EAN. يتم عرض النتيجة في النموذج وحفظها.',
      Input: 'حقل إدخال نص من سطر واحد',
      Textarea: 'إدخال نص متعدد الأسطر للتعليقات الأطول',
      Link: 'رابط إلى موقع خارجي',
      Picture: 'قم بتحميل الصورة التي سيتم عرضها عند ملئها.',
      Video: 'قم بتحميل مقطع فيديو سيتم عرضه أثناء ملئه.',
      Text: 'حفظ النصوص متعددة الأسطر بالتنسيق.',
      Header: 'عنوان كبير لتنظيم المحتوى',
      Rate: 'تصنيف 5 نجوم',
      RateSmiley: 'اختيار 3 وجوه مبتسمة (سعيد، محايد، حزين)',
      CreateTask: 'يتم إنشاء تذكرة للمستخدم',
      FileUpload: 'يمكن للمستخدم إرفاق أي عدد من الملفات بالنموذج.',
      Currency: 'يمكن للمستخدم تحديد مبلغ بالعملة.'
    },
    template: {
      template: 'قالب التقرير',
      templates: 'قوالب التقارير',
      name: 'اسم العائلة',
      description: 'وصف',
      customer: 'عميل',
      new: 'قالب تقرير جديد',
      edit: 'تحرير قالب التقرير',
      create: 'إنشاء قالب التقرير',
      public: 'عام',
      active: 'نشيط',
      deleteConfirm: "هل تريد حقًا حذف قالب التقرير '{{templateName}}'؟",
      deleteSuccess: 'تمت إزالة قالب التقرير',
      editor: 'مصمم القالب',
      notForMobile: 'لم يتم تحسين مصمم القالب للأجهزة المحمولة. الرجاء استخدام Blink Check في المتصفح لهذا الغرض.',
      noTemplates: 'لا توجد قوالب التقارير حتى الآن.',
      custom: 'القالب الفردي',
      defaultWithPoints: 'القالب القياسي - بنقاط مئوية',
      default: 'القالب القياسي - بدون نقاط مئوية',
      createTemplateInfo: 'لا يمكنك تحرير قالب التقرير إلا بعد إنشائه.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'مجموعة الأذونات العالمية',
    notAllowed: 'ليس لديك الإذن بتغيير أذونات هذا القالب.',
    all: 'يمكن ملء النموذج من قبل جميع المستخدمين',
    groups: 'لا يمكن ملء النموذج إلا بواسطة المستخدمين من مجموعات التفويض هذه',
    noGroupLicence: 'لم يتم تفعيل ترخيص Blink Check لهذه المجموعة.',
    overridingPermssion: 'يمنح خيار "إعدادات النظام" الموجود في مجموعة أذونات "مسؤول النظام" المستخدمين تلقائيًا كافة الأذونات لهذا النموذج.',
    onlyLicensedPermissionGroups: 'يتم عرض مجموعات التفويض التي تم تعيين ترخيص Blink Check لها فقط.',
    canUpdateChecklist: 'تحرير قالب النموذج',
    canFillChecklist: 'تعبئة الاستمارة',
    canReadChecklistDataOwn: 'عرض وتقييم النماذج المملوءة ذاتيا',
    canReadChecklistDataOwnEmployees: 'عرض وتقييم جميع النماذج المكتملة من قبل موظفيك',
    canReadChecklistDataOwnLocations: 'عرض وتقييم جميع النماذج المكتملة للأشياء الخاصة بك',
    canReadChecklistDataAll: 'عرض وتقييم جميع النماذج المكتملة'
  },
  companyConfig: {
    companyConfig: 'تكوين المستأجر',
    logoInfo: 'يظهر في الرأس في قوالب التقارير القياسية.',
    footer: 'تذييل',
    footerInfo: 'يظهر في التذييل في قوالب التقارير القياسية.',
    offline: 'يرجى إنشاء اتصال بالإنترنت لتتمكن من تعديل التكوين.'
  },
  switchCompanyDialogHeader: 'تغيير العملاء',
  switchCompanyDialogText: 'إذا قمت بتغيير العميل، سيتم إعادة تعيين كافة النماذج قيد التقدم. هل تريد الاستمرار؟',
  results: {
    results: 'تقييم',
    details: 'تفاصيل',
    created: 'مخلوق',
    result: 'نتيجة',
    noReportJsonExists: 'لم يتم إنشاء التقرير بعد. سيتم إنشاء التقرير متأخرًا، يرجى المحاولة مرة أخرى لاحقًا.',
    noResults: 'لا توجد نماذج مكتملة حتى الآن.',
    noResultsForDateFilter: 'لا توجد نماذج مكتملة للفترة المحددة.',
    periodFrom: 'الفترة من',
    periodTo: 'فترة تصل',
    location: 'هدف',
    createUser: 'المنشئ',
    loginUser: 'موظفين',
    checklist: 'قالب النموذج',
    user: 'مستخدم',
    recentResults: 'النماذج المكتملة حاليا',
    recent: 'حاضِر',
    pointOf: '{{ itemPoints }} من {{ maxItemPoints }} نقطة',
    checklistCreatedByAt: 'تم ملء النموذج بواسطة {{ firstName }} {{ lastName }} في {{ createDate }} الساعة {{ createTime }} مساءً.',
    linkedTask: 'التذكرة المرتبطة: #{{ taskId }} {{ taskTitle }}',
    offline: 'يرجى إنشاء اتصال بالإنترنت لتتمكن من تقييم النماذج المكتملة.',
    delete: {
      title: 'حذف النموذج المكتمل',
      message: 'هل تريد حقًا حذف النموذج المكتمل "{{checklistName}}"؟',
      success: 'تمت إزالة النموذج المكتمل'
    },
    editReferenceTimestamp: 'تعديل التاريخ المرجعي',
    editReferenceTimestampDescription: 'يحدد التاريخ المرجعي الوقت الذي تم فيه إكمال النموذج. قد يختلف هذا عن الوقت الذي تم فيه تقديم النموذج.'
  },
  dependencies: {
    dependency: 'الاعتماد',
    addTitle: 'الرجاء تحديد قيمة المقارنة',
    addMessage: 'يرجى تقديم القيمة التي يجب استيفاؤها حتى يتم عرض العنصر.',
    addTitleMulti: 'الرجاء تحديد قيم المقارنة',
    addMessageMulti: 'يرجى تقديم القيم، التي يجب استيفاء إحداها حتى يتم عرض العنصر.',
    present: 'متاح',
    notPresent: 'غير متوفره',
    noResults: 'لا توجد تبعيات موجودة. يمكن إضافة تبعيات جديدة عبر إضافة التبعية.',
    noAvailableItems: 'هناك عدد قليل جدًا من العناصر في النموذج لإنشاء التبعيات. الرجاء إضافة المزيد من العناصر أولا.',
    noAvailableItemsInGroup: 'هناك عدد قليل جدًا من العناصر في الصفحة لإنشاء التبعيات. الرجاء تحديد صفحة تحتوي على عناصر.',
    dependencyAdded: 'تمت إضافة التبعية.',
    dependencyRemoved: 'تمت إزالة التبعية.',
    dependencyInfo: 'بمجرد إضافة تبعية، لن يظهر هذا العنصر ({{elementName}}) إلا إذا تم ملء العنصر (العناصر) التابعة.',
    true: 'نعم',
    false: 'لا',
    '#not_empty#': 'متاح',
    '#empty#': 'غير متوفره',
    frown: 'حزين',
    meh: 'كل شيء على ما يرام',
    smile: 'سعيد'
  },
  noChecklists: 'لا توجد نماذج متاحة.',
  updating: 'يتم الآن تحديث Blink Check.',
  installing: 'جارٍ تثبيت الإصدار {{version}}...',
  appName: 'فحص وميض',
  notAvailableInPublicChecklists: ' (غير متوفر في قوالب النماذج العامة)',
  pdfAssignTemplate: 'يجب عليك تعيين قالب تقرير للنموذج قبل أن تتمكن من استخدام تنزيل PDF.'
};
