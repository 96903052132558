export default {
  home: {
    pageTitle: 'Moje formuláře',
    locationChecklists_label: 'Vyplňte formulář pro objekt',
    generalChecklists_label: 'Vyplňte obecný formulář',
    processingChecklists_label: 'Probíhá formulář',
    checklistDatas_label: 'Vyplněné formuláře',
    selectLocation: 'Vyberte objekt',
    scanQrCode: 'Naskenujte kód objektu',
    showAll: '... ukázat vše',
    showLess: '... Ukaž méně'
  },
  fill: {
    checklists: 'formuláře',
    fillChecklist: 'vyplnit formulář',
    checklistNotFound: 'Požadovaný formulář bohužel nebyl nalezen.',
    checklistOfflineLoginUserRequired: 'Tento formulář vyžaduje výběr zaměstnance. Pro přístup k šabloně formuláře vytvořte internetové připojení.',
    checklistNotOfflineAvailable: 'Šablona formuláře není uložena offline, pro přístup k šabloně formuláře se prosím připojte k internetu.',
    resetConfirm: 'Opravdu chcete resetovat formulář?',
    categoryAppeared: 'Kategorie „{{categoryName}}“ byla přidána.',
    groupAppeared: 'Stránka „{{groupName}}“ byla přidána.',
    itemAppearedInGroup: 'Na stránku „{{groupName}}“ byly přidány nové otázky.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'K další otázce',
    containerUriExpired: 'Bohužel doba nahrávání vypršela.',
    finishMessage: 'Zadali jste všechny potřebné údaje a nyní můžete vyplnit formulář.',
    offlineMessage: 'Pro vyplnění formuláře se prosím připojte k internetu.',
    offlinePreviewMessage: 'Chcete-li vygenerovat náhled, připojte se k internetu.',
    checklistUpdatedHeader: 'Šablona formuláře změněna',
    checklistUpdatedMessage: 'Šablona formuláře byla změněna. Před odesláním formuláře znovu zkontrolujte své záznamy.',
    checklistLocationRequiredHeader: 'Vyberte objekt',
    checklistLocationRequiredMessage: 'Chcete-li vyplnit tento formulář, musíte vybrat objekt.',
    fileUploadFileMissingHeader: 'Soubory nebyly nalezeny',
    fileUploadFileMissingMessage: 'Následující soubory byly vybrány, ale nelze je v zařízení nalézt: {{ missingFiles }}',
    finishSuccess: 'Formulář byl úspěšně vyplněn a všechna data zpracována.',
    finishError: 'Formulář nebylo možné vyplnit kvůli chybě. Kontaktujte prosím podporu.',
    createdWith: 'Tento formulář byl vytvořen pomocí',
    preview: 'náhled'
  },
  item: {
    boolean: { yes: 'Ano', no: 'Ne' },
    scan: 'skenovat',
    email: { invalidEmail: 'Zkontrolujte prosím svůj záznam.' },
    takePicture: { picture: 'Obrázek', buttonText: 'přidat fotku' },
    createTask: {
      taskTitle: 'Název lístku',
      taskDescription: 'Popis vstupenky',
      taskDueDate: 'Datum splatnosti vstupenky'
    }
  },
  noChecklistsForLocationFound: 'Pro tento objekt neexistuje žádný formulář. Vyberte prosím jinou nemovitost nebo vyplňte obecný formulář.',
  selectChecklistByLocation: 'Vyberte formulář pro objekt'
};
