import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export class TranslateExtension implements FormlyExtension {
  constructor(private translate: TranslateService) {
  }

  prePopulate(field: FormlyFieldConfig) {
    const props = field.props || {};
    if (!props.label || props['_translated']) {
      return;
    }

    props['_translated'] = true;

    field.expressions = {
      ...(field.expressions || {}),
      'props.label': this.translate.stream(props.label)
    };
  }
}

export const requiredMessage = (translate: TranslateService) =>
  (error: any, field: FormlyFieldConfig) =>
    translate.stream(
      'typedGlobal.formError.isRequired',
      { name: field.props.label.charAt(0).toUpperCase() + field.props.label.slice(1) }
    );

export function registerTranslateExtension(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message: requiredMessage(translate)
      }
    ],
    extensions: [
      {
        name: 'translate',
        extension: new TranslateExtension(translate)
      }
    ]
  };
}
