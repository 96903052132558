<ui-page [title]="t.REGISTRATION_SUCCESS.SIGNED_IN"
         [legacyWrapper]="true">
  <div class="ion-text-center">
    <h1 class="mt-0 mb-15">{{ t.REGISTRATION_SUCCESS.CONGRATULATIONS | translate }}</h1>
    <ui-icon
      [icon]="BlinkIcon.Success"
      class="text-primary"
      size="7x">
    </ui-icon>
    <p>{{ t.REGISTRATION_SUCCESS.SIGNED_IN_TEXT | translate }}</p>
    <ion-button class="go-to-homepage-button"
                expand="block"
                size="large"
                routerLink="/home">
      {{ t.REGISTRATION_SUCCESS.START | translate }}
    </ion-button>
  </div>
</ui-page>
