// noinspection ES6PreferShortImport

import { Injectable } from '@angular/core';
import { CheckItemValueRepository } from '../item-value/item-value.repository';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckItem } from './item';
import { CheckItemRepository } from './item.repository';
import { CheckItemDependencyRepository } from '../item-dependency/item-dependency.repository';

@Injectable({ providedIn: 'root' })
export class CheckItemConfigFacade {

  constructor(
    private itemRepo: CheckItemRepository,
    private itemValueRepo: CheckItemValueRepository,
    private itemDependencyRepo: CheckItemDependencyRepository) {
  }

  getManyForConfig(itemIds: number[]): Observable<CheckItem[]> {
    return combineLatest([
      this.itemRepo.getMany(itemIds),
      combineLatest(itemIds.map(itemId => this.itemValueRepo.getItemItemValues(itemId))),
      combineLatest(itemIds.map(itemId => this.itemDependencyRepo.getItemDependencies(itemId)))
    ]).pipe(
      map(([
             items,
             itemValuesMap,
             itemItemDependenciesMap
           ]) => {


          return items.map((item, i) => {
            // todo add custom item validators (like email or what)

            const noOrFulfilledDependencies =
              !itemItemDependenciesMap[i].length
              || itemItemDependenciesMap[i].some(d => d.fulfilled);
            const fillVisible =
              item.Visible
              && noOrFulfilledDependencies;

            return {
              ...item,
              itemValues: itemValuesMap[i],
              itemDependencies: itemItemDependenciesMap[i],
              fillVisible
            };
          }).sort((a, b) => a.Order - b.Order);
        }
      ));
  }


  // getGroupItemsForConfig(groupId: number) {
  //   return store.pipe(
  //     select(state => state.groupItemIdMap[groupId]),
  //     map(itemIds => itemIds ? itemIds : []),
  //     switchMap(itemIds =>
  //       itemIds.length
  //         ? this.getManyForConfig(itemIds)
  //         : of([])
  //     )
  //   );
  // }
}
