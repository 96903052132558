export default {
  home: {
    pageTitle: 'Početna stranica',
    welcome: 'Dobro došli u aplikaciju Blink me. Zabavite se razgovarajući,\n' +
      '\n' +
      'Vaš Blink tim',
    startChat: 'početi',
    ticket: 'ulaznica',
    timeLicenseMissing: 'Nije vam dodijeljena licenca za Blink Time!'
  },
  conversations: {
    pageTitle: 'Vijesti',
    yourChats: 'Vaši razgovori',
    chat: 'razgovor'
  },
  settings: {
    settings: 'postavke',
    pushEnabled: 'Primajte push obavijesti',
    system: 'sustav',
    languageSelection: 'izbor jezika',
    currentSettings: 'Trenutne postavke',
    user: 'korisnik',
    appVersion: 'Verzija (aplikacija)',
    apiVersion: 'Verzija (API)',
    deviceInfo: 'Verzija uređaja',
    buildVersion: 'verzija (verzija)',
    helpAndDataPolicy: 'Pomoć i zaštita podataka',
    text1: 'Naš tim za podršku rado će vam pomoći ako imate bilo kakvih pitanja:',
    phone: 'telefon',
    mail: 'e-pošta',
    information: 'info',
    documentation: 'Online dokumentacija',
    documentationText: 'U našoj mrežnoj dokumentaciji pronaći ćete upute i videozapise za sva važna područja Blinka.',
    dataPolicy: 'zaštita podataka',
    support: 'podrška',
    moreActions: 'Daljnje akcije',
    protocol: 'protokol',
    showChatUserSecret: 'Podijelite chat s drugim uređajem',
    scanCodeAnotherDevice: 'Skenirajte sljedeći QR kod na svom novom uređaju ili unesite svoj osobni chat kod na novom uređaju!',
    yourPersonalCode: 'Vaš osobni kod za chat',
    permissions: {
      title: 'obavijest',
      subtitle: 'Neophodno za primanje push obavijesti za chat poruke, treptajuće obavijesti i naljepnice.',
      grantPermission: 'dati dopuštenje',
      camera: 'fotoaparat',
      cameraHint: 'Neophodno za bilježenje vremena preko objektnog koda.',
      nfc: 'NFC',
      nfcHint: 'Neophodno za bilježenje vremena putem NFC oznake.',
      media: 'Uzmi sliku i sliku iz knjižnice',
      mediaHint: 'Neophodno za snimanje slika i dokumenata koji se odnose na vaše vrijeme i karte.',
      location: 'Mjesto',
      locationHint: 'Potrebno je zabilježiti vašu lokaciju u vrijeme snimanja vremena.',
      notifications: 'Push obavijesti',
      notificationsHint: 'Neophodno za primanje push obavijesti za chat poruke, treptajuće obavijesti i naljepnice.',
      locationDisabledOnSystem: 'Usluge lokacije su onemogućene. Aktivirajte ovo u postavkama svog uređaja.'
    }
  },
  connectionState: {
    connecting: 'Spojiti...',
    connected: 'Vezani zajedno.',
    disconnecting: 'Veza je prekinuta...',
    disconnected: 'izgubljena veza',
    denied: 'Veza se nije mogla uspostaviti.'
  },
  noOneToFind: 'Nitko se ne može pronaći.',
  nothingToFind: 'Ništa se ne može pronaći.',
  noLocationFound: 'Nema pronađenih objekata.',
  searchPlaceholder: 'Tražiti...',
  employeeSearchPlaceholder: 'Ime Ime',
  typeToSearch: 'Dodirnite za pretraživanje...',
  typeToSearchEmployee: 'Unesite ime osobe da započnete razgovor',
  createChatWithEmployee: 'Traže se zaposlenici',
  createChatWithLocationTeam: 'Objekt pretraživanja',
  createTeamleaderChat: 'Svi moji zaposlenici',
  yourLanguageSelection: 'Vaš odabir jezika:',
  loginInfo: 'Za registraciju skenirajte svoj osobni QR kod:',
  updating: 'Blink me se ažurira.',
  notice: 'Obavijest',
  importantNotice: 'Važna nota',
  continue: 'Unaprijediti',
  intro: {
    slide1: {
      header: 'Dobrodošli',
      text: 'Zabavite se uz svoju aplikaciju Blink me.'
    },
    slide2: {
      header: 'razgovor',
      text: 'Razgovarajte izravno sa svojim kolegama ili saznajte najnovije vijesti iz zanimljivih grupa.'
    },
    slide3: {
      header: 'Ulaznice',
      text: 'Ovdje možete vidjeti svoje zadatke i izraditi aplikacije.'
    }
  },
  pleaseWait: 'Molimo pričekajte...',
  skip: 'Preskočiti',
  startAgain: 'Počni iznova',
  lock: {
    createPinHeader: 'Vaš osobni PIN',
    createPinInfo: 'Kako biste zaštitili svoje podatke, ovdje možete postaviti PIN koji morate unijeti svaki put kada koristite aplikaciju Blink me.',
    validatePin: 'Unesite ponovo PIN.',
    tryAgain: 'PIN ne odgovara. Molimo pokušajte ponovo.',
    triedToOften: 'Nažalost, vaš PIN ne odgovara. Želite li ponovno pokrenuti proces ili ga preskočiti i učiniti kasnije?',
    enterPinHeader: 'Pin unos',
    enterPinInfo: 'Unesite svoj PIN za otključavanje aplikacije.',
    remainingTrys: 'Još {{amount}} pokušaja. Zatim se morate ponovno prijaviti u aplikaciju.',
    tooManyEnters: 'Nažalost, registracija PIN-om više nije moguća. Prijavite se ponovno sa svojom Blink ID karticom.',
    reSignIn: 'Nova registracija',
    setpin: 'Unos PIN-a prilikom korištenja aplikacije',
    skip: 'Ne želite li sada dodijeliti PIN? Ovu funkciju možete aktivirati u bilo kojem trenutku putem postavki aplikacije, uključujući i kasnije.',
    dontUsePin: 'nemojte koristiti PIN',
    deactivatePinHeader: 'Onemogući PIN',
    deactivatePinInfo: 'Unesite svoj PIN kako biste deaktivirali zaštitu.'
  },
  active: {
    yourTickets: 'Vaše ulaznice',
    createNewTask: 'Kreirajte novu kartu',
    assignee: 'urednik',
    location: 'objekt',
    dueDate: 'Zbog',
    createTask: 'Izradi ulaznicu',
    taskCreated: 'Ulaznica je kreirana.',
    backToTasks: 'Povratak na karte',
    createdAt: 'stvoreno na',
    attachments: 'Prilozi',
    addFileOrImage: 'Dodajte datoteku ili fotografiju',
    addImage: 'dodajte fotografiju',
    comments: 'Komentari',
    addComment: 'Dodaj komentar',
    addCommentInfo: 'Komentar je vidljiv svima koji mogu vidjeti ovu kartu.',
    commentCreated: 'Komentar je spremljen.',
    showClosed: 'Pogledajte dovršene ulaznice',
    resetFilter: 'resetirati sve filtere',
    changeTicketStatusTo: 'Promijeni status karte u:',
    editTask: 'Uredi kartu',
    saveTask: 'spremiti ulaznice',
    taskSaved: 'Ulaznica je spremljena.',
    deleteImage: 'Jasna slika',
    deleteImageConfirmation: 'Želite li stvarno izbrisati sliku?',
    deleteAttachment: 'Izbriši privitak',
    deleteAttachmentConfirmation: 'Jeste li sigurni da želite izbrisati ovaj privitak?',
    deleteComment: 'Obriši komentar',
    deleteCommentConfirmation: 'Želite li stvarno izbrisati komentar?',
    changeAssignee: 'promijeniti urednika',
    duplicateTask: 'Duplikat karte',
    duplicateTaskConfirmation: 'Želite li umnožiti ovu kartu?',
    taskDuplicated: 'Ulaznica je duplicirana.',
    deleteTask: 'Izbriši kartu',
    deleteTaskConfirmation: 'Jeste li sigurni da želite izbrisati ovu kartu?',
    taskDeleted: 'Ulaznica je izbrisana.',
    assigneeChanged: 'Procesor je promijenjen.',
    statusChanged: 'Status karte je promijenjen.',
    startDate: 'početi',
    endDate: 'Kraj',
    doctorVisitDate: 'Datum posjeta liječniku',
    doctorVisitDescription: 'Datum posjeta liječniku: {{date}}',
    chooseDate: 'Odaberite datum',
    comment: 'Komentar (neobavezno)',
    dateError: 'Datum završetka ne može biti prije datuma početka',
    employee: 'Zaposlenici',
    pleaseConnectToLoad: 'Uspostavite internetsku vezu za učitavanje ulaznica.'
  },
  emptyState: {
    chats: 'Napravite chat!',
    chat: 'Napiši prvu poruku :)',
    tasks: 'Nema ulaznica.',
    attachments: 'Nema dostupnih privitaka.',
    comments: 'Nema dostupnih komentara.',
    vacation: 'Za ovu godinu nema upisanog godišnjeg odmora'
  },
  formError: {
    min: 'Vrijednost mora biti veća od {{value}}.',
    min2: 'Vrijednost mora biti veća ili jednaka {{value}}.',
    max: 'Vrijednost mora biti manja ili jednaka {{value}}.',
    requiredBecause: 'Vrijednost je potrebna jer je {{name}} popunjeno.',
    isRequired: '{{name}} je obavezno.',
    numberInUse: 'Personalni broj je već u upotrebi.',
    maxlength: '{{name}} ne može sadržavati više od {{value}} znakova.',
    faultyBlinkId: 'ID treptanja nije točan'
  },
  ngSelect: {
    typeToSeach: 'Unesite 3 ili više slova.',
    loading: 'učitavanje podataka...',
    notFound: '{{title}} nije pronađeno.',
    clearAll: 'Vrati na zadano'
  },
  placeholder: {
    employee: 'Pretraživanje po imenu, prezimenu, ID-u zaposlenika, ID-u treptanja',
    location: 'Pretraživanje po nazivu, broju ili kodovima objekata',
    date: 'Odaberite datum',
    searchTasks: 'U potrazi za ulaznicama...'
  },
  hasChat: 'Započni chat sada',
  hasNoChatYet: 'Nažalost, još nisam instalirao aplikaciju Blink me.',
  noBlinkActiveLicence: 'Bez dozvole',
  noBlinkActiveLicenceInfo: 'Trenutno nemate licencu za ovu aplikaciju. Molimo kontaktirajte svog nadređenog.',
  noBlinkMeLicence: 'Bez dozvole',
  noBlinkMeLicenceInfo: 'Ova se aplikacija ne može koristiti s vašim sustavom. Koristite Blink Time iz aplikacije ili Playstorea.',
  noSuitableAppFound: 'Nije pronađena aplikacija za otvaranje datoteke.',
  myChats: 'tvoji razgovori',
  hasNoChatsHome: 'Počnite razgovarati sada! Možete razgovarati sa svim kolegama i sudjelovati u objektnim i timskim grupama.',
  hasNoTicketsHome: 'Nema otvorenih ulaznica.',
  myTickets: 'vaše ulaznice',
  myEmployees: 'Moji zaposlenici',
  group: 'skupina',
  confirmLogoutWithChat: 'Ako se odjavite, vaše chat poruke bit će izbrisane. Želite li nastaviti?',
  messageNotDecryptable: 'Poruka se ne može dešifrirati.',
  chat: {
    userAlreadyExists: 'Već ste postavili chat na drugom uređaju. Kako biste mogli koristiti chat na ovom uređaju, morate povezati uređaje. Alternativno, možete ponovno pokrenuti postavljanje, ali tada se chat više ne može koristiti na drugom uređaju.',
    linkDevices: 'Povežite uređaje',
    withoutChat: 'Nastavite bez razgovora',
    setupAgain: 'Ponovno postavite chat',
    setupAgainText: 'Ako ponovno postavite chat, sve poruke iz postojećih pojedinačnih chatova bit će izbrisane i chat se više ne može koristiti na drugim uređajima! Želite li nastaviti?',
    LinkDevicesText: 'Za povezivanje uređaja za chat, otvorite Postavke na svom drugom uređaju i odaberite "Dijeli chat s drugim uređajem".',
    personalToken: 'Vaš osobni kod za chat',
    wrongCode: 'Navedeni kod za chat nije točan.',
    beingPrepared: 'Chat je u pripremi.',
    twilioAccessDenied: 'Nije bilo moguće uspostaviti vezu s chat uslugom. Molimo kontaktirajte podršku za Blink.',
    notificationsDenied: 'Obavijesti su onemogućene na vašem uređaju/pregledniku. Idite na Postavke i omogućite obavijesti za Blink me za primanje obavijesti. Zatim možete aktivirati obavijesti u svojim osobnim postavkama.',
    deleted: 'Chat je izbrisan',
    unavailable: 'Chat nije dostupan'
  },
  protocol: {
    SEND_EMAIL: 'Pošalji e-poštom',
    DELETE: 'Izbriši dnevnik',
    deleteMessage: 'Želite li stvarno izbrisati dnevnik? Ova se radnja ne može poništiti.',
    Send: 'Poslati',
    ConfirmMessage: 'Zapisnik aplikacije sada će biti poslan podršci za Blink.',
    SuccessMessage: 'Dnevnik je uspješno poslan Blink podršci.'
  },
  timeTracking: {
    title: 'Praćenje vremena',
    start: 'Početak',
    startDescription: 'Pritisnite start za početak praćenja vremena',
    started: 'Započelo',
    stop: 'Zaustaviti',
    duration: 'Duljina vremena',
    syncButtonLabel: 'Vrijeme učitavanja zabilježeno izvan mreže',
    offlineSyncMessage: 'Morate biti na mreži za prijenos spremljenih vremena.',
    successfulSync: 'Vremena su učitana',
    scanNowQrCode: 'QR kod',
    scanNowQrCodeDescription: 'Skenirajte QR kod u objektu.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Prislonite pametni telefon na NFC naljepnicu u objektu.',
    nfcDescription: 'Detekcija putem NFC-a: Prislonite svoj mobitel na objektni kod',
    noTimeTrackingMethodAvailableDescription: 'Za ovog korisnika nije konfigurirana metoda praćenja vremena. Molimo kontaktirajte svog voditelja tima.',
    noLocationCode: 'Nema dostupnog koda',
    noLocationCodeDescription: 'Odaberite ovu opciju ako u objektu nema QR koda ili NFC naljepnice. Ostavljanje komentara je obavezno.',
    noLocationCodeConfirm: 'Jeste li sigurni da želite započeti bez objektnog koda? Morate dati komentar da biste prestali.',
    wrongCode: 'Skenirani QR kod nije objektni kod!',
    readNotice: 'Molimo pročitajte upute!',
    warning: 'upozorenje',
    lessThanAMinuteTitle: 'Kompletno snimanje vremena',
    lessThanAMinute: 'Počeli ste prije manje od 1 minute. Jeste li sigurni da želite prestati?',
    continue: 'Nastavi snimanje',
    timeAlert: {
      differenceInMinutesHint: 'Vrijeme na vašem pametnom telefonu razlikuje se od vremena na poslužitelju. Razlika se bilježi',
      differentTimeZoneHint: 'Vremenska zona vašeg pametnog telefona razlikuje se od vremenske zone poslužitelja. Razlika se bilježi.'
    },
    leaveComment: {
      withCode: 'Podaci o objektu/usluzi nisu bili jasni. Recite nam u kojem objektu/službi ste radili.',
      withoutCode: 'Nikakav objektni kod nije snimljen pri pokretanju ili zaustavljanju. Objasnite zašto ste započeli/zaustavili bez objekta.'
    },
    comment: 'komentar',
    enterComment: 'Komentirajte praćenje vremena',
    differentQrCode: {
      title: 'promijeniti objekt?',
      continueInLocation: 'Nastavite s radom u {{locationName}}.',
      automaticStopInLocation: 'Snimanje vremena u {{locationName}} će se automatski zaustaviti.',
      stopInLocation: 'Zaustavi snimanje vremena za {{locationName}}.',
      stop: 'Zaustaviti',
      stopNoLocation: 'Zaustavite snimanje vremena.',
      continueNoOldLocation: 'Snimanje vremena za prethodni objekt se automatski zaustavlja.',
      continueWorking: 'Nastavi raditi',
      continueWorkingInNewLocation: 'Nastavite s radom u novoj nekretnini.',
      stopWithLocationActivity: 'Zaustavi snimanje vremena {{locationActivity}}.',
      continueWithNewLocationActivity: 'Nastavite raditi s {{locationActivity}}',
      stopOldLocationActivity: 'Snimanje vremena na {{locationActivity}} automatski se zaustavlja.',
      stopOldNoLocationActivity: 'Snimanje vremena bez izvedbe automatski se zaustavlja.',
      continueNoLocationActivity: 'Nastavite s radom bez učinka.',
      sameLocationTitle: 'Promijeniti izvedbu?',
      differentLocationTitle: 'Promijeniti objekt?'
    },
    saveAndStop: 'Spremi i zaustavi',
    noLocationScanned: '- Nema prigovora -',
    noLocationFound: '- objekt dvosmislen -',
    timerDifference: '{{h}} sati {{mm}} minuta',
    stopSuccess: 'Vrijeme je uspješno zabilježeno',
    stopSuccessToast: 'Praćenje vremena uspješno je zaustavljeno.',
    stopTimeTracking: 'Zaustaviti',
    manual: {
      time: 'Vrijeme',
      locationSelect: 'Odaberite objekt',
      loadMore: 'Učitaj više',
      trackTime: 'Rekordno vrijeme',
      location: 'objekt',
      locationActivity: 'Izvođenje',
      date: 'Datum',
      startTime: 'Iz',
      endTime: 'Do',
      create: 'Potvrdi',
      createAgain: 'Zabilježite više vremena na ovom objektu',
      errorUntil: 'From Time mora biti manje od To Time',
      worktimeDifference: 'Radni sati:',
      worktimeDifferenceValue: '{{hours}} sati {{minutes}} minuta',
      worktimeDifferenceValueMinutes: '{{minutes}} minuta',
      success: 'Vrijeme je uspješno zabilježeno',
      break: 'Pauza:',
      breakMinutes: '{{minutes}} minuta',
      breakHoursAndMinutes: '{{hours}} sati {{minutes}} minuta',
      noActivity: '- bez performansi -',
      locationPlaceHolder: 'Pretraživanje po nazivu, broju ili kodovima objekata',
      conflictError: 'Već postoji bilježenje vremena u navedenim razdobljima. Preklapanja nisu dopuštena. Provjerite svoje podatke.',
      currentDayDuration: 'Već snimljeno',
      expectedDuration: 'Očekivano radno vrijeme',
      expectedDurationIncludedCurrent: 'uključujući trenutni ulaz'
    },
    differenceInMinutesHint: 'Vrijeme na vašem pametnom telefonu razlikuje se od vremena na poslužitelju. Razlika se bilježi',
    differentTimeZoneHint: 'Vremenska zona vašeg pametnog telefona razlikuje se od vremenske zone poslužitelja. Razlika se bilježi.',
    proposed: {
      capture: 'Uhvatiti',
      time: 'Vrijeme',
      captureTime: 'Rekordno vrijeme',
      button: 'Potvrdi',
      title: 'Potvrdite radno vrijeme',
      includingBreak: '(uključujući pauzu)',
      from: 'Iz',
      to: 'Do',
      break: 'Pauza',
      hour: 'Sat',
      confirm: {
        description: 'Molimo potvrdite svoje radno vrijeme',
        confirm: 'Da, tako je',
        editButton: 'Ne, to nije u redu'
      },
      edit: {
        description: 'Molimo navedite trajanje',
        breakIncluded: 'Uključujući {{ duration }} prekid',
        comment: 'komentar',
        yourComment: 'tvoj komentar',
        minutes: 'minuta',
        hours: 'Sati'
      },
      reasons: {
        overtime: 'Prekovremeni rad zbog kupca',
        faster: 'Gotovo brže',
        representative: 'Ja sam predstavnik',
        other: 'Razno'
      },
      description: 'Potvrdite svoje radno vrijeme čim završite s radom u objektu.'
    },
    codeNotAssignable: 'Skenirani QR kod nije moguće dodijeliti!',
    running: 'U tijeku je bilježenje vremena'
  },
  timeFramePicker: {
    title: 'Odaberite razdoblje',
    today: 'Danas',
    currentWeek: 'Ovaj tjedan',
    currentMonth: 'Trenutni mjesec',
    custom: 'Prilagođen',
    startDate: 'početi',
    endDate: 'Kraj'
  },
  timeSheet: {
    title: 'Vremena i planiranje',
    noPlannings: 'Bez planiranja',
    planned: 'Planirani',
    workingTime: 'radni sati',
    workloadDuration: 'Tjedno radno vrijeme',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Trebalo bi',
    workedDuration: 'Je',
    hasWorkload: 'Radi taj dan',
    pleaseConnectToLoad: 'Uspostavite internetsku vezu za učitavanje vremena.',
    footer: {
      planned: 'Planirani',
      worklogsTotal: 'Ukupno sati',
      workloadTotal: 'Radno vrijeme prema ugovoru',
      difference: 'razlika'
    },
    homepageTitle: 'Vaš radni dan'
  },
  planning: {
    includingPause: 'Pauza uklj.:',
    planRoute: 'Planirajte rutu',
    title: 'planiranje',
    modifyMinutes: 'Paušalna stopa:'
  },
  worklog: {
    title: 'Vrijeme zabilježeno',
    absenceTitle: 'odsutnost',
    workingTime: 'radni sati',
    breakDuration: 'Pauza',
    reduce: 'paušal',
    attachments: 'Prilozi',
    comments: 'Komentari',
    comment: 'komentar',
    noLocation: 'Nema navedenog objekta',
    changedBy: 'Vrijeme se promijenilo s {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Jeste li sigurni da želite izbrisati ovaj privitak?',
    attachmentDeleted: 'Prilog je izbrisan',
    confirmCommentDelete: 'Jeste li sigurni da želite izbrisati ovaj komentar?',
    commentDeleted: 'Komentar je izbrisan'
  },
  tabs: {
    home: 'Dom',
    times: 'puta',
    chat: 'razgovor',
    tickets: 'Ulaznice',
    more: 'više'
  },
  profile: {
    title: 'profil',
    testing: 'Testiranje',
    testingDescription: 'Ovo područje je vidljivo samo ako ste spojeni na testni sustav.',
    testingAllowQrCodeOnWeb: 'Dopusti ručni unos QR koda',
    pushNotifications: 'Push obavijesti',
    reloadUserContext: 'Ponovno učitaj korisnički kontekst',
    pushNotificationSuccessful: 'Push obavijest uspješno primljena',
    editProfilePicture: 'promijeni sliku profila',
    editProfilePictureTitle: 'Odaberite odjeljak',
    pushNotificationNotice: 'Test zahtijeva dopuštenje "Uređivanje postavki stanara".',
    send: 'Poslati',
    profilePicture: 'Profilna slika'
  },
  help: { title: 'Pomozite' },
  myVacations: {
    vacation: 'Odmor',
    amountAvailableVacationDays: 'Još je dostupno {{amount}} dana odmora',
    totalVacationDays: 'Pravo na godišnji odmor {{ total }} dana',
    remainingVacationDays: '({{ total }} dana {{ sign }} {{ remaining }} dana preostalog odmora {{ previousYear }})',
    xDaysVacations: '{{ total }} dana odmora',
    title: 'Vaš odmor'
  },
  externalLinks: {
    title: 'vanjske poveznice',
    notice: 'Ovu vezu je dala vaša tvrtka. Napuštate sustav Blink.'
  },
  stickers: {
    title: 'Vaše naljepnice',
    sticker: 'Naljepnice',
    sender: 'Primatelj',
    text: 'Što se tiče',
    date: 'Datum',
    emptyState: 'Nažalost, još nemate nijednu naljepnicu',
    senderDescription: 'Ovu naljepnicu vam je poslao {{sender}} {{date}}',
    readed: 'čitati',
    notReaded: 'još nisam pročitao',
    newStickerToaster: 'Nova naljepnica za treptaj!',
    open: 'Otvoren',
    next: 'Unaprijediti',
    titleCases: {
      case1: 'Čestitamo na vašoj prvoj naljepnici!',
      case2: 'Odlično, sada već imate <b>{{count}}</b> naljepnica!',
      case3: 'Čestitamo na vašoj 10. naljepnici!',
      case4: '30 naljepnica - wow!',
      case5: 'Sada ovdje postaje gužva: <b>{{count}}</b> naljepnice!'
    },
    confirmDelete: 'Želite li stvarno izbrisati ove naljepnice?'
  },
  autoTranslate: {
    isTranslated: 'Automatski prevedeno',
    translationError: 'Automatski prijevod trenutno nije moguć'
  },
  confirmLogout: 'Želite li se stvarno odjaviti?',
  message: 'Vijesti',
  migration: {
    wrongVersion: 'U trenutnoj verziji Blink me nije podržana prijava putem aplikacije Blink Time. Ažurirajte aplikaciju Blink me na najnoviju verziju'
  },
  eLearning: {
    pendingTab: 'Za napraviti ({{value}})',
    completedTab: 'Gotovo ({{value}})',
    completed: 'Završeno',
    pending: 'Otvoren',
    dueFalling: 'Zakašnjelo',
    title: 'E-učenje',
    course: 'tečaj',
    dueDate: 'Vrijedi do',
    topics: 'predmeta',
    completeDate: 'Završeno na',
    status: { Pending: 'otvoren', Done: 'dovršeno' },
    infos: {
      pendingExists: 'Ovdje možete pronaći sve svoje otvorene tečajeve.',
      finishOverdue: 'Molimo vas da što prije odradite obuku koja je već zakazana.',
      allCompleted: 'izvrsno! Uspješno ste završili sve svoje tečajeve obuke.',
      emptyState: 'Nemate dodijeljenu obuku.'
    }
  },
  checklists: {
    title: 'Obrasci',
    description: 'Ispunite obrazac za objekt',
    button: 'Odaberite objekt'
  }
};
