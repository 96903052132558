export default {
  home: {
    pageTitle: 'Formlarım',
    locationChecklists_label: 'Bir nesne için form doldurma',
    generalChecklists_label: 'Genel formu doldurun',
    processingChecklists_label: 'Form devam ediyor',
    checklistDatas_label: 'Tamamlanan formlar',
    selectLocation: 'Nesne seç',
    scanQrCode: 'Nesne kodunu tarayın',
    showAll: '... Hepsini Göster ↓',
    showLess: '... daha az göster'
  },
  fill: {
    checklists: 'Formlar',
    fillChecklist: 'formu doldur',
    checklistNotFound: 'Maalesef istediğiniz form bulunamadı.',
    checklistOfflineLoginUserRequired: 'Bu form bir çalışanın seçimini gerektirir. Form şablonuna erişmek için lütfen internet bağlantısı kurun.',
    checklistNotOfflineAvailable: "Form şablonu çevrimdışı olarak kaydedilmez; form şablonuna erişmek için lütfen İnternet'e bağlanın.",
    resetConfirm: 'Formu gerçekten sıfırlamak istiyor musunuz?',
    categoryAppeared: '"{{categoryName}}" kategorisi eklendi.',
    groupAppeared: '"{{groupName}}" sayfası eklendi.',
    itemAppearedInGroup: '"{{groupName}}" sayfasına yeni sorular eklendi.',
    openGroupsAvailable: 'openGroupsMevcut',
    toNextGroup: 'Bir sonraki soruya',
    containerUriExpired: 'Maalesef yükleme süresi doldu.',
    finishMessage: 'Gerekli tüm verileri girdiniz ve artık formu doldurabilirsiniz.',
    offlineMessage: 'Formu doldurmak için lütfen internete bağlanın.',
    offlinePreviewMessage: "Bir önizleme oluşturmak için lütfen İnternet'e bağlanın.",
    checklistUpdatedHeader: 'Form şablonu değiştirildi',
    checklistUpdatedMessage: 'Form şablonu değiştirildi. Lütfen formu göndermeden önce girişlerinizi tekrar kontrol edin.',
    checklistLocationRequiredHeader: 'nesne seç',
    checklistLocationRequiredMessage: 'Bu formu doldurmak için bir nesne seçmelisiniz.',
    finishSuccess: 'Form başarıyla tamamlandı ve tüm veriler işlendi.',
    finishError: 'Form bir hatadan dolayı tamamlanamadı. Lütfen desteğe başvurun.',
    createdWith: 'Bu form şununla oluşturuldu:',
    preview: 'Ön izleme',
    fileUploadFileMissingHeader: 'Dosyalar bulunamadı',
    fileUploadFileMissingMessage: 'Aşağıdaki dosyalar seçildi ancak cihazda bulunamıyor: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Evet', no: 'HAYIR' },
    scan: 'tarama',
    email: { invalidEmail: 'Lütfen girişinizi kontrol edin.' },
    takePicture: { picture: 'Resim', buttonText: 'fotoğraf ekle' },
    createTask: {
      taskTitle: 'Biletin başlığı',
      taskDescription: 'Biletin açıklaması',
      taskDueDate: 'Bilet son tarihi'
    }
  },
  noChecklistsForLocationFound: 'Bu nesnenin herhangi bir formu yoktur. Lütfen başka bir mülk seçin veya genel bir form doldurun.',
  selectChecklistByLocation: 'Nesne için form seçin'
};
