export default {
  home: {
    pageTitle: 'Le mie forme',
    locationChecklists_label: 'Compila un modulo per un oggetto',
    generalChecklists_label: 'Compila il modulo generale',
    processingChecklists_label: 'Modulo in corso',
    checklistDatas_label: 'Moduli compilati',
    selectLocation: 'Seleziona oggetto',
    scanQrCode: 'Scansiona il codice oggetto',
    showAll: '...mostra tutto',
    showLess: '... mostra meno'
  },
  fill: {
    checklists: 'Forme',
    fillChecklist: 'Compilare il modulo',
    checklistNotFound: 'Purtroppo non è stato possibile trovare il modulo richiesto.',
    checklistOfflineLoginUserRequired: 'Questo modulo richiede la selezione di un dipendente. Stabilire una connessione Internet per accedere al modello di modulo.',
    checklistNotOfflineAvailable: 'Il modello di modulo non viene salvato offline, connettiti a Internet per accedere al modello di modulo.',
    resetConfirm: 'Vuoi davvero reimpostare il modulo?',
    categoryAppeared: 'La categoria "{{categoryName}}" è stata aggiunta.',
    groupAppeared: 'La pagina "{{groupName}}" è stata aggiunta.',
    itemAppearedInGroup: 'Sono state aggiunte nuove domande alla pagina "{{groupName}}".',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Alla prossima domanda',
    containerUriExpired: 'Purtroppo il periodo di caricamento è scaduto.',
    finishMessage: 'Hai inserito tutti i dati necessari e ora puoi compilare il modulo.',
    offlineMessage: 'Collegati a Internet per completare il modulo.',
    offlinePreviewMessage: "Connettiti a Internet per generare un'anteprima.",
    checklistUpdatedHeader: 'Il modello del modulo è stato modificato',
    checklistUpdatedMessage: 'Il modello del modulo è stato modificato. Si prega di controllare nuovamente i dati inseriti prima di inviare il modulo.',
    checklistLocationRequiredHeader: 'Seleziona oggetto',
    checklistLocationRequiredMessage: 'È necessario selezionare un oggetto per completare questo modulo.',
    finishSuccess: 'Il modulo è stato completato con successo e tutti i dati sono stati elaborati.',
    finishError: "Impossibile completare il modulo a causa di un errore. Si prega di contattare l'assistenza.",
    createdWith: 'Questo modulo è stato creato con',
    preview: 'anteprima',
    fileUploadFileMissingHeader: 'File non trovati',
    fileUploadFileMissingMessage: 'I seguenti file sono stati selezionati ma non possono essere trovati sul dispositivo: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'SÌ', no: 'NO' },
    scan: 'scansione',
    email: { invalidEmail: 'Per favore controlla la tua voce.' },
    takePicture: { picture: 'Immagine', buttonText: 'aggiungere una foto' },
    createTask: {
      taskTitle: 'Titolo del biglietto',
      taskDescription: 'Descrizione del biglietto',
      taskDueDate: 'Data di scadenza del biglietto'
    }
  },
  noChecklistsForLocationFound: "Non esiste alcuna forma per questo oggetto. Seleziona un'altra proprietà o compila un modulo generale.",
  selectChecklistByLocation: "Seleziona il modulo per l'oggetto"
};
