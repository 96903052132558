import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlinkIcon } from '../ui-icon';
import { Subject } from 'rxjs/internal/Subject';
import { throttleTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/*
https://ionicframework.com/docs/api/button
 */
@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss']
})
export class UiButtonComponent {
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Input() label: string;
  @Input() disabled = false;
  @Input() icon: BlinkIcon = null;
  @Input() iconSlot: 'start' | 'end' = 'start';
  @Input() textRight = false;
  @Input() textLeft = false;
  @Input() iconOnly = false;
  @Input() iconSolid = false;
  @Input() expand: 'full' | 'block' | undefined = undefined;
  @Input() fill: 'clear' | 'outline' | 'solid' | 'default' | undefined = undefined;
  @Input() size: 'default' | 'large' | 'small' | undefined = undefined;
  @Input() color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | undefined = undefined;
  buttonClicked = new Subject();

  constructor() {
    this.buttonClicked
      .asObservable()
      .pipe(
        takeUntilDestroyed(),
        //throttle for 1.5 seconds until next click
        throttleTime(1500)).subscribe(() => {
      this.clicked.emit();
    });
  }
}
