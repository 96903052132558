export default {
  barcodeScanner: {
    permissionHeader: 'авторизація',
    permissionMessage: 'Якщо ви хочете надати дозвіл на використання камери, увімкніть її в налаштуваннях програми.',
    scanPrompt: 'Сканувати {{thing}}',
    enterPrompt: 'Введіть {{thing}}',
    blinkId: 'Blink ID',
    isNoBlinkId: 'Відсканований QR-код не є дійсним Blink ID',
    objectCode: "Об'єктний код"
  },
  camera: {
    promptLabelHeader: 'Виберіть джерело',
    promptLabelPicture: 'зробити знімок',
    promptLabelPhoto: 'Виберіть із галереї'
  },
  network: {
    changed: {
      connected: 'Підключення до Інтернету встановлено',
      disconnected: 'З’єднання з Інтернетом перервано'
    }
  },
  file: { downloadStarted: 'Завантаження розпочато.' },
  nfc: {
    wrongCode: "Відсканований QR-код не є об'єктним кодом!",
    error: 'Помилка NFC'
  }
};
