export default {
  config: {
    checklist: {
      checklist: 'قالب فرم',
      checklists: 'قالب های فرم',
      none: 'هیچ فرمی موجود نیست',
      new: 'قالب فرم جدید',
      edit: 'ویرایش قالب فرم',
      saved: 'قالب فرم ذخیره شده است.',
      delete: 'حذف قالب فرم',
      deleteChecklistResults: 'همچنین تمام فرم های تکمیل شده قالب فرم {{name}} حذف شود؟',
      deleteInfo: 'اگر قالب فرم را حذف کنید، تمام فرم های تکمیل شده نیز به صورت غیر قابل برگشت حذف خواهند شد! <br><br>برای نگهداری فرم های تکمیل شده، قالب فرم را غیرفعال کنید.',
      deleted: 'قالب فرم حذف شده است.',
      duplicate: 'قالب فرم تکراری',
      duplicateInfo: 'می توانید از تابع تکراری برای کپی کردن قالب های فرم استفاده کنید',
      duplicateMessage: 'آیا می خواهید قالب فرم "{0}}" را با تمام محتوا کپی کنید؟',
      duplicated: 'قالب فرم تکراری بود.',
      templateName: 'نام فرم',
      activeInfo: 'فقط قالب‌های فعال را می‌توان پر کرد؛ قالب‌های غیرفعال را فقط می‌توان در مدیریت قالب ویرایش کرد.',
      locationRequired: 'انتخاب شیء الزامی است',
      locationRequiredInfo: 'در صورت فعال شدن، یک شی باید انتخاب شود یا کد QR اسکن شود تا فرم پر شود.',
      loginUserRequired: 'انتخاب کارمند الزامی است',
      loginUserRequiredInfo: 'در صورت فعال شدن، باید یک کارمند برای پر کردن فرم انتخاب شود.',
      allLocationAllowed: 'اجازه دادن به همه اشیاء',
      allowOnlyCertainLocations: 'فقط به اشیاء خاصی اجازه دهید',
      public: 'فرم عمومی',
      publicInfo: 'یک پیوند عمومی ایجاد کنید که با آن فرم توسط هر کاربری پر شود. هیچ لاگین یا اپلیکیشنی لازم نیست. می توانید تاریخ اعتبار تا زمانی که پیوند باید معتبر باشد را مشخص کنید.',
      preview: 'فعال کردن پیش نمایش',
      previewInfo: 'در صورت فعال شدن، هنگام پر کردن فرم، دکمه "پیش نمایش" همیشه در پایین فرم نمایش داده می شود. به عنوان جایگزینی برای دکمه پیش‌نمایش دائمی، می‌توانید عنصر محتوای «پیش‌نمایش» را در هر جایی از فرم قرار دهید (این عملکرد نیازی به فعال کردن ندارد).',
      shortUrlExpireDate: 'تاریخ انقضا',
      sysadminOnly: 'فقط مدیران سیستم',
      allUsers: 'تمام کاربران',
      usersOfPermissionGroup: 'کاربران این گروه های مجوز',
      emailConfig: 'پیکربندی ایمیل',
      emailConfigInfo: 'اگر آدرس های ایمیل را در اینجا وارد کنید، فرم های تکمیل شده به طور خودکار به این آدرس های ایمیل ارسال می شود.',
      emailSaved: 'آدرس ایمیل ذخیره شده است',
      emailRemoved: 'آدرس ایمیل حذف شده است',
      importExportInfo: 'تابع صادرات به شما امکان می‌دهد قالب‌های فرم و الگوهای گزارش مرتبط را صادر کنید تا آنها را به مشتری یا سیستم دیگری Blink Check وارد کنید.',
      import: 'وارد کردن قالب فرم',
      importInfo: 'لطفاً فایل .check-b64 قالب فرمی را که می خواهید وارد کنید انتخاب کنید.',
      imported: 'فرم با موفقیت ایجاد شد.',
      templatesInAppFolder: 'الگوهای موجود در فهرست برنامه:',
      invalidImport: 'فایل انتخابی یک قالب فرم معتبر نیست.',
      exported: 'قالب فرم صادر شده است',
      offline: 'لطفاً برای ویرایش الگوهای فرم یک اتصال اینترنتی برقرار کنید.',
      checklistContainsCreateTask: 'الگوی فرم حاوی عنصر "ایجاد بلیط" است و بنابراین نمی توان آن را روی عمومی تنظیم کرد. لطفاً عنصر "ایجاد بلیط" را حذف کنید.'
    },
    category: {
      category: 'دسته بندی',
      categories: 'دسته بندی ها',
      saved: 'دسته ذخیره شده است.',
      editTitle: 'ویرایش دسته',
      content: 'عناصر',
      newCategory: 'دسته بندی جدید',
      name: 'نام دسته',
      edit: 'ویرایش دسته',
      duplicated: 'دسته تکراری بود',
      noCategories: 'هنوز هیچ دسته ای وجود ندارد. با استفاده از دکمه "افزودن دسته" دسته بندی های جدید ایجاد کنید.',
      inactive: 'غیر فعال',
      showInactive: 'غیرفعال ها را نشان دهید',
      duplicateCategoryConfirmHeader: 'دسته بندی تکراری',
      duplicateCategoryConfirm: 'آیا می خواهید دسته "{0}}" را با تمام محتویات آن کپی کنید؟',
      delete: 'حذف دسته',
      deleteCategoryConfirm: 'آیا مطمئن هستید که می خواهید دسته "{{categoryName}}" را حذف کنید؟',
      deleteSuccess: 'دسته حذف شده است.'
    },
    group: {
      group: 'صفحه',
      groups: 'صفحات',
      created: 'صفحه ایجاد شده است.',
      updated: 'تغییرات صفحه ذخیره شده است.',
      updateError: 'صفحه ذخیره نشد. لطفاً بعداً دوباره امتحان کنید.',
      rename: 'تغییر نام صفحه',
      saved: 'صفحه ذخیره شد',
      toggleSort: 'تغییر مرتب سازی',
      editGroupName: 'ویرایش نام صفحه',
      new: 'صفحه جدید',
      name: 'نام صفحه',
      duplicated: 'صفحه تکراری شد',
      noGroups: 'هنوز هیچ صفحه ای وجود ندارد. با استفاده از دکمه "افزودن صفحه" صفحات جدیدی ایجاد کنید.',
      noItems: 'هنوز هیچ عنصری وجود ندارد. با استفاده از دکمه "افزودن عنصر" عناصر جدید ایجاد کنید.',
      add: 'اضافه کردن صفحه',
      infoElement: 'عنصر اطلاعات',
      inputElement: 'عنصر ورودی',
      addInfoElement: 'افزودن عنصر اطلاعات',
      addInputElement: 'عنصر ورودی را اضافه کنید',
      delete: 'حذف صفحه',
      deleteGroupConfirm: 'آیا مطمئن هستید که می خواهید صفحه "{{groupName}}" را حذف کنید؟',
      chooseGroup: 'المان به کدام صفحه باید اضافه شود؟',
      duplicateGroupConfirmHeader: 'صفحه تکراری',
      duplicateGroupConfirm: 'آیا می خواهید صفحه "{0}}" را با تمام محتوا کپی کنید؟',
      deleteSuccess: 'صفحه حذف شده است.'
    },
    item: {
      add: 'افزودن عنصر',
      created: 'مورد ایجاد شد',
      saved: 'مورد ذخیره شده است.',
      chooseItemType: 'مورد را انتخاب کنید',
      values: 'ارزش های',
      value: 'ارزش',
      ratingNumber: 'ارزیابی',
      addItemValue: 'ارزش جدید اضافه کنید',
      addItemValueSuccess: 'ارزش جدید اضافه شد',
      updateItemValueSuccess: 'ارزش به روز شده است',
      removeItemValueSuccess: 'مقدار حذف شده است',
      removeItemValue: 'حذف مقدار',
      takePictureButtonText: 'تصویر را انتخاب کنید',
      editTitle: 'ویرایش عنصر',
      editDependenciesTitle: 'ویرایش وابستگی ها',
      required: 'فیلد اجباری',
      name: 'نام عنصر',
      description: 'شرح اختیاری عنصر',
      points: 'نکته ها',
      duplicated: 'مورد تکراری بود',
      duplicateItemConfirmHeader: 'عنصر تکراری',
      duplicateItemConfirm: 'آیا می خواهید عنصر "{{itemName}}" را با تمام محتویات آن کپی کنید؟',
      delete: {
        title: 'حذف آیتم',
        message: 'آیا واقعاً می خواهید عنصر "{{itemName}}" را حذف کنید؟',
        success: 'مورد حذف شده است'
      },
      moveItem: 'انتقال عنصر',
      move: 'حرکت',
      moveItemInfo: 'لطفاً دسته و صفحه ای را انتخاب کنید که مورد فعلی باید به آن منتقل شود.',
      itemMoved: 'مورد منتقل شده است'
    },
    editItem: {
      name: 'نام خانوادگی',
      nameInfo: 'این متن مستقیماً قبل از عنصر ورودی نمایش داده می شود.',
      link: 'آدرس لینک',
      linkInfo: 'این آدرس از طریق یک دکمه قابل دسترسی خواهد بود. لطفا یک نشانی وب معتبر وارد کنید.',
      description: 'شرح',
      descriptionInfo: 'اگر مایل به ارائه دستورالعمل برای تکمیل فرم هستید، می توانید آنها را در اینجا وارد کنید.',
      linkDescription: 'برچسب دکمه',
      linkDescriptionInfo: 'یک نام برای دکمه وارد کنید.',
      values: 'ارزش های',
      valuesInfo: 'می توانید برای هر مقدار یک عدد رتبه بندی تعیین کنید. می توانید بعداً با استفاده از شماره رتبه بندی ارزیابی ها را انجام دهید. شماره امتیاز اختیاری است.',
      value: 'ارزش',
      valuePlaceholder: 'ارزش جدید',
      ratingNumber: 'ارزیابی',
      requiredField: 'فیلد اجباری',
      requiredFieldInfo: 'اگر به عنوان یک فیلد اجباری فعال شود، باید این فیلد پر شود تا فرم ارسال شود.',
      externalNo: 'شماره خارجی',
      externalNoInfo: 'اطلاعات اختیاری برای رابط ها یا ارزیابی ها از طریق سیستم های خارجی.',
      editable: 'قابل ویرایش',
      editableInfo: 'برای اینکه کاربران بتوانند مطالب را وارد کنند، عنصر باید قابل ویرایش باشد.',
      defaultValue: 'مقدار پیش فرض را مشخص کنید',
      defaultValueInfo: 'می توانید یک تخصیص استاندارد برای این عنصر تعیین کنید.',
      defaultValueInfoEditable: 'مقدار توسط کاربر فرم قابل تغییر است.',
      visible: 'دید',
      visibleInfo: 'Visibility کنترل می کند که آیا این عنصر توسط ویرایشگر قابل مشاهده است یا خیر.',
      externalNoAleradyUsed: 'این شماره خارجی در حال حاضر در حال استفاده است.',
      taskTypeId: 'نوع بلیط',
      loginUserId: 'کاربر',
      locationId: 'نام شی',
      videoId: 'شماره ویدیو',
      videoType: 'سرویس ویدئو',
      videoTypes_youtube: 'یوتیوب',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'شناسه ویدیوی منحصر به فرد را اینجا وارد کنید. شما می توانید این را، به عنوان مثال، در URL ویدیو پیدا کنید. <br/>به عنوان مثال: https://www.youtube.com/watch?v=XXXXXX یا در Vimeo https://vimeo.com/XXXXXX. <br/> در این مورد، XXXXX باید در فیلد وارد شود.',
      defaultTaskTitle: 'عنوان پیش فرض بلیط',
      defaultTaskDescription: 'شرح پیش فرض بلیط',
      titleEditable: 'عنوان بلیط قابل ویرایش توسط کاربر',
      descriptionEditable: 'توضیحات بلیط قابل ویرایش توسط کاربر',
      dueDateEditable: 'تاریخ سررسید بلیط قابل ویرایش توسط کاربر',
      addReportAttachment: 'فرم تکمیل شده را به صورت PDF به بلیط پیوست کنید',
      noTaskTypesFound: 'هیچ نوع بلیطی پیدا نشد. انواع بلیط را می توان از طریق Blink Active مدیریت کرد.',
      noLoginUsersFound: 'هیچ کاربری پیدا نشد کاربران را می توان از طریق Blink Manager مدیریت کرد.',
      noLocationsFound: 'هیچ شیئی یافت نشد. اشیاء را می توان از طریق Blink Manager مدیریت کرد.',
      createTaskItemInfo: 'با این تابع می توانید بلیط های فعال چشمک زدن ایجاد کنید. می توانید عنوان و توضیحات را مشخص کنید یا از کاربر بخواهید آن را تکمیل کند. شی به طور خودکار اختصاص داده می شود و مدیر شی مسئول به عنوان پردازنده اختصاص داده می شود.',
      duplicatedValuesNotAllowed: 'مقادیر تکراری مجاز نیستند.',
      valueLengthExceeded: 'مقدار وارد شده خیلی طولانی است و کوتاه خواهد شد.'
    },
    itemTypes: {
      PreviewButton: 'پیش نمایش سند',
      Select: 'انتخاب از لیست',
      Signature: 'امضا',
      Email: 'ورودی ایمیل',
      SendEmailTo: 'تحویل ایمیل',
      Date: 'تاریخ',
      Time: 'زمان',
      Boolean: 'پرسش بلی یا خیر',
      TakePicture: 'عکس گرفتن',
      TakeMultiPicture: 'عکس گرفتن',
      Scan: 'اسکن Qr/EAN',
      Input: 'ورودی متن',
      Textarea: 'ورودی متن چند خطی',
      Link: 'ربط دادن',
      Picture: 'نمایش تصویر',
      Video: 'تبلیغ ویدیویی',
      Text: 'نمایش متن',
      Header: 'عنوان',
      Rate: 'ارزیابی',
      RateSmiley: 'رتبه بندی لبخند',
      CreateTask: 'بلیط ایجاد کنید',
      FileUpload: 'فایل ها را آپلود کنید',
      Currency: 'واحد پول'
    },
    itemTypeDescriptions: {
      PreviewButton: 'نسخه پیش نمایش فرم را مشاهده کنید.',
      Select: 'هر مورد از لیست را که می توان از بین آنها انتخاب کرد را تعریف کنید.',
      Signature: 'فیلد امضای دیجیتال (قابل استفاده با انگشت)',
      Email: 'کاربر می تواند یک آدرس ایمیل معتبر ارائه دهد.',
      SendEmailTo: 'کاربر می تواند یک آدرس ایمیل معتبر ارائه کند که فرم تکمیل شده به صورت PDF به آن ارسال شود.',
      Date: 'کاربر می تواند تاریخ را از طریق تقویم مشخص کند',
      Time: 'کاربر می تواند زمان را مشخص کند',
      Boolean: 'کاربر بله یا خیر را انتخاب می کند',
      TakePicture: 'کاربر می تواند با دوربین عکس بگیرد یا تصویری را آپلود کند.',
      TakeMultiPicture: 'کاربر می تواند چندین عکس با دوربین بگیرد یا تصاویر را آپلود کند.',
      Scan: 'از کاربران بخواهید یک کد QR یا EAN را اسکن کنند. نتیجه در فرم نمایش داده می شود و ذخیره می شود.',
      Input: 'فیلد ورودی متن تک خطی',
      Textarea: 'ورودی متن چند خطی برای نظرات طولانی تر',
      Link: 'لینک به سایت خارجی',
      Picture: 'تصویری را بارگذاری کنید که هنگام پر کردن نمایش داده شود.',
      Video: 'ویدیویی را آپلود کنید که هنگام پر کردن فرم نمایش داده می‌شود.',
      Text: 'قالب بندی را به متون چند خطی اضافه کنید.',
      Header: 'عنوان بزرگ برای ساختار محتوا',
      Rate: 'امتیاز 5 ستاره',
      RateSmiley: 'انتخاب 3 صورتک (شاد، خنثی، غمگین)',
      CreateTask: 'یک بلیط برای یک کاربر ایجاد می شود',
      FileUpload: 'کاربر می تواند هر تعداد فایل را به فرم پیوست کند.',
      Currency: 'کاربر می تواند مبلغی را به ارز مشخص کند.'
    },
    template: {
      template: 'الگوی گزارش',
      templates: 'الگوهای گزارش',
      name: 'نام خانوادگی',
      description: 'شرح',
      customer: 'مشتری',
      new: 'قالب گزارش جدید',
      edit: 'ویرایش قالب گزارش',
      create: 'ایجاد الگوی گزارش',
      public: 'عمومی',
      active: 'فعال',
      deleteConfirm: 'آیا مطمئن هستید که می خواهید الگوی گزارش "{{templateName}}" را حذف کنید؟',
      deleteSuccess: 'الگوی گزارش حذف شده است',
      editor: 'طراح قالب',
      notForMobile: 'طراح قالب برای دستگاه های تلفن همراه بهینه نشده است. لطفاً برای این کار از Blink Check در مرورگر استفاده کنید.',
      noTemplates: 'هنوز هیچ الگوی گزارشی وجود ندارد.',
      custom: 'قالب فردی',
      defaultWithPoints: 'الگوی استاندارد - با درصد',
      default: 'الگوی استاندارد - بدون امتیاز درصد',
      createTemplateInfo: 'فقط پس از ایجاد الگوی گزارش می توانید آن را ویرایش کنید.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'گروه مجوز جهانی',
    notAllowed: 'شما اجازه تغییر مجوزهای این الگو را ندارید.',
    all: 'این فرم برای همه کاربران قابل پر کردن است',
    groups: 'این فرم را فقط کاربران این گروه های مجوز می توانند پر کنند',
    noGroupLicence: 'مجوز Blink Check برای این گروه فعال نیست.',
    overridingPermssion: 'گزینه "System Settings" در گروه مجوز "Sysadmin" به طور خودکار کلیه مجوزهای این فرم را به کاربران می دهد.',
    onlyLicensedPermissionGroups: 'فقط گروه های مجوزی که مجوز Blink Check به آنها اختصاص داده شده است نمایش داده می شوند.',
    canUpdateChecklist: 'ویرایش قالب فرم',
    canFillChecklist: 'فرم را پر کنید',
    canReadChecklistDataOwn: 'مشاهده و ارزیابی فرم های خود پر شده',
    canReadChecklistDataOwnEmployees: 'تمام فرم های تکمیل شده توسط کارمندان خود را مشاهده و ارزیابی کنید',
    canReadChecklistDataOwnLocations: 'تمام فرم های تکمیل شده اشیاء خود را مشاهده و ارزیابی کنید',
    canReadChecklistDataAll: 'مشاهده و ارزیابی تمام فرم های تکمیل شده'
  },
  companyConfig: {
    companyConfig: 'پیکربندی مستاجر',
    logoInfo: 'در سربرگ الگوهای گزارش استاندارد ظاهر می شود.',
    footer: 'پاورقی',
    footerInfo: 'در پاورقی الگوهای گزارش استاندارد ظاهر می شود.',
    offline: 'لطفاً یک اتصال اینترنتی برقرار کنید تا بتوانید پیکربندی را ویرایش کنید.'
  },
  switchCompanyDialogHeader: 'مشتریان را تغییر دهید',
  switchCompanyDialogText: 'اگر مشتری را تغییر دهید، همه فرم‌های در حال انجام بازنشانی خواهند شد. می خواهید ادامه دهید؟',
  results: {
    results: 'ارزیابی',
    details: 'جزئیات',
    created: 'ایجاد شده',
    result: 'نتیجه',
    noReportJsonExists: 'این گزارش هنوز ایجاد نشده است. گزارش با تأخیر ایجاد می‌شود، لطفاً بعداً دوباره امتحان کنید.',
    noResults: 'هنوز هیچ فرم تکمیل شده ای وجود ندارد.',
    noResultsForDateFilter: 'هیچ فرم تکمیل شده ای برای دوره انتخابی وجود ندارد.',
    periodFrom: 'دوره از',
    periodTo: 'دوره بالا',
    location: 'هدف - شی',
    createUser: 'ایجاد کننده',
    loginUser: 'کارمندان',
    checklist: 'قالب فرم',
    user: 'کاربر',
    recentResults: 'فرم های در حال تکمیل',
    recent: 'جاری',
    pointOf: '{{ itemPoints }} از {{ maxItemPoints }} امتیاز',
    checklistCreatedByAt: 'فرم توسط {{ firstName }} {{ lastName }} در {{ createDate }} ساعت {3}} بعد از ظهر پر شد.',
    linkedTask: 'بلیت پیوندی: #{{ taskId }} {{ taskTitle }}',
    offline: 'لطفاً برای ارزیابی فرم های تکمیل شده، اتصال اینترنتی برقرار کنید.',
    delete: {
      title: 'حذف فرم تکمیل شده',
      message: 'آیا واقعاً می خواهید فرم تکمیل شده "{{checklistName}}" را حذف کنید؟',
      success: 'فرم تکمیل شده حذف شده است'
    },
    editReferenceTimestamp: 'ویرایش تاریخ مرجع',
    editReferenceTimestampDescription: 'تاریخ مرجع زمان تکمیل فرم را تعیین می کند. این ممکن است با زمانی که فرم ارسال شده است متفاوت باشد.'
  },
  dependencies: {
    dependency: 'وابستگی',
    addTitle: 'لطفا مقدار مقایسه را انتخاب کنید',
    addMessage: 'لطفاً مقداری را که برای نمایش آیتم باید رعایت شود را وارد کنید.',
    addTitleMulti: 'لطفا مقادیر مقایسه را انتخاب کنید',
    addMessageMulti: 'لطفاً مقادیری را ارائه کنید که برای نمایش آیتم باید یکی از آنها رعایت شود.',
    present: 'در دسترس',
    notPresent: 'غیر قابل دسترسی',
    noResults: 'هیچ وابستگی وجود ندارد. وابستگی های جدید را می توان از طریق "افزودن وابستگی" اضافه کرد.',
    noAvailableItems: 'عناصر کمی در فرم برای ایجاد وابستگی وجود دارد. لطفا ابتدا موارد بیشتری را اضافه کنید.',
    noAvailableItemsInGroup: 'عناصر بسیار کمی در صفحه برای ایجاد وابستگی وجود دارد. لطفاً صفحه ای را انتخاب کنید که حاوی عناصر باشد.',
    dependencyAdded: 'وابستگی اضافه شد.',
    dependencyRemoved: 'وابستگی حذف شده است.',
    dependencyInfo: 'هنگامی که یک وابستگی اضافه می‌کنید، این مورد ({{elementName}}) تنها در صورتی ظاهر می‌شود که مورد(های) وابسته پر شده باشد.',
    true: 'آره',
    false: 'خیر',
    '#not_empty#': 'در دسترس',
    '#empty#': 'غیر قابل دسترسی',
    frown: 'غمگین',
    meh: 'داره خوب میشه',
    smile: 'خوشحال'
  },
  noChecklists: 'هیچ فرمی موجود نیست',
  updating: 'بررسی چشمک زدن در حال به روز رسانی است.',
  installing: 'در حال نصب نسخه {{version}}...',
  appName: 'چشمک زدن چک',
  notAvailableInPublicChecklists: ' (در قالب های عمومی موجود نیست)',
  pdfAssignTemplate: 'قبل از اینکه بتوانید از دانلود PDF استفاده کنید، باید یک الگوی گزارش به فرم اختصاص دهید.'
};
