import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { deleteEntitiesByPredicate, selectAllEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs'
import { PersistStateService } from "../shared";
import { ReplaySubject } from 'rxjs';


const logSorter = (a: BLogEntry, b: BLogEntry) => {
  return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
};

export interface BLogEntry {
  id: string;
  error: string;
  timestamp: string;
}

const store = createStore(
  {name: 'log'},
  withEntities<BLogEntry, 'id'>({idKey: 'id'})
);

@Injectable({providedIn: 'root'})
export class LogRepository {
  store = store;
  allLogs$ = store.pipe(selectAllEntities(), map(conversations => conversations.sort(logSorter)));

  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();

  constructor(private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store, true).subscribe(() => {
      store.update(deleteEntitiesByPredicate(({timestamp}) => dayjs().diff(dayjs(timestamp), 'days') > 14));
      this.persistentStateReadySubject.next(true)
    });
  }

  add(error, isError = false) {
    if (isError) {
      console.error(error);
    }

    store.update(upsertEntities({
      id: uuidv4(),
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      timestamp: dayjs().toLocaleString()
    }));
  }

  addWithExecuteTime(log, start: dayjs.Dayjs, end: dayjs.Dayjs) {
    const difference = end.diff(start, 'millisecond');
    const message = `${log}. Diff: ${difference}ms, Start: ${start.format('HH:mm:ss:SSS')}, End: ${end.format('HH:mm:ss:SSS')}`;
    this.add(message);
  }
}

