import { Injectable } from '@angular/core';

import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckSendMail } from './send-mail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckSendMailApiService {
  private api = this.apiManager
    .createApiForEndpoint<CheckSendMail>(
      'odata/v1',
      'SendMails',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager) {
  }

  create(checklistId, emailAddress) {
    const body = {
      ChecklistId: checklistId,
      Address: emailAddress,
      Name: ''
    };
    return this.api.post({ body });
  }

  getForChecklist(checklistId: number): Observable<CheckSendMail[]> {
    const options = {
      filter: { ChecklistId: checklistId }
    };
    return this.api.get(options);
  }

  update(sendMail: CheckSendMail): Observable<CheckSendMail> {
    const options = {
      key: sendMail.Id,
      oDataReturnRepresentation: true,
      body: { Address: sendMail.Address }
    };
    return this.api.patch(options);
  }

  delete(id: number) {
    return this.api.delete({ key: id });
  }

}
