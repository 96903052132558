export default {
  home: {
    pageTitle: 'My forms',
    locationChecklists_label: 'Fill in form for an object',
    generalChecklists_label: 'Fill in general form',
    processingChecklists_label: 'Form in process',
    checklistDatas_label: 'Completed forms',
    selectLocation: 'Select object',
    scanQrCode: 'Scan object code',
    showAll: '... show all',
    showLess: '... show less'
  },
  fill: {
    checklists: 'Forms',
    fillChecklist: 'Fill out form',
    checklistNotFound: 'Sorry, the requested form could not be found.',
    checklistOfflineLoginUserRequired: 'This form requires the selection of an employee. Please connect to the internet to access the form.',
    checklistNotOfflineAvailable: 'The form is not stored offline, please connect to the internet to access the form.',
    resetConfirm: 'Do you really want to reset the form?',
    categoryAppeared: 'Category "{{categoryName}}" was added.',
    groupAppeared: 'Page "{{groupName}}" was added.',
    itemAppearedInGroup: 'New questions have been added to the "{{groupName}}" page.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'To the next question',
    containerUriExpired: 'Unfortunately, the upload period has expired.',
    finishMessage: 'You have entered all the required data and can now close the form.',
    offlineMessage: 'Please connect to the internet to complete the form.',
    offlinePreviewMessage: 'Please connect to the internet to generate a preview.',
    checklistUpdatedHeader: 'Form Template Changed',
    checklistUpdatedMessage: 'The form template has been changed. Please check your entries again before submitting the form.',
    checklistLocationRequiredHeader: 'Select object',
    checklistLocationRequiredMessage: 'You must select an object to fill out this form.',
    finishSuccess: 'The form has been completed successfully and all data has been processed.',
    finishError: 'The form could not be completed due to an error. Please contact the support.',
    createdWith: 'This form was created with',
    preview: 'Preview',
    fileUploadFileMissingHeader: 'Files not found',
    fileUploadFileMissingMessage: 'The following files have been selected but cannot be found on the device: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'Yes', no: 'No' },
    scan: 'Scan',
    email: { invalidEmail: 'Please check your input.' },
    takePicture: { picture: 'Image', buttonText: 'Add photo' },
    createTask: {
      taskTitle: 'Ticket title',
      taskDescription: 'Ticket description',
      taskDueDate: 'Due date of the ticket'
    }
  },
  noChecklistsForLocationFound: 'There is no form for this object. Please choose another object or fill in a general form.',
  selectChecklistByLocation: 'Select form for object'
};
