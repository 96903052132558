export default {
  barcodeScanner: {
    permissionHeader: 'Autorización',
    permissionMessage: 'Si quieres dar permiso para usar tu cámara, actívalo en los ajustes de la aplicación.',
    scanPrompt: '{{thing}} escanear',
    enterPrompt: 'Introduzca {{thing}}',
    blinkId: 'ID de parpadeo',
    isNoBlinkId: 'El código QR escaneado no es un Blink ID válido',
    objectCode: 'Código objeto'
  },
  camera: {
    promptLabelHeader: 'Seleccionar fuente',
    promptLabelPicture: 'Haz una foto',
    promptLabelPhoto: 'Seleccione de la galería'
  },
  network: {
    changed: {
      connected: 'Conexión a Internet establecida',
      disconnected: 'Conexión a Internet interrumpida'
    }
  },
  file: { downloadStarted: 'Descarga iniciada.' },
  nfc: {
    wrongCode: 'El código QR escaneado no es un código objeto.',
    error: 'Error NFC'
  }
};
