import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared/api-manager';
import { CheckItemValue } from './item-value';
import { tap } from 'rxjs/operators';
import { CheckItemValueRepository } from './item-value.repository';

@Injectable({
  providedIn: 'root'
})
export class CheckItemValueApi {
  private api = this.apiManager
    .createApiForEndpoint<CheckItemValue>(
      'odata/v1',
      'ItemValues',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private itemValueRepo: CheckItemValueRepository) {
  }

  create(itemValue: Partial<CheckItemValue>) {
    console.log('create!', itemValue);
    return this.api.post({ body: itemValue }).pipe(tap(
      created => this.itemValueRepo.sync([created], false)
    ));
  }

  update(itemValue: CheckItemValue) {
    const query = {
      key: itemValue.Id,
      oDataReturnRepresentation: true,
      body: itemValue
    }
    return this.api.patch(query).pipe(tap(
      updated => this.itemValueRepo.sync([updated], false)
    ));
  }

  delete(id: number) {
    this.api.delete({ key: id }).subscribe(
      () => this.itemValueRepo.delete(id)
    );
  }
}
