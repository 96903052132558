import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiButton } from '../../shared/button';
import { UiConfirmDialogOptions } from '../dialog.service';
import { BlinkIcon } from '../../ui-icon/blink-icon';

@Component({
  selector: 'ui-dialog-confirm',
  templateUrl: './ui-dialog-confirm.component.html',
  styleUrls: ['./ui-dialog-confirm.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiDialogConfirmComponent {
  options: UiConfirmDialogOptions;

  constructor(private modalController: ModalController) {
  }

  @HostListener('window:keydown.escape', ['$event'])
  handleEscape() {
    this.closeModal();
  }

  @HostListener('window:keydown.enter', ['$event'])
  handleEnter() {
    const button = this.options.buttons.find(x => x.handleOnEnter);
    if (button) {
      this.handle(button);
    }
  }

  handle(button: UiButton) {
    if (button?.handler) {
      button.handler();
    }
    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  protected readonly BlinkIcon = BlinkIcon;
}
