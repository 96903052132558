import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared/api-manager';
import { tap } from 'rxjs/operators';

import { CheckCategoryDependency } from './category-dependency';
import { CheckCategoryDependencyRepository } from './category-dependency.repository';

@Injectable({ providedIn: 'root' })
export class CheckCategoryDependencyApi {
  private api = this.apiManager.createApiForEndpoint<CheckCategoryDependency>(
    'odata/v1',
    'CategoryDependencies',
    BlinkService.Check
  );

  constructor(private apiManager: ApiManager,
              private categoryDependencyRepo: CheckCategoryDependencyRepository) {
  }

  create(categoryDependency: Partial<CheckCategoryDependency>) {
    return this.api.post({ body: categoryDependency }).pipe(tap(
      d => this.categoryDependencyRepo.sync([d], false)
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.categoryDependencyRepo.delete(id)
    ));
  }
}
