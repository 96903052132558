export default {
  config: {
    checklist: {
      checklist: 'Πρότυπο φόρμας',
      checklists: 'Πρότυπα φόρμας',
      none: 'Δεν υπάρχουν διαθέσιμα έντυπα.',
      new: 'Νέο πρότυπο φόρμας',
      edit: 'Επεξεργασία προτύπου φόρμας',
      saved: 'Το πρότυπο φόρμας έχει αποθηκευτεί.',
      delete: 'Διαγραφή προτύπου φόρμας',
      deleteChecklistResults: 'Να διαγραφούν επίσης όλες οι συμπληρωμένες φόρμες του προτύπου φόρμας {{name}};',
      deleteInfo: 'Εάν διαγράψετε το πρότυπο φόρμας, όλες οι συμπληρωμένες φόρμες θα διαγραφούν επίσης αμετάκλητα! <br><br>Για να διατηρήσετε τις συμπληρωμένες φόρμες, απενεργοποιήστε το πρότυπο φόρμας.',
      deleted: 'Το πρότυπο φόρμας διαγράφηκε.',
      duplicate: 'Πρότυπο διπλότυπης φόρμας',
      duplicateInfo: 'Μπορείτε να χρησιμοποιήσετε τη λειτουργία διπλότυπο για να αντιγράψετε πρότυπα φόρμας',
      duplicateMessage: 'Θέλετε να αντιγράψετε το πρότυπο φόρμας "{{checklistName}}" με όλο το περιεχόμενο;',
      duplicated: 'Το πρότυπο φόρμας έγινε διπλότυπο.',
      templateName: 'Όνομα φόρμας',
      activeInfo: 'Μόνο ενεργά πρότυπα μπορούν να συμπληρωθούν, ανενεργά πρότυπα μπορούν να επεξεργαστούν μόνο στη διαχείριση προτύπων.',
      locationRequired: 'Η επιλογή αντικειμένου είναι υποχρεωτική',
      locationRequiredInfo: 'Εάν είναι ενεργοποιημένο, πρέπει να επιλεγεί ένα αντικείμενο ή να σαρωθεί ο κωδικός QR για να συμπληρωθεί η φόρμα.',
      loginUserRequired: 'Η επιλογή υπαλλήλου είναι υποχρεωτική',
      loginUserRequiredInfo: 'Εάν ενεργοποιηθεί, πρέπει να επιλεγεί ένας υπάλληλος για να συμπληρώσει τη φόρμα.',
      allLocationAllowed: 'Επιτρέψτε όλα τα αντικείμενα',
      allowOnlyCertainLocations: 'Επιτρέψτε μόνο ορισμένα αντικείμενα',
      public: 'Δημόσια μορφή',
      publicInfo: 'Δημιουργήστε έναν δημόσιο σύνδεσμο με τον οποίο η φόρμα μπορεί να συμπληρωθεί από οποιονδήποτε χρήστη. Δεν απαιτείται σύνδεση ή εφαρμογή. Μπορείτε να καθορίσετε μια ημερομηνία εγκυρότητας μέχρι να είναι έγκυρος ο σύνδεσμος.',
      preview: 'Ενεργοποίηση προεπισκόπησης',
      previewInfo: 'Εάν είναι ενεργοποιημένο, ένα κουμπί «Προεπισκόπηση» θα εμφανίζεται πάντα στο κάτω μέρος της φόρμας κατά τη συμπλήρωσή της. Εναλλακτικά στο κουμπί μόνιμης προεπισκόπησης, μπορείτε επίσης να τοποθετήσετε το στοιχείο περιεχομένου «Προεπισκόπηση» οπουδήποτε στη φόρμα (αυτή η λειτουργία δεν χρειάζεται να ενεργοποιηθεί).',
      shortUrlExpireDate: 'Ημερομηνία λήξης',
      sysadminOnly: 'Μόνο διαχειριστές συστήματος',
      allUsers: 'Ολοι οι χρήστες',
      usersOfPermissionGroup: 'Χρήστες αυτών των ομάδων εξουσιοδότησης',
      emailConfig: 'Διαμόρφωση email',
      emailConfigInfo: 'Εάν εισαγάγετε διευθύνσεις email εδώ, οι συμπληρωμένες φόρμες θα αποστέλλονται αυτόματα σε αυτές τις διευθύνσεις email.',
      emailSaved: 'Η διεύθυνση email έχει αποθηκευτεί',
      emailRemoved: 'Η διεύθυνση email έχει διαγραφεί',
      importExportInfo: 'Η λειτουργία εξαγωγής σάς επιτρέπει να εξάγετε πρότυπα φόρμας και σχετικά πρότυπα αναφορών για να τα εισαγάγετε ξανά σε άλλο πελάτη ή σύστημα ελέγχου αναλαμπής.',
      import: 'Εισαγωγή προτύπου φόρμας',
      importInfo: 'Επιλέξτε το αρχείο .check-b64 του προτύπου φόρμας που θέλετε να εισαγάγετε.',
      imported: 'Η φόρμα δημιουργήθηκε με επιτυχία.',
      templatesInAppFolder: 'Πρότυπα στον κατάλογο εφαρμογών:',
      invalidImport: 'Το επιλεγμένο αρχείο δεν είναι έγκυρο πρότυπο φόρμας.',
      exported: 'Το πρότυπο φόρμας έχει εξαχθεί',
      offline: 'Δημιουργήστε μια σύνδεση στο διαδίκτυο για να επεξεργαστείτε πρότυπα φόρμας.',
      checklistContainsCreateTask: 'Το πρότυπο φόρμας περιέχει το στοιχείο "Δημιουργία εισιτηρίου" και επομένως δεν μπορεί να οριστεί σε δημόσιο. Καταργήστε το στοιχείο "Δημιουργία εισιτηρίου".'
    },
    category: {
      category: 'κατηγορία',
      categories: 'Κατηγορίες',
      saved: 'Η κατηγορία έχει αποθηκευτεί.',
      editTitle: 'Επεξεργασία κατηγορίας',
      content: 'στοιχεία',
      newCategory: 'Νέα κατηγορία',
      name: 'Ονομα κατηγορίας',
      edit: 'Επεξεργασία κατηγορίας',
      duplicated: 'Η κατηγορία ήταν διπλότυπη.',
      noCategories: 'Δεν υπάρχουν ακόμη κατηγορίες. Δημιουργήστε νέες κατηγορίες χρησιμοποιώντας το κουμπί «Προσθήκη κατηγορίας».',
      inactive: 'αδρανής',
      showInactive: 'Εμφάνιση ανενεργών',
      duplicateCategoryConfirmHeader: 'Διπλότυπη κατηγορία',
      duplicateCategoryConfirm: 'Θέλετε να αντιγράψετε την κατηγορία "{{categoryName}}" με όλο το περιεχόμενό της;',
      delete: 'Διαγραφή κατηγορίας',
      deleteCategoryConfirm: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε την κατηγορία "{{categoryName}}";',
      deleteSuccess: 'Η κατηγορία έχει αφαιρεθεί.'
    },
    group: {
      group: 'Σελίδα',
      groups: 'σελίδες',
      created: 'Η σελίδα έχει δημιουργηθεί.',
      updated: 'Οι αλλαγές στη σελίδα έχουν αποθηκευτεί.',
      updateError: 'Δεν ήταν δυνατή η αποθήκευση της σελίδας. Παρακαλώ δοκιμάστε ξανά αργότερα.',
      rename: 'Μετονομασία σελίδας',
      saved: 'Η σελίδα έχει αποθηκευτεί.',
      toggleSort: 'Αλλαγή ταξινόμησης',
      editGroupName: 'Επεξεργασία ονόματος σελίδας',
      new: 'ΝΕΑ ΣΕΛΙΔΑ',
      name: 'Όνομα της σελίδας',
      duplicated: 'Η σελίδα ήταν διπλότυπη.',
      noGroups: 'Δεν υπάρχουν ακόμα σελίδες. Δημιουργήστε νέες σελίδες χρησιμοποιώντας το κουμπί "Προσθήκη σελίδας".',
      noItems: 'Δεν υπάρχουν ακόμη στοιχεία. Δημιουργήστε νέα στοιχεία χρησιμοποιώντας το κουμπί "Προσθήκη στοιχείου".',
      add: 'Προσθήκη σελίδας',
      infoElement: 'Στοιχείο πληροφοριών',
      inputElement: 'Στοιχείο εισόδου',
      addInfoElement: 'Προσθήκη στοιχείου πληροφοριών',
      addInputElement: 'Προσθήκη στοιχείου εισαγωγής',
      delete: 'Διαγραφή σελίδας',
      deleteGroupConfirm: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τη σελίδα "{{groupName}}";',
      chooseGroup: 'Σε ποια σελίδα πρέπει να προστεθεί το στοιχείο;',
      duplicateGroupConfirmHeader: 'Διπλότυπη σελίδα',
      duplicateGroupConfirm: 'Θέλετε να αντιγράψετε τη σελίδα "{{groupName}}" με όλο το περιεχόμενο;',
      deleteSuccess: 'Η σελίδα έχει αφαιρεθεί.'
    },
    item: {
      add: 'Προσθήκη στοιχείου',
      created: 'Το στοιχείο δημιουργήθηκε',
      saved: 'Το στοιχείο έχει αποθηκευτεί.',
      chooseItemType: 'Επιλέξτε αντικείμενο',
      values: 'Αξίες',
      value: 'αξία',
      ratingNumber: 'Εκτίμηση',
      addItemValue: 'Προσθέστε νέα αξία',
      addItemValueSuccess: 'Προστέθηκε νέα αξία',
      updateItemValueSuccess: 'Η τιμή έχει ενημερωθεί',
      removeItemValueSuccess: 'Η τιμή έχει αφαιρεθεί',
      removeItemValue: 'Κατάργηση τιμής',
      takePictureButtonText: 'Επιλέξτε εικόνα',
      editTitle: 'Επεξεργασία στοιχείου',
      editDependenciesTitle: 'Επεξεργασία εξαρτήσεων',
      required: 'Υποχρεωτικό πεδίο',
      name: 'Όνομα του στοιχείου',
      description: 'προαιρετική περιγραφή του στοιχείου',
      points: 'Πόντοι',
      duplicated: 'Το στοιχείο ήταν διπλότυπο.',
      duplicateItemConfirmHeader: 'Διπλότυπο στοιχείο',
      duplicateItemConfirm: 'Θέλετε να αντιγράψετε το στοιχείο "{{itemName}}" με όλο το περιεχόμενό του;',
      delete: {
        title: 'Διαγραφή στοιχείου',
        message: 'Θέλετε πραγματικά να διαγράψετε το στοιχείο "{{itemName}}";',
        success: 'Το στοιχείο έχει αφαιρεθεί'
      },
      moveItem: 'Μετακίνηση στοιχείου',
      move: 'Κίνηση',
      moveItemInfo: 'Επιλέξτε την κατηγορία και τη σελίδα στην οποία πρέπει να μετακινηθεί το τρέχον στοιχείο.',
      itemMoved: 'Το στοιχείο έχει μετακινηθεί'
    },
    editItem: {
      name: 'Επώνυμο',
      nameInfo: 'Αυτό το κείμενο εμφανίζεται ακριβώς πριν από το στοιχείο εισαγωγής.',
      link: 'Διεύθυνση συνδέσμου',
      linkInfo: 'Αυτή η διεύθυνση θα είναι προσβάσιμη μέσω ενός κουμπιού. Εισαγάγετε μια έγκυρη διεύθυνση URL.',
      description: 'Περιγραφή',
      descriptionInfo: 'Εάν θέλετε να δώσετε οδηγίες για τη συμπλήρωση της φόρμας, μπορείτε να τις εισάγετε εδώ.',
      linkDescription: 'χαρακτηρισμός κουμπιού',
      linkDescriptionInfo: 'Εισαγάγετε ένα όνομα για το κουμπί.',
      values: 'Αξίες',
      valuesInfo: 'Μπορείτε να καθορίσετε έναν αριθμό βαθμολογίας για κάθε τιμή. Μπορείτε να πραγματοποιήσετε αξιολογήσεις αργότερα χρησιμοποιώντας τον αριθμό αξιολόγησης. Ο αριθμός βαθμολογίας είναι προαιρετικός.',
      value: 'αξία',
      valuePlaceholder: 'Νέα αξία',
      ratingNumber: 'Εκτίμηση',
      requiredField: 'Υποχρεωτικό πεδίο',
      requiredFieldInfo: 'Εάν ενεργοποιηθεί ως υποχρεωτικό πεδίο, τότε αυτό το πεδίο πρέπει να συμπληρωθεί για να μπορέσει να υποβληθεί η φόρμα.',
      externalNo: 'Εξωτερικός αριθμός',
      externalNoInfo: 'Προαιρετικές πληροφορίες για διεπαφές ή αξιολογήσεις μέσω εξωτερικών συστημάτων.',
      editable: 'Επεξεργάσιμο',
      editableInfo: 'Για να μπορούν οι χρήστες να κάνουν καταχωρήσεις, το στοιχείο πρέπει να είναι επεξεργάσιμο.',
      defaultValue: 'Καθορίστε την προεπιλεγμένη τιμή',
      defaultValueInfo: 'Μπορείτε να καθορίσετε μια τυπική ανάθεση για αυτό το στοιχείο.',
      defaultValueInfoEditable: 'Η τιμή μπορεί να αλλάξει από τον χρήστη της φόρμας.',
      visible: 'ορατότητα',
      visibleInfo: 'Η ορατότητα ελέγχει εάν το στοιχείο είναι ορατό από τον επεξεργαστή ή όχι.',
      externalNoAleradyUsed: 'Αυτός ο εξωτερικός αριθμός χρησιμοποιείται ήδη.',
      taskTypeId: 'τύπο εισιτηρίου',
      loginUserId: 'χρήστης',
      locationId: 'όνομα αντικειμένου',
      videoId: 'αριθμός βίντεο',
      videoType: 'υπηρεσίες βίντεο',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'vimeo',
      videoInfo: 'Εισαγάγετε το μοναδικό αναγνωριστικό βίντεο εδώ. Μπορείτε να το βρείτε, για παράδειγμα, στη διεύθυνση URL του βίντεο. <br/>Παράδειγμα: https://www.youtube.com/watch?v=XXXXXX ή στο Vimeo https://vimeo.com/XXXXXX. <br/>Σε αυτήν την περίπτωση, το XXXXX θα πρέπει να εισαχθεί στο πεδίο.',
      defaultTaskTitle: 'Προεπιλεγμένος τίτλος του εισιτηρίου',
      defaultTaskDescription: 'Προεπιλεγμένη περιγραφή του εισιτηρίου',
      titleEditable: 'Τίτλος εισιτηρίου με δυνατότητα επεξεργασίας από τον χρήστη',
      descriptionEditable: 'Περιγραφή εισιτηρίου με δυνατότητα επεξεργασίας από τον χρήστη',
      dueDateEditable: 'Ημερομηνία λήξης εισιτηρίου με δυνατότητα επεξεργασίας από τον χρήστη',
      addReportAttachment: 'Επισυνάψτε τη συμπληρωμένη φόρμα στο εισιτήριο ως PDF',
      noTaskTypesFound: 'Δεν βρέθηκαν τύποι εισιτηρίων. Η διαχείριση των τύπων εισιτηρίων γίνεται μέσω του Blink Active.',
      noLoginUsersFound: 'Δεν βρέθηκε κανένας χρήστης. Η διαχείριση των χρηστών μπορεί να γίνει μέσω του Blink Manager.',
      noLocationsFound: 'Δεν βρέθηκε κανένα αντικείμενο. Η διαχείριση των αντικειμένων μπορεί να γίνει μέσω του Blink Manager.',
      createTaskItemInfo: 'Με αυτή τη λειτουργία μπορείτε να δημιουργήσετε Blink Active Tickets. Μπορείτε να καθορίσετε τον τίτλο και την περιγραφή ή να ζητήσετε από τον χρήστη να τα συμπληρώσει. Το αντικείμενο εκχωρείται αυτόματα και ο υπεύθυνος διαχειριστής αντικειμένων εκχωρείται ως ο επεξεργαστής.',
      duplicatedValuesNotAllowed: 'Δεν επιτρέπονται διπλότυπες τιμές.',
      valueLengthExceeded: 'Η τιμή που εισαγάγατε είναι πολύ μεγάλη και θα συντομευτεί.'
    },
    itemTypes: {
      PreviewButton: 'προεπισκόπηση εγγράφου',
      Select: 'Επιλογή από τη λίστα',
      Signature: 'Υπογραφή',
      Email: 'εισαγωγή email',
      SendEmailTo: 'Αποστολή μέσω ηλεκτρονικού ταχυδρομείου',
      Date: 'Ημερομηνία',
      Time: 'χρόνος',
      Boolean: 'Ναι Οχι ΕΡΩΤΗΣΗ',
      TakePicture: 'τραβήξετε τη φωτογραφία',
      TakeMultiPicture: 'βγάζω φωτογραφίες',
      Scan: 'Σάρωση QR / EAN',
      Input: 'εισαγωγή κειμένου',
      Textarea: 'Εισαγωγή κειμένου σε πολλές γραμμές',
      Link: 'σύνδεση',
      Picture: 'προβολή εικόνας',
      Video: 'προβολή βίντεο',
      Text: 'εμφάνιση κειμένου',
      Header: 'επικεφαλίδα',
      Rate: 'Εκτίμηση',
      RateSmiley: 'smiley βαθμολογία',
      CreateTask: 'Δημιουργία εισιτηρίου',
      FileUpload: 'ανεβάσετε αρχεία',
      Currency: 'νόμισμα'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Δείτε μια έκδοση προεπισκόπησης της φόρμας.',
      Select: 'Καθορίστε τυχόν στοιχεία λίστας από τα οποία μπορεί να επιλεγεί.',
      Signature: 'Πεδίο για ψηφιακή υπογραφή (μπορεί να χρησιμοποιηθεί με το δάχτυλο)',
      Email: 'Ο χρήστης μπορεί να παρέχει μια έγκυρη διεύθυνση email.',
      SendEmailTo: 'Ο χρήστης μπορεί να παρέχει μια έγκυρη διεύθυνση email στην οποία θα σταλεί η συμπληρωμένη φόρμα ως PDF.',
      Date: 'Ο χρήστης μπορεί να καθορίσει μια ημερομηνία μέσω ενός ημερολογίου',
      Time: 'Ο χρήστης μπορεί να καθορίσει μια ώρα',
      Boolean: 'Ο χρήστης επιλέγει ναι ή όχι',
      TakePicture: 'Ο χρήστης μπορεί να τραβήξει μια φωτογραφία με την κάμερα ή να ανεβάσει μια εικόνα.',
      TakeMultiPicture: 'Ο χρήστης μπορεί να τραβήξει πολλές φωτογραφίες με την κάμερα ή να ανεβάσει εικόνες.',
      Scan: 'Απαιτείται από τους χρήστες να σαρώσουν έναν κωδικό QR ή EAN. Το αποτέλεσμα εμφανίζεται στη φόρμα και αποθηκεύεται.',
      Input: 'Πεδίο εισαγωγής κειμένου μονής γραμμής',
      Textarea: 'Εισαγωγή κειμένου σε πολλές γραμμές για μεγαλύτερα σχόλια',
      Link: 'Σύνδεσμος σε εξωτερικό ιστότοπο',
      Picture: 'Ανεβάστε μια εικόνα που θα εμφανίζεται κατά τη συμπλήρωση.',
      Video: 'Ανεβάστε ένα βίντεο που θα εμφανίζεται ενώ συμπληρώνετε τη φόρμα.',
      Text: 'Προσθήκη μορφοποίησης σε κείμενα πολλών γραμμών.',
      Header: 'Μεγάλη επικεφαλίδα για τη δομή του περιεχομένου',
      Rate: 'βαθμολογία 5 αστέρων',
      RateSmiley: 'Επιλογή από 3 χαμογελαστά πρόσωπα (χαρούμενα, ουδέτερα, λυπημένα)',
      CreateTask: 'Ένα εισιτήριο δημιουργείται για έναν χρήστη',
      FileUpload: 'Ο χρήστης μπορεί να επισυνάψει οποιοδήποτε αριθμό αρχείων στη φόρμα.',
      Currency: 'Ο χρήστης μπορεί να καθορίσει ένα ποσό σε ένα νόμισμα.'
    },
    template: {
      template: 'πρότυπο αναφοράς',
      templates: 'πρότυπα αναφοράς',
      name: 'Επώνυμο',
      description: 'Περιγραφή',
      customer: 'πελάτης',
      new: 'Νέο πρότυπο αναφοράς',
      edit: 'Επεξεργασία προτύπου αναφοράς',
      create: 'Δημιουργία προτύπου αναφοράς',
      public: 'Δημόσιο',
      active: 'Ενεργός',
      deleteConfirm: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε το πρότυπο αναφοράς "{{templateName}}";',
      deleteSuccess: 'Το πρότυπο αναφοράς έχει αφαιρεθεί',
      editor: 'Σχεδιαστής προτύπων',
      notForMobile: 'Ο σχεδιαστής προτύπων δεν είναι βελτιστοποιημένος για κινητές συσκευές. Χρησιμοποιήστε το Blink Check στο πρόγραμμα περιήγησης για αυτό.',
      noTemplates: 'Δεν υπάρχουν ακόμη πρότυπα αναφοράς.',
      custom: 'Μεμονωμένο πρότυπο',
      defaultWithPoints: 'Τυπικό πρότυπο - με ποσοστιαίες μονάδες',
      default: 'Τυπικό πρότυπο - χωρίς ποσοστιαίες μονάδες',
      createTemplateInfo: 'Μπορείτε να επεξεργαστείτε το πρότυπο αναφοράς μόνο αφού δημιουργηθεί.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Ομάδα παγκόσμιας άδειας',
    notAllowed: 'Δεν έχετε άδεια να αλλάξετε τα δικαιώματα αυτού του προτύπου.',
    all: 'Η φόρμα μπορεί να συμπληρωθεί από όλους τους χρήστες',
    groups: 'Η φόρμα μπορεί να συμπληρωθεί μόνο από χρήστες από αυτές τις ομάδες εξουσιοδότησης',
    noGroupLicence: 'Η άδεια ελέγχου αναλαμπής για αυτήν την ομάδα δεν είναι ενεργοποιημένη.',
    overridingPermssion: 'Η επιλογή "Ρυθμίσεις συστήματος" στην ομάδα αδειών "Sysadmin" δίνει αυτόματα στους χρήστες όλα τα δικαιώματα σε αυτήν τη φόρμα.',
    onlyLicensedPermissionGroups: 'Εμφανίζονται μόνο οι ομάδες εξουσιοδότησης στις οποίες έχει εκχωρηθεί άδεια ελέγχου αναλαμπής.',
    canUpdateChecklist: 'Επεξεργασία προτύπου φόρμας',
    canFillChecklist: 'συμπληρώστε τη φόρμα',
    canReadChecklistDataOwn: 'Δείτε και αξιολογήστε τις αυτοσυμπληρωμένες φόρμες',
    canReadChecklistDataOwnEmployees: 'Δείτε και αξιολογήστε όλες τις συμπληρωμένες φόρμες από τους υπαλλήλους σας',
    canReadChecklistDataOwnLocations: 'Δείτε και αξιολογήστε όλες τις ολοκληρωμένες φόρμες των δικών σας αντικειμένων',
    canReadChecklistDataAll: 'Δείτε και αξιολογήστε όλες τις συμπληρωμένες φόρμες'
  },
  companyConfig: {
    companyConfig: 'Διαμόρφωση μισθωτή',
    logoInfo: 'Εμφανίζεται στην κεφαλίδα των τυπικών προτύπων αναφορών.',
    footer: 'υποσέλιδο',
    footerInfo: 'Εμφανίζεται στο υποσέλιδο σε τυπικά πρότυπα αναφορών.',
    offline: 'Δημιουργήστε μια σύνδεση στο διαδίκτυο για να μπορέσετε να επεξεργαστείτε τη διαμόρφωση.'
  },
  switchCompanyDialogHeader: 'Αλλάξτε πελάτες',
  switchCompanyDialogText: 'Εάν αλλάξετε τον πελάτη, όλες οι φόρμες που βρίσκονται σε εξέλιξη θα επαναφερθούν. Θέλετε να συνεχίσετε;',
  results: {
    results: 'Εκτίμηση',
    details: 'Λεπτομέριες',
    created: 'Δημιουργήθηκε',
    result: 'Αποτέλεσμα',
    noReportJsonExists: 'Η αναφορά δεν έχει δημιουργηθεί ακόμη. Η αναφορά θα δημιουργηθεί με καθυστέρηση, δοκιμάστε ξανά αργότερα.',
    noResults: 'Δεν υπάρχουν ακόμα συμπληρωμένες φόρμες.',
    noResultsForDateFilter: 'Δεν υπάρχουν συμπληρωμένες φόρμες για την επιλεγμένη περίοδο.',
    periodFrom: 'Περίοδος',
    periodTo: 'περίοδο μέχρι',
    location: 'αντικείμενο',
    createUser: 'Δημιουργός',
    loginUser: 'Υπαλλήλους',
    checklist: 'Πρότυπο φόρμας',
    user: 'χρήστης',
    recentResults: 'Συμπληρωμένα έντυπα',
    recent: 'Ρεύμα',
    pointOf: '{{ itemPoints }} από {{ maxItemPoints }} πόντους',
    checklistCreatedByAt: 'Η φόρμα συμπληρώθηκε από {{ firstName }} {{ lastName }} στις {{ createDate }} στις {{ createTime }} μ.μ.',
    linkedTask: 'Συνδεδεμένο εισιτήριο: #{{ taskId }} {{ taskTitle }}',
    offline: 'Δημιουργήστε μια σύνδεση στο Διαδίκτυο για να μπορείτε να αξιολογήσετε τις συμπληρωμένες φόρμες.',
    delete: {
      title: 'Διαγραφή συμπληρωμένης φόρμας',
      message: 'Θέλετε πραγματικά να διαγράψετε τη συμπληρωμένη φόρμα "{{checklistName}}";',
      success: 'Η συμπληρωμένη φόρμα έχει αφαιρεθεί'
    },
    editReferenceTimestamp: 'Επεξεργασία ημερομηνίας αναφοράς',
    editReferenceTimestampDescription: 'Η ημερομηνία αναφοράς καθορίζει την ώρα συμπλήρωσης του εντύπου. Αυτό μπορεί να διαφέρει από τη στιγμή κατά την οποία υποβλήθηκε το έντυπο.'
  },
  dependencies: {
    dependency: 'Εξάρτηση',
    addTitle: 'Επιλέξτε τιμή σύγκρισης',
    addMessage: 'Δώστε την τιμή που πρέπει να πληρούται για να εμφανιστεί το στοιχείο.',
    addTitleMulti: 'Επιλέξτε τιμές σύγκρισης',
    addMessageMulti: 'Καταχωρίστε τις τιμές, μία από τις οποίες πρέπει να πληρούται για να εμφανιστεί το στοιχείο.',
    present: 'Διαθέσιμος',
    notPresent: 'Μη διαθέσιμο',
    noResults: 'Δεν υπάρχουν εξαρτήσεις. Μπορούν να προστεθούν νέες εξαρτήσεις μέσω Προσθήκη εξάρτησης.',
    noAvailableItems: 'Υπάρχουν πολύ λίγα στοιχεία στη φόρμα για τη δημιουργία εξαρτήσεων. Προσθέστε πρώτα περισσότερα στοιχεία.',
    noAvailableItemsInGroup: 'Υπάρχουν πολύ λίγα στοιχεία στη σελίδα για τη δημιουργία εξαρτήσεων. Επιλέξτε μια σελίδα που περιέχει στοιχεία.',
    dependencyAdded: 'Προστέθηκε η εξάρτηση.',
    dependencyRemoved: 'Η εξάρτηση έχει αφαιρεθεί.',
    dependencyInfo: 'Μόλις προσθέσετε μια εξάρτηση, αυτό το στοιχείο ({{elementName}}) θα εμφανίζεται μόνο εάν τα εξαρτημένα στοιχεία έχουν συμπληρωθεί.',
    true: 'Ναί',
    false: 'Οχι',
    '#not_empty#': 'Διαθέσιμος',
    '#empty#': 'Μη διαθέσιμο',
    frown: 'Λυπημένος',
    meh: 'Πάει εντάξει',
    smile: 'Ευτυχισμένος'
  },
  noChecklists: 'Δεν υπάρχουν διαθέσιμα έντυπα.',
  updating: 'Το Blink Check ενημερώνεται.',
  installing: 'Εγκατάσταση έκδοσης {{version}}...',
  appName: 'Έλεγχος αναβοσβήνει',
  notAvailableInPublicChecklists: ' (Δεν διατίθεται σε δημόσια πρότυπα φόρμας)',
  pdfAssignTemplate: 'Πρέπει να αντιστοιχίσετε ένα πρότυπο αναφοράς στη φόρμα για να μπορέσετε να χρησιμοποιήσετε τη λήψη PDF.'
};
