import { Component, inject } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';
import { List } from 'immutable';

@Component({
  selector: 'ui-select-profile-popover',
  templateUrl: './select-profile-popover.component.html'
})
export class SelectProfilePopoverComponent {
  loginProfiles: List<any>;
  activeProfile: any;

  private popoverController = inject(PopoverController);
  private menuController = inject(MenuController);

  selectLoginProfile(loginProfile: any) {
    this.menuController.close('main-menu');
    this.popoverController.dismiss(loginProfile, 'loginProfileSelected');
  }
}
