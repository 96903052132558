<ui-page [title]="t.REGISTRATION.withBlinkId"
         backHref="login"
         [legacyWrapper]="true">

  <div *ngIf="currentView === 'selectMethod'">
    <p class="mt-0">{{ t.scan.text | translate }}</p>
    <div class="ion-text-center">
      <img src="assets/imgs/scan_code_1.png"
           class="scan-code-image mb-15"/>
      <ion-button expand="block"
                  (click)="startScanner()">
        {{ t.scan.scan_now | translate }}
      </ion-button>
      <p class="line-around">{{ t.or | translate }}</p>
      <ion-button expand="block"
                  fill="outline"
                  (click)="currentView = 'enterManually'">
        <i slot="start" class="far fa-keyboard mr-5"></i>
        {{ t.scan.enter_manually | translate }}
      </ion-button>
    </div>

  </div>


  <div *ngIf="currentView === 'enterManually' && form">
    <p class="text-formatted mt-0">{{ t.REGISTER_MANUALLY_PAGE.TEXT | translate }}</p>
    <img src="assets/imgs/scan_code_2.png"
         class="scan-code-image mb-15"/>

    <form [formGroup]="form"
          (ngSubmit)="tryLogin()">
      <ui-input formControlName="blinkId"
                label="1 - Blink ID"
                placeholder="123-123-12">
        <small *ngIf="!blinkIdEmpty && form.get('blinkId').invalid">
          {{ t.REGISTER_MANUALLY_PAGE.BLINK_ID_INVALID | translate }}
        </small>
      </ui-input>

      <ui-input formControlName="code"
                label="2 - Code"
                placeholder="andavis-1234-1234-1234-1234">
        <small *ngIf="!blinkIdEmpty && form.get('blinkId').invalid">
          {{ t.REGISTER_MANUALLY_PAGE.BLINK_ID_INVALID | translate }}
        </small>
      </ui-input>

    </form>
    <ion-button expand="block"
                [disabled]="!form.valid || disabled"
                (click)="login()">
      {{ t.REGISTRATION.loginNow | translate }}
    </ion-button>
  </div>
</ui-page>
