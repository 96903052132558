<ui-modal-header [title]="title"></ui-modal-header>

<ion-content>
  <div class="slide-center">
    <img [alt]="this.alt" [src]="this.src">
  </div>
</ion-content>

<ion-footer [ngClass]="{ 'no-text': text.length <= 0 }">
  <ion-toolbar class="ion-text-center">
    <ion-buttons slot="primary">
      <ion-button *ngIf="deletable" (click)="deleteImage()">
        <i class="fal fa-trash-alt mr-5"></i>
        {{ t.global.delete | translate }}
      </ion-button>
    </ion-buttons>
    <ion-text>{{ text }}</ion-text>
  </ion-toolbar>
</ion-footer>
