<ui-page [title]="t.companyRequired" [contentPadding]="false">
  <p class="ion-padding-horizontal">{{ t.companyRequiredInfo | translate }}</p>

  <ng-container *ngIf="$companies | async as companies; else spinner">
    <ion-list>
      <ion-item *ngFor="let company of companies">
        <ion-label>{{ company.Name }}</ion-label>
        <ion-button slot="end" fill="outline" (click)="selectCompany(company)">
          {{ t.select | translate }}
        </ion-button>
      </ion-item>
    </ion-list>
  </ng-container>

  <ng-template #spinner>
    <div class="spinner-container">
      <ion-spinner color="primary"></ion-spinner>
    </div>
  </ng-template>
</ui-page>
