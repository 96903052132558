export default {
  cancel: 'Annuler',
  blinkSlogan: "Blink - L'application pour les nettoyeurs de bâtiments",
  all: 'Tous les',
  hello: 'Bonjour',
  description: 'Description',
  ok: 'Ok',
  reset: 'Réinitialiser',
  edit: 'Modifier',
  finish: 'Clôturer',
  notice: 'Remarque',
  content: 'Contenu',
  back: 'Retour',
  close: 'Fermer',
  backToHome: "Retour à la page d'accueil",
  proceed: 'Continuer',
  done: 'Prêt',
  delete: 'Supprimer',
  successful: 'Réussir',
  error: 'Erreur',
  ERROR_OCCURRED: 'Une erreur est survenue ou les données ne sont pas correctes. Veuillez réessayer.',
  internetRequired: 'Internet nécessaire',
  configuration: 'Configuration',
  logo: 'Logo',
  uploadLogo: 'Télécharger le logo',
  deleteLogo: 'Supprimer le logo',
  duplicate: 'Dupliquer',
  importExport: 'Importation / exportation',
  import: 'Importation',
  doImport: 'Importer',
  export: 'Exportation',
  doExport: 'Exporter',
  excelExport: 'Exportation Excel',
  excelExportDownloaded: 'Exportation Excel téléchargée',
  excelExportError: "Échec de l'exportation Excel",
  pdfDownload: 'Télécharger le PDF',
  pdfDownloaded: 'PDF téléchargé',
  configSaved: 'La configuration a été enregistrée.',
  send: 'Envoyer',
  connecting: 'Connecter...',
  or: 'ou',
  select: 'Sélectionner',
  today: "Aujourd'hui",
  camera: 'Caméra',
  gallery: 'Galerie',
  files: 'Fichiers',
  upload: 'Télécharger des fichiers',
  chooseFiles: 'Sélectionner des fichiers',
  filesTransferring: 'Transmettre des fichiers...',
  filesTransferringWait: 'Veuillez patienter pendant la transmission des fichiers.',
  filesTransferred: 'Fichiers transmis avec succès.',
  download: 'Téléchargement',
  showInactive: 'Afficher les inactifs',
  copyLink: 'Copier le lien',
  linkCopied: 'Le lien a été copié dans le presse-papiers.',
  permissions: 'Autorisations',
  email: 'Courrier électronique',
  emailConfig: "Configuration de l'e-mail",
  title: 'Titre',
  user: 'Utilisateur',
  logout: 'Se désinscrire',
  system: 'Système',
  appVersion: 'Version (App)',
  apiVersionCore: 'Version\n(API de base)',
  apiVersionCheck: 'Version\n(API de vérification)',
  deviceInfo: "Informations sur l'appareil",
  installBlinkTime: 'Installer Blink Time',
  installBlinkMe: 'Installer Blink Me',
  installing: 'La version {{version}} est installée ...',
  companyRequired: 'Sélectionner un mandant',
  companyRequiredInfo: 'Vous devez choisir un mandant pour pouvoir continuer en tant que sysadmin.',
  pleaseChoose: 'Veuillez choisir',
  pleaseFill: 'Veuillez remplir',
  noEntitiesFound: "Aucun élément n'a pu être trouvé.",
  addCustom: 'Créer un élément :',
  typeToSearchText: 'Veuillez saisir deux caractères ou plus...',
  loading: 'Chargement des données...',
  offline: 'Hors ligne',
  youAreOffline: 'Vous êtes hors ligne !',
  by: 'de',
  date: 'Date',
  time: 'Heure',
  searchTitle: 'Rechercher',
  searchTerm: 'Recherche de mots-clés',
  searchLoginUser: 'Rechercher des collaborateurs',
  searchLoginUserPlaceholder: 'Recherche par nom ou prénom',
  location: 'Objet',
  searchLocation: 'Chercher un objet',
  searchLocationPlaceholder: "Recherche par nom d'objet, numéro ou tags",
  deactivate: 'Désactiver',
  active: 'Actif',
  inactive: 'Inactif',
  tag: 'Jour',
  tags: 'Tags',
  color: 'Couleur',
  icon: 'Icône',
  language: 'Langue',
  general: 'Généralités',
  yes: 'Oui',
  no: 'Non',
  searchThrough: 'Parcourir',
  name: 'Nom',
  create: 'Créer',
  save: 'Enregistrer',
  home: 'Accueil',
  profile: 'Profil',
  templates: 'Modèles',
  noAssignment: 'non attribué',
  sort: {
    change: "Modifier l'ordre",
    save: "Enregistrer l'ordre",
    saved: "L'ordre a été enregistré."
  },
  signature: {
    signature: 'Signature',
    pleaseSign: 'Veuillez signer',
    clickHereToSign: 'Cliquez ici pour signer',
    addSignature: 'Ajouter une signature',
    save: 'Reprendre',
    delete: 'Supprimer'
  },
  formError: {
    min: 'La valeur doit être supérieure à {{value}}.',
    min2: 'La valeur doit être supérieure ou égale à {{value}}.',
    max: 'La valeur doit être inférieure ou égale à {{value}}.',
    requiredBecause: 'Valeur requise parce que {{name}} est rempli.',
    isRequired: '{{name}} est obligatoire.',
    numberInUse: 'Le matricule est déjà utilisé.',
    maxlength: '{{name}} ne doit pas contenir plus de {{value}}. de caractères.',
    email: 'Veuillez indiquer une adresse e-mail valide.',
    faultyBlinkId: 'ID de clignotement erroné'
  },
  scan: {
    choose: 'Attribuer un nouvel ID de clignotement',
    text: "Scannez le code QR de la carte d'inscription.",
    scan_now: 'Scanner maintenant',
    enter_manually: 'Saisir manuellement'
  },
  removeImage: "Supprimer l'image",
  tickets: { assignee: 'Responsable du traitement', dueTo: 'Échéance au' },
  ngSelect: {
    typeToSearch: 'Veuillez saisir 3 lettres ou plus.',
    loading: 'Chargement des données...',
    notFound: 'Aucun {{title}} trouvé.',
    clearAll: 'Réinitialisation'
  },
  help: {
    pageTitle: 'Aide',
    text1: "Notre équipe d'assistance se tient à votre disposition pour répondre à vos questions :",
    phone: 'Téléphone',
    mail: 'Courrier électronique',
    information: 'Info',
    documentation: 'Documentation en ligne',
    documentationText: 'Dans notre documentation en ligne, vous trouverez des instructions et des vidéos pour tous les domaines importants de Blink.',
    support: 'Support'
  },
  REGISTRATION: {
    WELCOME: 'Bienvenue !',
    chooseMethod: 'Avec quelle méthode souhaitez-vous vous inscrire ?',
    withBlinkId: "Carte d'identité BLINK",
    withCredentials: "Nom d'utilisateur / mot de passe",
    REGISTER: "S'inscrire",
    ALREADY_REGISTERED: "J'ai un compte Blink.",
    INVALID_CARD: 'Carte non valable.',
    noBlinkId: "Le code QR scanné n'est pas un Blink-ID valide",
    scanBlinkId: "Scanner l'ID clignotant",
    ERROR: "L'enregistrement n'a pas pu être terminé. Veuillez saisir une autre adresse e-mail ou un autre numéro de téléphone portable.",
    NO_INTERNET_EXPLORER: "Malheureusement, Internet Explorer n'est pas pris en charge par Blink. Veuillez utiliser Microsoft Edge, Firefox ou Chrome. Merci de votre compréhension.",
    loginNow: "S'inscrire maintenant",
    help: "Aide à l'inscription",
    helpText: `L'application Blink Manager vous permet de gérer les temps, les collaborateurs et la configuration du système. Blink Manager est uniquement destiné aux superviseurs, aux responsables d'objets, à la gestion et à l'administration ! Pour la simple saisie des temps, utilisez "Blink Time App"!<br/><br/>Il existe deux méthodes pour vous inscrire:<br/><br/><b>Inscription avec la carte Blink ID</b><br/><br/>Si vous avez une carte Blink ID, choisissez cette méthode. Lors de votre première inscription, nous vous demanderons une adresse e-mail ou un numéro de téléphone portable. Nous en avons besoin pour vous envoyer un mot de passe à usage unique pour l'inscription. Vous n'avez pas besoin de vous souvenir de ce mot de passe à usage unique. Si vous vous inscrivez à nouveau, nous vous enverrons un nouveau mot de passe à usage unique.<br/><br/><b>Inscription avec nom d'utilisateur et mot de passe</b><br/><br/>Vous ne recevrez votre nom d'utilisateur et votre mot de passe que sur demande de votre administrateur système. Cette méthode n'est disponible que pour les utilisateurs disposant de droits administratifs.<br/><br/><b>Support</b><br/><br/>Si vous rencontrez des problèmes lors de votre inscription, écrivez un e-mail à support@blink-time.de ou appelez le 0911 240 330 0.`,
    userOffline: 'Veuillez établir une connexion Internet pour pouvoir utiliser le Blink me.',
    login: "S'inscrire maintenant",
    codeError: 'Veuillez vérifier votre saisie et réessayer.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Félicitations !',
    YOU_DID_IT: 'Vous avez réussi.',
    SIGNED_IN: 'Connecté',
    SIGNED_IN_TEXT: "Vous vous êtes inscrit avec succès et vous pouvez maintenant utiliser l'application.",
    START_USAGE: "Vous pouvez maintenant utiliser l'application.",
    START: 'Lancement'
  },
  FORGOT_PASSWORD: {
    emailSent: "Un e-mail vous permettant de modifier vos données d'accès vous a été envoyé.",
    FORGOT_PASSWORD: 'Mot de passe oublié ?',
    errors: {
      notAllowed: 'Aucun mot de passe ne peut être réinitialisé pour cet utilisateur.',
      invalidToken: 'Le code de réinitialisation de votre mot de passe a expiré. Veuillez demander un nouveau code.'
    },
    reset: {
      backToRoot: 'Retour à la page de connexion',
      renewCode: 'Demander un nouveau code',
      resetPassword: 'Réinitialiser le mot de passe',
      success: 'Votre mot de passe a été réinitialisé avec succès.',
      password1: 'Votre nouveau mot de passe',
      password2: 'Répéter le mot de passe',
      validationError: 'Le mot de passe ne correspond pas à notre politique de mot de passe !',
      passwordsNotEqual: 'Les mots de passe ne sont pas identiques ! Veuillez vérifier votre saisie.',
      passwordValidation: 'Le mot de passe doit comporter au moins 6 caractères et contenir une lettre, un chiffre et un caractère spécial.'
    },
    FORGOT_PASSWORD_TEXT: 'Veuillez saisir votre adresse e-mail et le système cible pour réinitialiser votre mot de passe.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Vérifier les données',
    TEXT: 'Si nous devons réinitialiser votre mot de passe, nous avons besoin de votre adresse e-mail et, en option, de votre numéro de téléphone portable.',
    EMAIL: 'Adresse électronique',
    MOBILE: 'Numéro de portable'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Saisir le code',
    EMAIL_TEXT: "Saisissez le code que nous avons envoyé à l'adresse e-mail suivante :",
    SMS_TEXT: 'Saisissez le code que nous avons envoyé au numéro de portable suivant :',
    NO_CODE: "Je n'ai pas reçu de code.",
    CODE_LENGTH_ERROR: 'Le code saisi est plus long que six caractères. Veuillez saisir à nouveau votre code.',
    CODE_SENT: 'Le code a été envoyé.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Pas de code',
    TEXT1: "Vous n'avez pas reçu de code ?",
    RESEND_CODE: 'Envoyer à nouveau le code',
    TEXT2: "Si l'adresse e-mail ou le numéro de téléphone portable que vous avez enregistré dans le système a changé, veuillez contacter votre administrateur."
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: "Veuillez saisir votre Blink ID et votre code. Vous trouverez toutes les informations sur votre carte d'enregistrement.",
    textCredentials: "Le nom d'utilisateur et le mot de passe doivent vous être fournis par votre administrateur système.",
    BLINK_ID_INVALID: 'ID de clignotement erroné',
    loginCardNotFound: "L'identifiant Blink est déjà utilisé ou n'existe pas.",
    WRONG_CREDENTIALS: 'Les données saisies ne sont pas correctes. Veuillez vérifier votre saisie.',
    loginViaBlinkId: 'Inscription avec BlinkID',
    loginViaCredentials: "Inscription avec données d'accès",
    CODE: 'Code',
    codePlaceholder: "Votre code d'accès personnel",
    company: 'Système',
    password: 'Mot de passe',
    FORGOT_PASSWORD: "J'ai oublié mon mot de passe",
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Identification',
    TEXT_1: 'Pour que nous puissions vous identifier clairement, nous vous envoyons un code à usage unique par SMS ou par e-mail.',
    TEXT_2: 'Pour ce faire, veuillez indiquer votre numéro de téléphone portable ou votre adresse e-mail.'
  },
  update: {
    update: 'Actualiser',
    latestVersion: 'Une nouvelle version est disponible. Veuillez mettre à jour votre application pour profiter de tous les avantages.',
    warnVersion: 'Vous utilisez une version obsolète de cette application. Veuillez effectuer une mise à jour en temps voulu.',
    blockVersion: "Vous utilisez une version obsolète de cette application qui n'est plus supportée. Veuillez maintenant effectuer une mise à jour."
  },
  inProgress: 'En cours',
  video_offline: 'Les vidéos ne peuvent pas être visionnées en mode hors ligne, veuillez vous connecter à Internet.',
  location_offline: "L'objet n'est pas enregistré hors ligne, veuillez vous connecter à Internet pour récupérer le code QR.",
  invalidObjectCode: 'Aucun code objet valide.',
  invalidValue: 'valeur invalide',
  ERROR: {
    ERROR_400: "Une erreur est survenue. Veuillez réessayer plus tard. (Code d'erreur : 400)",
    ERROR_403: "Vous n'êtes pas autorisé à effectuer cette action",
    REFRESH_TOKEN_403: "Vos données d'accès ne sont plus valables. Veuillez vous reconnecter !",
    ERROR_500: "Une erreur est survenue. Veuillez réessayer plus tard. (Code d'erreur : 500)"
  },
  list: {
    entityPluralDefault: 'Résultats',
    emptyState: "Aucun {{entityPlural}} n'existe.",
    noResults: "Aucun {{entityPlural}} n'a pu être trouvé.",
    selectAll: 'Sélectionner tout {{entityPlural}}',
    massAction: 'Promotion pour {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Sélectionner tout {{count}}',
    massActionDeSelectAllGlobal: 'Désélectionner tout {{count}}'
  },
  languages: {
    ar: 'arabe',
    de: 'Allemand',
    es: 'Espagnol',
    fr: 'Français',
    hr: 'croate',
    it: 'italien',
    pt: 'Portugais',
    ru: 'russe',
    bg: 'bulgare',
    en: 'Anglais',
    fa: 'persan',
    el: 'grec',
    hu: 'hongrois',
    pl: 'polonais',
    ro: 'roumain',
    tr: 'turc',
    uk: 'ukrainien',
    cs: 'tchèque',
    sk: 'slovaque'
  },
  now: 'Maintenant',
  allCompanies: 'Tous les clients',
  createDate: 'Date de création',
  company: 'client',
  new: 'Nouveau',
  of: 'depuis',
  request: {
    deleteTitle: 'Supprimer {{item}}',
    confirmDelete: "Voulez-vous vraiment supprimer l'entrée « {{itemName}} » ?",
    itemAdded: '{{item}} a été créé.',
    itemAddedError: "{{item}} n'a pas pu être créé.",
    itemSaved: '{{item}} a été enregistré.',
    itemSavedError: "{{item}} n'a pas pu être enregistré.",
    itemRemoved: '{{item}} a été supprimé.',
    itemRemovedError: "{{item}} n'a pas pu être supprimé."
  },
  news: { pageTitle: 'Nouvelles', edit: 'Modifier les reportages' },
  profiles: {
    loginProfiles: 'Profils de connexion',
    add: 'Profil de connexion',
    logout: 'Si vous vous déconnectez, toutes les données et profils locaux seront supprimés ! Voulez-vous vous désabonner?',
    login: 'Registre',
    deleteConfirmation: 'Voulez-vous vraiment supprimer le profil ?',
    changed: 'Profil modifié avec succès',
    deleted: 'Profil supprimé avec succès'
  },
  deleteAttachment: {
    confirmTitle: 'Supprimer la pièce jointe',
    confirmText: 'Êtes-vous sûr de vouloir supprimer cette pièce jointe ?'
  },
  comments: {
    addComment: 'ajouter un commentaire',
    addCommentInfo: 'Le commentaire est visible par toute personne pouvant consulter ce ticket.',
    comment: 'commentaire',
    deleteComment: 'Supprimer le commentaire',
    deleteCommentConfirmation: 'Voulez-vous vraiment supprimer le commentaire ?'
  }
};
