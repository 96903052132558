import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

type TextAreaProps = FormlyFieldProps

export interface FormlyTextAreaFieldConfig extends FormlyFieldConfig<TextAreaProps> {
  type: 'textarea' | Type<UiFormlyFieldTextAreaComponent>;
}

@Component({
  selector: 'ui-formly-field-ion-textarea',
  styles: [':host {margin: 0}'],
  template: `
    <ion-textarea [formControl]="formControl" [ionFormlyAttributes]="field" [label]="props.label"
                  labelPlacement="stacked"></ion-textarea>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFormlyFieldTextAreaComponent extends FieldType<FieldTypeConfig<TextAreaProps>> {

  constructor(private elementRef: ElementRef) {
    super();
  }
}
