export default {
  home: {
    pageTitle: 'فرم های من',
    locationChecklists_label: 'یک فرم برای یک شی پر کنید',
    generalChecklists_label: 'فرم کلی را پر کنید',
    processingChecklists_label: 'فرم در حال انجام است',
    checklistDatas_label: 'فرم های تکمیل شده',
    selectLocation: 'شی را انتخاب کنید',
    scanQrCode: 'اسکن کد شی',
    showAll: '... نمایش همه',
    showLess: '... کمتر نشان می دهد'
  },
  fill: {
    checklists: 'تشکیل می دهد',
    fillChecklist: 'فرم را پر کنید',
    checklistNotFound: 'متأسفانه فرم درخواستی شما یافت نشد.',
    checklistOfflineLoginUserRequired: 'این فرم مستلزم انتخاب کارمند است. لطفاً برای دسترسی به قالب فرم یک اتصال اینترنتی برقرار کنید.',
    checklistNotOfflineAvailable: 'قالب فرم به صورت آفلاین ذخیره نمی شود، لطفاً برای دسترسی به قالب فرم به اینترنت متصل شوید.',
    resetConfirm: 'آیا واقعاً می خواهید فرم را بازنشانی کنید؟',
    categoryAppeared: 'دسته "{0}}" اضافه شده است.',
    groupAppeared: 'صفحه "{0}}" اضافه شده است.',
    itemAppearedInGroup: 'سوالات جدید به صفحه "{0}}" اضافه شده است.',
    openGroupsAvailable: 'OpenGroupsAvailable',
    toNextGroup: 'بریم سراغ سوال بعدی',
    containerUriExpired: 'با عرض پوزش، زمان آپلود به پایان رسیده است.',
    finishMessage: 'شما تمام داده های لازم را وارد کرده اید و اکنون می توانید فرم را تکمیل کنید.',
    offlineMessage: 'لطفا برای تکمیل فرم به اینترنت متصل شوید.',
    offlinePreviewMessage: 'لطفاً برای ایجاد پیش نمایش به اینترنت متصل شوید.',
    checklistUpdatedHeader: 'قالب فرم تغییر کرد',
    checklistUpdatedMessage: 'قالب فرم تغییر کرده است. لطفاً قبل از ارسال فرم مجدداً ورودی های خود را بررسی کنید.',
    checklistLocationRequiredHeader: 'شی را انتخاب کنید',
    checklistLocationRequiredMessage: 'برای تکمیل این فرم باید یک شی را انتخاب کنید.',
    finishSuccess: 'فرم با موفقیت تکمیل شد و تمام داده ها پردازش شدند.',
    finishError: 'فرم به دلیل خطا تکمیل نشد. لطفا با پشتیبانی تماس بگیرید.',
    createdWith: 'این فرم با ایجاد شده است',
    preview: 'پیش نمایش',
    fileUploadFileMissingHeader: 'فایل ها پیدا نشد',
    fileUploadFileMissingMessage: 'فایل‌های زیر انتخاب شده‌اند اما در دستگاه یافت نمی‌شوند: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'آره', no: 'خیر' },
    scan: 'اسکن کنید',
    email: { invalidEmail: 'لطفا ورودی خود را بررسی کنید.' },
    takePicture: { picture: 'تصویر', buttonText: 'یک تصویر اضافه کنید' },
    createTask: {
      taskTitle: 'عنوان بلیط',
      taskDescription: 'توضیحات بلیط',
      taskDueDate: 'تاریخ سررسید بلیط'
    }
  },
  noChecklistsForLocationFound: 'هیچ فرمی برای این شی وجود ندارد. لطفا ملک دیگری را انتخاب کنید یا یک فرم کلی را پر کنید.',
  selectChecklistByLocation: 'فرم را برای شی انتخاب کنید'
};
