import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilModule } from '@blink/util';
import { TranslateModule } from '@ngx-translate/core';
import { UiIconModule } from '../ui-icon/ui-icon.module';
import { UiInputDateComponent } from './ui-input-date/ui-input-date.component';
import { UiInputSelectIconComponent } from './ui-input-select-icon/ui-input-select-icon.component';
import { UiInputSelectComponent } from './ui-input-select';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiInputToggleComponent } from './ui-input-toggle/ui-input-toggle.component';
import { UiInputCheckboxComponent } from './ui-input-checkbox/ui-input-checkbox.component';

const uiInputComponents = [
  UiInputDateComponent,
  UiInputSelectIconComponent,
  UiInputSelectComponent,
  UiInputToggleComponent,
  UiInputCheckboxComponent,
];

@NgModule({
  declarations: [...uiInputComponents],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UtilModule,
    TranslateModule,
    ReactiveFormsModule,
    UiIconModule,
    NgSelectModule,
  ],
  exports: uiInputComponents,
})
export class UiInputsModule {
}
