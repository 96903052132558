import { UiColor } from './colors';
import { UiHandlerAction } from './action';

export interface UiButton extends UiHandlerAction<any>{
  fill: 'solid' | 'outline';
  color: UiColor;
  handler: (item?) => void;
}

export const uiEditButton = (handler: (item?) => void): UiButton => ({
  label: 'typedGlobal.edit',
  color: UiColor.Primary,
  fill: 'outline',
  handler
});

export const uiCancelButton = (handler?: (item?) => void): UiButton => ({
  label: 'typedGlobal.cancel',
  color: UiColor.Primary,
  fill: 'outline',
  handler
});

export const uiDeleteButton = (handler: (item?) => void, handleOnEnter = false): UiButton => ({
  label: 'typedGlobal.delete',
  color: UiColor.Danger,
  fill: 'solid',
  handler,
  handleOnEnter
});

export const uiCreateButton = (handler?: (item?) => void): UiButton => ({
  label: 'typedGlobal.create',
  color: UiColor.Success,
  fill: 'solid',
  handler
});


export const uiPrimaryButton = (label: string, handler: (item?) => void, handleOnEnter = false): UiButton => ({
  label,
  color: UiColor.Primary,
  fill: 'solid',
  handler,
  handleOnEnter
});

export const uiGenerateButton = (label: string, color: UiColor = UiColor.Primary, fill: 'solid' | 'outline' = 'solid', handler: (item?) => void, handleOnEnter = false): UiButton => ({
  label,
  color: color,
  fill,
  handler,
  handleOnEnter
});


export const uiSaveButton = (handler?: (item?) => void) =>
  uiPrimaryButton('typedGlobal.save', handler);
