import { createStore, select, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  getAllEntities,
  selectAllEntities,
  selectEntity,
  selectMany,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { CheckGroup } from './group';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';
import { CheckGroupDependencyRepository } from '../group-dependency/group-dependency.repository';
import { CheckChecklistDataRepository } from '../checklist-data/checklist-data.repository';
import { PersistStateService } from '../../shared';
import { CheckItemFillFacade } from '../item/item.fill-facade';

// todo that is the shit!
interface GroupRepoProps {
  checklistGroupsIdMap: IdMap;
  categoryGroupsIdMap: IdMap;
}

const store = createStore(
  checkStoreConfig('groups'),
  withEntities<CheckGroup, 'Id'>({ idKey: 'Id' }),
  withProps<GroupRepoProps>({
    checklistGroupsIdMap: {},
    categoryGroupsIdMap: {}
  })
);

@Injectable({ providedIn: 'root' })
export class CheckGroupRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();
  groups$ = store.pipe(selectAllEntities());

  constructor(private itemFacade: CheckItemFillFacade,
              private groupDependencyRepo: CheckGroupDependencyRepository,
              private checklistDataRepo: CheckChecklistDataRepository,
              private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(groups: CheckGroup[], isCompleteUpdate: boolean, removeAbsentForChecklistId: number = null) {
    const deleteGroupsIds = store
      .getValue()
      .checklistGroupsIdMap[removeAbsentForChecklistId];

    // the entity merge of elf store seems sets all "fillSeen" to false
    // maybe it will work without this workaround in a later version
    const seenGroupIds = store
      .query(getAllEntities())
      .filter(x => x !== undefined)
      .filter(x => x !== null)
      .filter(x => x.ChecklistId === groups[0]?.ChecklistId && x.fillSeen)
      .map(x => x.Id);

    groups = groups.map(groups => {
      return { ...groups, fillSeen: seenGroupIds.some(y => y === groups.Id) }
    })

    store.update(
      deleteEntities(deleteGroupsIds),
      upsertEntities(groups),
      idMapper(
        'checklistGroupsIdMap',
        groups,
        'ChecklistId',
        isCompleteUpdate
      ),
      idMapper(
        'categoryGroupsIdMap',
        groups,
        'CategoryId',
        isCompleteUpdate
      )
    );
  }

  updateGroupOrder(categoryId: number, groupOrder: number[]) {
    let order = 0;
    store.update(updateEntities(
      groupOrder,
      entity => {
        return { ...entity, Order: order++ };
      }
    ));
  }

  delete(id: number) {
    store.update(
      deleteEntities(id),
      removeMappedId('checklistGroupsIdMap', id),
      removeMappedId('categoryGroupsIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistGroupIdMap = store.getValue().checklistGroupsIdMap;
    let deleteGroupIds = [];
    checklistIds.forEach(x => {
      deleteGroupIds = deleteGroupIds.concat(checklistGroupIdMap[x]);
    });
    store.update(
      deleteEntities(deleteGroupIds),
      removeKeys('checklistGroupsIdMap', checklistIds)
    );
  }

  getSync(groupId: number) {
    return store.getValue().entities[groupId];
  }

  getFull(groupId: number): Observable<CheckGroup> {
    return combineLatest([
      store.pipe(selectEntity(groupId)),
      this.itemFacade.getGroupItemsForFill(groupId),
      this.groupDependencyRepo.getGroupDependencies(groupId)
    ]).pipe(
      map(([group, items, dependencies]) =>
        ({ ...group, items, dependencies })
      ));
  }

  setGroupSeen(groupId: number) {
    store.update(updateEntities(groupId, { fillSeen: true }));
  }

  resetGroupSeenForChecklist(checklistId: number) {
    const groupIds = store.getValue().checklistGroupsIdMap[checklistId];
    store.update(updateEntities(groupIds, { fillSeen: false }));
  }

  getMany(groupIds: number[]): Observable<CheckGroup[]> {
    if (groupIds) {
      return store.pipe(selectMany(groupIds));
    }
    return of([]);
  }

  getManyForConfig(groupIds: number[]): Observable<CheckGroup[]> {
    if (!groupIds.length) {
      return of([]);
    }

    return combineLatest([
      this.getMany(groupIds),
      // wont this emit with empty array?!
      combineLatest(groupIds.map(groupId => this.itemFacade.getGroupItemsForFill(groupId))),
      combineLatest(groupIds.map(groupId => this.groupDependencyRepo.getGroupDependencies(groupId)))
    ]).pipe(
      map(([groups, itemsMap, dependencyMap]) => {
        return groups
          .map((group, i) => {
              return {
                ...group,
                items: itemsMap[i],
                dependencies: dependencyMap[i]
              };
            }
          )
          .sort((a, b) => a.Order - b.Order);
      })
    );
  }


  getManyForFill(groupIds: number[]): Observable<CheckGroup[]> {
    if (!groupIds.length) {
      return of([]);
    }

    return combineLatest([
      this.getMany(groupIds),
      // wont this emit with empty array?!
      combineLatest(groupIds.map(groupId => this.itemFacade.getGroupItemsForFill(groupId))),
      combineLatest(groupIds.map(groupId => this.groupDependencyRepo.getGroupDependencies(groupId)))
    ]).pipe(
      map(([groups, itemsMap, dependencyMap]) => {
        return groups
          .map((group, i) => {
              const dependencies = dependencyMap[i];
              const noOrFulfilledDependencies =
                !dependencies.length
                || dependencies.some(d => d.fulfilled);

              const items = itemsMap[i].filter(i => i.fillVisible);
              const fillValid = items.map(i => i.fillValid).every(v => v);
              let fillColor = 'var(--ion-color-medium-tint)';
              if (group.fillSeen) {
                if (fillValid) {
                  fillColor = 'var(--ion-color-success)';
                } else {
                  fillColor = 'var(--ion-color-danger)';
                }
              }
              const fillVisible = noOrFulfilledDependencies && items.length > 0;
              return {
                ...group,
                items,
                dependencies,
                fillValid,
                fillColor,
                fillVisible
              };
            }
          )
          .sort((a, b) => a.Order - b.Order);
      })
    );
  }

  getChecklistGroupsForFill(checklistId: number) {
    return store.pipe(
      select(state => state.checklistGroupsIdMap[checklistId] || []),
      switchMap(groupIds => this.getManyForFill(groupIds))
    );
  }

  getCategoryGroupsForConfig(categoryId: number) {
    return store.pipe(
      select(state => state.categoryGroupsIdMap[categoryId] || []),
      switchMap(groupIds => this.getManyForConfig(groupIds))
    );
  }

  getCategoryGroupsForFill(categoryId: number) {
    return store.pipe(
      select(state => state.categoryGroupsIdMap[categoryId] || []),
      switchMap(groupIds => this.getManyForFill(groupIds))
    );
  }
}

