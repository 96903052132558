export default {
  barcodeScanner: {
    permissionHeader: 'مجوز',
    permissionMessage: 'اگر می خواهید اجازه استفاده از دوربین خود را بدهید، آن را در تنظیمات برنامه فعال کنید.',
    scanPrompt: 'اسکن {{thing}}',
    enterPrompt: '{{thing}} را وارد کنید',
    blinkId: 'شناسه چشمک زدن',
    isNoBlinkId: 'کد QR اسکن شده یک شناسه Blink معتبر نیست',
    objectCode: 'کد شی'
  },
  camera: {
    promptLabelHeader: 'منبع را انتخاب کنید',
    promptLabelPicture: 'یک عکس بگیر',
    promptLabelPhoto: 'از گالری انتخاب کنید'
  },
  network: {
    changed: {
      connected: 'اتصال به اینترنت برقرار شد',
      disconnected: 'اتصال اینترنت قطع شد'
    }
  },
  file: { downloadStarted: 'دانلود شروع شد.' },
  nfc: { wrongCode: 'کد QR اسکن شده یک کد شی نیست!', error: 'خطای NFC' }
};
