<ion-header class="ion-no-border no-border">
  <ion-toolbar mode="md" class="pt-10 pb-10">
    <ion-title>
      <ng-content select="[headerTitle]"></ng-content>
      {{ title | translate }}
    </ion-title>
    <div slot="end">
      <ui-modal-close-button (clicked)="modalController.dismiss()"></ui-modal-close-button>
    </div>
  </ion-toolbar>
</ion-header>
