import { Pipe, PipeTransform } from "@angular/core";
import dayjs from 'dayjs';

@Pipe({
  name: "day"
})
export class DayPipe implements PipeTransform {

  transform(value: string, format = 'DD') {
    return value ? dayjs(value).format(format) : null;
  }
}
