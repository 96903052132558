import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { ReplaySubject } from 'rxjs';
import { transformObjectToPath, TranslateGenericClass } from './translations';
import { take } from 'rxjs/operators';

// dayjs locals
import 'dayjs/locale/ar';
import 'dayjs/locale/bg';
import 'dayjs/locale/de'
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fa';
import 'dayjs/locale/fr';
import 'dayjs/locale/hr';
import 'dayjs/locale/hu';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/tr';
import 'dayjs/locale/uk';
import 'dayjs/locale/cs';
import 'dayjs/locale/sk';

import globalAr from './global-translations/global-ar';
import globalBg from './global-translations/global-bg';
import globalDe from './global-translations/global-de';
import globalEl from './global-translations/global-el';
import globalEn from './global-translations/global-en';
import globalEs from './global-translations/global-es';
import globalFa from './global-translations/global-fa';
import globalFr from './global-translations/global-fr';
import globalHr from './global-translations/global-hr';
import globalHu from './global-translations/global-hu';
import globalIt from './global-translations/global-it';
import globalPl from './global-translations/global-pl';
import globalPt from './global-translations/global-pt';
import globalRo from './global-translations/global-ro';
import globalRu from './global-translations/global-ru';
import globalTr from './global-translations/global-tr';
import globalUk from './global-translations/global-uk';
import globalCs from './global-translations/global-cs';
import globalSk from './global-translations/global-sk';


import { updateDayjsLocale } from '../init-dayjs';
import * as dayjs from 'dayjs';
import * as updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

const suffix = 'typedGlobal';

@Injectable({
  providedIn: 'root'
})
export class GlobalTranslateService extends TranslateGenericClass<typeof globalDe>() {
  currentLanguageKey: string;
  private initializedSubject = new ReplaySubject<void>(1);

  constructor(private platform: Platform,
              private translate: TranslateService) {
    super();
    Object.assign(this, transformObjectToPath(suffix, globalDe));
  }

  async initialize(languageKey: string) {
    this.translate.setDefaultLang('de');
    await this.translate.use(languageKey).toPromise();

    this.addTranslations('de', suffix, globalDe);
    this.addTranslations('en', suffix, globalEn);

    this.addTranslations('bg', suffix, globalBg);
    this.addTranslations('el', suffix, globalEl);
    this.addTranslations('fr', suffix, globalFr);
    this.addTranslations('hu', suffix, globalHu);
    this.addTranslations('it', suffix, globalIt);
    this.addTranslations('pl', suffix, globalPl);
    this.addTranslations('pt', suffix, globalPt);
    this.addTranslations('ro', suffix, globalRo);
    this.addTranslations('ru', suffix, globalRu);
    this.addTranslations('tr', suffix, globalTr);
    this.addTranslations('es', suffix, globalEs);
    this.addTranslations('ar', suffix, globalAr);
    this.addTranslations('fa', suffix, globalFa);
    this.addTranslations('hr', suffix, globalHr);
    this.addTranslations('uk', suffix, globalUk);
    this.addTranslations('cs', suffix, globalCs);
    this.addTranslations('sk', suffix, globalSk);
    this.currentLanguageKey = languageKey;

    updateDayjsLocale(languageKey);

    this.initializedSubject.next();
  }

  setupService(target, suffix: string, mainTranslation: object) {
    Object.assign(target, transformObjectToPath(suffix, mainTranslation));
  }

  addTranslations(languageKey: string, suffix: string, translations: object) {
    this.initializedSubject
      .pipe(take(1))
      .subscribe(() => {
        const mappedTranslations = {};
        mappedTranslations[suffix] = translations;
        this.translate.setTranslation(languageKey, mappedTranslations, true);
      });
  }

  getCurrentLanguageKey(): string {
    return this.currentLanguageKey;
  }
}
