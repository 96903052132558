export default {
  home: {
    pageTitle: 'Мої форми',
    locationChecklists_label: "Заповнити форму для об'єкта",
    generalChecklists_label: 'Заповніть загальну форму',
    processingChecklists_label: 'Форма в процесі',
    checklistDatas_label: 'Заповнені форми',
    selectLocation: "Виберіть об'єкт",
    scanQrCode: "Сканувати об'єктний код",
    showAll: '... Покажи все',
    showLess: '... показувати менше'
  },
  fill: {
    checklists: 'Форми',
    fillChecklist: 'заповнити форму',
    checklistNotFound: 'На жаль, форму, яку ви запитували, не вдалося знайти.',
    checklistOfflineLoginUserRequired: 'Ця форма вимагає підбору працівника. Щоб отримати доступ до шаблону форми, установіть підключення до Інтернету.',
    checklistNotOfflineAvailable: 'Шаблон форми не зберігається в автономному режимі, підключіться до Інтернету, щоб отримати доступ до шаблону форми.',
    resetConfirm: 'Ви дійсно хочете скинути форму?',
    categoryAppeared: 'Додано категорію "{{categoryName}}".',
    groupAppeared: 'Сторінку "{{groupName}}" додано.',
    itemAppearedInGroup: 'На сторінку "{{groupName}}" додано нові запитання.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Переходимо до наступного запитання',
    containerUriExpired: 'На жаль, термін завантаження минув.',
    finishMessage: 'Ви ввели всі необхідні дані і можете заповнити форму.',
    offlineMessage: 'Підключіться до Інтернету, щоб заповнити форму.',
    offlinePreviewMessage: 'Підключіться до Інтернету, щоб створити попередній перегляд.',
    checklistUpdatedHeader: 'Шаблон форми змінено',
    checklistUpdatedMessage: 'Шаблон форми змінено. Будь ласка, ще раз перевірте свої записи перед надсиланням форми.',
    checklistLocationRequiredHeader: "Виберіть об'єкт",
    checklistLocationRequiredMessage: "Ви повинні вибрати об'єкт, щоб заповнити цю форму.",
    fileUploadFileMissingHeader: 'Файли не знайдені',
    fileUploadFileMissingMessage: 'Наступні файли вибрано, але їх неможливо знайти на пристрої: {{ missingFiles }}',
    finishSuccess: 'Форма була успішно заповнена, і всі дані оброблено.',
    finishError: 'Форму не вдалося заповнити через помилку. Зверніться до служби підтримки.',
    createdWith: 'Ця форма була створена за допомогою',
    preview: 'попередній перегляд'
  },
  item: {
    boolean: { yes: 'Так', no: 'Немає' },
    scan: 'сканування',
    email: { invalidEmail: 'Будь ласка, перевірте свій запис.' },
    takePicture: { picture: 'Картина', buttonText: 'додати фото' },
    createTask: {
      taskTitle: 'Назва квитка',
      taskDescription: 'Опис квитка',
      taskDueDate: 'Термін оплати квитка'
    }
  },
  noChecklistsForLocationFound: 'Для цього об’єкта немає форми. Виберіть іншу нерухомість або заповніть загальну форму.',
  selectChecklistByLocation: "Виберіть форму для об'єкта"
};
