// noinspection ES6PreferShortImport

import { Injectable } from '@angular/core';
import { CheckItemValueRepository } from '../item-value/item-value.repository';
import { CheckItem } from './item';
import { CheckItemRepository } from './item.repository';
import { CheckItemDependencyRepository } from '../item-dependency/item-dependency.repository';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CheckChecklistDataRepository } from '../checklist-data/checklist-data.repository';

@Injectable({ providedIn: 'root' })
export class CheckItemFillFacade {

  constructor(
    private itemRepo: CheckItemRepository,
    private itemValueRepo: CheckItemValueRepository,
    private itemDependencyRepo: CheckItemDependencyRepository,
    private checklistDataRepo: CheckChecklistDataRepository) {
  }

  // TODO: use ItemConfigFacade
  getManyForFill(itemIds: number[]): Observable<CheckItem[]> {
    return combineLatest([
      this.itemRepo.getMany(itemIds),
      combineLatest(itemIds.map(itemId => this.itemValueRepo.getItemItemValues(itemId))),
      combineLatest(itemIds.map(itemId => this.itemDependencyRepo.getItemDependencies(itemId))),
      combineLatest(itemIds.map(itemId => this.checklistDataRepo.getItemChecklistItemData(itemId))),
      this.itemRepo.getEmailHistory()
    ]).pipe(
      map(([
             items,
             itemValuesMap,
             itemItemDependenciesMap,
             checklistItemDataMap,
             emailHistory
           ]) => {
          return items.map((item, i) => {
            const noOrFulfilledDependencies =
              !itemItemDependenciesMap[i].length
              || itemItemDependenciesMap[i].some(d => d.fulfilled);
            const fillValue = checklistItemDataMap[i]?.ItemData;
            const fillVisible =
              item.Visible
              && noOrFulfilledDependencies;
            let fillValid =
              !fillVisible
              || !item.Required
              // need some complex comparison here, because also 'false' is a valid value for required.
              || item.Required && fillValue !== undefined && fillValue !== null && fillValue !== '';

            if (item.ItemType === 'CreateTask') {
              const parsedValue = fillValue ? JSON.parse(fillValue) : null;
              if (parsedValue != null) {
                fillValid = parsedValue.Title && parsedValue.Title !== '';
              }
            }
            // todo add custom item validators (like email or what)

            return {
              ...item,
              itemValues: itemValuesMap[i],
              itemDependencies: itemItemDependenciesMap[i],
              fillValid,
              fillValue,
              fillVisible,
              fulfilledDependency: noOrFulfilledDependencies,
              fillEmailHistory: emailHistory[item.Id]
            };
          }).sort((a, b) => a.Order - b.Order);
        }
      ));
  }

  getGroupItemsForFill(groupId: number) {
    return this.itemRepo.getGroupItemIdsForFill(groupId).pipe(switchMap(itemIds =>
        itemIds.length
          ? this.getManyForFill(itemIds)
          : of([])
      )
    )
  }

  getChecklistItems(id: number): Observable<CheckItem[]> {
    return this.itemRepo.getChecklistItemIds(id).pipe(switchMap(itemIds =>
        itemIds.length
          ? this.getManyForFill(itemIds)
          : of([])
      )
    );
  }
}
