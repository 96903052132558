import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController, Platform } from '@ionic/angular';
import { PinLockRepository } from './pin-lock.repository';

@Injectable({ providedIn: 'root' })
export class PinLockService {
  enterPinModal = null;
  pinLockTimerSubscription: Subscription;

  constructor(private pinLockRepository: PinLockRepository,
              private modalController: ModalController,
              private platform: Platform) {
  }

  removePinLock() {
    this.killPinLockTimer();
    this.pinLockRepository.updatePinHash(null);
  }

  setLastUnlockDate() {
    // setLastUnlockDate if pin is activated
    // log("setLastUnlockDate");
    if (this.pinLockRepository.pinHash()) {
      this.pinLockRepository.updatePinHash(this.pinLockRepository.store.getValue().pinHash);
      // this.startPinLockTimer();
    }
  }

  // createPin(pin: string) {
  //   const pinHash = cyrb53(pin);
  //   this.setPinHash(pinHash);
  // }

  // async enterPin(deactivate = false) {
  //   if (this.enterPinModal === null) {
  //     this.enterPinModal = await this.modalController.create({
  //       component: BPinEnterComponent,
  //       componentProps: {
  //         hash: this.pinLockRepository.pinHash(),
  //         deactivate
  //       },
  //       cssClass: 'pin-modal',
  //       keyboardClose: false,
  //       backdropDismiss: false,
  //       swipeToClose: false
  //     });
  //
  //     await this.enterPinModal.present();
  //
  //     return this.enterPinModal.onDidDismiss().then(response => {
  //       this.enterPinModal = null;
  //       if (response.role === 'unlock') {
  //         this.setLastUnlockDate();
  //         return true;
  //       } else if (response.role === 'deactivate' || response.role === 'logout') {
  //         this.removePinLock();
  //       }
  //       return false;
  //     });
  //   }
  // }

  // deactivatePin() {
  //   return this.enterPin(true);
  // }

  // async showCreatePinModal(skipButton = false) {
  //   const createPinModal = await this.modalController.create({
  //     component: BPinCreateComponent,
  //     componentProps: {
  //       skipButton,
  //       isModal: true
  //     }
  //   });
  //
  //   await createPinModal.present();
  // }

  // private setPinHash(hash: number) {
  //   this.pinLockRepository.updatePinHash(hash);
  //   this.startPinLockTimer();
  // }

  // private startPinLockTimer(inMin?: number) {
  //   if (!inMin) {
  //     inMin = lockAfterMin;
  //   }
  //   const inMillis = inMin * 1000 * 60;
  //   if (this.pinLockTimerSubscription) {
  //     this.pinLockTimerSubscription.unsubscribe();
  //   }
  //   this.pinLockTimerSubscription = timer(inMillis).subscribe(() => this.enterPin());
  // }

  private killPinLockTimer() {
    if (this.pinLockTimerSubscription) {
      this.pinLockTimerSubscription.unsubscribe();
      this.pinLockTimerSubscription = null;
    }
  }

  // private setupPinLock() {
  //   if (this.pinLockRepository.isLocked()) {
  //     this.enterPin();
  //   } else if (this.pinLockRepository.pinHash()) {
  //     const nextLockInMin = dayjs().diff(dayjs(this.pinLockRepository.lastUnlockDate()), 'minutes');
  //     this.startPinLockTimer(nextLockInMin);
  //   }
  //
  //   this.platform.pause.subscribe(() => {
  //     this.killPinLockTimer();
  //   });
  //
  //   this.platform.resume.subscribe(() => {
  //     if (this.pinLockRepository.isLocked()) {
  //       this.enterPin();
  //     }
  //   });
  // }
}
