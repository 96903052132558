import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';

import { ApiManager, BlinkService, TypedQuery } from '../../shared/api-manager';
import { Observable, of } from 'rxjs';
import { CheckChecklist } from './checklist';
import { CheckReportTemplateType } from './report-template-type';
import { CheckGroup } from '../group/group';
import { CheckCategory } from '../category/category';
import { CheckChecklistRepository } from './checklist.repository';
import { CheckGroupRepository } from '../group/group.repository';
import { CheckItem } from '../item/item';
import { CheckItemValueRepository } from '../item-value/item-value.repository';
import { CheckChecklistDataRepository } from '../checklist-data/checklist-data.repository';

const checklistListSelect: (keyof CheckChecklist)[] = [
  'Id',
  'Name',
  'Icon',
  'Color',
  'CurrentUserCanUpdate',
  'CurrentUserCanFill',
  'Status',
  'IsPublic',
  'ShortUrl',
  'LocationRequired',
  'AllLocationAllowed',
  'Locations',
  'LoginUserRequired',
  'Language',
  'VersionTag'
];

@Injectable({
  providedIn: 'root'
})
export class CheckChecklistApi {
  private api =
    this.apiManager.createApiForEndpoint<CheckChecklist>(
      `odata/v1`,
      'Checklists',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private checklistRepo: CheckChecklistRepository,
              private checklistDataRepo: CheckChecklistDataRepository,
              private groupRepo: CheckGroupRepository,
              private itemValueRepo: CheckItemValueRepository) {
  }

  getList(companyId: number): void {
    const query = {
      select: checklistListSelect,
      filter: {
        CompanyId: companyId,
      }
    };
    this.api.get<CheckChecklist[]>(query)
      .subscribe(checklists => {
        this.checklistRepo.sync(checklists, true);
      })
    ;
  }

  get(id: number): Observable<CheckChecklist> {
    const query = {
      key: id,
      select: checklistListSelect
    };
    return this.api.get<CheckChecklist>(query)
      .pipe(
        tap(checklist => this.checklistRepo.sync([checklist]))
      );
  }

  getForConfig(id: number) {
    const query: TypedQuery<CheckChecklist> = {
      key: id,
      select: [
        ...checklistListSelect,
        'CurrentUserCanDelete',
        'ReportTemplateType',
        'ReportTemplateId',
        'Preview',
        'Tags'
      ],
      expand: [{ Items: { filter: { Active: true } } }]
    };
    this.api.get<CheckChecklist>(query)
      .subscribe(checklist => {
        this.checklistRepo.sync([checklist]);
      });
  }

  getWithCategories(id: number) {
    const query = {
      key: id,
      select: ['Id', 'Name'],
      expand: [
        'Categories',
        'CategoryDependencies',
        { Groups: { filter: { Active: true } } }
      ]
    };
    return this.api.get<CheckChecklist>(query).pipe(tap(
      checklist => this.checklistRepo.sync([checklist])
    ));
  }

  getFull(id: number): Observable<CheckChecklist> {
    const query = {
      key: id,
      select: ['Id', 'Name', 'CompanyId', 'Preview', 'VersionTag'],
      expand: [
        { Categories: { orderBy: ['Order'] } },
        'CategoryDependencies',
        { Groups: { filter: { Active: true }, orderBy: ['CategoryId', 'Order'] } },
        'GroupDependencies',
        { Items: { filter: { Active: true }, orderBy: ['GroupId', 'Order'] } },
        { ItemValues: { filter: { Active: true }, orderBy: ['ItemId', 'Order'] } },
        'ItemDependencies'
      ]
    };
    return this.api.get<CheckChecklist>(query).pipe(
      tap(checklist => this.checklistRepo.sync([{ ...checklist, offlineAvailable: true }]))
    );
  }

  startChecklistByHash(checklistHash: string) {
    const query = {
      filter: { Hash: checklistHash },
      select: ['Id'],
      skipAuth: true
    };
    return this.api.get<CheckChecklist[]>(query).pipe(
      switchMap(x => {
        const checklistId = x[0].Id;
        const query = {
          key: checklistId,
          select: ['Id', 'Name', 'Color', 'Icon', 'Hash', 'CompanyId', 'Preview', 'VersionTag'],
          expand: [
            'Categories',
            'CategoryDependencies',
            { Groups: { filter: { Active: true } } },
            'GroupDependencies',
            { Items: { filter: { Active: true } } },
            { ItemValues: { filter: { Active: true } } },
            'ItemDependencies'
          ]
        };
        return this.api.get<CheckChecklist>(query);
      }),
      switchMap(checklist => {
        this.checklistRepo.sync([checklist]);
        this.checklistDataRepo.startChecklistFill(
          checklist,
          null,
          null
        );
        return of(checklist.Id);
      })
    ).toPromise();
  }

  create(name: string, companyId: number): Observable<CheckChecklist> {
    const newChecklist: Partial<CheckChecklist> = {
      Name: name,
      CompanyId: companyId,
      Language: 'de',
      Icon: 'list',
      Color: '#cfd8dc',
      MailTemplateId: 2618232,
      IsPublic: false,
      LocationRequired: false,
      AllLocationAllowed: true,
      LoginUserRequired: false,
      ReportTemplateType: CheckReportTemplateType.DefaultWithPoints,
      Tags: [],
      Locations: [],
      PermissionGroups: [],
      Status: 'Active'
    };
    return this.api.post({ body: newChecklist }).pipe(tap(
      c => this.checklistRepo.sync([c])
    ));
  }

  duplicate(id: number) {
    return this.api.post({ key: id, action: 'Duplicate' })
      .pipe(
        switchMap((response: any) => this.get(response.value))
      );
  }

  update(body: Partial<CheckChecklist>): Observable<CheckChecklist> {
    const query = {
      key: body.Id,
      oDataReturnRepresentation: true,
      body
    }
    return this.api.patch(query).pipe(tap(
      c => this.checklistRepo.sync([c])
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.checklistRepo.delete(id)
    ));
  }

  export(id: number): Observable<string> {
    const options = {
      func: {
        Export: {
          checkListId: id,
          IncludeReportTemplates: true
        }
      }
    };
    return this.api.get(options) as Observable<any>;
  }

  import(data: string, companyId: number) {
    const options = {
      action: 'Import',
      body: {
        CompanyId: companyId,
        ImportFileContent: data,
        IncludeReportTemplates: true // includeReportTemplates is not used anymore in the service application
      }
    };
    return this.api.post(options);
  }

  getTags(): Observable<string[]> {
    return this.apiManager.get('odata/v1', 'Tags', { service: BlinkService.Check })
      .pipe(map(tags => tags.map(tag => tag.Name)));
  }

  getCategories(checklistId: number): Observable<CheckChecklist> {
    const query = {
      key: checklistId,
      select: ['Id'],
      expand: [
        'Categories',
        'CategoryDependencies',
      ]
    };
    return this.api.get<CheckChecklist>(query).pipe(
      tap(c => this.checklistRepo.sync([c]))
    );
  }

  updateCategoryOrder(checklistId: number, categoryOrder: number[]) {
    const options = {
      key: checklistId,
      action: 'Default.OrderCategories',
      body: {
        CategoryIds: categoryOrder
      }
    };
    return this.api.post(options).pipe(tap(
      // todo
    ));
  }

  getGroups(checklistId: number): Observable<CheckChecklist> {
    const query = {
      key: checklistId,
      select: ['Id', 'Name', 'CompanyId', 'Preview'],
      expand: [
        { Groups: { filter: { Active: true } } },
        'GroupDependencies',
      ]
    };
    return this.api.get<CheckChecklist>(query).pipe(
      tap(c => this.checklistRepo.sync([c]))
    );
  }

  updateGroupOrder(category: CheckCategory, groupOrder: number[]) {
    const options = {
      key: category.ChecklistId,
      action: 'Default.OrderCategoryGroups',
      body: {
        CategoryId: category.Id,
        GroupIds: groupOrder
      }
    };
    return this.api.post(options).pipe(tap(
      () => this.groupRepo.updateGroupOrder(category.Id, groupOrder)
    ));
  }

  updateItemOrder(group: CheckGroup, itemOrder: number[]) {
    const options = {
      key: group.ChecklistId,
      action: 'Default.OrderGroupItems',
      body: {
        GroupId: group.Id,
        ItemIds: itemOrder
      }
    };
    return this.api.post(options);
  }

  updateItemValueOrder(item: CheckItem, itemValueOrder: number[]) {
    const options = {
      key: item.ChecklistId,
      action: 'Default.OrderItemValues',
      body: {
        ItemId: item.Id,
        ItemValueIds: itemValueOrder
      }
    };
    return this.api.post(options).pipe(tap(
      () => this.itemValueRepo.updateItemValueOrder(itemValueOrder)
    ));
  }
}
