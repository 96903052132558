import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { of, ReplaySubject, Subject } from 'rxjs';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  selectEntity,
  selectMany,
  updateEntities,
  updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { CheckItemDependency } from './item-dependency';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';
import { CheckItem } from '../item/item';
import { checkDependency } from '../check-dependency';
import { PersistStateService } from '../../shared';

interface ItemDependencyRepoProps {
  checklistItemDependencyIdMap: IdMap;
  itemItemDependenciesIdMap: IdMap;
}

const store = createStore(
  checkStoreConfig('itemDependencies'),
  withEntities<CheckItemDependency, 'Id'>({ idKey: 'Id' }),
  withProps<ItemDependencyRepoProps>({
    checklistItemDependencyIdMap: {},
    itemItemDependenciesIdMap: {}
  })
);

@Injectable({ providedIn: 'root' })
export class CheckItemDependencyRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();
  itemAppeared$ = new Subject<number>();

  constructor(private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(dependencies: CheckItemDependency[], isCompleteUpdate: boolean, removeAbsentForChecklistId: number = null) {
    const deleteDependencyIds = store
      .getValue()
      .checklistItemDependencyIdMap[removeAbsentForChecklistId];

    store.update(
      deleteEntities(deleteDependencyIds),
      upsertEntities(dependencies),
      idMapper(
        'checklistItemDependencyIdMap',
        dependencies,
        'ChecklistId',
        isCompleteUpdate
      ),
      idMapper(
        'itemItemDependenciesIdMap',
        dependencies,
        'ItemId',
        isCompleteUpdate
      )
    );
  }

  delete(id: number) {
    store.update(
      deleteEntities(id),
      removeMappedId('itemItemDependenciesIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistItemDependencyIdMap = store.getValue().checklistItemDependencyIdMap;
    let deleteItemDependencyIds = [];
    checklistIds.forEach(x => {
      deleteItemDependencyIds = deleteItemDependencyIds.concat(checklistItemDependencyIdMap[x]);
    });
    store.update(
      deleteEntities(deleteItemDependencyIds),
      removeKeys('checklistItemDependencyIdMap', checklistIds)
    );
  }

  get(dependencyId: number) {
    return store.pipe(selectEntity(dependencyId));
  }

  getMany(ids: number[]) {
    if (ids) {
      return store.pipe(selectMany(ids));
    }
    return of([]);
  }

  getItemDependencies(itemId: number) {
    return store.pipe(
      select(state => state.itemItemDependenciesIdMap[itemId]),
      map(itemDependencyIds => itemDependencyIds ? itemDependencyIds : []),
      switchMap(itemDependencyIds => this.getMany(itemDependencyIds))
    );
  }

  checkDependency(dependencyId: number, dependencyItem: CheckItem, value: any) {
    const dependency = store.getValue().entities[dependencyId];
    if (!dependency) {
      return;
    }
    const fulfilled = checkDependency(dependency, dependencyItem, value);

    if (!dependency.fulfilled && fulfilled) {
      this.itemAppeared$.next(dependency.ItemId);
    }

    store.update(updateEntities(
      dependencyId,
      dep => ({ ...dep, fulfilled })
    ));
  }

  resetDependenciesForChecklist(checklistId: number) {
    store.update(
      updateEntitiesByPredicate(
        dep => dep.ChecklistId === checklistId,
        { fulfilled: false }
      )
    );
  }

  resetDependenciesForItems(itemIds: number[]) {
    store.update(
      updateEntitiesByPredicate(
        x => {
          return itemIds.includes(x.DependencyId)
        },
        { fulfilled: false }
      )
    );
  }
}
