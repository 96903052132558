import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ui-modal-close-button',
  templateUrl: './ui-modal-close-button.component.html',
  styleUrls: ['./ui-modal-close-button.component.scss']
})
export class UiModalCloseButtonComponent {
  @Output() clicked: EventEmitter<any> = new EventEmitter();
}
