<ui-page [title]="t.REGISTER_SET_AUTH_MODE_PAGE.PAGE_TITLE" backHref="login/blink-id">
  <ion-content>
    <ui-card class="login-card-margin register-set-auth-mode center">
      <div class="ion-padding">
        <div>{{ t.REGISTER_SET_AUTH_MODE_PAGE.TEXT_1 | translate }}</div>
        <br/>
        <div class="ion-margin-bottom">{{ t.REGISTER_SET_AUTH_MODE_PAGE.TEXT_2 | translate }}</div>

        <ion-segment class="ion-no-margin" [(ngModel)]="authMode">
          <ion-segment-button value="email">
            {{ t.CHECK_DATA_PAGE.EMAIL | translate }}
          </ion-segment-button>
          <ion-segment-button value="sms">
            {{ t.CHECK_DATA_PAGE.MOBILE | translate }}
          </ion-segment-button>
        </ion-segment>

        <ng-template [ngIf]="form">
          <form [formGroup]="form">
            <ion-item *ngIf="authMode === AuthModeEnum.EMAIL" class="blink-item-input ion-margin-top ion-margin-bottom"
                      color="input">
              <ion-input type="email"
                         formControlName="authModeValue"
                         label-placement="floating"
                         [label]="t.CHECK_DATA_PAGE.EMAIL | translate">
              </ion-input>
            </ion-item>

            <ion-item *ngIf="authMode === AuthModeEnum.SMS" class="blink-item-input ion-margin-top ion-margin-bottom"
                      color="input">
              <ion-input type="tel"
                         formControlName="authModeValue"
                         label-placement="floating"
                         [label]="t.CHECK_DATA_PAGE.MOBILE | translate">
              </ion-input>
            </ion-item>
          </form>

          <ion-button (click)="register()" [disabled]="!form.valid">
            {{ t.proceed | translate }}
          </ion-button>
        </ng-template>
      </div>
    </ui-card>
  </ion-content>
</ui-page>
