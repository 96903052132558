export default {
  config: {
    checklist: {
      checklist: 'Űrlapsablon',
      checklists: 'Űrlapsablonok',
      none: 'Nincsenek nyomtatványok.',
      new: 'Új űrlapsablon',
      edit: 'Űrlapsablon szerkesztése',
      saved: 'Az űrlapsablon elmentve.',
      delete: 'Űrlapsablon törlése',
      deleteChecklistResults: 'Törli a(z) {{name}} űrlapsablon összes kitöltött űrlapját is?',
      deleteInfo: 'Ha törli az űrlapsablont, az összes kitöltött űrlap is visszavonhatatlanul törlődik! <br><br>A kitöltött űrlapok megtartásához kapcsolja ki az űrlapsablont.',
      deleted: 'Az űrlapsablon törölve lett.',
      duplicate: 'Űrlapsablon másodpéldánya',
      duplicateInfo: 'Használhatja a duplikáció funkciót űrlapsablonok másolására',
      duplicateMessage: 'Szeretné megkettőzni a(z) "{{checklistName}}" űrlapsablont az összes tartalommal?',
      duplicated: 'Az űrlapsablon megkettőződött.',
      templateName: 'Űrlap neve',
      activeInfo: 'Csak az aktív sablonok tölthetők ki, az inaktív sablonok csak a sablonkezelésben szerkeszthetők.',
      locationRequired: 'Az objektum kiválasztása kötelező',
      locationRequiredInfo: 'Ha aktiválva van, az űrlap kitöltéséhez ki kell választani egy objektumot, vagy be kell olvasni a QR-kódot.',
      loginUserRequired: 'Az alkalmazottak kiválasztása kötelező',
      loginUserRequiredInfo: 'Ha aktiválva van, ki kell választani egy alkalmazottat az űrlap kitöltéséhez.',
      allLocationAllowed: 'Engedélyezze az összes objektumot',
      allowOnlyCertainLocations: 'Csak bizonyos objektumokat engedélyez',
      public: 'Nyilvános forma',
      publicInfo: 'Hozzon létre egy nyilvános hivatkozást, amellyel az űrlapot bármely felhasználó kitöltheti. Nincs szükség bejelentkezésre vagy alkalmazásra. Megadhat egy érvényességi dátumot, ameddig a hivatkozásnak érvényesnek kell lennie.',
      preview: 'Előnézet engedélyezése',
      previewInfo: 'Ha aktiválva van, akkor az űrlap alján mindig megjelenik egy „Előnézet” gomb kitöltésekor. Az állandó előnézet gomb alternatívájaként az „Előnézet” tartalomelemet bárhol elhelyezheti az űrlapon (ezt a funkciót nem kell aktiválni).',
      shortUrlExpireDate: 'Lejárati dátum',
      sysadminOnly: 'Csak rendszergazdák',
      allUsers: 'Minden felhasználó',
      usersOfPermissionGroup: 'Ezen jogosultsági csoportok felhasználói',
      emailConfig: 'E-mail konfiguráció',
      emailConfigInfo: 'Ha itt e-mail címeket ad meg, a kitöltött űrlapok automatikusan ezekre az e-mail címekre kerülnek elküldésre.',
      emailSaved: 'Az e-mail cím el lett mentve',
      emailRemoved: 'Az e-mail cím törölve lett',
      importExportInfo: 'Az export funkció lehetővé teszi az űrlapsablonok és a kapcsolódó jelentéssablonok exportálását, hogy újraimportálhassa őket egy másik Blink Check kliensbe vagy rendszerbe.',
      import: 'Űrlapsablon importálása',
      importInfo: 'Kérjük, válassza ki az importálni kívánt űrlapsablon .check-b64 fájlját.',
      imported: 'Az űrlap sikeresen létrehozva.',
      templatesInAppFolder: 'Sablonok az alkalmazáskönyvtárban:',
      invalidImport: 'A kiválasztott fájl nem érvényes űrlapsablon.',
      exported: 'Az űrlapsablon exportálva',
      offline: 'Kérjük, hozzon létre internetkapcsolatot az űrlapsablonok szerkesztéséhez.',
      checklistContainsCreateTask: 'Az űrlapsablon tartalmazza a „Jegy létrehozása” elemet, ezért nem állítható nyilvánosra. Kérjük, távolítsa el a „Jegy létrehozása” elemet.'
    },
    category: {
      category: 'kategória',
      categories: 'Kategóriák',
      saved: 'A kategória elmentve.',
      editTitle: 'Kategória szerkesztése',
      content: 'elemeket',
      newCategory: 'Új kategória',
      name: 'Kategória név',
      edit: 'Kategória szerkesztése',
      duplicated: 'A kategória duplikálva lett.',
      noCategories: 'Még nincsenek kategóriák. Hozzon létre új kategóriákat a „Kategória hozzáadása” gombbal.',
      inactive: 'inaktív',
      showInactive: 'Az inaktívak megjelenítése',
      duplicateCategoryConfirmHeader: 'Ismétlődő kategória',
      duplicateCategoryConfirm: 'Szeretné megkettőzni a "{{categoryName}}" kategóriát annak teljes tartalmával együtt?',
      delete: 'Kategória törlése',
      deleteCategoryConfirm: 'Biztosan törli a(z) "{{categoryName}}" kategóriát?',
      deleteSuccess: 'A kategória eltávolítva.'
    },
    group: {
      group: 'oldal',
      groups: 'oldalakat',
      created: 'Az oldal létrejött.',
      updated: 'Az oldal módosításait elmentettük.',
      updateError: 'Az oldalt nem sikerült menteni. Kérlek, próbáld újra később.',
      rename: 'Oldal átnevezése',
      saved: 'Az oldal el lett mentve.',
      toggleSort: 'Változtassa meg a rendezést',
      editGroupName: 'Szerkessze az oldal nevét',
      new: 'Új oldal',
      name: 'Az oldal neve',
      duplicated: 'Az oldal megkettőződött.',
      noGroups: 'Még nincsenek oldalak. Hozzon létre új oldalakat az „Oldal hozzáadása” gombbal.',
      noItems: 'Még nincsenek elemek. Hozzon létre új elemeket az „Elem hozzáadása” gombbal.',
      add: 'Oldal hozzáadása',
      infoElement: 'Info elem',
      inputElement: 'Bemeneti elem',
      addInfoElement: 'Információs elem hozzáadása',
      addInputElement: 'Bemeneti elem hozzáadása',
      delete: 'Oldal törlése',
      deleteGroupConfirm: 'Biztosan törli a(z) "{{groupName}}" oldalt?',
      chooseGroup: 'Melyik oldalhoz kell hozzáadni az elemet?',
      duplicateGroupConfirmHeader: 'Ismétlődő oldal',
      duplicateGroupConfirm: 'Szeretné megkettőzni a(z) "{{groupName}}" oldalt az összes tartalommal?',
      deleteSuccess: 'Az oldal eltávolítva.'
    },
    item: {
      add: 'Elem hozzáadása',
      created: 'Az elem létrejött',
      saved: 'Az elem el lett mentve.',
      chooseItemType: 'Tárgy kiválasztása',
      values: 'Értékek',
      value: 'Érték',
      ratingNumber: 'Értékelés',
      addItemValue: 'Új érték hozzáadása',
      addItemValueSuccess: 'Új érték került hozzáadásra',
      updateItemValueSuccess: 'Az érték frissült',
      removeItemValueSuccess: 'Az érték eltávolítva',
      removeItemValue: 'Érték eltávolítása',
      takePictureButtonText: 'Kép kiválasztása',
      editTitle: 'Elem szerkesztése',
      editDependenciesTitle: 'Függőségek szerkesztése',
      required: 'Kötelezően kitöltendő mező',
      name: 'Az elem neve',
      description: 'az elem opcionális leírása',
      points: 'Pontok',
      duplicated: 'Az elem megkettőződött.',
      duplicateItemConfirmHeader: 'Ismétlődő elem',
      duplicateItemConfirm: 'Szeretné megkettőzni a "{{itemName}}" elemet annak teljes tartalmával együtt?',
      delete: {
        title: 'Elem törlése',
        message: 'Biztosan törölni szeretné a(z) „{{itemName}}” elemet?',
        success: 'Az elem eltávolítva'
      },
      moveItem: 'Elem mozgatása',
      move: 'Mozog',
      moveItemInfo: 'Kérjük, válassza ki azt a kategóriát és oldalt, ahová az aktuális elemet át szeretné helyezni.',
      itemMoved: 'Az elem át lett helyezve'
    },
    editItem: {
      name: 'Vezetéknév',
      nameInfo: 'Ez a szöveg közvetlenül a beviteli elem előtt jelenik meg.',
      link: 'Link címe',
      linkInfo: 'Ez a cím egy gombbal lesz elérhető. Kérem, írjon be egy érvényes url-t.',
      description: 'Leírás',
      descriptionInfo: 'Ha útmutatást szeretne adni az űrlap kitöltéséhez, itt megadhatja azokat.',
      linkDescription: 'Gomb címke',
      linkDescriptionInfo: 'Adja meg a gomb nevét.',
      values: 'Értékek',
      valuesInfo: 'Minden értékhez megadhat egy minősítési számot. Az értékelési szám segítségével később is végezhet értékeléseket. Az értékelési szám nem kötelező.',
      value: 'Érték',
      valuePlaceholder: 'Új érték',
      ratingNumber: 'Értékelés',
      requiredField: 'Kötelezően kitöltendő mező',
      requiredFieldInfo: 'Ha kötelező mezőként van aktiválva, akkor ezt a mezőt ki kell tölteni az űrlap beküldéséhez.',
      externalNo: 'Külső szám',
      externalNoInfo: 'Választható információk interfészekhez vagy külső rendszereken keresztüli kiértékelésekhez.',
      editable: 'Szerkeszthető',
      editableInfo: 'Ahhoz, hogy a felhasználók bejegyzéseket tudjanak írni, az elemnek szerkeszthetőnek kell lennie.',
      defaultValue: 'Adja meg az alapértelmezett értéket',
      defaultValueInfo: 'Ehhez az elemhez szabványos hozzárendelést adhat meg.',
      defaultValueInfoEditable: 'Az értéket az űrlap felhasználója módosíthatja.',
      visible: 'láthatóság',
      visibleInfo: 'A láthatóság szabályozza, hogy az elem látható legyen-e a szerkesztő számára vagy sem.',
      externalNoAleradyUsed: 'Ez a külső szám már használatban van.',
      taskTypeId: 'Jegy típusa',
      loginUserId: 'felhasználó',
      locationId: 'Objektum neve',
      videoId: 'Videó száma',
      videoType: 'Videó szolgáltatás',
      videoTypes_youtube: 'Youtube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Ide írja be az egyedi videóazonosítót. Ezt megtalálhatja például a videó URL-jében. <br/>Példa: https://www.youtube.com/watch?v=XXXXX vagy a Vimeon https://vimeo.com/XXXXX. <br/>Ebben az esetben a XXXXX számot kell beírni a mezőbe.',
      defaultTaskTitle: 'A jegy alapértelmezett címe',
      defaultTaskDescription: 'A jegy alapértelmezett leírása',
      titleEditable: 'A jegy címe a felhasználó által szerkeszthető',
      descriptionEditable: 'A jegy leírása felhasználó által szerkeszthető',
      dueDateEditable: 'A jegy esedékessége a felhasználó által szerkeszthető',
      addReportAttachment: 'A kitöltött űrlapot PDF formátumban csatolja a jegyhez',
      noTaskTypesFound: 'Nem található jegytípus. A jegytípusok a Blink Active segítségével kezelhetők.',
      noLoginUsersFound: 'Nem található felhasználó. A felhasználók a Blink Manageren keresztül kezelhetők.',
      noLocationsFound: 'Nem található objektum. Az objektumok a Blink Manageren keresztül kezelhetők.',
      createTaskItemInfo: 'Ezzel a funkcióval Blink Active Ticketeket hozhat létre. Megadhat egy címet és leírást, vagy hagyhatja, hogy a felhasználó töltse ki őket. Az objektum hozzárendelése automatikusan történik, a felelős objektumkezelő pedig processzorként.',
      duplicatedValuesNotAllowed: 'Az értékek duplikálása nem megengedett.',
      valueLengthExceeded: 'A megadott érték túl hosszú, ezért lerövidül.'
    },
    itemTypes: {
      PreviewButton: 'Dokumentum előnézete',
      Select: 'Válogatás a listából',
      Signature: 'Aláírás',
      Email: 'e-mail bevitel',
      SendEmailTo: 'E-mail kiszállítás',
      Date: 'Dátum',
      Time: 'idő',
      Boolean: 'eldöntendő kérdés',
      TakePicture: 'Készíts fotót',
      TakeMultiPicture: 'fotókat készíteni',
      Scan: 'QR / EAN szkennelés',
      Input: 'Szövegbevitel',
      Textarea: 'Többsoros szövegbevitel',
      Link: 'linkelés',
      Picture: 'kép megjelenítése',
      Video: 'videó kijelző',
      Text: 'Szöveg megjelenítése',
      Header: 'főcím',
      Rate: 'Értékelés',
      RateSmiley: 'smiley értékelés',
      CreateTask: 'Jegy létrehozása',
      FileUpload: 'fájlok feltöltése',
      Currency: 'valuta'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Tekintse meg az űrlap előnézeti verzióját.',
      Select: 'Határozzon meg minden olyan listaelemet, amelyből kiválasztható.',
      Signature: 'Digitális aláírás mező (ujjal használható)',
      Email: 'A felhasználó érvényes e-mail címet adhat meg.',
      SendEmailTo: 'A Felhasználó megadhat egy érvényes e-mail címet, amelyre a kitöltött űrlapot PDF formátumban elküldi.',
      Date: 'A felhasználó megadhat egy dátumot a naptárban',
      Time: 'A felhasználó megadhat egy időpontot',
      Boolean: 'A felhasználó kiválasztja az igent vagy a nemet',
      TakePicture: 'A felhasználó fényképet készíthet a kamerával, vagy feltölthet képet.',
      TakeMultiPicture: 'A felhasználó több fényképet is készíthet a kamerával, vagy képeket tölthet fel.',
      Scan: 'A felhasználóknak QR-kód vagy EAN beolvasása szükséges. Az eredmény megjelenik az űrlapon, és mentésre kerül.',
      Input: 'Egysoros szövegbeviteli mező',
      Textarea: 'Többsoros szövegbevitel a hosszabb megjegyzésekhez',
      Link: 'Link külső oldalra',
      Picture: 'Töltsön fel egy képet, amely a kitöltésekor megjelenik.',
      Video: 'Tölts fel egy videót, amely a kitöltésekor megjelenik.',
      Text: 'Mentse el a többsoros szövegeket formázással.',
      Header: 'Nagy fejléc a tartalom felépítéséhez',
      Rate: '5 csillagos minősítés',
      RateSmiley: '3 mosolygós arc közül választhat (boldog, semleges, szomorú)',
      CreateTask: 'Jegy jön létre a felhasználó számára',
      FileUpload: 'A felhasználó tetszőleges számú fájlt csatolhat az űrlaphoz.',
      Currency: 'A felhasználó megadhat egy összeget pénznemben.'
    },
    template: {
      template: 'Jelentéssablon',
      templates: 'Jelentéssablonok',
      name: 'Vezetéknév',
      description: 'Leírás',
      customer: 'vevő',
      new: 'Új jelentéssablon',
      edit: 'Jelentéssablon szerkesztése',
      create: 'Jelentéssablon létrehozása',
      public: 'Nyilvános',
      active: 'Aktív',
      deleteConfirm: 'Biztosan törli a(z) „{{templateName}}” jelentéssablont?',
      deleteSuccess: 'A jelentéssablon eltávolítva',
      editor: 'Sablon tervező',
      notForMobile: 'A sablontervező nincs mobileszközökre optimalizálva. Ehhez használja a Blink Check-et a böngészőben.',
      noTemplates: 'Még nincsenek jelentéssablonok.',
      custom: 'Egyedi sablon',
      defaultWithPoints: 'Szabványsablon - százalékponttal',
      default: 'Szabványsablon – százalékpontok nélkül',
      createTemplateInfo: 'A jelentéssablont csak a létrehozása után szerkesztheti.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Globális engedélycsoport',
    notAllowed: 'Nincs engedélye a sablon engedélyeinek módosítására.',
    all: 'Az űrlapot minden felhasználó kitöltheti',
    groups: 'Az űrlapot csak ezekhez a jogosultsági csoportokhoz tartozó felhasználók tölthetik ki',
    noGroupLicence: 'Villogás Ellenőrizze a csoport licence nincs aktiválva.',
    overridingPermssion: 'A „Rendszerbeállítások” opció a „Sysadmin” engedélycsoportban automatikusan megadja a felhasználóknak az összes engedélyt ehhez az űrlaphoz.',
    onlyLicensedPermissionGroups: 'Csak azok a jogosultsági csoportok jelennek meg, amelyekhez a Blink Check licenc hozzá van rendelve.',
    canUpdateChecklist: 'Űrlapsablon szerkesztése',
    canFillChecklist: 'kitölteni az űrlapot',
    canReadChecklistDataOwn: 'Önkitöltős űrlapok megtekintése és értékelése',
    canReadChecklistDataOwnEmployees: 'Tekintse meg és értékelje az alkalmazottak által kitöltött űrlapokat',
    canReadChecklistDataOwnLocations: 'Tekintse meg és értékelje saját objektumai összes kitöltött űrlapját',
    canReadChecklistDataAll: 'Tekintse meg és értékelje az összes kitöltött űrlapot'
  },
  companyConfig: {
    companyConfig: 'Bérlő konfigurációja',
    logoInfo: 'A szabványos jelentéssablonok fejlécében jelenik meg.',
    footer: 'lábléc',
    footerInfo: 'A szabványos jelentéssablonok láblécében jelenik meg.',
    offline: 'Kérjük, hozzon létre internetkapcsolatot a konfiguráció szerkesztéséhez.'
  },
  switchCompanyDialogHeader: 'Ügyfelet váltani',
  switchCompanyDialogText: 'Ha módosítja az ügyfelet, az összes folyamatban lévő űrlap visszaáll. Akarod folytatni?',
  results: {
    results: 'Értékelés',
    details: 'Részletek',
    created: 'Létrehozva',
    result: 'Eredmény',
    noReportJsonExists: 'A jelentés még nem készült el. A jelentés késéssel készül el, próbálja újra később.',
    noResults: 'Még nincsenek kitöltött űrlapok.',
    noResultsForDateFilter: 'A kiválasztott időszakhoz nincsenek kitöltött űrlapok.',
    periodFrom: 'Időszaka',
    periodTo: 'időszak felfelé',
    location: 'tárgy',
    createUser: 'Teremtő',
    loginUser: 'Alkalmazottak',
    checklist: 'Űrlapsablon',
    user: 'felhasználó',
    recentResults: 'Jelenleg kitöltött űrlapok',
    recent: 'Jelenlegi',
    pointOf: '{{ itemPoints }}/{{ maxItemPoints }} pont',
    checklistCreatedByAt: 'Az űrlapot {{ firstName }} {{ lastName }} töltötte ki, {{ createDate }}, {{ createTime }} délután.',
    linkedTask: 'Kapcsolt jegy: #{{ taskId }} {{ taskTitle }}',
    offline: 'Kérjük, hozzon létre internetkapcsolatot, hogy ki tudja értékelni a kitöltött űrlapokat.',
    delete: {
      title: 'A kitöltött űrlap törlése',
      message: 'Biztosan törölni szeretné a(z) „{{checklistName}}” kitöltött űrlapot?',
      success: 'A kitöltött űrlap eltávolítva'
    },
    editReferenceTimestamp: 'Referencia dátumának szerkesztése',
    editReferenceTimestampDescription: 'A referencia dátuma határozza meg az űrlap kitöltésének időpontját. Ez eltérhet az űrlap benyújtásának időpontjától.'
  },
  dependencies: {
    dependency: 'Függőség',
    addTitle: 'Kérjük, válassza ki az összehasonlítási értéket',
    addMessage: 'Kérjük, adja meg azt az értéket, amelynek teljesítenie kell a tétel megjelenítéséhez.',
    addTitleMulti: 'Kérjük, válassza ki az összehasonlítási értékeket',
    addMessageMulti: 'Kérjük, adja meg az értékeket, amelyek közül az egyiknek teljesülnie kell ahhoz, hogy az elem megjelenjen.',
    present: 'Elérhető',
    notPresent: 'Nem érhető el',
    noResults: 'Nincsenek függőségek. Új függőségek adhatók hozzá a Függőség hozzáadása funkcióval.',
    noAvailableItems: 'Túl kevés elem van az űrlapon függőségek létrehozásához. Kérjük, először adjon hozzá további elemeket.',
    noAvailableItemsInGroup: 'Túl kevés elem van az oldalon a függőségek létrehozásához. Kérjük, válasszon olyan oldalt, amely elemeket tartalmaz.',
    dependencyAdded: 'Függőség hozzáadva.',
    dependencyRemoved: 'A függőség megszűnt.',
    dependencyInfo: 'Ha hozzáad egy függőséget, ez az elem ({{elementName}}) csak akkor jelenik meg, ha a függő elem(ek) kitöltésre kerültek.',
    true: 'Igen',
    false: 'Nem',
    '#not_empty#': 'Elérhető',
    '#empty#': 'Nem érhető el',
    frown: 'Szomorú',
    meh: 'Rendben megy',
    smile: 'Boldog'
  },
  noChecklists: 'Nincsenek nyomtatványok.',
  updating: 'A Blink Check frissítése folyamatban van.',
  installing: '{{version}} verzió telepítése...',
  appName: 'Pislogás ellenőrzése',
  notAvailableInPublicChecklists: ' (Nyilvános űrlapsablonokban nem érhető el)',
  pdfAssignTemplate: 'A PDF-letöltés használatához hozzá kell rendelnie egy jelentéssablont az űrlaphoz.'
};
