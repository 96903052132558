export default {
  home: {
    pageTitle: 'Ana sayfa',
    welcome: 'Blink me uygulamasına hoş geldiniz. Eğlenceli sohbetler,\n\nBlink ekibiniz',
    startChat: 'başlamak',
    ticket: 'bilet',
    timeLicenseMissing: 'Size bir Göz Kırpma Süresi lisansı atanmadı!'
  },
  conversations: { pageTitle: 'Haberler', yourChats: 'Sohbetleriniz', chat: 'Sohbet' },
  settings: {
    settings: 'Ayarlar',
    pushEnabled: 'Anlık bildirimler alın',
    system: 'sistem',
    languageSelection: 'Dil seçimi',
    currentSettings: 'Şuanki ayarlar',
    user: 'kullanıcı',
    appVersion: 'Sürüm (uygulama)',
    apiVersion: 'Sürüm (API)',
    deviceInfo: 'Cihaz sürümü',
    buildVersion: 'sürüm (derleme)',
    helpAndDataPolicy: 'Yardım ve veri koruma',
    text1: 'Sorularınız varsa destek ekibimiz size yardımcı olmaktan mutluluk duyacaktır:',
    phone: 'telefon',
    mail: 'e-posta',
    information: 'bilgi',
    documentation: 'Çevrimiçi belgeler',
    documentationText: "Çevrimiçi belgelerimizde Blink'in tüm önemli alanlarına ilişkin talimatlar ve videolar bulacaksınız.",
    dataPolicy: 'veri koruması',
    support: 'Destek',
    moreActions: 'Daha fazla eylemler',
    protocol: 'protokol',
    showChatUserSecret: 'Sohbeti diğer cihazla paylaşın',
    scanCodeAnotherDevice: 'Aşağıdaki QR kodunu yeni cihazınızda tarayın veya kişisel sohbet kodunuzu yeni cihazınıza girin!',
    yourPersonalCode: 'Kişisel sohbet kodunuz',
    permissions: {
      title: 'bildiri',
      subtitle: 'Sohbet mesajları, göz kırpma bildirimleri ve çıkartmalar için anlık bildirimler almak gereklidir.',
      grantPermission: 'izin ver',
      camera: 'kamera',
      cameraHint: 'Nesne kodu aracılığıyla zaman kaydı için gereklidir.',
      nfc: 'NFC',
      nfcHint: 'NFC etiketi aracılığıyla zaman kaydı için gereklidir.',
      media: 'Kütüphaneden resim ve resim alın',
      mediaHint: 'Saatleriniz ve biletlerinizle ilgili resim ve belgeleri yakalamak için gereklidir.',
      location: 'Konum',
      locationHint: 'Zaman kaydı sırasında konumunuzu kaydetmeniz gerekir.',
      notifications: 'Push bildirimleri',
      notificationsHint: 'Sohbet mesajları, göz kırpma bildirimleri ve çıkartmalar için anlık bildirimler almak gereklidir.',
      locationDisabledOnSystem: 'Konum hizmetleri devre dışı. Lütfen bunu cihazınızın ayarlarından etkinleştirin.'
    }
  },
  connectionState: {
    connecting: 'Bağlamak...',
    connected: 'Birbirine bağlı.',
    disconnecting: 'Bağlantı kesildi...',
    disconnected: 'bağlantı koptu',
    denied: 'Bağlantı kurulamadı.'
  },
  noOneToFind: 'Hiçbiri bulunamadı.',
  nothingToFind: 'Bulunacak hiçbir şey yok.',
  noLocationFound: 'Hiçbir nesne bulunamadı.',
  searchPlaceholder: 'Aramak...',
  employeeSearchPlaceholder: 'Ad soyad',
  typeToSearch: 'Aramak için dokunun...',
  typeToSearchEmployee: 'Sohbet başlatmak için kişinin adını girin',
  createChatWithEmployee: 'Çalışanlar aranıyor',
  createChatWithLocationTeam: 'Arama nesnesi',
  createTeamleaderChat: 'Tüm çalışanlarım',
  yourLanguageSelection: 'Dil seçiminiz:',
  loginInfo: 'Kayıt olmak için lütfen kişisel QR kodunuzu tarayın:',
  updating: 'Blink me güncelleniyor.',
  notice: 'Fark etme',
  importantNotice: 'Önemli Not',
  continue: 'Daha öte',
  intro: {
    slide1: { header: 'Hoş geldin', text: 'Blink me uygulamanızla eğlenin.' },
    slide2: {
      header: 'Sohbet',
      text: 'İş arkadaşlarınızla doğrudan sohbet edin veya ilginç gruplardan en son haberleri öğrenin.'
    },
    slide3: {
      header: 'Biletler',
      text: 'Burada görevlerinizi görebilir ve uygulamalar oluşturabilirsiniz.'
    }
  },
  pleaseWait: 'Lütfen bekleyin...',
  skip: 'Atlamak',
  startAgain: 'Yeniden başlamak',
  lock: {
    createPinHeader: "Kişisel PIN'iniz",
    createPinInfo: 'Verilerinizi korumak için burada Blink me uygulamasını her kullandığınızda girmeniz gereken bir PIN ayarlayabilirsiniz.',
    validatePin: "Lütfen PIN'i tekrar girin.",
    tryAgain: 'PIN eşleşmiyor. Lütfen tekrar deneyin.',
    triedToOften: "Maalesef PIN'iniz eşleşmiyor. İşlemi yeniden başlatmak mı yoksa atlayıp daha sonra mı yapmak istiyorsunuz?",
    enterPinHeader: 'Pin girişi',
    enterPinInfo: "Uygulamanın kilidini açmak için lütfen PIN'inizi girin.",
    remainingTrys: '{{amount}} deneme daha. Daha sonra uygulamaya tekrar giriş yapmanız gerekmektedir.',
    tooManyEnters: 'Maalesef PIN ile kayıt olmak artık mümkün değil. Lütfen Blink ID kartınızla tekrar giriş yapınız.',
    reSignIn: 'Yeni kayıt',
    setpin: 'Uygulamayı kullanırken PIN girişi',
    skip: 'Şimdi bir PIN atamak istemiyor musunuz? Bu işlevi daha sonra da dahil olmak üzere uygulama ayarlarından istediğiniz zaman etkinleştirebilirsiniz.',
    dontUsePin: 'PIN kullanmayın',
    deactivatePinHeader: "PIN'i devre dışı bırak",
    deactivatePinInfo: "Korumayı devre dışı bırakmak için lütfen PIN'inizi girin."
  },
  active: {
    yourTickets: 'Biletleriniz',
    createNewTask: 'Yeni bir bilet oluştur',
    assignee: 'editör',
    location: 'nesne',
    dueDate: 'Vade tarihi',
    createTask: 'Bilet oluştur',
    taskCreated: 'Bilet oluşturuldu.',
    backToTasks: 'Biletlere geri dön',
    createdAt: 'tarihinde oluşturuldu',
    attachments: 'Ekler',
    addFileOrImage: 'Dosya veya fotoğraf ekle',
    addImage: 'fotoğraf ekle',
    comments: 'Yorumlar',
    addComment: 'yorum ekle',
    addCommentInfo: 'Yorum, bu bileti görüntüleyebilen herkes tarafından görülebilir.',
    commentCreated: 'Yorum kaydedildi.',
    showClosed: 'Tamamlanan biletleri görüntüle',
    resetFilter: 'tüm filtreleri sıfırla',
    changeTicketStatusTo: 'Bilet durumunu şu şekilde değiştirin:',
    editTask: 'Bileti düzenle',
    saveTask: 'Bileti kaydet',
    taskSaved: 'Bilet kaydedildi.',
    deleteImage: 'Temiz görüntü',
    deleteImageConfirmation: 'Görüntüyü gerçekten silmek istiyor musunuz?',
    deleteAttachment: 'Eki sil',
    deleteAttachmentConfirmation: 'Bu eki silmek istediğinizden emin misiniz?',
    deleteComment: 'Yorumu sil',
    deleteCommentConfirmation: 'Yorumu gerçekten silmek istiyor musun?',
    changeAssignee: 'Düzenleyiciyi değiştir',
    duplicateTask: 'Yinelenen bilet',
    duplicateTaskConfirmation: 'Bu bileti çoğaltmak istiyor musunuz?',
    taskDuplicated: 'Bilet kopyalandı.',
    deleteTask: 'Bileti sil',
    deleteTaskConfirmation: 'Bu bileti silmek istediğinizden emin misiniz?',
    taskDeleted: 'Bilet silindi.',
    assigneeChanged: 'İşlemci değiştirildi.',
    statusChanged: 'Bilet durumu değiştirildi.',
    startDate: 'başlamak',
    endDate: 'Son',
    doctorVisitDate: 'Doktor ziyaretinin tarihi',
    doctorVisitDescription: 'Doktor ziyaretinin tarihi: {{date}}',
    chooseDate: 'Bir tarih seçin',
    comment: 'Yorum (isteğe bağlı)',
    dateError: 'Bitiş tarihi başlangıç tarihinden önce olamaz',
    employee: 'Çalışanlar',
    pleaseConnectToLoad: 'Biletlerinizi yüklemek için lütfen internet bağlantısı kurun.'
  },
  emptyState: {
    chats: 'Bir sohbet oluşturun!',
    chat: 'İlk mesajı yaz :)',
    tasks: 'Bilet bulunamadı.',
    attachments: 'Ek mevcut değil.',
    comments: 'Yorum yok.',
    vacation: 'Bu yıl için tatil girilmedi'
  },
  formError: {
    min: 'Değer {{value}} değerinden büyük olmalıdır.',
    min2: 'Değer, {{value}} değerinden büyük veya ona eşit olmalıdır.',
    max: 'Değer {{value}} değerinden küçük veya ona eşit olmalıdır.',
    requiredBecause: '{{name}} doldurulduğu için değer gerekli.',
    isRequired: '{{name}} zorunludur.',
    numberInUse: 'Personel numarası halihazırda kullanımda.',
    maxlength: '{{name}}, {{value}} karakterden fazlasını içeremez.',
    faultyBlinkId: 'Yanıp sönen kimlik yanlış'
  },
  ngSelect: {
    typeToSeach: 'Lütfen 3 veya daha fazla harf giriniz.',
    loading: 'veri yükleniyor...',
    notFound: '{{title}} bulunamadı.',
    clearAll: 'Varsayılana sıfırla'
  },
  placeholder: {
    employee: 'Ad, Soyadı, Çalışan Kimliği, Yanıp Sönen Kimliğe göre arama yapın',
    location: 'Ada, numaraya veya nesne kodlarına göre arayın',
    date: 'Bir tarih seçin',
    searchTasks: 'Bilet ara...'
  },
  hasChat: 'Şimdi sohbete başla',
  hasNoChatYet: 'henüz Blink me uygulamasını yüklemedi.',
  noBlinkActiveLicence: 'Lisans yok',
  noBlinkActiveLicenceInfo: 'Şu anda bu uygulama için bir lisansınız yok. Lütfen amirinizle iletişime geçin.',
  noBlinkMeLicence: 'Lisans yok',
  noBlinkMeLicenceInfo: "Bu uygulama sisteminizle kullanılamaz. Lütfen Uygulamadan veya Playstore'dan Blink Time'ı kullanın.",
  noSuitableAppFound: 'Dosyayı açacak uygulama bulunamadı.',
  myChats: 'sohbetlerin',
  hasNoChatsHome: 'Şimdi sohbete başlayın! Tüm meslektaşlarınızla konuşabilir, nesne ve ekip gruplarına katılabilirsiniz.',
  hasNoTicketsHome: 'Açık bilet bulunmamaktadır.',
  myTickets: 'biletlerin',
  myEmployees: 'Çalışanlarım',
  group: 'grup',
  confirmLogoutWithChat: 'Oturumu kapatırsanız sohbet mesajlarınız silinecektir. Devam etmek ister misin?',
  messageNotDecryptable: 'Mesajın şifresi çözülemedi.',
  chat: {
    userAlreadyExists: 'Başka bir cihazda sohbeti zaten kurdunuz. Bu cihazda sohbeti kullanabilmek için cihazları bağlamanız gerekir. Alternatif olarak kurulumu yeniden başlatabilirsiniz ancak sohbet artık diğer cihazda kullanılamaz.',
    linkDevices: 'Cihazları Bağla',
    withoutChat: 'Sohbet etmeden devam et',
    setupAgain: 'Sohbeti tekrar kurun',
    setupAgainText: 'Sohbeti yeniden ayarlarsanız mevcut bireysel sohbetlerdeki tüm mesajlar silinecek ve sohbet artık diğer cihazlarda kullanılamayacak! Devam etmek ister misin?',
    LinkDevicesText: `Cihazları sohbete bağlamak için diğer cihazınızda Ayarlar'ı açın ve "Sohbeti diğer cihazla paylaş"ı seçin.`,
    personalToken: 'Kişisel sohbet kodunuz',
    wrongCode: 'Sağlanan sohbet kodu yanlış.',
    beingPrepared: 'Sohbet hazırlanıyor.',
    twilioAccessDenied: 'Sohbet hizmetiyle bağlantı kurulamadı. Lütfen Blink Desteğiyle iletişime geçin.',
    notificationsDenied: "Cihazınızda/tarayıcınızda bildirimler devre dışı bırakıldı. Bildirim almak için Ayarlar'a gidin ve Yanıp sönme özelliği için bildirimleri etkinleştirin. Daha sonra kişisel ayarlarınızdan bildirimleri etkinleştirebilirsiniz.",
    deleted: 'Sohbet silindi',
    unavailable: 'Sohbet kullanılamıyor'
  },
  protocol: {
    SEND_EMAIL: 'E-posta ile göndermek',
    DELETE: 'Günlüğü sil',
    deleteMessage: 'Günlüğü gerçekten silmek istiyor musunuz? Bu eylem geri alınamaz.',
    Send: 'Göndermek',
    ConfirmMessage: 'Uygulamanın günlüğü artık Blink Desteğine gönderildi.',
    SuccessMessage: 'Günlük başarıyla Blink Desteğine gönderildi.'
  },
  timeTracking: {
    title: 'Zaman takibi',
    start: 'Başlangıç',
    startDescription: "Zaman takibini başlatmak için Başlat'a tıklayın",
    started: 'başladı',
    stop: 'Durdurmak için',
    duration: 'Zaman uzunluğu',
    syncButtonLabel: 'Çevrimdışı kaydedilen yükleme süreleri',
    offlineSyncMessage: 'Kaydedilen süreleri aktarmak için çevrimiçi olmanız gerekir.',
    successfulSync: 'Zamanlar yüklendi',
    scanNowQrCode: 'QR kod',
    scanNowQrCodeDescription: 'Nesnedeki QR kodunu tarayın.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Akıllı telefonunuzu nesnedeki NFC etiketine doğru tutun.',
    nfcDescription: 'NFC ile algılama: Cep telefonunuzu nesne koduna tutun',
    noTimeTrackingMethodAvailableDescription: 'Bu kullanıcı için yapılandırılmış bir zaman izleme yöntemi yok. Lütfen takım liderinizle iletişime geçin.',
    noLocationCode: 'Kod mevcut değil',
    noLocationCodeDescription: 'Nesnede QR kodu veya NFC etiketi yoksa bu seçeneği seçin. Yorum yapmak zorunludur.',
    noLocationCodeConfirm: 'Nesne kodu olmadan başlamak istediğinizden emin misiniz? Durdurmak için bir yorum girmelisiniz.',
    wrongCode: 'Taranan QR kodu bir nesne kodu değil!',
    readNotice: 'Lütfen notları okuyun!',
    warning: 'uyarı',
    lessThanAMinuteTitle: 'Zaman kaydını tamamla',
    lessThanAMinute: '1 dakikadan daha kısa süre önce başladınız. Durmak istediğinden emin misin?',
    continue: 'yakalamaya devam et',
    timeAlert: {
      differenceInMinutesHint: 'Akıllı telefonunuzdaki saat, sunucudaki saatten farklıdır. Fark günlüğe kaydedildi',
      differentTimeZoneHint: 'Akıllı telefonunuzun saat dilimi, sunucunun saat diliminden farklıdır. Fark günlüğe kaydedilir.'
    },
    leaveComment: {
      withCode: 'Nesne/hizmet hakkındaki bilgiler net değildi. Lütfen bize hangi mülkte/hizmette çalıştığınızı söyleyin.',
      withoutCode: 'Başlangıçta veya durdurmada hiçbir nesne kodu yakalanmadı. Lütfen neden bir nesne olmadan başladığınızı/durdurduğunuzu açıklayın.'
    },
    comment: 'Yorum',
    enterComment: 'Zaman takibi hakkında yorum yapın',
    differentQrCode: {
      title: 'Nesne değiştirilsin mi?',
      continueInLocation: '{{locationName}} içinde çalışmaya devam edin.',
      automaticStopInLocation: '{{locationName}} cinsinden zaman kaydı otomatik olarak durdurulacaktır.',
      stopInLocation: "{{locationName}}'da zaman kaydını durdurun.",
      stop: 'Durdurmak için',
      stopNoLocation: 'Zaman kaydını durdurun.',
      continueNoOldLocation: 'Önceki nesneye ilişkin zaman kaydı otomatik olarak durdurulur.',
      continueWorking: 'Çalışmaya devam et',
      continueWorkingInNewLocation: 'Yeni mülkte çalışmaya devam edin.',
      stopWithLocationActivity: '{{locationActivity}} tarihinde zaman kaydını durdurun.',
      continueWithNewLocationActivity: '{{locationActivity}} ile çalışmaya devam edin',
      stopOldLocationActivity: '{{locationActivity}} tarihindeki zaman kaydı otomatik olarak durduruldu.',
      stopOldNoLocationActivity: 'Performanssız zaman kaydı otomatik olarak durdurulur.',
      continueNoLocationActivity: 'Performans olmadan çalışmaya devam edin.',
      sameLocationTitle: 'Performans değiştirilsin mi?',
      differentLocationTitle: 'Nesne değiştirilsin mi?'
    },
    saveAndStop: 'Kaydet ve Durdur',
    noLocationScanned: '- Obje yok -',
    noLocationFound: '- Nesne net değil -',
    timerDifference: '{{h}} saat {{mm}} dakika',
    stopSuccess: 'Zaman başarıyla kaydedildi',
    stopSuccessToast: 'Zaman kaydı başarıyla durduruldu.',
    stopTimeTracking: 'Durdurmak için',
    manual: {
      time: 'Zaman',
      locationSelect: 'Nesne seç',
      loadMore: 'daha fazla yükle',
      trackTime: 'Rekor zamanı',
      location: 'nesne',
      locationActivity: 'Verim',
      date: 'Tarih',
      startTime: 'İtibaren',
      endTime: 'Değin',
      create: 'Onaylamak',
      createAgain: 'Bu nesne için ek süre kaydedin',
      errorUntil: 'Başlangıç zamanı Başlangıç zamanından daha az olmalıdır',
      worktimeDifference: 'Çalışma saatleri:',
      worktimeDifferenceValue: '{{hours}} saat {{minutes}} dakika',
      worktimeDifferenceValueMinutes: '{{minutes}} dakika',
      success: 'Zaman başarıyla kaydedildi',
      break: 'Kırmak:',
      breakMinutes: '{{minutes}} dakika',
      breakHoursAndMinutes: '{{hours}} saat {{minutes}} dakika',
      noActivity: '- performans yok -',
      locationPlaceHolder: 'Ada, numaraya veya nesne kodlarına göre arayın',
      conflictError: 'Belirtilen periyotlarda zaten zaman kaydı var. Örtüşmelere izin verilmez. Lütfen bilgilerinizi kontrol edin.',
      currentDayDuration: 'Zaten kaydedildi',
      expectedDuration: 'Beklenen çalışma saatleri',
      expectedDurationIncludedCurrent: 'akım girişi dahil'
    },
    differenceInMinutesHint: 'Akıllı telefonunuzdaki saat, sunucudaki saatten farklıdır. Fark günlüğe kaydedildi',
    differentTimeZoneHint: 'Akıllı telefonunuzun saat dilimi, sunucunun saat diliminden farklıdır. Fark günlüğe kaydedilir.',
    proposed: {
      capture: 'Esir almak',
      time: 'Zaman',
      captureTime: 'Rekor zamanı',
      button: 'Onaylamak',
      title: 'Çalışma saatlerini onaylayın',
      includingBreak: '(mola dahil)',
      from: 'İtibaren',
      to: 'Değin',
      break: 'Kırmak',
      hour: 'Saat',
      confirm: {
        description: 'Lütfen çalışma saatlerinizi onaylayın',
        confirm: 'Evet bu doğru',
        editButton: 'Hayır, bu doğru değil'
      },
      edit: {
        description: 'Lütfen süreyi belirtin',
        breakIncluded: '{{ duration }} ara dahil',
        comment: 'Yorum',
        yourComment: 'senin yorumun',
        minutes: 'dakika',
        hours: 'Saat'
      },
      reasons: {
        overtime: 'Müşteri nedeniyle fazla mesai',
        faster: 'Daha hızlı tamamlandı',
        representative: 'ben temsilciyim',
        other: 'Çeşitli'
      },
      description: 'Tesiste çalışmayı bitirir bitirmez çalışma saatlerinizi onaylayın.'
    },
    codeNotAssignable: 'Taranan QR kodu atanamıyor!',
    running: 'Zaman kaydı çalışıyor'
  },
  timeFramePicker: {
    title: 'Lütfen bir dönem seçin',
    today: 'Bugün',
    currentWeek: 'Bu hafta',
    currentMonth: 'İçinde bulunduğumuz ay',
    custom: 'Gelenek',
    startDate: 'başlamak',
    endDate: 'Son'
  },
  timeSheet: {
    title: 'Zamanlar ve planlama',
    noPlannings: 'Planlama yok',
    planned: 'Planlanan',
    workingTime: 'çalışma saatleri',
    workloadDuration: 'Haftalık çalışma süresi',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Meli',
    workedDuration: 'Dır-dir',
    hasWorkload: 'O gün çalış',
    pleaseConnectToLoad: 'Sürelerinizi yüklemek için lütfen internete bağlanın.',
    footer: {
      planned: 'Planlanan',
      worklogsTotal: 'Toplam Saat',
      workloadTotal: 'Sözleşmeye göre saatler',
      difference: 'fark'
    },
    homepageTitle: 'Çalışma gününüz'
  },
  planning: {
    includingPause: 'Mola dahil:',
    planRoute: 'Rota planla',
    title: 'planlama',
    modifyMinutes: 'Sabit fiyat:'
  },
  worklog: {
    title: 'Kaydedilen süre',
    absenceTitle: 'yokluk',
    workingTime: 'çalışma saatleri',
    breakDuration: 'Kırmak',
    reduce: 'sabit fiyat',
    attachments: 'Ekler',
    comments: 'Yorumlar',
    comment: 'Yorum',
    noLocation: 'Nesne belirtilmedi',
    changedBy: 'Saat {{firstName}} {{lastName}} olarak değiştirildi.',
    confirmAttachmentDelete: 'Bu eki silmek istediğinizden emin misiniz?',
    attachmentDeleted: 'Ek silindi',
    confirmCommentDelete: 'Bu yorumu silmek istediğinizden emin misiniz?',
    commentDeleted: 'Yorum silindi'
  },
  tabs: {
    home: 'Ev',
    times: 'zamanlar',
    chat: 'Sohbet',
    tickets: 'Biletler',
    more: 'Daha'
  },
  profile: {
    title: 'profil',
    testing: 'Test yapmak',
    testingDescription: 'Bu alan yalnızca bir test sistemine bağlıysanız görünür.',
    testingAllowQrCodeOnWeb: 'QR kodunun manuel girişine izin ver',
    pushNotifications: 'Push bildirimleri',
    reloadUserContext: 'Kullanıcı Bağlamını Yeniden Yükle',
    pushNotificationSuccessful: 'Anında bildirim başarıyla alındı',
    editProfilePicture: 'profil resmini değiştir',
    editProfilePictureTitle: 'Bölüm seç',
    pushNotificationNotice: 'Test, "Kiracı ayarlarını düzenleme" iznini gerektirir.',
    send: 'Göndermek',
    profilePicture: 'Profil fotoğrafı'
  },
  help: { title: 'Yardım' },
  myVacations: {
    vacation: 'Tatil',
    amountAvailableVacationDays: '{{amount}} günlük tatil hâlâ mevcut',
    totalVacationDays: 'Tatil hakkı {{ total }} gün',
    remainingVacationDays: '({{ total }} gün {{ sign }} {{ remaining }} gün kalan tatil {{ previousYear }})',
    xDaysVacations: '{{ total }} gün tatil',
    title: 'Senin tatilin'
  },
  externalLinks: {
    title: 'Dış bağlantılar',
    notice: 'Bu bağlantı şirketiniz tarafından sağlanmıştır. Blink sisteminden ayrılıyorsunuz.'
  },
  stickers: {
    title: 'Çıkartmalarınız',
    sticker: 'Çıkartmalar',
    sender: 'Alıcı',
    text: 'İlişkin',
    date: 'Tarih',
    emptyState: 'Maalesef henüz çıkartmanız yok',
    senderDescription: 'Bu çıkartma size {{sender}} tarafından {{date}} tarihinde gönderildi',
    readed: 'Okumak',
    notReaded: 'henüz okumadım',
    newStickerToaster: 'Yeni Göz Kırpma Çıkartma!',
    open: 'Açık',
    next: 'Daha öte',
    titleCases: {
      case1: '1. çıkartmanız için tebrikler!',
      case2: 'Harika, artık zaten <b>{{count}}</b> çıkartmanız var!',
      case3: '10. çıkartmanızı tebrik ederiz!',
      case4: '30 çıkartma - vay be!',
      case5: 'Artık burası kalabalıklaşıyor: <b>{{count}}</b> Çıkartmalar!'
    },
    confirmDelete: 'Bu çıkartmaları gerçekten silmek istiyor musunuz?'
  },
  autoTranslate: {
    isTranslated: 'Otomatik olarak çevrildi',
    translationError: 'Otomatik çeviri şu anda mümkün değil'
  },
  confirmLogout: 'Gerçekten çıkış yapmak istiyor musunuz?',
  message: 'Haberler',
  migration: {
    wrongVersion: "Blink me'nin mevcut sürümünde, Blink Time uygulaması aracılığıyla oturum açma desteklenmiyor. Lütfen Blink me uygulamasını en son sürüme güncelleyin"
  },
  eLearning: {
    pendingTab: 'Yapılacak ({{value}})',
    completedTab: 'Tamamlandı ({{value}})',
    completed: 'Tamamlanmış',
    pending: 'Açık',
    dueFalling: 'vadesi geçmiş',
    title: 'Uzaktan Eğitim',
    course: 'kurs',
    dueDate: 'Kadar geçerli',
    topics: 'konular',
    completeDate: 'Bitiş tarihi',
    status: { Pending: 'açık', Done: 'tamamlanmış' },
    infos: {
      pendingExists: 'Tüm açık eğitim kurslarınızı burada bulabilirsiniz.',
      finishOverdue: 'Lütfen zamanı gelmiş olan eğitimi mümkün olan en kısa sürede yapın.',
      allCompleted: 'Harika! Tüm eğitim kurslarınızı başarıyla tamamladınız.',
      emptyState: 'Atanmış bir eğitiminiz yok.'
    }
  },
  checklists: {
    title: 'Formlar',
    description: 'Bir nesne için form doldurma',
    button: 'Nesne seç'
  }
};
