import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OmCheckService } from '../../services/om-check/shared/om-check.service';
import { CheckTranslateService } from '@blink/util';
import { CameraService, NetworkService } from '@blink/capacitor-plugins';
import { BlinkIcon, UiDialogService } from '@blink/ui';
import { CompanyRepository } from '@blink/api';

@Component({
  selector: 'check-company-config-checklist',
  templateUrl: './company-config.component.html',
  styleUrls: ['./company-config.component.scss']
})
export class CompanyConfigComponent implements OnInit {
  @ViewChild('fileInput') fileInputObj;
  BlinkIcon = BlinkIcon;
  form: FormGroup;
  companyName: string;

  constructor(private fb: FormBuilder,
              private omCheckService: OmCheckService,
              private uiDialogService: UiDialogService,
              public t: CheckTranslateService,
              private cameraService: CameraService,
              private networkService: NetworkService,
              private companyRepository: CompanyRepository) {
  }

  get isOnline(): boolean {
    return this.networkService.connected;
  }

  ngOnInit() {
    this.companyRepository.selectedCompany$.subscribe(async company => {
      if (company && this.isOnline) {
        this.companyName = company.Name;
        let companyConfig = await this.omCheckService.getCompanyConfig(company.Id);

        if (!companyConfig) {
          companyConfig = {
            Id: 0,
            CompanyId: company.Id,
            ReportLogoBase64Encoded: null,
            ReportFooterText: ''
          };
        }

        this.form = this.fb.group({
          Id: [companyConfig.Id],
          CompanyId: [companyConfig.CompanyId],
          ReportLogoBase64Encoded: [companyConfig.ReportLogoBase64Encoded],
          ReportFooterText: [companyConfig.ReportFooterText]
        });
      }
    });
  }

  uploadImage() {
    this.cameraService.choosePhotoAsBase64({ resize: 250 }).then(imageBase64 => {
      this.form.patchValue({ ReportLogoBase64Encoded: imageBase64 });
    });
  }

  removeLogo() {
    this.form.patchValue({ ReportLogoBase64Encoded: null });
  }

  async save() {
    if (this.form.value.Id) {
      await this.omCheckService.updateCompanyConfig(this.form.value);
    } else {
      await this.omCheckService.createCompanyConfig(this.form.value)
        .then(createdConfig => this.form.patchValue(createdConfig));
    }
    this.uiDialogService.toast(this.t.global.configSaved, "success");
  }
}
