import { Component, forwardRef, Input } from '@angular/core';
import { AbstractInputComponent } from '../abstracts/input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Standardized Toggle with cool features
 * - use true / false values by default
 * - set valueChecked & valueUnchecked for custom values!
 */
@Component({
  selector: 'ui-input-toggle',
  templateUrl: 'ui-input-toggle.component.html',
  styleUrls: ['ui-input-toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiInputToggleComponent),
    multi: true
  }]
})
export class UiInputToggleComponent extends AbstractInputComponent {
  @Input() labelPlacement: 'start' | 'end' | 'fixed' | 'stacked' = 'end';
  @Input() valueChecked: string;
  @Input() valueUnchecked: string;

  get customValues() {
    return this.valueChecked && this.valueChecked
  }

  override get value(): boolean {
    return this.customValues
      ? this._value === this.valueChecked
      : this._value;
  }

  override set value(value: boolean) {
    if (this.customValues) {
      this._value = value ? this.valueChecked : this.valueUnchecked;
    } else {
      this._value = value;
    }
    this.notifyValueChange();
  }
}
