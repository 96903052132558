import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UiFormlyFormFieldModule } from '../../wrappers/form-field/form-field.module';
import { UiFormlyDisplayDescriptionFieldTypeComponent } from './ui-display-description.type';

@NgModule({
  declarations: [UiFormlyDisplayDescriptionFieldTypeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'ui-display-description',
          component: UiFormlyDisplayDescriptionFieldTypeComponent,
          // wrappers: ['ui-form-field']
        }
      ]
    })
  ]
})
export class UiFormlyDisplayDescriptionModule {
}
