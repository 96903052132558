<ui-page title="profile"
         [legacyWrapper]="true"
         [menuButton]="true">
  <h1 class="mt-0">{{ t.global.help.information | translate }}</h1>

  <ion-grid *ngIf="userContext" style="padding: 0;">
    <ion-row>
      <ion-col size="4" size-md="2">{{ t.global.user | translate }}</ion-col>
      <ion-col>{{ userContext.loginUserName }} ({{ userContext.LoginUserId }})</ion-col>
    </ion-row>
    <ion-row *ngIf="system">
      <ion-col size="4" size-md="2">{{ t.global.system | translate }}</ion-col>
      <ion-col>{{ system }}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4" size-md="2">{{ t.global.appVersion | translate }}</ion-col>
      <ion-col>{{ appVersion }}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4" size-md="2" class="pre-wrap">{{ t.global.apiVersionCore | translate }}</ion-col>
      <ion-col>
        <ng-container *ngIf="apiVersion$ | async as apiVersion; else versionSpinner">
          {{ apiVersion.Version }}
        </ng-container>
        <ng-template #versionSpinner>
          <ion-spinner></ion-spinner>
        </ng-template>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4" size-md="2" class="pre-wrap">{{ t.global.apiVersionCheck | translate }}</ion-col>
      <ion-col>
        <ng-container *ngIf="checkApiVersion$ | async as checkApiVersion">
          {{ checkApiVersion.Version }}
        </ng-container>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!isDesktop">
      <ion-col size="4" size-md="2">{{ t.global.deviceInfo | translate }}</ion-col>
      <ion-col>{{ deviceId }}</ion-col>
    </ion-row>
  </ion-grid>

  <h1>{{ t.global.help.documentation | translate }}</h1>
  <ion-row>
    <ion-col>
      <p style="margin-top: 0;">{{ t.global.help.documentationText | translate }}</p>

      <ion-button fill="outline" (click)="openDocumentation()">
        <span class="text-black">{{ t.global.help.documentation | translate }}</span>
      </ion-button>
    </ion-col>
  </ion-row>


  <h1>{{ t.global.help.support | translate }}</h1>
  <ion-grid style="padding: 0;">
    <ion-row class="ion-align-items-center">
      <ion-col size="4" size-md="2">
        <i class="far fa-phone fa-fw"></i>
        {{ t.global.help.phone | translate }}
      </ion-col>
      <ion-col>
        <ion-button href="tel:+4991124033022" fill="clear">0911 240 330 22</ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center">
      <ion-col size="4" size-md="2">
        <i class="far fa-at fa-fw"></i>
        E-Mail
      </ion-col>
      <ion-col>
        <ion-button href="mailto:support@blink.de" fill="clear" style="text-transform: none">support&#64;blink.de
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button class="ion-margin-top"
              (click)="logout()">
    {{ t.global.logout | translate }}
  </ion-button>
</ui-page>
