export default {
  home: {
    pageTitle: 'Formele mele',
    locationChecklists_label: 'Completați un formular pentru un obiect',
    generalChecklists_label: 'Completați formularul general',
    processingChecklists_label: 'Formular în curs',
    checklistDatas_label: 'Formulare completate',
    selectLocation: 'Selectați obiectul',
    scanQrCode: 'Scanați codul obiectului',
    showAll: '... arata tot',
    showLess: '... arata mai putin'
  },
  fill: {
    checklists: 'Forme',
    fillChecklist: 'completează formularul',
    checklistNotFound: 'Din păcate, formularul pe care l-ați solicitat nu a putut fi găsit.',
    checklistOfflineLoginUserRequired: 'Acest formular necesită selectarea unui angajat. Vă rugăm să stabiliți o conexiune la internet pentru a putea apela șablonul de formular.',
    checklistNotOfflineAvailable: 'Șablonul de formular nu este salvat offline, vă rugăm să vă conectați la Internet pentru a accesa șablonul de formular.',
    resetConfirm: 'Chiar vrei să resetați formularul?',
    categoryAppeared: 'Categoria „{{categoryName}}” a fost adăugată.',
    groupAppeared: 'Pagina „{{groupName}}” a fost adăugată.',
    itemAppearedInGroup: 'Au fost adăugate întrebări noi la pagina „{{groupName}}”.',
    openGroupsAvailable: 'OpenGroupsAvailable',
    toNextGroup: 'Trecem la următoarea întrebare',
    containerUriExpired: 'Din păcate, perioada de încărcare a expirat.',
    finishMessage: 'Ați introdus toate datele necesare și acum puteți completa formularul.',
    offlineMessage: 'Vă rugăm să vă conectați la internet pentru a completa formularul.',
    offlinePreviewMessage: 'Vă rugăm să vă conectați la internet pentru a genera o previzualizare.',
    checklistUpdatedHeader: 'Șablonul de formular a fost modificat',
    checklistUpdatedMessage: 'Șablonul formularului a fost modificat. Vă rugăm să verificați din nou intrările înainte de a trimite formularul.',
    checklistLocationRequiredHeader: 'Selectați obiectul',
    checklistLocationRequiredMessage: 'Trebuie să selectați un obiect pentru a completa acest formular.',
    finishSuccess: 'Formularul a fost completat cu succes și toate datele au fost procesate.',
    finishError: 'Formularul nu a putut fi completat din cauza unei erori. Vă rugăm să contactați asistența.',
    createdWith: 'Acest formular a fost creat cu',
    preview: 'previzualizare',
    fileUploadFileMissingHeader: 'Fișierele nu au fost găsite',
    fileUploadFileMissingMessage: 'Următoarele fișiere au fost selectate, dar nu pot fi găsite pe dispozitiv: {{ missingFiles }}'
  },
  item: {
    boolean: { yes: 'da', no: 'Nu' },
    scan: 'scanează',
    email: { invalidEmail: 'Vă rugăm să verificați intrarea dvs.' },
    takePicture: { picture: 'Imagine', buttonText: 'adauga o fotografie' },
    createTask: {
      taskTitle: 'Titlul biletului',
      taskDescription: 'Descrierea biletului',
      taskDueDate: 'Data scadenței biletului'
    }
  },
  noChecklistsForLocationFound: 'Nu există nicio formă pentru acest obiect. Vă rugăm să selectați o altă proprietate sau să completați un formular general.',
  selectChecklistByLocation: 'Selectați formularul pentru obiect'
};
