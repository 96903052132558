import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UiFormlyFieldToggleComponent } from './toggle.type';
import { UiFormlyFormFieldModule } from '../../wrappers/form-field/form-field.module';

@NgModule({
  declarations: [UiFormlyFieldToggleComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'toggle',
          component: UiFormlyFieldToggleComponent
          // wrappers: ['ui-form-field']
        }
      ]
    })
  ]
})
export class UiFormlyToggleModule {
}
