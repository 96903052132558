import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { deleteAllEntities, selectAllEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { ExternalLink } from './model/external-link.model';
import { PersistStateService } from '../shared';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionRepository } from '../core';

const store = createStore(
  { name: 'external-links' },
  withEntities<ExternalLink, 'Id'>({ idKey: 'Id' })
);


@Injectable({ providedIn: 'root' })
export class ExternalLinksRepository {

  activeExternalLinks$ = combineLatest([
    store.pipe(selectAllEntities()),
    this.sessionRepository.userContext$,
  ]).pipe(
    map(([externalLinks, userContext]) => {
      return externalLinks
        .filter(t => t.PermissionGroups.map(t => t.Id).includes(userContext.PermissionGroupId)
          || this.sessionRepository.getUserHasPermissions('CanEditSystemSettings'))
        .sort((a, b) => a.Order - b.Order) as ExternalLink[];
    })
  );

  constructor(private persistStateService: PersistStateService,
              private sessionRepository: SessionRepository) {
    this.persistStateService.persistState(store).subscribe();
  }

  upsert(externalLinks: ExternalLink[]) {
    store.update(upsertEntities(externalLinks));
  }

  reset() {
    store.update(deleteAllEntities());
  }
}
