export default {
  config: {
    checklist: {
      checklist: 'Шаблон за формуляр',
      checklists: 'Шаблони за формуляри',
      none: 'Няма налични формуляри.',
      new: 'Нов шаблон на формуляр',
      edit: 'Редактиране на шаблон на формуляр',
      saved: 'Шаблонът на формуляр е запазен.',
      delete: 'Изтриване на шаблон на формуляр',
      deleteChecklistResults: 'Също така изтрийте всички попълнени формуляри на шаблона на формуляр {{name}}?',
      deleteInfo: 'Ако изтриете шаблона за формуляр, всички попълнени формуляри също ще бъдат изтрити безвъзвратно! <br><br>За да запазите попълнените формуляри, деактивирайте шаблона на формуляр.',
      deleted: 'Шаблонът на формуляр беше изтрит.',
      duplicate: 'Дублиран шаблон на формуляр',
      duplicateInfo: 'Можете да използвате функцията за дублиране, за да дублирате шаблони на формуляри',
      duplicateMessage: 'Искате ли да дублирате шаблона на формуляр „{{checklistName}}“ с цялото съдържание?',
      duplicated: 'Шаблонът на формуляр е дублиран.',
      templateName: 'Име на формуляра',
      activeInfo: 'Могат да се попълват само активни шаблони; неактивните могат да се редактират само в управлението на шаблони.',
      locationRequired: 'Изборът на обект е задължителен',
      locationRequiredInfo: 'Ако е активиран, трябва да се избере обект или да се сканира QR кодът, за да се попълни формулярът.',
      loginUserRequired: 'Подборът на служители е задължителен',
      loginUserRequiredInfo: 'Ако е активиран, трябва да бъде избран служител, който да попълни формуляра.',
      allLocationAllowed: 'Разрешаване на всички обекти',
      allowOnlyCertainLocations: 'Разрешете само определени обекти',
      public: 'Обществена форма',
      publicInfo: 'Създайте публична връзка, с която формулярът може да бъде попълнен от всеки потребител. Не се изисква влизане или приложение. Можете да посочите дата на валидност, до кога връзката трябва да е валидна.',
      preview: 'Активиране на предварителен преглед',
      previewInfo: 'Ако е активиран, бутонът „Преглед“ винаги ще се показва в долната част на формуляра, когато го попълвате. Като алтернатива на постоянния бутон за преглед, можете също да поставите елемента на съдържанието „Преглед“ навсякъде във формуляра (не е необходимо тази функция да бъде активирана).',
      shortUrlExpireDate: 'Срок на годност',
      sysadminOnly: 'Само системни администратори',
      allUsers: 'Всички потребители',
      usersOfPermissionGroup: 'Потребители на тези групи за оторизация',
      emailConfig: 'Конфигурация на имейл',
      emailConfigInfo: 'Ако въведете имейл адреси тук, попълнените формуляри ще бъдат изпратени автоматично на тези имейл адреси.',
      emailSaved: 'Имейл адресът е запазен',
      emailRemoved: 'Имейл адресът е изтрит',
      importExportInfo: 'Функцията за експортиране ви позволява да експортирате шаблони на формуляри и свързани шаблони на отчети, за да ги импортирате повторно в друг клиент или система на Blink Check.',
      import: 'Импортиране на шаблон на формуляр',
      importInfo: 'Моля, изберете файла .check-b64 на шаблона на формуляр, който искате да импортирате.',
      imported: 'Формулярът е създаден успешно.',
      templatesInAppFolder: 'Шаблони в директорията на приложението:',
      invalidImport: 'Избраният файл не е валиден шаблон на формуляр.',
      exported: 'Шаблонът на формуляр е експортиран',
      offline: 'Моля, установете интернет връзка, за да редактирате шаблони на формуляри.',
      checklistContainsCreateTask: 'Шаблонът на формуляр съдържа елемент „Създаване на билет“ и следователно не може да бъде зададен като публичен. Моля, премахнете елемента „Създаване на билет“.'
    },
    category: {
      category: 'категория',
      categories: 'Категории',
      saved: 'Категорията е запазена.',
      editTitle: 'Редактиране на категория',
      content: 'елементи',
      newCategory: 'Нова категория',
      name: 'Име на категория',
      edit: 'Редактиране на категория',
      duplicated: 'Категорията беше дублирана.',
      noCategories: 'Все още няма категории. Създайте нови категории, като използвате бутона „Добавяне на категория“.',
      inactive: 'неактивен',
      showInactive: 'Показване на неактивните',
      duplicateCategoryConfirmHeader: 'Дублирана категория',
      duplicateCategoryConfirm: 'Искате ли да дублирате категорията „{{categoryName}}“ с цялото й съдържание?',
      delete: 'Изтриване на категория',
      deleteCategoryConfirm: 'Сигурни ли сте, че искате да изтриете категорията „{{categoryName}}“?',
      deleteSuccess: 'Категорията е премахната.'
    },
    group: {
      group: 'Страница',
      groups: 'страници',
      created: 'Страницата е създадена.',
      updated: 'Промените в страницата са запазени.',
      updateError: 'Страницата не можа да бъде запазена. Моля, опитайте отново по-късно.',
      rename: 'Преименуване на страницата',
      saved: 'Страницата е запазена.',
      toggleSort: 'Промяна на сортирането',
      editGroupName: 'Редактиране на името на страницата',
      new: 'Нова страница',
      name: 'Име на страницата',
      duplicated: 'Страницата беше дублирана.',
      noGroups: 'Все още няма страници. Създайте нови страници, като използвате бутона „Добавяне на страница“.',
      noItems: 'Все още няма елементи. Създайте нови елементи, като използвате бутона „Добавяне на елемент“.',
      add: 'Добавяне на страница',
      infoElement: 'Информационен елемент',
      inputElement: 'Входен елемент',
      addInfoElement: 'Добавете информационен елемент',
      addInputElement: 'Добавете входен елемент',
      delete: 'Изтриване на страница',
      deleteGroupConfirm: 'Сигурни ли сте, че искате да изтриете страницата „{{groupName}}“?',
      chooseGroup: 'Към коя страница трябва да се добави елементът?',
      duplicateGroupConfirmHeader: 'Дублирана страница',
      duplicateGroupConfirm: 'Искате ли да дублирате страницата „{{groupName}}“ с цялото съдържание?',
      deleteSuccess: 'Страницата е премахната.'
    },
    item: {
      add: 'Добавете елемент',
      created: 'Артикулът е създаден',
      saved: 'Артикулът е запазен.',
      chooseItemType: 'Избери предмет',
      values: 'Стойности',
      value: 'Стойност',
      ratingNumber: 'Оценка',
      addItemValue: 'Добавете нова стойност',
      addItemValueSuccess: 'Добавена е нова стойност',
      updateItemValueSuccess: 'Стойността е актуализирана',
      removeItemValueSuccess: 'Стойността е премахната',
      removeItemValue: 'Премахване на стойност',
      takePictureButtonText: 'Изберете изображение',
      editTitle: 'Редактиране на елемент',
      editDependenciesTitle: 'Редактиране на зависимости',
      required: 'Задължително поле',
      name: 'Име на елемента',
      description: 'незадължително описание на елемента',
      points: 'Точки',
      duplicated: 'Елементът беше дублиран.',
      duplicateItemConfirmHeader: 'Дублиран елемент',
      duplicateItemConfirm: 'Искате ли да дублирате елемента „{{itemName}}“ с цялото му съдържание?',
      delete: {
        title: 'Изтриване на елемент',
        message: 'Наистина ли искате да изтриете елемента „{{itemName}}“?',
        success: 'Елементът е премахнат'
      },
      moveItem: 'Преместване на елемент',
      move: 'Ход',
      moveItemInfo: 'Моля, изберете категорията и страницата, към която да бъде преместен текущият елемент.',
      itemMoved: 'Елементът е преместен'
    },
    editItem: {
      name: 'Фамилия',
      nameInfo: 'Този текст се показва директно преди входния елемент.',
      link: 'Адрес на връзката',
      linkInfo: 'Този адрес ще бъде достъпен чрез бутон. Моля, въведете валиден URL.',
      description: 'Описание',
      descriptionInfo: 'Ако искате да предоставите инструкции за попълване на формуляра, можете да ги въведете тук.',
      linkDescription: 'Етикет на бутона',
      linkDescriptionInfo: 'Въведете име за бутона.',
      values: 'Стойности',
      valuesInfo: 'Можете да зададете рейтингово число за всяка стойност. Можете да извършите оценки по-късно, като използвате номера на рейтинга. Номерът на оценката не е задължителен.',
      value: 'Стойност',
      valuePlaceholder: 'Нова стойност',
      ratingNumber: 'Оценка',
      requiredField: 'Задължително поле',
      requiredFieldInfo: 'Ако е активирано като задължително поле, това поле трябва да бъде попълнено, за да може формулярът да бъде изпратен.',
      externalNo: 'Външен номер',
      externalNoInfo: 'Незадължителна информация за интерфейси или оценки чрез външни системи.',
      editable: 'Може да се редактира',
      editableInfo: 'За да могат потребителите да правят записи, елементът трябва да може да се редактира.',
      defaultValue: 'Посочете стойността по подразбиране',
      defaultValueInfo: 'Можете да зададете стандартно присвояване на този елемент.',
      defaultValueInfoEditable: 'Стойността може да се променя от потребителя на формата.',
      visible: 'видимост',
      visibleInfo: 'Видимостта контролира дали елементът може да бъде видян от редактора или не.',
      externalNoAleradyUsed: 'Този външен номер вече се използва.',
      taskTypeId: 'Тип билет',
      loginUserId: 'потребител',
      locationId: 'Име на обект',
      videoId: 'Видео номер',
      videoType: 'Видео услуга',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Въведете уникалния идентификатор на видеоклипа тук. Можете да намерите това например в URL адреса на видеоклипа. <br/>Пример: https://www.youtube.com/watch?v=XXXXX или във Vimeo https://vimeo.com/XXXXX. <br/>В този случай в полето трябва да се въведе XXXXX.',
      defaultTaskTitle: 'Заглавие по подразбиране на билета',
      defaultTaskDescription: 'Описание по подразбиране на билета',
      titleEditable: 'Заглавието на билета може да се редактира от потребителя',
      descriptionEditable: 'Описанието на билета може да се редактира от потребителя',
      dueDateEditable: 'Крайната дата на билета може да се редактира от потребителя',
      addReportAttachment: 'Прикачете попълнения формуляр към билета като PDF',
      noTaskTypesFound: 'Не бяха намерени типове билети. Типовете билети могат да се управляват чрез Blink Active.',
      noLoginUsersFound: 'Не могат да бъдат намерени потребители. Потребителите могат да се управляват чрез Blink Manager.',
      noLocationsFound: 'Не могат да бъдат намерени обекти. Обектите могат да се управляват чрез Blink Manager.',
      createTaskItemInfo: 'С тази функция можете да създавате Blink Active Tickets. Можете да посочите заглавието и описанието или да накарате потребителя да ги попълни. Обектът се присвоява автоматично и отговорният мениджър на обекти се присвоява като процесор.',
      duplicatedValuesNotAllowed: 'Не се допускат дублиращи се стойности.',
      valueLengthExceeded: 'Въведената стойност е твърде дълга и ще бъде съкратена.'
    },
    itemTypes: {
      PreviewButton: 'Визуализация на документа',
      Select: 'Избор от списък',
      Signature: 'Подпис',
      Email: 'Въвеждане на имейл',
      SendEmailTo: 'Доставка по имейл',
      Date: 'Дата',
      Time: 'време',
      Boolean: 'Да-не въпрос',
      TakePicture: 'снимам',
      TakeMultiPicture: 'направи снимки',
      Scan: 'Qr/EAN сканиране',
      Input: 'Въвеждане на текст',
      Textarea: 'Многоредово въвеждане на текст',
      Link: 'Свързване',
      Picture: 'Дисплей на изображението',
      Video: 'Видео реклама',
      Text: 'Текстов дисплей',
      Header: 'заглавие',
      Rate: 'Оценка',
      RateSmiley: 'Оценка на усмивки',
      CreateTask: 'Създайте билет',
      FileUpload: 'Качване на файлове',
      Currency: 'валута'
    },
    itemTypeDescriptions: {
      PreviewButton: 'Вижте предварителна версия на формуляра.',
      Select: 'Дефинирайте всички елементи от списъка, от които можете да изберете един.',
      Signature: 'Поле за цифров подпис (може да се използва с пръст)',
      Email: 'Потребителят може да предостави валиден имейл адрес.',
      SendEmailTo: 'Потребителят може да посочи валиден имейл адрес, на който ще бъде изпратен попълненият формуляр като PDF.',
      Date: 'Потребителят може да посочи дата чрез календар',
      Time: 'Потребителят може да посочи час',
      Boolean: 'Потребителят избира да или не',
      TakePicture: 'Потребителят може да направи снимка с камерата или да качи изображение.',
      TakeMultiPicture: 'Потребителят може да прави няколко снимки с камерата или да качва изображения.',
      Scan: 'Изискване от потребителите да сканират QR код или EAN. Резултатът се показва във формата и се запазва.',
      Input: 'Едноредово поле за въвеждане на текст',
      Textarea: 'Многоредово въвеждане на текст за по-дълги коментари',
      Link: 'Връзка към външен сайт',
      Picture: 'Качете изображение, което ще се показва при попълване.',
      Video: 'Качете видеоклип, който ще се показва, докато попълвате формуляра.',
      Text: 'Добавяне на форматиране към многоредови текстове.',
      Header: 'Голямо заглавие за структуриране на съдържанието',
      Rate: '5-звезден рейтинг',
      RateSmiley: 'Избор от 3 усмихнати лица (щастливо, неутрално, тъжно)',
      CreateTask: 'Създава се билет за потребител',
      FileUpload: 'Потребителят може да прикачи произволен брой файлове към формуляра.',
      Currency: 'Потребителят може да посочи сума във валута.'
    },
    template: {
      template: 'Шаблон за отчет',
      templates: 'Шаблони за отчети',
      name: 'Фамилия',
      description: 'Описание',
      customer: 'клиент',
      new: 'Нов шаблон за отчет',
      edit: 'Редактиране на шаблон на отчет',
      create: 'Създаване на шаблон за отчет',
      public: 'Обществен',
      active: 'Активен',
      deleteConfirm: 'Сигурни ли сте, че искате да изтриете шаблона за отчет „{{templateName}}“?',
      deleteSuccess: 'Шаблонът за отчет е премахнат',
      editor: 'Дизайнер на шаблони',
      notForMobile: 'Дизайнерът на шаблони не е оптимизиран за мобилни устройства. Моля, използвайте Blink Check в браузъра за това.',
      noTemplates: 'Все още няма шаблони за отчети.',
      custom: 'Индивидуален шаблон',
      defaultWithPoints: 'Стандартен шаблон - с процентни точки',
      default: 'Стандартен образец - без проценти',
      createTemplateInfo: 'Можете да редактирате шаблона за отчет само след като е създаден.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Глобална група разрешения',
    notAllowed: 'Нямате разрешение да променяте разрешенията на този шаблон.',
    all: 'Формулярът може да бъде попълнен от всички потребители',
    groups: 'Формулярът може да бъде попълнен само от потребители от тези групи за оторизация',
    noGroupLicence: 'Лицензът Blink Check за тази група не е активиран.',
    overridingPermssion: 'Опцията „Системни настройки“ в групата с разрешения „Sysadmin“ автоматично дава на потребителите всички разрешения за този формуляр.',
    onlyLicensedPermissionGroups: 'Показват се само групи за упълномощаване, на които е присвоен лиценз за Blink Check.',
    canUpdateChecklist: 'Редактиране на шаблон на формуляр',
    canFillChecklist: 'попълнете формуляра',
    canReadChecklistDataOwn: 'Вижте и оценете самостоятелно попълнените формуляри',
    canReadChecklistDataOwnEmployees: 'Вижте и оценете всички попълнени формуляри от вашите служители',
    canReadChecklistDataOwnLocations: 'Вижте и оценете всички попълнени формуляри на вашите собствени обекти',
    canReadChecklistDataAll: 'Вижте и оценете всички попълнени формуляри'
  },
  companyConfig: {
    companyConfig: 'Конфигурация на наемател',
    logoInfo: 'Показва се в заглавката на стандартните шаблони за отчети.',
    footer: 'долен колонтитул',
    footerInfo: 'Показва се в долния колонтитул в стандартните шаблони за отчети.',
    offline: 'Моля, установете интернет връзка, за да можете да редактирате конфигурацията.'
  },
  switchCompanyDialogHeader: 'Сменете клиентите',
  switchCompanyDialogText: 'Ако промените клиента, всички формуляри в ход ще бъдат нулирани. Искаш ли да продължиш?',
  results: {
    results: 'Оценка',
    details: 'Подробности',
    created: 'Създаден',
    result: 'Резултат',
    noReportJsonExists: 'Докладът все още не е създаден. Отчетът ще бъде генериран със закъснение, моля, опитайте отново по-късно.',
    noResults: 'Все още няма попълнени формуляри.',
    noResultsForDateFilter: 'Няма попълнени формуляри за избрания период.',
    periodFrom: 'Период от',
    periodTo: 'период нагоре',
    location: 'обект',
    createUser: 'Създател',
    loginUser: 'служители',
    checklist: 'Шаблон за формуляр',
    user: 'потребител',
    recentResults: 'Текущо попълнени формуляри',
    recent: 'Текущ',
    pointOf: '{{ itemPoints }} от {{ maxItemPoints }} точки',
    checklistCreatedByAt: 'Формулярът е попълнен от {{ firstName }} {{ lastName }} на {{ createDate }} в {{ createTime }} следобед.',
    linkedTask: 'Свързан билет: #{{ taskId }} {{ taskTitle }}',
    offline: 'Моля, установете интернет връзка, за да можете да оцените попълнените формуляри.',
    delete: {
      title: 'Изтриване на попълнен формуляр',
      message: 'Наистина ли искате да изтриете попълнения формуляр „{{checklistName}}“?',
      success: 'Попълненият формуляр е премахнат'
    },
    editReferenceTimestamp: 'Редактиране на референтна дата',
    editReferenceTimestampDescription: 'Референтната дата определя времето, в което формулярът е бил попълнен. Това може да се различава от времето, в което е изпратен формулярът.'
  },
  dependencies: {
    dependency: 'Зависимост',
    addTitle: 'Моля, изберете стойност за сравнение',
    addMessage: 'Моля, посочете стойността, която трябва да бъде изпълнена, за да бъде показан артикулът.',
    addTitleMulti: 'Моля, изберете сравнителни стойности',
    addMessageMulti: 'Моля, посочете стойностите, една от които трябва да бъде изпълнена, за да се покаже артикулът.',
    present: 'На разположение',
    notPresent: 'Недостъпен',
    noResults: 'Няма налични зависимости. Нови зависимости могат да се добавят чрез Добавяне на зависимост.',
    noAvailableItems: 'Във формуляра има твърде малко елементи, за да се създадат зависимости. Моля, първо добавете още елементи.',
    noAvailableItemsInGroup: 'На страницата има твърде малко елементи, за да се създадат зависимости. Моля, изберете страница, която съдържа елементи.',
    dependencyAdded: 'Беше добавена зависимост.',
    dependencyRemoved: 'Зависимостта е премахната.',
    dependencyInfo: 'След като добавите зависимост, този елемент ({{elementName}}) ще се появи само ако зависимите елементи са били попълнени.',
    true: 'да',
    false: 'Не',
    '#not_empty#': 'На разположение',
    '#empty#': 'Недостъпен',
    frown: 'тъжно',
    meh: 'Всичко върви добре',
    smile: 'Щастлив'
  },
  noChecklists: 'Няма налични формуляри.',
  updating: 'Blink Check се актуализира.',
  installing: 'Инсталира се версия {{version}}...',
  appName: 'Проверка на мигане',
  notAvailableInPublicChecklists: ' (Не се предлага в публични шаблони на формуляри)',
  pdfAssignTemplate: 'Трябва да присвоите шаблон на отчет към формуляра, преди да можете да използвате изтеглянето на PDF.'
};
