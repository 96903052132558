import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UiFormlyFormFieldModule } from '../../wrappers/form-field/form-field.module';
import { UiFormlyFieldTextAreaComponent } from './textarea.type';

@NgModule({
  declarations: [UiFormlyFieldTextAreaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    UiFormlyFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'textarea',
          component: UiFormlyFieldTextAreaComponent,
          wrappers: ['ui-form-field']
        }
      ]
    })
  ]
})
export class UiFormlyTextAreaModule {
}
