export default {
  home: {
    pageTitle: 'صفحه نخست',
    welcome: 'به برنامه Blink me خوش آمدید. از چت کردن لذت ببرید،\n\nتیم Blink شما',
    startChat: 'شروع',
    ticket: 'بلیط',
    timeLicenseMissing: 'مجوز Blink Time به شما اختصاص داده نشده است!'
  },
  conversations: { pageTitle: 'اخبار', yourChats: 'چت های شما', chat: 'چت کنید' },
  settings: {
    settings: 'تنظیمات',
    pushEnabled: 'دریافت اعلان های فشار',
    system: 'سیستم',
    languageSelection: 'انتخاب زبان',
    currentSettings: 'تنظیمات فعلی',
    user: 'کاربر',
    appVersion: 'نسخه (برنامه)',
    apiVersion: 'نسخه (API)',
    deviceInfo: 'نسخه دستگاه',
    buildVersion: 'نسخه (ساخت)',
    helpAndDataPolicy: 'راهنما و حفاظت از داده ها',
    text1: 'تیم پشتیبانی ما در صورت داشتن هرگونه سوال به شما کمک خواهد کرد:',
    phone: 'تلفن',
    mail: 'پست الکترونیک',
    information: 'اطلاعات',
    documentation: 'مستندات آنلاین',
    documentationText: 'در مستندات آنلاین ما، دستورالعمل ها و ویدئوهایی را برای همه بخش های مهم Blink پیدا خواهید کرد.',
    dataPolicy: 'حفاظت از داده ها',
    support: 'حمایت کردن',
    moreActions: 'اقدامات بعدی',
    protocol: 'پروتکل',
    showChatUserSecret: 'چت را با دستگاه دیگر به اشتراک بگذارید',
    scanCodeAnotherDevice: 'کد QR زیر را در دستگاه جدید خود اسکن کنید یا کد چت شخصی خود را در دستگاه جدید وارد کنید!',
    yourPersonalCode: 'کد چت شخصی شما',
    permissions: {
      title: 'اطلاع',
      subtitle: 'برای دریافت اعلان های فشار برای پیام های چت، اعلان های چشمک زدن و استیکرها ضروری است.',
      grantPermission: 'اجازه دادن',
      camera: 'دوربین',
      cameraHint: 'برای ثبت زمان از طریق کد شی ضروری است.',
      nfc: 'NFC',
      nfcHint: 'برای ضبط زمان از طریق تگ NFC ضروری است.',
      media: 'عکس و تصویر را از کتابخانه بگیرید',
      mediaHint: 'برای ثبت تصاویر و اسناد مربوط به زمان و بلیط شما ضروری است.',
      location: 'محل',
      locationHint: 'لازم است موقعیت مکانی خود را در زمان ضبط ضبط کنید.',
      notifications: 'اعلان های فشاری',
      notificationsHint: 'برای دریافت اعلان های فشار برای پیام های چت، اعلان های چشمک زدن و استیکرها ضروری است.',
      locationDisabledOnSystem: 'خدمات مکان غیرفعال است. لطفاً این را در تنظیمات دستگاه خود فعال کنید.'
    }
  },
  connectionState: {
    connecting: 'اتصال...',
    connected: 'به هم گره خورده.',
    disconnecting: 'اتصال قطع شده است ...',
    disconnected: 'اتصال قطع شد',
    denied: 'اتصال برقرار نشد.'
  },
  noOneToFind: 'هیچ کدام پیدا نمی شود',
  nothingToFind: 'چیزی پیدا نمی شود.',
  noLocationFound: 'هیچ شیئی یافت نشد',
  searchPlaceholder: 'به دنبال ...',
  employeeSearchPlaceholder: 'نام اول نام',
  typeToSearch: 'برای جستجو ضربه بزنید...',
  typeToSearchEmployee: 'برای شروع چت، نام شخص را وارد کنید',
  createChatWithEmployee: 'به دنبال کارمندان',
  createChatWithLocationTeam: 'شی جستجو',
  createTeamleaderChat: 'همه کارمندانم',
  yourLanguageSelection: 'انتخاب زبان شما:',
  loginInfo: 'برای ثبت نام لطفا کد QR شخصی خود را اسکن کنید:',
  updating: 'Blink me در حال به روز رسانی است.',
  notice: 'اطلاع',
  importantNotice: 'یادداشت مهم',
  continue: 'به علاوه',
  intro: {
    slide1: { header: 'خوش آمدی', text: 'با برنامه Blink me خود لذت ببرید.' },
    slide2: {
      header: 'چت کنید',
      text: 'مستقیماً با همکاران خود چت کنید یا از آخرین اخبار گروه های جالب مطلع شوید.'
    },
    slide3: {
      header: 'بلیط',
      text: 'در اینجا می توانید وظایف خود را ببینید و برنامه های کاربردی ایجاد کنید.'
    }
  },
  pleaseWait: 'لطفا صبر کنید...',
  skip: 'پرش کنید',
  startAgain: 'از نو شروع کن',
  lock: {
    createPinHeader: 'پین شخصی شما',
    createPinInfo: 'برای محافظت از داده های خود، می توانید یک پین را در اینجا تنظیم کنید که هر بار که از برنامه Blink me استفاده می کنید باید آن را وارد کنید.',
    validatePin: 'لطفاً دوباره پین را وارد کنید.',
    tryAgain: 'پین مطابقت ندارد. لطفا دوباره آن را امتحان کنید.',
    triedToOften: 'متأسفانه پین شما مطابقت ندارد. آیا می خواهید فرآیند را مجدداً راه اندازی کنید یا از آن رد شوید و بعداً آن را انجام دهید؟',
    enterPinHeader: 'پین ورودی',
    enterPinInfo: 'لطفاً پین خود را وارد کنید تا قفل برنامه باز شود.',
    remainingTrys: '{{amount}} تلاش بیشتر. سپس باید دوباره وارد برنامه شوید.',
    tooManyEnters: 'متأسفانه ثبت نام با پین دیگر امکان پذیر نیست. لطفاً با کارت شناسایی Blink خود دوباره وارد شوید.',
    reSignIn: 'ثبت نام جدید',
    setpin: 'وارد کردن پین هنگام استفاده از برنامه',
    skip: 'آیا نمی خواهید اکنون یک پین اختصاص دهید؟ شما می توانید این عملکرد را در هر زمان از طریق تنظیمات برنامه فعال کنید، از جمله موارد بعدی.',
    dontUsePin: 'از پین استفاده نکنید',
    deactivatePinHeader: 'غیرفعال کردن پین',
    deactivatePinInfo: 'لطفاً پین خود را وارد کنید تا حفاظت را غیرفعال کنید.'
  },
  active: {
    yourTickets: 'بلیط های شما',
    createNewTask: 'یک بلیط جدید ایجاد کنید',
    assignee: 'ویرایشگر',
    location: 'هدف - شی',
    dueDate: 'سررسید',
    createTask: 'بلیط ایجاد کنید',
    taskCreated: 'بلیط ایجاد شده است.',
    backToTasks: 'بازگشت به بلیط',
    createdAt: 'ایجاد شده در',
    attachments: 'پیوست ها',
    addFileOrImage: 'فایل یا عکس اضافه کنید',
    addImage: 'یک تصویر اضافه کنید',
    comments: 'نظرات',
    addComment: 'اضافه کردن نظر',
    addCommentInfo: 'نظر برای هر کسی که بتواند این بلیط را ببیند قابل مشاهده است.',
    commentCreated: 'نظر ذخیره شده است.',
    showClosed: 'مشاهده بلیط های تکمیل شده',
    resetFilter: 'همه فیلترها را بازنشانی کنید',
    changeTicketStatusTo: 'تغییر وضعیت بلیط به:',
    editTask: 'ویرایش بلیط',
    saveTask: 'بلیط را ذخیره کنید',
    taskSaved: 'بلیط ذخیره شده است.',
    deleteImage: 'تصویر پاک کنید',
    deleteImageConfirmation: 'آیا واقعاً می خواهید تصویر را حذف کنید؟',
    deleteAttachment: 'حذف پیوست',
    deleteAttachmentConfirmation: 'آیا مطمئن هستید که می خواهید این پیوست را حذف کنید؟',
    deleteComment: 'حذف نظر',
    deleteCommentConfirmation: 'آیا واقعاً می خواهید کامنت را حذف کنید؟',
    changeAssignee: 'ویرایشگر را تغییر دهید',
    duplicateTask: 'بلیط تکراری',
    duplicateTaskConfirmation: 'آیا می خواهید این بلیط را کپی کنید؟',
    taskDuplicated: 'بلیط تکراری بود',
    deleteTask: 'حذف بلیط',
    deleteTaskConfirmation: 'آیا مطمئن هستید که می خواهید این بلیط را حذف کنید؟',
    taskDeleted: 'بلیط حذف شده است.',
    assigneeChanged: 'پردازنده عوض شده',
    statusChanged: 'وضعیت بلیط تغییر کرده است.',
    startDate: 'شروع',
    endDate: 'پایان',
    doctorVisitDate: 'تاریخ ویزیت دکتر',
    doctorVisitDescription: 'تاریخ ویزیت پزشک: {{date}}',
    chooseDate: 'تاریخ را انتخاب کنید',
    comment: 'نظر (اختیاری)',
    dateError: 'تاریخ پایان نمی تواند زودتر از تاریخ شروع باشد',
    employee: 'کارمندان',
    pleaseConnectToLoad: 'لطفاً برای بارگیری بلیط های خود یک اتصال اینترنتی برقرار کنید.'
  },
  emptyState: {
    chats: 'ایجاد یک چت!',
    chat: 'اولین پیام رو بنویس :)',
    tasks: 'هیچ بلیطی پیدا نشد',
    attachments: 'هیچ پیوستی موجود نیست.',
    comments: 'هیچ نظری در دسترس نیست.',
    vacation: 'هیچ تعطیلی برای امسال وارد نشده است'
  },
  formError: {
    min: 'مقدار باید بیشتر از {{value}} باشد.',
    min2: 'مقدار باید بزرگتر یا مساوی با {{value}} باشد.',
    max: 'مقدار باید کمتر یا مساوی با {{value}} باشد.',
    requiredBecause: 'مقدار لازم است زیرا {{name}} پر شده است.',
    isRequired: '{{name}} اجباری است.',
    numberInUse: 'شماره پرسنل در حال حاضر در حال استفاده است.',
    maxlength: '{{name}} نمی تواند بیش از {{value}} نویسه داشته باشد.',
    faultyBlinkId: 'شناسه چشمک زدن نادرست است'
  },
  ngSelect: {
    typeToSeach: 'لطفا 3 حرف یا بیشتر وارد کنید.',
    loading: 'در حال بارگیری داده ها...',
    notFound: 'هیچ {{title}} یافت نشد.',
    clearAll: 'تنظیم مجدد به حالت پیش فرض'
  },
  placeholder: {
    employee: 'جستجو بر اساس نام و نام خانوادگی، شماره پرسنل، شناسه چشمک زدن',
    location: 'جستجو بر اساس نام، شماره یا کد شی',
    date: 'تاریخ را انتخاب کنید',
    searchTasks: 'جستجوی بلیط ...'
  },
  hasChat: 'اکنون چت را شروع کنید',
  hasNoChatYet: 'متأسفانه من هنوز برنامه Blink me را نصب نکرده ام.',
  noBlinkActiveLicence: 'بدون مجوز',
  noBlinkActiveLicenceInfo: 'شما در حال حاضر مجوزی برای این برنامه ندارید. لطفا با سرپرست خود تماس بگیرید.',
  noBlinkMeLicence: 'بدون مجوز',
  noBlinkMeLicenceInfo: 'این برنامه با سیستم شما قابل استفاده نیست. لطفاً از Blink Time از برنامه یا Playstore استفاده کنید.',
  noSuitableAppFound: 'هیچ برنامه ای برای باز کردن فایل پیدا نشد.',
  myChats: 'چت های شما',
  hasNoChatsHome: 'اکنون چت را شروع کنید! می توانید با همه همکاران صحبت کنید و در گروه های شی و تیم شرکت کنید.',
  hasNoTicketsHome: 'هیچ بلیط باز موجود نیست.',
  myTickets: 'بلیط های شما',
  myEmployees: 'کارمندان من',
  group: 'گروه',
  confirmLogoutWithChat: 'اگر از سیستم خارج شوید، پیام های چت شما حذف خواهد شد. آیا می خواهید ادامه دهید؟',
  messageNotDecryptable: 'پیام رمزگشایی نشد.',
  chat: {
    userAlreadyExists: 'شما قبلاً چت را در دستگاه دیگری تنظیم کرده اید. برای اینکه بتوانید از چت در این دستگاه استفاده کنید، باید دستگاه ها را پیوند دهید. از طرف دیگر، می‌توانید راه‌اندازی را مجدداً راه‌اندازی کنید، اما دیگر نمی‌توان از گپ در دستگاه دیگر استفاده کرد.',
    linkDevices: 'دستگاه ها را پیوند دهید',
    withoutChat: 'بدون چت ادامه دهید',
    setupAgain: 'بازنشانی چت',
    setupAgainText: 'اگر دوباره چت را راه‌اندازی کنید، همه پیام‌های چت‌های فردی موجود حذف می‌شوند و دیگر نمی‌توان از گپ در دستگاه‌های دیگر استفاده کرد! آیا می خواهید ادامه دهید؟',
    LinkDevicesText: 'برای پیوند دادن دستگاه‌ها برای گپ، تنظیمات را در دستگاه دیگر خود باز کنید و «اشتراک‌گذاری گپ با دستگاه دیگر» را انتخاب کنید.',
    personalToken: 'کد چت شخصی شما',
    wrongCode: 'کد چت ارائه شده نادرست است.',
    beingPrepared: 'چت در حال آماده شدن است.',
    twilioAccessDenied: 'اتصال به سرویس چت برقرار نشد. لطفا با پشتیبانی Blink تماس بگیرید.',
    notificationsDenied: 'اعلان‌ها در دستگاه/مرورگر شما غیرفعال است. به تنظیمات بروید و اعلان‌ها را برای Blink me فعال کنید تا اعلان‌ها را دریافت کند. سپس می توانید اعلان ها را در تنظیمات شخصی خود فعال کنید.',
    deleted: 'چت حذف شده است',
    unavailable: 'چت در دسترس نیست'
  },
  protocol: {
    SEND_EMAIL: 'ارسال از طریق ایمیل',
    DELETE: 'پاک کردن گزارش',
    deleteMessage: 'آیا واقعاً می خواهید گزارش را حذف کنید؟ این عمل قابل لغو نیست.',
    Send: 'ارسال',
    ConfirmMessage: 'گزارش برنامه اکنون به پشتیبانی Blink ارسال خواهد شد.',
    SuccessMessage: 'گزارش با موفقیت به پشتیبانی Blink ارسال شد.'
  },
  timeTracking: {
    title: 'ردیابی زمان',
    start: 'شروع کنید',
    startDescription: 'برای شروع ردیابی زمان روی start کلیک کنید',
    started: 'آغاز شده',
    stop: 'برای متوقف کردن',
    duration: 'طول زمان',
    syncButtonLabel: 'زمان های آپلود به صورت آفلاین ثبت شده است',
    offlineSyncMessage: 'برای انتقال زمان های ذخیره شده باید آنلاین باشید.',
    successfulSync: 'زمان ها آپلود می شوند',
    scanNowQrCode: 'کد QR',
    scanNowQrCodeDescription: 'کد QR موجود در شی را اسکن کنید.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'تلفن هوشمند خود را در برابر برچسب NFC در جسم نگه دارید.',
    nfcDescription: 'تشخیص از طریق NFC: تلفن همراه خود را در برابر کد شیء نگه دارید',
    noTimeTrackingMethodAvailableDescription: 'هیچ روش ردیابی زمان برای این کاربر پیکربندی نشده است. لطفا با رهبر تیم خود تماس بگیرید.',
    noLocationCode: 'هیچ کدی در دسترس نیست',
    noLocationCodeDescription: 'اگر کد QR یا برچسب NFC در شی وجود ندارد، این گزینه را انتخاب کنید. ارائه نظر الزامی است.',
    noLocationCodeConfirm: 'آیا مطمئن هستید که می خواهید بدون کد شی شروع کنید؟ برای توقف باید نظر بدهید.',
    wrongCode: 'کد QR اسکن شده یک کد شی نیست!',
    readNotice: 'لطفا دستورالعمل ها را بخوانید!',
    warning: 'هشدار',
    lessThanAMinuteTitle: 'ثبت زمان کامل',
    lessThanAMinute: 'شما کمتر از 1 دقیقه پیش شروع کردید. آیا مطمئن هستید که می خواهید متوقف شوید؟',
    continue: 'به ضبط ادامه دهید',
    timeAlert: {
      differenceInMinutesHint: 'زمان روی گوشی هوشمند شما با زمان روی سرور متفاوت است. تفاوت ثبت شده است',
      differentTimeZoneHint: 'منطقه زمانی گوشی هوشمند شما با منطقه زمانی سرور متفاوت است. تفاوت ثبت شده است.'
    },
    leaveComment: {
      withCode: 'اطلاعات مربوط به شی/سرویس روشن نبود. لطفاً به ما بگویید که در کدام ملک/خدمات کار می کردید.',
      withoutCode: 'هیچ کد شی در شروع یا توقف ضبط نشد. لطفاً توضیح دهید که چرا بدون شی شروع به کار کردید/ متوقف کردید.'
    },
    comment: 'نظر',
    enterComment: 'در مورد ردیابی زمان نظر دهید',
    differentQrCode: {
      title: 'تغییر شی؟',
      continueInLocation: 'به کار در {{locationName}} ادامه دهید.',
      automaticStopInLocation: 'ضبط زمان در {{locationName}} به طور خودکار متوقف می شود.',
      stopInLocation: 'توقف ضبط زمان در {{locationName}}.',
      stop: 'برای متوقف کردن',
      stopNoLocation: 'ضبط زمان را متوقف کنید.',
      continueNoOldLocation: 'ضبط زمان برای شی قبلی به طور خودکار متوقف می شود.',
      continueWorking: 'به کار ادامه دهید',
      continueWorkingInNewLocation: 'ادامه کار در ملک جدید',
      stopWithLocationActivity: 'توقف زمان ضبط در {{locationActivity}}.',
      continueWithNewLocationActivity: 'ادامه کار با {{locationActivity}}',
      stopOldLocationActivity: 'ضبط زمان در {{locationActivity}} به طور خودکار متوقف می شود.',
      stopOldNoLocationActivity: 'ضبط زمان بدون عملکرد به طور خودکار متوقف می شود.',
      continueNoLocationActivity: 'بدون عملکرد به کار خود ادامه دهید.',
      sameLocationTitle: 'تغییر عملکرد؟',
      differentLocationTitle: 'تغییر شی؟'
    },
    saveAndStop: 'ذخیره و توقف',
    noLocationScanned: '- بدون شی -',
    noLocationFound: '- شیء مشخص نیست -',
    timerDifference: '{{h}} ساعت {{mm}} دقیقه',
    stopSuccess: 'زمان با موفقیت ثبت شد',
    stopSuccessToast: 'ضبط زمان با موفقیت متوقف شد.',
    stopTimeTracking: 'برای متوقف کردن',
    manual: {
      time: 'زمان',
      locationSelect: 'شی را انتخاب کنید',
      loadMore: 'بارگذاری بیشتر',
      trackTime: 'زمان ثبت',
      location: 'هدف - شی',
      locationActivity: 'کارایی',
      date: 'تاریخ',
      startTime: 'از جانب',
      endTime: 'تا زمان',
      create: 'تایید',
      createAgain: 'زمان اضافی را برای این شی ثبت کنید',
      errorUntil: 'از زمان باید کمتر از زمان باشد',
      worktimeDifference: 'ساعات کاری:',
      worktimeDifferenceValue: '{{hours}} ساعت {{minutes}} دقیقه',
      worktimeDifferenceValueMinutes: '{{minutes}} دقیقه',
      success: 'زمان با موفقیت ثبت شد',
      break: 'زنگ تفريح:',
      breakMinutes: '{{minutes}} دقیقه',
      breakHoursAndMinutes: '{{hours}} ساعت {{minutes}} دقیقه',
      noActivity: '- بدون اجرا -',
      locationPlaceHolder: 'جستجو بر اساس نام، شماره یا کد شی',
      conflictError: 'در حال حاضر ضبط زمان در دوره های مشخص شده وجود دارد. همپوشانی مجاز نیست. لطفا اطلاعات خود را بررسی کنید.',
      currentDayDuration: 'قبلاً ضبط شده است',
      expectedDuration: 'ساعات کاری مورد انتظار',
      expectedDurationIncludedCurrent: 'از جمله ورودی فعلی'
    },
    differenceInMinutesHint: 'زمان روی گوشی هوشمند شما با زمان روی سرور متفاوت است. تفاوت ثبت شده است',
    differentTimeZoneHint: 'منطقه زمانی گوشی هوشمند شما با منطقه زمانی سرور متفاوت است. تفاوت ثبت شده است.',
    proposed: {
      capture: 'گرفتن',
      time: 'زمان',
      captureTime: 'رکورد زمان',
      button: 'تایید',
      title: 'ساعت کاری را تایید کنید',
      includingBreak: '(از جمله استراحت)',
      from: 'از جانب',
      to: 'تا زمان',
      break: 'زنگ تفريح',
      hour: 'ساعت',
      confirm: {
        description: 'لطفا ساعت کاری خود را تایید کنید',
        confirm: 'بله درسته',
        editButton: 'نه، این درست نیست'
      },
      edit: {
        description: 'لطفا مدت زمان را مشخص کنید',
        breakIncluded: 'از جمله {{ duration }} استراحت',
        comment: 'نظر',
        yourComment: 'نظر شما',
        minutes: 'دقایق',
        hours: 'ساعت ها'
      },
      reasons: {
        overtime: 'اضافه کاری به دلیل مشتری',
        faster: 'سریعتر انجام شد',
        representative: 'من نماینده هستم',
        other: 'متفرقه'
      },
      description: 'به محض اتمام کار در ملک، ساعات کاری خود را تأیید کنید.'
    },
    codeNotAssignable: 'کد QR اسکن شده قابل تخصیص نیست!',
    running: 'ضبط زمان در حال اجرا است'
  },
  timeFramePicker: {
    title: 'لطفا یک دوره انتخاب کنید',
    today: 'امروز',
    currentWeek: 'هفته جاری',
    currentMonth: 'ماه جاری',
    custom: 'سفارشی',
    startDate: 'شروع',
    endDate: 'پایان'
  },
  timeSheet: {
    title: 'زمان و برنامه ریزی',
    noPlannings: 'بدون برنامه ریزی',
    planned: 'برنامه ریزی شده',
    workingTime: 'ساعات کاری',
    workloadDuration: 'زمان کار هفتگی',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'باید',
    workedDuration: 'است',
    hasWorkload: 'اون روز کار کن',
    pleaseConnectToLoad: 'لطفاً برای بارگیری زمان های خود یک اتصال اینترنتی برقرار کنید.',
    footer: {
      planned: 'برنامه ریزی شده',
      worklogsTotal: 'تمام وقت',
      workloadTotal: 'ساعت کاری طبق قرارداد',
      difference: 'تفاوت'
    },
    homepageTitle: 'روز کاری شما'
  },
  planning: {
    includingPause: 'استراحت شامل:',
    planRoute: 'مسیر را برنامه ریزی کنید',
    title: 'برنامه ریزی',
    modifyMinutes: 'نرخ ثابت:'
  },
  worklog: {
    title: 'زمان ثبت شده',
    absenceTitle: 'غیبت',
    workingTime: 'ساعات کاری',
    breakDuration: 'زنگ تفريح',
    reduce: 'نرخ ثابت',
    attachments: 'پیوست ها',
    comments: 'نظرات',
    comment: 'نظر',
    noLocation: 'شی مشخص نشده است',
    changedBy: 'زمان از {{firstName}} {{lastName}} تغییر کرد.',
    confirmAttachmentDelete: 'آیا مطمئن هستید که می خواهید این پیوست را حذف کنید؟',
    attachmentDeleted: 'پیوست حذف شده است',
    confirmCommentDelete: 'آیا مطمئن هستید که می خواهید این نظر را حذف کنید؟',
    commentDeleted: 'نظر حذف شد'
  },
  tabs: {
    home: 'صفحه اصلی',
    times: 'بار',
    chat: 'چت کنید',
    tickets: 'بلیط',
    more: 'بیشتر'
  },
  profile: {
    title: 'مشخصات',
    testing: 'آزمایش کردن',
    testingDescription: 'این ناحیه تنها زمانی قابل مشاهده است که به یک سیستم آزمایشی متصل شود.',
    testingAllowQrCodeOnWeb: 'اجازه ورود دستی کد QR را بدهید',
    pushNotifications: 'اعلان های فشاری',
    reloadUserContext: 'UserContext را دوباره بارگیری کنید',
    pushNotificationSuccessful: 'اعلان فشاری با موفقیت دریافت شد',
    editProfilePicture: 'تغییر عکس پروفایل',
    editProfilePictureTitle: 'بخش را انتخاب کنید',
    pushNotificationNotice: 'مجوز «ویرایش تنظیمات مشتری» برای آزمایش مورد نیاز است.',
    send: 'ارسال',
    profilePicture: 'عکس پروفایل'
  },
  help: { title: 'کمک' },
  myVacations: {
    vacation: 'تعطیلات',
    amountAvailableVacationDays: '{{amount}} روز از تعطیلات هنوز در دسترس است',
    totalVacationDays: 'حق مرخصی {{ total }} روز',
    remainingVacationDays: '({{ total }} روز {{ sign }} {{ remaining }} روز باقی مانده به تعطیلات {{ previousYear }})',
    xDaysVacations: '{{ total }} روز تعطیل',
    title: 'تعطیلات شما'
  },
  externalLinks: {
    title: 'لینک های خارجی',
    notice: 'این لینک توسط شرکت شما ارائه شده است. شما در حال ترک سیستم Blink هستید.'
  },
  stickers: {
    title: 'برچسب های شما',
    sticker: 'استیکرها',
    sender: 'گیرنده',
    text: 'با توجه',
    date: 'تاریخ',
    emptyState: 'متأسفانه هنوز هیچ برچسبی ندارید',
    senderDescription: 'این برچسب توسط {{sender}} در {{date}} برای شما ارسال شده است',
    readed: 'خواندن',
    notReaded: 'هنوز آن را نخوانده',
    newStickerToaster: 'برچسب پلک زدن جدید!',
    open: 'باز کن',
    next: 'به علاوه',
    titleCases: {
      case1: 'اولین برچسب شما را تبریک می گویم!',
      case2: 'عالی است، اکنون برچسب <b>{{count}}</b> دارید!',
      case3: 'دهمین استیکر شما را تبریک می گویم!',
      case4: '30 برچسب - وای!',
      case5: 'اکنون اینجا شلوغ شده است: <b>{{count}}</b> برچسب‌ها!'
    },
    confirmDelete: 'آیا واقعاً می خواهید این استیکرها را حذف کنید؟'
  },
  autoTranslate: {
    isTranslated: 'ترجمه خودکار',
    translationError: 'ترجمه خودکار در حال حاضر امکان پذیر نیست'
  },
  confirmLogout: 'آیا واقعاً می خواهید از سیستم خارج شوید؟',
  message: 'اخبار',
  migration: {
    wrongVersion: 'در نسخه فعلی Blink me، ورود از طریق برنامه Blink Time پشتیبانی نمی‌شود. لطفاً برنامه Blink me را به آخرین نسخه به روز کنید'
  },
  eLearning: {
    pendingTab: 'برای انجام ({{value}})',
    completedTab: 'انجام شد ({{value}})',
    completed: 'تکمیل شد',
    pending: 'باز کن',
    dueFalling: 'عقب افتاده',
    title: 'آموزش الکترونیکی',
    course: 'دوره',
    dueDate: 'معتبر تا',
    topics: 'فاعل، موضوع',
    completeDate: 'تمام شد',
    status: { Pending: 'باز کن', Done: 'تکمیل شد' },
    infos: {
      pendingExists: 'شما می توانید تمام دوره های آموزشی آزاد خود را در اینجا بیابید.',
      finishOverdue: 'لطفا آموزش هایی را که از قبل موعد مقرر شده است در اسرع وقت انجام دهید.',
      allCompleted: 'عالی! شما تمام دوره های آموزشی خود را با موفقیت پشت سر گذاشتید.',
      emptyState: 'شما هیچ آموزش اختصاصی ندارید.'
    }
  },
  checklists: {
    title: 'تشکیل می دهد',
    description: 'یک فرم برای یک شی پر کنید',
    button: 'شی را انتخاب کنید'
  }
};
