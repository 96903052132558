<ng-container *ngIf="videoDataModel">
  <ng-container *ngIf="isOnline; else videoOffline">
    <div class="vimeo-container-100" *ngIf="videoDataModel.videoType === 'vimeo'">
      <iframe [src]="vimeoUrl | safe" frameborder="0"
              allow="fullscreen" allowfullscreen>
      </iframe>
    </div>

    <div class="youtube-container-100" *ngIf="videoDataModel.videoType === 'youtube'">
      <iframe frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              [src]="youTubeUrl | safe">
      </iframe>
    </div>
  </ng-container>

  <ng-template #videoOffline>
    <ion-text>{{ t.video_offline | translate }}</ion-text>
  </ng-template>
</ng-container>
