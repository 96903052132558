export const barcodeScannerStyle = `
.display-none {
  display: none;
}

#barcode-scanner {
  height: 100%;
  text-align: center;
}

#barcode-scanner .barcode-scanner-icon-wrapper {
  position: absolute;
  top: 130px;
  width: 100%;
}

#barcode-scanner .fal {
  font-size: 50vh;
  font-weight: 100;
  color: var(--ion-color-primary);
  /*padding-top: 15%;*/
  margin: auto !important;
}

#barcode-scanner .scan-prompt {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  padding: 5px;
  border-radius: 4px;
  color: white;
}

#barcode-scanner .barcode-scanner-footer {
  position: absolute;
  bottom: 20px;
  width: 100%
}

#barcode-scanner .barcode-scanner-cancel {
  background-color: var(--ion-color-primary);
  color: black;
  border-radius: 4px;
  padding: 15px 30px;
}
`;
