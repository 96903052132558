import { List } from 'immutable';
import {
  DefaultEntitiesRef,
  entitiesPropsFactory,
  EntitiesRef,
  EntitiesState,
  getEntityType,
  getIdType
} from '@ngneat/elf-entities';
import { Reducer } from '@ngneat/elf';

export type IdMap = { [id: number]: number[] };

export function idMapper<S extends EntitiesState<Ref>,
  K extends keyof S,
  Ref extends EntitiesRef = DefaultEntitiesRef>(
  idMap: K,
  newEntities: any[],
  idProperty: keyof getEntityType<S, Ref>,
  isCompleteUpdate: boolean
): Reducer<S> {
  return function (state) {
    const map = state[idMap];
    const newIdsMap = List(newEntities)
      .groupBy(entity => entity[idProperty])
      .mapEntries(([checklistId, groups]) =>
        [checklistId, groups.map(entity => entity['Id']).toArray()])
      .toObject() as IdMap;

    const result: IdMap = { ...map };
    Object.keys(newIdsMap).forEach(id => {
      const source = map[id] && !isCompleteUpdate ? map[id] : [];
      result[id] = source.concat(newIdsMap[id].filter(i => source.indexOf(i) < 0));
    });
    return {
      ...state,
      [idMap]: result
    };
  };
}

export function removeMappedId<S extends Record<string, any>, K extends keyof S>(
  idMap: K,
  removeId: any
): Reducer<S> {
  return function (state) {
    const map = state[idMap];
    const result: IdMap = {};
    Object.keys(map).forEach(id => {
      const newEntries = map[id].filter(i => i !== removeId);
      if (newEntries.length) {
        result[id] = newEntries;
      }
    });
    return {
      ...state,
      [idMap]: result
    };
  };
}


export function removeKeys<S extends Record<string, any>, K extends keyof S>(
  idMap: K,
  removeKeys: any[]
): Reducer<S> {
  return function (state) {
    const result: IdMap = { ...state[idMap] };
    removeKeys.forEach(key => {
      delete result[key];
    });
    return {
      ...state,
      [idMap]: result
    };
  };
}

export const checkStoreConfig = (name: string) => ({ name: `check_${name}` });


// export const removeDeletedFromStore = <S extends Store>(store: S, entities: any[]) => {
//   const entityIds = entities.map(x => x.Id);
//   store.update(deleteEntitiesByPredicate(
//     c => !entityIds.includes(c.Id)
//   ));
// };

export const { withEntities, EntitiesRef: defaultEntitiesRef } =
  entitiesPropsFactory('');

/**
 * For removing Entities from the store, that were not returned by list api calls.
 */
export function deleteAbsentEntities<S extends EntitiesState<Ref>,
  Ref extends EntitiesRef = DefaultEntitiesRef>(
  entities: Partial<getEntityType<S, Ref>>[],
  removeAbsent: boolean,
  options: any = {}
): Reducer<S> {
  return function (state) {
    console.log('benis', removeAbsent);
    if (!removeAbsent) {
      return { ...state };
    }

    if (options?.idMap) {
      console.log('idMap', state[options.idMap]);
    }

    console.log('removing absence', entities);

    const { ref: { idsKey, entitiesKey } = defaultEntitiesRef } = options;

    const existingIds = entities.map(x => x['Id']);

    const idsToRemove = state[idsKey].filter(id => !existingIds.includes(id));
    const newEntities = { ...state[entitiesKey] };
    const newIds = state[idsKey].filter(
      (id: getIdType<S, Ref>) => !idsToRemove.includes(id)
    );

    console.log({ idsToRemove, newEntities });

    for (const id of idsToRemove) {
      Reflect.deleteProperty(newEntities, id);
    }

    return {
      ...state,
      [entitiesKey]: newEntities,
      [idsKey]: newIds
    };
  };
}

