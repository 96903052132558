import { createStore, select, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  selectAllEntities,
  selectMany,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { CheckItemValue } from './item-value';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';
import { map, switchMap } from 'rxjs/operators';
import { PersistStateService } from '../../shared/persist-state.service';

interface ItemValueRepoProps {
  checklistItemValueIdMap: IdMap;
  itemItemValuesIdMap: IdMap;
}

const store = createStore(
  checkStoreConfig('itemValues'),
  withEntities<CheckItemValue, 'Id'>({ idKey: 'Id' }),
  withProps<ItemValueRepoProps>({
    itemItemValuesIdMap: {},
    checklistItemValueIdMap: {}
  })
);

@Injectable({ providedIn: 'root' })
export class CheckItemValueRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();
  itemValues$ = store.pipe(selectAllEntities());

  constructor(private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(itemValues: CheckItemValue[], isCompleteUpdate: boolean, removeAbsentForChecklistId: number = null) {
    const deleteItemValueIds = store
      .getValue()
      .checklistItemValueIdMap[removeAbsentForChecklistId];

    store.update(
      deleteEntities(deleteItemValueIds),
      upsertEntities(itemValues),
      idMapper(
        'checklistItemValueIdMap',
        itemValues,
        'ChecklistId',
        isCompleteUpdate
      ),
      idMapper(
        'itemItemValuesIdMap',
        itemValues,
        'ItemId',
        isCompleteUpdate
      )
    );
  }

  updateItemValueOrder(itemValueOrder: number[]) {
    let order = 0;
    store.update(updateEntities(
      itemValueOrder,
      entity => {
        const update = { ...entity, Order: order++ };
        return update;
      }
    ));
  }

  delete(id: number) {
    store.update(
      deleteEntities(id),
      removeMappedId('itemItemValuesIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistItemDependencyIdMap = store.getValue().checklistItemValueIdMap;
    let deleteItemValueIds = [];
    checklistIds.forEach(x => {
      deleteItemValueIds = deleteItemValueIds.concat(checklistItemDependencyIdMap[x]);
    });
    store.update(
      deleteEntities(deleteItemValueIds),
      removeKeys('checklistItemValueIdMap', checklistIds)
    );
  }

  getMany(ids: number[]) {
    if (ids) {
      return store.pipe(selectMany(ids));
    }
    return of([]);
  }

  getItemItemValues(itemId: number): Observable<CheckItemValue[]> {
    return store.pipe(
      select(state => state.itemItemValuesIdMap[itemId]),
      map(itemValueIds => itemValueIds ? itemValueIds : []),
      switchMap(itemValueIds => this.getMany(itemValueIds))
    );
  }
}
