<ion-content>
  <ion-list class="list-remove-borders">
    <ion-item *ngFor="let loginProfile of loginProfiles" button
              [color]="loginProfile.id === activeProfile.id ? 'primary' : ''"
              (click)="selectLoginProfile(loginProfile)">
      <ion-label>{{ loginProfile.CompanyName }}
        <small style="display:block;margin-left:0px" class="input-info"
               *ngIf="loginProfile.System">{{ loginProfile.System }}</small>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
