<div class="ui-input-wrapper" [class.mb-15]="marginBottom">
  <ion-item *ngIf="type !== 'datepicker'"
            lines="none"
            class="blink-item-input"
            color="input">
    <ion-input *ngIf="type !== 'textarea'"
               [type]="type"
               [(ngModel)]="value"
               [disabled]="disabled"
               [placeholder]="placeholder | translate"
               label="{{ label | translate }} {{ labelAppend | translate }}"
               [labelPlacement]="labelPlacement"
               [required]="required"
               (ionBlur)="onTouched()"
               (keydown)="numberOnlyValidation($event)">
    </ion-input>
    <ion-textarea *ngIf="type === 'textarea'" rows="5"
                  [(ngModel)]="value"
                  [disabled]="disabled"
                  [placeholder]="placeholder | translate"
                  label="{{ label | translate }} {{ labelAppend | translate }}"
                  (ionBlur)="onTouched()"
                  [labelPlacement]="labelPlacement"
                  [required]="required">
    </ion-textarea>
  </ion-item>

  <small *ngIf="info" class="input-info">
    {{ info | translate }}
  </small>
  <div *ngIf="validationErrors"
       class="validation-container">
    <small *ngIf="validationErrors['required']">
      {{ t.formError.isRequired | translate: { name: label | translate } }}
    </small>
    <small *ngIf="validationErrors['email']">
      {{ t.formError.email | translate }}
    </small>
    <small *ngIf="validationErrors['min']">
      {{ t.formError.min | translate: { value: validationErrors['min'].min - 1 } }}
    </small>
    <small *ngIf="validationErrors['max']">
      {{ t.formError.max | translate: { value: validationErrors['max'].max } }}
    </small>
    <small *ngIf="validationErrors['maxlength']">
      {{
        t.formError.maxlength | translate: {
          name: label | translate,
          value: validationErrors['maxlength'].requiredLength
        }
      }}
    </small>
    <ng-content></ng-content>
  </div>
</div>
