import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { BlinkAuthService } from '../blinkApi/shared/blink-auth.service';
import { Subscription } from 'rxjs';
import { BlinkUserContext, SessionRepository } from '@blink/api';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {
  private _appInsights: ApplicationInsights;
  private _blinkAuthService: BlinkAuthService;

  private subscriptions = new Subscription();

  private get blinkAuthService(): BlinkAuthService {
    if (!this._blinkAuthService) {
      this._blinkAuthService = this.injector.get(BlinkAuthService);
    }

    return this._blinkAuthService;
  }

  private get appInsights(): ApplicationInsights {
    if (!this._appInsights) {
      if (environment.production) {
        const isApp = this.platform.is('cordova');
        this._appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: environment.variables.instrumentationKey,
            enableAutoRouteTracking: !isApp, // option to log all route changes,
            disableAjaxTracking: isApp
          }
        });
        this._appInsights.loadAppInsights();

        this.subscriptions.add(this.sessionRepo.userContext$.subscribe((user: BlinkUserContext) => {
          if (this.appInsights && user) {
            this.appInsights.context.user.id = `${this.sessionRepo.getSystem()}-${user.LoginUserId}`;
          }
        }));
      }
    }

    return this._appInsights;
  }

  constructor(private platform: Platform,
              private sessionRepo: SessionRepository,
              private injector: Injector) {
  }

  logPageView(name?: string, url?: string) { // option to call manually
    if (this.appInsights) {
      this.appInsights.trackPageView({ name: name, uri: url });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
