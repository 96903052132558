import { Injectable } from '@angular/core';
import { GlobalTranslateService, TranslateGenericClass } from '@blink/util';
import fillChecklistAr from './fill-checklist-ar';
import fillChecklistBg from './fill-checklist-bg';
import fillChecklistDe from './fill-checklist-de';
import fillChecklistEl from './fill-checklist-el';
import fillChecklistEn from './fill-checklist-en';
import fillChecklistEs from './fill-checklist-es';
import fillChecklistFa from './fill-checklist-fa';
import fillChecklistFr from './fill-checklist-fr';
import fillChecklistHr from './fill-checklist-hr';
import fillChecklistHu from './fill-checklist-hu';
import fillChecklistIt from './fill-checklist-it';
import fillChecklistPl from './fill-checklist-pl';
import fillChecklistPt from './fill-checklist-pt';
import fillChecklistRo from './fill-checklist-ro';
import fillChecklistRu from './fill-checklist-ru';
import fillChecklistTr from './fill-checklist-tr';
import fillChecklistUk from './fill-checklist-uk';
import fillChecklistCs from './fill-checklist-cs';
import fillChecklistSk from './fill-checklist-sk';
import { TranslateService } from '@ngx-translate/core';

const suffix = 'fillChecklist';

@Injectable({
  providedIn: 'root'
})
export class FillChecklistTranslateService extends TranslateGenericClass<typeof fillChecklistDe>() {
  constructor(public global: GlobalTranslateService,
              public translate: TranslateService) {
    super();

    global.setupService(this, suffix, fillChecklistDe);
    global.addTranslations('ar', suffix, fillChecklistAr);
    global.addTranslations('bg', suffix, fillChecklistBg);
    global.addTranslations('de', suffix, fillChecklistDe);
    global.addTranslations('el', suffix, fillChecklistEl);
    global.addTranslations('en', suffix, fillChecklistEn);
    global.addTranslations('es', suffix, fillChecklistEs);
    global.addTranslations('fa', suffix, fillChecklistFa);
    global.addTranslations('fr', suffix, fillChecklistFr);
    global.addTranslations('hr', suffix, fillChecklistHr);
    global.addTranslations('hu', suffix, fillChecklistHu);
    global.addTranslations('it', suffix, fillChecklistIt);
    global.addTranslations('pl', suffix, fillChecklistPl);
    global.addTranslations('pt', suffix, fillChecklistPt);
    global.addTranslations('ro', suffix, fillChecklistRo);
    global.addTranslations('ru', suffix, fillChecklistRu);
    global.addTranslations('tr', suffix, fillChecklistTr);
    global.addTranslations('uk', suffix, fillChecklistUk);
    global.addTranslations('cs', suffix, fillChecklistCs);
    global.addTranslations('sk', suffix, fillChecklistSk);
  }
}
