import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ENV_PROD, ENV_VARS_SYSTEM, GlobalTranslateService, logComponent, systemFromCheckUrl } from '@blink/util';
import { UiDialogService } from '@blink/ui';
import { AuthApi, ResetPasswordParams } from '@blink/api';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../login.service';

enum ResetErrors {
  InvalidToken = 'invalidToken',
  WrongPassword = 'wrongPassword'
}

@Component({
  selector: 'blink-page-reset-password',
  templateUrl: 'reset-password.html',
  styleUrls: ['reset-password.scss']
})
export class ResetPasswordComponent {
  loginUserId: number;
  code: string;
  error: any = false;
  form: FormGroup;

  constructor(public t: GlobalTranslateService,
              @Inject(ENV_PROD) private environmentProd: boolean,
              @Inject(ENV_VARS_SYSTEM) private fallbackSystem: string,
              public translate: TranslateService,
              private router: Router,
              private loginService: LoginService,
              private authApi: AuthApi,
              private uiDialogService: UiDialogService,
              public fb: FormBuilder,
              private route: ActivatedRoute) {
    logComponent(this);
  }

  ngOnInit() {
    this.loginUserId = parseInt(this.route.snapshot.params['loginUserId']);
    this.code = this.route.snapshot.params['code'];

    if (this.loginUserId && this.code) {

      this.form = this.fb.group({
        Password: ['', Validators.compose([
          Validators.required,
          Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*\\W).*'),
          Validators.minLength(6)
        ])],
        PasswordRepeat: ['', Validators.compose([
          Validators.required,
          Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*\\W).*'),
          Validators.minLength(6)
        ])]
      });
    }
  }

  get passwordValidationError() {
    return !this.form.get('Password').valid && this.form.get('Password').dirty
  }

  get passwordRepeatValidationError() {
    return !this.form.get('PasswordRepeat').valid && this.form.get('PasswordRepeat').dirty
  }

  get passwordsEqualError() {
    return this.form.get('Password').valid && this.form.get('Password').dirty
      && this.form.get('PasswordRepeat').valid && this.form.get('PasswordRepeat').dirty
      && this.form.get('Password').value !== this.form.get('PasswordRepeat').value;
  }

  doReset() {
    this.error = false;

    const params: ResetPasswordParams = {
      Code: this.code,
      Password: this.form.value.Password,
      LoginUserId: this.loginUserId
    };

    this.authApi.resetPassword(params)
      .then(() => {
        void this.uiDialogService.toast('typedGlobal.FORGOT_PASSWORD.reset.success', 'success');
        this.backToRoot();
      }).catch(response => {
      void this.handleError(response);
    });
  }

  async handleError(message: string) {
    if (message.includes('Invalid token.')) {
      this.error = ResetErrors.InvalidToken;
      this.uiDialogService.alert(this.t.FORGOT_PASSWORD.errors.invalidToken);
    } else {
      this.error = ResetErrors.WrongPassword;
      this.uiDialogService.alert(this.t.FORGOT_PASSWORD.reset.validationError);
    }
  }

  forgotPassword() {
    this.loginService.showForgotPassword(systemFromCheckUrl(this.environmentProd, this.fallbackSystem));
  }

  backToRoot() {
    this.router.navigateByUrl('/')
  }
}
