export default {
  home: {
    pageTitle: 'pagina principala',
    welcome: 'Bun venit la aplicația Blink me. conversație fericită,\n' +
      '\n' +
      'Echipa ta de clipire',
    startChat: 'ÎNCEPE',
    ticket: 'bilet',
    timeLicenseMissing: 'Nu vi s-a atribuit o licență Blink Time!'
  },
  conversations: {
    pageTitle: 'Știri',
    yourChats: 'chat-urile tale',
    chat: 'conversație'
  },
  settings: {
    settings: 'Setări',
    pushEnabled: 'Primiți notificări push',
    system: 'sistem',
    languageSelection: 'Alegerea limbii',
    currentSettings: 'Setarile curente',
    user: 'utilizator',
    appVersion: 'Versiune (aplicație)',
    apiVersion: 'Versiune (API)',
    deviceInfo: 'Versiunea dispozitivului',
    buildVersion: 'versiune (build)',
    helpAndDataPolicy: 'Ajutor și protecția datelor',
    text1: 'Echipa noastră de asistență va fi bucuroasă să vă ajute dacă aveți întrebări:',
    phone: 'telefon',
    mail: 'e-mail',
    information: 'info',
    documentation: 'Documentație online',
    documentationText: 'În documentația noastră online veți găsi instrucțiuni și videoclipuri pentru toate zonele importante ale Blink.',
    dataPolicy: 'protejarea datelor',
    support: 'A sustine',
    moreActions: 'Actiunile urmatoare',
    protocol: 'protocol',
    showChatUserSecret: 'Partajați chatul cu alt dispozitiv',
    scanCodeAnotherDevice: 'Scanați următorul cod QR pe noul dispozitiv sau introduceți codul personal de chat pe noul dispozitiv!',
    yourPersonalCode: 'Codul dvs. personal de chat',
    permissions: {
      title: 'notificare',
      subtitle: 'Necesar pentru a primi notificări push pentru mesajele de chat, notificări intermitente și autocolante.',
      grantPermission: 'permisie garantata',
      camera: 'aparat foto',
      cameraHint: 'Necesar pentru înregistrarea timpului prin cod obiect.',
      nfc: 'NFC',
      nfcHint: 'Necesar pentru înregistrarea timpului prin etichetă NFC.',
      media: 'Luați imagine și imagine din bibliotecă',
      mediaHint: 'Necesar pentru a captura imagini și documente legate de orele și biletele dvs.',
      location: 'Locație',
      locationHint: 'Este necesar să vă înregistrați locația în momentul înregistrării timpului.',
      notifications: 'Notificări',
      notificationsHint: 'Necesar pentru a primi notificări push pentru mesajele de chat, notificări intermitente și autocolante.',
      locationDisabledOnSystem: 'Serviciile de localizare sunt dezactivate. Vă rugăm să activați acest lucru în setările dispozitivului dvs.'
    }
  },
  connectionState: {
    connecting: 'Conectați...',
    connected: 'Legați împreună.',
    disconnecting: 'Conexiunea este deconectată...',
    disconnected: 'conexiune pierdută',
    denied: 'Conexiunea nu a putut fi stabilită.'
  },
  noOneToFind: 'Nimic de găsit.',
  nothingToFind: 'Nimic de găsit.',
  noLocationFound: 'Nu s-au găsit obiecte.',
  searchPlaceholder: 'Căuta...',
  employeeSearchPlaceholder: 'Nume prenumele',
  typeToSearch: 'Atinge pentru a căuta...',
  typeToSearchEmployee: 'Introdu numele persoanei pentru a începe un chat',
  createChatWithEmployee: 'cauta angajati',
  createChatWithLocationTeam: 'Căutare obiect',
  createTeamleaderChat: 'Toți angajații mei',
  yourLanguageSelection: 'Selectarea limbii dvs.:',
  loginInfo: 'Pentru a vă înregistra, vă rugăm să scanați codul QR personal:',
  updating: 'Blink me este în curs de actualizare.',
  notice: 'Înștiințare',
  importantNotice: 'Notă importantă',
  continue: 'Mai departe',
  intro: {
    slide1: {
      header: 'Bine ati venit',
      text: 'Distrează-te cu aplicația ta Blink me.'
    },
    slide2: {
      header: 'conversație',
      text: 'Discutați direct cu colegii dvs. sau aflați cele mai recente știri de la grupuri interesante.'
    },
    slide3: {
      header: 'bilete',
      text: 'Aici vă puteți vedea sarcinile și puteți crea aplicații.'
    }
  },
  pleaseWait: 'Va rugam asteptati...',
  skip: 'Ocolire',
  startAgain: 'Începe din nou',
  lock: {
    createPinHeader: 'PIN-ul dvs. personal',
    createPinInfo: 'Pentru a vă proteja datele, puteți seta aici un PIN pe care trebuie să îl introduceți de fiecare dată când utilizați aplicația Blink me.',
    validatePin: 'Vă rugăm să introduceți din nou codul PIN.',
    tryAgain: 'PIN-ul nu se potrivește. Vă rugăm să încercați din nou.',
    triedToOften: 'Din păcate, codul PIN nu se potrivește. Doriți să reporniți procesul sau să îl săriți și să îl faceți mai târziu?',
    enterPinHeader: 'Intrare PIN',
    enterPinInfo: 'Introduceți codul PIN pentru a debloca aplicația.',
    remainingTrys: 'Încă {{amount}} încercări. Apoi, trebuie să vă conectați din nou la aplicație.',
    tooManyEnters: 'Din păcate, înregistrarea cu PIN nu mai este posibilă. Vă rugăm să vă conectați din nou cu cardul dvs. de identitate Blink.',
    reSignIn: 'Înregistrare nouă',
    setpin: 'Introducerea codului PIN atunci când utilizați aplicația',
    skip: 'Nu doriți să atribuiți un PIN acum? Puteți activa această funcție oricând prin setările aplicației, inclusiv mai târziu.',
    dontUsePin: 'nu utilizați un PIN',
    deactivatePinHeader: 'Dezactivați codul PIN',
    deactivatePinInfo: 'Vă rugăm să introduceți codul PIN pentru a dezactiva protecția.'
  },
  active: {
    yourTickets: 'Biletele tale',
    createNewTask: 'Creați un bilet nou',
    assignee: 'editor',
    location: 'obiect',
    dueDate: 'Datorat pe',
    createTask: 'Creați un bilet',
    taskCreated: 'Biletul a fost creat.',
    backToTasks: 'Înapoi la bilete',
    createdAt: 'creat in',
    attachments: 'Atasamente',
    addFileOrImage: 'Adăugați fișier sau fotografie',
    addImage: 'adauga o fotografie',
    comments: 'Comentarii',
    addComment: 'adauga comentariu',
    addCommentInfo: 'Comentariul este vizibil pentru oricine poate vedea acest bilet.',
    commentCreated: 'Comentariul a fost salvat.',
    showClosed: 'Vezi biletele finalizate',
    resetFilter: 'resetați toate filtrele',
    changeTicketStatusTo: 'Schimbați starea biletului în:',
    editTask: 'Editați biletul',
    saveTask: 'Salvați biletul',
    taskSaved: 'Biletul a fost salvat.',
    deleteImage: 'Imagine clară',
    deleteImageConfirmation: 'Chiar vrei să ștergi imaginea?',
    deleteAttachment: 'Ștergeți atașamentul',
    deleteAttachmentConfirmation: 'Sigur doriți să ștergeți acest atașament?',
    deleteComment: 'Șterge comentariul',
    deleteCommentConfirmation: 'Chiar vrei să ștergi comentariul?',
    changeAssignee: 'schimba editorul',
    duplicateTask: 'Bilet duplicat',
    duplicateTaskConfirmation: 'Doriți să duplicați acest bilet?',
    taskDuplicated: 'Biletul a fost duplicat.',
    deleteTask: 'Ștergeți biletul',
    deleteTaskConfirmation: 'Sigur doriți să ștergeți acest bilet?',
    taskDeleted: 'Biletul a fost șters.',
    assigneeChanged: 'Procesorul a fost schimbat.',
    statusChanged: 'Starea biletului a fost schimbată.',
    startDate: 'ÎNCEPE',
    endDate: 'Sfârşit',
    doctorVisitDate: 'Data vizitei la medic',
    doctorVisitDescription: 'Data vizitei la medic: {{date}}',
    chooseDate: 'Alegeți o dată',
    comment: 'Comentariu (opțional)',
    dateError: 'Data de încheiere nu poate fi anterioară datei de început',
    employee: 'Angajatii',
    pleaseConnectToLoad: 'Vă rugăm să stabiliți o conexiune la internet pentru a vă încărca biletele.'
  },
  emptyState: {
    chats: 'Creează un chat!',
    chat: 'Scrie primul mesaj :)',
    tasks: 'Nu se găsesc bilete.',
    attachments: 'Nu există atașamente disponibile.',
    comments: 'Nu există comentarii disponibile.',
    vacation: 'Nu s-a intrat nicio vacanta pentru acest an'
  },
  formError: {
    min: 'Valoarea trebuie să fie mai mare decât {{value}}.',
    min2: 'Valoarea trebuie să fie mai mare sau egală cu {{value}}.',
    max: 'Valoarea trebuie să fie mai mică sau egală cu {{value}}.',
    requiredBecause: 'Valoare necesară deoarece {{name}} este completat.',
    isRequired: '{{name}} este obligatoriu.',
    numberInUse: 'Numărul de personal este deja utilizat.',
    maxlength: '{{name}} nu poate conține mai mult de {{value}} caractere.',
    faultyBlinkId: 'ID intermitent incorect'
  },
  ngSelect: {
    typeToSeach: 'Vă rugăm să introduceți 3 sau mai multe litere.',
    loading: 'încărcare date...',
    notFound: 'Nu s-a găsit {{title}}.',
    clearAll: 'Resetare la valorile implicite'
  },
  placeholder: {
    employee: 'Căutați după nume și prenume, număr de personal, Blink ID',
    location: 'Căutați după nume, număr sau coduri obiect',
    date: 'Alegeți o dată',
    searchTasks: 'Caută bilete...'
  },
  hasChat: 'Începeți chatul acum',
  hasNoChatYet: 'Din păcate, nu am instalat încă aplicația Blink me.',
  noBlinkActiveLicence: 'Fara licenta',
  noBlinkActiveLicenceInfo: 'În prezent, nu aveți o licență pentru această aplicație. Vă rugăm să contactați supervizorul dvs.',
  noBlinkMeLicence: 'Fara licenta',
  noBlinkMeLicenceInfo: 'Această aplicație nu poate fi utilizată cu sistemul dvs. Vă rugăm să utilizați Blink Time din aplicație sau Playstore.',
  noSuitableAppFound: 'Nu a fost găsită nicio aplicație pentru a deschide fișierul.',
  myChats: 'Chaturile tale',
  hasNoChatsHome: 'Începeți să discutați acum! Puteți vorbi cu toți colegii și puteți participa la grupuri de obiecte și echipe.',
  hasNoTicketsHome: 'Nu sunt disponibile bilete deschise.',
  myTickets: 'Biletele tale',
  myEmployees: 'Angajații mei',
  group: 'grup',
  confirmLogoutWithChat: 'Dacă vă deconectați, mesajele dvs. de chat vor fi șterse. Doriți să continuați?',
  messageNotDecryptable: 'Mesajul nu a putut fi decriptat.',
  chat: {
    userAlreadyExists: 'Ați configurat deja chatul pe alt dispozitiv. Pentru a putea folosi chat-ul pe acest dispozitiv, trebuie să conectați dispozitivele. Alternativ, puteți reporni configurarea, dar apoi chatul nu mai poate fi folosit pe celălalt dispozitiv.',
    linkDevices: 'Conectați dispozitive',
    withoutChat: 'Continuați fără chat',
    setupAgain: 'Resetați chatul',
    setupAgainText: 'Dacă configurați din nou chat-ul, toate mesajele din chat-urile individuale existente vor fi șterse și chat-ul nu va mai putea fi folosit pe celelalte dispozitive! Doriți să continuați?',
    LinkDevicesText: 'Pentru a conecta dispozitivele pentru chat, deschide Setări pe celălalt dispozitiv și selectează „Partajare chat cu alt dispozitiv”.',
    personalToken: 'Codul dvs. personal de chat',
    wrongCode: 'Codul de chat furnizat este incorect.',
    beingPrepared: 'Chatul este în curs de pregătire.',
    twilioAccessDenied: 'Conexiunea la serviciul de chat nu a putut fi stabilită. Vă rugăm să contactați asistența Blink.',
    notificationsDenied: 'Notificările sunt dezactivate pe dispozitivul/browserul dvs. Accesați Setări și activați notificările pentru Blink me pentru a primi notificări. Puteți activa apoi notificările în setările personale.',
    deleted: 'Chatul a fost șters',
    unavailable: 'Chatul nu este disponibil'
  },
  protocol: {
    SEND_EMAIL: 'Trimis prin posta electronica',
    DELETE: 'Curăță Jurnalul',
    deleteMessage: 'Chiar vrei să ștergi jurnalul? Această acțiune nu poate fi anulată.',
    Send: 'Trimite',
    ConfirmMessage: 'Jurnalul aplicației va fi trimis acum la Blink Support.',
    SuccessMessage: 'Jurnalul a fost trimis cu succes la Blink Support.'
  },
  timeTracking: {
    title: 'Urmărirea timpului',
    start: 'start',
    startDescription: 'Faceți clic pe Start pentru a începe urmărirea timpului',
    started: 'A început',
    stop: 'A opri',
    duration: 'Perioadă de timp',
    syncButtonLabel: 'Timpii de încărcare înregistrate offline',
    offlineSyncMessage: 'Pentru a transfera timpii salvati trebuie să fii online.',
    successfulSync: 'Orele sunt încărcate',
    scanNowQrCode: 'cod QR',
    scanNowQrCodeDescription: 'Scanați codul QR din obiect.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Țineți smartphone-ul de autocolantul NFC din obiect.',
    nfcDescription: 'Detectare prin NFC: țineți telefonul mobil lângă codul obiectului',
    noTimeTrackingMethodAvailableDescription: 'Nu există o metodă de urmărire a timpului configurată pentru acest utilizator. Vă rugăm să contactați liderul echipei dvs.',
    noLocationCode: 'Niciun cod disponibil',
    noLocationCodeDescription: 'Selectați această opțiune dacă nu există cod QR sau autocolant NFC în obiect. Furnizarea unui comentariu este obligatorie.',
    noLocationCodeConfirm: 'Sunteți sigur că doriți să începeți fără cod obiect? Trebuie să oferiți un comentariu pentru a opri.',
    wrongCode: 'Codul QR scanat nu este un cod obiect!',
    readNotice: 'Vă rugăm să citiți instrucțiunile!',
    warning: 'avertizare',
    lessThanAMinuteTitle: 'Înregistrare completă a timpului',
    lessThanAMinute: 'Ai început cu mai puțin de 1 minut în urmă. Ești sigur că vrei să te oprești?',
    continue: 'Continuați înregistrarea',
    timeAlert: {
      differenceInMinutesHint: 'Ora de pe smartphone-ul tău diferă de ora de pe server. Diferența este înregistrată',
      differentTimeZoneHint: 'Fusul orar al smartphone-ului dvs. diferă de fusul orar al serverului. Diferența este înregistrată.'
    },
    leaveComment: {
      withCode: 'Informațiile despre obiect/serviciu nu au fost clare. Vă rugăm să ne spuneți în ce proprietate/serviciu ați lucrat.',
      withoutCode: 'Nu a fost capturat niciun cod obiect la pornire sau oprire. Vă rugăm să explicați de ce ați pornit/oprit fără obiect.'
    },
    comment: 'cometariu',
    enterComment: 'Comentați despre urmărirea timpului',
    differentQrCode: {
      title: 'Schimbați obiectul?',
      continueInLocation: 'Continuați să lucrați în {{locationName}}.',
      automaticStopInLocation: 'Înregistrarea timpului în {{locationName}} se va opri automat.',
      stopInLocation: 'Opriți înregistrarea timpului în {{locationName}}.',
      stop: 'A opri',
      stopNoLocation: 'Opriți înregistrarea timpului.',
      continueNoOldLocation: 'Înregistrarea timpului pentru obiectul anterior este oprită automat.',
      continueWorking: 'Continua să lucreze',
      continueWorkingInNewLocation: 'Continuați să lucrați într-o proprietate nouă.',
      stopWithLocationActivity: 'Opriți înregistrarea timpului pe {{locationActivity}}.',
      continueWithNewLocationActivity: 'Continuați să lucrați cu {{locationActivity}}',
      stopOldLocationActivity: 'Înregistrarea timpului pe {{locationActivity}} este oprită automat.',
      stopOldNoLocationActivity: 'Înregistrarea timpului fără performanță este oprită automat.',
      continueNoLocationActivity: 'Continuați să lucrați fără performanță.',
      sameLocationTitle: 'Schimbați performanța?',
      differentLocationTitle: 'Schimbați obiectul?'
    },
    saveAndStop: 'salvați și opriți',
    noLocationScanned: '- Niciun obiect -',
    noLocationFound: '- Obiectul nu este clar -',
    timerDifference: '{{h}} ore {{mm}} minute',
    stopSuccess: 'Timpul înregistrat cu succes',
    stopSuccessToast: 'Înregistrarea timpului s-a oprit cu succes.',
    stopTimeTracking: 'A opri',
    manual: {
      time: 'Timp',
      locationSelect: 'selectați obiectul',
      loadMore: 'incarca mai mult',
      trackTime: 'timp record',
      location: 'obiect',
      locationActivity: 'Performanţă',
      date: 'Data',
      startTime: 'Din',
      endTime: 'Pana cand',
      create: 'A confirma',
      createAgain: 'Înregistrați timp suplimentar pentru acest obiect',
      errorUntil: 'Din timp trebuie să fie mai mic decât To time',
      worktimeDifference: 'Ore de lucru:',
      worktimeDifferenceValue: '{{hours}} ore {{minutes}} minute',
      worktimeDifferenceValueMinutes: '{{minutes}} minute',
      success: 'Timpul înregistrat cu succes',
      break: 'Pauză:',
      breakMinutes: '{{minutes}} minute',
      breakHoursAndMinutes: '{{hours}} ore {{minutes}} minute',
      noActivity: '- fara performanta -',
      locationPlaceHolder: 'Căutați după nume, număr sau coduri obiect',
      conflictError: 'Există deja o înregistrare a timpului în perioadele specificate. Suprapunerile nu sunt permise. Vă rugăm să vă verificați detaliile.',
      currentDayDuration: 'Deja înregistrate',
      expectedDuration: 'Program de lucru așteptat',
      expectedDurationIncludedCurrent: 'inclusiv intrarea curentului'
    },
    differenceInMinutesHint: 'Ora de pe smartphone-ul tău diferă de ora de pe server. Diferența este înregistrată',
    differentTimeZoneHint: 'Fusul orar al smartphone-ului dvs. diferă de fusul orar al serverului. Diferența este înregistrată.',
    proposed: {
      capture: 'Captură',
      time: 'Timp',
      captureTime: 'Timp record',
      button: 'A confirma',
      title: 'Confirmați programul de lucru',
      includingBreak: '(inclusiv pauză)',
      from: 'Din',
      to: 'Pana cand',
      break: 'Pauză',
      hour: 'Ceas',
      confirm: {
        description: 'Vă rugăm să vă confirmați programul de lucru',
        confirm: 'Da, așa e',
        editButton: 'Nu, nu este corect'
      },
      edit: {
        description: 'Vă rugăm să specificați durata',
        breakIncluded: 'Inclusiv {{ duration }} pauză',
        comment: 'cometariu',
        yourComment: 'comentariul tau',
        minutes: 'minute',
        hours: 'Ore'
      },
      reasons: {
        overtime: 'Orele suplimentare datorate clientului',
        faster: 'Gata mai repede',
        representative: 'sunt reprezentant',
        other: 'Diverse'
      },
      description: 'Confirmați-vă programul de lucru imediat ce ați terminat de lucrat la proprietate.'
    },
    codeNotAssignable: 'Codul QR scanat nu poate fi atribuit!',
    running: 'Înregistrarea timpului rulează'
  },
  timeFramePicker: {
    title: 'Vă rugăm să selectați o perioadă',
    today: 'Astăzi',
    currentWeek: 'Săptămâna aceasta',
    currentMonth: 'Luna curentă',
    custom: 'Personalizat',
    startDate: 'ÎNCEPE',
    endDate: 'Sfârşit'
  },
  timeSheet: {
    title: 'Timp și planificare',
    noPlannings: 'Fără planificare',
    planned: 'Planificat',
    workingTime: 'ore de lucru',
    workloadDuration: 'Timp de lucru săptămânal',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Ar trebui să',
    workedDuration: 'Este',
    hasWorkload: 'Lucrați în ziua aceea',
    pleaseConnectToLoad: 'Vă rugăm să vă conectați la internet pentru a vă încărca orele.',
    footer: {
      planned: 'Planificat',
      worklogsTotal: 'Total ore',
      workloadTotal: 'ore prin contract',
      difference: 'diferență'
    },
    homepageTitle: 'Ziua ta de lucru'
  },
  planning: {
    includingPause: 'Pauza inclusiv:',
    planRoute: 'Planificați traseul',
    title: 'planificare',
    modifyMinutes: 'Preţ global:'
  },
  worklog: {
    title: 'Timp înregistrat',
    absenceTitle: 'absenta',
    workingTime: 'ore de lucru',
    breakDuration: 'Pauză',
    reduce: 'preţ global',
    attachments: 'Atasamente',
    comments: 'Comentarii',
    comment: 'cometariu',
    noLocation: 'Niciun obiect specificat',
    changedBy: 'Ora sa schimbat de la {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Sigur doriți să ștergeți acest atașament?',
    attachmentDeleted: 'Atașamentul a fost șters',
    confirmCommentDelete: 'Sigur doriți să ștergeți acest comentariu?',
    commentDeleted: 'Comentariul a fost șters'
  },
  tabs: {
    home: 'Acasă',
    times: 'ori',
    chat: 'conversație',
    tickets: 'bilete',
    more: 'Mai mult'
  },
  profile: {
    title: 'profil',
    testing: 'Testare',
    testingDescription: 'Această zonă este vizibilă numai dacă sunteți conectat la un sistem de testare.',
    testingAllowQrCodeOnWeb: 'Permite introducerea manuală a codului QR',
    pushNotifications: 'Notificări',
    reloadUserContext: 'Reîncărcați UserContext',
    pushNotificationSuccessful: 'Notificare push primită cu succes',
    editProfilePicture: 'schimba poza de profil',
    editProfilePictureTitle: 'Selectați secțiunea',
    pushNotificationNotice: 'Testul necesită permisiunea „Editați setările locatarului”.',
    send: 'Trimite',
    profilePicture: 'Poză de profil'
  },
  help: { title: 'Ajutor' },
  myVacations: {
    vacation: 'Concediu de odihna',
    amountAvailableVacationDays: '{{amount}} zile de vacanță încă disponibile',
    totalVacationDays: 'Drept de concediu {{ total }} zile',
    remainingVacationDays: '({{ total }} zile {{ sign }} {{ remaining }} zile rămase de vacanță {{ previousYear }})',
    xDaysVacations: '{{ total }} zile de vacanță',
    title: 'Vacanta ta'
  },
  externalLinks: {
    title: 'linkuri externe',
    notice: 'Acest link a fost furnizat de compania dvs. Părăsiți sistemul Blink.'
  },
  stickers: {
    title: 'autocolantele tale',
    sticker: 'Autocolante',
    sender: 'Destinatar',
    text: 'In ceea ce priveste',
    date: 'Data',
    emptyState: 'Din păcate, încă nu aveți autocolante',
    senderDescription: 'Acest autocolant v-a fost trimis de {{sender}} pe {{date}}',
    readed: 'citit',
    notReaded: 'nu l-a citit inca',
    newStickerToaster: 'Noul autocolant Blink!',
    open: 'Deschis',
    next: 'Mai departe',
    titleCases: {
      case1: 'Felicitări pentru primul tău autocolant!',
      case2: 'Grozav, acum aveți deja autocolante <b>{{count}}</b>!',
      case3: 'Felicitări pentru al 10-lea autocolant!',
      case4: '30 de autocolante - wow!',
      case5: 'Acum devine aglomerat aici: <b>{{count}}</b> Stickere!'
    },
    confirmDelete: 'Chiar doriți să ștergeți aceste autocolante?'
  },
  autoTranslate: {
    isTranslated: 'Tradus automat',
    translationError: 'În prezent, traducerea automată nu este posibilă'
  },
  confirmLogout: 'Chiar vrei să te deconectezi?',
  message: 'Știri',
  migration: {
    wrongVersion: 'În versiunea actuală de Blink me, conectarea prin aplicația Blink Time nu este acceptată. Actualizați aplicația Blink me la cea mai recentă versiune'
  },
  eLearning: {
    pendingTab: 'De făcut ({{value}})',
    completedTab: 'Terminat ({{value}})',
    completed: 'Efectuat',
    pending: 'Deschis',
    dueFalling: 'Depasit',
    title: 'E-learning',
    course: 'curs',
    dueDate: 'Valabil până la',
    topics: 'subiecte',
    completeDate: 'Terminat pe',
    status: { Pending: 'deschis', Done: 'efectuat' },
    infos: {
      pendingExists: 'Puteți găsi toate cursurile de formare deschise aici.',
      finishOverdue: 'Vă rugăm să faceți cât mai repede posibil antrenamentul care este deja programat.',
      allCompleted: 'Excelent! Ați finalizat cu succes toate cursurile de formare.',
      emptyState: 'Nu aveți pregătire atribuită.'
    }
  },
  checklists: {
    title: 'Forme',
    description: 'Completați un formular pentru un obiect',
    button: 'Selectați obiectul'
  }
};
