import { Injectable } from '@angular/core';
import {
  LocationSelectionMethod,
  TimeTracking,
  TimeTrackingMethod,
  TimeTrackingStatus,
  TimeTrackingStopType,
  TimeTrackingType
} from './time-tracking-event/time-tracking';
import { v4 as uuidv4 } from 'uuid';
import { TimeTrackingProposedProps, TimeTrackingRepository } from './time-tracking-event/time-tracking.repository';
import * as actions from './actions';
import { GeolocationService, GPS_STATUS, NetworkService } from '@blink/capacitor-plugins';
import * as dayjs from 'dayjs'
import { TimeTrackingApi } from './time-tracking.api';
import { BlinkUserContext, SessionRepository } from '../core';
import {
  TimeTrackingContextRepository,
  TimeTrackingLocation,
  TimeTrackingLocationQrCode
} from './time-tracking-context';
import { getEntity, hasEntity } from '@ngneat/elf-entities';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { logService } from '@blink/util';

import * as isToday from 'dayjs/plugin/isToday'
import { TimeTrackingProposedDuration } from './time-tracking-context/time-tracking-context';

dayjs.extend(isToday);

@Injectable({ providedIn: 'root' })
export class TimeTrackingService {

  userContext: BlinkUserContext;
  appVersion = '';
  deviceId = '';
  initialized = false;
  initializeTimeTrackingSync = actions.initializeTimeTrackingSync;

  private syncDone = new BehaviorSubject<boolean>(null);

  constructor(public timeTrackingRepository: TimeTrackingRepository,
              public networkService: NetworkService,
              private timeTrackingApi: TimeTrackingApi,
              public geolocation: GeolocationService,
              private sessionRepository: SessionRepository,
              public timeTrackingContextRepository: TimeTrackingContextRepository) {

    logService(this);

    //Because we can synchronise only 50 batches in v3/TimeTrackingEvents endpoint
    //we need to wait for previous sync to finish before we check and start new one
    this.syncDone.pipe(debounceTime(500)).subscribe(syncDone => {
      if (syncDone && this.timeTrackingRepository.getAllTrackingsForSync().length > 0) {
        this.syncTimeTrackings();
      }
    });
  }

  get startedLessThanAMinute(): boolean {
    const start = this.timeTrackingRepository.getStart();
    return start ? dayjs().diff(dayjs(start.Timestamp), 'seconds') <= 60 : false;
  }

  get differentTimeZone(): boolean {
    return dayjs().utcOffset() < 60 || dayjs().utcOffset() > 120;
  }

  get invalidDifferenceInMinutes(): boolean {
    const difference = this.timeTrackingRepository.getDifferenceInMinutes;
    return difference <= -15 || difference >= 15;
  }

  init(appVersion: string) {
    this.sessionRepository.userContext$.subscribe(userContext => {
      this.userContext = userContext;
      this.appVersion = appVersion;
      if (!this.initialized) {
        this.initializeTimeTrackingSync();
      }
      this.initialized = true;
    });
  }

  async addTracking(timeTrackingType: TimeTrackingType, locationSelectionMethod: LocationSelectionMethod, locationCode: string = null) {
    const timeTracking = await this.buildTimeTracking(timeTrackingType, locationSelectionMethod, locationCode, null);
    this.timeTrackingRepository.add(timeTracking);
  }

  differentQrCode(qrCode) {
    const start = this.timeTrackingRepository.getStart();
    return start != null && start.LocationQrCode != null && start.LocationQrCode != '' && start.LocationQrCode !== qrCode;
  }

  async syncTimeTrackings() {
    if (this.networkService.connected && !this.timeTrackingRepository.store.getValue().syncInProgress) {
      const timeTrackings = this.timeTrackingRepository.getAllTrackingsForSync();
      if (timeTrackings.length > 0) {
        (await this.timeTrackingApi.postTimeTrackings({
          timeTrackings,
          appVersion: this.appVersion
        })).subscribe(() => {
          this.syncDone.next(true)
        });
      }
    }
  }

  async buildTimeTracking(
    type: TimeTrackingType,
    locationSelectionMethod: LocationSelectionMethod,
    locationCode: string = null,
    comment = '',
    timestamp = null,
    timeTrackingMethod: TimeTrackingMethod = null
  ): Promise<TimeTracking> {
    let locationQrCode: TimeTrackingLocationQrCode = null;

    if (this.differentTimeZone) {
      comment += (comment ? ', ' : '') + 'Zeitzone auf Smartphone weicht von Standard ab!';
    }

    if (this.invalidDifferenceInMinutes) {
      const minutes = this.timeTrackingRepository.getDifferenceInMinutes < 0 ? this.timeTrackingRepository.getDifferenceInMinutes : '+' + this.timeTrackingRepository.getDifferenceInMinutes;
      comment += (comment ? ', ' : '') + `Uhrzeit auf Smartphone weicht um ${minutes} Minuten ab!`;
    }


    if (locationCode && this.timeTrackingContextRepository.store.query(hasEntity(locationCode))) {
      locationQrCode = this.timeTrackingContextRepository.store.query(getEntity(locationCode));
    }

    let result = {
      Uuid: uuidv4(),
      SyncStatus: this.networkService.connected ? TimeTrackingStatus.Initial : TimeTrackingStatus.Offline,
      Type: type,
      Timestamp: timestamp ? dayjs(timestamp).toISOString() : dayjs().toISOString(),
      TimeTrackingMethod: timeTrackingMethod || (type === TimeTrackingType.Start ? TimeTrackingMethod.Start : TimeTrackingMethod.Stop),
      LocationSelectionMethod: locationSelectionMethod,
      GpsStatus: this.userContext && !this.userContext.GpsTrackingActive ? GPS_STATUS.DisabledInSystem : GPS_STATUS.Unknown,
      LocationId: locationQrCode ? locationQrCode.LocationId : null,
      LocationActivityId: timeTrackingMethod === null && locationQrCode ? locationQrCode.LocationActivityId : null,
      LocationQrCode: locationCode,
      Comment: comment
    } as TimeTracking;

    if (Capacitor.isNativePlatform()) {
      result = await this.getGpsData(result);
    }

    return result;
  }


  validateStop(startTimeTracking: TimeTracking, stopTimeTracking: TimeTracking): TimeTrackingStopType {
    const withoutStartCodeAndWithStopCodeWithoutLocationActivity =
      startTimeTracking.LocationQrCode === null &&
      stopTimeTracking.LocationQrCode !== null &&
      stopTimeTracking.LocationId !== null;

    const withoutStartCodeAndWithStopCodeWithoutLocation =
      startTimeTracking.LocationQrCode === null &&
      stopTimeTracking.LocationQrCode !== null &&
      stopTimeTracking.LocationId === null &&
      stopTimeTracking.LocationActivityId === null;

    const withoutLocationCodeInStartAndStop =
      startTimeTracking.LocationQrCode === null &&
      stopTimeTracking.LocationQrCode === null;

    const withStartLocationCodeWithLocationWithoutStopCode =
      startTimeTracking.LocationQrCode !== null &&
      startTimeTracking.LocationId !== null &&
      stopTimeTracking.LocationQrCode === null;


    const withStartLocationCodeWithoutLocationAndWithoutStopCode =
      startTimeTracking.LocationQrCode !== null &&
      startTimeTracking.LocationId === null &&
      stopTimeTracking.LocationQrCode === null;

    const withDifferentCodeInStartAndStopBothUnknownLocation =
      startTimeTracking.LocationQrCode !== null &&
      startTimeTracking.LocationId === null &&
      stopTimeTracking.LocationQrCode !== null &&
      stopTimeTracking.LocationId === null;


    const withSameLocationCodeInStartAndStopWithoutActivity =
      startTimeTracking.LocationQrCode !== null &&
      startTimeTracking.LocationId !== null &&
      stopTimeTracking.LocationQrCode !== null &&
      stopTimeTracking.LocationId !== null &&
      startTimeTracking.LocationQrCode === stopTimeTracking.LocationQrCode &&
      startTimeTracking.LocationId === stopTimeTracking.LocationId;


    const withDifferentLocationCodeBothSameLocationAndActivity =
      startTimeTracking.LocationQrCode !== null &&
      startTimeTracking.LocationId !== null &&
      startTimeTracking.LocationActivityId !== null &&
      stopTimeTracking.LocationQrCode !== null &&
      stopTimeTracking.LocationId !== null &&
      stopTimeTracking.LocationActivityId !== null &&
      startTimeTracking.LocationId === stopTimeTracking.LocationId &&
      startTimeTracking.LocationActivityId === stopTimeTracking.LocationActivityId;


    const withDifferentLocationCodeBothSameLocationAndWithoutActivity =
      startTimeTracking.LocationQrCode !== null &&
      startTimeTracking.LocationId !== null &&
      startTimeTracking.LocationActivityId === null &&
      stopTimeTracking.LocationQrCode !== null &&
      stopTimeTracking.LocationId !== null &&
      stopTimeTracking.LocationActivityId === null &&
      startTimeTracking.LocationQrCode !== stopTimeTracking.LocationQrCode &&
      startTimeTracking.LocationId === stopTimeTracking.LocationId;

    if (withoutStartCodeAndWithStopCodeWithoutLocationActivity ||
      withoutStartCodeAndWithStopCodeWithoutLocation ||
      withoutLocationCodeInStartAndStop ||
      withStartLocationCodeWithLocationWithoutStopCode ||
      withStartLocationCodeWithoutLocationAndWithoutStopCode ||
      withDifferentCodeInStartAndStopBothUnknownLocation) {
      return TimeTrackingStopType.StopWithComment;
    }
    if (withSameLocationCodeInStartAndStopWithoutActivity ||
      withDifferentLocationCodeBothSameLocationAndActivity ||
      withDifferentLocationCodeBothSameLocationAndWithoutActivity) {
      return TimeTrackingStopType.Stop;
    } else {
      return TimeTrackingStopType.ContinueWorking;
    }
  }

  getLocationQrCode(timeTracking: TimeTracking): TimeTrackingLocationQrCode | null {
    return timeTracking.LocationQrCode ? this.timeTrackingContextRepository.store.query(getEntity(timeTracking.LocationQrCode)) : null;
  }

  async prepareProposedWorklogs(locationSelectionMethod: LocationSelectionMethod, locationCode: string) {
    const relatedPlanningEntry = this.getProposedDuration(locationCode);

    this.timeTrackingRepository.setProposed({
      ...relatedPlanningEntry,
      start: await this.buildTimeTracking(
        TimeTrackingType.Start,
        locationSelectionMethod,
        locationCode,
        null,
        dayjs().add(-relatedPlanningEntry.duration, 'minutes').toISOString(),
        TimeTrackingMethod.Proposed),
      stop: await this.buildTimeTracking(
        TimeTrackingType.Stop,
        locationSelectionMethod,
        locationCode,
        null,
        dayjs().toISOString(),
        TimeTrackingMethod.Proposed)
    });
  }

  submitProposedWorklogs() {
    const proposed = this.timeTrackingRepository.getProposed();
    this.timeTrackingRepository.addMultiple([proposed.start, proposed.stop]);
    this.syncTimeTrackings();
  }

  getProposedDuration(locationCode: string): TimeTrackingProposedProps {
    const proposedResult: TimeTrackingProposedProps = {
      duration: 5,
      breakDuration: 0,
      location: this.getLocationByQrCode(locationCode),
      start: null,
      stop: null,
      showConfirm: false
    };

    const proposedDurationsForToday = this.timeTrackingContextRepository.getProposedDurations().filter(p =>
      dayjs(p.Date).isToday())
    let proposedDuration: TimeTrackingProposedDuration = proposedDurationsForToday.find(p => p.LocationId === null);

    if (proposedResult.location) {
      const proposedDurationsForTodayForLocation = proposedDurationsForToday
        .find(p => p.LocationId === proposedResult.location.Id)
      if (proposedDurationsForTodayForLocation) {
        proposedResult.showConfirm = true;
        proposedDuration = proposedDurationsForTodayForLocation
      }
    }

    if (proposedDuration && proposedDuration.ProposedDurationMinutes > 0) {
      proposedResult.duration = proposedDuration.ProposedDurationMinutes;
      proposedResult.breakDuration = proposedDuration.BreakDurationMinutes;
    }

    return proposedResult;
  }

  private getLocationByQrCode(locationCode: string): TimeTrackingLocation {
    const locationQrCode: TimeTrackingLocationQrCode = this.timeTrackingContextRepository.getLocationQrCode(locationCode);
    return locationQrCode?.LocationId
      ? this.timeTrackingContextRepository.getLocation(locationQrCode.LocationId)
      : null;
  }

  private async getGpsData(stopTimeTracking: TimeTracking): Promise<TimeTracking> {
    if (this.userContext && this.userContext.GpsTrackingActive) {
      const result = await this.geolocation.getCoordinates();
      console.log('gps = coordinates', result);
      stopTimeTracking.GpsStatus = result.status;
      if (result.status === GPS_STATUS.Ok && result.Position) {
        const cords = result.Position.coords;
        // stopTimeTracking.GpsStatus = coordinates && coordinates.coords ? GPS_STATUS.Ok : GPS_STATUS.Unknown;
        stopTimeTracking.GpsLatitude = cords.latitude ? cords.latitude : null;
        stopTimeTracking.GpsLongitude = cords.longitude ? cords.longitude : null;
        stopTimeTracking.GpsAccuracy = cords.accuracy ? cords.accuracy : null;
      }
    }

    return stopTimeTracking;
  }
}

