export default {
  barcodeScanner: {
    permissionHeader: 'Autorisation',
    permissionMessage: "Si vous souhaitez autoriser l'utilisation de votre caméra, activez-la dans les paramètres de l'application.",
    scanPrompt: 'Analyser {{thing}}',
    enterPrompt: 'Entrez {{thing}}',
    blinkId: 'Identifiant clignotant',
    isNoBlinkId: 'Le code QR scanné n`est pas un ID Blink valide',
    objectCode: 'Code de l`objet'
  },
  camera: {
    promptLabelHeader: 'Choisir la source',
    promptLabelPicture: 'Prendre une photo',
    promptLabelPhoto: 'Sélectionner dans la galerie'
  },
  network: {
    changed: {
      connected: 'Connexion Internet établie',
      disconnected: 'Connexion Internet interrompue'
    }
  },
  file: { downloadStarted: 'Le téléchargement a commencé.' },
  nfc: {
    wrongCode: "Le code QR scanné n'est pas un code d'objet !",
    error: 'Erreur NFC'
  }
};
