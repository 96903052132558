export function deUmlaut(value: string): string {
  return value.toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/[áàãâå]/g, 'a')
    .replace(/[ûúù]/g, 'u')
    .replace(/[õôóò]/g, 'o')
    .replace(/[ëêéè]/g, 'e')
    .replace(/[ìíîï]/g, 'i');
}
