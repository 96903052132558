<ui-page [title]="options.title"
         [ionNoBorder]="options.ionNoBorder || false">
  <div class="modal-content ion-padding">
    <form [formGroup]="form">
      <formly-form [form]="form"
                   [fields]="options.fields"
                   [model]="options.model">
      </formly-form>
    </form>
  </div>
  <ng-container customActions>
    <ion-button [fill]="options.action.fill"
                [color]="options.action.color"
                [disabled]="!formDirtyAndValid"
                (click)="handle(options.action)">
            <span [class.text-black]="options.action.fill === 'outline'">
              {{ options.action.label | translate | firstUppercase }}
            </span>
    </ion-button>
  </ng-container>
</ui-page>
