import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiInputComponent, UiInputCurrencyComponent } from './inputs';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractNgModelComponent } from './inputs/abstracts/ng-model.component';
import { AbstractInputComponent } from './inputs/abstracts/input.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectCompanyPopoverComponent } from './legacy/select-company-popover/select-company-popover.component';
import { UiPageComponent } from './ui-page/ui-page.component';
import { UiExternalLinkModalComponent, UiMainMenuComponent, UiMobileTabsComponent } from './navigation';
import { UiInfoComponent } from './ui-info';
import { UtilModule } from '@blink/util';
import { UiCardComponent } from './ui-card/ui-card.component';
import { UiTooltipDirective } from './ui-tooltip/ui-tooltip.directive';
import { UiDialogConfirmComponent } from './ui-dialog/ui-dialog-confirm/ui-dialog-confirm.component';
import { UiDialogFormComponent } from './ui-dialog/ui-dialog-form/ui-dialog-form.component';
import { FormlyModule } from '@ngx-formly/core';
import { UiFormlyModule } from './formly/ui-formly.module';
import { UiLoadingComponent } from './loading/loading.component';
import {
  UiDialogFileTransferProgressComponent
} from './ui-dialog/ui-dialog-file-transfer/file-transfer-progress.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { UiDialogPdfComponent } from './ui-dialog/ui-dialog-pdf/ui-dialog-pdf.component';
import { UiDatetimeComponent } from './inputs/components/ui-datetime/ui-datetime.component';
import { UiInfoFieldTypeComponent } from './formly/templates/ui-info.field-type';
import { startAndEndDateValidator } from './ui-dialog/dialog.service';
import { UiEmptyStateComponent } from './ui-empty-state/ui-empty-state.component';
import { UiModalHeaderComponent } from './ui-modal-header/ui-modal-header.component';
import { UiModalCloseButtonComponent } from './ui-modal-close-button/ui-modal-close-button.component';
import { UiButtonComponent } from './ui-button/ui-button.component';
import { UiIconModule } from './ui-icon/ui-icon.module';
import { UiInputsModule } from './inputs/ui-inputs.module';
import { UiDialogPopoverComponent } from './ui-dialog/ui-dialog-popover/ui-dialog-popover.component';
import { UiInputDateFieldTypeComponent } from './formly/templates/date/date.type';
import { UiFormlyFieldInputSelectComponent } from './formly/templates/input-select/input-select.type';
import { UiActionItemComponent } from './ui-action-item/ui-action-item.component';
import { UiDialogSignatureComponent } from './ui-dialog/ui-dialog-signature/ui-dialog-signature.component';
import { SelectProfilePopoverComponent } from './legacy/select-profile-popover/select-profile-popover.component';

const exportedDeclarations = [
  UiLoadingComponent,
  //Input
  UiInputComponent,
  //UiInputDateComponent,

  // Layout
  UiPageComponent,
  // UiIconComponent,
  UiInfoComponent,
  UiCardComponent,
  UiEmptyStateComponent,
  UiTooltipDirective,
  UiModalHeaderComponent,
  UiModalCloseButtonComponent,
  UiButtonComponent,

  // Navigation
  UiMainMenuComponent,
  UiMobileTabsComponent,
  UiInfoFieldTypeComponent,
  UiFormlyFieldInputSelectComponent,

  UiActionItemComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    UiInputsModule,
    FormlyModule.forRoot({
      validators: [{ name: 'startAndEndDateValidator', validation: startAndEndDateValidator }],
      types: [
        { name: 'ui-input-date', component: UiInputDateFieldTypeComponent },
        { name: 'ui-info', component: UiInfoFieldTypeComponent },
        { name: 'ui-input-select', component: UiFormlyFieldInputSelectComponent }
      ]
    }),
    // FormlyIonicModule,
    UiFormlyModule,
    UiIconModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    UtilModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [

    UiExternalLinkModalComponent,
    UiDatetimeComponent,
    // Inputs
    AbstractNgModelComponent,
    AbstractInputComponent,

    UiDialogConfirmComponent,
    UiDialogFormComponent,
    UiDialogFileTransferProgressComponent,
    UiDialogPdfComponent,
    UiDialogSignatureComponent,

    ...exportedDeclarations,

    // Legacy
    SelectCompanyPopoverComponent,
    SelectProfilePopoverComponent,
    UiDialogPopoverComponent

    , UiInputCurrencyComponent],
  exports: [
    ...exportedDeclarations,
    UiInputCurrencyComponent,
    UiIconModule,
    UiInputsModule,
    UiDatetimeComponent,
  ]
})
export class BlinkUiModule {
}
