import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ui-modal-header',
  templateUrl: './ui-modal-header.component.html',
  styleUrls: ['./ui-modal-header.component.scss']
})
export class UiModalHeaderComponent {
  @Input() title: string;

  constructor(public modalController: ModalController) {
  }
}
