import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUrlsToHtmlLinks'
})
export class ConvertUrlsToHtmlLinksPipe implements PipeTransform {

  transform(value: string): string {
    const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
    return value.replace(urlRegex, function (url) {
      let hrefUrl = url;
      if (!/^https?:\/\//i.test(url)) {
        hrefUrl = 'https://' + url;
      }
      return '<a href="' + hrefUrl + '" target="_blank">' + url + '</a>';
    });
  }
}
