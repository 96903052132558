<ion-item *ngIf="label"
          lines="none"
          class="blink-item-input"
          [id]="cid"
          [attr.required]="required ? '' : null">
  <ion-input
    type="tel"
    label="{{ label | translate }}"
    labelPlacement="stacked"
    [value]="internalValue"
    (ionChange)="formatCurrency($event)">
  </ion-input>
</ion-item>

<ion-input
  *ngIf="!label"
  type="tel"
  class="blink-input"
  [value]="internalValue"
  (ionChange)="formatCurrency($event)">
</ion-input>

<small *ngIf="!valid" class="text-danger">
  {{ t.invalidValue | translate}}
</small>

