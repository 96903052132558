import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[cy]'
})
export class CypressDirective implements OnInit {
  @Input() cy: string;
  @Input() cyId: number | string;

  constructor(private elementRef: ElementRef) {

  }


  ngOnInit() {
    if (this.cy) {
      this.setAttribute('data-cy', this.cy);
      if (this.cyId) {
        this.setAttribute('data-cy-id', String(this.cyId));
      }
    }
  }

  setAttribute(attr: string, value: string) {
    this.elementRef.nativeElement.setAttribute(attr, value);
  }
}

// export const cySelector = (cy: string, id?: number | string) => {
//   let selector = `[data-cy="${cy}"]`;
//   if (id) {
//     selector += `[data-cy-id="${id}"]`
//   }
//   return selector;
// }
