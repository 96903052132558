import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
// import {logService} from '../../../../../../apps/me/src/utils/logger';
import { PersistStateService } from '../../shared';

export interface ChatSessionState {
  encryption: {
    ChatEncryptionSharedSecret: string;
    ChatUserSecret: string;
    TwilioToken: string;
    ChatUserSecretCreationDate: string;
    FcmToken: string;
    AppInstanceIdentifier: string;
  };
  pushEnabled?: boolean;
  chatEnabled: boolean;
}

const store = createStore(
  { name: 'core-chat-session' },
  withProps<ChatSessionState>({
    encryption: {
      ChatEncryptionSharedSecret: null,
      ChatUserSecret: null,
      TwilioToken: null,
      ChatUserSecretCreationDate: null,
      FcmToken: null,
      AppInstanceIdentifier: null
    },
    pushEnabled: null,
    chatEnabled: false
  })
);

@Injectable({ providedIn: 'root' })
export class ChatSessionRepository {
  store = store;

  sessionReadySubject = new ReplaySubject<boolean>(1);
  sessionReady$ = this.sessionReadySubject.asObservable();


  chatEncryptionSharedSecret$ = store.pipe(select(session => session.encryption.ChatEncryptionSharedSecret));
  twilioToken$ = store.pipe(select(session => session.encryption.TwilioToken));
  chatUserSecret$ = store.pipe(select(session => session.encryption.ChatUserSecret));
  chatUserSecretCreationDate$ = store.pipe(select(session => session.encryption.ChatUserSecretCreationDate));
  // fcmToken$ = store.pipe(select(session => session.encryption.FcmToken));
  // appInstanceIdentifier$ = store.pipe(select(session => session.encryption.AppInstanceIdentifier));
  chatEnabled$ = store.pipe(select(session => session.chatEnabled));

  // pushEnabled$ = store.pipe(select(session => session.pushEnabled));


  constructor(private persistStateService: PersistStateService) {
    // logService(this);
    persistStateService.persistState(store, false).subscribe(() => {
      this.sessionReadySubject.next(true);
    });
  }

  /**
   * @deprecated The method should not be used. Please use from sessionRepository instead.
   */
  get appInstanceIdentifier() {
    return store.getValue().encryption.AppInstanceIdentifier;
  }

  get chatUserSecretCreationDate() {
    return store.getValue().encryption.ChatUserSecretCreationDate;
  }

  get chatUserSecret() {
    return store.getValue().encryption.ChatUserSecret;
  }

  get chatEnabled() {
    return store.getValue().chatEnabled;
  }

  updateTwilioToken(twilioToken: string) {
    store.update(
      setProps((state) => ({
        encryption: {
          ...state.encryption,
          TwilioToken: twilioToken
        }
      }))
    );
  }

  updateEncryptionTokens(encryptionTokens: EncryptionTokens) {
    store.update(
      setProps((state) => ({
        encryption: {
          ...state.encryption,
          ChatEncryptionSharedSecret: encryptionTokens.ChatEncryptionSharedSecret,
          ChatUserSecret: encryptionTokens.ChatUserSecret,
          TwilioToken: encryptionTokens.TwilioToken,
          ChatUserSecretCreationDate: encryptionTokens.ChatUserSecretCreationDate
        }
      }))
    );
  }

  updateChatUserSecretCreationDate(chatUserSecretCreationDate: string) {
    store.update(
      setProps((state) => ({
        encryption: {
          ...state.encryption,
          ChatUserSecretCreationDate: chatUserSecretCreationDate
        }
      }))
    );
  }

  updateChatUserSecret(chatUserSecret: string) {
    store.update(
      setProps((state) => ({
        encryption: {
          ...state.encryption,
          ChatUserSecret: chatUserSecret
        }
      }))
    );
  }

  /**
   * @deprecated The method should not be used. Please use from sessionRepository instead.
   */
  pushEnabled() {
    return store.getValue().pushEnabled;
  }

  setChatEnabled(chatEnabled: boolean) {
    store.update(setProps({ chatEnabled }));
  }
}

export interface EncryptionTokens {
  ChatEncryptionSharedSecret: string;
  ChatUserSecret: string;
  TwilioToken: string;
  ChatUserSecretCreationDate: string;
}
