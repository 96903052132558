import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[uiTooltip]'
})
export class UiTooltipDirective implements OnInit {
  @Input() uiTooltip: string;
  @Input() uiTooltipFlow: string;

  constructor(private elementRef: ElementRef,
              private translate: TranslateService) {
  }

  ngOnInit() {
    if (this.uiTooltip) {
      this.setTooltip(this.translate.instant(this.uiTooltip));
      if (this.uiTooltipFlow) {
        this.setTooltipFlow();
      }
    }
  }

  setTooltip(text: string) {
    this.elementRef.nativeElement.setAttribute('tooltip', text);
  }

  setTooltipFlow() {
    this.elementRef.nativeElement.setAttribute('flow', this.uiTooltipFlow);
  }
}
