import { Injectable, SecurityContext } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FilePicker, PickFilesResult } from '@hotend/capacitor-file-picker';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Filesystem } from '@capacitor/filesystem';
import { UiDialogService, UiFileTransferProgress } from '@blink/ui';
import { CheckChecklistItemData } from './types/checklist-data';

// todo refactor the viewmodel logic to smth more generic
// consider this for showing thumbnail on videos:
// https://stackoverflow.com/questions/7323053/dynamically-using-the-first-frame-as-poster-in-html5-video

export interface FileUploadViewModel {
  name: string;
  extension: string;
  type?: 'image' | 'video';
  src?: SafeUrl;
}

export interface FileUploadInfo {
  name: string;
  size: number;
  path: string;
  src: string;
  type: string;
}

export const HANDLE_FILE_UPLOAD = 'HANDLE_FILE_UPLOAD';

// todo expand extension arrays to some more useful (BUT WORKING!) values
const imageExtensions = ['jpg', 'jpeg', 'png'];
const videoExtensions = ['mp4'];


const shouldLoadSrcUrl = (extension: string) =>
  imageExtensions.includes(extension.toLowerCase())
  || videoExtensions.includes(extension.toLowerCase());

const getFileExtension = (fileNameOrPath: string) => {
  const filePath = fileNameOrPath.split('.');
  return filePath[filePath.length - 1];
};

const getFileType = (extension: string): "image" | "video" | undefined => {
  if (imageExtensions.includes(extension.toLowerCase())) {
    return 'image';
  } else if (videoExtensions.includes(extension.toLowerCase())) {
    return 'video';
  }
  return undefined;
};

const getBlobFileFromUrl = (url: string): Promise<File> => new Promise(resolve => {
  const xhr = new XMLHttpRequest(); // TODO: also use fetch?
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.addEventListener('load', () => {
    console.log('resolving promise....');
    const response = xhr.response;
    response.lastModifiedDate = new Date();
    resolve(response);
  });
  xhr.send();
});

@Injectable({ providedIn: 'root' })
export class FillItemFileUploadService {
  platform = Capacitor.getPlatform();

  private fileViewModelsSubject = new BehaviorSubject<{ [itemId: number]: FileUploadViewModel[] }>({});
  private fileViewModels$ = this.fileViewModelsSubject.asObservable();

  private webFiles: { [itemId: number]: File[] } = {};

  constructor(private sanitizer: DomSanitizer,
              private uiDialogService: UiDialogService) {
  }

  async removeFileViewModelFor(itemId: number) {
   const fileViewModelSnapshot = await firstValueFrom(this.fileViewModels$);
   delete fileViewModelSnapshot[itemId];
   this.fileViewModelsSubject.next(fileViewModelSnapshot);
  }

  fileViewModelsFor(itemId: number) {
    return this.fileViewModels$
      .pipe(
        map(x => x[itemId])
      );
  }

  async selectFilesFor(itemId: number): Promise<FileUploadInfo[]> {
    const permissions = await Filesystem.checkPermissions();
    if (permissions.publicStorage === 'prompt' || permissions.publicStorage === 'prompt-with-rationale') {
      await Filesystem.requestPermissions();
    }

    const selection: PickFilesResult = await FilePicker.pickFiles({
      multiple: true
    });


    let fileUploadInfos: FileUploadInfo[] = [];
    switch (this.platform) {
      case 'web':
        fileUploadInfos = this.prepareFileInfosForWeb(itemId, selection);
        break;
      case 'android':
        fileUploadInfos = this.prepareFileInfosForAndroid(selection);
        break;
      case 'ios':
        fileUploadInfos = this.prepareFileInfosForIOS(selection);
        break;
    }

    fileUploadInfos.map(x => {
      this.addFileUploadViewModel(x.name, x.src, itemId);
    });

    return fileUploadInfos;
  }


  deleteFile(itemId: number, fileName: string) {
    if (this.platform === 'web') {
      this.webFiles[itemId] = this.webFiles[itemId].filter(x => x.name !== fileName);
    }
    this.removeFileUploadViewModel(itemId, fileName);
  }

  async getFilesFor(item: CheckChecklistItemData): Promise<File[]> {
    if (this.platform === 'web') {
      return this.webFiles[item.ItemId];
    }

    const fileJson = item.ItemData.replace(HANDLE_FILE_UPLOAD, '');
    const files = JSON.parse(fileJson) as FileUploadInfo[];

    return Promise.all(
      files.map(file => {
        console.log('file', file);
        return getBlobFileFromUrl(file.src)
          .then(blobFile => {
            return new File([blobFile], file.name, { type: file.type });
          })
      })
    );
  }

  public setFileUploadViewModels(itemId: number, fileUploadInfos: FileUploadInfo[]) {
    for (const fileUploadInfo of fileUploadInfos) {
      this.addFileUploadViewModel(fileUploadInfo.name, this.sanitizer.sanitize(SecurityContext.URL, Capacitor.convertFileSrc(fileUploadInfo.path)), itemId);
    }
  }

  async clearFileUploadsFor(itemId: number) {
    const vms = this.fileViewModelsSubject.getValue();
    delete vms[itemId];
    this.fileViewModelsSubject.next(vms);
    if (this.platform === 'web') {
      delete this.webFiles[itemId];
      return;
    }
  }

  showUploadStatusModal(itemIds: number[]) {
    const vms = this.fileViewModelsSubject.getValue();
    const uploadsProgresses: UiFileTransferProgress[] = [];
    itemIds.forEach(itemId => {
      if (vms[itemId]) {
        vms[itemId].forEach(vm => {
          uploadsProgresses.push({
            fileName: vm.name,
            bytes: 0,
            loadedBytes: 0
          });
        });
      }
    });

    console.log({ uploadsProgresses });

    return this.uiDialogService.fileTransferProgress(uploadsProgresses);
  }

  public async isFileExisting(url: string): Promise<boolean> {

    const response = await fetch(url, {
      method: 'HEAD',
      mode: 'no-cors'
    });
    return response.ok;

  }

  private prepareFileInfosForWeb(itemId: number, selection: PickFilesResult): FileUploadInfo[] {
    const fileList: File[] = selection.files.map(x => x.blob as File);

    if (!this.webFiles[itemId]) {
      this.webFiles[itemId] = [];
    }

    return fileList.map(x => {
      this.webFiles[itemId].push(x);

      const objUrl = URL.createObjectURL(x);
      const fileSrc = this.sanitizer.sanitize(SecurityContext.URL, objUrl);
      return {
        name: x.name,
        size: x.size,
        path: fileSrc,
        src: fileSrc,
        type: x.type
      }
    });
  }

  private prepareFileInfosForAndroid(pickFilesResult: PickFilesResult): FileUploadInfo[] {
    return pickFilesResult.files.map(file => {
      const fileSrc = this.sanitizer.sanitize(SecurityContext.URL, Capacitor.convertFileSrc(file.path));
      return {
        name: file.name,
        size: file.size,
        path: file.path,
        src: fileSrc,
        type: file.mimeType
      };
    });
  }

  private prepareFileInfosForIOS(pickFilesResult: PickFilesResult): FileUploadInfo[] {
    return pickFilesResult.files.map(file => {
      return {
        name: file.name,
        size: file.size,
        path: file.path,
        src: Capacitor.convertFileSrc(file.path),
        type: file.mimeType
      };
    });
  }

  private addFileUploadViewModel(fileName: string, fileSrc: SafeUrl, itemId: number) {

    const newFile: FileUploadViewModel = {
      name: fileName,
      extension: getFileExtension(fileName)
    };
    if (shouldLoadSrcUrl(newFile.extension)) {
      newFile.src = fileSrc;
      newFile.type = getFileType(newFile.extension);
    }

    const vms = this.fileViewModelsSubject.getValue();
    let itemVms = vms[itemId];
    if (!itemVms) {
      itemVms = [];
    }
    itemVms = [...itemVms, newFile];
    this.fileViewModelsSubject.next({
      ...vms,
      [itemId]: itemVms
    });
  }

  private removeFileUploadViewModel(itemId: number, fileName: string) {
    const vms = this.fileViewModelsSubject.getValue();
    this.fileViewModelsSubject.next({
      ...vms,
      [itemId]: vms[itemId].filter(x => x.name !== fileName)
    });
  }
}
