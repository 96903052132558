import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, from, Observable, Subscription, tap } from 'rxjs';
import { BlinkLocation, BlinkLocationApi, BlinkLocationRepository } from '@blink/api';
import { map } from 'rxjs/operators';
import { GlobalTranslateService, trackById } from '@blink/util';

@Component({
  selector: 'check-cost-centre-search',
  templateUrl: './cost-centre-search.component.html',
  styleUrls: ['./cost-centre-search.component.scss']
})
export class CostCentreSearchComponent implements OnDestroy {
  @ViewChild('searchRow') searchRowObj;
  trackById = trackById;

  subscriptions = new Subscription();

  searchTerm$ = new BehaviorSubject<string>('');
  showInactive$ = new BehaviorSubject(false);
  locations$: Observable<BlinkLocation[]> = combineLatest([
    from(this.locationApi.getLocationsForCheck()).pipe(tap(locations => console.log('blörp', locations))),
    this.searchTerm$,
    this.showInactive$
  ]).pipe(map(([locations, searchTerm, showInactive]) =>
    locations
      .filter(x => {
        return searchTerm === ''
          || x.Name.toLowerCase().includes(searchTerm.toLowerCase())
          || x.CostCenterNumber.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter(x => showInactive || x.IsActive)
  ));

  constructor(
    public modalController: ModalController,
    public t: GlobalTranslateService,
    private locationApi: BlinkLocationApi,
    private locationRepo: BlinkLocationRepository) {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  search(event) {
    this.searchTerm$.next(event.detail.value);
  }

  onCostCenterSelected(location: BlinkLocation) {
    this.locationRepo.upsertLocations([location]);
    void this.modalController.dismiss(location, 'locationSelected');
  }

  toggleShowInactive(ev) {
    this.showInactive$.next(ev.detail.checked);
  }
}
