import { Component, Input } from '@angular/core';

@Component({
  selector: 'check-rate-display',
  templateUrl: './rate-display.component.html',
  styleUrls: ['./rate-display.component.scss']
})
export class RateDisplayComponent {
  @Input() value;
  @Input() size = 'lg';

  constructor() {
    window['rate'] = this;
  }
}
