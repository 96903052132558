import { AfterViewInit, Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'ui-dialog-pdf',
  template: `
      <ui-page [title]="title" [modalDismiss]="true">
          <ngx-extended-pdf-viewer *ngIf="showPdf"
                                   [base64Src]="base64Src"
                                   [showToolbar]="true"
                                   [showSidebarButton]="true"
                                   [showFindButton]="false"
                                   [showPagingButtons]="true"
                                   [showZoomButtons]="true"
                                   [showPresentationModeButton]="false"
                                   [showOpenFileButton]="false"
                                   [showPrintButton]="false"
                                   [showDownloadButton]="showDownloadButton"
                                   [filenameForDownload]="title"
                                   [showSecondaryToolbarButton]="true"
                                   [showRotateButton]="false"
                                   [showHandToolButton]="false"
                                   [showScrollingButton]="false"
                                   [showSpreadButton]="false"
                                   [showPropertiesButton]="false"
                                   [mobileFriendlyZoom]="isApp ? '150%': '100%'"
                                   [language]="languageKey">
          </ngx-extended-pdf-viewer>
      </ui-page>
  `
})
export class UiDialogPdfComponent implements AfterViewInit {
  showPdf = false;

  title: string;
  base64Src: string;
  showDownloadButton = false;
  languageKey: string;


  isApp = Capacitor.getPlatform() !== 'web';


  constructor() {
    window['pdfModal'] = this;
  }

  ngAfterViewInit() {
    // hack to make it possible to open the modal more than once...
    setTimeout(() => this.showPdf = true);
  }

}
