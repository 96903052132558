import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../../shared/api-manager';
import { tap } from 'rxjs/operators';

import { CheckGroupDependency } from './group-dependency';
import { CheckGroupDependencyRepository } from './group-dependency.repository';

@Injectable({ providedIn: 'root' })
export class CheckGroupDependencyApi {
  private api = this.apiManager
    .createApiForEndpoint<CheckGroupDependency>(
      'odata/v1',
      'GroupDependencies',
      BlinkService.Check
    );

  constructor(private apiManager: ApiManager,
              private groupDependencyRepo: CheckGroupDependencyRepository) {
  }

  create(itemDependency: Partial<CheckGroupDependency>) {
    return this.api.post({ body: itemDependency }).pipe(tap(
      d => this.groupDependencyRepo.sync([d], false)
    ));
  }

  delete(id: number) {
    return this.api.delete({ key: id }).pipe(tap(
      () => this.groupDependencyRepo.delete(id)
    ));
  }
}
