import { Injectable } from '@angular/core';
import { ApiManager, BlinkService, TypedQuery } from '../../shared/api-manager';
import { BlinkLocation } from './location';
import { BlinkLocationRepository } from './location.repository';
import { map, tap } from "rxjs/operators";
import { TimeTrackingLocationActivity } from '../../time-tracking';
import dayjs from 'dayjs';
import { firstValueFrom, Observable } from 'rxjs';
import { htmlDateFormat } from '@blink/util';
import { CompanyRepository } from '../company/company.repository';
import { UiInputSelectItem } from '@blink/ui';


export interface BlinkLocationQuery {
  search?: string;
  top: number;
  skip: number;
  total: number;
  items: BlinkLocation[];
}

@Injectable({ providedIn: 'root' })
export class BlinkLocationApi {
  private api = this.apiManager
    .createApiForEndpoint<BlinkLocation>(
      'odata/v2',
      'Locations',
      BlinkService.Core
    );

  private searchApi = this.apiManager
    .createApiForEndpoint<BlinkLocation>(
      'odata/v1',
      'LocationsSearchDetails',
      BlinkService.Core
    );

  private locationActivitiesApi = this.apiManager
    .createApiForEndpoint<TimeTrackingLocationActivity>(
      'odata/v1',
      'LocationActivities',
      BlinkService.Core
    );

  constructor(private apiManager: ApiManager,
              private locationRepo: BlinkLocationRepository,
              private companyRepository: CompanyRepository) {
  }

  getLocationsForCheck(): Promise<BlinkLocation[]> {
    const query: TypedQuery<BlinkLocation> = {
      filter: { CompanyId: this.companyRepository.getActiveCompanyId() },
      select: [
        'Id',
        'Name',
        'ObjectNumber',
        'CostCenterNumber',
        'Description',
        'ExternalId',
        'UpdateDate',
        'IsActive'
      ],
      expand: ['LocationQrCodes']
    };
    return this.api.get<BlinkLocation[]>(query).toPromise();
  }

  getLocationForCheckFilter(locationId: number) {
    const query: TypedQuery<BlinkLocation> = {
      key: locationId,
      select: [
        'Id',
        'Name'
      ]
    };
    return firstValueFrom(this.api.get<BlinkLocation>(query));
  }

  getLocationByQrCode(qrCode) {
    const query = {
      key: 'single',
      func: {
        'Services.GetLocationByLocationQrCode': {
          locationQrCodeId: qrCode
        }
      }
    }
    return this.api.get<BlinkLocation>(query).pipe(
      tap(location => {
        this.locationRepo.upsertLocations([{
          ...location,
          LocationQrCodes: [{
            Id: null,
            Active: true,
            LocationActivityId: null,
            LocationId: location.Id,
            LocationQrCodeId: qrCode
          }]
        }])
      })
    );
  }

  searchLocations(searchQuery: BlinkLocationQuery) {
    const query: TypedQuery<BlinkLocation> = {
      count: true,
      top: searchQuery.top,
      skip: searchQuery.skip,
      select: [
        'Id',
        'Name'
      ],
      service: BlinkService.Core,
      filter: {
        Active: true
      }
    };

    if (searchQuery.search) {
      query['search'] = searchQuery.search;
    }

    return this.apiManager.get('odata/v1', 'LocationsSearchDetails', query, true)
      .pipe(map((response) => {
        searchQuery.total = response.count;
        searchQuery.items = searchQuery.items.concat(response.data.map((location) => location as BlinkLocation));
        return searchQuery;
      }));
  }

  getLocationActivities(locationId: number, date: string = dayjs().format(htmlDateFormat)) {
    return this.apiManager
      .get('odata/v1', `LocationActivities/Services.GetActiveLocationActivities(locationId=${locationId}, date=${dayjs(date).format(htmlDateFormat)})`,
        {
          service: BlinkService.Core
        }, false)
  }

  autocompleteSearch = (companyId?: number) => (term: string): Observable<Array<UiInputSelectItem>> => {
    const query: TypedQuery<BlinkLocation> = {
      filter: {
        Active: true
      },
      search: term.replace(/'/g, '')
    };

    if (companyId) {
      query['filter']['CompanyId'] = companyId;
    } else if (this.companyRepository.getActiveCompanyId()) {
      query['filter']['CompanyId'] = this.companyRepository.getActiveCompanyId();
    }

    return this.searchApi.get<BlinkLocation[]>(query).pipe(
      map(location =>
        location.map(l => ({
          id: l.Id,
          name: l.Name
        } as UiInputSelectItem))
      ));
  }

  fetchForCompany(companyId: number): Observable<Array<UiInputSelectItem>> {
    const query: TypedQuery<BlinkLocation> = {
      filter: {
        CompanyId: companyId
      },
      select: [
        'Id',
        'Name'
      ]
    };
    return this.api.get<BlinkLocation[]>(query).pipe(
      map(location =>
        location.map(l => ({
          id: l.Id,
          name: l.Name
        } as UiInputSelectItem))
      ))
  }
}
