export default {
  home: {
    pageTitle: "Meine Formulare",
    locationChecklists_label: "Formular für ein Objekt ausfüllen",
    generalChecklists_label: "Allgemeines Formular ausfüllen",
    processingChecklists_label: "Formular in Bearbeitung",
    checklistDatas_label: "Ausgefüllte Formulare",
    selectLocation: "Objekt auswählen",
    scanQrCode: "Objekt Code scannen",
    showAll: "... alle anzeigen",
    showLess: "... weniger anzeigen"
  },
  fill: {
    checklists: 'Formulare',
    fillChecklist: 'Formular ausfüllen',
    checklistNotFound: 'Das gewünschte Formular konnte leider nicht gefunden werden.',
    checklistOfflineLoginUserRequired: 'Für dieses Formular ist die Auswahl eines Mitarbeiters erforderlich. Bitte stellen Sie eine Internet-Verbindung her, um die Formularvorlage aufrufen zu können.',
    checklistNotOfflineAvailable: 'Die Formularvorlage ist offline nicht gespeichert, bitte stellen Sie eine Internet-Verbindung her, um die Formularvorlage aufrufen zu können.',
    resetConfirm: 'Wollen Sie das Formular wirkich zurücksetzen?',

    categoryAppeared: 'Kategorie "{{categoryName}}" wurde hinzugefügt.',
    groupAppeared: 'Seite "{{groupName}}" wurde hinzugefügt.',
    itemAppearedInGroup: 'Es wurden neue Fragen in der Seite "{{groupName}}" hinzugefügt.',
    openGroupsAvailable: 'openGroupsAvailable',
    toNextGroup: 'Zur nächsten Frage',
    containerUriExpired: 'Der Zeitraum zum Hochladen ist leider abgelaufen.',

    finishMessage: 'Sie haben alle erforderlichen Daten erfasst und können das Formular jetzt abschließen.',
    offlineMessage: 'Bitte stellen sie eine Verbindung zum Internet her, um das Formular abzuschließen.',
    offlinePreviewMessage: 'Bitte stellen sie eine Verbindung zum Internet her, um eine Vorschau zu erzeugen.',
    checklistUpdatedHeader: 'Formularvorlage geändert',
    checklistUpdatedMessage: 'Die Formularvorlage wurde geändert. Bitte prüfen Sie nochmals Ihre Eingaben bevor Sie das Formular abschicken.',
    checklistLocationRequiredHeader: 'Objekt auswählen',
    checklistLocationRequiredMessage: 'Sie müssen ein Objekt auswählen, um dieses Formular ausfüllen zu können.',
    fileUploadFileMissingHeader: 'Dateien nicht gefunden',
    fileUploadFileMissingMessage: 'Folgende Dateien wurden ausgewählt, können aber nicht auf dem Gerät gefunden werden: {{ missingFiles }}',
    finishSuccess: 'Das Formular wurde erfolgreich abgeschlossen und alle Daten wurden verarbeitet.',
    finishError: 'Das Formular konnte aufgrund eines Fehlers nicht abgeschlossen werden. Bitte wenden Sie sich an den Support.',
    createdWith: 'Dieses Formular wurde erstellt mit',
    preview: 'Vorschau'
  },

  item: {
    boolean: {
      yes: 'Ja',
      no: 'Nein'
    },
    scan: 'Scan',
    email: {
      invalidEmail: "Bitte überprüfen Sie ihre Eingabe."
    },
    takePicture: {
      picture: "Bild",
      buttonText: "Foto hinzufügen"
    },
    createTask: {
      taskTitle: "Titel des Tickets",
      taskDescription: "Beschreibung des Tickets",
      taskDueDate: "Fälligkeitsdatum des Tickets"
    }
  },
  noChecklistsForLocationFound: "Für dieses Objekt gibt es kein Formular. Bitte wählen Sie ein anderes Objekt oder füllen Sie ein allgemeines Formular aus.",
  selectChecklistByLocation: "Formular für Objekt auswählen",
}
