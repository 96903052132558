<ion-content class="popover-content">
  <ion-datetime #dateTime
                (ionChange)="onDateTimeChange($event)"
                [cancelText]="t.cancel | translate"
                [doneText]=" t.ok | translate"
                [max]="maxDate"
                [min]="minDate"
                [ngModel]="value"
                [presentation]="presentation"
                first-day-of-week="1"
                size="cover"
                locale="{{t.currentLanguageKey}}">
  </ion-datetime>
  <div class="flex ion-justify-content-around">
    <ion-button (click)="setTimeNow()"
                *ngIf="presentation === 'time' && setNowButton"
                fill="clear">
      {{ t.now | translate }}
    </ion-button>
    <ion-button (click)="submitTime()"
                *ngIf="presentation === 'time'"
                fill="clear">
      {{ t.ok | translate }}
    </ion-button>
  </div>
  <ion-button *ngIf="presentation === 'date'" fill="clear" (click)="submitToday()">
    {{ t.today | translate }}
  </ion-button>
</ion-content>
