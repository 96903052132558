import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlinkIcon, UiDialogService } from '@blink/ui';
import { Platform, PopoverController } from '@ionic/angular';
import { copyTextToClipboard, getContrastColor, GlobalTranslateService } from '@blink/util';
import { ChecklistDropdownComponent } from '../checklist-dropdown-popover/checklist-dropdown.component';
import { CheckChecklist, SessionRepository } from '@blink/api';

@Component({
  selector: 'check-checklist-lineitem',
  templateUrl: './checklist-lineitem.component.html',
  styleUrls: ['./checklist-lineitem.component.scss']
})
export class ChecklistLineItemComponent implements OnInit {
  BlinkIcon = BlinkIcon;
  @Input() checklist: CheckChecklist;
  @Input() showExportButton: boolean;
  @Input() showDuplicateButton: boolean;
  @Input() showCopyPublicLinkButton = false;
  @Input() showIsStarted = false;
  @Output() checklistClick = new EventEmitter<number>();
  @Output() exportClick = new EventEmitter<number>();
  @Output() duplicateClick = new EventEmitter<number>();
  protected readonly getContrastColor = getContrastColor;

  constructor(public t: GlobalTranslateService,
              public platform: Platform,
              private popoverController: PopoverController,
              private uiDialogService: UiDialogService,
              private sessionRepo: SessionRepository) {
  }

  ngOnInit() {

    const canManageCheck = this.sessionRepo.getUserHasPermissions(['CanManageCheck']);
    if (!canManageCheck) {
      this.showDuplicateButton = false;
      this.showExportButton = false;
    }

    if (!this.checklist.IsPublic) {
      this.showCopyPublicLinkButton = false;
    }
  }

  async checklistDropdown(event: MouseEvent, checklistId) {
    event.stopPropagation();


    const groupDropdown = await this.popoverController.create({
      component: ChecklistDropdownComponent,
      componentProps: {
        showCopyPublicLinkButton: this.showCopyPublicLinkButton,
        showDuplicateButton: this.showDuplicateButton,
        showExportButton: this.showExportButton,
      },
      event: event,
      showBackdrop: false,
    });

    groupDropdown.onWillDismiss().then(result => {
      if (result.role === 'duplicate') {
        this.duplicateClick.emit(checklistId);
      } else if (result.role === 'export') {
        this.exportClick.emit(checklistId);
      } else if (result.role === 'copyPublicLink') {
        this.copyShortUrl()
      }
    });

    await groupDropdown.present();
  }

  copyShortUrl() {
    copyTextToClipboard(this.checklist.ShortUrl);
    this.uiDialogService.toast(this.t.linkCopied);
  }

  showContextMenu(): boolean {
    return this.platform.width() < 501 && (this.showCopyPublicLinkButton || this.showDuplicateButton || this.showExportButton);
  }

}
