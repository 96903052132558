import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginShellComponent } from './login-shell';
import { TranslateModule } from '@ngx-translate/core';
import { BlinkUiModule } from '@blink/ui';
import { BlinkCoreModule } from '../core.module';
import { RouterModule, Routes } from '@angular/router';
import { BlinkIdFlowComponent } from './blink-id-flow/blink-id-flow';
import { LoginService } from './login.service';
import { LoginSuccessComponent } from './login-success/login-success';
import { CredentialsFlowComponent } from './credentials-flow/credentials-flow';
import { EnterVerificationCodeComponent } from './blink-id-flow/two-factor-auth/enter-verification-code/enter-verification-code';
import { SelectAuthModeComponent } from './blink-id-flow/two-factor-auth/select-auth-mode/select-auth-mode';
import { ResetPasswordComponent } from './credentials-flow/reset-password/reset-password';
import { BlinkLoginConfigService, BlinkLoginConfig } from './utils/injection-tokens';


const loginRoutes: Routes = [
  {
    path: 'register',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginShellComponent
  },
  {
    path: 'login/blink-id',
    component: BlinkIdFlowComponent
  },
  {
    path: 'login/enter-code',
    component: EnterVerificationCodeComponent
  },
  {
    path: 'login/credentials',
    component: CredentialsFlowComponent
  },
  {
    path: 'login/select-auth-mode',
    component: SelectAuthModeComponent
  },
  {
    path: 'login/success',
    component: LoginSuccessComponent
  },
  {
    path: 'passwordreset/:code/:loginUserId',
    redirectTo: '/login/passwordreset/:code/:loginUserId',
  },
  {
    path: 'login/passwordreset/:code/:loginUserId',
    component: ResetPasswordComponent
  },
];


@NgModule({
  declarations: [
    LoginShellComponent,
    EnterVerificationCodeComponent,
    SelectAuthModeComponent,
    CredentialsFlowComponent,
    ResetPasswordComponent,
    LoginSuccessComponent,
    BlinkIdFlowComponent,
  ],
  exports: [],
  imports: [
    IonicModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlinkUiModule,
    BlinkCoreModule,
    RouterModule.forChild(loginRoutes),
  ],
  providers: [
    { provide: LoginService, useClass: LoginService }
  ]
})
export class BlinkLoginModule {
  static forRoot(config: BlinkLoginConfig) {
    return {
      ngModule: BlinkLoginModule,
      providers: [
        { provide: BlinkLoginConfigService, useValue: config }
      ]
    };
  }
}
