export default {
  barcodeScanner: {
    permissionHeader: 'Разрешение',
    permissionMessage: 'Если вы хотите дать разрешение на использование вашей камеры, включите его в настройках приложения.',
    scanPrompt: 'Сканировать {{thing}}',
    enterPrompt: 'Введите {{thing}}',
    blinkId: 'Идентификатор мигания',
    isNoBlinkId: 'Отсканированный QR-код не является действительным Blink ID',
    objectCode: 'Код объекта'
  },
  camera: {
    promptLabelHeader: 'Выберите источник',
    promptLabelPicture: 'Сделать фотографию',
    promptLabelPhoto: 'Выберите из галереи'
  },
  network: {
    changed: {
      connected: 'Подключение к Интернету установлено',
      disconnected: 'Прерванное подключение к Интернету'
    }
  },
  file: { downloadStarted: 'Загрузка началась.' },
  nfc: {
    wrongCode: 'Отсканированный QR-код не является кодом объекта!',
    error: 'Ошибка NFC'
  }
};
