import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import {
  deleteEntities,
  getAllEntities,
  selectManyByPredicate,
  updateEntities,
  upsertEntities,
  withEntities
} from "@ngneat/elf-entities";
import { BlinkNotification } from "./model/BlinkNotification";
import { PersistStateService } from "../shared";
import { NotificationTypeInternalId } from './model/BlinkNotificationEnums';
import { map, tap } from 'rxjs/operators';
import dayjs from 'dayjs';
import { BehaviorSubject, combineLatestWith } from "rxjs";
import { logService } from '@blink/util';
import { SessionRepository } from '../core';

const store = createStore(
  { name: 'blink-notifications' },
  withEntities<BlinkNotification, 'Id'>({ idKey: 'Id' }),
);

@Injectable({ providedIn: 'root' })
export class BlinkNotificationsRepository {
  store = store;
  unreadStickersSynced$: BehaviorSubject<BlinkNotification[]> = new BehaviorSubject<BlinkNotification[]>(null);

  unreadStickers$ = store.pipe(
    selectManyByPredicate((entity) =>
      entity.NotificationTypeInternalId === NotificationTypeInternalId.Sticker &&
      entity.Receivers.some(t => t.ReadDate === null && t.ReceiverId === this.sessionRepository.getUserContext().LoginUserId) &&
      entity.CreationDate !== null),
    tap(results => {
      results.sort((a, b) => dayjs(b.CreationDate).unix() - dayjs(a.CreationDate).unix())
    }));

  private allStickers$ = store.pipe(
    selectManyByPredicate((entity) =>
      entity.NotificationTypeInternalId === NotificationTypeInternalId.Sticker && entity.PredefinedImage !== null));

  receivedStickers$ = this.allStickers$.pipe(
    combineLatestWith(this.sessionRepository.userContext$),
    map(([stickers, userContext]) =>
      stickers.filter((sticker) =>
        sticker.Receivers.some((receiver) => receiver.ReceiverId === userContext.LoginUserId))
    ));

  sendStickers$ = this.allStickers$.pipe(
    combineLatestWith(this.sessionRepository.userContext$),
    map(([stickers, user]) =>
      stickers.filter((sticker) => sticker.SenderId === user.LoginUserId)
    ));

  constructor(persistStateService: PersistStateService,
              private sessionRepository: SessionRepository) {
    logService(this);
    persistStateService.persistState(store).subscribe();
  }

  sync(notifications: BlinkNotification[], removeAbsent: boolean, type: 'received' | 'send') {
    store.update(upsertEntities(notifications));
    if (removeAbsent) {
      const ids = notifications.map(n => n.Id);
      const loginUserId = this.sessionRepository.getUserContext().LoginUserId;
      const absentNotificationIds = store.query(getAllEntities())
        .filter(n => n.NotificationTypeInternalId === NotificationTypeInternalId.Sticker
          && (type === "received" ? n.Receivers.some((receiver) => receiver.ReceiverId === loginUserId) : n.SenderId === loginUserId)
          && !ids.includes(n.Id))
        .map(n => n.Id);

      store.update(deleteEntities(absentNotificationIds));

      if (type === 'received') {
        const unreadStickers = notifications
          .filter(n => n.NotificationTypeInternalId === NotificationTypeInternalId.Sticker
            && n.Receivers.some(t => t.ReadDate === null));

        if (unreadStickers.length > 0) {
          this.unreadStickersSynced$.next(unreadStickers);
        }
      }
    }
  }

  markAsRead(notificationId: number, receiverId: number, readDate: string) {
    store.update(updateEntities(notificationId, (entity) => ({
      ...entity, Receivers: entity.Receivers.map(r => {
        if (r.Id === receiverId) {
          r.ReadDate = readDate;
        }
        return r;
      })
    })));
  }
}
