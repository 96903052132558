import { Injectable } from '@angular/core';
import { ApiManager, BLINK_SERVICE_HTTP_TOKEN, BlinkService, HANDLE_ERROR } from '../../shared';
import { catchError, finalize, map, switchMap, timeout } from 'rxjs/operators';
import { SessionRepository } from '../session/session.repository';
import { HttpContext, HttpContextToken } from '@angular/common/http';
import { LoginParams } from './LoginParams';
import { AlertController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom, from, Observable, Subject } from "rxjs";
import { LoginUser } from '@blink/shared-blink-types';
import { Router } from "@angular/router";
import { ResetPasswordParams } from './ResetPasswordParams';
import { DeviceInfoResponse, DeviceServices } from '@blink/shared/feature/devices/main';
import { CompanyRepository } from '../company/company.repository';
import { UiDialogService } from '@blink/ui';

@Injectable({ providedIn: 'root' })
export class AuthApi {
  AUTH_INTERCEPTION = new HttpContextToken<boolean>(() => true);

  private loginInProcessSubject = new Subject<boolean>();
  loginInProcess$ = this.loginInProcessSubject.asObservable();

  constructor(private apiManager: ApiManager,
              private sessionRepository: SessionRepository,
              private navController: NavController,
              private alertController: AlertController,
              private translate: TranslateService,
              private deviceServices: DeviceServices,
              private uiDialogService: UiDialogService,
              private router: Router,
              private companyRepository: CompanyRepository) {
  }

  unRegisterApp(appInstanceIdentifier: string) {
    const data = {
      body: {
        AppInstanceIdentifier: appInstanceIdentifier
      },
      service: BlinkService.Core
    };

    return this.apiManager.post('api/v1', 'UnregisterApp', data).subscribe();
  }

  login(params: LoginParams): Observable<AuthResponse> {
    this.loginInProcessSubject.next(true);
    return from(this.deviceServices.getDeviceInfo()).pipe(
      switchMap(deviceInfo => {
        this.sessionRepository.setSystem(params.system);
        const extendedParams = mapLoginParams(params, deviceInfo);
        const options = {
          key: 'single',
          body: extendedParams,
          httpOptions: {
            context: new HttpContext()
              .set(this.AUTH_INTERCEPTION, false)
              .set(HANDLE_ERROR, false)
              .set(BLINK_SERVICE_HTTP_TOKEN, BlinkService.Core)
          }
        };
        return this.apiManager.post('api/v2', 'auth', options).pipe(
          catchError((err) => {
            this.uiDialogService.errorOccurred();
            throw err;
          }),
          finalize(() => this.loginInProcessSubject.next(false))
        );
      })
    );
  }


  loginAndHandleResult(params: LoginParams) {
    this.login(params)
      .subscribe((auth: AuthResponse) => {
        if (auth.id_token) {
          console.log('auth.id_token', auth.id_token);
          this.sessionRepository.setTokens(auth.id_token, auth.refresh_token);
          this.navController.navigateForward('register/success');
        } else {
          this.handleAuthResponse(params, auth);
        }
      });
  }

  searchLoginUsers(term: string): Observable<LoginUser[]> {
    const options = {
      func: {
        SearchLoginUsers: { searchText: term }
      },
      service: BlinkService.Core
    };

    return this.apiManager.get('odata/v1', 'LoginUsers', options, false);
  }

  searchLocations(term: string) {
    const options = {
      filter: {
        CompanyId: this.companyRepository.getActiveCompanyId(),
        IsActive: true
      },
      search: term,
      service: BlinkService.Core
    };

    return this.apiManager.get('odata/v2', 'Locations', options, false);
  }

  showWrongCredentialsError() {
    this.alertController.create({
      message: this.translate.instant('typedGlobal.REGISTER_MANUALLY_PAGE.WRONG_CREDENTIALS'),
      buttons: [{
        text: 'OK'
      }]
    }).then(alert => alert.present());
  }

  registerPasswordless(params: LoginParams) {
    const options = {
      body: {
        AuthMode: params.authMode,
        BlinkId: params.blinkId,
        EMail: params.email,
        OneTimePassword: params.code || params.password,
        PhoneNumber: params.phoneNumber
      },
      service: BlinkService.Core
    };
    return this.apiManager.post('api/v1', 'PasswordLess/Registration', options);
  }

  forgotPassword(email, system) {
    this.sessionRepository.setSystem(system);

    const options = {
      handleError: false,
      service: BlinkService.Core
    }

    return this.apiManager.post('api/v1', `UsernamePassword/RequestMail/SetPassword?email=${email}`, options);
  }

  refreshToken(): Observable<string> {
    return from(this.deviceServices.getDeviceInfo()).pipe(
      switchMap(deviceInfo => {
        const options = {
          body: {
            Number: deviceInfo.Number,
            Type: deviceInfo.Type,
            DeviceInfo: JSON.stringify(deviceInfo.DeviceInfo)
          },
          httpOptions: {
            context: new HttpContext()
              .set(this.AUTH_INTERCEPTION, false)
              .set(BLINK_SERVICE_HTTP_TOKEN, BlinkService.Core),
            params: {
              refreshToken: this.sessionRepository.getRefreshToken()
            }
          }
        };
        return this.apiManager.post('api/v1', 'Token/Refresh', options).pipe(
          timeout(5000),
          map((auth: AuthResponse) => {
            this.sessionRepository.setTokens(auth.id_token, auth.refresh_token);
            return auth.id_token;
          })
        );
      })
    );
  }

  getApiVersion() {
    return this.apiManager.get('api/v1', 'Version', { key: 'single', service: BlinkService.Core });
  }

  resetPassword(params: ResetPasswordParams) {
    const options = {
      body: {
        LoginUserId: params.LoginUserId,
        Code: params.Code,
        Password: params.Password
      },
      service: BlinkService.Core
    };
    return firstValueFrom(this.apiManager.post('api/v1', 'UsernamePassword/SetPassword', options));
  }

  async loginWithTransferToken(system: string, authTransferToken: string) {
    this.loginInProcessSubject.next(true);
    const deviceInfo = await this.deviceServices.getDeviceInfo();
    this.sessionRepository.setSystem(system);
    const transferParams = {
      AuthTransferToken: authTransferToken,
      Device: {
        Number: deviceInfo.Number,
        Type: deviceInfo.Type,
        DeviceInfo: JSON.stringify(deviceInfo.DeviceInfo)
      }
    };

    const options = {
      key: 'single',
      body: transferParams,
      httpOptions: {
        context: new HttpContext()
          .set(this.AUTH_INTERCEPTION, false)
          .set(BLINK_SERVICE_HTTP_TOKEN, BlinkService.Core)
      }
    };
    this.apiManager.post('api/v1', 'AuthWithTransferToken', options).pipe(
      finalize(() => this.loginInProcessSubject.next(false))
    ).subscribe((auth: AuthResponse) => {
      if (auth.id_token) {
        this.sessionRepository.setTokens(auth.id_token, auth.refresh_token);
        this.navController.navigateForward('register/success');
      }
    });
  }

  private handleAuthResponse(params: LoginParams, auth: AuthResponse) {
    if (auth.id_token) {
      this.navController.navigateRoot('register/success');
    } else {
      switch (auth.token_type) {
        case 'error':
        case 'locked':
        case 'new':
          this.alertController.create({
            message: this.translate.instant('typedGlobal.REGISTRATION.INVALID_CARD'),
            buttons: [{
              text: 'OK'
            }]
          }).then(alert => alert.present());
          break;
        case 'assigned':
          this.navController.navigateForward(`register/auth-mode`, { state: { loginParams: params } });
          break;
        case 'no match':
          this.showWrongCredentialsError();
          break;
        default:
          this.router.navigate(['register/enter-code'],
            { state: { loginParams: Object.assign(params, { auth: JSON.parse(auth.token_type) }) } });
          break;
      }
    }
  }
}

function mapLoginParams(params: LoginParams, deviceInfo: DeviceInfoResponse) {
  return {
    Username: params.login || params.blinkId,
    Password: params.code || params.password,
    AuthMode: params.authMode, // 'pwd',
    Device: {
      Number: deviceInfo.Number,
      Type: deviceInfo.Type,
      DeviceInfo: JSON.stringify(deviceInfo.DeviceInfo)
    }
  };
}

export interface AuthResponse {
  access_token: string;
  id_token: string;
  refresh_token: string;
  token_type: string;
}
