export default {
  home: {
    pageTitle: 'Αρχική σελίδα',
    welcome: 'Καλώς ήρθατε στην εφαρμογή Blink me. Καλή κουβέντα,\n\nΗ ομάδα σας Blink',
    startChat: 'αρχίζουν',
    ticket: 'εισιτήριο',
    timeLicenseMissing: 'Δεν σας έχει εκχωρηθεί άδεια χρήσης Blink Time!'
  },
  conversations: {
    pageTitle: 'Νέα',
    yourChats: 'Οι συνομιλίες σας',
    chat: 'Κουβέντα'
  },
  settings: {
    settings: 'Ρυθμίσεις',
    pushEnabled: 'Λάβετε ειδοποιήσεις push',
    system: 'Σύστημα',
    languageSelection: 'επιλογή γλώσσας',
    currentSettings: 'Τρέχουσες Ρυθμίσεις',
    user: 'χρήστης',
    appVersion: 'Έκδοση (εφαρμογή)',
    apiVersion: 'Έκδοση (API)',
    deviceInfo: 'Έκδοση συσκευής',
    buildVersion: 'έκδοση (κατασκευή)',
    helpAndDataPolicy: 'Βοήθεια και προστασία δεδομένων',
    text1: 'Η ομάδα υποστήριξής μας θα χαρεί να σας βοηθήσει εάν έχετε οποιεσδήποτε ερωτήσεις:',
    phone: 'τηλέφωνο',
    mail: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
    information: 'πληροφορίες',
    documentation: 'Ηλεκτρονική τεκμηρίωση',
    documentationText: 'Στην ηλεκτρονική μας τεκμηρίωση θα βρείτε οδηγίες και βίντεο για όλους τους σημαντικούς τομείς του Blink.',
    dataPolicy: 'προστασία δεδομένων',
    support: 'Υποστήριξη',
    moreActions: 'Περαιτέρω ενέργειες',
    protocol: 'πρωτόκολλο',
    showChatUserSecret: 'Κοινή χρήση συνομιλίας με άλλη συσκευή',
    scanCodeAnotherDevice: 'Σαρώστε τον παρακάτω κωδικό QR στη νέα σας συσκευή ή πληκτρολογήστε τον προσωπικό σας κωδικό συνομιλίας στη νέα συσκευή!',
    yourPersonalCode: 'Ο προσωπικός σας κωδικός συνομιλίας',
    permissions: {
      title: 'Γνωστοποίηση',
      subtitle: 'Είναι απαραίτητο να λαμβάνετε ειδοποιήσεις push για μηνύματα συνομιλίας, ειδοποιήσεις που αναβοσβήνουν και αυτοκόλλητα.',
      grantPermission: 'χορηγήσει άδεια',
      camera: 'ΦΩΤΟΓΡΑΦΙΚΗ ΜΗΧΑΝΗ',
      cameraHint: 'Απαραίτητο για καταγραφή χρόνου μέσω αντικειμενικού κωδικού.',
      nfc: 'NFC',
      nfcHint: 'Απαραίτητο για εγγραφή χρόνου μέσω ετικέτας NFC.',
      media: 'Λήψη εικόνας και εικόνας από τη βιβλιοθήκη',
      mediaHint: 'Απαραίτητο για τη λήψη εικόνων και εγγράφων που σχετίζονται με τις ώρες και τα εισιτήριά σας.',
      location: 'Τοποθεσία',
      locationHint: 'Είναι απαραίτητο να καταγράψετε την τοποθεσία σας τη στιγμή της εγγραφής.',
      notifications: 'Push ειδοποιήσεις',
      notificationsHint: 'Είναι απαραίτητο να λαμβάνετε ειδοποιήσεις push για μηνύματα συνομιλίας, ειδοποιήσεις που αναβοσβήνουν και αυτοκόλλητα.',
      locationDisabledOnSystem: 'Οι υπηρεσίες τοποθεσίας είναι απενεργοποιημένες. Ενεργοποιήστε το στις ρυθμίσεις της συσκευής σας.'
    }
  },
  connectionState: {
    connecting: 'Συνδέω-συωδεομαι...',
    connected: 'Δεμένα μαζί.',
    disconnecting: 'Η σύνδεση έχει αποσυνδεθεί...',
    disconnected: 'η σύνδεση χάθηκε',
    denied: 'Δεν ήταν δυνατή η δημιουργία σύνδεσης.'
  },
  noOneToFind: 'Δεν βρέθηκε κανένα.',
  nothingToFind: 'Τίποτα δεν μπορεί να βρεθεί.',
  noLocationFound: 'Δεν βρέθηκαν αντικείμενα.',
  searchPlaceholder: 'Ψάχνω...',
  employeeSearchPlaceholder: 'Όνομα Όνομα',
  typeToSearch: 'Πατήστε για αναζήτηση...',
  typeToSearchEmployee: 'Εισαγάγετε το όνομα του ατόμου για να ξεκινήσετε μια συνομιλία',
  createChatWithEmployee: 'Ψάχνει για υπαλλήλους',
  createChatWithLocationTeam: 'Αντικείμενο αναζήτησης',
  createTeamleaderChat: 'Όλοι οι υπάλληλοί μου',
  yourLanguageSelection: 'Η επιλογή της γλώσσας σας:',
  loginInfo: 'Για να εγγραφείτε, σαρώστε τον προσωπικό σας κωδικό QR:',
  updating: 'Το Blink me ενημερώνεται.',
  notice: 'Ειδοποίηση',
  importantNotice: 'Σημαντική σημείωση',
  continue: 'Περαιτέρω',
  intro: {
    slide1: {
      header: 'καλως ΗΡΘΑΤΕ',
      text: 'Διασκεδάστε με την εφαρμογή Blink me.'
    },
    slide2: {
      header: 'Κουβέντα',
      text: 'Συζητήστε απευθείας με τους συναδέλφους σας ή μάθετε τα τελευταία νέα από ενδιαφέρουσες ομάδες.'
    },
    slide3: {
      header: 'Εισιτήρια',
      text: 'Εδώ μπορείτε να δείτε τις εργασίες σας και να δημιουργήσετε εφαρμογές.'
    }
  },
  pleaseWait: 'Παρακαλώ περιμένετε...',
  skip: 'Παραλείπω',
  startAgain: 'Ξεκινήστε ξανά',
  lock: {
    createPinHeader: 'Το προσωπικό σας PIN',
    createPinInfo: 'Για να προστατεύσετε τα δεδομένα σας, μπορείτε να ορίσετε ένα PIN εδώ που πρέπει να εισάγετε κάθε φορά που χρησιμοποιείτε την εφαρμογή Blink me.',
    validatePin: 'Εισαγάγετε ξανά το PIN.',
    tryAgain: 'Το PIN δεν ταιριάζει. Δοκιμάστε το ξανά.',
    triedToOften: 'Δυστυχώς το PIN σας δεν ταιριάζει. Θέλετε να επανεκκινήσετε τη διαδικασία ή να την παραλείψετε και να την κάνετε αργότερα;',
    enterPinHeader: 'Εισαγωγή καρφίτσας',
    enterPinInfo: 'Εισαγάγετε το PIN σας για να ξεκλειδώσετε την εφαρμογή.',
    remainingTrys: '{{amount}} ακόμη προσπάθειες. Στη συνέχεια, πρέπει να συνδεθείτε ξανά στην εφαρμογή.',
    tooManyEnters: 'Δυστυχώς, η εγγραφή με PIN δεν είναι πλέον δυνατή. Παρακαλούμε συνδεθείτε ξανά με το Blink ID σας.',
    reSignIn: 'Νέα καταχώρηση',
    setpin: 'Εισαγωγή PIN κατά τη χρήση της εφαρμογής',
    skip: 'Δεν θέλετε να εκχωρήσετε ένα PIN τώρα; Μπορείτε να ενεργοποιήσετε αυτήν τη λειτουργία ανά πάσα στιγμή μέσω των ρυθμίσεων της εφαρμογής, ακόμη και αργότερα.',
    dontUsePin: 'μην χρησιμοποιείτε PIN',
    deactivatePinHeader: 'Απενεργοποίηση PIN',
    deactivatePinInfo: 'Εισαγάγετε το PIN σας για να απενεργοποιήσετε την προστασία.'
  },
  active: {
    yourTickets: 'Τα εισιτήριά σας',
    createNewTask: 'Δημιουργήστε ένα νέο εισιτήριο',
    assignee: 'συντάκτης',
    location: 'αντικείμενο',
    dueDate: 'Οφείλεται στις',
    createTask: 'Δημιουργία εισιτηρίου',
    taskCreated: 'Το εισιτήριο δημιουργήθηκε.',
    backToTasks: 'Επιστροφή στα εισιτήρια',
    createdAt: 'δημιουργήθηκε στο',
    attachments: 'Συνημμένα',
    addFileOrImage: 'Προσθήκη αρχείου ή φωτογραφίας',
    addImage: 'προσθεσε μια φωτογραφια',
    comments: 'Σχόλια',
    addComment: 'πρόσθεσε σχόλιο',
    addCommentInfo: 'Το σχόλιο είναι ορατό σε οποιονδήποτε μπορεί να δει αυτό το εισιτήριο.',
    commentCreated: 'Το σχόλιο έχει αποθηκευτεί.',
    showClosed: 'Δείτε ολοκληρωμένα εισιτήρια',
    resetFilter: 'επαναφέρετε όλα τα φίλτρα',
    changeTicketStatusTo: 'Αλλαγή κατάστασης εισιτηρίου σε:',
    editTask: 'Επεξεργασία εισιτηρίου',
    saveTask: 'Αποθήκευση εισιτηρίου',
    taskSaved: 'Το εισιτήριο έχει αποθηκευτεί.',
    deleteImage: 'Καθαρή εικόνα',
    deleteImageConfirmation: 'Θέλετε πραγματικά να διαγράψετε την εικόνα;',
    deleteAttachment: 'Διαγραφή συνημμένου',
    deleteAttachmentConfirmation: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το συνημμένο;',
    deleteComment: 'Διαγραφή σχολίου',
    deleteCommentConfirmation: 'Θέλετε πραγματικά να διαγράψετε το σχόλιο;',
    changeAssignee: 'Αλλαγή επεξεργαστή',
    duplicateTask: 'Διπλότυπο εισιτήριο',
    duplicateTaskConfirmation: 'Θέλετε να αντιγράψετε αυτό το εισιτήριο;',
    taskDuplicated: 'Το εισιτήριο ήταν διπλό.',
    deleteTask: 'Διαγραφή εισιτηρίου',
    deleteTaskConfirmation: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το εισιτήριο;',
    taskDeleted: 'Το εισιτήριο έχει διαγραφεί.',
    assigneeChanged: 'Ο επεξεργαστής έχει αλλάξει.',
    statusChanged: 'Η κατάσταση του εισιτηρίου έχει αλλάξει.',
    startDate: 'αρχίζουν',
    endDate: 'Τέλος',
    doctorVisitDate: 'Ημερομηνία επίσκεψης γιατρού',
    doctorVisitDescription: 'Ημερομηνία επίσκεψης σε γιατρό: {{date}}',
    chooseDate: 'Επιλέξτε μια ημερομηνία',
    comment: 'Σχόλιο (προαιρετικό)',
    dateError: 'Η ημερομηνία λήξης δεν μπορεί να είναι πριν από την ημερομηνία έναρξης',
    employee: 'Υπαλλήλους',
    pleaseConnectToLoad: 'Δημιουργήστε μια σύνδεση στο διαδίκτυο για να φορτώσετε τα εισιτήριά σας.'
  },
  emptyState: {
    chats: 'Δημιουργήστε μια συνομιλία!',
    chat: 'Γράψε το πρώτο μήνυμα :)',
    tasks: 'Δεν βρέθηκαν εισιτήρια.',
    attachments: 'Δεν υπάρχουν διαθέσιμα συνημμένα.',
    comments: 'Δεν υπάρχουν διαθέσιμα σχόλια.',
    vacation: 'Δεν έχουν μπει διακοπές για φέτος'
  },
  formError: {
    min: 'Η τιμή πρέπει να είναι μεγαλύτερη από {{value}}.',
    min2: 'Η τιμή πρέπει να είναι μεγαλύτερη ή ίση με {{value}}.',
    max: 'Η τιμή πρέπει να είναι μικρότερη ή ίση με {{value}}.',
    requiredBecause: 'Απαιτείται τιμή επειδή έχει συμπληρωθεί το {{name}}.',
    isRequired: 'Το {{name}} είναι υποχρεωτικό.',
    numberInUse: 'Ο αριθμός προσωπικού χρησιμοποιείται ήδη.',
    maxlength: 'Το {{name}} δεν μπορεί να περιέχει περισσότερους από {{value}} χαρακτήρες.',
    faultyBlinkId: 'Σφάλμα αναγνωριστικού αναβοσβήνει'
  },
  ngSelect: {
    typeToSeach: 'Εισαγάγετε 3 ή περισσότερα γράμματα.',
    loading: 'τα δεδομένα φορτώνονται...',
    notFound: 'Δεν βρέθηκε {{title}}.',
    clearAll: 'Επαναφορά στο προκαθορισμένο'
  },
  placeholder: {
    employee: 'Αναζήτηση με όνομα και επίθετο, αριθμό προσωπικού, αναγνωριστικό αναλαμπής',
    location: 'Αναζήτηση με όνομα, αριθμό ή κωδικούς αντικειμένων',
    date: 'Επιλέξτε μια ημερομηνία',
    searchTasks: 'Αναζήτηση εισιτηρίων...'
  },
  hasChat: 'Ξεκινήστε τη συνομιλία τώρα',
  hasNoChatYet: 'Δυστυχώς, δεν έχω εγκαταστήσει ακόμα την εφαρμογή Blink me.',
  noBlinkActiveLicence: 'Χωρίς άδεια',
  noBlinkActiveLicenceInfo: 'Προς το παρόν δεν έχετε άδεια για αυτήν την εφαρμογή. Επικοινωνήστε με τον προϊστάμενό σας.',
  noBlinkMeLicence: 'Χωρίς άδεια',
  noBlinkMeLicenceInfo: 'Αυτή η εφαρμογή δεν μπορεί να χρησιμοποιηθεί με το σύστημά σας. Χρησιμοποιήστε το Blink Time από την εφαρμογή ή το Playstore.',
  noSuitableAppFound: 'Δεν βρέθηκε εφαρμογή για άνοιγμα του αρχείου.',
  myChats: 'Οι συνομιλίες σας',
  hasNoChatsHome: 'Ξεκινήστε τη συνομιλία τώρα! Μπορείτε να μιλήσετε με όλους τους συναδέλφους και να συμμετέχετε σε ομάδες αντικειμένων και ομάδων.',
  hasNoTicketsHome: 'Δεν υπάρχουν ανοιχτά εισιτήρια.',
  myTickets: 'Τα εισιτήριά σας',
  myEmployees: 'Οι υπάλληλοί μου',
  group: 'ομάδα',
  confirmLogoutWithChat: 'Εάν αποσυνδεθείτε, τα μηνύματά σας συνομιλίας θα διαγραφούν. Θα θέλατε να συνεχίσετε;',
  messageNotDecryptable: 'Δεν ήταν δυνατή η αποκρυπτογράφηση του μηνύματος.',
  chat: {
    userAlreadyExists: 'Έχετε ήδη ρυθμίσει τη συνομιλία σε άλλη συσκευή. Για να μπορείτε να χρησιμοποιήσετε τη συνομιλία σε αυτήν τη συσκευή, πρέπει να συνδέσετε τις συσκευές. Εναλλακτικά, μπορείτε να επανεκκινήσετε τη ρύθμιση, αλλά στη συνέχεια η συνομιλία δεν μπορεί πλέον να χρησιμοποιηθεί στην άλλη συσκευή.',
    linkDevices: 'Σύνδεση συσκευών',
    withoutChat: 'Συνεχίστε χωρίς συνομιλία',
    setupAgain: 'Ρυθμίστε ξανά τη συνομιλία',
    setupAgainText: 'Εάν ρυθμίσετε ξανά τη συνομιλία, όλα τα μηνύματα από τις υπάρχουσες μεμονωμένες συνομιλίες θα διαγραφούν και η συνομιλία δεν μπορεί πλέον να χρησιμοποιηθεί στις άλλες συσκευές! Θα θέλατε να συνεχίσετε;',
    LinkDevicesText: 'Για να συνδέσετε συσκευές για συνομιλία, ανοίξτε τις Ρυθμίσεις στην άλλη συσκευή σας και επιλέξτε "Κοινή χρήση συνομιλίας με άλλη συσκευή".',
    personalToken: 'Ο προσωπικός σας κωδικός συνομιλίας',
    wrongCode: 'Ο κωδικός συνομιλίας που παρέχεται είναι εσφαλμένος.',
    beingPrepared: 'Η συνομιλία προετοιμάζεται.',
    twilioAccessDenied: 'Δεν ήταν δυνατή η δημιουργία σύνδεσης με την υπηρεσία συνομιλίας. Επικοινωνήστε με την Υποστήριξη Blink.',
    notificationsDenied: 'Οι ειδοποιήσεις είναι απενεργοποιημένες στη συσκευή/το πρόγραμμα περιήγησής σας. Μεταβείτε στις Ρυθμίσεις και ενεργοποιήστε τις ειδοποιήσεις για το Blink me για λήψη ειδοποιήσεων. Στη συνέχεια, μπορείτε να ενεργοποιήσετε τις ειδοποιήσεις στις προσωπικές σας ρυθμίσεις.',
    deleted: 'Η συνομιλία έχει διαγραφεί',
    unavailable: 'Η συνομιλία δεν είναι διαθέσιμη'
  },
  protocol: {
    SEND_EMAIL: 'Απόστειλε μέσω ηλεκτρονικού ταχυδρομείου',
    DELETE: 'Εκκαθάριση αρχείου καταγραφής',
    deleteMessage: 'Θέλετε πραγματικά να διαγράψετε το αρχείο καταγραφής; Αυτή η πράξη δε μπορεί να αναιρεθεί.',
    Send: 'Στείλετε',
    ConfirmMessage: 'Το αρχείο καταγραφής της εφαρμογής θα αποσταλεί τώρα στην υποστήριξη Blink.',
    SuccessMessage: 'Το αρχείο καταγραφής στάλθηκε με επιτυχία στην υποστήριξη Blink.'
  },
  timeTracking: {
    title: 'Παρακολούθηση χρόνου',
    start: 'Αρχή',
    startDescription: 'Κάντε κλικ στην έναρξη για να ξεκινήσει η παρακολούθηση χρόνου',
    started: 'ξεκίνησε',
    stop: 'Να σταματήσει',
    duration: 'Χρονική διάρκεια',
    syncButtonLabel: 'Οι χρόνοι μεταφόρτωσης καταγράφηκαν εκτός σύνδεσης',
    offlineSyncMessage: 'Για να μεταφέρετε τους αποθηκευμένους χρόνους πρέπει να είστε συνδεδεμένοι.',
    successfulSync: 'Οι χρόνοι ανεβαίνουν',
    scanNowQrCode: 'Κωδικός QR',
    scanNowQrCodeDescription: 'Σαρώστε τον κωδικό QR στο αντικείμενο.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Κρατήστε το smartphone σας πάνω στο αυτοκόλλητο NFC στο αντικείμενο.',
    nfcDescription: 'Ανίχνευση μέσω NFC: Κρατήστε το κινητό σας στον κωδικό αντικειμένου',
    noTimeTrackingMethodAvailableDescription: 'Δεν έχει ρυθμιστεί μέθοδος παρακολούθησης χρόνου για αυτόν τον χρήστη. Επικοινωνήστε με τον αρχηγό της ομάδας σας.',
    noLocationCode: 'Δεν υπάρχει διαθέσιμος κωδικός',
    noLocationCodeDescription: 'Επιλέξτε αυτήν την επιλογή εάν δεν υπάρχει κωδικός QR ή αυτοκόλλητο NFC στο αντικείμενο. Η υποβολή σχολίου είναι υποχρεωτική.',
    noLocationCodeConfirm: 'Είστε βέβαιοι ότι θέλετε να ξεκινήσετε χωρίς κωδικό αντικειμένου; Πρέπει να δώσετε ένα σχόλιο για να σταματήσετε.',
    wrongCode: 'Ο σαρωμένος κωδικός QR δεν είναι κωδικός αντικειμένου!',
    readNotice: 'Παρακαλώ διαβάστε τις οδηγίες!',
    warning: 'προειδοποίηση',
    lessThanAMinuteTitle: 'Πλήρης καταγραφή χρόνου',
    lessThanAMinute: 'Ξεκινήσατε πριν από λιγότερο από 1 λεπτό. Είστε βέβαιοι ότι θέλετε να σταματήσετε;',
    continue: 'συνεχίστε τη λήψη',
    timeAlert: {
      differenceInMinutesHint: 'Η ώρα στο smartphone σας διαφέρει από την ώρα στο διακομιστή. Η διαφορά καταγράφεται',
      differentTimeZoneHint: 'Η ζώνη ώρας του smartphone σας διαφέρει από τη ζώνη ώρας του διακομιστή. Η διαφορά καταγράφεται.'
    },
    leaveComment: {
      withCode: 'Οι πληροφορίες σχετικά με το αντικείμενο/υπηρεσία δεν ήταν σαφείς. Πείτε μας σε ποιο ακίνητο/υπηρεσία εργαζόσασταν.',
      withoutCode: 'Κανένας κωδικός αντικειμένου δεν καταγράφηκε κατά την έναρξη ή τη διακοπή. Εξηγήστε γιατί ξεκινήσατε/σταματήσατε χωρίς αντικείμενο.'
    },
    comment: 'σχόλιο',
    enterComment: 'Σχολιάστε την παρακολούθηση χρόνου',
    differentQrCode: {
      title: 'Αλλαγή αντικειμένου;',
      continueInLocation: 'Συνεχίστε να εργάζεστε στο {{locationName}}.',
      automaticStopInLocation: 'Η εγγραφή χρόνου στο {{locationName}} θα σταματήσει αυτόματα.',
      stopInLocation: 'Διακοπή εγγραφής χρόνου στο {{locationName}}.',
      stop: 'Να σταματήσει',
      stopNoLocation: 'Διακοπή εγγραφής χρόνου.',
      continueNoOldLocation: 'Η εγγραφή χρόνου για το προηγούμενο αντικείμενο διακόπτεται αυτόματα.',
      continueWorking: 'Συνέχισε να δουλεύεις',
      continueWorkingInNewLocation: 'Συνεχίστε να εργάζεστε σε νέο ακίνητο.',
      stopWithLocationActivity: 'Διακοπή εγγραφής χρόνου στο {{locationActivity}}.',
      continueWithNewLocationActivity: 'Συνεχίστε να εργάζεστε με {{locationActivity}}',
      stopOldLocationActivity: 'Η εγγραφή χρόνου στο {{locationActivity}} διακόπτεται αυτόματα.',
      stopOldNoLocationActivity: 'Η εγγραφή χρόνου χωρίς απόδοση διακόπτεται αυτόματα.',
      continueNoLocationActivity: 'Συνεχίστε να εργάζεστε χωρίς απόδοση.',
      sameLocationTitle: 'Αλλαγή απόδοσης;',
      differentLocationTitle: 'Αλλαγή αντικειμένου;'
    },
    saveAndStop: 'Αποθήκευση και Διακοπή',
    noLocationScanned: '- Κανένα αντικείμενο -',
    noLocationFound: '- Το αντικείμενο δεν είναι σαφές -',
    timerDifference: '{{h}} ώρες {{mm}} λεπτά',
    stopSuccess: 'Η ώρα καταγράφηκε με επιτυχία',
    stopSuccessToast: 'Η εγγραφή χρόνου σταμάτησε με επιτυχία.',
    stopTimeTracking: 'Να σταματήσει',
    manual: {
      time: 'χρόνος',
      locationSelect: 'Επιλέξτε αντικείμενο',
      loadMore: 'φόρτωσε περισσότερα',
      trackTime: 'Χρόνος ηχογράφησης',
      location: 'αντικείμενο',
      locationActivity: 'Εκτέλεση',
      date: 'Ημερομηνία',
      startTime: 'Από',
      endTime: 'Μέχρι',
      create: 'Επιβεβαιώνω',
      createAgain: 'Καταγράψτε επιπλέον χρόνο για αυτό το αντικείμενο',
      errorUntil: 'Ο χρόνος πρέπει να είναι μικρότερος από τον χρόνο',
      worktimeDifference: 'Ωρες εργασίας:',
      worktimeDifferenceValue: '{{hours}} ώρες {{minutes}} λεπτά',
      worktimeDifferenceValueMinutes: '{{minutes}} λεπτά',
      success: 'Η ώρα καταγράφηκε με επιτυχία',
      break: 'Διακοπή:',
      breakMinutes: '{{minutes}} λεπτά',
      breakHoursAndMinutes: '{{hours}} ώρες {{minutes}} λεπτά',
      noActivity: '- χωρίς απόδοση -',
      locationPlaceHolder: 'Αναζήτηση με όνομα, αριθμό ή κωδικούς αντικειμένων',
      conflictError: 'Υπάρχει ήδη καταγραφή χρόνου στις καθορισμένες περιόδους. Δεν επιτρέπονται επικαλύψεις. Ελέγξτε τα στοιχεία σας.',
      currentDayDuration: 'Ήδη ηχογραφημένο',
      expectedDuration: 'Αναμενόμενες ώρες εργασίας',
      expectedDurationIncludedCurrent: 'συμπεριλαμβανομένης της τρέχουσας εισόδου'
    },
    differenceInMinutesHint: 'Η ώρα στο smartphone σας διαφέρει από την ώρα στο διακομιστή. Η διαφορά καταγράφεται',
    differentTimeZoneHint: 'Η ζώνη ώρας του smartphone σας διαφέρει από τη ζώνη ώρας του διακομιστή. Η διαφορά καταγράφεται.',
    proposed: {
      capture: 'Πιάνω',
      time: 'χρόνος',
      captureTime: 'Χρόνος ηχογράφησης',
      button: 'Επιβεβαιώνω',
      title: 'Επιβεβαιώστε τις ώρες εργασίας',
      includingBreak: '(συμπεριλαμβανομένου του διαλείμματος)',
      from: 'Από',
      to: 'Μέχρι',
      break: 'Διακοπή',
      hour: 'Ρολόι',
      confirm: {
        description: 'Επιβεβαιώστε τις ώρες εργασίας σας',
        confirm: 'Ναι, σωστά',
        editButton: 'Όχι, δεν είναι σωστό'
      },
      edit: {
        description: 'Προσδιορίστε τη διάρκεια',
        breakIncluded: 'Συμπεριλαμβανομένου του διαλείμματος {{ duration }}',
        comment: 'σχόλιο',
        yourComment: 'το σχόλιό σου',
        minutes: 'λεπτά',
        hours: 'Ωρες'
      },
      reasons: {
        overtime: 'Υπερωρίες λόγω πελάτη',
        faster: 'Έγινε πιο γρήγορα',
        representative: 'Είμαι αντιπρόσωπος',
        other: 'Διάφορα'
      },
      description: 'Επιβεβαιώστε τις ώρες εργασίας σας μόλις ολοκληρώσετε την εργασία σας στο κατάλυμα.'
    },
    codeNotAssignable: 'Δεν είναι δυνατή η εκχώρηση του σαρωμένου κωδικού QR!',
    running: 'Η εγγραφή χρόνου εκτελείται'
  },
  timeFramePicker: {
    title: 'Επιλέξτε μια περίοδο',
    today: 'Σήμερα',
    currentWeek: 'Τρέχουσα εβδομάδα',
    currentMonth: 'Τρέχων μήνας',
    custom: 'Εθιμο',
    startDate: 'αρχίζουν',
    endDate: 'Τέλος'
  },
  timeSheet: {
    title: 'Χρόνοι & προγραμματισμός',
    noPlannings: 'Κανένας προγραμματισμός',
    planned: 'Σχεδιασμένος',
    workingTime: 'ώρες εργασίας',
    workloadDuration: 'Εβδομαδιαίος χρόνος εργασίας',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Πρέπει',
    workedDuration: 'Είναι',
    hasWorkload: 'Δούλεψε εκείνη τη μέρα',
    pleaseConnectToLoad: 'Δημιουργήστε μια σύνδεση στο διαδίκτυο για να φορτώσετε τους χρόνους σας.',
    footer: {
      planned: 'Σχεδιασμένος',
      worklogsTotal: 'Συνολικές ώρες',
      workloadTotal: 'Ώρες σύμφωνα με το συμβόλαιο',
      difference: 'διαφορά'
    },
    homepageTitle: 'Η εργάσιμη ημέρα σας'
  },
  planning: {
    includingPause: 'Διάλειμμα με:',
    planRoute: 'Σχεδιάστε τη διαδρομή',
    title: 'σχεδίαση',
    modifyMinutes: "Κατ' αποκοπή τιμή:"
  },
  worklog: {
    title: 'Καταγράφηκε χρόνος',
    absenceTitle: 'απουσία',
    workingTime: 'ώρες εργασίας',
    breakDuration: 'Διακοπή',
    reduce: "κατ' αποκοπή συντελεστή",
    attachments: 'Συνημμένα',
    comments: 'Σχόλια',
    comment: 'σχόλιο',
    noLocation: 'Δεν έχει καθοριστεί αντικείμενο',
    changedBy: 'Η ώρα άλλαξε από {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το συνημμένο;',
    attachmentDeleted: 'Το συνημμένο έχει διαγραφεί',
    confirmCommentDelete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το σχόλιο;',
    commentDeleted: 'Το σχόλιο διαγράφηκε'
  },
  tabs: {
    home: 'Σπίτι',
    times: 'φορές',
    chat: 'Κουβέντα',
    tickets: 'Εισιτήρια',
    more: 'περισσότερο'
  },
  profile: {
    title: 'Προφίλ',
    testing: 'Δοκιμές',
    testingDescription: 'Αυτή η περιοχή είναι ορατή μόνο όταν είναι συνδεδεμένη σε δοκιμαστικό σύστημα.',
    testingAllowQrCodeOnWeb: 'Να επιτρέπεται η μη αυτόματη εισαγωγή κωδικού QR',
    pushNotifications: 'Push ειδοποιήσεις',
    reloadUserContext: 'Επαναφόρτωση UserContext',
    pushNotificationSuccessful: 'Η ειδοποίηση Push ελήφθη με επιτυχία',
    editProfilePicture: 'αλλαγή φωτογραφίας προφίλ',
    editProfilePictureTitle: 'Επιλέξτε ενότητα',
    pushNotificationNotice: 'Απαιτείται η εξουσιοδότηση "Επεξεργασία ρυθμίσεων πελάτη" για τη δοκιμή.',
    send: 'Στείλετε',
    profilePicture: 'Εικόνα προφίλ'
  },
  help: { title: 'Βοήθεια' },
  myVacations: {
    vacation: 'Διακοπές',
    amountAvailableVacationDays: '{{amount}} ημέρες διακοπών είναι ακόμα διαθέσιμες',
    totalVacationDays: 'Δικαίωμα διακοπών {{ total }} ημέρες',
    remainingVacationDays: '({{ total }} ημέρες {{ sign }} {{ remaining }} ημέρες απομένουν για διακοπές {{ previousYear }})',
    xDaysVacations: '{{ total }} ημέρες διακοπές',
    title: 'Οι διακοπές σου'
  },
  externalLinks: {
    title: 'εξωτερικοί σύνδεσμοι',
    notice: 'Αυτός ο σύνδεσμος παρέχεται από την εταιρεία σας. Αποχωρείτε από το σύστημα Blink.'
  },
  stickers: {
    title: 'Τα αυτοκόλλητα σου',
    sticker: 'Αυτοκόλλητα',
    sender: 'Παραλήπτης',
    text: 'Σχετικά με',
    date: 'Ημερομηνία',
    emptyState: 'Δυστυχώς δεν έχετε ακόμα αυτοκόλλητα',
    senderDescription: 'Αυτό το αυτοκόλλητο σας εστάλη από τον χρήστη {{sender}} στις {{date}}',
    readed: 'ανάγνωση',
    notReaded: 'δεν το διαβάσατε ακόμα',
    newStickerToaster: 'Νέο αυτοκόλλητο που αναβοσβήνει!',
    open: 'Ανοιξε',
    next: 'Περαιτέρω',
    titleCases: {
      case1: 'Συγχαρητήρια για το 1ο αυτοκόλλητο!',
      case2: 'Τέλεια, τώρα έχετε ήδη αυτοκόλλητα <b>{{count}}</b>!',
      case3: 'Συγχαρητήρια για το 10ο αυτοκόλλητο!',
      case4: '30 αυτοκόλλητα - ουάου!',
      case5: 'Τώρα έχει κόσμο εδώ: <b>{{count}}</b> Αυτοκόλλητα!'
    },
    confirmDelete: 'Θέλετε πραγματικά να διαγράψετε αυτά τα αυτοκόλλητα;'
  },
  autoTranslate: {
    isTranslated: 'Αυτόματη μετάφραση',
    translationError: 'Αυτήν τη στιγμή δεν είναι δυνατή η αυτόματη μετάφραση'
  },
  confirmLogout: 'Θέλετε πραγματικά να αποσυνδεθείτε;',
  message: 'Νέα',
  migration: {
    wrongVersion: 'Στην τρέχουσα έκδοση του Blink me, η σύνδεση μέσω της εφαρμογής Blink Time δεν υποστηρίζεται. Ενημερώστε την εφαρμογή Blink me στην πιο πρόσφατη έκδοση'
  },
  eLearning: {
    pendingTab: 'Να κάνετε ({{value}})',
    completedTab: 'Έγινε ({{value}})',
    completed: 'Ολοκληρώθηκε το',
    pending: 'Ανοιξε',
    dueFalling: 'Εκπρόθεσμος',
    title: 'Ηλεκτρονική μάθηση',
    course: 'σειρά μαθημάτων',
    dueDate: 'Εγκυρο μέχρι',
    topics: 'μαθήματα',
    completeDate: 'Ολοκληρώθηκε στις',
    status: { Pending: 'Άνοιξε', Done: 'ολοκληρώθηκε το' },
    infos: {
      pendingExists: 'Μπορείτε να βρείτε όλα τα ανοιχτά σεμινάρια εκπαίδευσης εδώ.',
      finishOverdue: 'Παρακαλώ κάντε την προπόνηση που έχει ήδη προβλεφθεί το συντομότερο δυνατό.',
      allCompleted: 'Εξοχος! Έχετε ολοκληρώσει επιτυχώς όλα τα εκπαιδευτικά σας μαθήματα.',
      emptyState: 'Δεν έχετε ορίσει εκπαίδευση.'
    }
  },
  checklists: {
    title: 'Έντυπα',
    description: 'Συμπληρώστε μια φόρμα για ένα αντικείμενο',
    button: 'Επιλέξτε αντικείμενο'
  }
};
