export default {
  "datepicker": {
    "today": "Heute",
    "placeholder": "Wählen Sie ein Datum"
  },
  "areas": {
    "area": "Bereich",
    "areas": "Bereiche",
    "manageAreas": "Bereiche verwalten",
    "manageAreasNoAreas": "In der Auswahl sind keine Bereiche vorhanden.",
    "noAreas": "Es sind noch keine Bereiche konfiguriert. Fügen Sie neue Bereiche über den \"Bereich hinzufügen\" - Button hinzu.",
    "addAreaButton": "Bereich hinzufügen",
    "removeAreaButton": "Bereich entfernen",
    "infoBoxText": "Wenn Sie Bereiche definieren, dann können Sie Objekte und Mitarbeiter einem oder mehreren Bereichen zuordnen. Der Bereichsmanager hat Zugriff auf alle zu dem Bereich gehörenden Objekte und Mitarbeiter.",
    "description": "Beschreibung",
    "externalId": "Externe Id",
    "editAreaPageTitle": "Bereich bearbeiten",
    "managers": "Manager",
    "areaUpdated": "Der Bereich wurde aktualisiert.",
    "areaCreated": "Der Bereich wurde angelegt.",
    "areaDeleted": "Der Bereich wurde erfolgreich gelöscht.",
    "areaDeletedError": "Der Bereich konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "areaConflictError": "Bereich kann nicht gelöscht werden, da es verknüpfte Objekte oder Mitarbeiter gibt.",
    "selectNoAreas": "Keine Bereiche gefunden",
    "searchPlaceholder": "Bereiche auswählen",
    "areaRequired": "Die Angabe von mindestens einem Bereich ist verpflichtend.",
    "areaAddedToSelection": "Der Bereich wurde der Auswahl hinzugefügt.",
    "confirmDeleteTitle": "Bereich löschen",
    "confirmDeleteText": "Wollen Sie diese Bereich wirklich löschen?",
  },
  "deleteSingleInterface": {
    "confirmTitle": "{{entity}} löschen",
    "confirmText": "Wollen Sie diese {{entity}} wirklich löschen?",
    "confirmButton": "{{entity}} löschen",
    "cancelButton": "Abbrechen"
  },
  "customActions": {
    "customActionName": "CustomAction Name",
    "customActions": "Auswertungen",
    "permissions": "Berechtigungen",
    "updateDate": "Ausführungsdatum",
    "execute": "Ausführen",
    "executeAgain": "Erneut ausführen",
    "execution": {
      "pageTitle": "Ausführung"
    },
    "protocol": "Protokoll",
    "detail": {
      "pageTitle": "Berechtigungen verwalten",
      "notice": "Nur Mitarbeiter aus den hier zugeordneten Berechtigungsgruppen dürfen die Custom Action ausführen",
      "noPermissionGroups": "Es sind noch keine Berechtigungsgruppen konfiguriert. Fügen Sie neue Berechtigungsgruppen über den \"Hinzufügen\" - Button hinzu."
    },
    "permissionGroups": {
      "pageTitle": "Berechtigungsgruppen hinzufügen",
      "permissionGroups": "Berechtigungsgruppen",
      "delete": {
        "title": "Berechtigungsgruppe löschen",
        "description": "Wollen Sie diese Berechtigungsgruppe wirklich löschen?",
        "deleted": "Die Berechtigungsgruppe wurde gelöscht",
      }
    },
    "customActionJobState": {
      "Error": "Fehler",
      "Waiting": "Wartet",
      "Processing": "Wird ausgeführt",
      "Completed": "Erledigt"
    },
    "customActionJobsStatus": "Status",
    "customActionJobsParameters": "Parameter",
    "noConfiguredCustomAction": "Es ist keine Custom Action in diesem System konfiguriert.",
    "unknownItemType": "Unbekannter Typ ({{ type }}). Bitte wenden Sie sich an einen Administrator um die Konfiguration der CustomAction zu prüfen."
  },
  "SCHEDULER": {
    "singleAlertDescription": "Planungen, die nur an einem Tag gültig sind werden in dieser Liste nicht angezeigt. Sie können die Planung in den gebuchten Zeiten oder in der Kalenderansicht sehen.",
    "goToScheduler": "Zur Kalenderansicht",
    "close": "Schließen",
    "toggleCalendarView": "Alle Planungen",
    "toggleListView": "Serienplanung",
    "filter": {
      "showObsoletePlannings": "Abgelaufene Plandaten anzeigen",
      "showFuturePlannings": "zukünftige Plandaten anzeigen",
      "activityPlaceholder": "Leistung, Revier, Auftrag",
      "viewIsFiltered": "Ansicht wird gefiltert",
      "doFilter": "Plandaten filtern"
    },
    "description": "Hinweis für Mitarbeiter",
    "interval": "Intervall",
    "intervalOptions": {
      "single": "Einmalig",
      "weekly": "Wöchentlich",
      "every2weeks": "Alle 2 Wochen",
      "every3weeks": "Alle 3 Wochen",
      "every4weeks": "Alle 4 Wochen"
    },
    "validity": "Gültigkeit",
    "validOn": "Gültig am",
    "validFrom": "Gültig ab",
    "validUntil": "Gültig bis",
    "validIndefinitely": "Unbegrenzt gültig",
    "notValidAnymoreSince": "Nicht mehr gültig ab: ",
    "time": "Zeit",
    "options": "Optionen",
    "title": "Mitarbeiter einplanen",
    "TIMEFRAME": "Zeitraum",
    "MINUTES": "Minuten",
    "DURATION": "Dauer",
    "includingBreak": "inkl. {{duration}} h Pause",
    "includingBreakWithoutH": "inkl. {{duration}} Pause",
    "BREAK": "Pause (min)",
    "inklBREAK": "inkl. Pause (min)",
    "breakWithoutMin": "Pause",
    "inclBreakWithoutMin": "inkl. Pause",
    "PLANNING_ADDED": "Planung wurde gespeichert",
    "PLANNING_SAVED": "Planung wurde gespeichert",
    "PLANNING_DELETED": "Planung wurde gelöscht",
    "DELETE_PLANNING": "Planung löschen",
    "DELETE_PLANNING_CONFIRM_TEXT": "Wollen Sie diesen Eintrag wirklich löschen?",
    "SUBTRACT_BREAK": "Pausenzeiten werden bei {{employeeName}} automatisch von der erfassten Zeit abgezogen.",
    "SUBTRACT_BREAK_NO": "Pausen werden bei {{employeeName}} nicht von der erfassten Zeit abgezogen. Einstellung kann im Mitarbeiter geändert werden.",
    "WEEKDAYS": "Wochentage",
    "oneDayRequired": "Bitte wählen Sie mindestens einen Wochentag aus.",
    "pageHeader": "Einplanung von <b>{{employee}}</b> für <b>{{location}}</b>.",
    "toggleOnlyDuration": "Nur Dauer angeben",
    "toggleBreak": "Pause",
    "toggleReduceDuration": "Pauschale (+/-)",
    "breakDurationDescription": "Uhrzeiten ohne Pausenzeit angeben",
    "reduceDurationHelpText": "Pauschale Zuschläge werden automatisch auf erfasste Zeiten aufgeschlagen oder abgezogen. <br/>Wenn Sie einen Abzug wünschen, geben Sie bitte das Minus \"-\" mit an.",
    "reduceDurationDescription": "Von der Arbeitszeit werden pauschal immer <b>{{minutes}}</b> Minuten abgezogen.",
    "addDurationDescription": "Es werden immer pauschal <b>{{minutes}}</b> Minuten zur Arbeitszeit dazugerechnet.",
    "toggleReduceTimeRound": "Uhrzeit auf-/abrunden",
    "subtractTimes": "Zeiten abziehen",
    "inheritTrimSettings": "Aus Objekteinstellungen übernehmen",
    "trimModes": {
      "None": "kein Abzug",
      "Before": "Vorher",
      "After": "Nachher",
      "Both": "Vorher und Nachher"
    },
    "trimTimesBeforeDescription": "Wenn zwischen {{start}} und {{stop}} gestartet wird, dann gilt als Startzeit immer {{stop}}",
    "trimTimesAfterDescription": "Wenn zwischen {{start}} und {{stop}} gestoppt wird, dann gilt als Stopzeit immer {{start}}",
    "errors": {
      "errorUntil": "Von Zeit muss kleiner als Bis Zeit sein",
      "errorMultipleDuration": "Pro Tag/Mitarbeiter/Objekt darf nur eine Dauer angelegt werden.",
      "errorNoMix": "Pro Tag/Mitarbeiter/Objekt dürfen Zeiträume und Dauer nicht gemischt werden.",
      "errorNoIntersections": "Pro Tag/Mitarbeiter/Objekt darf es keine sich überschneidenden Zeiträume geben.",
      "errorValidity": "Es können keine Einplanungen mit sich überschneidenden Gültigigkeiten angelegt werden.",
      "validOnBeforeTomorrow": "\"Gültig am\" kann frühestens morgen sein",
      "validFromBeforeTomorrow": "\"Gültig von\" kann frühestens morgen sein",
      "validFromAfterValidUntil": "\"Gültig von\" muss vor \"Gültig bis\" liegen",
      "validUntilIsPast": "\"Gültig bis\" darf nicht in der Vergangenheit liegen",
      "OverlappingTimeRanges": "Diese Planung kann aufgrund folgender Überschneidungen nicht angelegt werden",
      "maxWorklogDurationExceeded": "Die Soll einer Planung darf nicht größer als die maximale erlaubte Arbeitszeit. (Maximal {{maxHours}}h)",
      "400": "Planung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Angaben.",
      "durationMoreThanTimeRange": "Summe von Soll und Pause muss kleiner als der angegebene Zeitraum sein.",
      "breakMoreThanDuration": "Die Pause muss kleiner als die Dauer sein.",
      "durationGreaterZero": "Soll muss größer als 0 sein."
    },
    "reason": "Begründung",
    "planningType": "Typ der Einplanung",
    "planningTypes": {
      "TimeSpan": "Exakte Zeitvorgabe",
      "TimeFrame": "Dauer mit Zeitfenster",
      "Duration": "Nur Dauer"
    },
    "warningAutomaticallyUntilChange": "Achtung, das Feld \"Bis\" wurde automatisch angepasst.",
    "editPlanning": "Planung bearbeiten"
  },
  "attachments": {
    "noAttachments": "Keine Anhänge vorhanden"
  },
  "ionicons": {
    "searchTerm": "Icon suchen (http://ionicons.com)"
  },
  "blinkActive": {
    "ticketTitle": "{{title}} von {{startDate}} bis {{endDate}}",
    "title": "Blink Active",
    "pageTitle": "Konfiguration",
    "noTaskTypes": "Es sind noch keine Tickettypen vorhanden.",
    "newTaskType": "Neuer Tickettyp",
    "allTaskType": "Alle Tickettypen",
    "taskStatus": "Statuswerte",
    "completedTasks": "Abgeschlossene Tickets",
    "explanation": "Blink Active ermöglicht die Definition von Tickettypen und deren Statuswerten. Tickettypen können über die Blink Time App für Mitarbeiter freigeschaltet werden.",
    "dueTo": "Fällig bis:",
    "changeEmployee": "Bearbeiter ändern",
    "sortBy": {
      "date": "Fälligkeit",
      "id": "#"
    },
    "dueFilter": {
      "label": "Fälligkeit",
      "overdue": "Überfällig",
      "dueInOneWeek": "Fällig in 1 Woche",
      "dueInTwoWeeks": "Fällig in 2 Wochen",
      "dueInThreeWeeks": "Fällig in 3 Wochen",
      "dueInFourWeeks": "Fällig in 4 Wochen"
    },
    "errors": {
      "dueDateMustBeFuture": "Das Fälligkeitsdatum muss in der Zukunft liegen. Bitte korrigieren Sie den Tag der Fälligkeit.",
      "onlyOneTypeNew": "Es darf nur ein Statuswert vom Typ Neu sein",
      "endShouldBeSameOrAfterStart": "Das Startdatum muss gleich oder vor dem Enddatum sein.",
      "404": "Ticket konnte nicht aktualisiert werden, da Sie kein Zugriff mehr darauf haben."
    },
    "tasks": {
      "showInActiveTasks": "Abgeschlossene Tickets anzeigen",
      "noStatus": "- Kein Status -",
      "task": "Ticket",
      "tasks": "Tickets",
      "allTasks": "Alle Tickets",
      "myTasks": "Meine Tickets",
      "createTask": "Neues Ticket erstellen",
      "title": "Titel",
      "noAssignment": "nicht zugewiesen",
      "type": "Typ",
      "description": "Beschreibung",
      "startDate": "Start",
      "endDate": "Ende",
      "list": {
        "chooseStatus": "Status wählen",
        "placeholder": "Suche nach Beschreibung, Nummer, Objekt",
        "sort": {
          "createDateAsc": "Erstelldatum ältestes zuerst",
          "createDateDesc": "Erstelldatum neuestes zuerst",
          "dueDateAsc": "Fälligkeit ältestes zuerst",
          "dueDateDesc": "Fälligkeit neuestes zuerst"
        }
      },
      "edit": {
        "pageTitle": "Ticket bearbeiten",
        "updateTaskSuccess": "Die Ticket wurde gespeichert.",
        "watcher": "Beobachter",
        "watcherExplanation": "Beobachter werden über Änderungen an diesem Ticket informiert, können die Details einsehen und, je nach Berechtigung, Änderungen vornehmen.",
        "addWatcher": "Beobachter hinzufügen",
        "watcherAdded": "Der Beobachter wurde hinzugefügt.",
        "watcherRemoved": "Der Beobachter wurde entfernt."
      },
      "detail": {
        "pageTitle": "Ticket Details",
        "addComment": "Hinzufügen",
        "addAttachment": "Hinzufügen",
        "attachmentUploaded": "Datei wurde erfolgreich hochgeladen.",
        "takePicture": "Bild aufnehmen",
        "choosePicture": "Bild aus Bibliothek",
        "commentCreated": "Kommentar wurde erstellt.",
        "chooseFile": "Datei auswählen",
        "createdAt": "{{ticketType}} angelegt am {{date}}",
        "createdFrom": "von {{reporter}}",
        "deleteTask": "Löschen",
        "duplicateTask": "Duplizieren",
        "taskDeleted": "Die Ticket wurde gelöscht.",
        "taskDuplicated": "Die Ticket wurde dupliziert.",
        "actionSheetTitle": "Was möchten Sie tun?",
        "moreActions": "Weitere Aktionen",
        "workingOnLocations": "In diesem Zeitraum arbeitet {{employee}} in diesen Objekten:",
        "moreAbsences": "Weitere Abwesenheiten in diesen Objekten:",
        "noneScheduled": "Keine Einplanungen für diesen Zeitraum vorhanden. Es werden alle Mitarbeiter mit Abwesenheiten angezeigt.",
        "employeeIsRequired": "Für die Vorschau muss dem Ticket ein Mitarbeiter zugewiesen werden.",
        "absenceNotFound": "Abwesenheit kann nicht erstellt werden, da der Abwesenheitstyp nicht ermittelt werden konnte oder Sie keine Rechte zur Anlage dieses Abwesenheitstyps haben."
      },
      "status": {
        "title": "Status",
        "name": "Name",
        "externalId": "Externe ID",
        "actionKey": "Action Key",
        "action": "Aktion",
        "color": "Statusfarbe",
        "edit": {
          "pageTitle": "Statuswerte",
          "reorderItems": "Sortierung ändern",
          "saveOrder": "Sortierung speichern",
          "noTaskStatus": "Aktuelle keine Status vorhanden."
        },
        "detail": {
          "actionPlaceholder": "Aktion",
          "actionHelpText": "Bezeichnung um in diesen Status zu wechseln. (z.B. mit Ticket beginnen)",
          "namePlaceholder": "in Bearbeitung",
          "editPageTitle": "Status bearbeiten",
          "createPageTitle": "Status anlegen",
          "createSuccess": "Status wurde angelegt.",
          "updateSuccess": "Status wurde gespeichert.",
          "selectedIcon": "Aktuelles Icon: "
        }
      },
      "date": "Datum",
      "unknownLocation": "Kein Standort",
      "noTasks": "Juhu - keine offenen Tickets. Zeit für ein Eis!",
      "orCreateYourOwn": "oder verschaff dir selber Arbeit",
      "create": {
        "dueDatePlaceholder": "Fällig am",
        "pageTitle": "Neues Ticket anlegen",
        "description": "Notizen hier eintragen",
        "created": "Die Ticket wurde erstellt.",
        "failure": "Die Ticket wurde nicht erstellt. Bitte versuchen Sie es erneut.",
        "noTypesError": "Es sind noch keine Tickettypen vorhanden. Um eine Ticket anlegen zu können, muss mindestens ein Tickettyp vorhanden sein. Sie werden jetzt weitergeleitet."
      },
      "delete": {
        "confirmTitle": "Ticket löschen",
        "confirmText": "Wollen Sie diese Ticket wirklich löschen?",
        "confirmButton": "Ticket löschen",
        "cancelButton": "Abbrechen"
      },
      "deleteAttachment": {
        "confirmTitle": "Anhang löschen",
        "confirmText": "Wollen Sie diesen Anhang wirklich löschen?",
        "confirmButton": "Anhang löschen",
        "cancelButton": "Abbrechen"
      },
      "duplicate": {
        "confirmTitle": "Ticket duplizieren",
        "confirmText": "Wollen Sie dieses Ticket duplizieren?",
        "cancelButton": "Abbrechen",
        "confirmButton": "Ticket duplizieren"
      },
      "types": {
        "name": "Tickettyp",
        "description": "Beschreibung",
        "externalId": "Externe ID",
        "showInBlinkTimeApp": "In Blink Time / Blink Me App anzeigen",
        "autoAssign": "Ticket automatisch zuweisen",
        "automaticTeamlead": "Teamleiter/Objektleiter zuweisen",
        "automaticTeamleadDescription": "Wenn Tickets von diesem Typ angelegt werden, werden die Teamleiter vom Ersteller/Mitarbeiter bzw. Objektleiter vom Objekt dem Ticket zugewiesen.",
        "addAutomaticAssignLoginUser": "Mitarbeiter zuweisen",
        "automaticAssignLoginUserDescription": "Wenn Tickets von diesem Typ angelegt werden, werden folgende Mitarbeiter zugewiesen.",
        "edit": {
          "createSuccess": "Tickettyp wurde angelegt.",
          "updateSuccess": "Tickettyp wurde gespeichert.",
          "createPageTitle": "Tickettyp anlegen",
          "editPageTitle": "Tickettyp bearbeiten"
        },
        "vacationRequestToggle": "Abwesenheitsantrag",
        "vacationRequest": {
          "category": "Kategorie",
          "id": "Nummer Abwesenheitstyp",
          "hint": "Tickettypen, die als Abwesenheitsantrag gekennzeichnet sind, haben 2 zusätzliche Datumsfelder zum Ausfüllen. Bei der Freigabe von Anträgen wird automatisch der Abwesenheitsassistent geöffnet. Geben Sie die Kategorie und die Nummer des Abwesenheitstyps an, der angelegt werden soll.",
          "Vacation": "Urlaub",
          "Disease": "Krankheit",
          "None": "Keine"
        }
      },
      "notFound": "Die Ticket wurde nicht gefunden."
    },
    "status": {
      "new": "Neu",
      "inProgress": "In Bearbeitung",
      "completed": "Abgeschlossen",
      "title": "Status",
      "name": "Name",
      "type": "Typ",
      "externalId": "Externe ID",
      "actionKey": "Action Key",
      "action": "Aktion",
      "color": "Statusfarbe",
      "icon": "Icon",
      "edit": {
        "pageTitle": "Statuswerte",
        "reorderItems": "Sortierung ändern",
        "saveOrder": "Sortierung speichern",
        "noTaskStatus": "Aktuell keine Statuswerte vorhanden."
      },
      "delete": {
        "confirmTitle": "Status löschen",
        "confirmText": "Wollen Sie diesen Status wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "confirmButton": "Status löschen",
        "cancelButton": "Abbrechen",
        "success": "Der Status wurde erfolgreich gelöscht.",
        "failure": "Ein Fehler ist aufgetreten. Der Status konnte nicht gelöscht werden."
      },
      "detail": {
        "actionPlaceholder": "Aktion",
        "actionHelpText": "Bezeichnung um in diesen Status zu wechseln. (z.B. mit Ticket beginnen)",
        "namePlaceholder": "in Bearbeitung",
        "editPageTitle": "Status bearbeiten",
        "createPageTitle": "Status anlegen",
        "createSuccess": "Status wurde angelegt.",
        "updateSuccess": "Status wurde gespeichert.",
        "selectedIcon": "Aktuelles Icon: "
      }
    },
    "taskType": {
      "taskTypes": "Tickettypen",
      "delete": {
        "confirmTitle": "Tickettyp löschen",
        "confirmText": "Wollen Sie diesen Tickettyp wirklich ignorieren? Diese Aktion kann nicht rückgängig gemacht werden.",
        "confirmButton": "Tickettyp löschen",
        "cancelButton": "Abbrechen",
        "success": "Der Tickettyp wurde erfolgreich gelöscht.",
        "failure": "Ein Fehler ist aufgetreten. Der Tickettyp konnte nicht gelöscht werden.",
        "conflict": "Tickettyp kann nicht gelöscht werden, da dieser bereits genutzt wird."
      }
    },
    "changeStatus": "Status ändern"
  },
  "qualification": {
    "qualification": "Qualifikation",
    "qualifications": "Qualifikationen",
    "noQualifications": "- keine Qualifikationen konfiguriert -",
    "create": "Qualifikation anlegen",
    "created": "Die Qualifikation wurde angelegt",
    "update": "Qualifikation bearbeiten",
    "updated": "Die Qualifikation wurde aktualisiert",
    "updateFailure": "Die Qualifikation konnte nicht aktualisiert werden.",
    "delete": {
      "confirmTitle": "Qualifikation löschen",
      "confirmText": "Wollen Sie diese Qualifikation wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
      "confirmButton": "Qualifikation löschen",
      "cancelButton": "Abbrechen",
      "success": "Die Qualifikation wurde erfolgreich gelöscht.",
      "deleted": "Die Qualifikation wurde gelöscht",
      "failure": "Ein Fehler ist aufgetreten. Die Qualifikation konnte nicht gelöscht werden.",
      "failureAssignedEntities": "Diese Qualifikation ist einem oder mehreren Mitarbeitern zugeordnet und kann nicht gelöscht werden."
    },
    "search": {
      "placeholder": "Bitte auswählen"
    }
  },
  "district": {
    "district": "Revier",
    "districts": "Reviere",
    "general": "Allgemein",
    "budget": "Kontrollvorgaben",
    "budgetAbr": "KV",
    "budgetText1": "Die Angabe von Kontrollvorgaben für das Revier sind optional. Die Kontrollvorgaben helfen Ihnen bei der Einplanung der Mitarbeiter und bei der Auswertung der Zeiten.",
    "budgetText2": "Wählen Sie die Tage an denen im Revier gearbeitet wird und geben Sie die Gesamtdauer pro Tag an.",
    "status": {
      "status": "Status",
      "active": "aktiv",
      "inactive": "inaktiv",
      "showInactiveDistricts": "Inaktive Reviere anzeigen"
    },
    "noDistricts": "- keine aktive Reviere konfiguriert -",
    "employeeDescription": "Mitarbeiter Hinweise",
    "create": "Revier anlegen",
    "created": "Das Revier wurde angelegt",
    "update": "Revier bearbeiten",
    "updated": "Das Revier wurde aktualisiert",
    "delete": {
      "confirmTitle": "Revier löschen",
      "confirmText": "Wollen Sie dieses Revier wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
      "confirmButton": "Revier löschen",
      "cancelButton": "Abbrechen",
      "success": "Das Revier wurde erfolgreich gelöscht.",
      "failureUsedInShift": "Das Revier kann nicht gelöscht werden, weil es noch mit einer oder mehrere Schichten verknüpft ist. Alternativ können Sie das Revier auf inaktiv setzen.",
      "failure": "Ein Fehler ist aufgetreten. Das Revier konnte nicht gelöscht werden."
    }
  },
  "shift": {
    "shift": "Schicht",
    "shifts": "Schichten",
    "shiftPlanning": "Dienstplanung",
    "noShifts": "- keine Schichten konfiguriert -",
    "expired": "abgelaufen",
    "expiredAt": "Abgelaufen am {{date}}",
    "reactivate": "Schicht reaktivieren",
    "showExpiredShifts": "Abgelaufene Schichten anzeigen",
    "create": "Schicht anlegen",
    "created": "Die Schicht wurde angelegt",
    "update": "Schicht bearbeiten",
    "updated": "Die Schicht wurde aktualisiert",
    "baseData": "Basisdaten",
    "options": "Optionen",
    "details": "Weitere Details",
    "shiftData": "Schichtdaten",
    "name": "Name",
    "district": "Revier",
    "abbreviation": "Kürzel",
    "abbreviationInfo": "Das Kürzel wird in Vorschau und Druckansicht angezeigt.",
    "locationActivity": "Leistung",
    "requiredEmployees": "Anzahl Mitarbeiter",
    "timeAndDay": "Uhrzeit + Tag",
    "startTime": "Von",
    "endTime": "Bis",
    "break": "inkl. Pause (min)",
    "duration": "Dauer",
    "holidayHandling": "Feiertage berücksichtigen",
    "notOnPublicHolidays": "Schicht gilt nicht an Feiertagen",
    "allDays": "Schicht gilt immer",
    "onlyPublicHolidays": "Schicht gilt nur an Feiertagen",
    "description": "Beschreibung",
    "employeeDescription": "Mitarbeiter Hinweise",
    "externalId": "Externe Nummer",
    "assignEmployees": "Mitarbeiter zuweisen",
    "addEmployees": "Mitarbeiter wählen",
    "planningSettings": "Einplanungseinstellungen",
    "workArea": "Arbeitsbereich dieser Schicht",
    "durationOfThisShift": "Dauer dieser Schicht",
    "dateOfThisShift": "Datum dieser Schicht",
    "withRepetition": "(mit Wiederholungen)",
    "availableEmployees": "Verfügbare Mitarbeiter",
    "plannedEmployees": "Eingeplante Mitarbeiter",
    "onlyToday": "einmalig",
    "repeated": "wiederholt",
    "repeatedCapital": "Wiederholt",
    "repeatedUntil": "Wiederholt bis",
    "singleDays": "Einzelne Tage",
    "toOccupy": "zu besetzen",
    "assigned": "Schicht besetzt",
    "editThisShift": "Schichteinstellungen",
    "visibilityInfo": "Hier legen Sie das Datum fest, bis zu dem Ihre Mitarbeiter die Planung sehen dürfen.",
    "visibleUntil": "Sichtbar bis",
    "visibility": "Sichtbarkeit",
    "addEmployee": "Mitarbeiter hinzufügen",
    "thisShift": "Diese Schicht",
    "newPlanning": "Neue Planung:",
    "deleteThisPlanning": "Diese Planung löschen:",
    "at": "am Datum",
    "from": "ab Datum",
    "repeating": "wiederholend",
    "allPlannings": "alle Planungen",
    "delete": "Planung löschen",
    "deleteFromWeek": "Alle Planungen dieser Woche löschen",
    "settings": "Einstellungen Kalender",
    "showShiftName": "Schichtname anzeigen",
    "showShiftAbrv": "Schichtkürzel anzeigen",
    "showEmployeeName": "Mitarbeiternamen anzeigen",
    "showActivity": "Leistung anzeigen",
    "budgetExceeded": "Kontrollvorgabe überschritten",
    "employeePerDistrict": "Mitarbeiter pro Revier",
    "hideAll": "Keine Mitarbeiter anzeigen",
    "withAlarmOnly": "Nur Mitarbeiter mit Alarmen anzeigen",
    "showAll": "Alle Mitarbeiter anzeigen",
    "hideWithoutAlarm": "Mitarbeiter ausblenden",
    "absence": "Mitarbeiter ist an diesem Tag wegen {{absenceType}} abwesend.",
    "noDistrictsHeader": "Revier benötigt",
    "noDistrictsMessage": "Damit Sie mit der Dienstplanung starten können müssen Sie mindestens ein Revier anlegen.",
    "noShiftsHeader": "Erste Schicht anlegen",
    "noShiftsMessage": "Um mit der Dienstplanung zu starten, legen Sie Ihre erste Schicht an.",
    "activateShiftPlanning": "Soll die Dienstplanung für dieses Objekt nun aktiviert werden?",
    "couldNotActivateShiftPlanning": "Dienstplanung konnte nicht aktiviert werden, da bereits zukünftige Planungen vorhanden sind. Bitte löschen oder beenden Sie diese zuvor.",
    "export": "Excel Export",
    "exportInfo": "Export von Objekt {{locationName}} für Zeitraum {{from}} - {{to}} als druckbare Excel.",
    "showLegend": "Legende anzeigen",
    "showEmployeeNames": "Mitarbeiter-Namen",
    "showEmployeeNamesOptions": {
      "none": "Keine",
      "fullName": "Vollständige Namen",
      "abbreviation": "Abkürzungen"
    },
    "showEmployeeDescriptions": "Mitarbeiter-Hinweise anzeigen",
    "showEmployeeNumber": "Mitarbeiter-Nummer anzeigen",
    "alerts": {
      "alerts": "Alarme",
      "EmployeeWorkloadSchemaInTimeRangeExceeded": "Die vertraglich vereinbarte Arbeitszeit (WAZ) im aktuellen Zeitraum wird überschritten.",
      "EmployeeMaxWorkingHoursOfDayExceeded": "Max. Stunden pro Tag überschritten.",
      "EmployeeMissingRestPeriod": "Ruhezeit von 11 Stunden nicht eingehalten.",
      "EmployeeMaxContiguousWorkingDaysExceeded": "Arbeitet mehr als 6 Tage am Stück.",
      "EmployeeOverlappingsInPlanning": "Die Zeiträume der eingeplanten Schichten überschneiden sich am {{date}}."
    },
    "errors": {
      "errorUntil": "Von Zeit muss kleiner als Bis Zeit sein",
      "errorValidity": "Es können keine Einplanungen mit sich überschneidenden Gültigigkeiten angelegt werden.",
      "validOnBeforeTomorrow": "\"Gültig am\" kann frühestens morgen sein",
      "validFromBeforeTomorrow": "\"Gültig ab\" kann frühestens heute sein",
      "validFromAfterValidUntil": "\"Gültig ab\" muss vor \"Gültig bis\" liegen",
      "validUntilIsPast": "\"Gültig bis\" darf nicht in der Vergangenheit liegen",
      "expired": "Schicht ist abgelaufen",
      "planningHasPlanningEntries": "Heutige Planung {{date}} konnten nicht gelöscht werden, da bereits Arbeitszeiten für die Planung erfasst wurden."
    },
    "intervals": {
      "every": "jeden",
      "second": "zweiten",
      "third": "dritten",
      "fourth": "vierten"
    },
    "visibilitySaved": "Die Sichtbarkeit wurde angepasst."
  },
  "activityTypes": {
    "requiredFieldsErrors": "Bitte füllen Sie Name und Nummer aus um eine Leistungsart anzulegen.",
    "created": "Die Leistungsart wurde angelegt",
    "typeUpdated": "Die Leistungsart wurde aktualisiert",
    "nameAndNumberMayNotBeChanged": "Name und Nummer können nicht mehr geändert werden, wenn die Leistungsart bereits in Benutzung ist.",
    "create": "Leistungsart",
    "noActivityTypes": "- keine Leistungsarten konfiguriert -",
    "activityTypes": "Leistungsarten",
    "activityType": "Leistungsart",
    "inActive": "deaktiviert",
    "active": "Aktiv",
    "name": "Name",
    "number": "Nummer",
    "activate": "Aktivieren",
    "activateTitle": "Leistung aktivieren",
    "deactivate": "Deaktivieren",
    "edit": "Leistungsart bearbeiten",
    "activities": {
      "choose": "Leistung auswählen",
      "noActivity": "- keine Leistung -",
      "noActivityTypes": "Leistungen können nicht angelegt werden, weil keine Leistungsarten vorhanden sind. Leistungsarten müssen zuerst in den Mandanteneinstellungen angelegt werden.",
      "assign": "Leistung zuordnen",
      "assigned": "Leistung wurde zugeordnet / entfernt",
      "activity": "Leistung",
      "entityPlural": "Leistungen",
      "save": "Leistung speichern",
      "edit": "Leistung bearbeiten",
      "create": "Leistung",
      "created": "Die Leistung wurde angelegt",
      "updated": "Die Leistung wurde aktualisiert",
      "pageTitle": "Leistungen",
      "info": "Mit Leistungen können Arbeitszeiten auf verschiedene Tätigkeiten, wie Glasreinigung oder Unterhaltsreinigung gebucht werden.",
      "name": "Leistungsbeschreibung",
      "costCenterNumber": "Kst. Nr",
      "orderNumber": "Auftragsnummer",
      "district": "Revier",
      "shortname": "Kurzwahlnr. für telefonische Zeiterfassung",
      "shortnameInfo": "Die Kurzwahl muss zweistellig sein. Bitte nur einen Wert eintragen, wenn Sie Leistungen über die Telefonfunktion erfassen.",
      "shortnameShort": "Kurzwahlnr.",
      "noActivities": "- keine Leistungen am Objekt konfiguriert -",
      "activate": "Aktivieren",
      "activated": "Die Leistung wurde aktiviert.",
      "delete": "Leistung löschen",
      "confirmDelete": "Wollen Sie diese Leistung wirklich löschen?",
      "deactivatedNotDeleted": "Die Leistung konnte nicht gelöscht werden, weil Arbeitszeiten damit verknüpft sind. Die Leistung wird stattdessen deaktiviert.",
      "deleted": "Die Leistung wurde gelöscht.",
      "showInactiveActivities": "Inaktive Leistungen anzeigen",
      "showExpiredActivities": "Abgelaufene Leistungen anzeigen",
      "note": "Notiz",
      "validFrom": "Gültig ab",
      "validTo": "Gültig bis",
      "externalId": "Externe ID",
      "noMatch": "Die gewählte Leistung passt nicht zum gewählten Datum oder ist inaktiv.",
      "noMatchCreate": "Die Leistung der Planung passt nicht zum gewählten Datum oder ist inaktiv. Es wurde automatisch “keine Leistung” vorausgewählt.",
      "timeRangeInfo": "Wenn Sie eine Gültigkeit festlegen, dann kann diese Leistung nur für Arbeitszeiten verwendet werden, die im angegebenen Zeitraum erfasst wurden.",
      "noActivityAvailable": "Keine aktive Leistung zur Auswahl vorhanden.",
      "searchPlaceholder": "Suche nach Leistungsbeschreibung, Leistungsart, Kostenstelle oder Auftragsnr.",
      "sort": {
        "nameAsc": "Leistungsbeschreibung (A..Z)",
        "nameDesc": "Leistungsbeschreibung (Z..A)"
      },
      "expired": "Ungültig",
      "error409_qrCode": "Eine inaktive oder nicht mehr gültige Leistung kann nicht einem Objekt-Code zugewiesen werden.",
      "isDefault": "Standard",
      "isDefaultDescription": "Sie können eine Leistung als Standard definieren, dann wird diese Leistung allen Arbeitszeiten zugeordnet, die ohne Planung oder definierte Leistung erfasst wurden.",
      "defaultAlreadyExists": "Leistung kann nicht gespeichert werden, da bereits eine Standard-Leistung für diesen Gültigkeitszeitraum existiert."
    }
  },
  "ABSCENCE_TYPES": {
    "Work": "Arbeit",
    "Vacation": "Urlaub",
    "Disease": "Krankheit",
    "Missing": "Unentschuldigtes Fehlen",
    "Pubholiday": "Feiertag",
    "ChildDisease": "Kind krank",
    "SpecialLeave": "Sonderurlaub",
    "ShortTimeWork": "Kurzarbeit",
    "Unpaid": "Frei/Unbezahlt",
    "Other": "Sonstiges"
  },
  "ADDRESS": {
    "COMPANY": "Firma",
    "NAME": "Bezeichnung",
    "ADDITION": "Adresszusatz",
    "STREET": "Strasse",
    "ADDRESS_NO": "Hausnummer",
    "ZIP": "PLZ",
    "CITY": "Ort",
    "COUNTRY": "Land"
  },
  "CHECK_DATA_PAGE": {
    "PAGE_TITLE": "Daten überprüfen",
    "TEXT": "Falls wir Ihr Kennwort zurücksetzen müssen benötigen wir Ihre E-Mail-Adresse und optional Ihre Mobilnummer.",
    "EMAIL": "E-Mail-Adresse",
    "MOBILE": "Mobilnummer"
  },
  "CODE": "Code",
  "COMMENT": "Kommentar",
  "COMMENTS": {
    "comment": "Kommentar",
    "comments": "Kommentare",
    "addComment": "Kommentar hinzufügen",
    "saveComment": "Kommentar speichern",
    "noComments": "Keine Kommentare vorhanden",
    "confirmDelete": "Wollen Sie diesen Kommentar wirklich löschen?",
    "deleted": "Kommentar wurde gelöscht",
    "created": "Kommentar wurde angelegt",
    "noCommentCreated": "Es wurde kein Kommentar angegeben.",
    "validationErrorLength": "Kommentare dürfen nicht mehr als 1000 Zeichen enthalten. Bitte kürzen Sie Ihren Kommentar."
  },
  "DAY": {
    "MONDAY": "Montag",
    "TUESDAY": "Dienstag",
    "WEDNESDAY": "Mittwoch",
    "THURSDAY": "Donnerstag",
    "FRIDAY": "Freitag",
    "SATURDAY": "Samstag",
    "SUNDAY": "Sonntag"
  },
  "DAY_SHORT": {
    "MONDAY": "Mo",
    "TUESDAY": "Di",
    "WEDNESDAY": "Mi",
    "THURSDAY": "Do",
    "FRIDAY": "Fr",
    "SATURDAY": "Sa",
    "SUNDAY": "So"
  },
  "DELETE_DATA": "Daten löschen!",
  "DURATION": "Dauer",
  "EMPLOYEE": {
    "multiple": {
      "placeholder": "Keine {{entity}} gefunden."
    },
    "SELECT_ASSIGNEE": "Bearbeiter wählen",
    "SELECT_REPORTER": "Ersteller wählen",
    "additionalInfo": "Zusatzinformation",
    "abscenceOverview": {
      "title": "Abwesenheiten",
      "description": "Hinweis: Aus Datenschutzgründen werden Krankheitstage in dieser Liste nicht angezeigt.",
      "noResults": "Für den ausgewählten Zeitraum sind keine Abwesenheiten vorhanden.",
      "type": "Art",
      "daySum": "Tage gesamt",
      "toggleLabel": "Anzeigen"
    },
    "edit": {
      "pageTitle": "Mitarbeiter bearbeiten"
    },
    "DEACTIVATE": "Mitarbeiter deaktivieren",
    "ACTIVATE": "Mitarbeiter reaktivieren",
    "EMPLOYEE": "Mitarbeiter",
    "EMPLOYEES": "Mitarbeiter",
    "CREATED": "Mitarbeiter erfolgreich angelegt",
    "SAVED": "Der Mitarbeiter wurde gespeichert",
    "SELECT_EMPLOYEE": "Mitarbeiter auswählen",
    "SELECT_MANAGER": "Objektleiter auswählen",
    "SELECT_TEAMLEADER": "Vorgesetzten auswählen",
    "PRE_NAME": "Vorname",
    "SURE_NAME": "Nachname",
    "MOBILE": "Mobilnummer",
    "EMP_NO": "Personalnummer",
    "EMP_NO_SHORT": "Pers.Nr.",
    "LOGIN_USER_TYPE": "Benutzertyp",
    "WORKLOAD_SCHEME": "Wochenarbeitszeit",
    "workloadSchemaDescription": "Bitte tragen Sie hier die Arbeitszeit laut Vertrag (Wochenarbeitszeit) ein. Die exakte Einplanung können Sie nach dem Anlegen des Mitarbeiters vornehmen.",
    "PERMISSION_GROUP": "Berechtigungsgruppe",
    "TIME_TRACKING_MODE": "Zeiterfassungstyp",
    "TIME_TRACKING": {
      "PROPOSED": "Sollzeiten bestätigen",
      "EXACT": "Start / Stop"
    },
    "SUPERVISOR": "Vorgesetzter",
    "SUPERVISORS": "Vorgesetzte",
    "ASSIGN_SUPERVISOR": "Vorgesetzten zuweisen",
    "ASSIGN_LOGIN_CARD": "Neue Blink-ID zuweisen",
    "ASSIGN_LOGIN_CARD_WARNING": "<b>Achtung:</b> Wenn Sie dem Mitarbeiter eine neue Blink ID zuweisen, dann muss sich der Mitarbeiter in allen Apps neu anmelden. Bitte informieren Sie den Mitarbeiter darüber nach der Änderung der Blink ID.",
    "ASSIGN_LOGIN_CARD_TEXT": "Für die Anmeldung in Blink wurde automatisch eine Blink ID erzeugt und zugewiesen.<br/>Mit dem angezeigten QR Code kann sich {{employeeName}} in den Blink Apps anmelden.",
    "ASSIGN_LOGIN_CARD_TITLE": " Persönliche Blink ID Karte von {{employeeName}}",
    "ASSIGN_LOGIN_CARD_DESCRIPTION": "<br/>Hinweis: Über die Mitarbeiter Stammdaten<br/>können Sie die Blink ID Karte jederzeit herunterladen<br/>oder eine abweichende Blink ID zuweisen, wenn<br/>Sie NFC Karten für Terminals verwenden.",
    "assignLoginCardManuallyTitle": "Karte zuweisen",
    "ASSIGN_LOGIN_CARD_MANUALLY": "Bitte geben Sie die Blink ID ein, die dem Mitarbeiter zugewiesen werden soll.",
    "LOGIN_CARD_ASSIGNED": "Blink-ID wurde zugewiesen",
    "SUBTRACT_BREAK": "Pausenzeiten automatisch von erfassten Zeiten abziehen",
    "CALENDAR": "Feiertagskalender",
    "NO_CALENDAR": "Kein Kalender festgelegt",
    "INACTIVE": "Inaktiv",
    "DETAIL_DATA": "Stammdaten",
    "BOOKED_TIMES": "Gebuchte Zeiten",
    "NO_WORKLOADSCHEMA": "-- keine Wochenarbeitszeit --",
    "WIZARD": {
      "proceed": "Weiter",
      "back": "Zurück",
      "STEP_1": "Basisdaten ({{step}}/{{steps}})",
      "STEP_2": "Wochenarbeitszeit ({{step}}/{{steps}})",
      "STEP_3": "Einstellungen & Sonstiges ({{step}}/{{steps}})",
      "STEP_TEAMLEADER": "Vorgesetzte zuordnen ({{step}}/{{steps}})",
      "STEP_4": "Blink ID ({{step}}/{{steps}})",
      "SKIP_BLINK_ID": "Blink ID später zuordnen",
      "stepTeamleaderDescription": "Hier können Sie einen oder mehrere Vorgesetzte festlegen, die Zugriff auf diesen Mitarbeiter haben sollen.",
      "stepTeamLeaderDescriptionSecondPart": "Sie können diesen Schritt auch überspringen und später in den Stammdaten Vorgesetzte zuweisen",
      "stepTeamLeaderEmptyState": "Dieser Mitarbeiter hat noch keinen Chef.",
      "FINISH": {
        "WITH_BLINK_ID": "Ihr Mitarbeiter ist erfolgreich angelegt und kann mit der Zeiterfassung starten. Optional können Sie den neuen Mitarbeiter pro Wochentag und Objekt einplanen. Die Einplanung nehmen Sie bitte im Objekt vor.",
        "WITHOUT_BLINK_ID": "Ihr Mitarbeiter ist erfolgreich angelegt, kann aber erst mit der Zeiterfassung starten, wenn Sie eine Blink ID zuordnen.",
        "FINISH": "Fertig",
        "CREATE_ANOTHER_EMPLOYEE": "Weiteren Mitarbeiter anlegen"
      }
    },
    "PLANNING": "Einplanung",
    "taxType": "Beschäftigungsverhältnis",
    "allTaxTypes": "Alle Beschäftigungsverhältnisse",
    "taxTypes": {
      "SocialInsuranceEmployment": "sozialversicherungspflichtig",
      "MinorEmployment": "geringfügig beschäftigt"
    },
    "addEmployee": "Mitarbeiter hinzufügen",
    "leaveDate": "Austrittsdatum",
    "detail": {
      "global": "Allgemein",
      "contract_data": "Vertragsdaten",
      "settings": "Einstellungen",
      "other": "Sonstiges",
      "no_emp_no": "Keine Personalnummer vorhanden",
      "entryDate": "Eintrittsdatum",
      "leavingDate": "Austrittsdatum",
      "costCenterNumber": "Standard Kostenstelle",
      "daysOfVacationCurrentYear": "Urlaubsanspruch pro Jahr",
      "daysOfVacationCurrentYearInfo": "Anzahl der Urlaubstage pro Jahr",
      "remainingDaysOfVacationLastYear": "Resturlaub",
      "remainingDaysOfVacationLastYearInfo": "Anzahl der Resturlaubstage für das aktuelle Jahr",
      "workloadSchema": "Wochenarbeitszeit",
      "editWorkloadSchema": "Wochenarbeitszeit bearbeiten",
      "DEACTIVATE_CONFIRM": "Wollen Sie diesen Mitarbeiter wirklich deaktivieren? Der Mitarbeiter kann dann keine Zeiten mehr erfassen oder sich im System anmelden. Der Mitarbeiter kann später wieder aktiviert werden, wobei er jedoch in jedem Fall eine neue Blink ID benötigt. ACHTUNG: Es werden alle Einplanungen und Vorgesetzten vom Mitarbeiter gelöscht!",
      "USER_DEACTIVATED": "Der Mitarbeiter {{employee}} wurde deaktiviert",
      "ACTIVATE_CONFIRM": "Wollen Sie diesen Mitarbeiter wieder aktivieren? Damit der Mitarbeiter wieder Zeiten erfassen und sich im System anmelden kann, muss ihm eine neue Blink ID zugewiesen werden. Anschließend kann sich der Mitarbeiter wieder registrieren.",
      "USER_ACTIVATED": "Der Mitarbeiter {{employee}} wurde reaktiviert",
      "leavingNotice": "Bei Angabe eines Austrittsdatums wird der Mitarbeiter am Folgetag automatisch deaktiviert.",
      "noTeamleaders": "Diesem Mitarbeiter wurde noch kein Vorgesetzter zugewiesen.",
      "addTeamleader": "Vorgesetzten zuweisen",
      "teamleaderAssigned": "Der Vorgesetzte wurde dem Mitarbeiter zugewiesen.",
      "teamleaderRemoved": "Der ausgewählte Vorgesetzte wurden entfernt.",
      "showBlinkId": "Blink ID Karte anzeigen & teilen",
      "noLoginCard": "keine",
      "deleteFutureAbsences": "Löschen zukünftiger Abwesenheiten",
      "deleteTeamleader": "Möchten Sie den ausgewählten Vorgesetzte wirklich entfernen?"
    },
    "delete": {
      "deleteEmployee": "Mitarbeiter löschen",
      "deleteConfirm": "Wollen Sie diesen Mitarbeiter wirklich löschen? Diese Aktion kann nicht zurückgenommen werden.",
      "deleted": "Der Mitarbeiter wurde gelöscht.",
      "deleteError": "Der Mitarbeiter kann nicht gelöscht werden. ({{message}})"
    },
    "NO_RESULTS": "Kein Mitarbeiter gefunden.",
    "search": {
      "searchTerm": "Suchbegriff",
      "placeholder": "Suchen nach Vor- und Nachname, Personalnummer oder Blink ID",
      "notLoaded": "Suchen Sie nach Mitarbeitern.",
      "showInactiveEmployees": "Inaktive Mitarbeiter anzeigen",
      "assignTeamleader": "Vorgesetzten zuweisen",
      "removeTeamleader": "Vorgesetzten entfernen",
      "teamleaderAssigned": "Der Vorgesetzte wurde dem ausgewählten Mitarbeitern zugewiesen.",
      "teamleaderRemoved": "Der Vorgesetzte wurde vom ausgewählten Mitarbeitern entfernt.",
      "areaAssigned": "Der Bereich wurde dem ausgewählten Mitarbeitern zugewiesen.",
      "areaRemoved": "Der Bereich wurde vom ausgewählten Mitarbeitern entfernt.",
      "selectAll": "Mitarbeiter auswählen",
      "selectAllGlobal": "Alle {{count}} auswählen",
      "deSelectAllGlobal": "Alle {{count}} abwählen",
      "allCompanies": "Alle Mandanten",
      "chooseAction": "Ausführen ({{count}})",
      "results": "{{count}} von {{total}} Ergebnissen",
      "massAction": "Aktion für {{count}} Mitarbeiter",
      "resetSearch": "Suche zurücksetzen"
    },
    "sort": {
      "nameAsc": "Name (A..Z)",
      "nameDesc": "Name (Z..A)",
      "empNo": "Personalnr.",
      "empNoAsc": "Personalnr. (1..9)",
      "empNoDesc": "Personalnr. (9..1)",
      "createDate": "Erstelldatum",
      "createDateAsc": "Erstelldatum ältestes zuerst",
      "createDateDesc": "Erstelldatum neuestes zuerst"
    },
    "vacation": {
      "edit": "Resturlaub bearbeiten",
      "vacationDays": "Anzahl Tage Resturlaub aus {{year}}",
      "remainingForYear": "Resturlaub im Jahr {{year}}: {{days}} Tage",
      "hint": "Geben Sie das Jahr an, in dem der Resturlaub genommen werden soll."
    },
    "timeLimit": "Mitarbeiterbefristung",
    "timeLimits": "Mitarbeiterbefristungen",
    "error": {
      "409": "E-Mail oder Mobilnummer wird für die sichere Authentifizierung genutzt und darf nicht leer sein."
    }
  },
  "EMPLOYEE_ADD_MODAL": {
    "PAGE_TITLE": "Zuordnung",
    "CHOOSE_EMPLOYEES": "Wählen Sie die Mitarbeiter aus, die Sie dem Objekt hinzufügen möchten.",
    "ADD_EMPLOYEES": "Mitarbeiter hinzufügen"
  },
  "EMPLOYEE_PLANNING": {
    "noPlannings": "Keine Einplanung vorhanden",
    "noPlannings2": "Keine Planungen vorhanden",
    "PAGE_TITLE": "Wochenplanung",
    "SCHEDULE": "Planung",
    "PLANNED_DURATION": "Soll",
    "deletePlanning": "Serienplanung löschen",
    "deleteAllPlannings": "Alle Serienplanungen löschen",
    "deleteAllPlanningsConfirm": "Wollen Sie wirklich alle Einplanungen für das Objekt {{location}}  löschen?",
    "deleteAllEmployeePlanningsConfirm": "Wollen Sie wirklich alle Einplanungen für den Mitarbeiter {{employee}}  löschen?",
    "deleteAllPlanningsSuccess": "Alle Einplanungen wurden gelöscht.",
    "overPlanned": "Achtung: Die eingeplanten Zeiten überschreiten die Wochenarbeitszeit vom Mitarbeiter!",
    "createSerialPlanning": "Serienplanung anlegen",
    "createSinglePlanning": "Einzelplanung anlegen",
    "createAbsence": "Abwesenheit anlegen"
  },
  "ENTER_CODE_PAGE": {
    "PAGE_TITLE": "Code eingeben",
    "EMAIL_TEXT": "Geben Sie den Code ein, den wir an folgende E-Mail-Adresse geschickt haben:",
    "SMS_TEXT": "Geben Sie den Code ein, den wir an folgende Mobilnummer geschickt haben:",
    "NO_CODE": "Ich habe keinen Code erhalten.",
    "CODE_LENGTH_ERROR": "Der eingegebene Code ist länger als sechs Zeichen. Bitte geben Sie Ihren Code erneut ein."
  },
  "ERROR": {
    "100": "Es konnte keine Verbindung hergestellt werden. Bitte prüfen Sie Ihre Internetverbindung.",
    "403": "Sie haben keine Berechtigung um diese Aktion auszuführen.",
    "403_REFRESH_TOKEN": "Ihre Zugangsdaten sind nicht mehr gültig. Bitte melden Sie sich erneut an!",
    "404": "Daten sind für Ihren Benutzer nicht verfügbar. Bitte wenden Sie sich an den Support.",
    "500": "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    "needReload": "Es ist ein Fehler beim Laden der Daten aufgetreten. Die Seite wird neu geladen.",
    "planningsConflictNoPermission": "Planung kann nicht angelegt werden. Es gibt eine Überschneidung mit einem Objekt, das Sie nicht sehen dürfen.",
    "planningsConflict": "Der ausgewählte Mitarbeiter ist zu diesem Zeitpunkt bereits verplant. Bitte wählen Sie einen anderen Mitarbeiter.",
    "worklogOverlapping": "Es gibt bereits eine Zeiterfassung in den angegebenen Zeiträumen. Überschneidungen sind nicht erlaubt. Bitte kontrollieren Sie Ihre Angaben."
  },
  "ERROR_OCCURRED": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
  "ERROR_PARTIALLY_OCCURRED": "Daten konnten nicht vollständig geladen werden.",
  "FORGOT_PASSWORD": {
    "emailSent": "Eine E-Mail mit der Sie Ihre Zugangsdaten ändern können, wurde an Sie geschickt.",
    "FORGOT_PASSWORD": "Kennwort vergessen?",
    "FORGOT_PASSWORD_TEXT": "Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse und das Zielsystem an um Ihr Kennwort zurückzusetzen.",
    "FORGOT_PASSWORD_PLACEHOLDER": "Benutzername oder E-Mail",
    "errors": {
      "notAllowed": "Für diesen Benutzer kann kein Passwort zurückgesetzt werden.",
      "invalidToken": "Der Code, um ihr Kennwort zurückzusetzen, ist abgelaufen. Bitte fordern Sie einen neuen Code an."
    },
    "reset": {
      "backToRoot": "Zurück zur Login-Seite",
      "renewCode": "Neuen Code anfordern",
      "resetPassword": "Kennwort zurücksetzen",
      "success": "Ihr Kennwort wurde erfolgreich zurückgesetzt.",
      "password1": "Ihr Neues Kennwort",
      "password2": "Kennwort wiederholen",
      "validationError": "Das Kennwort entspricht nicht unseren Passwortrichtlinien!",
      "passwordsNotEqual": "Die Kennwörter sind nicht identisch! Bitte überprüfen Sie Ihre Eingabe.",
      "passwordValidation": "Das Kennwort muss mindestens 6 Zeichen umfassen und  einen Buchstaben, eine Zahl und ein Sonderzeichen enthalten."
    }
  },
  "GLOBAL": {
    "massActions": "Massenaktionen",
    "duplicate": "Duplizieren",
    "attachments": "Anhänge",
    "filter": "Filter",
    "filterIsActive": "Ansicht ist gefiltert.",
    "resetFilter": "Filter zurücksetzen",
    "details": "Details",
    "showDetails": "Details einblenden",
    "hideDetails": "Details ausblenden",
    "TIME": "Uhrzeit",
    "inactiveEntities": "Inaktive",
    "active": "aktiv",
    "inactive": "deaktiviert",
    "loadingBlink": "Blink wird geladen ...",
    "help": "Hilfe",
    "excelExport": "Excel-Export",
    "overtime": "Überstunden",
    "impersonate": "Identität annehmen",
    "loginCard": "Blink ID Karte",
    "ACCEPT": "Übernehmen",
    "OKAY": "Ok",
    "CANCEL": "Abbrechen",
    "ADD": "Hinzufügen",
    "SAVE": "Speichern",
    "FINISH": "Fertigstellen",
    "SEARCH": "Suchen",
    "DOWNLOAD": "Herunterladen",
    "DOWNLOAD_ERROR": "Die Datei konnte nicht geöffnet werden. Bitte installieren Sie eine APP um Dateien dieses Typs öffnen zu können. (z.B. PDF Viewer, MS-/Google Docs)",
    "SHARE": "Teilen",
    "PLEASE_WAIT": "Daten werden geladen",
    "PLEASE_WAIT2": "Bitte warten",
    "SAVED": "Daten wurden gespeichert",
    "BACK": "Zurück",
    "SEND": "Abschicken",
    "ACTIONS": "Aktionen",
    "CHOOSE_ACTION": "Aktion auswählen",
    "CLOSE": "Schliessen",
    "CHOOSE_COMMENT": "Bitte Kommentar angeben",
    "LOAD_MORE": "Weitere laden",
    "NO_PERMISSION_GROUP": "Keine Sonderberechtigungen",
    "ERROR_OCCURED": "Ein Fehler ist aufgetreten oder die Daten sind nicht korrekt. Bitte versuchen Sie es erneut.",
    "DATE": "Datum",
    "FROM": "Von",
    "UNTIL": "Bis",
    "CHOOSE_DATE": "Datum auswählen",
    "OFFLINE": "Keine Verbindung zum Server. Bitte überprüfen Sie Ihre Interneteinstellungen.",
    "YES": "Ja",
    "NO": "Nein",
    "STOP_REQUEST": "Anfrage abbrechen",
    "CALENDAR": "Feiertagskalender",
    "NEXT": "Weiter",
    "ACCEPTANCE": "Bestätigung",
    "ERROR": "Fehler",
    "client": "Mandant",
    "COMMENT": "Kommentar",
    "COMMENTS": "Kommentare",
    "DELETE": "Löschen",
    "EDIT": "Bearbeiten",
    "RESPECT": "Berücksichtigen",
    "IGNORE": "Ignorieren",
    "NOTICE": "Hinweis",
    "CREATE": "Erstellen",
    "ACTIVE": "Aktiv",
    "NAME": "Name",
    "DESCRIPTION": "Beschreibung",
    "NUMBER": "Nummer",
    "EXTERNALID": "Externe Id",
    "RESET": "Zurücksetzen",
    "ALL": "Alle",
    "SHOW": "Anzeigen",
    "OR": "oder",
    "AND": "und",
    "TITLE": "Titel",
    "MISSING_REQUIRED_FIELDS": "Bitte überprüfen Sie das Formular und füllen alle Pflichtfelder aus.",
    "INACTIVE": "Inaktiv",
    "invalidTokens": "Ihre gespeicherten Anmeldeinformationen sind abgelaufen. Bitte melden Sie sich erneut an.",
    "settingsSaved": "Die Einstellungen wurden gespeichert.",
    "of": "von",
    "settings": "Einstellungen",
    "status": "Status",
    "emailAddress": "E-Mail Adresse",
    "search": {
      "numberResults": "Anzahl Ergebnisse",
      "results": "{{count}} von {{total}} Ergebnissen",
      "quickFilter": "Schnellfilter",
      "reset": "Zurücksetzen",
      "search": "Suchen",
      "noResults": "Leider haben wir nichts gefunden. Bitte suchen Sie erneut.",
      "allCompanies": "Alle Mandanten",
      "qualifySearchNotice": "Bitte schränken Sie Ihren Suchbegriff weiter ein.",
      "showInactiveEmployees": "Inaktive Mitarbeiter anzeigen"
    },
    "detail": "Detail",
    "adress": "Adresse",
    "workload": "Wochenarbeitszeit",
    "noWorkload": "keine Wochenarbeitszeit",
    "differentUTC": "Die eingestellte Zeitzone Ihres Gerätes entspricht nicht der Zeitzone des Blink-Systems. Alle angezeigten Zeiten wurden umgerechnet auf die Zeitzone Ihres Gerätes. Geänderte und erfasste Zeiten werden automatisch in die Zeitzone des Blink Systems umgerechnet.",
    "YEAR": "Jahr",
    "cw": "KW",
    "color": "Farbe",
    "selectAll": "Alle auswählen",
    "deselectAll": "Alle abwählen",
    "format": "Format",
    "copy": "Kopieren",
    "wrongSystem": "Bitte öffnen Sie zukünftig den Blink Manager über https://{{companyName}}.blink.online. Bitte klicken Sie hier um zu wechseln.",
    "wrongSystemButton": "Jetzt wechseln auf {{companyName}}.blink.online",
    "pleaseSelectCompany": "Bitte wählen Sie im Hauptmenü einen Mandanten aus um diese Seite anzuzeigen.",
    "pleaseSelectCompanyTitle": "Mandant auswählen",
    "OPEN": "Öffnen",
    "REMOVE": "Entfernen",
    standard: 'Standard',
    "updated": "Daten wurden aktualisiert",
    "created": "Daten wurden erstellt"
  },
  "HELLO": "Hallo",
  "HOME": {
    "MANAGE": "Verwalten",
    "PAGE_TITLE": "Startseite",
    "OPEN_ISSUES": "Offene Tickets",
    "WHATS_MISSING": "Mitarbeiter Monitor",
    "APPROVE_TIMES": "Zeiten",
    "CREATE_WORKLOG": "Zeit erfassen",
    "createAbscence": "Abwesenheit erfassen",
    "TIMES_PER_LOCATION": "Zeiten pro Objekt",
    "TIMES_PER_EMPLOYEE": "Zeiten pro Mitarbeiter",
    "NO_CURRENT_USER": "Ihre Daten konnten nicht geladen werden!",
    "RELOAD_CURRENT_USER": "Daten erneut laden",
    "createEmployee": "Neuer Mitarbeiter",
    "createLocation": "Neues Objekt",
    "createCustomer": "Neuer Kunde",
    "placeholderEmployeeSearch": "Name, Personalnr. oder Blink ID",
    "placeholderLocationSearch": "Name oder Kst.Nr.",
    "VACATION_PLANNING": "Abwesenheiten"
  },
  "INFO": {
    "PAGE_TITLE": "Persönliche Einstellungen"
  },
  "LANGUAGE": {
    "de": "Deutsch",
    "tr": "Türkisch",
    "en": "Englisch",
    "hu": "Ungarisch",
    "ro": "Rumänisch",
    "bg": "Bulgarisch",
    "da": "Dänisch",
    "el": "Griechisch",
    "it": "Italienisch",
    "hr": "Kroatisch",
    "nl": "Niederländisch",
    "pl": "Polnisch",
    "pt": "Portugiesisch",
    "sk": "Slowakisch",
    "sl": "Slowenisch",
    "es": "Spanisch",
    "cs": "Tschechisch",
    "ru": "Russisch"
  },
  "LOCATION": {
    "single": {
      "placeholder": "Kein Ergebnis gefunden"
    },
    "gpsTolerance": {
      "systemTolerance": "Toleranz für GPS (Systemeinstellung): {{amount}} m",
      "locationToleranceLabel": "Toleranz (Radius in m)",
      "createLocationTolerance": "Abweichende Toleranz für dieses Objekt definieren"
    },
    "gpsHint": "Wenn Sie eine Adresse eingetragen haben, dann werden die Geokoordinaten automatisch ermittelt und eingetragen. Hier nur Eintragungen vornehmen, wenn Sie keine Adresse vom Objekt haben.",
    "editAddress": {
      "action": "Adresse ändern",
      "save": "Adresse speichern"
    },
    "editGPS": {
      "action": "GPS Daten ändern",
      "save": "GPS Daten speichern"
    },
    "SEARCH_PLACEHOLDER": "Nach Kst.Nr, Name oder Kunde suchen",
    "customer": "Kunde",
    "DETAIL_DATA": "Stammdaten",
    "SELECT_LOCATION": "Objekt auswählen",
    "navigateToObject": "Zum Objekt",
    "objectDetails": "Objektdetails",
    "PHONES": {
      "description": "Wenn es für ein Objekt mehrere Durchwahlen gibt können Sie auch mit dem Platzhalter * arbeiten. Beispiel: 091124033*",
      "invalidPhone": "Bitte geben Sie eine gültige Telefonnummer ein.",
      "ADD": "Telefonnummer hinzufügen",
      "addError": "Telefonnummer kann nicht hinzugefügt werden, weil sie bereits in einem anderen Objekt verwendet wird.",
      "ADDED": "Telefonnummer wurde hinzugefügt",
      "NO_ITEMS": "Keine Telefonnummern vorhanden",
      "TITLE": "Telefonnummern",
      "ASSIGNED_ITEMS": "Zugewiesene Telefonnummern",
      "CONFIRM_DELETE": "Wollen Sie diese Telefonnummer wirklich löschen?",
      "DELETED": "Telefonnummer gelöscht!",
      "COUNTRY_CODE": "Länderkennzeichnung",
      "PHONE_NUMBER": "Telefonnummer",
      "PHONE_HELP": "Was bedeuten diese Telefonnummern?",
      "PHONE_HELP_DESCRIPTION": "Telefonnummern sind nur relevant, wenn die Zeiterfassung per Telefon verwendet wird. An Hand der hinterlegten Nummer wird das Objekt identifiziert."
    },
    "comment": "Mitarbeiter-Hinweis",
    "comments": "Mitarbeiter-Hinweise",
    "commentsNotice": "Diese Hinweise werden Mitarbeitern in der App angezeigt, wenn sie die Zeiterfassung für das Objekt gestartet haben.",
    "DETAIL": "Objekt Detail",
    "NO_LOCATION": "kein Objekt angegeben",
    "LOCATION": "Objekt",
    "LOCATIONS": "Objekte",
    "NAME": "Objekt Name",
    "COST_CENTER_NO": "Kostenstellen-Nr.",
    "COST_CENTER_NO_SHORT": "Kst.Nr",
    "OBJECT_NO": "Objektnummer",
    "DESCRIPTION": "Kommentar",
    "MANAGER": "Objektleiter",
    "NO_MANAGERS": "Es wurde noch kein Objektleiter für dieses Objekt definiert",
    "ADDRESS": "Adresse",
    "COMPANY": "Mandant",
    "CHOOSE_COMPANY": "Bitte wählen Sie einen Mandanten aus.",
    "COMMENT": "Kommentar",
    "excelExportLocation": "Excel Export Objekte",
    "excelExportLocationCodes": "Excel Export Objekt Codes",
    "LOCATION_QR_CODES_EXCEL": "Standort-Codes",
    "EDIT": {
      "CONFIRM_DELETE_MANAGER": "Objektleiter entfernen",
      "DELETE_MANAGER": "Möchten Sie den ausgewählten Objektleiter wirklich entfernen?",
      "MANAGER_DELETED": "Objektleiter wurde entfernt.",
      "CHOOSE_MANAGERS": "Wählen Sie die Mitarbeiter aus, die als Objektleiter für dieses Objekt agieren sollen.",
      "ADD_MANAGERS": "Objektleiter festlegen",
      "MANAGERS_ADDED": "Der Objektleiter wurden dem Objekt hinzugefügt",
      "MANAGER_EXISTS": "Der gewählte Objektleiter ist bereits zugewiesen",
      "LOCATION_UPDATED": "Objekt wurde aktualisiert"
    },
    "DOWNLOAD_LOCATION_CODE": "Objekt-Code herunterladen",
    "SEND_PDF_HINT": "Bitte geben Sie die E-Mail-Adresse ein, an die der Objekt-Code versandt werden soll und wählen Sie das gewünschte Format.",
    "CODE_SENT": "Objekt-Code wurde versandt!",
    "CODES": {
      "CODE": "Objekt-Code",
      "PAGE_TITLE": "Objekt-Codes",
      "description": "QR Codes oder NFC Sticker werden in den Objekten angebracht um die Zeiten automatisch dem richtigen Objekt zuordnen zu können.",
      "NO_CODES_AVAILABLE": "- keine Objekt-Codes vorhanden -",
      "CONFIRM_DELETION": "Wollen Sie diesen Objekt-Code löschen?",
      "DELETE_CODE": "Objekt-Code löschen",
      "SCAN": "Objekt-Code scannen",
      "SCAN_TEXT": "Wenn Sie keine Objekt-Codes zur Verfügung haben, können Sie alternativ eigene Objekt-Codes generieren und diese dann drucken:",
      "GENERATE": "Objekt-Code generieren",
      "SUCCESS": "Objekt-Code wurde erfolgreich erstellt / zugewiesen",
      "ERROR_ASSIGN": "Der Objekt-Code ist bereits vergeben oder wurde deaktiviert",
      "ADD": "Objekt-Code hinzufügen",
      "addInfo": "Es sind nur Buchstaben, Zahlen, Unterstrich und Minus erlaubt.",
      "CREATE_TITLE": "Objekt-Code hinzufügen",
      "SCAN_ERROR": "Der gescannte QR-Code ist kein Objekt-Code!",
      "MANUALLY": "Manuell erfassen",
      "notCreated": "Der Code wurde nicht angelegt. Bitte geben Sie einen gültigen Wert ein."
    },
    "INACTIVE": "Inaktiv",
    "ACTIVATE": "Objekt reaktivieren",
    "DEACTIVATE": "Objekt deaktivieren",
    "DEACTIVATE_CONFIRM": "Wollen Sie dieses Objekt wirklich deaktivieren? Wenn Sie das Objekt deaktivieren, werden alle Einplanungen gelöscht. Wollen Sie fortfahren?",
    "ACTIVATE_CONFIRM": "Wollen Sie dieses Objekt wieder aktivieren? Ggfls. müssen Sie für Ihre Mitarbeiter an diesem Objekt neue Einplanungen erstellen.",
    "locationDeactivated": "{{ location }} wurde deaktiviert",
    "locationActivated": "{{ location }} wurde reaktiviert",
    "search": {
      "searchTerm": "Suchbegriff",
      "placeholder": "Suche nach Name, Nummer oder Objekt-Codes",
      "notLoaded": "Suchen Sie nach Objekten.",
      "NO_RESULTS": "Keine Objekte gefunden",
      "showInactiveLocations": "Inaktive Objekte anzeigen",
      "selectAll": "Auswählen",
      "unSelectAll": "AUSWAHL AUFHEBEN",
      "chooseAction": "Ausführen ({{count}})",
      "teamleaderAssigned": "Der Objektleiter wurde den ausgewählten Objekten zugewiesen.",
      "teamleaderRemoved": "Der Objektleiter wurde aus den ausgewählten Objekten entfernt."
    },
    "NO_RESULTS": "Keine Objekte gefunden.",
    "CONFIGURATION": "Konfiguration",
    "MONITORING": {
      "monitorButton": "Statistik",
      "pageTitle": "",
      "actualBelowTarget": "Ist-Zeit unterschreitet Soll-Zeit",
      "actualAboveTarget": "Ist-Zeit überschreitet Soll-Zeit",
      "actualEqualTarget": "Keine Abweichung zur Soll-Zeit",
      "actualBelowBudget": "Ist-Zeit unterschreitet Kontrollvorgabe",
      "actualAboveBudget": "Ist-Zeit überschreitet Kontrollvorgabe",
      "actualEqualBudget": "Keine Abweichung zur Kontrollvorgabe",
      "worklogsByActivity": "Zeit pro Leistung",
      "worklogsByEmployee": "Zeit pro Mitarbeiter",
      "open": "ANZEIGEN",
      "withoutActivity": "-- ohne Leistung --",
      "emptyState": "Keine Planungen und keine Arbeitszeiten vorhanden"
    },
    "sort": {
      "nameAsc": "Name (A..Z)",
      "nameDesc": "Name (Z..A)",
      "costCenterNumberAsc": "Kostenstelle (1..9)",
      "costCenterNumberDesc": "Kostenstelle (9..1)",
      "createDateAsc": "Erstelldatum ältestes zuerst",
      "createDateDesc": "Erstelldatum neuestes zuerst"
    }
  },
  "LOCATION_CREATE": {
    "TITLE": "Objekt erstellen",
    "CREATE": "Objekt erstellen",
    "SUCCESS": "Objekt wurde erstellt",
    "assignTeamleader": "Objektleiter zuweisen",
    "assignTeamleaderDescription": "Damit dieses Objekt von Objektleitern gesehen werden kann, weisen Sie diese hier zu.",
    "assignLater": "Später festlegen"
  },
  "LOCATION_DETAIL": {
    "PLANNING": "Einplanung",
    "SHIFTPLANNING": "Dienstplanung",
    "BOOKED_TIMES": "Gebuchte Zeiten",
    "PRINT_LOCATION_ID": "Objekt-Codes",
    "PHONE_NUMBERS": "Telefonnummern"
  },
  "LOCATION_PLANNING": {
    "overPlanned": "Achtung: Die eingeplanten Zeiten überschreiten die Kontrollvorgaben vom Objekt!",
    "NONE": "keine Plaung",
    "FINE": "Feine Planung",
    "ROUGH": "Grobe Planung",
    "PAGE_TITLE": "Einplanung",
    "TEXT": "Alle Planungsoptionen sind kombinierbar, können aber auch einzeln verwendet werden.",
    "HOURS_PER_WEEK": "Stunden / Woche",
    "EMPLOYEES_ASSIGNED": "Mitarbeiter sind dem Objekt zugeordnet",
    "NO_ENTRIES": "-- kein Mitarbeiter eingeplant --",
    "NO_LOCATION_PLANNING": "-- keine Planung vorhanden --",
    "EMPLOYEES": "Mitarbeiter im Objekt",
    "WORKING_DAYS": "Sollzeit pro Wochentag",
    "EMPLOYEE_PLANNING": "Sollzeit pro Mitarbeiter",
    "DISABLE_OPTION": "Option deaktivieren",
    "SHOWLOCATIONPLANNING": "Wenn Sie diese Option deaktivieren, wird die gesamte Grob-Planung gelöscht.",
    "SHOWEMPLOYEEPLANNING": "Wenn Sie diese Option deaktivieren, wird die gesamte Fein-Planung gelöscht.",
    "NONE_DESCRIPTION_TEXT": "Es wird keine Planung vorgenommen.",
    "FINE_DESCRIPTION_TEXT": "Pro Mitarbeiter können Sie exakt definieren, an welchem Tag wie lange gearbeitet wird. Das ist die genaueste und umfangreichste Form der Planung, aber nur mit dieser Art Planung kann das System erkennen ob Sollzeiten eingehalten werden oder nicht.",
    "ROUGH_DESCRIPTION_TEXT": "Hier definieren Sie die mit dem Kunden vereinbarten Zeiten, die an diesem Objekt gearbeitet werden müssen. Diese Angabe ist optional, hilft aber bei der Einplanung und der Zeitenkontrolle.",
    "PLANNING_DETAILS": "Planungsdetails",
    "DELETE_PLANNING_409": "Planung kann nicht gelöscht werden, da bereits Arbeitszeiten darauf erfasst wurden."
  },
  "LOCATION_PLANNING_ENTRIES": {
    "pageTitle": "Kontrollvorgaben",
    "TEXT": "Kontrollvorgaben helfen bei der Einplanung der Mitarbeiter und bei der Auswertung der Zeiten. Kontrollvorgaben dienen als Vergleichswert zu Soll-Planungen und Ist-Zeiten.",
    "TEXT2": "Wählen Sie die Tage an denen am Objekt gearbeitet wird und geben Sie die Gesamtdauer pro Tag an.",
    "SAVED": "Die Kontrollvorgaben für das Objekt {{location}} wurden gespeichert."
  },
  "LOGOUT_PAGE": {
    "PAGE_TITLE": "Abmelden",
    "CONFIRM_LOGOUT": "Wenn Sie sich abmelden, werden alle lokalen Daten gelöscht! Wollen Sie sich abmelden?"
  },
  "MANAGE_CALENDARS": {
    "CREATE_CALENDAR": "Kalender",
    "CALENDAR_NAME": "Name",
    "externalId": "Externe Id",
    "standard": "Standardkalender für Mitarbeiter",
    "CREATE": "Kalender anlegen",
    "CALENDAR_CREATED": "Kalendar {{calendar}} wurde angelegt",
    "CONFIRM_DELETE": "Wollen Sie den Kalendar {{name}} wirklich löschen?",
    "CALENDAR_DELETED": "Kalendar {{name}} wurde gelöscht",
    "CONFIRM_REMOVE_ENTRY": "Wollen Sie diesen Eintrag wirklich löschen?",
    "NEW_ENTRY": "Feiertag",
    "ENTRY_CREATED": "Feiertag wurde angelegt.",
    "entryCreationFailed": "Feiertag konnte nicht angelegt werden.",
    "calendarSaved": "Der Kalender wurde gespeichert.",
    "calendarSaveError": "Der Kalender konnte nicht gespeichert werden.",
    "entryDeleted": "Der Feiertag wurde gelöscht",
    "calendarCannotBeDeleted": "Kalender enthält Feiertage und kann nicht gelöscht werden"
  },
  "MANAGE_EMPLOYEES": {
    "NO_EMPLOYEES": "Dem Objekt sind noch keine Mitarbeiter zugeordnet.",
    "DELETE": "Mitarbeiter löschen",
    "CONFIRM_TEXT": "Wollen Sie den Mitarbeiter ### wirklich löschen?",
    "NO_EMPLOYEES_TO_ADD": "Es stehen keine Mitarbeiter für eine Zuordnung zur Verfügung.",
    "EMPLOYEES_ADDED": "Mitarbeiter hinzugefügt",
    "EMPLOYEE_DELETED": "Mitarbeiter gelöscht"
  },
  "MANAGE_LOGIN_CARDS": {
    "NUMBER": "Anzahl",
    "DESCRIPTION": "Blink ID Karten werden jedem Mitarbeiter automatisch zugewiesen. Mit der Blink ID Karte kann man sich in den Apps anmelden. <br/><b>Neu</b>: Blink ID Karten, die keinem Mitarbeiter zugewiesen sind <br/><b>Zugewiesen</b>: Zugewiesene Blink ID Karten",
    "CREATION_DATE": "Erstellungsdatum",
    "CREATE": "Blink ID",
    "SEND_CSV": "CSV",
    "SEND_PDF": "PDF",
    "ENTER_EMAIL": "Bitte geben Sie die E-Mail-Adresse an, an die die Liste / PDf versandt werden soll",
    "EMAIL_SENT": "Eine E-Mail mit den angeforderten Daten wurde an Sie gesandt",
    "CREATE_CARDS": {
      "TITLE": "Bitte geben Sie die Anzahl der zu erstellenden Karten ein",
      "CREATED": "Ihre Blink ID Karten wurden erstellt",
      "DESCRIPTION": "Geben Sie die Anzahl von Blink IDs an, die Sie erzeugen möchten. Sie können sich das Ergebnis sofort als CSV oder PDF exportieren lassen.<br/>Hinweis: Beim Anlegen von neuen Mitarbeitern werden automatisch Blink IDs erzeugt und zugewiesen. Eine manuelle Anlage von Blink IDs ist nur sinnvoll, wenn Sie die automatische Zuordnung nicht nutzen wollen."
    },
    "creationDate": "Erstelldatum",
    "numberCircle": "Erste Karte im Nummernkreis",
    "NEW": "Neu",
    "ASSIGNED": "Zugewiesen",
    "LOCKED": "Gesperrt",
    "EMPLOYEE": "Zugeord. Mitarbeiter",
    "sort": "Sortierung",
    "sorting": {
      "DateAsc": "Erstelldatum aufsteigend",
      "DateDesc": "Erstelldatum absteigend",
      "BlinkIdAsc": "Blink ID aufsteigend",
      "BlinkIdDesc": "Blink ID absteigend"
    }
  },
  "MANAGE_USER_GROUPS": {
    "ENTER_NAME": "Namen eingeben",
    "CREATE_GROUP": "Gruppe",
    "MEMBERS_OF_GROUP": "Mitarbeiter der Gruppe",
    "NO_MEMBERS": "Keine Mitarbeiter zugewiesen",
    "systemGroupsHint": "Diese Berechtigungsgruppen sind nur für Systemadministratoren. Wenn Sie Berechtigungen für Mitarbeiter anpassen wollen müssen Sie die Berechtigungen im Mandanten ändern."
  },
  "MONITOR": {
    "employeeMonitor": "Meine Mitarbeiter",
    "locationMonitor": "Meine Objekte",
    "worklogsAndAbsences": "Zeiten & Abwesenheiten",
    "forms": "Formulare",
    "orders": "Bestellungen",
    "ACTIONS_TITLE": "Zeit erfassen",
    "editPlanningEntry": "Planungseintrag bearbeiten",
    "planningDetails": "Planung Details",
    "LOAD_MORE": "nachladen",
    "showAll": "Alles anzeigen",
    "selectTeamleader": "Bitte wählen Sie einen Vorgesetzten aus",
    "LOCATIONS_NO_WORKLOGS": "Für folgende Objekte wurde keine Zeit erfasst:",
    "EMPLOYEES_NO_WORKLOGS": "Folgende Mitarbeiter haben keine Zeit erfasst:",
    "NO_LOCATIONS": "Für alle Objekte wurden die geplanten Zeiten erfasst.",
    "NO_EMPLOYEES": "Alle Mitarbeiter haben Zeiten erfasst.",
    "NO_WORKLOAD_TEXT": "Für {{employee}} ist an diesem Tag keine Wochenarbeitszeit hinterlegt. Bitte geben Sie eine Dauer an",
    "EXPLANATION_TEXT": "Wählen Sie für diesen Tag eine der folgenden Abwesenheiten aus.",
    "worklogFilterDescription": "Filter nach Status",
    "filterByName": "Suche nach Vor- und Nachname",
    "monitorActions": {
      "NO_NEED_OF_WORK": "{{employee}} muss an diesem Tag nicht arbeiten.",
      "WORKLOAD_TEXT": "{{employee}} muss an diesem Tag {{hours}} Stunden arbeiten.",
      "PLANNING_ENTRIES_TEXT": "{{hours}}h im Objekt {{location}}",
      "PLANNING_DURATION_TEXT": "{{employee}} muss an diesem Tag {{duration}} Stunden im Objekt {{location}} arbeiten.",
      "PLANNING_TIMEFRAME_TEXT": "{{employee}} muss an diesem Tag im Objekt {{location}} von {{start}} bis {{end}} arbeiten.",
      "PLANNING_SET_ABSENCE": "Abwesenheit festlegen",
      "CHOOSE_SUBSTITUTE": "Wollen Sie eine Vertretung festlegen?",
      "CHANGE_DATE": "Umplanen",
      "blinkPlanInfo": "Umplanen und Löschen muss über die Dienstplanung vorgenommen werden."
    },
    "filterLabels": {
      "red": "Probleme",
      "green": "Erf. Zeiten",
      "yellow": "Abwesend",
      "blue": "Einplanung",
      "grey": "Ohne Planung"
    },
    "EMPLOYEE": {
      "NO_WORKLOGS": "Keine Zeiten erfasst",
      "NEED_OF_WORK": "Arbeitet an diesem Tag",
      "NO_NEED_OF_WORK": "Muss an diesem Tag nicht arbeiten",
      "START_OF_WORK": "Arbeitet seit {{startOfWork}} Uhr",
      "WORKED_AT_LOCATION": "Hat {{workedDuration}}h im Objekt {{locationName}} gearbeitet",
      "PLANNED_DURATION": "(Soll: {{plannedDuration}}h)",
      "WORKED_AT_UNKNOWN_LOCATION": "Hat {{workedDuration}} gearbeitet (Objekt unbekannt)",
      "WORK_TIME_PROPOSED_END_AT": "Ende (Plan): {{endOfWork}} Uhr",
      "WORK_TIME_EXACT_STARTS_AT": "Beginn (Plan): {{startOfWork}} Uhr",
      "planned": "Geplant: "
    },
    "LOCATION": {
      "placeholder": "Suche nach Name",
      "NO_WORKLOGS": "Noch keine Zeiterfassung für heute.",
      "NO_NEED_OF_WORK": "Für heute ist keine Arbeit in diesem Objekt geplant.",
      "percentageMore": "Ist-Zeit überschreitet Soll-Zeit um",
      "percentageLess": "Ist-Zeit unterschreitet Soll-Zeit um",
      "sort": {
        "nameAsc": "Name (A..Z)",
        "nameDesc": "Name (Z..A)",
        "unfinishedPEAsc": "Anzahl nicht erledigte Planungen (1..9)",
        "unfinishedPEDesc": "Anzahl nicht erledigte Planungen (9..1)"
      }
    },
    "SETTINGS": {
      "TITLE": "Einstellungen",
      "showRedEntries": "Es sind Zeiten für den Tag eingeplant, aber es wurde nicht gearbeitet.",
      "showGreenEntries": "Es sind Arbeitszeiten für den Tag vorhanden.",
      "showNeutralEntries": "Es sind keine Zeiten für den Tag eingeplant.",
      "showYellowEntries": "Es sind Abwesenheiten für den Tag vorhanden."
    },
    "CALL": "Anrufen",
    "DETAIL": "Zeiten im Detail",
    "CREATE_WORKLOG": "Zeit nachtragen",
    "TO_PLANNING": "Zur Einplanung",
    "alwaysShowAlarms": "Zeiten mit Alarm immer anzeigen",
    "CREATE_WORKLOG_OR_ABSENCE": "Zeiten & Abwesenheiten erfassen"
  },
  "MONTHS": {
    "january": "Januar",
    "february": "Februar",
    "march": "März",
    "april": "April",
    "mai": "Mai",
    "june": "Juni",
    "july": "Juli",
    "august": "August",
    "september": "September",
    "october": "Oktober",
    "november": "November",
    "december": "Dezember"
  },
  "NEXT": "Weiter",
  "NO_CODE_PAGE": {
    "PAGE_TITLE": "Kein Code",
    "TEXT1": "Sie haben keinen Code bekommen?",
    "RESEND_CODE": "Code nochmal schicken",
    "TEXT2": "Falls sich Ihre im System hinterlegte E-Mail-Adresse oder Mobilnummer geändert hat, wenden Sie sich bitte an Ihren Administrator."
  },
  "OK": "Ok",
  "PERMISSIONS": {
    "CanEditCompanies": "Mandanten anlegen und bearbeiten",
    "CanEditCompaniesInside": "Mandanten-Einstellungen bearbeiten",
    "CanTrackTimeForEmployees": "Zeiten für andere Mitarbeiter erfassen",
    "CanEditLocations": "Alle Objekte anlegen und bearbeiten",
    "CanExportWorklogs": "Zeiten als Excel oder PDF exportieren",
    "CanExportWorklogsDescription": "Nur Zeiten von Mitarbeitern und Objekten, die man bearbeiten darf (siehe Rechte unter Mitarbeiter und Objekt), können exportiert (Excel, PDF) werden.",
    "CanEditLocationPhoneNumbers": "Telefonnummern anlegen und bearbeiten",
    "CanEditShiftPlannings": "Dienstplanung bearbeiten",
    "CanEditSystemSettings": "Systemeinstellungen",
    "CanEditSubEntireEmployees": "Eigene Mitarbeiter/Benutzer anlegen und bearbeiten",
    "CanEditSubEntireEmployeesDescription": "Benutzer dürfen alle Mitarbeiter bearbeiten, bei denen sie als Teamleiter hinterlegt sind. Benutzer dürfen alle Mitarbeiter eines Bereichs bearbeiten, wenn sie als Manager des Bereichs hinterlegt sind.",
    "CanRequestLocationPdfs": "Objekt-Codes herunterladen",
    "CanEditSubLocations": "Eigene Objekte anlegen und bearbeiten",
    "CanEditSubLocationsDescription": "Benutzer dürfen alle Objekte bearbeiten, bei denen sie als Objektleiter hinterlegt sind. Benutzer dürfen alle Objekte eines Bereichs bearbeiten, wenn sie als Manager des Bereichs hinterlegt sind.",
    "CanEditPlanningEntries": "Planungen für eigene Mitarbeiter anlegen und bearbeiten",
    "CanEditPlanningEntriesDescription": "Benutzer dürfen alle Planungen von Mitarbeitern anlegen und bearbeiten, bei denen sie als Teamleiter bzw. als Manager des Bereiches hinterlegt sind. Dies gilt für alle Planungen der Objekte die der Benutzer auch sehen darf.",
    "CanBookWorklogs": "Zeiten freigeben, buchen und ändern",
    "CanBookWorklogsDescription": "Benutzer dürfen alle Zeiten von Mitarbeitern bearbeiten, freigeben und buchen, bei denen sie als Teamleiter bzw. als Manager des Bereiches hinterlegt sind oder Recht zum Bearbeiten des Mitarbeiters haben (siehe Rechte unter Mitarbeiter). Dies gilt für alle Zeiten der Objekte die der Benutzer auch sehen darf.\nFreigegebene Arbeitszeiten können bearbeitet werden.",
    "CanAssignLoginCards": "Mitarbeitern Blink ID Karten zuweisen",
    "CanEditWorklogs": "Zeiten freigeben und ändern",
    "CanEditWorklogsDescription": "Benutzer dürfen alle Zeiten von Mitarbeitern bearbeiten und freigeben, bei denen sie als Teamleiter bzw. als Manager des Bereiches hinterlegt sind oder Recht zum Bearbeiten des Mitarbeiters haben (siehe Rechte unter Mitarbeiter). Dies gilt für alle Zeiten der Objekte die der Benutzer auch sehen darf.\nNach der Freigabe ist keine Bearbeitung mehr erlaubt.",
    "CanReadSubWorklogs": "Zeiten und Planungen von eigenen Mitarbeitern sehen",
    "CanReadSubWorklogsDescription": "Benutzer dürfen alle Zeiten und Planungen von Mitarbeitern sehen, bei denen sie als Teamleiter bzw. als Manager des Bereiches hinterlegt sind. Sichtbar sind alle Zeiten und Planungen der Objekte die der Benutzer auch sehen darf.",
    "CanSelectLocationAtTimeTracking": "Objektauswahl bei Zeiterfassung erlaubt (nur Blink me)",
    "CanSelectLocationAtTimeTrackingDescription": "Objektauswahl bei Zeiterfassung ist möglich. Es muss nicht zwingend ein Objekt-Code gescannt werden. Betrifft nur die Zeiterfassung über die Blink me. Bei der Zeiterfassung per Blink me im Browser ist diese Berechtigung Vorraussetzung.",
    "CanTrackTimeManually": "Eigene Zeit manuell erfassen (nur Blink me)",
    "CanTrackTimeManuallyDescription": "Mitarbeiter kann eigene Zeiten manuell nachträglich erfassen. Eine nachträgliche Bearbeitung ist jedoch nicht möglich.",
    "CanEditEntireEmployees": "Alle Mitarbeiter/Benutzer anlegen und bearbeiten",
    "CanEditEntireEmployeesDescription": "Benutzer dürfen alle Mitarbeiter des Mandanten bearbeiten.",
    "CanEditTerminals": "Terminals anlegen und bearbeiten",
    "CanUseExtendedAuthProcess": "Muss sichere Authentifizierung nutzen",
    "CanImpersonate": "Kann Identität anderer Mitarbeiter annehmen",
    "CanManageEmployees": "Alle Benutzertypen anlegen und bearbeiten",
    "CanManageLocations": "Alle Objekte anlegen und bearbeiten",
    "CanManageLocationsDescription": "Benutzer dürfen alle Objekte des Mandanten bearbeiten",
    "CanManageTime": "Alle Zeiten verwalten (für andere Mitarbeiter erfassen, buchen, exportieren), Einplanungen verwalten",
    "CanManageActive": "Blink Active verwalten",
    "CanManageInsight": "Blink Insight verwalten",
    "BlinkTime": "Blink Time",
    "Employees": "Mitarbeiter",
    "Locations": "Objekte",
    "System": "System",
    "NEW_PERMISSION_GROUP": "Neue Berechtigungsgruppe",
    "CanEditAreas": "Bereiche verwalten",
    "CanManageCheck": "Blink Check Vorlagen verwalten",
    "CanTrackTime": "Eigene Zeit erfassen",
    "CanSeeOwnPlannings": "Eigene Planung sehen",
    "CanSetWorklogStatusToExported": "Zeiten-Status auf exportiert setzen",
    "CanManageWorklogStatus": "Zeiten-Status beliebig ändern und zurücksetzen",
    "CanDeactivateEmployees": "Mitarbeiter deaktivieren",
    "CanDeactivateLocations": "Objekte deaktivieren",
    "Active": "Blink Active",
    "CreateTicket": "Ticket erstellen",
    "CreateTicketDescription": "Benutzer dürfen Tickets erstellen und eigene Kommentare und Anhänge erstellen und Löschen.",
    "CanManageAssignedTasks": "Eigene Tickets bearbeiten",
    "CanManageAllTasksOfCompany": "Alle Tickets bearbeiten",
    "CanChangeTaskStatus": "Ticket-Status ändern",
    "CanChangeTaskAssignee": "Ticket-Bearbeiter ändern",
    "CanUseSpecialAbsenceTypes": "Geschützte Abwesenheiten anlegen und bearbeiten",
    "CanUseSpecialAbsenceTypesDescription": "Es dürfen auch Abwesenheiten angelegt und bearbeitet werden die als geschützte Abwesenheitstypen gekennzeichnet sind.",
    "CanReadOwnLocations": "Eigene Objekte lesen",
    "CanReadOwnLocationsDescription": "Benutzer dürfen alle Objekte sehen, bei denen sie als Objektleiter hinterlegt bzw. sie als Manager des Bereiches hinterlegt sind.",
    "CanReadCompanyLocations": "Alle Objekte lesen",
    "CanReadCompanyLocationsDescription": "Benutzer dürfen alle Objekte des Mandanten sehen.",
    "CanReadManagerLocations": "Objekte der Objektleiter lesen",
    "CanReadManagerLocationsDescription": "Benutzer dürfen alle Objekte sehen, die auch ihre Teamleiter sehen dürfen.",
    "CanReadAreaLocations": "Objekte der Bereiche lesen",
    "CanReadAreaLocationsDescription": "Benutzer dürfen alle Objekte sehen, die den Bereichen zugeordnet sind, zu denen sie selbst gehören.",
    "CanTrackTimeWithoutLocationQrCode": "Zeit erfassen ohne QR Code",
    "CanTrackTimeWithoutLocationQrCodeDescription": "Mitarbeiter hat die Option “Kein Code vorhanden” beim Starten/Stoppen zur Auswahl. In diesem Fall erfolgt der Star/Stop ohne die Angaben eines Objektes.",
    "ORDER": {
      "tab": "Order",
      "forAllSuppliers": "für alle Lieferanten",
      "forSelectedSuppliers": "für ausgewählte Lieferanten",
      "forAllAssortments": "für alle Sortimente",
      "forSelectedAssortments": "für ausgewählte Sortimente",
      "baseData": "Stammdaten",
      "baseDataAll": "Stammdaten, Einstellungen und Lieferanten bearbeiten",
      "baseDataAllDescription": "Aktivieren, damit ein Benutzer Einstellungen in Blink Order, wie Stammdaten, Steuersätze, etc. bearbeiten kann, Lieferanten erstellen/bearbeiten und Dashboards verwalten kann.",
      "can_manage_products": "Produkte erstellen/bearbeiten",
      "can_manage_productsDescription": "Aktivieren, damit ein Benutzer Produkte erstellen und vorhandene Produkte bearbeiten sowie Preise ändern kann.",
      "can_research_customerproducts": "Im Artikelstamm recherchieren",
      "can_research_customerproductsDescription": "Aktivieren, damit ein Benutzer Produkte im Artikelstamm recherchieren kann.",
      "can_manage_assortments": "Sortimente ändern",
      "can_manage_assortmentsDescription": "Aktivieren, damit ein Benutzer Produkt-Sortimente sehen, bearbeiten und neue Produkt-Sortimente anlegen kann.",
      "can_view_price": "Preise lesen",
      "can_view_priceDescription": "Aktivieren, damit ein Benutzer Preise der Produkte sehen kann. Ist diese Option nicht aktiviert, werden Preise im Artikelstamm und Bestellprozess ausgeblendet.",
      "organisation": "Organisation",
      "can_manage_budgets": "Budgets ändern",
      "can_manage_budgetsDescription": "Aktivieren, damit ein Benutzer Budgets der Objekte verwalten kann.",
      "can_carry_budget_over_to_next_period": "Budgets in die nächste Periode übernehmen",
      "can_carry_budget_over_to_next_periodDescription": "Aktivieren, damit ein Benutzer nicht verbrauchte Budgets von einer Periode in die nächste übernehmen kann.",
      "can_manage_deliveryaddresses": "Lieferadresse ändern",
      "can_manage_deliveryaddressesDescription": "Aktivieren, damit ein Benutzer die Lieferadresse eines Objekts bearbeiten oder ein neues Objekt anlegen kann.",
      "can_manage_billingaddressAll": "Rechnungsadresse auswählen",
      "can_manage_billingaddressAllDescription": "Aktivieren, damit ein Benutzer die Standard-Rechnungsadresse für ein Objekt festlegen und im Bestellprozess eine abweichende Rechnungsadresse auswählen kann.",
      "can_subscripe_notifications": "Benachrichtigung abonnieren",
      "can_subscripe_notificationsDescription": "Aktivieren, damit ein Benutzer sich für Benachrichtigungen bei bestimmten Ereignissen (z.B. bei einer Budgetüberschreitung durch eine Bestellung) anmelden kann.",
      "can_manage_notifications": "Benachrichtigung ändern",
      "can_manage_notificationsDescription": "Aktivieren, damit ein Benutzer Benachrichtigungen bei bestimmten Ereignissen (z.B. bei einer Budgetüberschreitung durch eine Bestellung) ändern kann.",
      "orderingProcess": "Bestellprozess",
      "can_create_order": "Bestellungen erstellen",
      "can_create_orderDescription": "Aktivieren, damit ein Benutzer eine neue Bestellung anlegen kann.",
      "can_only_order_from_template": "Nur Bestellvorlagen für Bestellung verwendbar",
      "can_only_order_from_templateDescription": "Aktivieren, damit ein Benutzer nur Bestellungen auf Basis von Bestellvorlagen erstellen kann. Der Bestellung können keine weiteren Produkte hinzugefügt werden.",
      "can_manage_ordertemplates": "Bestellvorlagen ändern",
      "can_manage_ordertemplatesDescription": "Aktivieren, damit ein Benutzer Bestellvorlagen erstellen, löschen und bearbeiten kann.",
      "can_approve_order": "Bestellungen freigeben",
      "can_approve_orderDescription": "Aktivieren, damit ein Benutzer Bestellungen freigeben kann, so dass diese an den Lieferanten weitergereicht werden.",
      "can_approve_and_edit_order": "Bestellungen freigeben und ändern",
      "can_approve_and_edit_orderDescription": "Aktivieren, damit ein Benutzer Bestellungen vor der Freigabe bearbeiten kann.",
      "can_exceed_own_budget": "Budget überschreiten",
      "can_exceed_own_budgetDescription": "Aktivieren, damit ein Benutzer Bestellungen anlegen und deren Bestellwert das eigene Budget überschreiten kann.",
      "can_create_budgetneutral_order": "Budgetneutrale Bestellungen erstellen",
      "can_create_budgetneutral_orderDescription": "Aktivieren, damit ein Benutzer Bestellungen anlegen kann und der Bestellwert nicht auf das Budget des Objekts gebucht wird.",
      "can_create_textonly_order_lineitem": "Freipositionen erstellen",
      "can_create_textonly_order_lineitemDescription": "Aktivieren, damit ein Benutzer Freipositionen zu einer Bestellung hinzufügen kann.",
      "can_set_deliverydate": "Lieferdatum setzen",
      "can_set_deliverydateDescription": "Aktivieren, damit ein Benutzer ein Lieferdatum zu einer Bestellung angeben kann.",
      "approvalProcess": "Freigabeprozess",
      "approval_never": "Freigabe nie erforderlich",
      "approval_neverDescription": "Aktivieren, damit ein Benutzer Bestellungen ohne vorherige Freigabe an den Lieferanten übermittelt kann.",
      "approval_always": "Freigabe immer erforderlich",
      "approval_alwaysDescription": "Aktivieren, damit die Bestellungen des Benutzers immer freigegeben werden müssen.",
      "approval_budget_exceeded": "Freigabe erforderlich bei Budgetüberschreitung",
      "approval_budget_exceededDescription": "Aktivieren, damit die Bestellungen des Benutzers freigegeben werden müssen, wenn der Bestellwert das Budget überschreitet.",
      "approval_budgetneutral": "Freigabe erforderlich bei\nbudgetneutraler Bestellung",
      "approval_budgetneutralDescription": "Aktivieren, damit die Bestellungen des Benutzers freigegeben werden müssen, wenn diese budgetneutral sind.",
      "approval_costcenter_approval": "Freigabe erforderlich bei\nFreigabepflichtiger Kostenstelle",
      "approval_costcenter_approvalDescription": "Aktivieren, damit die Bestellungen des Benutzers freigegeben werden müssen, wenn die Kostenstelle freigabepflichtig ist.",
      "approval_product_approval": "Freigabe erforderlich bei\nfreigabepflichtigem Produkt/Sortiment",
      "approval_product_approvalDescription": "Aktivieren, damit die Bestellungen des Benutzers freigegeben werden müssen, wenn Produkte/Sortimente freigabepflichtig sind."
    }
  },
  "PHONE_CODES": {
    "BE": "Belgien (+32)",
    "BG": "Bulgarien (+359)",
    "DK": "Dänemark (+45)",
    "DE": "Deutschland (+49)",
    "EE": "Estland (+372)",
    "FI": "Finnland (+358)",
    "FR": "Frankreich (+590)",
    "GR": "Griechenland (+30)",
    "GB": "Grossbritannien (+44)",
    "IE": "Irland (+353)",
    "IT": "Italien (+39)",
    "LV": "Lettland (+371)",
    "LT": "Litauen (+7012)",
    "LU": "Luxemburg (+352)",
    "MT": "Malta (+356)",
    "NL": "Niederlande (+31)",
    "AT": "Österreich (+43)",
    "PL": "Polen (+48)",
    "PT": "Portugal (+351)",
    "RO": "Rumänien (+40)",
    "SE": "Schweden (+46)",
    "CH": "Schweiz (+41)",
    "SK": "Slowakei (+421)",
    "SI": "Slowenien (+386)",
    "ES": "Spanien (+34)",
    "CZ": "Tschechische Republik (+420)",
    "TR": "Türkei (+90)",
    "HU": "Ungarn (+36)",
    "CY": "Zypern (+357)"
  },
  "COUNTRIES": {
    "DE": "Deutschland",
    "AT": "Österreich",
    "CH": "Schweiz",
    "BE": "Belgien",
    "BG": "Bulgarien",
    "DK": "Dänemark",
    "EE": "Estland",
    "FI": "Finnland",
    "FR": "Frankreich",
    "GR": "Griechenland",
    "GB": "Grossbritannien",
    "IE": "Irland",
    "IT": "Italien",
    "LV": "Lettland",
    "LT": "Litauen",
    "LU": "Luxemburg",
    "MT": "Malta",
    "NL": "Niederlande",
    "PL": "Polen",
    "PT": "Portugal",
    "RO": "Rumänien",
    "SE": "Schweden",
    "SK": "Slowakei",
    "SI": "Slowenien",
    "ES": "Spanien",
    "CZ": "Tschechische Republik",
    "TR": "Türkei",
    "HU": "Ungarn",
    "CY": "Zypern"
  },
  "PLEASE_CHOOSE": "Bitte auswählen",
  "REGISTER_MANUALLY_PAGE": {
    "TEXT": "Bitte geben Sie Ihre Blink ID und Ihren Code ein. Sie finden alle Informationen auf Ihrer Registrierungskarte.",
    "textCredentials": "Benutzername und Passwort muss Ihnen Ihr Systemadministrator zur Verfügung stellen.",
    "BLINK_ID_INVALID": "Blink-ID fehlerhaft",
    "loginCardNotFound": "Die Blink ID ist entweder bereits vergeben oder nicht vorhanden",
    "WRONG_CREDENTIALS": "Die eingegebenen Daten sind nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.",
    "loginViaBlinkId": "Anmeldung mit BlinkID",
    "loginViaCredentials": "Anmeldung mit Zugangsdaten",
    "CODE": "Code",
    "codePlaceholder": "ihrefirma-1234-1234-1234-1234-1234",
    "company": "System",
    "username": "Benutzername / E-Mail",
    "password": "Kennwort",
    "FORGOT_PASSWORD": "Ich habe mein Passwort vergessen"
  },
  "REGISTER_SET_AUTH_MODE_PAGE": {
    "PAGE_TITLE": "Identifikation",
    "TEXT_1": "Damit wir Sie eindeutig identifizieren können, schicken wir Ihnen einen Einmal-Code per SMS oder E-Mail.",
    "TEXT_2": "Bitte geben Sie dafür Ihre Mobilnummer oder E-Mail-Adresse an."
  },
  "REGISTRATION": {
    "WELCOME": "Willkommen!",
    "chooseMethod": "Mit welcher Methode möchten Sie sich anmelden?",
    "withBlinkId": "BLINK ID Karte",
    "withCredentials": "Benutzername / Passwort",
    "REGISTER": "Registrieren",
    "ALREADY_REGISTERED": "Ich habe einen Blink Account.",
    "INVALID_CARD": "Karte nicht gültig.",
    "noBlinkId": "Der gescannte QR-Code ist keine gültige Blink-ID",
    "ERROR": "Die Registrierung konnte nicht abgeschlossen werden. Bitte geben Sie eine andere E-Mail-Adresse oder Mobilnummer an.",
    "NO_INTERNET_EXPLORER": "Der Internet Explorer wird von Blink leider nicht unterstützt. Bitte verwenden Sie Microsoft Edge, Firefox oder Chrome. Vielen Dank für Ihr Verständnis.",
    "loginNow": "Jetzt anmelden",
    "help": "Hilfe bei der Anmeldung",
    "helpText": "Der Blink Manager ermöglicht Ihnen die Verwaltung von Zeiten, Mitarbeitern und die Systemkonfiguration. Der Blink Manager ist nur für Vorgesetzte, Objektleiter, Verwaltung und Administration! Für die reine Zeiterfassung nutzen Sie die App \"Blink Time\"!<br/><br/>Es gibt zwei Methoden, mit denen Sie sich anmelden können:<br/><br/><b>Anmeldung mit Blink ID Karte</b><br/><br/>Wenn Sie eine Blink ID Karte haben, dann wählen Sie diese Methode. Bei der ersten Registrierung werden Sie nach einer E-Mail Adresse oder Handynummer gefragt. Diese benötigen wir, um Ihnen ein Einmalpasswort für die Anmeldung zu schicken. Sie brauchen sich das Einmalpasswort nicht merken. Wenn Sie sich erneut anmelden, schicken wir Ihnen ein neues Einmalpasswort.<br/><br/><b>Anmeldung mit Benutzername & Passwort</b><br/><br/>Sie erhalten Benutzername und Passwort nur auf Nachfrage von Ihrem Systemadministrator. Diese Methode steht nur Benutzern mit administrativen Rechten zur Verfügung.<br/><br/><b>Support</b><br/><br/>Falls Sie Probleme bei der Anmeldung haben schreiben Sie eine E-Mail an support@blink.de oder rufen Sie an unter 0911 240 330 22.",
    "userOffline": "Bitte stellen Sie eine Internetverbindung her um den Blink Manager nutzen zu können.",
    "login": "Jetzt anmelden",
    "codeError": "Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut."
  },
  "REGISTRATION_SUCCESS": {
    "CONGRATULATIONS": "Glückwunsch!",
    "YOU_DID_IT": "Sie haben es geschafft.",
    "SIGNED_IN": "Angemeldet",
    "SIGNED_IN_TEXT": "Sie haben sich erfolgreich angemeldet und können die App jetzt benutzen.",
    "START": "Start"
  },
  "SCAN": {
    "CHOOSE": "Neue Blink-ID zuweisen",
    "TEXT": "Scannen Sie den QR-Code der Anmeldekarte.",
    "SCAN_NOW": "Jetzt scannen",
    "ENTER_MANUALLY": "Manuell eingeben"
  },
  "SETTINGS": {
    "TERMINALS": {
      "CREATE": "Terminal anlegen",
      "USER_NAME": "Terminal Name",
      "CREATED": "Terminal wurde angelegt"
    },
    "SYSADMINS": {
      "CREATE": "Systemadministrator",
      "firstName": "Vorname",
      "lastName": "Nachname",
      "email": "Sysadmin E-Mail-Adresse",
      "CREATED": "Sysadmin wurde angelegt",
      "choosePermissionGroup": "Bitte wählen Sie eine Berechtigungsgruppe aus."
    }
  },
  "SETTINGS_PAGE": {
    "dataCannotBeSaved": "Die Einstellungen können nicht gespeichert werden. Bitte kontrollieren Sie die Eingabe.",
    "SYSTEM": "System",
    "LANGUAGE_SELECTION": "Sprachauswahl",
    "CURRENT_SETTINGS": "Aktuelle Einstellungen",
    "USER": "Benutzer",
    "APP_VERSION": "Version (App)",
    "API_VERSION": "Version (Api)",
    "DEVICE_INFO": "Geräteversion",
    "BUILD_VERSION": "Version (Build)",
    "APPROVAL": {
      "TEXT": "Hier legen Sie fest unter welchen Bedingungen der Mitarbeiter in der Freigabeliste rot angezeigt wird.",
      "CRITERIA": "Freigabekriterien",
      "ONLY_FOR_CURRENT_USER": "Diese Einstellungen gelten nur für den aktuell angemeldeten Benutzer!",
      "STANDARD_RELEASE_PERIOD": "Standard Freigabezeitraum",
      "LAST_WEEK": "Letzte Woche",
      "LAST_MONTH": "Letzter Monat"
    },
    "GOODWILL": {
      "TITLE": "Abweichungen / Kulanz",
      "ABOVE_TARGET": "über Soll (min)",
      "UNDER_TARGET": "unter Soll (min)"
    },
    "activeUsers": "Aktive User",
    "PERMISSION_GROUP": {
      "CREATED": "Berechtigungsgruppe wurde erstellt."
    },
    "dateSettings": {
      "standard": "Datumbereich Zeiten",
      "standardDescription": "Mit dieser Einstellung legen Sie fest, welcher Zeitraum automatisch in Listen vorausgewählt werden soll",
      "planning": "Datumsbereich Planung",
      "timeFrameSaved": "Ihre Einstellungen zur Standard-Datumsauswahl wurden gespeichert."
    },
    "language": "Sprache",
    "receiveNotifications": "Push Benachrichtigungen erhalten",
    "profiles": {
      "loginProfiles": "Login Profile",
      "add": "Login Profil",
      "logout": "Wenn Sie sich abmelden, werden alle lokalen Daten und Profile gelöscht! Wollen Sie sich abmelden?",
      "login": "Anmelden"
    },
    "profilePicture": {
      "title": "Profilbild",
      "change": "Profilbild ändern",
      "modifyDialog": "Ausschnitt wählen"
    },
    "notificationDescription": "Für den Test ist Mitarbeiter-Berechtigung “Mandanten-Einstellung bearbeiten” oder Sysadmin-Berechtigung “Mandanten anlegen/bearbeiten” notwendig."
  },
  "SET_CUSTOM_TIME_FRAME_PAGE": {
    "PAGE_TITLE": "Zeitraum wählen"
  },
  "START": "Start",
  "STOP": "Stop",
  "SUPPORT": {
    "USER_ERROR": "Es gibt ein Problem mit dem aktuellen Benutzer.",
    "LOAD_SUPPORT_PAGE": "Zurück zur Benutzerauswahl",
    "LEAVE_SUPPORT": "Möchten Sie den Impersonate Mode jetzt verlassen?"
  },
  "TIME_FRAMES": {
    "custom": "Benutzerdefiniert",
    "currentWeek": "Aktuelle Woche",
    "lastWeek": "Letzte Woche",
    "last7Days": "Letzten 7 Tage",
    "last30Days": "Letzten 30 Tage",
    "currentMonth": "Aktueller Monat",
    "lastMonth": "Letzter Monat",
    "currentYear": "Aktuelles Jahr",
    "lastYear": "Letztes Jahr",
    "nextYear": "Nächstes Jahr",
    "today": "Heute",
    "yesterday": "Gestern"
  },
  "TIME_FRAME_PICKER": {
    "TITLE": "Bitte Zeitraum auswählen"
  },
  "TIME_SHEET_PAGE": {
    "PAGE_TITLE": "Arbeitszeitliste",
    "NO_ENTRIES": "Keine Einträge für den ausgewählten Zeitraum vorhanden"
  },
  "USER_TYPES": {
    "Employee": "Mitarbeiter",
    "Assignee": "Bearbeiter",
    "Reporter": "Ersteller",
    "Teamleader": "Vorgesetzter",
    "AreaManager": "Manager",
    "Manager": "Objektleiter",
    "Administration": "Administrator",
    "Systemadmin": "Systemadmin",
    "Terminal": "Terminal"
  },
  "WORKING_DAYS": "Arbeitstage",
  "WORKLOG": {
    "invalidFutureDate": "Arbeitszeiten können nicht für die Zukunft angelegt werden.",
    "ignoreAlerts": {
      "confirmTitle": "Alarme ignorieren",
      "confirmText": "Wollen Sie die Alarme wirklich ignorieren? Diese Aktion kann nicht rückgängig gemacht werden.",
      "confirmButton": "Alarme ignorieren",
      "cancelButton": "Abbrechen",
      "success": "Die Alarme wurden erfolgreich ignoriert.",
      "failure": "Ein Fehler ist aufgetreten. Die Alarme konnten nicht ignoriert werden."
    },
    "createAbscence": {
      "willBeDeleted": "wird gelöscht",
      "willBeReplaced": "{{type}} ersetzt {{worklogType}}. {{worklogType}} wird automatisch gelöscht.",
      "pageTitle": "Vorschau zur Abwesenheitserfassung",
      "showPreview": "Zur Vorschau",
      "preview": "Vorschau",
      "createNew": "Neu Anlegen",
      "existing": "Bereits vorhanden",
      "worklogExists": "Arbeitszeit vorhanden! Bitte prüfen, ob Sie hier eine Abwesenheit anlegen wollen.",
      "create": "{{type}} anlegen",
      "description": "An diesen Tagen wird {{type}} angelegt. Sie können einzelne Elemente löschen oder Zusätzliche anlegen.",
      "createNow": "Jetzt anlegen",
      "createSuccess": "Die Abwesenheiten wurden erfolgreich angelegt.",
      "createFailure": "Ein Fehler beim Anlegen der Abwesenheiten ist aufgetreten. Bitte versuchen es erneut.",
      "error": "Ist (h) einer Abwesenheit muss kleiner als 18,98h sein.",
      "modelNotCool": "Urlaubsantrag ist nicht vollständig. Mitarbeiter, Start- und Ende-Datum sind notwendig.",
      "vacationWillBeDeleted": "{{existingType}} wird automatisch gelöscht, wenn {{type}} angeleget wird.",
      "keepAbsence": "Nicht löschen",
      "notCountedForSubstitude": "Vertretungsstunden werden bei der Ist-Berechnung der Abwesenheit nicht berücksichtigt."
    },
    "deleted": "Gelöscht",
    "commentVisibleForClient": "Dieser Kommentar ist für den Mitarbeiter sichtbar!",
    "commentNotVisibleForClient": "Dieser Kommentar ist nicht für den Mitarbeiter sichtbar!",
    "deleteWorklog": "Arbeitszeit löschen",
    "deleteAbsence": "Abwesenheit löschen",
    "deleteAbsences": "Abwesenheiten löschen",
    "deleteConfirm": "Wollen Sie diese Einträge wirklich löschen?",
    "deleteConfirmSingle": "Wollen Sie diesen Eintrag wirklich löschen?",
    "TIME_ENTRIES_SAVED": "Änderungen an Zeiteinträgen wurden gespeichert",
    "WORKLOG": "Arbeitszeit",
    "ABSENCE": "Abwesenheit",
    "ABSENCES": "Abwesenheiten",
    "EMPLOYEE_TIMESHEET": "Zeit pro Mitarbeiter",
    "LOCATION_TIMESHEET": "Zeit pro Objekt",
    "WORK_TIME_IN_HOURS": "Arbeitszeit (in Stunden)",
    "WORK_TIMES": "Arbeitszeiten",
    "TIME_RANGE": "Zeitraum",
    "NO_ENTRIES": "Keine Einträge vorhanden",
    "ACTUAL_TIME": "Ist",
    "TARGET_TIME": "Soll",
    "budget": "KV",
    "targetPlannedTime": "Soll",
    "plannedWorkTime": "Geplante Arbeitszeit",
    "specifiedTime": "Kontrollvorgaben",
    "WORK_TIME": "WAZ",
    "breakDuration": "Pause",
    "reduceDuration": "Pauschale",
    "nightSurcharges": "Nachtzuschläge",
    "statusNewConfirm": "Wenn Sie den Status zurücksetzen kann es passieren, dass diese Arbeitszeit erneut in Ihr ERP System exportiert wird. Möchten Sie fortfahren?",
    "STATUS_TEXT": "Status",
    "STATUS": {
      "New": "wartet auf Freigabe",
      "Approved": "Freigegeben",
      "Billed": "Gebucht",
      "Exported": "Exportiert"
    },
    "EDIT": {
      "ignoreAlertsOnSave": "Nach dem Speichern Alarme auf ignoriert setzen",
      "globalSection": "Allgemein",
      "deleteTimeEntry": "Zeiteintrag ignorieren",
      "deleteTimeEntryConfirm": "Wollen Sie diese Arbeitszeit wirklich ignorieren?",
      "timeEntryDeleted": "Arbeitszeit wird nun nicht mehr berücksichtigt.",
      "activateTimeEntry": "Zeiteintrag berücksichtigen",
      "timeEntryActivated": "Arbeitszeit wurde aktiviert.",
      "createTimeEntry": "Arbeitszeit hinzufügen"
    },
    "STATUS_CHANGES": {
      "CHANGE": "Manuelle Änderungen",
      "LOCATION": "Objekt von {{before}} auf {{after}} geändert",
      "WORKED_DURATION": "Ist-Zeit von {{before}} auf {{after}} geändert",
      "BREAK_DURATION": "Pause von {{before}} auf {{after}} geändert",
      "REDUCE_DURATION": "Pauschal von {{before}} auf {{after}} geändert",
      "PLANNED_DURATION": "Soll-Zeit von {{before}} auf {{after}} geändert",
      "COST_CENTER_NUMBER": "Kostenstelle von {{before}} auf {{after}} geändert"
    },
    "OBJECT": "Objekt",
    "EMPLOYEE": "Mitarbeiter",
    "CHOOSE_LOCATION": "Bitte wählen Sie ein Objekt. Das Objekt des Arbeitszeiteintrages wird dann aktualisiert.",
    "CHANGE_LOCATION": "Objekt ändern",
    "CHANGE_WORKLOG": "Arbeitszeit bearbeiten",
    "CHANGE_PLANNED_DURATION": "Soll-Zeit ändern",
    "CHANGE_COST_CENTER": "Kostenstelle ändern",
    "WORKLOG_UPDATED": "Die Änderungen wurde aktualisiert",
    "PLANNED_DURATION_UPDATED": "Soll-Zeit wurde aktualisiert",
    "ENTER_WORK_DURATION": "Geben Sie die korrigierte Zeit in Stunden ein",
    "LOCATION_UPDATED": "Objekt wurde aktualisiert",
    "CREATE_WORKLOG": "Manuell Zeit/Abwesenheit erfassen",
    "WORKLOG_CREATED": "Arbeitszeit/Abwesenheit wurde erfasst",
    "APPROVAL": {
      "errors": "Fehler",
      "warnings": "Warnungen",
      "allEmployees": "Alle Mitarbeiter",
      "allTeamleaders": "Alle Vorgesetzten",
      "allLocations": "Alle Objekte",
      "allWorklogStates": "Alle Zeiten",
      "employees": "Mitarbeiter",
      "worklogs": "Arbeitszeiten",
      "exportWorklogs": "Zeiten exportieren",
      "exportMarkAsExported": "Zeiten nach dem Excelexport auf exportiert setzen",
      "markAsExported": "Auf Exportiert setzen",
      "MARK_EXPORTED_CONFIRM": "Wollen Sie für {{numSelection}} Mitarbeiter {{numWorklogs}} Einträge auf exportiert setzen?",
      "MARKED_AS_EXPORTED": "Die Zeiten wurden als Exportiert markiert",
      "SHOW_ONLY_EMPLOYEES_WITHOUT_EMPNO": "Nur Mitarbeiter ohne Personalnummer anzeigen",
      "SHOW_ONLY_EMPLOYEES_WITH_ALARMS": "Nur Alarme anzeigen",
      "APPROVE": "Freigeben",
      "APPROVE_WORKLOGS": "Zeiten freigeben ({{ number }})",
      "BOOK_WORKLOGS": "Zeiten buchen ({{ number }})",
      "WORKLOGS_BOOKED": "Zeiten wurden gebucht",
      "WORKLOGS_APPROVED": "Zeiten wurden freigegeben",
      "NO_WORKLOGS_TO_APPROVE": "Keine Arbeitszeiten zur Freigabe verfügbar",
      "SELECT_ALL": "Alle auswählen",
      "SELECTION_EMPTY": "Bitte wählen Sie mindestens einen Datensatz aus",
      "APPROVE_CONFIRM_TEXT": "Wollen Sie für {{numSelection}} Mitarbeiter {{numWorklogs}} Einträge freigeben?",
      "BOOK_CONFIRM_TEXT": "Wollen Sie für {{numSelection}} Mitarbeiter {{numWorklogs}} Einträge buchen?",
      "NEW": "Die ausgewählten Zeiten wurden zurückgesetzt auf \"wartet auf Freigabe\".",
      "NEW_FAILED": "Die Zeiten konnten nicht auf \"wartet auf Freigabe\" geändert werden.",
      "APPROVED": "Die ausgewählten Zeiten wurden freigegeben.",
      "BILLED": "Die Zeiten wurden gebucht.",
      "BOOKED": "Die Zeiten wurden gebucht.",
      "APPROVAL_FAILED": "Die Zeiten konnten nicht freigegeben werden.",
      "BOOK_FAILED": "Die Zeiten konnten nicht gebucht werden.",
      "EXPORT_FAILED": "Die Zeiten konnten nicht exportiert werden.",
      "EXPORTED": "Die Zeiten wurden als Exportiert markiert.",
      "EXPORTED_WITH_MAIL": "Die Zeiten wurden exportiert. Eine E-Mail wurde an: {{email}} geschickt",
      "FILTER": "Erweiterte Suche",
      "CLOSE_FILTER": "Erweiterte Suche schließen",
      "all_clients": "Alle Mandanten",
      "noWorklogs": "Keine Zeiten vorhanden",
      "worklogStatusNew": "Bitte freigeben ({{count}} Zeiten)",
      "worklogStatusApproved": "Bereit zur Buchung ({{count}} Zeiten)",
      "worklogStatusBooked": "Bereit zum Export ({{count}} Zeiten)",
      "worklogStatusExported": "Exportiert ({{count}} Zeiten)",
      "allWorklogsApproved": "Alle Zeiten freigegeben!",
      "allWorklogsBooked": "Alle Zeiten gebucht!",
      "allWorklogsExported": "Alle Zeiten exportiert!",
      "crucialFilterSet": "Die angezeigten Ist-Zeiten entsprechen dem eingestellten Filter.",
      "showDetails": "Alle Details anzeigen",
      "duplicateAbscenceNote": "Für diesen Tag gibt es mehrere Abwesenheiten!",
      "WORKLOGS_SET_NEW": "Zeiten wurden zurückgesetzt auf \"wartet auf Freigabe\""
    },
    "approvalDetail": {
      "pageTitle": "Zeiten nach Objekt",
      "attendanceTime": "Ist-Zeit",
      "timesInDetail": "Zeiten im Detail",
      "timesPerCostCenter": "Zeiten pro Kostenstelle",
      "showPlannings": "Eingeplante Zeiten anzeigen"
    },
    "CREATE": {
      "CREATE_WORKLOG": "Zeit erfassen",
      "CREATE_ABSENCE": "Zeit erfassen",
      "TYPE_OF_ABSENCE": "Art der Abwesenheit",
      "MORE_TYPE_OF_ABSENCES": "Weitere Arten der Abwesenheit",
      "ADOPT": "Übernehmen",
      "reason": "Begründung",
      "choose_reason": "Bitte Begründung auswählen",
      "noWorkload": "Abwesenheiten können nicht angelegt werden, weil der ausgewählte Mitarbeiter keine Wochenarbeitszeiten hinterlegt hat.",
      "invalidDates": "Bis-Datum darf nicht vor Vor-Datum liegen!",
      "chooseANonFutureDate": "Bitte wählen Sie ein Datum aus, dass nicht in der Zukunft liegt."
    },
    "SHOW_EMPLOYEE_WORKLOGS": "Alle Zeiten des Mitarbeiters anzeigen",
    "SHOW_LOCATION_WORKLOGS": "Alle Zeiten des Objekts anzeigen",
    "showMap": "Auf Karte anzeigen",
    "PROBLEMATIC_WORKLOGS_ONLY": "Nur Mitarbeiter mit abweichenden Sollzeiten",
    "alerts": "Alarme",
    "ALERT": {
      "1": "Erfasste Zeit war nicht eingeplant",
      "2": "Erfasste Zeit nicht im eingeplanten Zeitraum",
      "3": "Erfasste Zeiten passen nicht zur Wochenarbeitszeit",
      "4": "Objekt nicht gefunden aufgrund unbekannter Tel.nr.",
      "5": "Kein Objekt angegeben",
      "6": "Nicht berücksichtigter Zeiteintrag",
      "7": "Zeit wurde automatisch gestoppt",
      "8": "Dauer überschreitet maximale Arbeitszeit",
      "9": "Das Objekt wurde bei der Zeiterfassung nur teilweise bestätigt",
      "10": "Bei der Zeiterfassung wurden unterschiedliche Objekte angegeben",
      "11": "Zulässiger Wert an maximalen Arbeitsstunden im Monat wurde überschritten",
      "12": "GPS: Start und Stop liegen zu weit auseinander",
      "13": "GPS: Start oder Stop passen nicht zum Objekt",
      "14": "GPS: Fehlende Koordinaten bei Start/Stop",
      "15": "Erfasste Zeit beinhaltet überschneidende Zeiteinträge"
    },
    "TIME_ENTRY_ALERT": {
      "AUTO_STOP": "Automatisch gestoppt. Mitarbeiter hat nicht ausgestempelt.",
      "AUTO_STOP_BY_START_EVENT": "Automatisch gestoppt. Mitarbeiter hat die Zeiterfassung doppelt gestartet.",
      "START_OTHER_DAY": "Arbeitszeit wurde gesplittet. Zeiterfassung wurde am vorigen Tag gestartet.",
      "END_OTHER_DAY": "Arbeitszeit wurde gesplittet. Zeiterfassung endet am nächsten Tag."
    },
    "worklogActions": {
      "new": "Wartet auf Freigabe",
      "approve": "Freigeben",
      "markAsBooked": "Buchen",
      "export": "Exportiert",
      "download": "Herunterladen",
      "createWorklog": "Zeit / Abwesenheit erfassen"
    },
    "errors": {
      "endsNextDay": "Die Arbeitszeit darf nicht am nächsten Tag enden. Legen Sie bitte eine zusätzliche Arbeitszeit für den nächsten Tag an."
    },
    "approveWorklogDeletion": "Soll der Anhang \"{{attachmentName}}\" gelöscht werden?",
    "splitting": {
      "title": "Gebuchte Zeit splitten",
      "chooseHowToSplit": "Wählen Sie wie Sie splitten möchten:",
      "chooseHowToSplitInfo": "Mit der Splitten Funktion können Sie die Arbeitszeit auf mehrere neue Arbeitszeiten mit unterschiedlichen Leistungen verteilen. Nachdem Sie eine Methode gewählt haben können Sie die Zeiten in der Vorschau anpassen.",
      "byPlannings": "Nach Planung splitten",
      "byPlanningsInfo": "Die Arbeitszeit wird auf die für diesen Tag geplanten Einsätze aufgeteilt.",
      "byOvertime": "Überschrittene Sollzeit splitten",
      "byOvertimeInfo": "Die Arbeitszeit, die über der Sollzeit liegt, wird einer neuen Leistung zugeordnet.",
      "byDuration": "Vorgegebene Dauer splitten",
      "byDurationInfo": "Sie geben einer Dauer vor und diese wird in einer neuen Arbeitszeit gespeichert.",
      "chooseDuration": "Dauer vorgeben",
      "chooseDurationInfo": "Bitte geben Sie die Anzahl h an, die in eine neue Arbeitszeit gesplittet werden sollen.",
      "amountDuration": "Anzahl Stunden splitten",
      "current": "Original vor dem Split",
      "is": "Ist",
      "should": "Soll",
      "location": "Objekt",
      "activity": "Leistung",
      "afterSplit": "Original nach dem Split",
      "splitNow": "Jetzt splitten",
      "success": "Zeit wurde gesplittet.",
      "description": "Dies is eine Vorschau: Bitte prüfen Sie die Daten und passen diese bei Bedarf an.",
      "new": "Neue Arbeitszeit"
    }
  },
  "approveEmployee": {
    "menuTitle": "Zeiten Freigabe",
    "pageTitle": "Zeiten freigeben ({{count}})",
    "nonApprovedEmployees": "Mitarbeiter zum Freigeben",
    "approvedEmployees": "Freigegebene Zeiten",
    "noEmployees": "Keine Mitarbeiter zur Freigabe vorhanden.",
    "noTimes": "Es wurden keine Zeiten freigegeben."
  },
  "approveEmployeeDetail": {
    "headerText": "Bitte geben Sie die Zeiten frei.",
    "description": "Geben Sie alle Zeiten auf einmal frei oder jede Zeit einzeln.",
    "approveAllButtonText": "Alle Zeiten ohne Alarm freigeben",
    "headerProblems": "Es gibt noch {{count}} Zeiten zum Freigeben.",
    "singleApproveDescription": "Diese müssen einzeln freigegeben werden.",
    "nextEmployeeDescription": "Sie haben alle Zeiten erfolgreich freigegeben!",
    "filterButtonText": "Nur Zeiten zur Freigabe anzeigen",
    "showAll": "Alles anzeigen",
    "allWorklogsApproved": "Alle Zeiten freigegeben",
    "nextEmployees": "zum nächsten Mitarbeiter",
    "backToList": "zurück zur Übersicht"
  },
  "calendar": {
    "resetFilter": "Alle Tage anzeigen",
    "noEntriesForSelection": "Am ausgewählten Tag sind keine Einträge vorhanden."
  },
  "company": {
    "name": "Name",
    "created": "Mandant wurde angelegt",
    "detailData": "Stammdaten",
    "settings": "Einstellungen",
    "taxTypes": "Beschäftigungsverhältnis",
    "saved": "Daten wurden gespeichert.",
    "selectImage": "Neues Logo hochladen",
    "invoiceUrl": "Rechnungsportal URL",
    "invoicePassword": "Rechnungsportal Passwort",
    "invoiceUsername": "Rechnungsportal Benutzername",
    "newCompany": "Neuer Mandant",
    "newCompanyDescription": "Geben Sie den Namen des neuen Mandaten ein",
    "missingName": "Es muss ein Name eingegeben werden um einen Mandanten anlegen zu können.",
    "taxTypeActivityType": {
      "saved": "Mapping wurde gespeichert",
      "noActivityTypes": "Mapping nicht möglich, weil keine Leistungsarten vorhanden sind. Leistungsarten müssen zuerst in den Mandanteneinstellungen angelegt werden."
    },
    "taxTypeDetail": {
      "name": "Name",
      "title": "Bezeichnung",
      "overTimeAlarm": "Überstunden Alarm",
      "overTimeAlarmLabel": "Anzahl Stunden pro Monat, ab der ein Überstunden-Alarm ausgelöst wird",
      "requiredFieldsErrors": "Bitte füllen Sie Name und Nummer aus um eine Lohnart anzulegen.",
      "taxTypeMapping": "Lohnarten Mapping",
      "taxTypeStandard": "Lohnarten Standard",
      "taxTypeActivityTypes": "Lohnarten Leistungarten",
      "taxTypeMappingDescription": "Hier können Sie den einzelnen Lohnarten die Buchstaben/Nummern zuordnen, die Sie auch in Ihrer Lohnbuchhaltung verwenden. Diese Daten werden beim Export dann mit exportiert. Lohnarten der Abwesenheiten finden Sie bei den Abwesenheitstypen."
    },
    "reasons": "Änderungsgründe",
    "licences": {
      "noLicences": "Derzeit sind keine Lizenzen in diesem Mandant aktiviert. Möchten Sie Lizenzen in diesem Mandant aktivieren?",
      "manageLicences": "Lizenzen verwalten"
    },
    "reports": {
      "pageTitle": "Berichtsvorlagen",
      "showPlaceholder": "Platzhalter anzeigen",
      "pageIntroduction": "Hier können Sie Wordvorlagen hinterlegen, die beim Export von PDF Berichten in den Bereichen \"Zeiten freigeben\" und \"Gebuchte Zeiten\" verwendet werden können. In den Wordvorlagen können Sie mit Platzhaltern arbeiten. In unserer Online-Dokumentation finden Sie fertige Vorlagen zum Download",
      "linkText": "Vorlagen herunterladen",
      "noTemplates": "Es sind noch keine Wordvorlagen hinterlegt."
    },
    "news": {
      "pageTitle": "News",
      "create": "News anlegen",
      "title": "Titel",
      "deleteConfirm": "Wollen Sie diese News löschen?",
      "created": "News wurde angelegt",
      "edited": "News wurden gespeichert",
      "deleted": "Der News wurde gelöscht.",
      "hint": "Hier können Sie Newsmeldungen anlegen, die automatisch in der Blink Time App angezeigt werden. Es werden immer alle Newsmeldungen angezeigt."
    },
    "feature": {
      "title": "Funktionen",
      "description": "Hier sehen Sie die Funktionen die zusätzlich pro Mandanten aktiviert oder deaktiviert werden können. Dies dient zur besseren Steuerung der erwünschten und genutzten Funktionen im Unternehmen."
    },
    "details": {
      "general": "Allgemein",
      "communication": "Inhalte & Links",
      "organisation": "Organisation",
      "baseData": "Basisdaten",
      "templates": "Vorlagen"
    },
    "externalLinks": {
      "title": "Externe Links",
      "create": "Externer Link",
      "name": "Name",
      "url": "Link",
      "order": "Reihenfolge",
      "description": "Beschreibung",
      "created": "Externer Link wurde angelegt",
      "edited": "Externer Link wurden gespeichert",
      "hint": "Externe Links werden im Hauptmenü vom Manager und im Menü der Blink me App angezeigt. Damit können externe Webseiten wie z.B. Rabattportale oder Intranetseiten in Blink integriert werden.",
      "permissionsLabel": "Sichtbar für Berechtigungsgruppe",
      "noPermission": "Berechtigungsgruppe",
      "permissionsHeader": "Sichtbar für",
      "deleteConfirm": "Wollen Sie diese Externer Link löschen?",
      "deleted": "Der Externer Link wurde gelöscht.",
      "hintModal": "Dieser Link wurde von Ihrem Unternehmen bereitgestellt. Sie verlassen das Blink System.",
      "urlHint": "Bitte geben Sie den Link mit https:// oder http:// an.",
      "descriptionHint": "Die Beschreibung wird den Benutzern angezeigt, bevor sie zur externen Seite gelangen."
    }
  },
  "configuration": {
    "loginCards": "Blink ID Karten",
    "permissions": "Berechtigungen",
    "publicHolidays": "Feiertage",
    "sysadmins": "Systemadministratoren",
    "systemSettings": "Systemeinstellungen",
    "licences": "Lizenzen"
  },
  "contextMenu": {
    "noActions": "Keine Aktionen verfügbar",
    "openInNewTab": "Link in neuem Tab öffnen",
    "newWorklog": "Wartet auf Freigabe",
    "approveWorklog": "Freigeben",
    "bookWorklog": "Buchen",
    "exportWorklog": "Auf exportiert setzen",
    "showWorklogDetail": "Details",
    "editWorklog": "Bearbeiten",
    "edit": "Bearbeiten",
    "splitWorklog": "Splitten",
    "ignoreAlarms": "Alarme ignorieren",
    "addComment": "Kommentar hinzufügen",
    "deleteWorklog": "Löschen",
    "blinkActiveShowTaskDetail": "Detail",
    "setToPlannedDuration": "Auf Sollzeit setzen",
    "changeLocationActivity": "Leistung ändern",
    "assignLocations": "Objekte verwalten",
    "resetPassword": "Passwort zurücksetzen",
    "assumeIdentity": "Identität annehmen",
    "deactivate": "Deaktivieren",
    "activate": "Aktivieren"
  },
  "toPlannedDuration": {
    "endChanged": "Ende wird von {{before}} Uhr auf {{after}} Uhr geändert",
    "error": "Automatische Anpassung nicht möglich. Bitte bearbeiten Sie die Zeit manuell",
    "automaticApproval": "Automatische Freigabe",
    "blinkActiveShowTaskDetail": "Detail",
    "changeLocationActivity": "Leistung ändern"
  },
  "customers": {
    "customer": "Kunde",
    "customers": "Kunden",
    "customerData": "Stammdaten",
    "manageCustomers": "Kunden",
    "pageTitle": "Kundenverwaltung",
    "noCustomersYet": "Es sind noch keine Kunden angelegt. Legen Sie jetzt den ersten Kunden an.",
    "noResults": "Kein Kunde gefunden.",
    "name": "Kundenname",
    "costCenterNo": "Kostenstellen-Nr.",
    "number": "Kundennummer",
    "create": {
      "create": "Kunde anlegen",
      "title": "Kunden anlegen",
      "success": "Der Kunde wurde angelegt"
    },
    "detail": {
      "customerUpdated": "Die Änderungen am Kunden wurden gespeichert"
    },
    "reasons": {
      "description": "Änderungsgründe sind vordefinierte Kommentare, die beim Anlegen und bearbeiten von Arbeitszeiten ausgewählt werden können. Aktivieren Sie hier individuelle Änderungsgründe für diesen Kunden. Wenn nicht aktiv, gelten die Standardeinstellungen vom Mandanten.",
      "toggle": "Kundenindividuelle Änderungsgründe"
    },
    "searchPlaceholder": "Suchen nach Kundenname oder Kundennumme",
    "sort": {
      "nameAsc": "Name (A..Z)",
      "nameDesc": "Name (Z..A)",
      "numberAsc": "Kundennr. (1..9)",
      "numberDesc": "Kundennr. (9..1)"
    }
  },
  "dashboard": {
    "employeeListDescription": "Folgende Mitarbeiter sind heute nicht da:"
  },
  "excelExport": {
    "type": "Typ",
    "export": "Exportieren",
    "description": "Welche Excelversion möchten Sie exportieren?",
    "excelVersion2": "Excel Version 2",
    "excelVersion1": "Excel Version 1",
    "excelVersion2_info": "Standardversion (ab März 19)",
    "excelVersion1_info": "Dieser Export wird aus Kompatibilitätsgründen für Exporte vor 1.März 2019 empfohlen",
    "changeStatusAfterExport": "Status nach dem Export ändern?",
    "changeStatus": "Status auf exportiert setzen",
    "masterData": "Stammdatenexport",
    "loadSurcharges": "Zuschläge im Detail",
    "loadSurchagresFileName": "ZuschlaegeDetails",
    "errorTimeRange": "Der ausgewählte Zeitraum darf nicht länger als 14 Monate sein"
  },
  "gpsInformation": {
    "accuracy": "Genauigkeit",
    "worklogCardTitle": "GPS Positionsinformation",
    "noGpsForEvent": "keine GPS Info vorhanden",
    "noGpsForLocation": "Keine GPS Daten für Objekt vorhanden.",
    "distanceByLocation": "Entfernung vom Objekt: {{distance}}",
    "distanceByEvents": "Start und Stop liegen {{distance}} auseinander"
  },
  "help": {
    "pageTitle": "Profil",
    "text1": "Unser Support Team steht Ihnen bei Fragen gerne zur Verfügung:",
    "phone": "Telefon",
    "mail": "E-Mail",
    "informations": "Info",
    "documentation": "Online-Dokumentation",
    "documentationText": "In unserer Online Dokumentation finden Sie Anleitungen und Videos für alle wichtigen Bereiche von Blink.",
    "support": "Support"
  },
  "homePage": {
    "locationSearch": "Suche nach Objekten",
    "employeeSearch": "Suche nach Mitarbeiter"
  },
  "import": {
    "close": "Fertigstellen",
    "pageTitle": "Import",
    "chooseTargetCompany": "Wählen Sie das Ziel-Unternehmen",
    "whatToImport": "Welche Daten möchten Sie importieren?",
    "entities": "Typ",
    "plannings": "Einplanungen von Mitarbeitern",
    "uploadExcel": "Upload Excel",
    "chooseUniqueNumber": "Bitte wählen Sie die eindeutige Kennzeichnung der Datensätze.",
    "chooseUniqueNumberPlanning": "Bitte wählen Sie die eindeutige Kennzeichnung des Objektes aus.",
    "uniqueNumberIs": "Die eindeutige Kennzeichnung der Datensätze ist das Feld: ",
    "none": "keine Prüfung auf Eindeutigkeit",
    "CostCenterNumber": "Kostenstellennummer",
    "ObjectNumber": "Objektnummer",
    "file": "Datei",
    "chooseFile": "Datei auswählen",
    "uploadAndValidate": "Hochladen & Prüfen",
    "dragFile": "Hier Datei ablegen",
    "errors": "Fehler in der Datei",
    "warnings": "Warnungen in der Datei",
    "done": "Import abgeschlossen!",
    "doneText": "Import wurde erfolgreich gestartet. Sie werden per Push Benachrichtigung informiert, sobald der Import abgeschlossen ist.",
    "readyForImport": "Bereit für den Import",
    "startImport": "Import jetzt starten",
    "numberOfImports": "Datensätze die importiert werden: {{numberOfImports}}",
    "infoBoxText": "Über den Importassistenten können Sie Mitarbeiter, Objekte und Planungen importieren. Für den Import müssen Sie unsere Excel Vorlagen verwenden.",
    "templates": "Excelvorlagen",
    "alreadyRunningError": "Ein Import läuft bereits. Bitte warten Sie bis der aktuelle Import beendet ist.",
    "validFromDescription": "Alle neuen Planungen erhalten das folgende Datum als \"Gültig ab\". Alle bestehenden und bereits gültigen Planungen der Mitarbeiter werden zu diesem Datum beendet. Bestehenden Planungen in der Zukunft werden gelöscht. Dies gilt jedoch nur für Objekte, die Teil des Excel-Importes sind.",
    "IgnorePlanningConflicts": "Konflikte in Planungen ignorieren",
    "createDate": "Erstelldatum",
    "createUser": "Ersteller",
    "entityType": "Typ",
    "backToList": "Zurück zur Übersicht",
    "status": {
      "column": "Status",
      "New": "Neu",
      "Invalid": "Daten ungültig",
      "Valid": "Daten geprüft",
      "Imported": "Importiert",
      "Processing": "Import läuft",
      "Error": "Error"
    }
  },
  "licences": {
    "licence": {
      "activate": "Aktivieren",
      "deactivate": "Deaktivieren",
      "activateLicence": "Lizenz aktivieren",
      "activateConfirm": "Die Nutzung dieses Moduls ist kostenpflichtig. Zu den Details der Kosten kontaktieren Sie bitte den Vertrieb.",
      "deactivateLicence": "Lizenz deaktivieren",
      "deactivateConfirm": "Wollen Sie diese Lizenz wirklich deaktivieren?",
      "name": "Lizenz für"
    }
  },
  "manageClients": {
    "pageTitle": "Mandantenverwaltung"
  },
  "menu": {
    "home": "Startseite",
    "configuration": "Konfiguration",
    "settings": {
      "title": "Einstellungen",
      "approvalCriteria": "Freigabekriterien",
      "systemSettings": "Systemeinstellungen",
      "manageClients": "Mandanten verwalten"
    },
    "support": "Support",
    "employeeMonitor": "Mitarbeiter Monitor",
    "approveTimes": "Zeiten",
    "administration": {
      "title": "Verwaltung",
      "employees": "Mitarbeiter",
      "locations": "Objekte"
    },
    "about": {
      "title": "Über",
      "informations": "Persönliche Einstellungen",
      "privacy": "Datenschutz",
      "changelog": "Changelog"
    },
    "customActions": "Auswertungen",
    "logout": "Abmelden"
  },
  "premiumPay": {
    "cardTitle": "Zuschläge",
    "noEntries": "Keine Zuschläge vorhanden",
    "dubletteNotice": "Zuschlag ist mehrfach vorhanden und kann nicht bearbeitet werden. Bitte ignorieren Sie einen dieser Zuschläge.",
    "types": {
      "Night": "Nachtzuschlag",
      "Sunday": "Sonntagszuschlag",
      "PublicHoliday": "Feiertagszuschlag"
    },
    "status": {
      "manually": "manuell bearbeitet",
      "ignored": "ignoriert",
      "automatically": "automatisch berechnet",
      "none": "-"
    },
    "edit": {
      "pageTitle": "Zuschläge bearbeiten",
      "changedBy": "Bearbeitet von: {{updateUser}}",
      "notice": "Wenn Sie einen Zuschlag auf ignoriert setzen oder bearbeiten, dann wird der Zuschlag bei einer Änderung der Arbeitszeiten nicht mehr aktualisiert.",
      "columnPremium": "Zuschlag",
      "columnState": "Status",
      "columnDuration": "Dauer (h)",
      "edit": "Zuschlag bearbeiten",
      "new": "Neuer Zuschlag",
      "ignore": "Zuschlag ignorieren",
      "confirmIgnore": "Wollen Sie diesen Zuschlag wirklich ignorieren?",
      "respect": "Zuschlag berücksichtigen",
      "confirmRespect": "Wollen Sie diesen Zuschlag wirklich berücksichtigen?"
    },
    "created": "Der Zuschlag wurde erfolgreich angelegt.",
    "saved": "Der Zuschlag wurde gespeichert."
  },
  "push": {
    "menuTitle": "Nachrichten",
    "newMessage": "Neue Nachricht",
    "openMessage": "Öffnen",
    "yourMessage": "Nachricht",
    "yourMessages": "Ihre Nachrichten",
    "noMessagesAvailable": "Es sind keine Nachrichten vorhanden.",
    "deleteAll": "Alle Nachrichten löschen",
    "deleteAllConfirm": "Wollen Sie wirklich alle Nachrichten löschen?",
    "deleteMessage": "Nachricht löschen",
    "deleteMessageConfirm": "Wollen Sie diese Nachricht wirklich löschen?",
    "location_absent_employee": {
      "planningTime": "Arbeitszeit von {{from}} bis {{until}}"
    },
    "errors": {
      "disabled": "Benachrichtigungen sind auf ihrem Gerät deaktiviert. Gehen Sie in die Handy-Einstellungen und aktivieren Benachrichtigungen für BlinkManager um Benachrichtigungen zu erhalten. Anschließend können Sie in den Persönlichen Einstellungen Benachrichtigungen aktivieren."
    }
  },
  "reason": {
    "reason": "Änderungsgrund",
    "reasons": "Änderungsgründe",
    "noReasons": "Keine Änderungsgründe vorhanden",
    "shortKey": "Externe ID",
    "name": "Name",
    "allowComment": "Kommentar erlaubt ja/nein",
    "newReason": "Neuer Änderungsgrund",
    "newReasonDescription": "Bitte geben Sie den Anzeigenamen des neuen Änderungsgrund ein. Anschließend können Sie die Konfiguration festlegen.",
    "created": "Änderungsgrund wurde angelegt",
    "patched": "Änderungen wurden gespeichert",
    "confirmDelete": "Wollen Sie diesen Änderungsgrund wirklich löschen?",
    "deleted": "Der Änderungsgrund wurde gelöscht.",
    "selectReason": "Bitte Änderungsgrund auswählen"
  },
  "report": {
    "dragFile": "Datei ablegen oder auswählen",
    "pageTitle": "Neue Vorlage",
    "name": "Name der Vorlage",
    "description": "Beschreibung",
    "placeholder": "Platzhalter",
    "placeholdersNotFound": "Es konnten keine Platzhalter für den Typ \"{{type}}\" gefunden werden.",
    "type": "Typ der Vorlage",
    "downloadDocument": "Vorlage herunterladen",
    "delete": {
      "action": "Löschen",
      "confirmTitle": "Vorlage löschen",
      "confirm": "Wollen Sie diese Vorlage wirklich löschen?",
      "success": "Vorlage wurde gelöscht.",
      "failure": "Vorlage konnte nicht gelöscht werden."
    },
    "create": {
      "add": "Vorlage",
      "fileInvalid": "Bitte wählen Sie als Datei ein Word-Dokument aus.",
      "action": "Vorlage anlegen",
      "success": "Vorlage wurde angelegt.",
      "failure": "Vorlage konnte nicht angelegt werden.",
      "uploadDone": "Die Datei wurde erfolgreich hochgeladen."
    },
    "typesDescription": "Für die Anzeige von Inhalten können Sie in den Wordvorlagen mit Platzhaltern arbeiten. Diese Platzhalter unterscheiden sich je nach Berichtstyp.",
    "types": {
      "EmployeeReport": "Mitarbeiterbericht",
      "EmployeeFullMonthReport": "Mitarbeiterbericht alle Tage",
      "LocationReport": "Objektbericht"
    }
  },
  "sortMode": {
    "Priority": "Priorität",
    "Alpha": "Alphabetisch"
  },
  "stepper": {
    "step": "Schritt",
    "next": "Nächster Schritt"
  },
  "systemSettings": {
    "nightSurchargeText": "Nachtzuschlag im Zeitraum",
    "ordermanagerSettings": "Einstellungen zum ordermanager",
    "worklogMaxDuration": "Maximale Arbeitszeit in Stunden",
    "worklogMaxDurationMoved": "Diese Einstellung finden Sie ab sofort in den Mandanteneinstellungen",
    "emailHost": "E-Mail Host",
    "emailUsername": "E-Mail Benutzername",
    "emailPassword": "E-Mail Passwort",
    "enableAutoCreatePublicHolidayWorklogs": "Automatische Erzeugung von Abwesenheiten an Feiertagen",
    "gpsTrackingActive": "Tracking via GPS Aktiv",
    "gpsTrackingToleranceMeters": "Toleranz (Radius in m)"
  },
  "tags": {
    "label": "Tags",
    "info": "Mit Tags (Stichwörtern) lassen sich Objekte strukturieren und nach Themen gliedern. Insight Kunden können Objekte nach Tags gruppiert anzeigen lassen.",
    "no_tags": "Noch keine Tags vorhanden.",
    "create": {
      "label": "Tag hinzufügen",
      "placeholder": "Tag eingeben"
    },
    "choose": "Auswählen",
    "created": "Tag wurde erstellt.",
    "delete": "Tag löschen",
    "delete_confirm": "Wollen Sie dieses Tag wirklich löschen?"
  },
  "taxTypes": {
    "maxMonthlyHours": "Maximale Arbeitszeit pro Monat",
    "specialLeave": "Sonderurlaub",
    "newTaxType": "Neue Lohnart",
    "newTaxTypeDescription": "Geben Sie den Namen der neuen Lohnart ein",
    "nightSurcharges": "Nachtzuschlag",
    "delete": "Beschäftigungsverhältnis löschen?",
    "deleted": "Beschäftigungsverhältnis wurde gelöscht.",
    "deleteError": "Das Beschäftigungsverhältnis kann nicht gelöscht werden, da es bereits in mindestens einem Mitarbeiter verwendet wird!"
  },
  "timeEntry": {
    "isIgnored": "Dieser Zeiteintrag wird nicht berücksichtigt.",
    "intersects": "Dieser Zeiteintrag überschneidet sich mit einem anderen Zeiteintrag. Bitte korrigieren Sie die Zeiten oder ignorieren Sie diesen Zeiteintrag."
  },
  "workloadSchema": {
    "edit": {
      "title": "Wochenarbeitszeit bearbeiten",
      "add": "Hinzufügen"
    },
    "showObsolete": "Alte Einträge anzeigen",
    "validFrom": "gültig von",
    "validUntil": "gültig bis",
    "invalidDate": "Wochenarbeitszeit kann nicht angelegt werden. Startdatum überschneidet sich mit bestehenden Gültigkeiten.",
    "requiresFutureDate": "Eine Wochenarbeitszeit muss ein Gültigkeitsdatum in der Zukunft haben.",
    "errors": {
      "ValidFromdateisnotpossible": "Ausgewähltes Datum ist nicht möglich. Bitte wählen Sie ein anders Datum, dass sich nicht mit bestehenden Wochenarbeitszeiten überschneidet."
    }
  },
  "formValidation": {
    "maxLength": "Bitte beachten Sie die maximale Länge von {{length}} Zeichen",
    "min": "Bitte geben Sie einen Wert größer oder gleich {{min}} ein"
  },
  "protocol": {
    "pageTitle": "Protokoll",
    "description": "Beschreibung",
    "searchPlaceholder": "Beschreibung, Nummer",
    "level": "Log-Level",
    "type": "Typ",
    "Originator": "Benutzername",
    "levels": {
      "All": "Alle",
      "Debug": "Debug",
      "Info": "Info",
      "Warn": "Warnung",
      "Error": "Fehler"
    },
    "detail": {
      "pageTitle": "Protokoll-Detail"
    },
    "SendingEndpoint": "Sender",
    "ReceivingEndpoint": "Empfänger"
  },
  "companySettings": {
    "push": "Push Benachrichtigungen",
    "pushDelay": "Verspätung",
    "pushDelayInfo": "Nachricht wird nach {{min}} Minuten Verspätung verschickt.",
    "approval": "Freigabekriterien",
    "approvalProcessLabel": "Zeiten prüfen bei",
    "approvalProcess": "Freigabeprozess",
    "approvalProcessHelp": "Hier können Sie festlegen, welche Bedingungen gefüllt sein müssen, damit eine Arbeitszeit freigegeben bzw. gebucht werden kann. Legen Sie zuerst fest, ob diese Prüfung gar nicht, bei der Freigabe oder beim Buchen stattfinden soll. Anschließend aktivieren Sie die Bedingungen. Nur wenn bei jedem Zeiteintrag die Bedingungen erfüllt sind, kann eine Arbeitszeit freigegeben bzw. gebucht werden.",
    "None": "Keine Prüfung",
    "Approval": "Freigabe",
    "Booking": "Buchen",
    "worklogRequiresCostcenterNo": "Kostenstellennr. ist Pflicht",
    "worklogRequiresCostcenterNoHelp": "Um Zeiten freizugeben bzw. zu buchen muss eine Kostenstellennummer angegeben sein.",
    "worklogRequiresObjectNo": "Objektnr. ist Pflicht",
    "worklogRequiresObjectNoHelp": "Um Zeiten freizugeben bzw. zu buchen muss eine Objektnummer angegeben sein.",
    "worklogRequiresLocationActivity": "Aktive und gültige Leistung ist Pflicht",
    "worklogRequiresLocationActivityHelp": "Um Zeiten freizugeben bzw. zu buchen muss eine aktive und gültige Leistung angegeben sein.",
    "employeeRequiresEmployeeNo": "Personalnummer ist Pflicht",
    "employeeRequiresEmployeeNoHelp": "Um Zeiten freizugeben bzw. zu buchen muss der Mitarbeiter eine Personalnummer haben.",
    "worklogRequiresOrderNo": "Auftragsnummer ist Pflicht",
    "worklogRequiresOrderNoHelp": "Um Zeiten freizugeben bzw. zu buchen muss eine Auftragsnummer angegeben sein.",
    "taxTypeRequiresNo": "Beschäftigungsverhältnis muss Nummer enthalten",
    "taxTypeRequiresNoHelp": "Um Zeiten freizugeben bzw. zu buchen muss im Beschäftigungsverhältnis vom Mitarbeiter eine Nummer hinterlegt sein.",
    "companyRequiresNo": "Mandant muss Mandantennummer haben",
    "companyRequiresNoHelp": "Um Zeiten freizugeben bzw. zu buchen muss der Mandant eine Mandantennummer haben.",
    "worklogRequiresDateNotToday": "Freigabe bzw. buchen von Zeiten nicht am selben Tag",
    "worklogRequiresDateNotTodayHelp": "Erfasste Zeiten können erst ab dem Folgetag freigegeben bzw. gebucht werden.",
    "alarms": "Alarme",
    "alarmDescription": "Hier legen Sie fest, welche Alarme berücksichtigt werden sollen.",
    "breaktime": "Pausen",
    "timeEntriesHaveNoOverlappings": "Freigabe bzw. buchen von Zeiten nicht mit Überschneidungen",
    "timeEntriesHaveNoOverlappingsHelp": "Um erfasste Zeiten eines Mitarbeiters freizugeben oder zu buchen, darf es keine zeitlichen Überschneidungen geben.",
    "timeRounding": "Auf-/Abrunden",
    "alertsInTrackedTimes": "Alarme bei erfassten Zeiten",
    "alertsInTrackedTimesInfo": "Hier können Sie konfigurieren, welche Alarme erzeugt werden sollen, nachdem Arbeitszeiten erfasst wurden. Diese Alarme werden im Monitor, in der Zeiten Freigabe und in gebuchten Zeiten angezeigt.",
    "alertsInPlanning": "Warnhinweise in der Einplanung",
    "alertsInPlanningInfo": "Hier können Sie festlegen, welche Warnhinweise im Einplanungskalender angezeigt werden sollen. Diese Warnungen beziehen sich nur auf geplante Arbeitszeiten.",
    "locationReport": "Objekt Bericht",
    "locationReportHelp": "Im Objekt Monitor wird ein Berichtssymbol pro Objekt angezeigt, mit dem der Objektbericht geöffnet werden kann.  Das Berichtssymbol wird rot angezeigt, wenn der Ist Wert vom aktuellen Monat die hier definierte Toleranz gegenüber dem Soll über- oder unterschreitet.",
    "locationReportingPositiveDeviationPercentage": "Überschreitung vom Soll in %",
    "locationReportingPositiveDeviationPercentageHelp": "Bei Überschreitung wird das Berichtssysmbol im Objektmonitor rot angezeigt.",
    "locationReportingNegativeDeviationPercentage": "Unterschreitung vom Soll in %",
    "locationReportingNegativeDeviationPercentageHelp": "Bei Unterschreitung wird das Berichtssysmbol im Objektmonitor rot angezeigt."
  },
  "breaktimeSettings": {
    "breakTimeCalculationMode": "Pausen-Modus",
    "breakTimeCalculationModeNote": "<p>Mit den Pauseneinstellungen können Sie die automatische Berechnung von Pausen angeben. Die Parameter können dabei von Ihnen so verändert werden, dass es zu den gesetzlichen Anforderungen Ihrer Branche passt.</p><br><p><b>Bitte stellen Sie sicher, dass diese gesetzlichen Anforderungen eingehalten werden!</b></p>",
    "breakTimeCalculationModeInfo": "<b>Pausen aus Planungen</b><br>Beim Erfassen von Arbeitszeiten wird immer exakt die Pausendauer von der Arbeitszeit abgezogen, die in der Planung hinterlegt wurde.<br><br><b>Automatische Pausenberechnung</b><br>Bei der automatischen Berechnung werden immer alle erfassten Arbeitszeiten eines Mitarbeiters berücksichtigt, die weniger als 3 Stunden Abstand voneinander haben. Die Pausen werden anhand der hinterlegten Einstellungen berechnet. Bei der Pausenberechnung werden ausgestempelte Pausen zwischen den Arbeitszeiten auch mit eingerechnet.",
    "basedOnPlanning": "Pausen aus Planungen",
    "basedOnPlanningInfo": "Beim Erfassen von Arbeitszeiten wird immer exakt die Pausendauer von der Arbeitszeit abgezogen, die in der Planung hinterlegt wurde.",
    "autoBreakCalculation": "Automatische Pausenberechnung",
    "autoBreakCalculationInfo": "Bei der automatischen Berechnung werden immer alle erfassten Arbeitszeiten eines Mitarbeiters berücksichtigt, die weniger als 3 Stunden Abstand voneinander haben. Die Pausen werden anhand der hinterlegten Einstellungen berechnet. Bei der Pausenberechnung werden ausgestempelte Pausen zwischen den Arbeitszeiten auch mit eingerechnet.",
    "minBreakDurationMinutes": "Minimale Pausenzeit",
    "minBreakDurationMinutesInfo": "Unterbrechnungen der Arbeitszeit werden erst als Pausenzeit gewertet, wenn die minimale Pausenzeit erreicht wird. Pausen, die kürzer sind, werden nicht in der Pausenberechnung berücksichtigt.",
    "maxBreakDurationMinutes": "Maximale Pausenzeit",
    "maxBreakDurationMinutesInfo": "Unterbrechnungen der Arbeitszeit, die größer als die maximale Pausenzeit sind, werden als Freizeit und nicht als Pause gewertet.",
    "firstObligatoryBreakCheckMinutes": "Dauer bis zur ersten Pflichtpause",
    "firstObligatoryBreakCheckMinutesInfo": "Die Dauer gibt an, ab wann die erste Pflichtpause gemacht werden muss.",
    "firstObligatoryBreakTargetMinutes": "Pausendauer der erste Pflichtpause",
    "firstObligatoryBreakTargetMinutesInfo": "Die angegebene Pausendauer wird nach Erreichen der Dauer der ersten Pflichtpause automatisch von der Arbeitszeit abgezogen.",
    "secondObligatoryBreakCheckMinutes": "Dauer bis zur zweiten Pflichtpause",
    "secondObligatoryBreakTargetMinutes": "Pausendauer der zweiten Pflichtpause",
    "thirdObligatoryBreakCheckMinutes": "Dauer bis zur dritten Pflichtpause",
    "thirdObligatoryBreakTargetMinutes": "Pausendauer der dritten Pflichtpause",
    "considerPlanningGapsAsPlannedBreaks": "Lücken in Planung als Pausen berücksichtigen",
    "considerPlanningGapsAsPlannedBreaksInfo": "Mit dieser Einstellung kann die Lage der Pause geplant werden. Wenn es zwei Planungen gibt und zwischen diesen beiden Planungen eine Lücke gelassen wird, so wird diese Lücke als Lage der Pause angenommen. Voraussetzung ist, dass der Mitarbeiter nur einmal startet und einmal stoppt, damit die Pause automatisch zur angegebenen Lage berechnet werden kann.",
    "minPlanningGapToConsiderMinutes": "Mindestlänge der Planungslücke um als Pause zu gelten",
    "maxPlanningGapToConsiderMinutes": "Maximallänge der Planungslücke um noch als Pause zu gelten",
    "enableBreakTimeDeductionGoodwill": "Kulanzregelung",
    "enableBreakTimeDeductionGoodwillInfo": "Mit der Kulanzregelung werden automatisch abgezogene Pausen so berechnet, dass die Arbeitszeit nicht kleiner als die angegebene Dauer werden kann. Beispiel: Mitarbeiter arbeitet 6h 10min. Mit aktivierter Kulanzregelung werden statt 30min nur 10 min abgezogen, d.h. der Mitarbeiter hat 6h Arbeitszeit gebucht. Ohne aktivierte Kulanzregelung werden 30 min abgezogen, d.h. der Mitarbeiter hat 5h 40min Arbeitszeit gebucht."
  },
  "planningSettings": {
    "adjustTime": "Ist-Zeit auf Soll-Zeit anpassen",
    "adjustTimeHelp": "Hier können Sie einstellen, ob erfasste Arbeitszeiten automatisch an die Sollzeit angepasst werden sollen. Wichtig: Die Einstellungen die Sie hier vornehmen werden als Standardeinstellung für Objekte und für Planungen  übernommen. Sie können diese Einstellung pro Objekt und Planung individuell überschreiben.",
    "adjustTimeLocationHelp": "Hier können Sie einstellen, ob erfasste Arbeitszeiten automatisch an die Sollzeit angepasst werden sollen.",
    "enableDurationTrim": "Automatische Anpassung der Ist-Zeit",
    "enableDurationTrimHelp": "Die automatische Anpassung erfolgt täglich für gestern und vorgestern.",
    "durationTrimModeLabel": "Ist-Zeiten Anpassung",
    "durationTrimMode": {
      "None": "Keine automatische Anpassung",
      "Reduce": "Soll-Zeit Überschreitungen anpassen",
      "ReduceExample": "Bei Überschreitung der Soll-Zeit von bis zu {{minutes}} Minuten wird die Ist-Zeit auf die Soll-Zeit gekürzt.",
      "Supplement": "Soll-Zeit Unterschreitungen anpassen",
      "SupplementExample": "Bei Unterschreitung der Soll-Zeit von bis zu {{minutes}} Minuten wird die Ist-Zeit auf die Soll-Zeit erhöht.",
      "Both": "Über und Unterschreitungen anpassen",
      "BothExample": "Bei Überschreitung oder Unterschreitung der Soll-Zeit von bis zu {{minutes}} Minuten wird die Ist-Zeit auf die Soll-Zeit gekürzt bzw. erhöht."
    },
    "durationTrimDurationMinutes": "Anpassungsbereich in Minuten",
    "durationTrimDurationMinutesHelp": "Anpassungen an die Sollzeit werden nur vorgenommen, wenn die Über- bzw. Unterschreitung innerhalb der hier angegebenen Minuten stattfindet.",
    "durationTrimReason": "Begründung",
    "durationTrimReasonHelp": "Die angegebene Begründung wird bei jeder automatischen Anpassung der Arbeitszeit als Kommentar hinterlegt.",
    "roundUpOrDown": "Uhrzeit auf-/abrunden",
    "roundUpOrDownHelp": "Mit dieser Funktion werden Start und Ende automatisch an die geplante Uhrzeit angepasst. Die Einstellung, die hier vorgenommen wird gilt für alle Objekte und Planungen. Pro Objekt und Planung kann die Einstellung individuell überschrieben werden.",
    "timeStartTrimModeLabel": "Startzeit auf-/abrunden",
    "exportInheritedTrimSettings": "Wird aus Objekteinstellungen übernommen",
    "timeTrimMode": {
      "None": "Keine",
      "Before": "Nur vorher",
      "After": "Nur nachher",
      "Both": "vorher und nachher"
    },
    "timeEndTrimModeLabel": "Stoppzeit auf-/abrunden",
    "timeTrimDurationMinutes": "Minuten",
    "timeTrimReason": "Begründung",
    "timeTrimReasonHelp": "Die angegebene Begründung wird bei jeder automatischen Anpassung der Arbeitszeit als Kommentar hinterlegt.",
    "timeTrimExample": "Beispiel für Dienstbeginn 8 Uhr und Dienstende 16 Uhr an Hand der gesetzten Einstellungen:",
    "timeTrimStartExample": "Wenn zwischen {{startTime}} und {{endTime}} gestartet wird, dann gilt als Startzeit immer {{time}}.",
    "timeTrimEndExample": "Wenn zwischen {{startTime}} und {{endTime}} gestoppt wird, dann gilt als Stoppzeit immmer {{time}}.",
    "timeRoundingNote": "Bevor Sie die automatische Anpassung von Arbeitszeit aktivieren, sollten Sie die rechtlichen Grundlagen prüfen. <br /> Informieren Sie Ihre Arbeitnehmer über die automatischen Anpassungen bzw. lassen Sie sich diese bestätigen.",
    "adjustManually": "Manuelle Anpassung",
    "adjustManuallyNote": "Hinweis: Die automatische Anpassung der Ist-Zeit wird automatisch täglich für die letzten 2 Tage durchgeführt. Sie müssen diese nur dann manuell ausführen, wenn Sie die Funktion zum ersten Mal aktivieren und Vergangenheitsdaten anpassen wollen.",
    "adjustManuallyErrorTimeRange": "Der Zeitraum darf maximal 31 Tage betragen.",
    "settingsFromCompany": "Einstellungen aus Mandant",
    "inheritFromCompany": "Aus Mandanteneinstellungen übernehmen",
    "inheritFromCompanyHelp": "Sie können die Standardeinstellungen aus dem Mandant übernehmen oder individuelle Einstellungen für diese Objekt festlegen.",
    "inheritFromCompanyYes": "Ja - Einstellungen aus Mandant übernehmen",
    "inheritFromCompanyNo": "Nein - Individuelle Objekteinstellungen festlegen",
    "adjustNow": "Jetzt anpassen",
    "noTrimSettings": "Uhrzeit auf-/abrunden ist für das Objekt und den Mandanten nicht konfiguriert."
  },
  "planningEntry": {
    "chooseSubstitute": "Vertretung festlegen",
    "chooseSubstituteDescription": "Bitte wählen Sie eine Vertretung für {{ employeeName }} am {{ day }}, {{ time }} im Objekt {{ location }}",
    "substitudeHasBeenChanged": "Vertretung erfolgreich erstellt",
    "substitudeFailedPEInUse": "Vertretung kann nicht eingeplant werden, da die Planung bereits in einer Arbeitszeit berücksichtigt ist.",
    "substituteHint": "Vertretung für {{ employeeName }}",
    "substituteByHint": "Wird vertreten durch {{ employeeName }}",
    "noSubstitutePlanned": "Keine Vertretung eingeplant",
    "delete": "Planung löschen",
    "deleteDescription": "Welche Planung wollen Sie löschen?",
    "confirmDelete": "Wollen Sie diese einzelne Planung für diesen Tag löschen?",
    "deletePE": "Nur diese einzelne Planung",
    "deleteEPE": "Serienplanung löschen",
    "deleteEPEToaster": "Serienplanung gelöscht",
    "deletePEToaster": "Planung gelöscht",
    "chooseDateHeader": "Bitte wählen Sie ein neues Datum für diese Planung.",
    "actualDate": "Aktuelles Datum: {{weekday}}, {{date}}",
    "dateMoveTo": "Verschieben auf",
    "dateSave": "Neues Datum speichern",
    "employeeComments": "Mitarbeiter Hinweise",
    "locationComments": "Objekt Hinweise",
    "reduceDuration": "{{comma}}Pauschale {{duration}} h",
    "source": {
      "PlanningEntrySchema": "Serienplanung",
      "SinglePlanningEntry": "Einzelplanung",
      "ExternalSystem": "Geplant in externem System",
      "BlinkPlan": "Blink Plan"
    },
    "changeDateTitle": "Umplanen",
    "plusMinusDay": "Einen Tag früher / später",
    "plusMinusHour": "Eine Stunde früher / später",
    "editAll": "Alle Daten bearbeiten"
  },
  "terminal": {
    "terminal": "Terminal",
    "terminals": "Terminals",
    "createTerminal": "Terminal anlegen",
    "updateTerminal": "Terminal bearbeiten",
    "showInactive": "Inaktive Terminals anzeigen",
    "mainLocation": "Objekt",
    "deviceNumber": "Seriennummer",
    "state": {
      "state": "Status",
      "new": "Neu",
      "active": "Aktiv",
      "instock": "Im Lager",
      "inrepair": "Zur Reparatur",
      "notinuse": "Nicht in Verwendung",
      "inactive": "Inaktiv"
    },
    "description": "Beschreibung",
    "inventoryNo": "Inventarnummer",
    "externalId": "Externe ID",
    "additionalLocations": "Weitere Objekte",
    "searchPlaceholder": "Suchen nach Seriennummer, Objekte oder Inventarnummer",
    "terminalIsInactive": "Terminal ist deaktiviert",
    "type": {
      "HardwareTerminal": "Kostenpflichtiges Blink-Terminal",
      "SoftwareTerminal": "Kunden-Terminal"
    },
    "deactivate": "Deaktivieren",
    "deactivateHeader": "Wollen Sie dieses Terminal wirklich deaktivieren?",
    "deactivateMessage": "Wenn Sie das Terminal deaktivieren, können keine Zeiten mehr von diesem Gerät empfangen werden.\nDieser Vorgang kann nicht rückgängig gemacht werden. \nWollen Sie fortfahren?",
    "deleteHeader": "Wollen Sie dieses Terminal wirklich löschen?",
    "deleteMessage": "Wollen Sie fortfahren?",
    "configurationQr": "Konfiguration QR Code",
    "syncIntervalStartingHour": "Uhrzeit der Aktualisierung",
    "syncInterval": {
      "syncInterval": "Abruf der Stammdaten",
      "onceADay": "Alle 24 Stunden",
      "hourly": "Stündlich",
      "2Hours": "Alle 2 Stunden",
      "6Hours": "Alle 6 Stunden"
    },
    "enableManualSelectionOfStartStop": "Mitarbeiter müssen Start/Stop bei der Erfassung auswählen",
    "locationRememberMode": {
      "locationRememberMode": "Objektvorschlag bei der Zeiterfassung",
      "byDevice": "Letztes Objekt am Terminal",
      "byDeviceDescription": "Das Terminal speichert sich die letzte Auswahl des Objektes und schlägt diese bei allen weiteren Zeiterfassungen vor, unabhängig vom Mitarbeiter.",
      "byEmployee": "Letztes Objekt des Mitarbeiters",
      "byEmployeeDescription": "Das Terminal speichert sich die letzte Auswahl des Mitarbeiters und schlägt ihm diese bei der nächsten Zeiterfassung wieder vor."
    },
    "selectionOfLocationMode": {
      "selectionOfLocationMode": "Objektauswahl",
      "onlyBasedOnPlanning": "Automatisch auf Basis der Planung",
      "onlyBasedOnPlanningDescription": "Es wird das Objekt und die Leistung auf Basis der Planung genutzt. Existiert keine Planung, so wird das Standard-Objekt des Terminal ohne Leistung genutzt.",
      "manualSelectionAllowed": "Manuelle Auswahl erlaubt",
      "manualSelectionAllowedDescription": "Es wird das Objekt und die Leistung der Planung vorgeschlagen. Der Mitarbeiter kann diese aber immer ändern.",
      "manualWhenWithoutPlanning": "Manuelle Auswahl, nur wenn keine Planung vorhanden",
      "manualWhenWithoutPlanningDescription": "Es wird das Objekt und die Leistung der Planung genutzt. Nur wenn es keine Planung gibt, so kann der Mitarbeiter diese ändern."
    },
    "defaultLanguage": "Standard Sprache",
    "availableLanguages": "Weitere auswählbare Sprachen",
    "lastTimeTracking": "Letzte Zeiterfassung am Terminal",
    "global": "Allgemein",
    "moreOptions": "Erweitert",
    "emptyState": "Keine Terminals vorhanden.",
    "noResults": "Es konnten keine Terminals gefunden werden.",
    "EnableKeepAppAwake": "Terminal App immer im Vordergrund",
    "EnableQrCodeScan": "Blink ID Karte scannen erlauben",
    "AllowChangeOfSettingsWithoutPin": "Änderung der Einstellungen ohne PIN erlauben",
    "results": "{{total}} Ergebnisse",
    "createHardwareTerminal": "Kostenpflichtiges Blink-Terminal anlegen",
    "selectAll": "Terminals auswählen",
    "massAction": "Aktion für {{count}} Terminals",
    "EnableTimeTrackingByManualEnteredBlinkId": "Zeiterfassung durch manuell eingegebene Blink-ID erlauben (ab Terminal Version 1.9)",
    "created": "Terminal wurde angelegt",
    "edited": "Terminal wurden gespeichert",
  },
  "budgetWorkingHours": {
    "BudgetWorkingHoursMonday": "Montag",
    "BudgetWorkingHoursTuesday": "Dienstag",
    "BudgetWorkingHoursWednesday": "Mittwoch",
    "BudgetWorkingHoursThursday": "Donnerstag",
    "BudgetWorkingHoursFriday": "Freitag",
    "BudgetWorkingHoursSaturday": "Samstag",
    "BudgetWorkingHoursSunday": "Sonntag",
    "BudgetWorkingHoursPublicHoliday": "Feiertag"
  },
  "coreList": {
    "entityPluralDefault": "Ergebnisse",
    "emptyState": "Keine {{entityPlural}} vorhanden.",
    "noResults": "Es konnten keine {{entityPlural}} gefunden werden.",
    "selectAll": "Alle {{entityPlural}} auswählen",
    "massAction": "Aktion für {{count}} {{entityPlural}}"
  },
  "saving": "Speichern...",
  "planned": "Geplant",
  "plannedTimeFrame": "Geplant im Zeitfenster",
  "breakTimeEntry": {
    "StopStartBreak": "Pause (Ausgestempelt)",
    "FirstObligatoryBreak": "Pause (1. Pflichtpause)",
    "SecondObligatoryBreak": "Pause (2. Pflichtpause)",
    "ThirdObligatoryBreak": "Pause (3. Pflichtpause)",
    "PlanningGapBreak": "Pause (Geplante Pause)",
    "PlanningBreak": "Pause (Geplante Pause)",
    "ManuallyBreak": "Pause (Manuell erfasst)",
    "UnknownBreak": "Pause (genaue Lage unbekannt)",
    "PaidBreak": "bezahlte "
  },
  "duration": "Dauer",
  "oClock": "Uhr",
  "should": "Soll",
  "formError": {
    "min": "Wert muss größer als {{value}} sein.",
    "min2": "Wert muss größer oder gleich {{value}} sein.",
    "max": "Wert muss kleiner oder gleich {{value}} sein.",
    "requiredBecause": "Wert benötigt weil {{name}} ausgefüllt ist.",
    "isRequired": "{{name}} ist Pflicht.",
    "numberInUse": "Personalnummer ist bereits in Verwendung.",
    "maxlength": "{{name}} darf nicht mehr als {{value}} Zeichen enthalten.",
    "email": "Bitte geben sie eine gültige E-Mail Adresse an."
  },
  "inMinutes": "(in Minuten)",
  "approveWorklogSettings": {
    "title": "Einstellungen",
    "workTime": "Wochenarbeitszeit (WAZ) anzeigen",
    "targetTime": "Sollzeit anzeigen"
  },
  "general": "Allgemein",
  "importantAlert": "Wichtiger Hinweis",
  "progressiveRenderingInfo": "<b>Ladezeitoptimierung aktiv:</b> Eventuell müssen sie die Seite aktualisieren um Änderungen sehen zu können.",
  "REPRESENTED_EMPLOYEE": {
    "available": "Verfügbar",
    "notAvailable": "Bereits eingeplant",
    "timeSlotAvailable": "Verfügbarkeit prüfen",
    "representationNotPossible": "Nicht verfügbar",
    "all": "Alle",
    "withLocationExperience": "Mit Objekterfahrung",
    "alreadyScheduled": "Zu diesem Zeitpunkt eingeplant:",
    "searchTerm": "Suchen",
    "searchPlaceholder": "Name, Personalnummer",
    "searchEmptyState": "Keine Mitarbeiter gefunden.",
    "substituteHasAbsence": "Dieser Mitarbeiter ist an diesem Tag nicht da. Wollen Sie ihn dennoch als Vertretung einplanen?",
    "assignAnyway": "Trotzdem zuweisen",
    "lastTimeInObject": "Letzter Einsatz im Objekt: {{date}}",
    "isTodayInLocation": "Arbeitet heute im Objekt",
    "never": "nie",
    "setPlanningButton": "Vertretung von {{startTime}} bis {{endTime}} einplanen",
    "isAbsentDescription": "Dieser Mitarbeiter ist and diesem Tag nicht da. Wollen Sie ihn dennoch als Vertretung einplanen?",
    "isAbsentSet": "Trotzdem einplanen",
    "isAvailableSet": "Als Vertretung einplanen",
    "planningIsInterfering": "Vertretung an diesem Tag nicht möglich"
  },
  "UPDATE": {
    "UPDATE": "Aktualisieren",
    "latestVersion": "Es liegt eine neue Version vor. Bitte aktualisieren Sie Ihre App um alle Vorteile zu nutzen.",
    "warnVersion": "Sie nutzen eine veraltete Version dieser App. Bitte führen Sie zeitnah ein Update durch.",
    "blockVersion": "Sie nutzen eine veraltete Version dieser App die nicht mehr unterstützt wird. Bitte führen Sie nun ein Update durch."
  },
  "VACANCY_PLANNING": {
    "ofEmployees": "{{amount}} von {{total}} Mitarbeitern",
    "rest": "Rest",
    "withRestFromLastYear": "{{days}} verbleibend (von {{days1}} + {{days2}} Tagen)",
    "withoutRestFromLastYear": "{{days}} verbleibend (von {{days1}} Tagen)",
    "withoutVacationBudget": "{{days}} Tage eingetragen",
    "overBudget": "{{days}} Tage über Urlaubsanspruch ({{days1}}/{{days2}})"
  },
  "days": "Tage",
  "week": "Woche",
  "month": "Monat",
  "alerts": {
    "alerts": "Alarme",
    "EmployeeWorkloadSchemaInTimeRangeExceeded": "Die vertraglich vereinbarte Arbeitszeit (WAZ) im aktuellen Zeitraum wird überschritten.",
    "EmployeeMaxWorkingHoursOfDayExceeded": "Max. Stunden pro Tag überschritten.",
    "employeeMaxWorkingHoursOfDayExceededSetting": "Warnung, wenn die Planung die maximale Arbeitszeit pro Tag überschreitet.",
    "EmployeeMissingRestPeriod": "Ruhezeit von 11 Stunden nicht eingehalten.",
    "employeeMissingRestPeriodSetting": "Warnung, wenn die Ruhezeit von 11 h zwischen zwei geplanten Diensten nicht eingehalten wird.",
    "EmployeeMaxContiguousWorkingDaysExceeded": "Arbeitet mehr als 6 Tage am Stück.",
    "employeeMaxContiguousWorkingDaysExceededSetting": "Warnung, wenn Mitarbeiter für mehr als 6 Tage am Stück eingeplant wird.",
    "EmployeeOverlappingsInPlanning": "Die Zeiträume der eingeplanten Schichten überschneiden sich am {{date}}."
  },
  "ABSENCES": {
    "Title": "Abwesenheitstypen",
    "Absence": "Abwesenheit",
    "Create": "Abwesenheitstyp",
    "Icon": "Icon",
    "Order": "Reihenfolge",
    "BaseData": "Allgemein",
    "TaxType": "Nummer",
    "WageTypeDefault": "Standard Lohnart",
    "TaxTypes": "Abweichende Lohnart",
    "TaxTypeDescription": "Für alle Beschäftigungsverhältnisse wird als Standard Lohnart {{ wageTypeDefault }} angenommen. Wenn Sie eine abweichende Lohnart angeben wollen, tragen Sie es beim Beschäftigungsverhältnis ein.",
    "NoTaxTypeDescription": "Im Reiter \"Allgemein\" können Sie eine Lohnart angeben, die für alle Beschäftigungsverhältnisse gilt. Hier können Sie pro Beschäftigungsverhältnis eine abweichende Lohnart angeben.",
    "calculateWorkedDurationMode": "Berechnung der Ist-Zeit",
    "creatingPreview": "Vorschau beim Anlegen",
    "SetToZero": "Immer 0 Stunden",
    "UsePlannings": "Ist entspricht der geplanten Zeit",
    "UseWorkloadSchema": "Ist entspricht der Wochenarbeitszeit",
    "UsePlanningsOrWorkloadSchema": "Ist entspricht der geplanten Zeit oder der Wochenarbeitszeit",
    "considerExistingWorklogs": "Soll die vorhandene Ist-Zeit bei der Berechnung der Abwesenheit berücksichtigt werden?",
    "usableByUsersWithSpecialPermission": "Geschützter Abwesenheitstyp",
    "PLEASE_CHOOSE": "Bitte wählen Sie zuerst einen Mitarbeiter",
    "CHANGE": "Abwesenheit bearbeiten",
    "Translation": "Übersetzung",
    "Name": "Name",
    "Abbreviation": "Abkürzung",
    "Description": "Bezeichnung",
    "ChooseTranslateLanguage": "Übersetzung hinzufügen",
    "ChooseCategory": "Kategorie",
    "CategoryHint": "Abwesenheiten dieser Kategorie werden als {{category}} berücksichtigt.",
    "ReplacesOtherAbsenceTypes": "Ersetzt andere Abwesenheiten",
    "Category": {
      "None": "Keine",
      "Vacation": "Urlaub",
      "Disease": "Krank"
    },
    "ChooseProposeAbsenceCreationMode": "Abwesenheiten vorschlagen an",
    "ProposeAbsenceCreationMode": {
      "EveryDay": "Jedem Tag",
      "DaysWithPlanning": "Geplanten Tagen",
      "DaysWithWorkload": "Tagen mit Wochenarbeitszeit",
      "DaysWithPlanningOrWorkload": "Geplanten Tagen oder mit Wochenarbeitszeit",
      "NoProposal": "Kein Vorschlag"
    },
    "abbreviationInfo": "Wird im Urlaubsplaner und beim Excelexport verwendet.",
    "orderInfo": "Hiermit kann die Reihenfolge bestimmt werden, in der Abwesenheitstypen beim Anlegen vorgeschlagen werden. Je kleiner die Zahl, desto weiter oben.",
    "numberInfo": "Eindeutige Nummer zur Identifizierung z.b. beim Export in die Lohnbuchhaltung.",
    "wageTypeDefaultInfo": "Lohnart unter der dieser Abwesenheitstyp in der Lohnbuchhaltung verarbeitet wird. Wichtig vor allem beim Abrechnungsexport z.B. nach Datev. Die Standard Lohnart kann im Reiter “Abweichende Lohnarten” pro Beschäftigungsverhältnis überschrieben werden.",
    "categoryInfo": "Die Kategorie muss nur gewählt werden, wenn es sich bei der Abwesenheit um Krankheit oder Urlaub handelt. Bei Urlaub wird diese Abwesenheit in die Berechnung der Urlaubstage mit einbezogen. Diese Einstellung hat direkten Einfluss auf die Berechnung von Zuschlägen beim Abrechnungsexport (z.B. Datevexport).",
    "calculateWorkedDurationModeInfo": "Beim Anlegen von Abwesenheiten wird die IST-Zeit der Abwesenheit auf Basis der hier eingestellten Logik berechnet.",
    "calculateWorkedDurationModeInfoDisabled": "Für automatisch erzeugte Abwesenheiten an Feiertagen wird immer nur die Wochenarbeitszeit berücksichtigt.",
    "considerExistingWorklogsInfo": "Wenn aktiviert, dann werden Arbeitszeiten bei der Berechnung der IST-Zeit der Abwesenheit berücksichtigt. Sinnvoll z.B. bei Kurzarbeit.",
    "chooseProposeAbsenceCreationModeInfo": "An diesen Tagen sollen die Abwesenheiten angelegt werden (nur Vorschlag im Assistent beim Anlegen mehrerer Abwesenheiten)",
    "usableByUsersWithSpecialPermissionInfo": "Abwesenheiten von diesem Typ dürfen nur von Benutzern mit der Berechtigung “Geschützte Abwesenheiten anlegen und bearbeiten” angelegt und bearbeitet werden. Unabhängig von der Einstellung sind alle angelegten Abwesenheiten immer für alle Nutzer sichtbar.",
    "replacesOtherAbsenceTypesInfo": "Wenn für mehrere Tage Abwesenheiten über den Assistenten angelegt werden, dann werden bereits vorhandene Abwesenheiten durch diese Abwesenheit ersetzt (z.B. Krankheit ersetzt vorhandenen Urlaub, in diesem Fall muss bei “Krankheit” diese Einstellung aktiv sein)"
  },
  "insight": {
    "title": "Blink Insight",
    "configuration": "Blink Insight verwalten",
    "customer": "Insight Kunde",
    "customers": "Insight Kunden",
    "showInactiveCustomers": "Inaktive Insight Kunden anzeigen",
    "info": "Erstellen Sie Insight-Kunden, um Ihren Kunden Zugriff auf das Insight Portal zu geben. Ein Insight-Kunde kann beliebige Objekte verschiedener Blink Kunden und Mandanten enthalten!",
    "sharedLocations": "Aktuell freigegebene Objekte gesamt: {{amount}} Objekte",
    "createCustomer": "Neuen Insight-Kunden anlegen",
    "createCustomerInfo": "Bitte geben Sie den Anzeigenamen des neuen Insight-Kunden ein. Anschließend können Sie die Konfiguration festlegen.",
    "noResults": "Keine Insight-Kunden",
    "userAmount": "Anzahl der Benutzer",
    "locationAmount": "Anzahl der Objekte",
    "users": "Kundenbenutzer",
    "usersInfo": "Legen Sie Kundenbenutzer an, damit Ihre Kunden Zugriff auf das Insight Portal bekommen. Über die Objektzuweisung wird festgelegt, welche Objekte der Benutzer im Portal sehen darf. Wenn Sie eine Vorschau vom Portal sehen möchten nutzen Sie “Identität annehmen” des Benutzers.",
    "customerName": "Kundenname",
    "message": "Willkommensnachricht",
    "messageInfo": "Nachricht, die Ihrem Kunden auf der Startseite angezeigt wird. Links müssen mit 'http://' oder 'https://' beginnen.",
    "logo": "Logo",
    "logoInfo": "Wird neben der Willkommensnachricht angezeigt.",
    "uploadLogo": "Logo hochladen",
    "removeLogo": "Logo löschen",
    "showPlanningsInDetail": {
      "label": "Planungen im Detail anzeigen",
      "info": "Wenn die Details aktiviert sind, dann können Kundenbenutzer die Anzahl der geplanten Mitarbeiter und tatsächlich gearbeiteten Dienste einsehen. Wenn Details nicht aktiviert sind, dann wird nur ein zusammenfassender Status pro Objekt angezeigt.",
      "true": "Ja, Details der eingeplanten Mitarbeiter anzeigen",
      "false": "Nein, nur zusammenfassenden Objektstatus anzeigen"
    },
    "showEmployeeNames": {
      "label": "Mitarbeiternamen anzeigen",
      "info": "Um die Objekte zu überwachen, sieht der Kunde die Mitarbeiter als kleine Icons dargestellt. Darf der Kunde unter den Icons die Namen der Mitarbeiter sehen?",
      "fullName": "Vollständige Namen anzeigen",
      "abbreviation": "Kürzel anzeigen",
      "noName": "Keine Namen anzeigen"
    },
    "showLocationTime": "als Summe pro Objekt anzeigen",
    "showEmployeeTime": "im Detail pro Mitarbeiter anzeigen",
    "startTime": "Uhrzeit Start",
    "endTime": "Uhrzeit Stop",
    "duration": "Dauer",
    "locationActivityDescription": "Leistungsbeschreibung",
    "showTimeInfo": "Die Daten pro Mitarbeiter können nur angezeigt werden, wenn die Details pro Objekt aktiviert wurden.",
    "createUser": "Kundenbenutzer erstellen",
    "editUser": "Kundenbenutzer bearbeiten",
    "locationGroupedByTagViewActive": "Gruppierte Ansicht nach Tags anzeigen",
    "customerSaved": "Insight Kunde wurde gespeichert.",
    "noCustomerUsers": "Keine Kundenbenutzer gefunden.",
    "statusChanged": "Status wurde geändert.",
    "userLocationsManage": "Objektzuordnung",
    "assignLocations": "Objekt",
    "locationAssigned": "Objekt wurde zugewiesen.",
    "locationRemoved": "Objekt wurde entfernt.",
    "allLocationsRemoved": "Objekte wurde entfernt.",
    "deactivateCustomer": "Kunde deaktivieren",
    "activateCustomer": "Kunde aktivieren",
    "customerDeactivated": "Kunde wurde deaktiviert.",
    "customerActivated": "Kunde wurde aktiviert.",
    "deactivateUser": "Benutzer deaktivieren",
    "confirmUserDeactivation": "Möchten Sie diesen Benutzer deaktivieren?",
    "confirmUserActivation": "Möchten Sie diesen Benutzer aktivieren?",
    "assigned": "zugewiesen",
    "removeAssignment": "Zuweisung entfernen",
    "assignmentRemoved": "Zuweisung wurde entfernt.",
    "resetPassword": "Passwort zurücksetzen",
    "resetPasswordConfirm": "Möchten Sie dem Kunden eine E-Mail Benachrichtigung schicken zum Zurücksetzen des Passworts?",
    "passwordReseted": "E-Mail versendet",
    "noAssignedLocations": "Keine zugewiesenen Objekte.",
    "removeAllLocations": "Alle Objekte entfernen",
    "removeAllLocationsConfirm": "Wollen Sie alle Objekte dieses Benutzers entfernen?",
    "createTickets": "Tickets erstellen & bearbeiten",
    "createTicketsInfo": "Hier legen Sie fest, ob und welche Tickets ihr Kunde über das Portal anlegen kann. Kundenbenutzer können nur Tickets sehen und bearbeiten, bei denen sie als Bearbeiter oder Ersteller hinterlegt sind.",
    "documentsPerLocation": "Dokumente pro Objekt",
    "documentsPerLocationInfo": "Sie können Tickets auch zur Speicherung und Anzeige von Dokumenten verwenden. Kundenbenutzer können diese Tickets dann nicht erstellen oder bearbeiten, sondern die Anhänge dieser Tickets werden als Downloads am Objekt zur Verfügung gestellt.\nWählen Sie hier aus, welche Anhänge von welchen Tickettypen als Dokumente bereitgestellt werden sollen.",
    "noBlinkActiveLicenceOrNoTicketTypes": "Damit Ihre Kunden Tickets anlegen können müssen Sie Blink Active aktivieren und Tickettypen konfigurieren.",
    "emailAlreadyInUse": "Der Benutzer konnte nicht angelegt werden. Bitte prüfen Sie, ob die E-Mailadresse bereits in Verwendung ist.",
    "showPlannedTimes": "Soll-Zeiten anzeigen",
    "showActualTimes": "Ist-Zeiten anzeigen",
    "locationStatus": "Objektstatus Anzeige & Texte",
    "locationStatusInfo": "Kunden können pro Objekt einen Statuswert sehen. Dieser wird automatisch auf Basis der Planungen, erfassten Zeiten und aktuell eingestempelten Mitarbeitern ermittelt. Wenn ein Statuswert ausgeblendet wird, dann wird stattdessen automatisch der Status “Keine Daten vorhanden” angezeigt. \n\n Der Objektstatus bezieht sich auch immer auf die Anzeige der entsprechenden Detaildaten, soweit diese freigegeben sind. Beispiel: Wenn “Keine Zeit erfasst” ausgeblendet wird, dann werden auch in der Detailansicht die rot markierten Mitarbeiter ausgeblendet.",
    "locationStatusLabel": "Statusbezeichnung",
    "locationStatusTitle": {
      "LocationCleaningPlanned": "Objekt ist eingeplant",
      "LocationCleaned": "Objekt wurde gereinigt",
      "EmployeeInLocation": "Aktuell Mitarbeiter im Objekt",
      "LocationNotCleaned": "Keine Zeit erfasst",
      "NoCleaningPlanned": "Keine Reinigung geplant",
      "NoData": "Keine Daten vorhanden"
    },
    "locationStatusInfos": {
      "LocationCleaningPlanned": "Es gibt mindestens eine Planung für das Objekt.",
      "LocationCleaned": "Es gibt mindestens eine erfasste Ist-Zeit für das Objekt.",
      "EmployeeInLocation": "Mindestens ein Mitarbeiter ist aktuell eingestempelt",
      "LocationNotCleaned": "Es gab Planungen und es wurde keine einzige Ist-Zeit erfasst.",
      "NoCleaningPlanned": "Keine Planungen für dieses Objekt vorhanden.",
      "NoData": "Dieser Status wird angezeigt, wenn andere Statuswerte deaktiviert werden."
    },
    "onlyShowAttachments": "Nur Anhänge/Dokumente anzeigen",
    "evaluation": {
      "title": "Auswertungen",
      "check": {
        "title": "Blink Check Auswertung",
        "statisticsActive": "Auswertung aktivieren",
        "statisticsActiveDescription": "Die Auswertungen zeigen ausgefüllte Blink Check\nFormulare an. Alle Auswertungen sind immer objektbezogen. Ausgefüllte Check Formulare werden nur angezeigt, wenn das Formular für ein Objekt ausgefüllt wurde, dass der jeweilige Insight Benutzer sehen kann.",
        "threshold": "Schwelle in %",
        "thresholdDescription": "Geben Sie hier einen Prozentwert an, der in den\nAuswertungen als rote Linie angezeigt wird. Wenn das\nFeld leer gelassen wird, dann wird keine rote Linie angezeigt.",
        "thresholdError": "Schwellwert darf nur zwischen 1 und 100 sein.",
        "excelExport": "Excel Export",
        "excelExportDescription": "Aktivieren, damit Kundenbenutzer die ausgefüllten Formulare als Excel herunterladen dürfen.\nHinweis: Beim Excel Export werden alle Daten eines Formulars exportiert! Auch die Daten, die evtl. im PDF nicht angezeigt werden.",
        "pdfDownload": "PDF Download",
        "pdfDownloadDescription": "Aktivieren, damit Kundenbenutzer ausgefüllte Formulare als PDF herunterladen können. Es wird das in Blink Check definierte PDF zum Download angeboten.",
        "checkForm": "Blink Check Formulare",
        "checkFormDescription": "Hier legen Sie fest, welche Formulare für diesen Kunden freigegeben werden. Formulare werden Kunden in Blink Insight immer objektbezogen angezeigt,d.h. wenn Sie hier ein Formular freischalten können Insight Benutzer immer nur die Formulare sehen, die für das Objekt ausgefüllt wurden, das die Benutzer sehen können."
      },
      "ticket": {
        "title": "Ticket Auswertung",
        "statisticActive": "Auswertungen aktivieren",
        "statisticActiveDescription": "Die Auswertungen zeigen erstellte Tickets an. Es werden nur erledigte Tickets angezeigt. Es werden nur Tickettypen angezeigt, die für den Kunden freigeschaltet sind.",
        "setGrouping": "Gruppierung festlegen",
        "setGroupingDescription": "Die Tickets werden nach Lösungszeit in Tagen ausgewertet. Sie können festlegen, wieviele Tage in einer Gruppe zusammengefasst werden sollen",
        "addGroup": "Gruppe hinzufügen",
        "group": "Gruppe",
        "groupTitle": "Beschreibung",
        "fromDays": "Von (Tage)",
        "toDays": "Bis (Tage)"
      }
    }
  },
  "noResults": "Keine Ergebnisse",
  "quillEditorLinkWithoutSelection": "Sie müssen Text markieren um einen Link zu erstellen.",
  "linksShouldStartByHttp": "Links müssen mit 'http://' oder 'https://' beginnen.",
  "showEmployees": "Mitarbeiter anzeigen",
  "home": "Home",
  "profile": "Profil",
  "baseData": "Stammdaten",
  "preview": {
    "durationFormat": "Formatierung Soll- und Ist-Zeiten im Stunden- und Minuten-Format",
    "durationFormatInfo": "Hiermit können Sie das Eingabe- und Anzeige-Format von Soll- und Ist-Zeiten auf Stunden und Minuten aktivieren z.B. 1,25 h werden dann als 1h 15m angezeigt.\nAktuell ist diese Formatierung nur in den Dialogen Zeit Splitten, Planung Splitten, Zeit nachtragen, Arbeitszeit bearbeiten und Planung bearbeiten nutzbar."
  },
  "barcodeScanner": {
    "permissionHeader": "Berechtigung",
    "permissionMessage": "Wenn Sie die Berechtigung zur Verwendung Ihrer Kamera erteilen möchten, aktivieren Sie diese in den App-Einstellungen.",
    "scanPrompt": "{{thing}} scannen",
    "enterPrompt": "{{thing}} eingeben",
    "blinkId": "Blink-ID",
    "isNoBlinkId": "Der gescannte QR-Code ist keine gültige Blink-ID",
    "objectCode": "Objekt Code"
  },
  "timelimit": {
    "type": "Befristungstyp",
    "types": "Befristungstypen",
    "editType": "Befristungstyp bearbeiten",
    "createType": "Befristungstyp anlegen",
    "deleteType": "Befristungstyp löschen",
    "deleteTypeConfirm": "Möchten sie den Befristungstyp wirklich löschen?",
    "deleteTimeLimit": "Mitarbeiterbefristung löschen",
    "deleteTimeLimitConfirm": "Möchten sie die Mitarbeiterbefristung wirklich löschen?",
    "typeDeleted": "Befristungstyp wurde gelöscht.",
    "priority": "Priorität",
    "information": "Informativ",
    "critical": "Kritisch",
    "remindTeamleader": "Vorabbenachrichtigung",
    "remindTeamleaderInfo": "Die Einstellungen der Vorabbenachrichtigung ist für die Einplanung, Zeitenfreigabe und Ticketerstellung relevant.",
    "remindUnit": "Wann benachrichtigen",
    "remindBefore": "vorher benachrichtigen",
    "createTask": "Ticket anlegen",
    "taskType": "Tickettyp",
    "taskCreation": "Anlage des Tickets",
    "taskOwner": "Ticket zuweisen an",
    "taskComment": "Ticket Kommentar",
    "days": "Tage",
    "weeks": "Wochen",
    "month": "Monat",
    "atRemindDate": "bei Benachrichtigung",
    "atValidToDate": "am letzten Tag der Gültigkeit",
    "employee": "Mitarbeiter",
    "teamlead": "Objektleiter",
    "validFrom": "Gültig ab",
    "validTo": "Gültig bis",
    "expired": "Mitarbeiterbefristung '{{name}}' ist am {{date}} abgelaufen.",
    "willExpire": "Mitarbeiterbefristung '{{name}}' wird am {{date}} ablaufen.",
    "employeeEdit": "Mitarbeiterbefristung bearbeiten",
    "employeeCreate": "Mitarbeiterbefristung anlegen",
    "typeCreated": "Befristungstyp wurde angelegt",
    "typeEdited": "Befristungstyp wurden gespeichert",
  },
  "camera": {
    "promptLabelHeader": "Quelle wählen",
    "promptLabelPicture": "Foto aufnehmen",
    "promptLabelPhoto": "Aus Galerie auswählen"
  },
  "planningSplit": {
    "planningSplit": "Planung splitten",
    "chooseHowToAdjustPeriod": "Wählen Sie aus, wie der Zeitraum angepasst werden soll.",
    "timeFrame": "Arbeiten zur gleichen Zeit",
    "timeFrameHint": "Planungen werden vom Typ “Dauer mit Zeitfenster” angelegt. Der ursprüngliche Zeitraum wird für alle Planungen übernommen.",
    "duration": "Uhrzeit nicht relevant",
    "durationHint": "Planungen werden vom Typ “Nur Dauer” angelegt. Der ursprüngliche Zeitraum wird nicht übernommen.",
    "timeSpan": "Arbeiten zeitlich versetzt",
    "timeSpanHint": "Planungen werden vom Typ “Exakte Zeitvorgabe” angelegt. Der ursprüngliche Zeitraum wird so aufgeteilt, dass die Planungen nacheinander beginnen.",
    "planningAInfo": "Wählen Sie aus, in wieviele Einzelplanungen die Planung aufgeteilt werden soll. Die Gesamtzeit bleibt erhalten.",
    "planningABreaktimeInfo": "Beim Splitten wird die Pausenzeit nicht übernommen.",
    "planningATrimInfo": "Beim Splitten wird die Pauschale nicht übernommen.",
    "planningABreaktimeAndTrimInfo": "Beim Splitten wird die Pausenzeit und die Pauschale nicht übernommen.",
    "planningA": "{{amount}} Planungen à {{duration}}"
  },
  "planningErrors": {
    "startAtBeforeEndAt": "Bis darf nicht kleiner/gleich Von sein.",
    "breakDurationMinutesAndDurationMinutesNotNegative": "inkl. Pause und Soll nicht negativ.",
    "durationMinutesGreaterZero": "Soll muss größer 0 sein.",
    "startAtEndAtDifferenceShouldContainBreakDurationMinutesAndDurationMinutes": "Differenz zwischen Von und Bis muss mindestens Pause + Soll umfassen.",
    "inUse": "Planung ist bereits in einer Arbeitszeit berücksichtigt und kann nicht bearbeitet werden."
  },
  "stickers": {
    "title": "Sticker",
    "employeeHint": "Sticker können nur von Mitarbeitern gelesen werden, die entweder den Blink Manager oder die Blink me App verwenden.",
    "myStickers": "Meine Sticker",
    "sendedStickers": "Gesendete Sticker",
    "createTitle": "Sticker senden",
    "readed": "gelesen",
    "notReaded": "noch nicht gelesen",
    "receiver": "Empfänger",
    "text": "Betreff",
    "textArea": "Text",
    "emptyState": "Du hast leider noch keine Sticker",
    "sender": "Absender",
    "senderDescription": "Dieser Sticker wurde dir gesendet von {{sender}} am {{date}}",
    "sendSticker": "Sticker versenden",
    "confirmDelete": "Wollen Sie diese Sticker wirklich löschen?"
  },
  "toolsense": {
    "title": "Maschinen",
    "siteId": "Site ID",
    "siteIdInfo": "Sie finden die Site ID in der URL im ToolSense Portal, wenn Sie die Eigenschaften der Maschine aufrufen.",
    "erpId": "ERP ID",
    "erpIdInfo": "Dieses Feld ist optional. Sie finden die ERP ID in den Eigenschaften der Maschine im ToolSense Portal",
    "category": "Kategorie",
    "brand": "Hersteller",
    "type": "Typ",
    "serialNo": "Seriennr.",
    "warning": "Wartung",
    "availability": {
      "READY_TO_USE": "Betriebsbereit",
      "DAMAGED": "Defekt",
      "IN_REPAIR": "In Reparatur",
      "RETIRED": "Ausgeschieden",
      "NEEDS_APPROVAL": "Freigabe nötig",
      "WAREHOUSE": "Im Lager",
      "ORDERED": "Bestellt"
    }
  },
  "statistics": {
    "allSelected": "Alle ausgewählt",
    "allTeamleaders": "Alle Vorgesetzte",
    "allAreas": "Alle Bereiche",
    "NoData": "Keine Daten vorhanden",
    "deviceTypes": {
      "App": "Blink Time",
      "BlinkTimeMobileWeb": "Mobile Web",
      "ImportService": "Excel import",
      "ExternalSystem": "Externes System",
      "Web": "Blink Manager (Web)",
      "ManagerApp": "Blink Manager",
      "BlinkMe": "Blink me",
      "BlinkMeWeb": "Blink me (Web)",
      "BlinkMeApp": "Blink me",
      "BlinkCheckApp": "Blink Check",
      "BlinkCheckWeb": "Blink Check (Web)",
      "BlinkManagerApp": "Blink Manager",
      "BlinkManagerWeb": "Blink Manager (Web)",
      "BlinkInsightWeb": "Blink Insight (Web)",
      "Terminal": "Terminal",
      "Telephone": "Telefon",
      "AzureFunction": "Externe Funktion",
      "Others": "Others"
    },
    "employee": {
      "appsUsed": "Verwendete Apps",
      "lastTimeTracking": "Letzte Zeiterfassung",
      "noAppsUsed": "Keine Apps in den letzten 3 Monaten verwendet",
      "noTimeTracking": "Keine Zeiten in den letzten 3 Monaten erfasst"
    },
    "timeTrackingMode": {
      "Unkown": "Unbekannt",
      "Proposed": "Sollzeiten bestätigen",
      "StartStop": "Start / Stop",
      "ManualTimeTracking": "Manuelle Zeiterfassung",
      "AutoTimeTracking": "Automatische Zeiterfassung"
    },
    "page": {
      "title": "Statistiken über Nutzungsverhalten",
      "description": "Hier finden Sie Auswertungen zur Nutzung der Apps und Zeiterfassungsmethoden durch die Mitarbeiter, dargestellt nach Vorgesetztem, Bereich oder nach Mitarbeiter.",
      "overviewLabel": "Übersicht",
      "overview": {
        "TimeTrackings": "Verteilung der Zeiterfassungsmethoden in %",
        "AuthorizationApps": "Verteilung der Nutzung der Apps in % ",
        "AuthorizationPlatforms": "Verteilung der App Nutzung nach Betriebssysteme in %",
        "subtitle": "Auswertung der letzten 2 Wochen"
      }
    },
    "deviceUsageTypes": {
      "label": "Statistik",
      "TimeTrackings": "Zeiterfassungsmethoden",
      "AuthorizationApps": "Nutzung der Apps"
    },
    "sort": {
      "AppAsc": "App aufsteigend",
      "AppDesc": "App absteigend",
      "BlinkTimeAsc": "Blink Time aufsteigend",
      "BlinkTimeDesc": "Blink Time absteigend",
      "BlinkTimeMobileWebAsc": "Mobile Web aufsteigend",
      "BlinkTimeMobileWebDesc": "Mobile Web absteigend",
      "WebAsc": "Blink Manager (Web) aufsteigend",
      "WebDesc": "Blink Manager (Web) absteigend",
      "ImportServiceAsc": "Excel import aufsteigend",
      "ImportServiceDesc": "Excel import absteigend",
      "ExternalSystemAsc": "Externes System aufsteigend",
      "ExternalSystemDesc": "Externes System absteigend",
      "ManagerAppAsc": "Blink Manager (Web) aufsteigend",
      "ManagerAppDesc": "Blink Manager (Web) absteigend",
      "BlinkMeAsc": "Blink me aufsteigend",
      "BlinkMeDesc": "Blink me absteigend",
      "BlinkMeWebAsc": "Blink me (Web) aufsteigend",
      "BlinkMeWebDesc": "Blink me (Web) absteigend",
      "BlinkMeAppAsc": "Blink me aufsteigend",
      "BlinkMeAppDesc": "Blink me absteigend",
      "BlinkCheckAppAsc": "Blink Check aufsteigend",
      "BlinkCheckAppDesc": "Blink Check absteigend",
      "BlinkCheckWebAsc": "Blink Check (Web) aufsteigend",
      "BlinkCheckWebDesc": "Blink Check (Web) absteigend",
      "BlinkManagerAppAsc": "Blink Manager aufsteigend",
      "BlinkManagerAppDesc": "Blink Manager absteigend",
      "BlinkManagerWebAsc": "Blink Manager (Web) aufsteigend",
      "BlinkManagerWebDesc": "Blink Manager (Web) absteigend",
      "BlinkInsightWebAsc": "Blink Insight (Web) aufsteigend",
      "BlinkInsightWebDesc": "Blink Insight (Web) absteigend",
      "TerminalAsc": "Terminal aufsteigend",
      "TerminalDesc": "Terminal absteigend",
      "TelephoneAsc": "Telefon aufsteigend",
      "TelephoneDesc": "Telefon absteigend",
      "AzureFunctionAsc": "Externe Funktion aufsteigend",
      "AzureFunctionDesc": "Externe Funktion absteigend",
      "OtherAsc": "Sonsitges aufsteigend",
      "OtherDesc": "Sonsitges absteigend",
      "TeamleaderAsc": "Vorgesetzter aufsteigend",
      "TeamleaderDesc": "Vorgesetzter absteigend",
      "AreaAsc": "Bereich aufsteigend",
      "AreaDesc": "Bereich absteigend",
      "EmployeeAsc": "Mitarbeiter aufsteigend",
      "EmployeeDesc": "Mitarbeiter absteigend"
    },
    "authorizationPlatforms": {
      "Other": "Sonstiges",
      "Web": "Web",
      "iOS": "iOS",
      "Android": "Android"
    }
  },
  "timeApproval": {
    "location": "Objektname",
    "costCenterNumber": "Kst.Nr.",
    "emptyState": "Keine Zeiten zur Freigabe vorhanden",
    "statuses": {
      "NotSet": "Alles anzeigen",
      "NotAllApproved": "Wartet auf Freigabe",
      "AllApproved": "Freigegeben",
      "NoWorklogs": "Keine Zeiten erfasst"
    },
    "widget": {
      "forTimeRange": "für den gewählten Zeitraum",
      "locationsReleased": "Objekte freigegeben:",
      "allLocationsReleased": "Alle Objekte freigegeben",
      "employeesReleased": "Mitarbeiter freigegeben:",
      "allEmployeesReleased": "Alle Mitarbeiter freigegeben"
    },
    "sort": {
      "ApprovedPercentageDesc": "Freigegeben absteigend",
      "ApprovedPercentageAsc": "Freigegeben aufsteigend",
      "NameDesc": "Name absteigend",
      "NameAsc": "Name aufsteigend",
      "CostCenterDesc": "Kst.Nr. absteigend",
      "CostCenterAsc": "Kst.Nr. aufsteigend",
      "ManagersLastNameDesc": "Objektleiter absteigend",
      "ManagersLastNameAsc": "Objektleiter aufsteigend",
      "LocationPlanningEntriesTotalDurationMinutesDesc": "KV absteigend",
      "LocationPlanningEntriesTotalDurationMinutesAsc": "KV aufsteigend",
      "PlanningEntriesTotalDurationMinutesDesc": "Soll absteigend",
      "PlanningEntriesTotalDurationMinutesAsc": "Soll aufsteigend",
      "WorklogTotalDurationMinutesDesc": "Ist absteigend",
      "WorklogTotalDurationMinutesAsc": "Ist aufsteigend",
      "LastNameAsc": "Nachname aufsteigend",
      "LastNameDesc": "Nachname absteigend",
      "NumberAsc": "Nummer aufsteigend",
      "NumberDesc": "Nummer absteigend",
      "TeamLeadersAsc": "Vorgesetzter aufsteigend",
      "TeamLeadersDesc": "Vorgesetzter absteigend",
      "WorkloadTotalDurationMinutesAsc": "WAZ aufsteigend",
      "WorkloadTotalDurationMinutesDesc": "WAZ absteigend"
    },
    "durationInfo": {
      "locationDeviationPositive": "Ist-Zeit überschreitet Kontrollvorgabe",
      "locationDeviationNegative": "Ist-Zeit unterschreitet Kontrollvorgabe",
      "planningEntryDeviationPositive": "Ist-Zeit überschreitet Soll-Zeit ",
      "planningEntryDeviationNegative": "Ist-Zeit unterschreitet Soll-Zeit",
      "employeeDeviationPositive": "Ist-Zeit überschreitet Wochenarbeitszeit",
      "employeeDeviationNegative": "Ist-Zeit unterschreitet Wochenarbeitszeit"
    }
  }
}
