import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlinkEmployee, BlinkEmployeeApi } from '@blink/api';
import { GlobalTranslateService, trackById } from '@blink/util';

@Component({
  selector: 'check-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnDestroy {
  @ViewChild('searchRow') searchRowObj;
  trackById = trackById;

  subscriptions = new Subscription();

  searchTerm$ = new BehaviorSubject<string>('');
  showInactive$ = new BehaviorSubject(false);
  employees$ = combineLatest([
    this.employeeApi.getEmployeesForCheck(),
    this.searchTerm$,
    this.showInactive$
  ]).pipe(map(([employees, searchTerm, showInactive]) =>
    employees.filter(x => {
        return x.displayName.toLowerCase().includes(searchTerm.toLowerCase());
      }
    ).filter(x => showInactive || x.LoginUser.Active).sort((a, b) => a.displayName.localeCompare(b.displayName)))
  );

  public t = inject(GlobalTranslateService);

  constructor(public modalController: ModalController,
              private employeeApi: BlinkEmployeeApi) {
  }

  search(event) {
    this.searchTerm$.next(event.detail.value);
  }

  onEmployeeSelected(employee: BlinkEmployee) {
    this.modalController.dismiss(employee, 'employeeSelected');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleShowInactive(ev) {
    this.showInactive$.next(ev.detail.checked);
  }
}
