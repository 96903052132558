import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { of, ReplaySubject, Subject } from 'rxjs';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  selectMany,
  updateEntities,
  updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { checkStoreConfig, IdMap, idMapper, removeKeys, removeMappedId } from '../utils-temp';

import { CheckCategoryDependency } from './category-dependency';
import { CheckItem } from '../item/item';
import { checkDependency } from '../check-dependency';
import { PersistStateService } from '../../shared';

interface CategoryDependencyRepoProps {
  checklistCategoryDependencyIdMap: IdMap;
  categoryDependenciesIdMap: IdMap;
}

const store = createStore(
  checkStoreConfig('categoryDependencies'),
  withEntities<CheckCategoryDependency, 'Id'>({ idKey: 'Id' }),
  withProps<CategoryDependencyRepoProps>({
    checklistCategoryDependencyIdMap: {},
    categoryDependenciesIdMap: {}
  })
);

@Injectable({ providedIn: 'root' })
export class CheckCategoryDependencyRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();
  categoryAppeared$ = new Subject<number>();

  constructor(private persistStateService: PersistStateService) {
    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(dependencies: CheckCategoryDependency[], isCompleteUpdate: boolean, removeAbsentForChecklistId: number = null) {
    const deleteCategoryDependencyIds = store
      .getValue()
      .checklistCategoryDependencyIdMap[removeAbsentForChecklistId];

    store.update(
      deleteEntities(deleteCategoryDependencyIds),
      upsertEntities(dependencies),
      idMapper(
        'checklistCategoryDependencyIdMap',
        dependencies,
        'ChecklistId',
        isCompleteUpdate
      ),
      idMapper(
        'categoryDependenciesIdMap',
        dependencies,
        'CategoryId',
        isCompleteUpdate
      )
    );
  }

  delete(id: number) {
    store.update(
      deleteEntities(id),
      removeMappedId('categoryDependenciesIdMap', id)
    );
  }

  deleteEntitiesOfChecklists(checklistIds: number[]) {
    const checklistCategoryDependencyIdMap = store.getValue().checklistCategoryDependencyIdMap;
    let deleteCategoryDependencyIds = [];
    checklistIds.forEach(x => {
      deleteCategoryDependencyIds = deleteCategoryDependencyIds.concat(checklistCategoryDependencyIdMap[x]);
    });
    store.update(
      deleteEntities(deleteCategoryDependencyIds),
      removeKeys('checklistCategoryDependencyIdMap', checklistIds)
    );
  }

  getMany(ids: number[]) {
    if (ids) {
      return store.pipe(selectMany(ids));
    }
    return of([]);
  }

  getCategoryDependencies(categoryId: number) {
    return store.pipe(
      select(state => state.categoryDependenciesIdMap[categoryId]),
      map(categoryDependencyIds => categoryDependencyIds ? categoryDependencyIds : []),
      switchMap(categoryDependencyIds => this.getMany(categoryDependencyIds))
    );
  }

  checkDependency(dependencyId: number, dependencyItem: CheckItem, value: any) {
    const dependency = store.getValue().entities[dependencyId];
    if (!dependency) {
      return;
    }
    const fulfilled = checkDependency(dependency, dependencyItem, value);

    if (!dependency.fulfilled && fulfilled) {
      this.categoryAppeared$.next(dependency.CategoryId);
    }

    store.update(updateEntities(
      dependencyId,
      dep => ({ ...dep, fulfilled })
    ));
  }

  resetDependenciesForChecklist(checklistId: number) {
    store.update(
      updateEntitiesByPredicate(
        dep => dep.ChecklistId === checklistId,
        { fulfilled: false }
      )
    );
  }

  resetDependenciesForItems(itemIds: number[]) {
    store.update(
      updateEntitiesByPredicate(
        x => {
          return itemIds.includes(x.DependencyId)
        },
        { fulfilled: false }
      )
    );
  }
}
