import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BarcodeScannerService } from '@blink/capacitor-plugins';
import { AuthApi, LoginParams } from '@blink/api';
import {
  AuthModeEnum,
  BlinkIdValidator,
  ENV_PROD,
  GlobalTranslateService,
  logComponent,
  validateBlinkCode
} from '@blink/util';

import { LoginService } from '../login.service';

@Component({
  selector: 'blink-id-flow',
  templateUrl: './blink-id-flow.html',
  styleUrls: ['./blink-id-flow.scss']
})
export class BlinkIdFlowComponent {
  currentView: 'selectMethod' | 'enterManually' = 'selectMethod'; // scan code happens programmatically
  form: FormGroup;
  disabled = false;

  constructor(public alertCtrl: AlertController,
              public t: GlobalTranslateService,
              public translate: TranslateService,
              @Inject(ENV_PROD) private environmentProd: boolean,
              public authApi: AuthApi,
              private loginService: LoginService,
              private barcodeScanner: BarcodeScannerService,
              private fb: FormBuilder) {
    logComponent(this)

    this.form = this.fb.group({
      blinkId: ['', [Validators.required, BlinkIdValidator]],
      code: ['', [Validators.required, validateBlinkCode]]
    });

    if (!this.environmentProd) {
      this.form.patchValue({
        blinkId: '500-003-19',
        code: 'dev-rene-8262-5996-2147-7705-4635'
      });
    }
  }

  get blinkIdEmpty() {
    const blinkId = this.form.value.blinkId;
    return blinkId === '' || blinkId === '--';
  }

  startScanner() {
    this.barcodeScanner.scanBlinkId()
      .then((result) => {
        if (result) {
          this.loginService.login(result);
        }
      });
  }

  tryLogin() {
    if (this.form.valid && !this.disabled) {
      this.login();
    }
  }

  login() {
    this.disabled = true;
    const regex = /(^([A-Za-z-]|[0-9][a-zA-Z-])*)/;

    const oneTimePassword = this.form.value.code;
    const companyFromOneTimePassword = oneTimePassword.match(regex);

    const password = oneTimePassword.replace(regex, '');
    const company = companyFromOneTimePassword[0].substring(0, companyFromOneTimePassword[0].length - 1);

    const loginParams: LoginParams = {
      system: company,
      blinkId: this.form.value.blinkId,
      password: password,
      authMode: AuthModeEnum.SIMPLE
    };

    this.loginService.login(loginParams);

    this.disabled = false;
  }

}
