export default {
  barcodeScanner: {
    permissionHeader: 'Permission',
    permissionMessage: 'If you want to give permission to use your camera, enable it in the app settings.',
    scanPrompt: 'Scan {{thing}}',
    enterPrompt: 'Enter {{thing}}',
    blinkId: 'Blink ID',
    isNoBlinkId: 'The scanned QR code is not a valid Blink ID',
    objectCode: 'Object Code'
  },
  camera: {
    promptLabelHeader: 'Select source',
    promptLabelPicture: 'Take photo',
    promptLabelPhoto: 'Select from gallery'
  },
  network: {
    changed: {
      connected: 'Internet connection established',
      disconnected: 'Internet connection interrupted'
    }
  },
  file: { downloadStarted: 'Download was started.' },
  nfc: {
    wrongCode: 'The scanned QR code is not an object code!',
    error: 'NFC error'
  }
};
