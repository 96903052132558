export default {

  config: {
    checklist: {
      checklist: 'Formularvorlage',
      checklists: 'Formularvorlagen',
      none: 'Keine Formulare vorhanden.',
      new: 'Neue Formularvorlage',
      edit: 'Formularvorlage bearbeiten',
      saved: 'Formularvorlage wurde gespeichert.',

      delete: 'Formularvorlage löschen',
      deleteChecklistResults: 'Alle ausgefüllten Formulare der Formularvorlage {{name}} ebenfalls löschen?',
      deleteInfo: 'Wenn Sie die Formularvorlage löschen, werden ebenfalls alle ausgefüllten Formulare unwiderruflich gelöscht! <br><br>Um die ausgefüllten Formulare zu behalten, deaktivieren Sie die Formularvorlage.',
      deleted: 'Formularvorlage wurde gelöscht.',

      duplicate: 'Formularvorlage duplizieren',
      duplicateInfo: 'Mit der Duplizierungsfunktion können Sie Formularvorlage duplizieren',
      duplicateMessage: 'Möchten Sie die Formularvorlage "{{checklistName}}" mit allen Inhalten duplizieren?',
      duplicated: 'Formularvorlage wurde dupliziert.',

      templateName: 'Formular-Name',
      activeInfo: 'Nur aktive Vorlagen können ausgefüllt werden, inaktive Vorlagen können nur in der Vorlagenverwaltung bearbeitet werden.',
      locationRequired: 'Objektauswahl Pflicht',
      locationRequiredInfo: 'Wenn aktiviert, dann muss für das Ausfüllen des Formulars zwingend ein Objekt ausgewählt bzw. der QR Code gescannt werden.',
      loginUserRequired: 'Mitarbeiterauswahl Pflicht',
      loginUserRequiredInfo: 'Wenn aktiviert, dann muss für das Ausfüllen des Formulars zwingend ein Mitarbeiter ausgewählt werden.',
      allLocationAllowed: 'Alle Objekte erlauben',
      allowOnlyCertainLocations: 'Nur bestimmte Objekte erlauben',
      public: 'Öffentliches Formular',
      publicInfo: 'Erzeugen Sie einen öffentlichen Link mit dem das Formular von jedem beliebigen Anwender ausgefüllt werden kann. Es ist kein Login und keine App notwendig. Sie können ein Gültigkeitsdatum angeben bis wann der Link gültig sein soll.',
      preview: 'Vorschau aktivieren',
      previewInfo: 'Wenn aktiviert, dann wird im unteren Bereich des Formulars beim Ausfüllen immer ein “Vorschau” Button angezeigt. Alternativ zum dauerhaften Vorschau Button können Sie auch das Inhaltselement “Vorschau” an einer beliebigen Stelle vom Formular platzieren (dazu muss diese Funktion nicht aktiviert werden).',
      shortUrlExpireDate: 'Ablaufdatum',

      sysadminOnly: 'Nur Systemadmins',
      allUsers: 'Alle Benutzer',
      usersOfPermissionGroup: 'Benutzer dieser Berechtigungsgruppen',

      emailConfig: 'E-Mail Konfiguration',
      emailConfigInfo: 'Hinterlegen Sie hier E-Mail Adressen, dann werden ausgefüllte Formulare automatisch an diese E-Mail Adressen geschickt.',
      emailSaved: 'E-Mail Adresse wurde gespeichert',
      emailRemoved: 'E-Mail Adresse wurde gelöscht',

      importExportInfo: 'Mit der Exportfunktion können Sie Formularvorlagen und zugehörige Berichtsvorlagen exportieren, um diese in einem anderen Blink Check Mandant oder System wieder zu importieren.',
      import: 'Formularvorlage importieren',
      importInfo: 'Bitte wählen Sie die .check-b64 Datei der Formularvorlage, die Sie importieren möchten.',
      imported: 'Formular wurde erfolgreich angelegt.',
      templatesInAppFolder: 'Vorlagen im Appverzeichnis:',
      invalidImport: 'Gewählte Datei ist keine gültige Formularvorlage.',
      exported: 'Formularvorlage wurde exportiert',
      offline: 'Bitte stellen Sie eine Internet-Verbindung her, um Formularvorlagen bearbeiten zu können.',
      checklistContainsCreateTask: "Formularvorlage enthält Element ‘Ticket anlegen’ und kann daher nicht auf öffentlich gesetzt werden. Bitte entfernen Sie das Element ‘Ticket anlegen’."
    },
    category: {
      category: 'Kategorie',
      categories: 'Kategorien',
      saved: 'Kategorie wurde gespeichert.',
      editTitle: 'Kategorie bearbeiten',
      content: 'Elemente',
      newCategory: 'Neue Kategorie',
      name: 'Name der Kategorie',
      edit: 'Kategorie bearbeiten',
      duplicated: 'Kategorie wurde dupliziert.',
      noCategories: 'Es sind noch keine Kategorien vorhanden. Legen Sie neue Kategorien über den "Kategorie hinzufügen" - Button an.',
      inactive: 'inaktiv',
      showInactive: 'Inaktive anzeigen',
      duplicateCategoryConfirmHeader: 'Kategorie duplizieren',
      duplicateCategoryConfirm: 'Möchten Sie die Kategorie "{{categoryName}}" mit allen Inhalten duplizieren?',
      delete: 'Kategorie löschen',
      deleteCategoryConfirm: 'Wollen Sie die Kategorie "{{categoryName}}" wirklich löschen?',
      deleteSuccess: 'Kategorie wurde entfernt.'
    },
    group: {
      group: 'Seite',
      groups: 'Seiten',
      created: 'Die Seite wurde angelegt.',
      updated: 'Die Änderungen an der Seite wurden gespeichert.',
      updateError: 'Seite konnte nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.',
      rename: 'Seite umbenennen',
      saved: 'Seite wurde gespeichert.',
      toggleSort: 'Sortierung ändern',
      editGroupName: 'Seitenname bearbeiten',
      new: 'Neue Seite',
      name: 'Name der Seite',
      duplicated: 'Seite wurde dupliziert.',
      noGroups: 'Es sind noch keine Seiten vorhanden. Legen Sie neue Seiten über den "Seite hinzufügen" - Button an.',
      noItems: 'Es sind noch keine Elemente vorhanden. Legen Sie neue Elemente über den "Element hinzufügen" - Button an.',
      add: 'Seite hinzufügen',
      infoElement: 'Info-Element',
      inputElement: 'Eingabe-Element',
      addInfoElement: 'Info-Element hinzufügen',
      addInputElement: 'Eingabe-Element hinzufügen',
      delete: 'Seite löschen',
      deleteGroupConfirm: 'Wollen Sie die Seite "{{groupName}}" wirklich löschen?',
      chooseGroup: 'In welche Seite soll das Element hinzugefügt werden?',
      duplicateGroupConfirmHeader: 'Seite duplizieren',
      duplicateGroupConfirm: 'Möchten Sie die Seite "{{groupName}}" mit allen Inhalten duplizieren?',
      deleteSuccess: 'Seite wurde entfernt.'
    },
    item: {
      add: 'Element hinzufügen',
      created: 'Element wurde erstellt',
      saved: 'Element wurde gespeichert.',
      chooseItemType: 'Element auswählen',
      values: 'Werte',
      value: 'Wert',
      ratingNumber: 'Bewertung',
      addItemValue: 'Neuen Wert hinzufügen',
      addItemValueSuccess: 'Neuer Wert wurde hinzugefügt',
      updateItemValueSuccess: 'Wert wurde aktualisiert',
      removeItemValueSuccess: 'Wert wurde entfernt',
      removeItemValue: 'Wert entfernen',
      takePictureButtonText: 'Bild wählen',
      editTitle: 'Element bearbeiten',
      editDependenciesTitle: 'Abhängigkeiten bearbeiten',
      required: 'Pflichtfeld',
      name: 'Name des Elements',
      description: 'optionale Beschreibung des Elements',
      points: 'Punkte',
      duplicated: 'Element wurde dupliziert.',
      duplicateItemConfirmHeader: 'Element duplizieren',
      duplicateItemConfirm: 'Möchten Sie das Element "{{itemName}}" mit allen Inhalten duplizieren?',
      delete: {
        title: 'Element löschen',
        message: 'Wollen Sie das Element “{{itemName}}" wirklich löschen?',
        success: 'Element wurde entfernt'
      },
      moveItem: 'Element verschieben',
      move: 'Verschieben',
      moveItemInfo: 'Bitte wählen Sie die Kategorie und die Seite aus, auf die das aktuelle Element verschoben werden soll.',
      itemMoved: 'Element wurde verschoben'
    },
    editItem: {
      name: 'Name',
      nameInfo: 'Dieser Text wird direkt vor dem Eingabeelement angezeigt.',
      link: 'Linkadresse',
      linkInfo: 'Diese Adresse wird über einen Button erreichbar sein. Bitte geben Sie eine gültige URL ein.',
      description: 'Beschreibung',
      descriptionInfo: 'Falls Sie Hinweise für das Ausfüllen geben möchten, können Sie diese hier eintragen.',
      linkDescription: 'Button Bezeichnung',
      linkDescriptionInfo: 'Geben Sie eine Bezeichnung für den Button ein.',
      values: 'Werte',
      valuesInfo: 'Für jeden Wert können Sie eine Bewertungszahl angeben. An Hand der Bewertungszahl können Sie später Auswertungen vornehmen. Die Bewertungszahl ist optional.',
      value: 'Wert',
      valuePlaceholder: 'Neuer Wert',
      ratingNumber: 'Bewertung',
      requiredField: 'Pflichtfeld',
      requiredFieldInfo: 'Wenn als Pflichtfeld aktiviert, dann muss dieses Feld gefüllt sein, damit das Formular abgeschickt werden kann.',
      externalNo: 'Externe Nummer',
      externalNoInfo: 'Optionale Angabe für Schnittstellen oder Auswertungen über externe Systeme.',
      externalNoError: 'Nur Buchstaben und Zahlen erlaubt.',
      editable: 'Bearbeitbar',
      editableInfo: 'Damit Benutzer Eingaben vornehmen können muss das Element bearbeitbar sein.',
      defaultValue: 'Standardwert vorgeben',
      defaultValueInfo: 'Sie können eine Standardbelegung für dieses Element vorgeben.',
      defaultValueInfoEditable: 'Der Wert kann vom Benutzer des Formulares geändert werden.',
      visible: 'Sichtbarkeit',
      visibleInfo: 'Die Sichtbarkeit steuert, ob das Element vom Bearbeiter gesehen werden kann oder nicht.',
      externalNoAleradyUsed: 'Diese Externe Nummer wird bereits verwendet.',
      taskTypeId: 'Ticket-Typ',
      loginUserId: 'Benutzer',
      locationId: 'Objektname',
      videoId: 'Video Nummer',
      videoType: 'Video Service',
      videoTypes_youtube: 'YouTube',
      videoType_vimeo: 'Vimeo',
      videoInfo: 'Tragen Sie hier die eindeutige Video ID ein. Sie finden diese z.B. in der URL vom Video. <br/>Beispiel: https://www.youtube.com/watch?v=XXXXX oder bei Vimeo https://vimeo.com/XXXXX. <br/>In diesem Fall müsste das XXXXX in das Feld eingetragen werden.',
      defaultTaskTitle: 'Vorgegebener Titel des Tickets',
      defaultTaskDescription: 'Vorgegebene Beschreibung des Tickets',
      titleEditable: 'Titel des Tickets vom Benutzer bearbeitbar',
      descriptionEditable: 'Beschreibung des Tickets vom Benutzer bearbeitbar',
      dueDateEditable: 'Fälligkeitsdatum des Tickets vom Benutzer bearbeitbar',
      addReportAttachment: 'Ausgefülltes Formular als PDF an Ticket anhängen',
      noTaskTypesFound: 'Es konnten keine Ticket-Typen gefunden werden. Die Ticket-Typen können über Blink Active verwaltet werden.',
      noLoginUsersFound: 'Es konnten keine Benutzer gefunden werden. Die Benutzer können über den Blink Manager verwaltet werden.',
      noLocationsFound: 'Es konnte keine Objekte gefunden werden. Die Objekte können über den Blink Manager verwaltet werden.',
      createTaskItemInfo: 'Mit dieser Funktion können Sie Blink Active Tickets anlegen. Sie können Titel und Beschreibung vorgeben oder vom Benutzer ausfüllen lassen. Das Objekt wird automatisch zugeordnet, als Bearbeiter wird der zuständige Objektleiter zugewiesen.',
      duplicatedValuesNotAllowed: 'Doppelte Werte sind nicht erlaubt.',
      valueLengthExceeded: 'Der eingegebene Wert ist zu lang und wird gekürtzt.'
    },
    itemTypes: {
      PreviewButton: "Dokumentenvorschau",
      Select: "Auswahl aus Liste",
      Signature: "Unterschrift",
      Email: "E-Mail Eingabe",
      SendEmailTo: "E-Mail Versand",
      Date: "Datum",
      Time: "Uhrzeit",
      Boolean: "Ja / Nein - Frage",
      TakePicture: "Bild aufnehmen",
      TakeMultiPicture: "Bilder aufnehmen",
      Scan: "Qr-/ EAN-Scan",
      Input: "Texteingabe",
      Textarea: "Mehrzeilige Texteingabe",
      Link: "Verlinkung",
      Picture: "Bild Anzeige",
      Video: "Video Anzeige",
      Text: "Text Anzeige",
      Header: "Überschrift",
      Rate: "Bewertung",
      RateSmiley: "Smiley Bewertung",
      CreateTask: "Ticket anlegen",
      FileUpload: "Dateien hochladen",
      Currency: "Währung",
    },
    itemTypeDescriptions: {
      PreviewButton: "Zeigen sie eine Vorabversion des Formulars an.",
      Select: "Definieren Sie beliebige Listenelemente, aus denen eines ausgewählt werden kann.",
      Signature: "Feld für digitale Unterschrift (mit Finger nutzbar)",
      Email: "Benutzer kann eine gültige E-Mail Adresse angeben.",
      SendEmailTo: "Benutzer kann eine gültige E-Mail Adresse angeben, an die das ausgefüllte Formular als PDF geschickt wird.",
      Date: "Benutzer kann ein Datum über einen Kalender angeben",
      Time: "Benutzer kann eine Uhrzeit angeben",
      Boolean: "Benutzer wählt Ja oder Nein",
      TakePicture: "Der Benutzer kann ein Foto mit der Kamera machen oder ein Bild hochladen.",
      TakeMultiPicture: "Der Benutzer kann mehrere Fotos mit der Kamera machen oder Bilder hochladen.",
      Scan: "Fordern Sie Benutzer auf einen QR Code oder eine EAN zu scannen. Das Ergebnis wird in dem Formular angezeigt und gespeichert.",
      Input: "Einzeiliges Texteingabefeld",
      Textarea: "Mehrzeilige Texteingabe für längere Kommentare",
      Link: "Link auf externe Seite",
      Picture: "Laden Sie ein Bild hoch das beim Ausfüllen angezeigt wird.",
      Video: "Laden Sie ein Video hoch das beim Ausfüllen angezeigt wird.",
      Text: "Hinterlegen Sie mehrzeilige Texte mit Formatierungen.",
      Header: "Große Überschrift zur Strukturierung der Inhalte",
      Rate: "5-Sterne Bewertung",
      RateSmiley: "Auswahl aus 3 Smiley Gesichtern (fröhlich, neutral, traurig)",
      CreateTask: "Es wird ein Ticket für einen Benutzer angelegt",
      FileUpload: "Der Benutzer kann mehrere beliebige Dateien dem Formular anhängen.",
      Currency: "Der Benutzer kann einen Geldbetrag dezimal in Euro angeben.",
    },
    template: {
      template: 'Berichtsvorlage',
      templates: 'Berichtsvorlagen',
      name: 'Name',
      description: 'Beschreibung',
      customer: 'Kunde',
      new: 'Neue Berichtsvorlage',
      edit: 'Berichtsvorlage bearbeiten',
      create: 'Berichtsvorlage anlegen',
      public: 'Öffentlich',
      active: 'Aktiv',
      deleteConfirm: 'Wollen Sie die Berichtsvorlage \'{{templateName}}\' wirklich löschen?',
      deleteSuccess: 'Berichtsvorlage wurde entfernt',
      editor: 'Vorlagendesigner',
      notForMobile: 'Der Vorlagendesigner ist nicht für mobile Endgeräte optimiert. Bitte nutzen Sie hierfür Blink Check im Browser.',
      noTemplates: 'Es sind noch keine Berichtsvorlagen vorhanden.',
      custom: 'Individuelle Vorlage',
      defaultWithPoints: 'Standardvorlage - mit Prozentpunkten',
      default: 'Standardvorlage - ohne Prozentpunkten',
      createTemplateInfo: 'Sie können die Berichtsvorlage erst nach dem Anlegen bearbeiten.'
    }
  },
  checklistPermission: {
    globalPermissionGroup: 'Globale Berechtigungsgruppe',
    notAllowed: 'Sie haben keine Berechtigung um die Berechtigungen dieser Vorlage zu ändern.',
    all: 'Das Formular kann von allen Benutzer ausgefüllt werden',
    groups: 'Das Formular kann nur von Benutzern dieser Berechtigungsgruppen ausgefüllt werden',
    noGroupLicence: 'Blink Check Lizenz für diese Gruppe ist nicht aktiviert.',
    overridingPermssion: 'Durch die Option "Systemeinstellung" in der Berechtigungsgruppe "Sysadmin" erhalten die Benutzer automatisch alle Berechtigungen auf dieses Formular.',
    // noGroupLicence: 'Blink Check Lizenz für diese Gruppe ist nicht aktiviert. Aktivieren Sie die Blink Check Lizenz im Blink Manager.'
    onlyLicensedPermissionGroups: "Es werden nur Berechtigungsgruppen angezeigt, denen eine Blink Check Lizenz zugeordnet ist.",

    canUpdateChecklist: 'Formularvorlage bearbeiten',
    canFillChecklist: 'Formular ausfüllen',
    canReadChecklistDataOwn: 'Selbst ausgefüllte Formulare anzeigen und auswerten',
    canReadChecklistDataOwnEmployees: 'Alle ausgefüllten Formulare eigener Mitarbeiter anzeigen und auswerten',
    canReadChecklistDataOwnLocations: 'Alle ausgefüllten Formulare eigener Objekte anzeigen und auswerten',
    canReadChecklistDataAll: 'Alle ausgefüllten Formulare anzeigen und auswerten'
  },


  companyConfig: {
    companyConfig: 'Mandantenkonfiguration',
    logoInfo: 'Wird in den Standardberichtsvorlagen in der Kopfzeile angezeigt.',
    footer: 'Fußzeile',
    footerInfo: 'Wird in den Standardberichtsvorlagen in der Fußzeile angezeigt.',
    offline: 'Bitte stellen Sie eine Internet-Verbindung her, um die Konfiguration bearbeiten zu können.'
  },

  switchCompanyDialogHeader: "Mandanten wechseln",
  switchCompanyDialogText: "Wenn Sie den Mandanten wechseln, werden alle Formulare in Bearbeitung zurückgesetzt. Möchten Sie fortfahren?",

  results: {
    results: 'Auswertung',
    resultsPlural: 'Auswertungen',
    details: 'Details',
    created: 'Erstellt',
    result: 'Ergebnis',
    noReportJsonExists: 'Der Bericht wurde noch nicht angelegt. Der Bericht wird zeitverzögert erzeugt, bitte versuchen Sie es später nochmal.',
    noResults: 'Es sind noch keine ausgefüllten Formulare vorhanden.',
    noResultsForDateFilter: 'Es sind keine ausgefüllten Formulare für den gewählten Zeitraum vorhanden.',
    periodFrom: 'Zeitraum von',
    periodTo: 'Zeitraum bis',
    location: 'Objekt',
    createUser: 'Ersteller',
    loginUser: 'Mitarbeiter',
    checklist: 'Formularvorlage',
    user: 'Benutzer',
    recentResults: 'Aktuell ausgefüllte Formulare',
    recent: 'Aktuell',
    pointOf: '{{ itemPoints }} von {{ maxItemPoints }} Punkten',
    checklistCreatedByAt: 'Formular ausgefüllt von {{ firstName }} {{ lastName }} am {{ createDate }} um {{ createTime }} Uhr.',
    linkedTask: 'Verknüpftes Ticket: #{{ taskId }} {{ taskTitle }}',
    offline: 'Bitte stellen Sie eine Internet-Verbindung her, um ausgefüllte Formulare auswerten zu können.',
    delete: {
      title: 'Ausgefülltes Formular löschen',
      message: 'Wollen Sie das ausgefüllte Formular “{{checklistName}}" wirklich löschen?',
      success: 'Ausgefülltes Formular wurde entfernt'
    },
    editReferenceTimestamp: 'Referenzdatum bearbeiten',
    editReferenceTimestampDescription: 'Das Referenzdatum bestimmt den Zeitpunkt, an dem das Formular ausgefüllt wurde. Dieser kann sich gegebenenfalls von dem Zeitpunkt unterscheiden, an dem das Formular abgeschickt wurde.',
    searchPlaceholder: 'Suchen nach Mitarbeiter, Ersteller, Objekt oder Inhalt.'
  },
  "dependencies": {
    "dependency": "Abhängigkeit",
    "addTitle": "Bitte Vergleichswert auswählen",
    "addMessage": "Bitte geben Sie den Wert an, der erfüllt werden muss, damit das Element angezeigt wird.",
    "addTitleMulti": "Bitte Vergleichswerte auswählen",
    "addMessageMulti": "Bitte geben Sie die Werte an, von denen einer erfüllt werden muss, damit das Element angezeigt wird.",
    "present": "Vorhanden",
    "notPresent": "Nicht vorhanden",
    "noResults": "Keine Abhängigkeiten vorhanden. Neue Abhängigkeiten können über \"Abhängigkeit hinzufügen\" hinzugefügt werden.",
    "noAvailableItems": "Es sind zu wenige Elemente in dem Formular vorhanden um Abhängigkeiten anzulegen. Bitte fügen Sie zuerst weitere Elemente hinzu.",
    "noAvailableItemsInGroup": "Es sind zu wenige Elemente in der Seite vorhanden um Abhängigkeiten anzulegen. Bitte wählen sie eine Seite aus, die Elemente enthält.",
    "dependencyAdded": "Abhängigkeit wurde hinzugefügt.",
    "dependencyRemoved": "Abhängigkeit wurde entfernt.",
    "dependencyInfo": "Sobald Sie eine Abhängigkeit hinzufügen wird dieses Element ({{elementName}}) nur angezeigt, wenn das oder die abhängigen Elemente ausgefüllt wurden.",
    "true": "Ja",
    "false": "Nein",
    "#not_empty#": "Vorhanden",
    "#empty#": "Nicht vorhanden",
    "frown": "Traurig",
    "meh": "Geht so",
    "smile": "Glücklich"
  },
  comparisons: {
    equals: "Gleich",
    greaterThan: "Größer als",
    greaterThanOrEqual: "Größer oder gleich",
    lessThan: "Kleiner als",
    lessThanOrEqual: "Kleiner oder gleich"
  },
  noChecklists: "Keine Formulare vorhanden.",
  updating: "Blink Check wird aktualisiert.",
  installing: "Version {{version}} wird installiert ...",
  appName: "Blink Check",
  notAvailableInPublicChecklists: " (In öffentlichen Formularvorlagen nicht verfügbar)",
  pdfAssignTemplate: "Sie müssen dem Formular eine Berichtsvorlage zuweisen, bevor sie den PDF Download verwenden können.",
  noCheckLicense: 'Keine BlinkCheck Lizenz',
  noCheckLicenseInfo: 'Bitte wenden Sie sich an Ihren BlinkCheck Administrator.',
};
