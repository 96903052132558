<ng-container *ngIf="useIonDatetime">
  <ion-item *ngIf="label"
            lines="none"
            color="input"
            class="blink-item-input blink-item-input-new-style"
            button="true"
            [id]="cid"
            [attr.required]="required ? '' : null"
            [disabled]="disabled">
    <ion-input
      tabindex="-1"
      (ionFocus)="openDatepickerPopoverDelayed($event)"
      [ngModel]="value | day: format"
      (ionChange)="handleClearValue($event)"
      [placeholder]="placeholder | translate"
      label="{{ label | translate }}"
      labelPlacement="stacked"
      [clearInput]="clearable"
      inputmode="none">
      <ui-icon [icon]="icon"
               size="lg"
               class="mr-15"
               slot="end">
      </ui-icon>
    </ion-input>
  </ion-item>

  <ion-item *ngIf="!label"
            lines="none"
            color="input"
            class="blink-item-input blink-item-input-new-style"
            [id]="cid"
            [attr.required]="required ? '' : null"
            [disabled]="disabled">
    <ion-input tabindex="-1"
               (ionFocus)="openDatepickerPopoverDelayed($event)"
               [ngModel]="value | day: format"
               (ionChange)="handleClearValue($event)"
               [placeholder]="placeholder | translate"
               [clearInput]="clearable"
               inputmode="none"
               [disabled]="disabled">
    </ion-input>
  </ion-item>

</ng-container>

<!-- Currently only relevant for time -->
<ng-container *ngIf="!useIonDatetime">
  <ion-item class="blink-item-input blink-item-input-new-style"
            lines="none"
            color="input">
    <ion-input [ngModel]="value | day: format"
               [ngModelOptions]="{standalone: true, updateOn: 'blur'}"
               type="time"
               label="{{ label | translate }}"
               labelPlacement="floating"
               [required]="required"
               (ionChange)="timeInputChange($event)">
      <ui-icon [icon]="icon"
               size="lg"
               class="mr-15"
               slot="end"
               aria-hidden="true">
      </ui-icon>
    </ion-input>
  </ion-item>
</ng-container>
