import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiPageService {
  private scrollTopSubject = new Subject();
  scrollTop$ = this.scrollTopSubject.asObservable();

  private scrollBottomSubject = new Subject();
  scrollBottom$ = this.scrollBottomSubject.asObservable();

  scrollTop() {
    console.log('UiPageService: scrollTop() not implemented!');
  }

  scrollBottom() {
    console.log('UiPageService: scrollBottom() not implemented!');
  }
}
