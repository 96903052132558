import { Inject, Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { APP_NAME, APP_VERSION } from '@blink/util';
import { DeviceInfo, DeviceInfoResponse } from './devices.interface';

@Injectable({
  providedIn: 'root',
})
export class DeviceServices {
  constructor(
    @Inject(APP_VERSION) public appVersion: string,
    @Inject(APP_NAME) public appName: string,
  ) {
  }

  async getDeviceInfo(): Promise<DeviceInfoResponse> {
    const DeviceInfo = await Device.getInfo();
    const deviceId = await Device.getId();
    let deviceInfoAppName = 'BlinkUnknown';
    switch (this.appName) {
      case 'Blink me':
        deviceInfoAppName = 'BlinkMe';
        break;
      case 'Blink Check':
        deviceInfoAppName = 'BlinkCheck';
        break;
    }

    const deviceInfo: DeviceInfo = {
      appVersion: this.appVersion,
      platform: DeviceInfo.platform,
      model: DeviceInfo.model,
      manufacturer: DeviceInfo.manufacturer,
      operatingSystemVersion: DeviceInfo.osVersion,
      androidSDKVersion: DeviceInfo.androidSDKVersion || '',
      webViewVersion: DeviceInfo.webViewVersion,
    };

    return {
      Number: deviceId.identifier,
      Type:
        DeviceInfo.platform === 'web'
          ? deviceInfoAppName + 'Web'
          : deviceInfoAppName + 'App',
      DeviceInfo: deviceInfo,
    };
  }
}
